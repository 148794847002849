import React from 'react'
import classnames from 'classnames';
import './index.css'

const MigrationNotification = () => {


  return (
    <div className={classnames('notification-widget entity-migration fixed-widget')}>
      <div className="notification">
        <div className="notification-text">fundraisers.giveindia.org is now give.do. Learn more about the partnership <a href="https://give.do/blog/?p=12547" style={{color: "#f4a034"}}>here</a>.</div>
      </div>
    </div>
  )
}

export default MigrationNotification