// import React from 'react'
import { observe } from 'fast-json-patch'
import _, { sample } from 'lodash'
import Helpers from './helpers'

/**
  Methods Available

  @method getThisFormSchema(step)
  Outputs the schema for the step identifier that's been provided.

  @method getValidationsFromSchema(schema)
  Fetches list of Validations from the schema that has been passed.

  @method getFormFieldsFromSchema(schema)
  Outputs an array of fields that could be rendered by the Form.jsx file for each elements.

  @method getArrayFieldIndex(key, value, identifier)
  For array fields, fetching an existing index or creating a new index for an array field.

  @method  generateRepeaterKey

  @method getObjectDiff(object1, object2)
  Returns difference in two objects (object1 - object2)

  @method clearFormData(data)
  Removed data for array fields that were initialised without values.

  @method validateFormFields

*/

export default class DynamicFormHelper {  
  constructor(){

  }

  static regionOptions = { 
    'Andaman & Nicobar Islands': 'Andaman & Nicobar Islands',
    'Andhra Pradesh': 'Andhra Pradesh',
    'Assam': 'Assam',
    'Chandigarh': 'Chandigarh',
    'Dadra & Nagar Haveli': 'Dadra & Nagar Haveli',
    'Delhi': 'Delhi',
    'Gujarat': 'Gujarat',
    'Haryana': 'Haryana',
    'Himachal Pradesh': 'Himachal Pradesh',
    'Jammu & Kashmir': 'Jammu & Kashmir',
    'Kerala': 'Kerala',
    'Lakshadweep': 'Lakshadweep',
    'Maharashtra': 'Maharashtra',
    'Manipur': 'Manipur',
    'Meghalaya': 'Meghalaya',
    'Karnataka': 'Karnataka',
    'Nagaland': 'Nagaland',
    'Odisha': 'Odisha',
    'Pondicherry': 'Pondicherry',
    'Punjab': 'Punjab',
    'Rajasthan': 'Rajasthan',
    'Tamil Nadu': 'Tamil Nadu',
    'Tripura': 'Tripura',
    'West Bengal': 'West Bengal',
    'Sikkim': 'Sikkim',
    'Arunachal Pradesh': 'Arunachal Pradesh',
    'Mizoram': 'Mizoram',
    'Daman & Diu': 'Daman & Diu',
    'Goa': 'Goa',
    'Bihar': 'Bihar',
    'Madhya Pradesh': 'Madhya Pradesh',
    'Uttar Pradesh': 'Uttar Pradesh',
    'Chattisgarh': 'Chattisgarh',
    'Jharkhand': 'Jharkhand',
    'Uttarakhand': 'Uttarakhand',
    'Telangana': 'Telangana',
  }
  
  static formFieldsFromSchema = []
  static formSteps = {}
  static formFieldElementTypes = [
    'input',
    'radio',
    'select',
    'textarea',
    'upload',
    'media', 
    'multi-select',
    'tel',
    'checkbox',
  ]
  static formArrayFieldIndex = {}

  // Arrayform Index would look something like
  /*
    {
      "nonprofit.external_profiles" ; {
        currentIndex: 1,
        entries: [
          {
            identifier: {
              label: "Website",
            },
            index: 0
          },
          {
            identifier: {
              label: "Facebook",
            },
            index: 1
          }
        ]
      }
    }
  */

  static formValidations = {
    required: []
  }
  
  
  /* 
    Method: getThisFormSchema
    Takes the schema and the step data and returns the schema for the required step     
  */

  static getThisFormSchema = (formJSON, step) => {
    if(formJSON.hasOwnProperty('properties')){
      const child = formJSON.properties;
      if(child.hasOwnProperty(step)){
        return child[step]
      }
      else{
        console.error('Invalid Form Step; Schema doesn\'t contain an object with the name' + step);
      }
    }
    else{
      console.error('Invalid JSON Format');
      return false;
    }
  }

  /*
    Method: getValidationsFromSchema
    Takes formSchema as input and returns an array of validations in the format 
    {
      required: [...],
      field_key: { ... }
    }
  */
  // TODO: Convert this method to work on Breadth First Search instead of recursion

  static getValidationsFromSchema = (formSchema) => {
    if (formSchema !== null && Object.keys(formSchema).length) {
      if (formSchema.hasOwnProperty('properties')) {
        formSchema = formSchema.properties
      }
      Object.keys(formSchema).map(item => {
        if (formSchema[item].hasOwnProperty('validations') &&formSchema[item].validations.required === true) {
          this.formValidations[formSchema[item].key] = formSchema[item].validations
          if (formSchema[item].validations.required === true)
            if (item === 'beneficiaryName' || item === 'accountType' || item === 'accountNumber' || item === 'ifsc' || item === 'cancelledCheque') {
              Array.from({ length: 2 }, (v, k) => {
                this.formValidations.required.push(
                  (formSchema[item].collection !== undefined ? formSchema[item].collection + '.' + 'bank_accounts' + '.' + k + '.' : '') + formSchema[item].key
                )
              })
            } else {
              this.formValidations.required.push(
                (formSchema[item].collection !== undefined ? formSchema[item].collection + '.' : '') + formSchema[item].key
              )
            }
          return this.formValidations
        } 
        else if (formSchema[item].hasOwnProperty('properties')) {
          this.formValidations = this.getValidationsFromSchema(formSchema[item], this.formValidations)
        }
      })
      return this.formValidations
    }
  }


  // Method to recursively generate formFields the respective steps, creates an array of FormElement values that can be rendered with the component FormItem 
  static getFormFieldsFromSchema = (jsonSchema, is_new_step = false, is_approvals = false) => {

    // Incase of a newer step, empty the current list of fields. 
    // TODO: Once created, store the value to ensure it doesn't have to rerun again. [Memoize]
    if(is_new_step) this.formFieldsFromSchema = []
    
    const thisSchema = _.cloneDeep(jsonSchema)
    if(_.has(thisSchema,'properties')){
      
      if(is_approvals && thisSchema.isApprovals === false){        
        return 
      }
      
      // Case 1: If Element is a section Header and is not iterable, push the value of the section without the properties property.
      if(_.has(thisSchema,'element') && thisSchema.element !== 'step' && !_.has(thisSchema,'iterable')){
        this.formFieldsFromSchema.push(_.omit(thisSchema, ['properties']))
      }

      // Case 2: If Element is iterable where in the input fields gets displayed multiple times on the value in repeaton field 
      if(_.has(thisSchema, 'iterable') && thisSchema.iterable){                      
        // Case 2a: If the schema is iterable over array values 
        if(typeof thisSchema.repeaton === 'object'){
          thisSchema.repeaton.forEach(repeatKey => {
            let indexValue = repeatKey;

            this.formFieldsFromSchema.push(_.omit(
              { 
                ...thisSchema, 
                title: Helpers.formatText(
                  typeof thisSchema.title === 'object' ? thisSchema.title[indexValue] : thisSchema.title.replace('{{repeatKey}}', indexValue)
                ),
                description: thisSchema.description ? Helpers.formatText(
                  typeof thisSchema.description === 'object' ? thisSchema.description[indexValue] : thisSchema.description.replace('{{repeatKey}}', indexValue)
                ) : undefined
              },
              [
                'properties', 
                'identifier', 
                'identifier_replacers',
                'description'
              ]
            ))
            
            if(_.has(thisSchema, 'siblings') && thisSchema.siblings){
              
              let parentKey

              if(thisSchema.identifier){
                parentKey =  ( thisSchema.collection ? `${thisSchema.collection}.` : '' ) + (thisSchema.key ? `${thisSchema.key}.` : '') + `{{index}}`               
              }
              else{
                parentKey =  ( thisSchema.collection ? `${thisSchema.collection}.` : '' ) + (thisSchema.key ? `${thisSchema.key}.` : '') + `${repeatKey}`               
              }
              

              const tempnode = _.cloneDeep(thisSchema['properties']) 

              _.forIn(tempnode, (item => {

                const replacers = thisSchema.identifier_replacers ? 
                  { 
                    ...thisSchema.identifier_replacers, 
                    [thisSchema.index]: repeatKey 
                  } : 
                  { [thisSchema.index]: repeatKey }
                                    
                return this.getFormFieldsFromSchema({
                  ...item, 
                  collection: parentKey ? parentKey: thisSchema.collection,
                  identifier: thisSchema.identifier,               
                  value: thisSchema.identifier ? ( item.value ? this.replaceIdentifierWithReplacers(item.value, replacers) : null) : item.value,
                  identifier_replacers: replacers,
                  title: item.title ? item.title : (thisSchema?.title ? thisSchema?.title[repeatKey]  : ''),
                  description: item.description ? item.description : (thisSchema?.description ? thisSchema?.description[repeatKey] : ''),
                  is_column: thisSchema.element === 'row' ? true : false
                }, false, is_approvals)
                
              }))
            }            
            else{
              const tempnode = _.cloneDeep(thisSchema['properties']) 
              _.forIn(tempnode, (item, i) => {

                const replacers = thisSchema.identifier_replacers ? 
                  { ...thisSchema.identifier_replacers, [thisSchema.index]: repeatKey } : 
                  { [thisSchema.index]: repeatKey }

                return this.getFormFieldsFromSchema({
                  ...item, 
                  collection: thisSchema.collection + (thisSchema.key ? `.${thisSchema.key}` : ''),
                  identifier_replacers: replacers,
                  is_column: thisSchema.element === 'row' ? true : false
                }, false, is_approvals)
              })
            }                      
          })
        } 

        // Case 2b: If the schema is iterable over value from the state, string represent the key of the value    
        else{                              
          const tempnode = _.cloneDeep(thisSchema['properties'])
          const parentKey = thisSchema.collection ? `${thisSchema.collection}.${thisSchema.key}` : thisSchema.key
          let repeaterIndex
          // If the the properties aren't to be iterated on a key value, the code needs to create a button for adding a new array 

          if(!thisSchema.repeaton){
            repeaterIndex = this.generateRepeaterKey(thisSchema.key)
          }
          
          this.formFieldsFromSchema.push({
            repeaton: thisSchema.repeaton ? ( 
                thisSchema.collection ? `${thisSchema.collection}.${thisSchema.repeaton}` : thisSchema.repeaton 
              ) : (
                repeaterIndex ?  repeaterIndex : null
              ),
            key: parentKey,
            title: thisSchema.title,
            description: thisSchema.description,
            startIndex: thisSchema.startIndex,
            repeat: [],
            dependency: thisSchema.dependency ? thisSchema.dependency : null
          })        

          _.forIn(tempnode, (item, i) => {
            return this.getFormFieldsFromSchema({
              ...item,
              collection: `${parentKey}.{{index}}`,
              repeaton: thisSchema.repeaton ? (thisSchema.collection ? `${thisSchema.collection}.${thisSchema.repeaton}` : thisSchema.repeaton) : (repeaterIndex ?  repeaterIndex : null),
              is_column: thisSchema.element === 'row' ? true : false,
              dependency: thisSchema.dependency ? thisSchema.dependency : ( item.dependency ? item.dependency : null )
            }, false, is_approvals)
          })
                  
          // If Schema doesn't have a repeat on key and is an iterable item, add a button to increment values of segment           
          if(!thisSchema.repeaton){            
            this.formFieldsFromSchema.push({
              element: 'increment-button',
              title: `Add ${thisSchema.action || thisSchema.title?.replace('{{repeatKey}}','') || 'Item'}`,
              key: repeaterIndex,
              dependency: thisSchema.dependency ? thisSchema.dependency : null
            })
          }
        }
      }

      // Case 3: If Element has properties sibling but is not iterable, eg for external profiles, where the values aren't displayed together. 
      else if(_.has(thisSchema, 'siblings') && thisSchema.siblings){
        const parentKey = thisSchema.collection ? `${thisSchema.collection}.${thisSchema.key}.{{index}}` : `${thisSchema.key}.{{index}}`
        const tempnode = _.cloneDeep(thisSchema['properties'])
        _.forIn(tempnode, ((item, key) => {  return this.getFormFieldsFromSchema({
            ...item, 
            collection: parentKey,
            identifier: thisSchema.identifier, 
            dependency: thisSchema.dependency ? thisSchema.dependency : ( item.dependency ? item.dependency : null ),
            is_column: thisSchema.element === 'row' ? true : false
          }, false, is_approvals)
        })) 
      }
      // Case 4: If the object is tail node       
      else{
        const tempnode = _.cloneDeep(thisSchema['properties'])        
        _.forIn(tempnode, (item, key) => {        
          return this.getFormFieldsFromSchema({ 
            ...item,
            collection: item.collection ? item.collection : thisSchema.collection,
            dependency: thisSchema.dependency ? thisSchema.dependency : ( item.dependency ? item.dependency : null ),
            is_column: thisSchema.element === 'row' ? true : false
          }, false, is_approvals)
        })
      }
    }
    else if(_.has(thisSchema, 'element') && this.formFieldElementTypes.indexOf(thisSchema.element) >= 0){
      
      if(thisSchema.hasOwnProperty('enum')){
        if(typeof thisSchema.enum === 'string'){
          thisSchema.enum = eval('this.' + thisSchema.enum)          
        }
      }

      let elementKey = thisSchema.collection ? `${thisSchema.collection}.${thisSchema.key}` : thisSchema.key
      let identifier = null
      if(thisSchema.identifier && thisSchema.identifier_replacers){ 
        identifier = this.replaceIdentifierWithReplacers(thisSchema.identifier, thisSchema.identifier_replacers)
      }            
    
      if(thisSchema.repeaton && typeof thisSchema.repeaton === 'string'){      
        let index = this.formFieldsFromSchema.findIndex(item => item.repeaton === thisSchema.repeaton)
        if(index >= 0){
          this.formFieldsFromSchema[index].repeat.push(_.omit({
            ...thisSchema,
            key: elementKey
          }, ['properties', 'repeaton', 'identifier', 'collection']))
        }
      }
      else{
        this.formFieldsFromSchema.push({
          ...thisSchema, 
          key: elementKey, 
          identifier: identifier !== null ? identifier : thisSchema.identifier        
        })
      }
    }
    return this.formFieldsFromSchema
  }
    
  // Method to get index of Array Fields with the parent path and the identifier   
  static getArrayFieldIndex = (values, path, identifier = false) => {      
    
    if(this.formArrayFieldIndex[path]){
      /* 
        If the local store for path exists and the array contains a object with the identifier that's passed, then fetch the index and return the value.
        Avoid doing the whole business of some 100 lines of code again.
      */
      const keyIndex = _.findIndex(this.formArrayFieldIndex[path], item => _.isEqual(item.identifier, identifier))       
      if(keyIndex !== -1){
        return this.formArrayFieldIndex[path][keyIndex].index
      }
    }
                
    let keyValue = Helpers.getObjectfromPath(values, path);     // Geting the value of the parent Path 
    let keyIndex      // Initialising Index Value; this value will be returned back while the function is called.
    
    // Case 1: If keyValue is not empty. 
    // Eg. if Key is 'documents' and the value of nonprofit.document is not empty

    if(keyValue !== undefined && keyValue.length !== 0){      
      const tempIndex = _.findIndex(keyValue, identifier) 
      if(tempIndex !== -1){
        /* 
          If an array exists with the identifier.
          Eg. identifier is { label: "Website" } for "external_profiles", 
          it'll return the index of the array external_profiles where the value identifier is what's passed to the function.
        */

        if(this.formArrayFieldIndex[path]){          
          /* 
            If the path is part of the array field that's created already.
            Eg. If the path is documents and the keys for the path has been previously stored in the variable.
          */ 
          if(this.formArrayFieldIndex[path][tempIndex]){
            // If the index found exists in the locally stored indexing array
            if(_.isEqual(this.formArrayFieldIndex[path][tempIndex].identifier, identifier)){
              // If both the idenfier passed and the one at the said indexes are the same then return the index stored locally 
              keyIndex = this.formArrayFieldIndex[path][tempIndex].index
            }
            else{
              // Else assign the tempIndex to the value
              keyIndex = tempIndex
            }
          }
          else{
            // Figure out why the next line? Was written previously, have no idea why 
            // keyIndex = this.formArrayFieldIndex[path].length 

            // Else assign the tempIndex to the value
            keyIndex = tempIndex
          }
        }
        else{
          // Else assign the tempIndex to the value
          keyIndex = tempIndex
        }
      }
      else{        
        if(this.formArrayFieldIndex[path]){
          let thisIdentifierIndex = _.findIndex(this.formArrayFieldIndex[path], (item) => _.isEqual(item.identifier,identifier))          
          if(thisIdentifierIndex !== -1){              
            keyIndex = this.formArrayFieldIndex[path][thisIdentifierIndex].index              
          } 
          else{
            keyIndex = keyValue.length + this.formArrayFieldIndex[path].length
          }
        }
        else{
          keyIndex = keyValue.length
        }
      }

      if(this.formArrayFieldIndex[path] !== undefined){          
        this.formArrayFieldIndex[path].push({
          identifier: identifier,
          index : keyIndex
        })
      }
      else{
        this.formArrayFieldIndex[path] = [{
          identifier: identifier,
          index : keyIndex
        }]
      }
    }
    // Case 2: Value is null, i.e, it's a new non-profit and some index of the path is already stored
    else if(Object.keys(this.formArrayFieldIndex).length !== 0 && Object.keys(this.formArrayFieldIndex).indexOf(path) >= 0){   
                      
      let thisIdentifierIndex = _.findIndex(this.formArrayFieldIndex[path], (item) => _.isEqual(item.identifier,identifier))
      if(thisIdentifierIndex !== -1){
        keyIndex = this.formArrayFieldIndex[path][thisIdentifierIndex].index
      }
      else{
        keyIndex = this.formArrayFieldIndex[path].length
        this.formArrayFieldIndex[path].push({
          identifier: identifier,
          index: keyIndex
        })
      }      
    }  
    // Case 3: Value is null, i.e It;s a new nonprofit, but no value for the key has been stored   
    else{   
      if(Object.keys(this.formArrayFieldIndex).length === 0 || this.formArrayFieldIndex[path] === undefined){               
        keyIndex = 0
        this.formArrayFieldIndex[path] = [{          
          identifier: identifier,
          index: keyIndex
        }]         
      }
    }   
    return keyIndex    
  }

  static replaceIdentifierWithReplacers = (identifier, replacer) => {    
    if(typeof identifier === 'object'){      
      Object.keys(identifier).forEach(item => {
        identifier[item] = identifier[item].replace(
          identifier[item], 
          replacer[identifier[item]
            .replace('{{','')
            .replace('}}','')]
        )
      })
    }
    else{
      identifier = identifier.replace(
        identifier,
        replacer[identifier.replace('{{','').replace('}}','')]
      )
    }
    return identifier;
  }

  static validateFormFields = (formData, validation) => {
    const errors = [];
    validation.forEach(keyIndex => {
      const value = _.get(formData, keyIndex.split('.'))
      if(value === undefined){
        errors.push(keyIndex);
      }
    })    
    return errors
  }


  static clearNulls = (obj) => {
    return function prune(current) {
      _.forOwn(current, function (value, key) {
        if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value) ||
          (_.isString(value) && _.isEmpty(value)) ||
          (_.isObject(value) && _.isEmpty(prune(value)))) {
  
          delete current[key];
        }
      });
      // remove any leftover undefined values from the delete 
      // operation on an array
      if (_.isArray(current)) _.pull(current, undefined);
  
      return current;
  
    }(_.cloneDeep(obj));  
    // Do not modify the original object, create a clone.
  }

  static clearFormData = (data) => {        
    let formData = _.cloneDeep(data)
    // TODO: Change this function to be able to check for the available properties by default 
  
    formData = this.clearNulls(formData)

    if(formData.nonprofits){
      // Removing Documents with Empty URI and Empty type
      if (formData.nonprofits.documents && formData.nonprofits.documents.length !== 0) {
        formData.nonprofits.documents = _.filter(formData.nonprofits.documents, (item) =>
          item !== undefined && item !== null && item.uri !== undefined && item.uri !== '' && item.uri !== null &&
          item.document_type !== null && item.document_type !== undefined && item.document_type !== ''
        )
      }   
      // Removing External Profiles with Empty URI
      if(formData.nonprofits.external_profiles && formData.nonprofits.external_profiles.length !== 0){
        // Filtering out information that has valid keys available, which might trigger validation error on backend upon request submission/approval
        formData.nonprofits.external_profiles = _.filter(formData.nonprofits.external_profiles, (item) => 
          item !== undefined && 
          item !== null && 
          item.uri !== undefined && 
          item.uri !== '' && 
          item.uri !== null &&
          item.label !== undefined &&
          item.label !== '' &&
          item.label !== null
        )
      }
      // Removing Bank Accounts with Empty Account Name, Type and/or Account Number and is_international
      if (formData.nonprofits.bank_accounts && formData.nonprofits.bank_accounts.length !== 0) {
        formData.nonprofits.bank_accounts = _.filter(formData.nonprofits.bank_accounts, (item) =>
          item !== null && item.account_name !== undefined && item.account_type !== undefined && item.account_number !== undefined &&
          item.is_international !== null && item.is_international !== undefined
        )
      }
      
      // Removing Staff Member details that aren't filled completely
      if(formData.nonprofits.others && formData.nonprofits.others.staff_related_board && formData.nonprofits.others.staff_related_board.length !== 0){      
        formData.nonprofits.others.staff_related_board = _.filter(formData.nonprofits.others.staff_related_board, (item) => 
          item !== null && 
          item.name !== '' && item.name !== undefined && 
          item.relation !== '' && item.relation !== undefined
        )
      }

      // Removing Leaders' that aren't filled completely
      if(formData.nonprofits.others && formData.nonprofits.others.leaders && formData.nonprofits.others.leaders.length !== 0){      
        formData.nonprofits.others.leaders = _.filter(formData.nonprofits.others.leaders, (item) => 
          item !== null && 
          item.name !== '' && item.name !== undefined && 
          item.profile !== '' && item.profile !== undefined
        )
      }
      
      // Removing Governing Body Members' that aren't filled completely
      if(formData.nonprofits.governing_body && formData.nonprofits.governing_body.members && formData.nonprofits.governing_body.members.length !== 0){      
        formData.nonprofits.governing_body.members = _.filter(formData.nonprofits.governing_body.members, (item) => 
          item !== null && 
          item.name !== '' && item.name !== undefined && 
          item.gender !== '' && item.gender !== undefined &&
          item.position !== '' && item.position !== undefined          
        )
      }
      
      // Removing gst_address if gst_number is not present
      if (formData.nonprofits.donation_options && formData.nonprofits.donation_options.length !== 0) {
        if(!formData.nonprofits.gst_details &&!formData.nonprofits.gst_details.gst_number) delete formData.nonprofits.gst_details.gst_address
        }

      //Removing cause object if name or description is not present
      if (formData.nonprofits.causes && formData.nonprofits.causes.length !== 0) {
        formData.nonprofits.causes = _.filter(formData.nonprofits.causes, (item) =>
          item !== undefined &&
          item !== null &&
          item.name !== undefined &&
          item.name !== '' &&
          item.name !== null &&
          item.description !== undefined &&
          item.description !== '' &&
          item.description !== null
        )
      }
    }
    
    if(formData.projects){
      // Removing Donation Options with Empty Title and Amount 
      if(formData.projects.donation_options && formData.projects.donation_options.length !== 0){
        formData.projects.donation_options = _.filter(formData.projects.donation_options, (item) => 
          item !== null && item.title !== undefined && item.amount !== undefined
        )
      }
    }
    return formData
  }

  static getObjectDiff = (newObject, controlObject) => {
    const diff = {}
    diff._id = controlObject._id
    try{
      Object.keys(newObject).forEach(key => {        
        if(_.isEqual(newObject[key], controlObject[key])){
          // Do Nothing
        }
        else{          
          diff[key] = newObject[key]
        }
      })
      return diff
    }
    catch(error){
      console.error(error);
    }
  }

  static generateRepeaterKey = (key) => {
    return key.split('.').join('_')
  }

  // This method currently is not dynamic and will require some changes to cater to project collections, is needed 
  static transformAPIRequestErrors = (errors) => {    
    if(errors && errors.length !== 0 && errors !== null){      
      const returnError = {}
      if(Array.isArray(errors)){
        errors.forEach(item => {
          returnError['nonprofits.' + Object.keys(item)[0]] = Object.values(item)[0]
        })      
        return returnError;
      }
    }
    else{
      return null
    }
  }

  static getStepDetailsfromKey = (key, schema) => {    
    const schemaClone = Helpers.cloneObject(schema)    
    let stepKey
    if(schemaClone.properties){
      for(let i = 0; i < Object.keys(schemaClone.properties).length; i++){
        const item = Object.keys(schemaClone.properties)[i]
        if(schemaClone.properties[item].element === 'step'){          
          const stringifiedSchema = JSON.stringify(schemaClone.properties[item])          
          if(stringifiedSchema.indexOf(key) >= 0){                        
            stepKey = item
          }
          else if(key.indexOf('nonprofits') !== -1 && stringifiedSchema.indexOf(key.replace('nonprofits.','')) >= 0){            
            if(item !== 'project') stepKey = item
          }
          else if(key.indexOf('projects') !== -1 && stringifiedSchema.indexOf(key.replace('projects.','')) >= 0){            
            if(item === 'project') stepKey = item  
          }          
          else if(key.indexOf('nonprofits') !== -1 && stringifiedSchema.indexOf(key.replace('nonprofits.','').split('.')[0]) >= 0){            
            if(item !== 'project') stepKey = item            
          }          
          else if(key.indexOf('projects') !== -1 && stringifiedSchema.indexOf(key.replace('projects.','').split('.')[0]) >= 0){                        
            if(item === 'project') stepKey = item  
          }                              
        }
      }
    }
    return stepKey
  } 

  static mapErrorToStepKey = (errors, schema) => {    
    const stepErrorMapping = {}
    let stepKey
    if(errors && Array.isArray(errors)){
      errors.forEach(item => {
        if(typeof item === 'object'){
          Object.keys(item).forEach(key => {        
            stepKey = this.getStepDetailsfromKey(key, schema)
            if(stepKey){
              if(stepErrorMapping.hasOwnProperty(stepKey)){
                stepErrorMapping[stepKey].push(key)
              }
              else{
                stepErrorMapping[stepKey] = [ key ]
              }
            }
          })
        }
        else if(typeof item === 'string'){
          stepKey = this.getStepDetailsfromKey(item, schema)
          if(stepKey){
            if(stepErrorMapping.hasOwnProperty(stepKey)){
              stepErrorMapping[stepKey].push(item)
            }
            else{
              stepErrorMapping[stepKey] = [ item ]
            }
          }
        }
      })    
      return stepErrorMapping
    }
    else{
      return false
    }
  }
}