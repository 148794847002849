import React, { Component } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import Helpers from '../../utils/helpers';

import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';

class GalleryRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
      modelOpen: null,
      currentModelImage: 0,
    };
  }

  openModal = (modal, i) => {
    this.setState({modelOpen: modal, currentModelImage: i});
	}

	closeModal = () => {
    this.setState({modelOpen: null, currentModelImage: 0});
	}

  switchView = (view) => {
    this.setState({ view: view });
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if(val === '' && key[1] !== 'video') {
      return this.na;
    }
    return val;
  }

  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if(to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if(to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if(to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if(to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }

  render() {
    const { children, tabs } = this.props;
    const { view, currentModelImage, modelOpen } = this.state;
    let index = 0;
    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">Gallery</h3>
                <div className="heading-bar-btn">
                  {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab => {
                    if (tab === view) {
                      return (
                        <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                      );
                    }
                    return (
                      <button type="button" className="btn btn-outline-secondary btn-right" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                    );
                  })}
                </div>
              </div>
              <div className="gallery-wrapper">
                <div className="mr-t-20 mr-b-20">
                  {(this.getInputData(['nonprofit', 'gallery']) === this.na || this.getInputData(['nonprofit', 'gallery']).length === 0) ? (
                    <h5 className="txt-center"><i>Nothing here, Please click on edit and add images or videos</i></h5>
                  ) : null}
                </div>
                <ApprovalsRejectionsButtons path={['nonprofit', 'gallery']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                  <div>
                    <ModalGateway>
                      {modelOpen === 'gallery' ? (
                        <Modal onClose={this.closeModal}>
                          <Carousel views={this.getInputData(['nonprofit', 'gallery']).filter(i => i.media_type === 'gallery_image').map(i => ({ src: i.uri }))} currentIndex={currentModelImage} />
                        </Modal>
                      ) : null}
                    </ModalGateway>
                    <div className="gl-list">
                      {(this.getInputData(['nonprofit', 'gallery']) !== this.na && this.getInputData(['nonprofit', 'gallery']).length > 0) ? this.getInputData(['nonprofit', 'gallery']).map((d, i) => {
                        if (d.media_type === 'gallery_image') {
                          const temp_val = index;
                          index++;
                          return (
                            <div className="gl-item" key={d.uri}>
                              <div className="img-wrapper" onClick={(e) => { e.preventDefault(); this.openModal("gallery", temp_val); }}>
                                <img src={d.uri} alt="" />
                              </div>
                              <p>{d.label}</p>
                            </div>
                          );
                        }
                      }) : null}
                    </div>
                    <div className="gl-list">
                      {(this.getInputData(['nonprofit', 'gallery']) !== this.na && this.getInputData(['nonprofit', 'gallery']).length > 0) ? this.getInputData(['nonprofit', 'gallery']).map((d, i) => (
                        (d.media_type === 'gallery_video') ? (
                          <div className="gl-item" key={d.uri}>
                            <div className="img-wrapper">
                              <iframe width="176" height="100" src={d.uri} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                              {/* <Icon path={mdiPlayCircleOutline} className="menu-arrow" size={1.5} color="#c4302b" /> */}
                            </div>
                            <p>{d.label}</p>
                          </div>
                        ) : null
                      )) : null}
                    </div>
                  </div>
                </ApprovalsRejectionsButtons>
              </div>
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GalleryRead;
