import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Icon from '@mdi/react';
import { mdiChevronRight, mdiLoading, mdiCancel, mdiFloppy } from '@mdi/js';
import Helpers from "../../../utils/helpers";
import * as projectApprovalsItemAction from '../../../reducers/AdminApprovals/projectApprovalsItemReducer';
import * as ConfigAction from '../../../reducers/configReducer';

import './ProjectApprovalsItem.css';

import Loading from '../../../appComponents/Loading';
import NonprofitInfoRead from '../../../components/NonprofitInfoRead';
import ProjectOnboardingRead from '../../../components/ProjectOnboardingRead';
import ProjectOnboardingEdit from '../../../components/ProjectOnboardingEdit';
import { Redirect } from 'react-router-dom';


class ProjectApprovalsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readEdit: {
        project: false,
      },
      project: {},
      prevProject: {},
      rejecting: false,
      approvals: []
    };
  }

  componentDidMount() {
    const { Projects, projects, match, config, Config } = this.props;
    const { id } = match.params;
    if (!projects.get_status.project) {
      Projects.getProject(id);
    }
    if (!config.get_status.config) {
      Config.get();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.projects.project !== state.prevProject) {
      setData.prevProject = props.projects.project;
      setData.project = { ...props.projects.project };
      setData.project = Helpers.createNonprofitWithDiff(props.projects.project);
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  componentWillUnmount() {
    const { Projects } = this.props;
    Projects.reset();
  }

  onProjectSave = () => {
    const { Projects } = this.props;
    const { project } = this.state;
    const requestedProject = { ...project };
    delete requestedProject.status;
    return Projects.setProject(requestedProject).then(() => {
      this.changeReadOrEditForm('project', false);
    });
  }

  onProjectChange = (project) => {
    this.setState({ project });
  }

  changeReadOrEditForm = (who, to) => {
    const { readEdit } = this.state;
    const value = { ...readEdit, [who]: to };
    this.setState({ readEdit: value });
  }

  buttonListHTML = (component) => {
    const { projects } = this.props;
    const { rejecting, project } = this.state;
    if (rejecting) {
      return null;
    }
    switch (component) {
      case 'ProjectOnboardingRead':
          return (
            <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
              <button type="button" className="btn btn-success mr-2" disabled={project?.edits && project?.edits?.length >= 0} title={""} onClick={(e) => { e.preventDefault(); this.changeReadOrEditForm('project', true); }}>
                  <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                  &nbsp;Edit
                </button>
            </div>
          );
      case 'ProjectOnboardingEdit':
          return (
            <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
              <button type="submit" className="btn btn-primary mr-2">
                {(projects.set_status.project === 'pending') ? (
                  <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                ) : (
                  <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
                )}
                &nbsp;Save
              </button>
              <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.changeReadOrEditForm('project', false); }}>
                <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                &nbsp;Cancel
              </button>
            </div>
          );
      default:
        return null;
    }
  }

  formHTML = () => {
    const { readEdit, project } = this.state;
    const { projects, config } = this.props;
    if (readEdit.project === false) {
      return (
        <ProjectOnboardingRead value={projects.project} tabs={{ Draft: project}} errors={projects.errors.project}>
          {this.buttonListHTML('ProjectOnboardingRead')}
        </ProjectOnboardingRead>
      );
    }
    if (readEdit.project === true) {
      return (
        <ProjectOnboardingEdit value={project} errors={projects.errors.project} onSubmit={this.onProjectSave} onChange={this.onProjectChange} causes={config.causes || []}>
          {this.buttonListHTML('ProjectOnboardingEdit')}
        </ProjectOnboardingEdit>
      );
    }
    return null;
  }

  approvalHTML = () => {
    const { rejecting } = this.state;
    const { match, projects } = this.props;
    if (projects.project.status === 'published') {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right">
          <button type="button" className="btn btn-secondary mr-2">
            <Icon path={mdiCancel} className="menu-arrow" size={0.75} color="#333333" /> Approved
          </button>
        </div>
      );
    } else if (projects.project.status === 'pending') {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right">
          <button type="button" className="btn btn-danger mr-2">
            <Icon path={mdiCancel} className="menu-arrow" size={0.75} color="#333333" /> Rejected
          </button>
        </div>
      );
    } else if (projects.project.status !== 'final_approval') {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right">
          <button type="button" className="btn btn-secondary mr-2">
            <Icon path={mdiCancel} className="menu-arrow" size={0.75} color="#333333" /> Can't be Approved, Please visit Pending Approvals page
          </button>
        </div>
      );
    } else if (rejecting) {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right">
          <button type="button" className="btn btn-danger mr-2" onClick={this.rejectClick}>
          {(projects.set_status.project === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            )}
            &nbsp;Reject
          </button>
          <button type="button" className="btn btn-secondary" onClick={(e) => { e.preventDefault(); this.showHideRejectComponent(false); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.60} color="#333333" /> Cancel
          </button>
        </div>
      );
    } else {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right">
          <button type="button" className="btn btn-success mr-2" onClick={this.approveClick}>
            {(projects.set_status.project === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            )}
            &nbsp;Approve
          </button>
          <button type="button" className="btn btn-danger" onClick={(e) => { e.preventDefault(); this.showHideRejectComponent(true); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.60} color="#ffffff" /> Reject
          </button>
        </div>
      );
    }
  }

  approveClick = (e) => {
    e.preventDefault();
    const { projects, Projects } = this.props;
    const nextStatus = 'published';
    const { project } = this.state;
    if (project.edits && project.edits.length >= 0) {
      let approveIndexes;
      approveIndexes = project?.edits?.map((item, index) => index)
      Projects.setProjectAP(project._id, { approve: approveIndexes }).then((res) => {
        if (res) {
          Projects.setProject({ status: nextStatus, _id: projects.project._id });
        }
      }).catch((err) => {
        console.log(err, 'error')
      })
    } else {
      Projects.setProject({ status: nextStatus, _id: projects.project._id });
    }
  }

  rejectClick = (e) => {
    e.preventDefault();
    const { Projects, projects } = this.props;
    const { project } = this.state;
    const nextStatus = 'pending';
    Projects.setProject({ status: nextStatus, _id: projects.project._id }).then(() => {
      this.changeReadOrEditForm('project', false);
    });
  }

  showHideRejectComponent = (value) => {
    this.setState({ rejecting: value, readEdit: { project: value } });
  }

  render() {
    const { projects } = this.props;
    if (!projects.get_status.project || projects.get_status.project === 'pending') {
      return (<Loading />);
    }
    if (!projects.get_status.project || projects.get_status.project === 'failed') {
      return (<Redirect to="/404"/>);
    }
    return (
      <div className="content-wrapper">
        <NonprofitInfoRead value={projects.project.nonprofit} />
        {this.formHTML()}
        <div className="row justify-content-md-center">
          <div className="col-md-12 d-flex align-items-stretch">
              <div className="row flex-grow">
                {this.approvalHTML()}
              </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projectApprovalsItem,
  config: state.config,
});

const mapActionToProps = dispatch => ({
  Projects: bindActionCreators(projectApprovalsItemAction, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(ProjectApprovalsItem);
