import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loading from '../Loading';

import * as LoginAction from '../../reducers/loginReducer';
import * as ConfigAction from '../../reducers/configReducer';
import UserUpdate from '../UserUpdate/UserUpdate';

class PrivateRouteComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    const { login, Login, privateRoute, PrivateRoute } = this.props;
    if (login.user_is_valid === null) {
      Login.get();
    }
  }

  componentDidUpdate = () => {
    const { login, Login, privateRoute, PrivateRoute } = this.props;
    if (login.user_is_valid === null && login.loading === false) {
      Login.get();
    }
  }

  render() {
    const {
      component: Private,
      login,
      location
    } = this.props;
    if (login.user_is_valid === null) {
      return (<Loading />);
    }
    if (login.user_is_valid === true) {
      return (<Private />);
    }
    return (
      <Redirect
        to={{
          pathname: '/login',
          from: location.pathname,
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
  config: state.config,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(PrivateRouteComponent);
