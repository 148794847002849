import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';
import ProjectService from '../../services/project-service';
import CampaignService from '../../services/campaign-service';
import DonationService from '../../services/donation-service';

export const GET_FILTER_PG_LIST = 'GET_FILTER_PG_LIST';
export const GET_FILTER_PG_LIST_SUCCESS = 'GET_FILTER_PG_LIST_SUCCESS';
export const GET_FILTER_PG_LIST_FAIL = 'GET_FILTER_PG_LIST_FAIL';
export const GET_FILTER_SERVICE = 'GET_FILTER_SERVICE';
export const GET_FILTER_SERVICE_SUCCESS = 'GET_FILTER_SERVICE_SUCCESS';
export const GET_FILTER_SERVICE_FAIL = 'GET_FILTER_SERVICE_FAIL';
export const GET_FILTER_SERVICE_RESET = 'GET_FILTER_SERVICE_RESET';
export const GET_FILTER_RESET = 'GET_FILTER_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  services: [],
  pg: {},
  get_status: {},
};

export const filterByReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_FILTER_PG_LIST:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pg: 'pending',
        },
      };
    case GET_FILTER_PG_LIST_SUCCESS:
      return {
        ...state,
        pg: action.pg,
        get_status: {
          ...state.get_status,
          pg: 'success',
        },
      };
    case GET_FILTER_PG_LIST_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pg: 'failed',
        },
      };
    case GET_FILTER_SERVICE_RESET:
      return {
        ...state,
        services: [],
        get_status: {},
      };
    case GET_FILTER_SERVICE:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          service: 'pending',
        },
      };
    case GET_FILTER_SERVICE_SUCCESS:
      return {
        ...state,
        services: action.services,
        get_status: {
          ...state.get_status,
          service: 'success',
        },
      };
    case GET_FILTER_SERVICE_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          service: 'failed',
        },
      };
    case GET_FILTER_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getPG() {
  return (dispatch) => {
    dispatch({
      type: GET_FILTER_PG_LIST,
    });
    return DonationService.get({ path: ['payment-entities'] })
      .then((response) => {
        dispatch({
          type: GET_FILTER_PG_LIST_SUCCESS,
          pg: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_FILTER_PG_LIST_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getService(service, query) {
  return (dispatch) => {
    dispatch({
      type: GET_FILTER_SERVICE,
    });
    let Service = null;
    const params = {
      ...query,
      limit: 20,
      sort: '-updated_at',
    };
    if (service === 'nonprofits') {
      Service = NonProfitService;
    }
    if (service === 'projects') {
      Service = ProjectService;
    }
    if (service === 'campaigns') {
      Service = CampaignService;
      params.scope = 'global';
    }

    return Service.get({ params })
      .then((response) => {
        dispatch({
          type: GET_FILTER_SERVICE_SUCCESS,
          services: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_FILTER_SERVICE_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function resetService() {
  return (dispatch) => {
    dispatch({
      type: GET_FILTER_SERVICE_RESET,
    });
  }
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: GET_FILTER_RESET,
    });
  };
}
