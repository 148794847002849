import React from 'react';

function Loading(props) {
  return (
    <div className="col-md-12">
      {props.message? ( <h5 className="text-center">{props.message}</h5> ): null}
      <div className="dot-opacity-loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
export default Loading;
