/*
 * API service function
 * responsible for communication to server
 * endpont: object of config
 * array_data : headers
 * array_data : auth
 * array_data : path
 * array_data : params
 * array_data : method
 * array_data : data
 */

// import fetch from 'isomorphic-fetch';
import _Error from './_error';
import Storage from './storage';
import axios from 'axios'
import { endpoints } from '../config';

/**
 * 
 * @method refreshToken: Generates Refresh token if the request returns and unauthorized response
 * @class API: 
 * * @method request: API request handler
**/

const refreshToken = () => {
  return new Promise((resolve, reject) => {
    const token = Storage.getItem('token')
    axios({
      url: `${endpoints.user.url}/me`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      },
      withCredentials: true
    })
      .then(response => {
        Storage.setItem('token', response.data?.data?.token)
        resolve(response.data?.data?.token)
      })
      .catch(e => {
        reject(e)
      })
  })
}
class API {
  static request(endpoint, array_data = {}, credentials = false) {
    let headers = {};
    if (array_data.headers) {
      headers = array_data.headers;
    }

    if (!headers['Content-Type'] && !(array_data.data && array_data.data instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }

    if (!headers.Accept) {
      headers.Accept = 'application/json';
    }

    let url = (array_data.path) ? endpoint.url + array_data.path.join('/') : endpoint.url;

    // TODO Replace this with a standard querystring encoder like qs package
    if (array_data.params !== null && array_data.params !== undefined && Object.keys(array_data.params).length !== 0) {
      let params;
      Object.keys(array_data.params).forEach((key) => {
        let pair;
        if (array_data.params[key] === undefined) {
          pair = `${key}`;
        } else {
          if (Array.isArray(array_data.params[key])) {
            pair = '';
            array_data.params[key].forEach(i => {
              if (i.op) {
                pair = `${pair}&${key}${i.op}${encodeURIComponent(i.value)}`;
              } else {
                pair = `${pair}&${key}[]=${encodeURIComponent(i)}`;
              }
            });
            //remove first char in pair
            pair = pair.substring(1);
          } else {
            if (typeof array_data.params[key] === 'object') {
              pair = `${key}${array_data.params[key].op}${encodeURIComponent(array_data.params[key].value)}`;
            } else {
              pair = `${key}=${array_data.params[key]}`;
            }
          }
        }
        if (params === undefined) {
          params = pair;
        } else {
          params = `${params}&${pair}`;
        }
      });
      url = encodeURI(`${url}?${params}`);
      if (url.includes('%5B')) {
        url = url.replace(/%5B/g, '').replace(/%5D/g, '');
      }
    }
    let method = 'get';
    if (array_data.method !== undefined) {
      method = array_data.method;
    } else if (array_data.data !== undefined) {
      method = 'post';
    } else {
      method = 'get';
    }

    const req = {
      method,
      headers: headers
    };
    if (array_data.data) {
      req.data = array_data.data;
    }

    const axiosAPIInterface = axios.create()

    axiosAPIInterface.interceptors.request.use(config => {
      const token = Storage.getItem('token')
      const isAuthRequired = (array_data.auth !== undefined) ? array_data.auth : endpoint.auth
      if (token && isAuthRequired) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config
    }, e => {
      Promise.reject(e)
    })

    axiosAPIInterface.interceptors.response
      .use(response => {
        return response
      },
        async (error) => {
          const originalRequest = error.config
          // If request returns an unauthorised response, return retry the request after generating the new Refresh Token
          if (error.response.status === 404) {
            throw new _Error('', { location: '/not-found' });
          }

          if ((error.response.status === 401) && !originalRequest._retry) {
            originalRequest._retry = true
            try {
              // Generate new Refresh Token 
              const access_token = await refreshToken();
              // Pass the generated token as the bearer token for the retry of the request
              originalRequest.headers['Authorization'] = `Bearer ${access_token}`
              return axiosAPIInterface(originalRequest)
            }
            catch (e) {
              throw new _Error(e.response?.data || { error: 'Not Authorized' })
            }
          }
          else {
            throw new _Error(error.response?.data)
          }
        })

    return axiosAPIInterface({
      url: url,
      ...req,
      withCredentials: credentials
    })
      .then(response => {
        if (response?.data) {
          return response.data
        }
      })
      .catch(e => {
        if (e.message) {
          if (e.message.errors) {
            throw new _Error('', { errors: e.message.errors })
          }
          else if (e.message.error) {
            throw new _Error('', { error: e.message.error })
          }
          throw new _Error(e.message?.error || e.message, e.message)
        }

        if (e.errors) {
          throw new _Error('', e.errors)
        }
        throw new _Error()
      })
  }
}

export default API;
