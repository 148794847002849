import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';
import ProjectService from '../../services/project-service';

export const GET_APPROVAL_NONPROFIT = 'GET_APPROVAL_NONPROFIT';
export const GET_APPROVAL_NONPROFIT_SUCCESS = 'GET_APPROVAL_NONPROFIT_SUCCESS';
export const GET_APPROVAL_NONPROFIT_FAIL = 'GET_APPROVAL_NONPROFIT_FAIL';

export const SET_APPROVAL_NONPROFIT = 'SET_APPROVAL_NONPROFIT';
export const SET_APPROVAL_NONPROFIT_SUCCESS = 'SET_APPROVAL_NONPROFIT_SUCCESS';
export const SET_APPROVAL_NONPROFIT_FAIL = 'SET_APPROVAL_NONPROFIT_FAIL';


export const SET_NONPROFIT_EDITS = 'SET_NONPROFIT_EDITS';
export const SET_NONPROFIT_EDITS_SUCCESS = 'SET_NONPROFIT_EDITS_SUCCESS';
export const SET_NONPROFIT_EDITS_FAIL = 'SET_NONPROFIT_EDITS_FAIL';


export const SET_PROJECT_EDITS = 'SET_PROJECT_EDITS';
export const SET_PROJECT_EDITS_SUCCESS = 'SET_PROJECT_EDITS_SUCCESS';
export const SET_PROJECT_EDITS_FAIL = 'SET_PROJECT_EDITS_FAIL';

// Schema Form Flags
export const GET_SCHEMA = 'GET_SCHEMA'
export const GET_SCHEMA_SUCCESS = 'GET_SCHEMA_SUCCESS'
export const GET_SCHEMA_FAIL = 'GET_SCHEMA_FAIL'

export const GET_APPROVAL_PROJECT = 'GET_APPROVAL_PROJECT';
export const GET_APPROVAL_PROJECT_SUCCESS = 'GET_APPROVAL_PROJECT_SUCCESS';
export const GET_APPROVAL_PROJECT_FAIL = 'GET_APPROVAL_PROJECT_FAIL';

export const SET_APPROVAL_PROJECT = 'SET_APPROVAL_PROJECT';
export const SET_APPROVAL_PROJECT_SUCCESS = 'SET_APPROVAL_PROJECT_SUCCESS';
export const SET_APPROVAL_PROJECT_FAIL = 'SET_APPROVAL_PROJECT_FAIL';

export const REJECT_NONPROFIT = 'REJECT_NONPROFIT';
export const REJECT_NONPROFIT_SUCCESS = 'REJECT_NONPROFIT_SUCCESS';
export const REJECT_NONPROFIT_FAIL = 'REJECT_NONPROFIT_FAIL';

export const APPROVAL_RESET = 'APPROVAL_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  nonprofit: {},
  project: {},
  get_status: {},
  set_status: {},
  errors: {},
};

export const pendingApprovalsItemReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_APPROVAL_NONPROFIT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };
    case GET_APPROVAL_NONPROFIT_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
      };
    case GET_APPROVAL_NONPROFIT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
      };
    case SET_APPROVAL_NONPROFIT:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case SET_APPROVAL_NONPROFIT_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        set_status: {
          ...state.set_status,
          nonprofit: 'success',
        },
      };
    case SET_APPROVAL_NONPROFIT_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors,
        },
      };
    case SET_NONPROFIT_EDITS:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case SET_NONPROFIT_EDITS_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        set_status: {
          ...state.set_status,
          nonprofit: 'success',
        },
      };
    case SET_NONPROFIT_EDITS_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors,
        },
      };
    case GET_APPROVAL_PROJECT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'pending',
        },
      };
    case GET_APPROVAL_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        get_status: {
          ...state.get_status,
          project: 'success',
        },
      };
    case GET_APPROVAL_PROJECT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'failed',
        },
      };
    case GET_SCHEMA:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'pending',
        },
      };  
    case GET_SCHEMA_SUCCESS:      
      return {
        ...state,
        schema: action.schema,
        get_status: {
          ...state.get_status,
          schema: 'success',
        },
        load_status: {
          ...state.load_status,
          schema: action.load_status,
        }
      };
    case GET_SCHEMA_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'failed',
        },
        errors: {
          ...state.errors,
          schema: action.errors
        }
      };
    case SET_APPROVAL_PROJECT:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'pending',
        },
        errors: {
          ...state.errors,
          project: {},
        },
      };

    case SET_APPROVAL_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        set_status: {
          ...state.set_status,
          project: 'success',
        },
      };
    case SET_APPROVAL_PROJECT_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'failed',
        },
        errors: {
          ...state.errors,
          project: action.errors,
        },
      };
    case SET_PROJECT_EDITS:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'pending',
        },
        errors: {
          ...state.errors,
          project: {},
        },
      };

    case SET_PROJECT_EDITS_SUCCESS:
      return {
        ...state,
        project: action.project,
        set_status: {
          ...state.set_status,
          project: 'success',
        },
      };
    case SET_PROJECT_EDITS_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'failed',
        },
        errors: {
          ...state.errors,
          project: action.errors,
        },
      };
    case APPROVAL_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getNonprofit(request) {
  return (dispatch) => {
    dispatch({
      type: GET_APPROVAL_NONPROFIT,
    });
    return NonProfitService.get(request)
      .then((response) => {
        dispatch({
          type: GET_APPROVAL_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_APPROVAL_NONPROFIT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function setNonprofit(data, method = 'put') {
  return (dispatch) => {
    dispatch({
      type: SET_APPROVAL_NONPROFIT,
    });
    return NonProfitService.set({ data, method: method, path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_APPROVAL_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_APPROVAL_NONPROFIT_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));          
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_APPROVAL_NONPROFIT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));          
        } else {
          dispatch({
            type: SET_APPROVAL_NONPROFIT_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));        
      });
  };
}

export function getProject(request) {
  return (dispatch) => {
    dispatch({
      type: GET_APPROVAL_PROJECT,
    });
    return ProjectService.get(request)
      .then((response) => {
        const projects = response.filter((item) => {
          if (item.status === 'final_approval' || item.status === 'pending') {
            return true;
          }
          return false;
        });
        if (projects.length >= 1) {
          dispatch({
            type: GET_APPROVAL_PROJECT_SUCCESS,
            project: projects[0],
          });
          return response;
        } else {
          dispatch({
            type: GET_APPROVAL_PROJECT_FAIL,
          });
          return Promise.reject(new _Error());
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_APPROVAL_PROJECT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function setProject(data) {
  return (dispatch) => {
    dispatch({
      type: SET_APPROVAL_PROJECT,
    });
    return ProjectService.set({ data, method: 'put', path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_APPROVAL_PROJECT_SUCCESS,
          project: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_APPROVAL_PROJECT_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Project form'));
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_APPROVAL_PROJECT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
        } else {
          dispatch({
            type: SET_APPROVAL_PROJECT_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getFormSchema(request){  
  return (dispatch) => {
    dispatch({
      type: GET_SCHEMA
    })
    return NonProfitService.getSchema(request)
      .then(response => {                
        dispatch({
          type: GET_SCHEMA_SUCCESS,          
          schema: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_SCHEMA_FAIL,
          errors: error
        })
        dispatch(NotificationAction.addNotification(error.message));
      })              
  }
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: APPROVAL_RESET,
    });
  };
}

export function rejectNonprofit(data) {
  return (dispatch) => {
    dispatch({
      type: REJECT_NONPROFIT,
    });
    return NonProfitService.reject({ path: [data._id, 'reject'], data, method: 'post' })
      .then((response) => {
        dispatch({
          type: REJECT_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Rejected successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: REJECT_NONPROFIT_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));          
        } else if (error.value && error.value.error) {
          dispatch({
            type: REJECT_NONPROFIT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));          
        } else {
          dispatch({
            type: REJECT_NONPROFIT_FAIL,
            errors: {},
          });
        }       
      });
  };
}

export function setNonprofitWithEdits(id, data) {
  return (dispatch) => {
    dispatch({
      type: SET_NONPROFIT_EDITS,
    });
    const request = {};
    request.method = 'put';
    request.path = [id, 'apply-edits'];
    request.data = data;
    return NonProfitService.set(request)
      .then((response) => {
        dispatch({
          type: SET_NONPROFIT_EDITS_SUCCESS,
          nonprofit: response.data,
        });        
        return response.data;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_NONPROFIT_EDITS_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));          
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_NONPROFIT_EDITS_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));          
        } else {
          dispatch({
            type: SET_NONPROFIT_EDITS_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));        
      });
  };
}

export function setProjectWithEdits(id, data) {
  return (dispatch) => {
    dispatch({
      type: SET_PROJECT_EDITS,
    });
    const request = {};
    request.method = 'put';
    request.path = [id, 'apply-edits'];
    request.data = data;
    return ProjectService.set(request)
      .then((response) => {
        dispatch({
          type: SET_PROJECT_EDITS_SUCCESS,
          project: response.data,
        });        
        return response.data;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_PROJECT_EDITS_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Project form'));          
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_PROJECT_EDITS_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));          
        } else {
          dispatch({
            type: SET_PROJECT_EDITS_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));        
      });
  };
}