/**
 * Service function to make API call
 */

import _Error from '../utils/_error';
import API from '../utils/api';
import { endpoints } from '../config';
// import Helpers from '../utils/helpers';


export default class ReportSubscriptionService {
  static requestAPI(endpoint = endpoints.subscriptionsReports, request = {}) {
    return API.request(endpoint, request);
  }
  static get(request) {
    return this.requestAPI(undefined, request);
  }
}
