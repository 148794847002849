import React, { Component } from 'react';

import Icon from '@mdi/react';
import { mdiPlus, mdiTrashCanOutline } from '@mdi/js';

import PhoneInput from '../PhoneInput';
import SelectInput from '../SelectInput';

import Helpers from '../../utils/helpers';
import { requiredComplianceOptions, configCategoryCodeOptions, priorityOptions } from '../../config';
import AdminEntityTagWidget from '../../productComponents/Admin/AdminEntityTagWidget/AdminEntityTagWidget';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
class NonprofitAdminSettingsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: { _id: props.value._id },
      isProvideCertificate10BE: props?.value?.can_provide_10be || false,
    };
  }



  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit, login } = this.props;
    const { changes, isProvideCertificate10BE } = this.state;
    const payloadWith10BE = { ...changes, can_provide_10be: !login?.user?.is_admin ? undefined : isProvideCertificate10BE }
    e.preventDefault();
    if (onSubmit) {
      onSubmit(payloadWith10BE);
    }
  }

  setTagSelect = (tag) => {
    const tags = this.getInputData(['nonprofit', 'tags']);
    this.handelInputChange(['nonprofit', 'tags', tags.length], tag.value);
  }

  setRequiredComplianceSelect = (compliance) => {
    const required_compliances = this.getInputData(['nonprofit', 'required_compliances']);
    this.handelInputChange(['nonprofit', 'required_compliances', required_compliances.length], compliance.value);
  }

  setCategoryCodeOptionsSelect = (option) => {
    const nteecc_category_codes = this.getInputData(['nonprofit', 'nteecc_category_codes']);
    this.handelInputChange(['nonprofit', 'nteecc_category_codes', nteecc_category_codes.length], option.value);
  }

  setPriorityOptionsSelect = (option) => {
    this.handelInputChange(['nonprofit', 'priority'], option.value);
  }

  categoryCodeToDescription = (value) => {
    const index = Helpers.objSearch(configCategoryCodeOptions, value, 'value');
    if (index > -1) {
      return configCategoryCodeOptions[index].label;
    }
    return value;
  }

  getPriorityOptionsSelect = (option) => {
    const data = this.getInputData(['nonprofit', 'priority']);
    return { label: data, value: data };
  }

  removeMember = (index) => {
    this.handelInputChange(['nonprofit', 'members', index], null);
  }

  addMember = () => {
    const members = this.getInputData(['nonprofit', 'members']);
    const member = {
      designation: '',
      is_primary_contact: true,
      name: '',
    };
    this.handelInputChange(['nonprofit', 'members', members.length], member);
  }

  removeCategoryCodeOptions = (index) => {
    this.handelInputChange(['nonprofit', 'nteecc_category_codes', index], null);
  }

  removeTag = (index) => {
    this.handelInputChange(['nonprofit', 'tags', index], null);
  }

  removeRequiredComplianceOptions = (index) => {
    this.handelInputChange(['nonprofit', 'required_compliances', index], null);
  }

  removeApprovedComplianceOptions = (index) => {
    this.handelInputChange(['nonprofit', 'approved_compliances', index], null);
  }

  onArchive = () => {
    const isPublished = this.getInputData([null, 'is_published'])
    const status = this.getInputData([null, 'status'])
    if (isPublished) {
      this.handelInputChange([null, 'status'], 'unpublished')

    } else if (status === 'unpublished') {
      this.handelInputChange([null, 'status'], 'pending')
    }
  }

  onPublish = () => {
    //TODO update un published to published
    this.handelInputChange([null, 'status'], 'published')

  }
  on10BEValidChange = (e) => {
    this.setState({ isProvideCertificate10BE: e.target.checked })
  }

  render() {
    const { children, config, event, value, login } = this.props;
    const { country_code } = config.defaults || {};
    const { isProvideCertificate10BE } = this.state
    const configTags = event.data.map(i => i.event_id);
    const tagsOptions = configTags.filter(i => (!this.getInputData(['nonprofit', 'tags']).includes(i))).map(i => ({ label: i, value: i }));
    const categoryCodeOptions = configCategoryCodeOptions.filter(i => (!this.getInputData(['nonprofit', 'nteecc_category_codes']).includes(i.value)));
    const required_compliances_after_filter = requiredComplianceOptions.filter(i => (!this.getInputData(['nonprofit', 'required_compliances']).includes(i.value)));
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">Nonprofit Settings for Admin</h3>
                  {this.getInputData(['value', 'status']) != 'pending' && this.getInputData(['value', 'status']) != 'final_approval' ?
                    <button type="button" className="btn btn-primary mr-2" style={{ height: 'fit-content' }} onClick={(e) => { e.preventDefault(); this.onArchive() }}>
                      &nbsp; {this.getInputData(['value', 'status']) === 'published' ? 'Archive this NGO' : 'Move to pending'}
                    </button>
                    : null
                  }
                  {
                    this.props.value && this.props.value.status && this.props.value.status === 'unpublished' ?
                      <button type="button" className='btn btn-primary mr-2' style={{ height: 'fit-content' }} onClick={(e) => { e.preventDefault(); this.onPublish() }}>
                        Publish Non-profit
                      </button>
                      : null
                  }

                </div>
                {/* <AdminEntityTagWidget entity_id={value?._id} entity_type="nonprofits" /> */}
                <div className="row mr-b-20">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>Auto receipt<i> (Only works for non-4Limb Nonprofits)</i></label>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="auto_receipt" value="yes" checked={this.getInputData(['nonprofit', 'auto_receipt']) === true} onChange={(e) => { this.handelInputChange(['nonprofit', 'auto_receipt'], e); }} /> Yes <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="auto_receipt" value="no" checked={this.getInputData(['nonprofit', 'auto_receipt']) === false} onChange={(e) => { this.handelInputChange(['nonprofit', 'auto_receipt'], e); }} /> No <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>Nonprofit Compliance</label>
                      <div className="tags-wrapper">
                        {this.getInputData(['nonprofit', 'required_compliances']) && this.getInputData(['nonprofit', 'required_compliances']).length > 0 ? (
                          this.getInputData(['nonprofit', 'required_compliances']).map((i, index) => (
                            <label key={i} className="badge badge-primary" onClick={(e) => { e.preventDefault(); this.removeRequiredComplianceOptions(index) }}>{requiredComplianceOptions[Helpers.objSearch(requiredComplianceOptions, i, 'value')].label}</label>
                          ))
                        ) : this.getInputData(['nonprofit', 'required_compliances'])}
                      </div>
                      <SelectInput options={required_compliances_after_filter} onChange={this.setRequiredComplianceSelect} />
                    </div>
                    <div className="form-group">
                      <label>Nonprofit Approved Compliance</label>
                      <div className="tags-wrapper">
                        {this.getInputData(['nonprofit', 'approved_compliances']) && this.getInputData(['nonprofit', 'approved_compliances']).length > 0 ? (
                          this.getInputData(['nonprofit', 'approved_compliances']).map((i, index) => (
                            <label key={i.id} className="badge badge-primary" onClick={(e) => { e.preventDefault(); this.removeApprovedComplianceOptions(index) }}>{requiredComplianceOptions[Helpers.objSearch(requiredComplianceOptions, i.id, 'value')].label}</label>
                          ))
                        ) : this.getInputData(['nonprofit', 'approved_compliances'])}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {this.getInputData(['nonprofit', 'required_compliances']).includes("fbassured") ? (
                      <div>
                        <div className="form-group">
                          <label>Category code</label>
                          {this.getInputErrors(['nonprofit', 'nteecc_category_codes'])}
                          <div className="tags-wrapper">
                            {this.getInputData(['nonprofit', 'nteecc_category_codes']) && this.getInputData(['nonprofit', 'nteecc_category_codes']).length > 0 ? (
                              this.getInputData(['nonprofit', 'nteecc_category_codes']).map((i, index) => (
                                <label key={i} className="badge badge-primary" onClick={(e) => { e.preventDefault(); this.removeCategoryCodeOptions(index) }}>{this.categoryCodeToDescription(i)}</label>
                              ))
                            ) : this.getInputData(['nonprofit', 'nteecc_category_codes'])}
                          </div>
                          <SelectInput options={categoryCodeOptions} onChange={this.setCategoryCodeOptionsSelect} />
                        </div>
                        <div className="form-group">
                          <label>Priority</label>
                          {this.getInputErrors(['nonprofit', 'priority'])}
                          <SelectInput options={priorityOptions} onChange={this.setPriorityOptionsSelect} defaultValue={this.getPriorityOptionsSelect()} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {login.user?.is_admin && <div className="">
                    <div className="form-group" style={{ display: "flex" }}>
                      {this.getInputErrors(['nonprofit', '10be'])}
                      <input type="checkbox" name="isProvideCertificate10BE" checked={isProvideCertificate10BE} value={this.getInputData(['nonprofit', '10be'])} onChange={(e) => { this.on10BEValidChange(e); }} />
                      <label className="col-md-12 col-form-label">Can give 10BE certificate</label>
                    </div>
                  </div>}
                  {/* <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>NGO-require pan card for donation amount more then</label>
                      <div><i>Feature to be implemented</i></div>
                    </div>
                  </div> */}
                </div>

                <h6>Members</h6>
                <div className="row">
                  <div className="col-lg-2 col-md-12"><p>Name</p></div>
                  <div className="col-lg-2 col-md-12"><p>Designation</p></div>
                  <div className="col-lg-2 col-md-12"><p>Phone</p></div>
                  <div className="col-lg-2 col-md-12"><p>Email</p></div>
                  <div className="col-lg-2 col-md-12"><p>Primary Contact</p></div>
                  <div className="col-lg-2 col-md-12"></div>
                </div>
                {this.getInputData(['nonprofit', 'members']) ? (this.getInputData(['nonprofit', 'members']).map((d, i) => (
                  <div className="row tm-divider" key={i}>
                    <div className="col-lg-2 col-md-12">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Name" value={d.name} onChange={(e) => { this.handelInputChange(['nonprofit', 'members', i, 'name'], e) }} />
                      </div>
                      {this.getInputErrors(['nonprofit', 'members', i, 'name'])}
                    </div>
                    <div className="col-lg-2 col-md-12">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Designation" value={d.designation} onChange={(e) => { this.handelInputChange(['nonprofit', 'members', i, 'designation'], e) }} />
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-12">
                      <div className="form-group">
                        <PhoneInput countrycode={country_code} value={d.phone} onChange={(e) => { this.handelInputChange(['nonprofit', 'members', i, 'phone'], e) }} />
                        {/* <input type="text" className="form-control" placeholder="Phone" value={d.phone} onChange={(e) => { this.handelInputChange(['nonprofit', 'members', i, 'phone'], e) }} /> */}
                      </div>
                      {this.getInputErrors(['nonprofit', 'members', i, 'phone'])}
                    </div>
                    <div className="col-lg-2 col-md-12">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Email" value={d.email} onChange={(e) => { this.handelInputChange(['nonprofit', 'members', i, 'email'], e) }} />
                      </div>
                      {this.getInputErrors(['nonprofit', 'members', i, 'email'])}
                    </div>
                    <div className="col-lg-2 col-md-12">
                      <div className="form-group">
                        <select className="form-control" value={d.is_primary_contact ? 'Yes' : 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'members', i, 'is_primary_contact'], e); }}>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      {this.getInputErrors(['nonprofit', 'members', i, 'is_primary_contact'])}
                    </div>
                    <div className="col-lg-2 col-md-12 tm-remove">
                      <button type="button" className="btn btn-light" onClick={(e) => { e.preventDefault(); this.removeMember(i); }}>
                        <Icon path={mdiTrashCanOutline} className="menu-arrow" size={0.60} color="#333333" />
                        &nbsp;Remove
                      </button>
                    </div>
                  </div>
                ))) : this.getInputData(['nonprofit', 'members'])}
                <div className="row">
                  <div className="col-md-12">
                    <button type="button" className="btn btn-info mr-2" onClick={(e) => { e.preventDefault(); this.addMember(); }}><Icon path={mdiPlus} className="menu-arrow" size={0.60} color="#ffffff" />&nbsp;Add</button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,

});

export default withRouter(connect(mapStateToProps)(NonprofitAdminSettingsEdit));
