import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import './facebookOnboarding.scss';

import FacebookOnboardingNav from './FacebookOnboardingNav'
import FbOnboardPAN from './FacebookOnboardingPanVerification'
import FbOnboardInitialDetails from './FbOnboardInitialDetails';
import FbOnboardRequiredDocuments from './FbOnboardRequiredDocuments';
import FbOnboardDataAvailability from './FbOnboardDataAvailability'
import FbOnboardLogin from './FbOnboardLogin';
import FbOnboardWelcome from './FbOnboardWelcome'
import FbOnboardFinal from './FbOnboardFinal'

import UserDropdown from '../../appComponents/UserDropdown'

import * as HomeActions from '../../reducers/homeReducer';
import * as NonProfitActions from '../../reducers/FacebookOnboarding/onboardingNonProfitItemReducer';
import * as NGOOnboardingHomeAction from '../../reducers/NGOOnboarding/homeReducer';
import * as LoginAction from '../../reducers/loginReducer';

import Helpers from './../../utils/helpers'

import logo from '../../resources/images/giveindia.svg';

// Steps of Registration and Identifiers.
// 'getPan' = Initial Stage, Get PAN Number, and verify
// 'login' = Login to Give Dashboard
// 'initialDetails' = For non-registered Non Profits
// 'requiredDocuments'
// 'dataAvailability'
// 'successOrError'
// 'fbOnboard'


const errorMessage = {
	onFCRA80GError : {
		heading: 'FCRA and 80G are mandatory for Facebook onboarding.', 
		description: '<p>A charity needs to hold a valid FCRA registration and a valid 80G certificate to be onboarded onto Facebook Charitable Giving. Unfortunately we will not be able to go ahead with the onboarding process for now. You can check back with us or apply on the same link once you have both the documents available.</p> <p>You can continue to raise funds on Give.do at 0% retention without the said documents.<p>' ,
		customButton : 'Contact Support',
		mailTo: 'mailto:partnership@give.do'
	},
	on12AError: {		
		heading: '12A is mandatory for Facebook onboarding', 
		description: '<p>A charity needs to hold a valid 12A registration certificate to be onboarded onto Facebook Charitable Giving. Unfortunately we will not be able to go ahead with the onboarding process for now. You can check back with us or apply on the same link once you have the required document available.</p>',		
	},
	nonMemberLogin: { 
		heading: 'POC/Sign-in/Sign-up related issues.', 
		description: '<p>Please write to <a href="mailto:partnership@give.do" target="_blank">partnership@give.do<a> and we will get back to you within 48 hours.',
	},
	onFCRA80GErrorInitial : {
		heading: 'FCRA and 80G are both mandatory for Facebook onboarding', 
		description: '<p>A charity needs to hold a valid FCRA registration and a valid 80G certificate to be onboarded onto Facebook Charitable Giving. Unfortunately we will not be able to go ahead with the onboarding process for now. You can check back with us or apply on the same link once you have both the documents available.</p> <p>You can continue to raise funds on Give.do at 0% retention without the said documents.<p>',
		buttonText: 'Proceed to Give fundraisers Onboarding',
		redirectUrl: '/onboarding'
	},
	onAdminError : {
		heading: 'Admin User', 
		description: 'You are an admin user. You can logout and login again to continue with onboarding flow',
		buttonText: 'Go back to login',
		redirectUrl: '/login'
	}
}
class FacebookOnboarding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataPan : {
				panCard : ''			
			},			
			isEnable : false,
			errorMessage : '',
			isNewNonProfit: false,
			dataInitial : {
				organizationName : '',				
				is12A : '',
				is80G : '',
				isFCRA : ''
			},
			dataDocuments : {
				provideDocuments : '',
				provideDocumentsClarification : ''
			},
			dataAvailability : {
				dataAvailabilitySharing : '',
				dataAvailabilityReceipt : ''
			},
			dataFbOnboard : {
				ngoFbUrl : '',
				signatory_name : '',
				signatory_email : '',
				accept_facebook_terms: false,
				accept_razorpay_terms: false				
			},
			error : {
				dataPanError : [],
				dataInitialError : [],
				dataDocumentsError : [],
				dataAvailabilityError : [],
				dataFbOnboardError : []
			},
			isMember : '',
			step : 'getPan', 
			nonprofit : {},
			userNonProfits : [],			
			isExistingNgo : false,
			finalData : { heading: '', description: '', buttonText: '', redirectUrl: '', customButton: '', mailTo: '' },
			formSteps: {
				getPan: 'PAN Details',				
				initialDetails: 'Verify NON Profit Details',
				login: 'Login',
				requiredDocuments: 'Required Documents',
				dataAvailability: 'Data Availability',
				fbOnboard: 'Final Step',
			},
			redirect_url: '',
			required_compliances : ['fbassured']
		}
	}	

	componentDidMount(){
		if(window) window.scrollTo(0, 0);
    const { login, Login} = this.props		
    if(login.user_is_valid === null){			
      Login.get()
		} 
		this.getParams()	
	}
	
	componentDidUpdate(){
		const {login} = this.props;
		const {step} = this.state;
		if(login.user_is_valid === false && (step === 'requiredDocuments' || step === 'dataAvailability' || step === 'fbOnboard')){
			// If user logs at the point where verification is required, then the user is redirected back to the step for Login 
			this.setData({target: {name: 'step', value: 'login'}}, 'main');
		}
	}

	goToStep = (key) => {
		this.setData({target: {name: 'step', value: key}},'main')
	}
		
	previousStep = () => {
		const { step, nonprofit, dataInitial, isNewNonProfit } = this.state;
		switch(step){
			case 'successOrError' : 
				// this.goToStep('getPan')
				//Recheck
				if(dataInitial.organizationName && isNewNonProfit){					
					this.goToStep('initialDetails')
				}
				else {
					this.goToStep('getPan')
				}
				break;
			case 'initialDetails': 
				this.goToStep('getPan')
				break;
			case 'login' : 
				this.goToStep('getPan')
				break;
			case 'requiredDocuments':
				if(nonprofit && window.location.search){
					this.goToStep('getPan')
				} else if(nonprofit){
					this.goToStep('getPan')
				}
				else{
					this.goToStep('initialDetails')
				}
				break;
			case 'dataAvailability':
				this.goToStep('requiredDocuments')
				break;
			case 'fbOnboard' : 
				this.goToStep('dataAvailability')
				break;
			case 'default':
				// Do Nothing
				break;
		}
	}

	getNonProfit = async (params = false) => {    
    const { dataPan} = this.state;
    const { nonprofits, Nonprofits } = this.props;
    // if (!nonprofits.get_status.nonprofit) {      
		const requestParams = params || {path: [dataPan.panCard]}

    let data = await Nonprofits.getNonprofit(requestParams);
    if(data._id){   
      return data;
    }
    else{
      return false;
    }
  }

	saveNonProfit = async (nonprofit = false) => {
		
		if(nonprofit){	 
			const {NGOOnboardingHome, nonprofitData, Nonprofits} = this.props					
			// If _id exists, update the nonprofit 
			if(nonprofit._id){
				return Nonprofits.setNonprofit(nonprofit).then(res => {
					return res;
				})
				.catch(error => {
					// Do something for Non Authorised Error 						
					return error;
				})					
			}
			// else, create a new nonprofit
			else{
				return NGOOnboardingHome.createNonprofit(nonprofit).then(response => {
					return response
				}).catch(error => {
					console.error(error)
					return false
				})					
			}										
		}
		else{
			const { dataInitial, dataPan } = this.state;			
			let nonProfitData = {
				name: dataInitial.organizationName,
				pan_card: dataPan.panCard,
				required_compliances : ['fbassured']
			}
			let newnpo = await this.saveNonProfit(nonProfitData)			
			if(newnpo){
				this.setData({target: {name: 'nonprofit',value: newnpo}},'main');		
				// this.goToStep('requiredDocuments')				
				return newnpo
			}
		}
  }

	check80GFCRA = (data) => {
		let _80gData, fcraData;	
		if(data.fcra && data['80g'] ){										
			_80gData = { target: { name: 'is80G', value: true } }
			fcraData = { target: { name: 'isFCRA', value: true } }

			// If Non Profit Data Exists and has 80G & FCRA, take the user to the Login Page
			if(_80gData && fcraData){
				this.setData(_80gData, 'dataPan');
				this.setData(fcraData, 'dataPan');
			}	

			return true
		}
		else{
			return false
		}
	}
  
	onPANUpdate = async (pan_card = false) => {	
		
		const { login } = this.props;				
		const { dataPan } = this.state;

		// Fetching the nonprofit information from the PAN Card Value 
		let nonprofit = await this.getNonProfit({path: [pan_card ? pan_card : dataPan.panCard]})
		
		// Setting values in state for futher usage 
		if(nonprofit && nonprofit._id){

			this.setState({						
				dataInitial : { 
					_id: nonprofit._id,
					organizationName : nonprofit.name 
				}
			})

			this.setData({
				target: {
					name: 'nonprofit',
					value: nonprofit
				}
			},'main')
		}
		else{
			this.setState({						
				dataInitial : { 										
					organizationName : '',				
					is12A : '',
					is80G : '',
					isFCRA : ''
				}
			})

			this.setData({
				target: {
					name: 'nonprofit',
					value: {}
				}
			},'main')

		}

		if(login.user_is_valid === true && (			
			login.user.is_email_verified === true && 
			login.user.is_phone_verified === true
			)){
				// If the logged In user is valid and both email and phone are verified, continue checking for other details 
				if(nonprofit){					
					// If nonprofit exists, check for 80G and FCRA values							           
					this.goToStep('login')					
				}
				// If nonprofit doesn't exists, continue to the next screen to enter information to Create a new nonprofit
				else{
					const isMemberData = {target: {'name': 'isMember', 'value': false}}
					this.setData(isMemberData, 'main');
					this.goToStep('initialDetails')		
				}
			}
			else {				
				if(!nonprofit){
					this.goToStep('initialDetails')
				}
				else{
					// TODO: Should we wait for the Login Call to be successful before making a decision here ? 
					this.goToStep('login')
				}
			}		
	}

	checkInitialDetails = (data) => {
		if(!data.is12A){
			// If the logged in Non Profit doesn't have 12A Cerificate, end the process and show the Resepctive Error 							
			let _12AErrorData = {target: {'name': 'finalData', 'value': errorMessage.on12AError}};
			this.setData(_12AErrorData, 'main');
			this.goToStep('successOrError')
			return false
		}
		else if(!data.is80G || !data.isFCRA){	     
			// If the selected Non Profit doesn't  have one of both 80G or FCRA Certificates, end the process and show the respecitive Error.							
			let fcra80GError = {target: {'name': 'finalData', 'value': errorMessage.onFCRA80GErrorInitial}};
			this.setData(fcra80GError, 'main');
			this.goToStep('successOrError')
			return false							
		}				
		else{
			return true
		}
	}

	onUpdateInitialDetails = async () => {		       
		const { dataInitial, dataPan, nonprofit, error } = this.state;
		const { login } = this.props

		let nonProfitData = {
			name: dataInitial.organizationName,
			pan_card: dataPan.panCard,
			required_compliances : ['fbassured']
		}

		if(error.dataInitialError.length == 0) {            
			// Checking if PAN Card Is already associated with an existing non-profit. If not, create a new nonprofit.
			if(Object.keys(nonprofit).length === 0 && nonprofit.constructor === Object){
				this.setData({target: { name: 'isNewNonProfit', value: true}}, 'main')
				if(nonProfitData.name && nonProfitData.pan_card ){					
					if(this.checkInitialDetails(dataInitial)){
						// Otherwise, create a new nonprofit based on users' login status						
						if(!login.user_is_valid){
							this.goToStep('login')
						}
						else{
							// If users is logged in and either phone or email is not verified 							
							if(login.user.is_email_verified === false || login.user.is_phone_verified === false){								
								// TODO: Once login/verification is successful, create a callback to create nonprofit instead of writing the code for creation on onLogin method 
								this.goToStep('login')
							}
							// Else, continue with creation of nonprofit
							else{
								let newnpo = await this.saveNonProfit(nonProfitData)
								if(newnpo){
									this.setData({target: {name: 'nonprofit',value: newnpo}},'main');	
									this.setData({target: { name: 'isNewNonProfit', value: false}}, 'main')
									// Once nonprofit is created, set step to required documents 
									this.goToStep('requiredDocuments')		
								}								
							}
						}						
					}						
				}
			}		
			// If not, update the data for the existing non-profit
			else {
			  if(this.checkInitialDetails(dataInitial)){
					let existingnpo = await this.saveNonProfit({...nonProfitData, _id: dataInitial._id});
					if(existingnpo) {
						this.setData({target: {name : 'nonprofit', value: existingnpo}}, 'main');
						this.goToStep('requiredDocuments')
					}
				}
			}
		}		
	}

	onUpdateRequiredDocuments = () => {
		this.goToStep('dataAvailability')
	}

	onUpdateDataAvailability = () => {		
		this.goToStep('fbOnboard')
	}

	onUpdateFinalDetails = async () => {	
		
		const { nonprofit, dataFbOnboard, required_compliances } = this.state

		let nonprofitData = {}      
		nonprofitData._id = nonprofit._id
		nonprofitData.external_profiles = nonprofit.external_profiles
		let onboardData = Helpers.cloneObject(dataFbOnboard)
      
		if(onboardData.hasOwnProperty('ngoFbUrl')){        
			let facebookIndex = nonprofitData.external_profiles.findIndex(item => item.label === 'Facebook');
			if(facebookIndex >= 0){                
				nonprofitData.external_profiles[facebookIndex].uri = onboardData.ngoFbUrl;
			}
			else{
				nonprofitData.external_profiles.push({
					label: 'Facebook',
					uri: onboardData.ngoFbUrl
				});
			}
			delete onboardData.ngoFbUrl;
		}
      
      nonprofitData.facebook_onboarding = onboardData 
      nonprofitData.required_compliances = required_compliances            

      let save = await this.saveNonProfit(nonprofitData);      
      if(save){                
        let data = {
          target:{
            name: 'finalData',
            value: {
              heading: 'Welcome to Facebook Onboarding',
              description: 'You meet all the criteria for facebook onboarding',
              buttonText: 'Proceed to Facebook Onboarding',
              redirectUrl: `/new-onboarding/${nonprofitData._id}/fbassured`
            }
          }          
        }             
        this.setData(data, 'main')
				this.goToStep('successOrError')
      } 
	}

	getParams = async (type) => {
		// nonprofit_details, pan_card 					
		const queryString = this.props.location.search;
		const urlParams = new URLSearchParams(queryString);
		const pan_card = urlParams.get('pan_card')		
		
		if(pan_card){						
			this.setData({target: {name: 'panCard', value: pan_card}}, 'dataPan')
			this.onPANUpdate(pan_card)						
		}					
		
		const nonprofit_details = urlParams.get('nonprofit_details')

		if(nonprofit_details){
			const parsedNGODetails = JSON.parse(nonprofit_details)			
			this.setData({target: {name: 'dataInitial', value: parsedNGODetails}}, 'main')
		}
	}

	checkPan = () => {
    const pan = this.state.dataA.panCard
    const regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if(regex.test(pan.toUpperCase())) {
      this.setChecks('panCorrect', true)
    } else {
			this.setChecks('panCorrect', false)
			this.setErrors('panError');
    }
	}

	checkNgo = () => {
		const foundNgo = this.state.nonprofits.filter(np => np._id === this.state.ngo)	
		return foundNgo.length == 1 ? true : false;
	}

	setData = (e, dataType) => {		
		if(dataType === 'dataAvailability') {
			let agreeStatus = document.getElementById('sharing').checked == true && document.getElementById('receipt').checked == true;
			agreeStatus ? this.setState({ isEnable : true, errorMessage : '' }) : this.setState({ isEnable : false , errorMessage : '' })
			let disagreeStatus = document.getElementById('disagreeSharing').checked == true || document.getElementById('disagreeReceipt').checked == true;
			disagreeStatus ? this.setState({ errorMessage : 'Both the fields should be agreed to proceed with the Onboarding flow' }) : this.setState({ errorMessage : ''})
		}
		const key = e.target.name;
		let value = ''
		if(e.target.name === 'accept_facebook_terms' || e.target.name === 'accept_razorpay_terms'){	
			// If Set Data is called from changing value at element 
			if(e.target.hasOwnProperty('checked'))					
				value = e.target.checked;
			// If Set Data is called explicitly to set the state.
			else
				value = e.target.value;
		}
		else if(e.target.value === 'true'){
			value = true
		}
		else if(e.target.value === 'false') {
			value = false
		}
		else {
			value = e.target.value;
		}	

		if(dataType === 'main') {
			this.setState({[key] : value })
		} else {			
			const newData = {...this.state[dataType]};
			newData[key] = value;		
			this.setState({ [dataType] : newData })
		}		
	}

	setEditorData = (e, dataType, name) => {
		const key = name;
		const value = e;
		const newData = {...this.state[dataType]};
		newData[key] = value;
		this.setState({ [dataType] : newData })
	}

	setChecks = (key, value) => {
		const newChecks = {...this.state.checks};
		newChecks[key] = value;
		this.setState({ checks : newChecks })
	}

	addError = (obj, keyName, errorStr) => {
		const {error} = this.state;
		const newError = error
		let count = 0;
		if(obj in newError) {
			if(newError[obj].length > 0) {
				if(newError[obj].find(o => o.key === keyName) === undefined) {
					newError[obj].push({'key':keyName, 'value':errorStr})
					count++
				}
			} else {
				newError[obj].push({'key':keyName, 'value':errorStr})
				count++
			}
			count > 0 && this.setState({error : newError})
		}
	}

	setFacebookOnboardingData = (nonprofit) => {
		if(nonprofit.hasOwnProperty('facebook_onboarding')) {
			if(nonprofit.facebook_onboarding.hasOwnProperty('signatory_email'))
				this.setData({target: {'name': 'signatory_email', 'value': nonprofit.facebook_onboarding.signatory_email}},'dataFbOnboard');	
			
			if(nonprofit.facebook_onboarding.hasOwnProperty('signatory_name'))
				this.setData({target: {'name': 'signatory_name', 'value': nonprofit.facebook_onboarding.signatory_name}},'dataFbOnboard');
			
			if(nonprofit.facebook_onboarding.hasOwnProperty('accept_facebook_terms')){			
				this.setData({target: {'name': 'accept_facebook_terms', 'value': nonprofit.facebook_onboarding.accept_facebook_terms ? true : false}},'dataFbOnboard');
			}
			if(nonprofit.facebook_onboarding.hasOwnProperty('accept_razorpay_terms'))
				this.setData({target: {'name': 'accept_razorpay_terms', 'value': nonprofit.facebook_onboarding.accept_razorpay_terms ? true: false}},'dataFbOnboard');			
		}

		if(nonprofit.hasOwnProperty('external_profiles')){
			let facebookProfile = nonprofit.external_profiles.filter(item => item.label === 'Facebook');
			if(facebookProfile.length !== 0){
				this.setData({target: {'name': 'ngoFbUrl', 'value': facebookProfile[0].uri}},'dataFbOnboard');
			}
		}
	}

	removeError = (obj, keyName) => {
		const {error} = this.state;
		const newError = error;
		let count = 0;
		if(obj in newError) {
			if(newError[obj].length > 0) {
				if(newError[obj].findIndex(o => o.key === keyName) > -1) {
					newError[obj].splice(newError[obj].find(o => o.key === keyName), 1)
					count++;
				}
			}
			count>0 && this.setState({error : newError})
		}
	}		

	onLogin = async () => {
		const { login, Login, home, Home } = this.props;
		const { nonprofit, dataInitial } = this.state;		
		
		// For admin user login, throw an error asking them to login as a non-profit admin.
		if(login.user_is_valid && login.user.is_admin){
			let adminError = {target: {'name': 'finalData', 'value': errorMessage.onAdminError}}
			this.setData(adminError, 'main')
			this.goToStep('successOrError')	
		}	
		else if(login.user_is_valid && !login.user.is_admin){
			// If it's updating information of an existing NGO 
			if(!this.state.isNewNonProfit){ 
				if (!home.get_status.nonprofit){
					let userNonProfits = await Home.getNonprofits();					
					
					this.setData({
						target: {
							name: 'userNonProfits', 
							value: userNonProfits
						}
					},'main')

					// Checking for Non Profit ID that matches with the registered PAN #
					let thisNonProfit = userNonProfits.filter(item => item._id === nonprofit._id);					
					if(thisNonProfit.length !== 0){
						thisNonProfit = thisNonProfit[0];												
						this.setFacebookOnboardingData(thisNonProfit);
						if(this.check80GFCRA(thisNonProfit)){
							this.setData({target: {'name': 'isMember', 'value': true}},'main');	
							this.goToStep('requiredDocuments')
						}
						else{
							this.goToStep('initialDetails')
						}
						// If Non Profits part of the user profile is one of organisations for which PAN was entered, go to the next stage of Required Document's Check. 
					}
					else{
						// If Non Profits part of the user profile doesn't contain organisation for which PAN was entered, show message saying onboarding not being possible.						
						let memberError = {target: {'name': 'finalData', 'value': errorMessage.nonMemberLogin}};
						this.setData(memberError, 'main');
						this.goToStep('successOrError')
					}
				}
				else{
					// If Users click on the back button and comes back to the screen, checking for existing data and appending the required information 
					// Eg. User Logs in initially with a Pan Card and decides to go back and change the information of PAN Card. 
					const { userNonProfits } = this.state;					
					if(userNonProfits.length !== 0){
						let thisNonProfit = userNonProfits.filter(item => item._id === nonprofit._id);
						if(thisNonProfit.length !== 0){
							thisNonProfit = thisNonProfit[0];
							this.setFacebookOnboardingData(thisNonProfit);
							if(this.check80GFCRA(thisNonProfit)){								
								this.setData({target: {'name': 'isMember', 'value': true}},'main');	
								this.goToStep('requiredDocuments')
							}
							else{
								this.goToStep('initialDetails')
							}
						}
						else{
							this.setData({target: {'name': 'isMember', 'value': false}},'main');							
							let memberError = {target: {name: 'finalData', value: errorMessage.nonMemberLogin}};
							this.setData(memberError, 'main');
							this.goToStep('successOrError')
						}
					}
				}
			}
			// For New NGO Creation
			else{
				let data = await this.saveNonProfit();				
				if(data && data._id){					
					this.setData({target: {name: 'nonprofit',value: data}},'main');		
					this.goToStep('requiredDocuments')				
				}
			}
		}
	}
	
	render(){
		const {login} = this.props;		
		return (
			<div className="container-scroller">
				<nav className="navbar horizontal-layout col-lg-12 col-12 p-0">
					<div className="container d-flex flex-row nav-top mr-b-20">
					<div className="text-center navbar-brand-wrapper d-flex align-items-top">
						<a className="navbar-brand brand-logo" href="/">
							<img src={logo} alt="logo" />
						</a>
						<a className="navbar-brand brand-logo-mini" href="/">
							<img src={logo} alt="logo" />
						</a>						
					</div>
					<div className="navbar-menu-wrapper d-flex align-items-center">
						{login.user_is_valid && <UserDropdown />}							
					</div>
					</div>					
				</nav>
				
				<div className="container-fluid page-body-wrapper">
					<div className="main-panel container">
						<div className="content-wrapper">

							{this.state.step === 'getPan' && (
								<FbOnboardPAN 
									state={this.state} 
									setData={this.setData} 
									addError={this.addError} 
									removeError={this.removeError}
									onSubmit={this.onPANUpdate}
								/>
							)}

							{this.state.step === 'login' && (
								<FbOnboardLogin 
									onLogin={this.onLogin} 
									state={this.state} 
									goBack={this.previousStep} 
									setData={this.setData}  									
								/>
							)}

							{this.state.step === 'initialDetails' && (
								<FbOnboardInitialDetails 
									state={this.state} 
									setData={this.setData} 
									setEditorData={this.setEditorData} 
									addError={this.addError} 
									removeError={this.removeError} 
									goBack={this.previousStep} 	
									onSubmit={this.onUpdateInitialDetails}
								/>
							)}

							{this.state.step === 'requiredDocuments' && (
								<FbOnboardRequiredDocuments 
									state={this.state} 
									setData={this.setData} 
									setEditorData={this.setEditorData} 
									addError={this.addError} 
									removeError={this.removeError} 
									goBack={this.previousStep} 
									onSubmit={this.onUpdateRequiredDocuments}
								/>
							)}

							{this.state.step === 'dataAvailability' && (
								<FbOnboardDataAvailability 
									state={this.state} 
									setData={this.setData} 
									addError={this.addError} 
									removeError={this.removeError} 
									goBack={this.previousStep} 
									onSubmit={this.onUpdateDataAvailability}
								/>
							)}						

							{this.state.step === 'successOrError' && <FbOnboardWelcome state={this.state} goBack={this.previousStep}/>}

							{this.state.step === 'fbOnboard' && (
								<FbOnboardFinal 
									state={this.state} 
									setData={this.setData} 
									addError={this.addError} 
									removeError={this.removeError}  
									goBack={this.previousStep} 
									onSubmit={this.onUpdateFinalDetails}
								/>
							)}

						</div>
					</div>
				</div>
			</div>
			
		);
	}
}

const mapStateToProps = state => ({
	login: state.login,
	home: state.home,
	nonprofitData: state.ngoOnboardingHome,
	nonprofits: state.profileNonprofits,
});

const mapActionToProps = dispatch => ({
	Login: bindActionCreators(LoginAction, dispatch),
	Home: bindActionCreators(HomeActions, dispatch),
	NGOOnboardingHome: bindActionCreators(NGOOnboardingHomeAction, dispatch),  
	Nonprofits: bindActionCreators(NonProfitActions, dispatch), 
});

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
)(FacebookOnboarding));