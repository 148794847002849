import { Box, Divider, Grid, ListItemIcon, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DonationService from "../../../services/donation-service";

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    label: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    subTitle: {
        fontSize: 14,
        fontWeight: '400'
    },

    modalContent: {
        padding: '10px 25px !important'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};

const ContactModalPopup = (props) => {
    const classes = useStyles()

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const Donation = props.item

    const [mobile, setMobile] = useState('');
    const [mobileErr, setMobileErr] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false);
    const [message, setMessage] = useState('');
    const [messageErr, setMessageErr] = useState(false);
    const [channels, setChannels] = useState([]);
    const [channelsErr, setChannelsErr] = useState(false);

    const channelOptions = ['sms', 'email', 'whatsapp'];

    useEffect(() => {
        setMobile(Donation?.user?.phone ? Donation.user.phone : '');
        setEmail(Donation?.user?.email ? Donation.user.email : '');
    }, [])

    const displayAlert = (res) => {
        for (const channelName in res) {
            toast.success(`${channelName} : ${res[channelName]}`, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const sendNotificationToUser = async (Donation) => {
        let data = {
            "channels": channels,
            "toMail": email,
            "mobile": mobile,
            "orderId": Donation.order_id,
            "userName": Donation.user.name
        }
        try {
            const res = await DonationService.sendNotification(data);
            displayAlert(res);
        } catch (err) {       
            toast.error('Some error occured', {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });     
        }
    };

    const openContactModal = () => {
        setShowModal(true);
    }

    const callApi = () => {
        setLoading(false);
        setShowModal(false);
        sendNotificationToUser(Donation);
    }

    const checkValidations = () => {
        if (mobile.length < 13) {
            setMobileErr(true);
        }
        if (email.length === 0) {
            setEmailErr(true);
        }

    }

    const handleSend = (e) => {
        e.preventDefault();
        if ( channels.length !== 0 ) {
            if (channels.length === 3) {
                if (mobile.length > 10 && email.length > 0) {
                    callApi();
                } else {
                    checkValidations() 
                }
            } else if (( (channels.includes('sms') || channels.includes('whatsapp')) && mobile.length > 10 ) || ( channels.includes('email') && email.length !== 0 )) {
                    callApi();
                } else {
                    checkValidations()
                }
        } else {
            setChannelsErr(true);
        }
    };

    const handleChange = (event) => {
        if(channelsErr){
            setChannelsErr(false)
        }
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setChannels(channels.length === channelOptions.length ? [] : channelOptions);
            return;
        }
        setChannels(value);
    };

    const handleMobileChange = (e) => {
        if (mobileErr) {
            setMobileErr(false);
        }
        setMobile(e.target.value);
    }

    const handleEmailChange = (e) => {
        if (emailErr) {
            setEmailErr(false);
        }
        setEmail(e.target.value);
    }

    // const handleMessageChange = (e) => {
    //     if (messageErr) {
    //         setMessageErr(false);
    //     }
    //     setMessage(e.target.value);
    // }

    return (
        <>
            <div className="col-md-8 pt-5">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => {
                        e.preventDefault();
                        openContactModal()
                    }}
                >
                    Contact
                </button>
            </div>
            <Modal
                show={showModal}
                dialogClassName="modal-90w"
                onHide={() => { setShowModal(false); }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Contact Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '10px 25px' }}>
                    <Grid container item style={{ marginBottom: 10 }}>
                        <Grid item md={6}>
                            <Typography className={classes.title}>For :</Typography>
                            <Typography className={classes.subTitle}>{(Donation.user?.name)}</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Typography className={classes.title}>Donating To :</Typography>
                            <Typography className={classes.subTitle}>{(Donation.campaign?.title)}</Typography>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginBottom: 20 }} />
                    <Grid container md={12} spacing={2} >
                        <Grid item md={6}>
                            <TextField
                                label="Mobile"
                                id="outlined-size-small"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                value={mobile}
                                name={"mobile"}
                                onChange={ e => handleMobileChange(e) }
                            />
                            {mobileErr && <p className="error-message">Enter a valid mobile number(Add country codes Ex:+91)</p>}
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                label="Email"
                                id="outlined-size-small"
                                defaultValue=""
                                variant="outlined"
                                size="small"
                                type={'email'}
                                value={email}
                                name={"email"}
                                onChange={ e => handleEmailChange(e) }
                            />
                            {emailErr && <p className="error-message">Enter a valid email ID</p>}
                        </Grid>
                    </Grid>
                    <Grid container md={12} spacing={2}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mutiple-select-label">Select a Channel</InputLabel>
                            <Select
                                labelId="mutiple-select-label"
                                multiple
                                value={channels}
                                onChange={handleChange}
                                renderValue={(selected) => selected.join(", ")}
                            >
                                {channelOptions.map((channelOption) => (
                                    <MenuItem key={channelOption} value={channelOption}>
                                        <ListItemIcon>
                                            <Checkbox checked={channels.indexOf(channelOption) > -1} />
                                        </ListItemIcon>
                                        <ListItemText primary={channelOption} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {channelsErr && <p className="error-message">Select at least one channel to proceed.</p>}
                        </FormControl>
                    </Grid>
                    {/* <Box marginTop={'20px'} width={"100%"}>
                        <label className={classes.label}>Message: </label>
                        <textarea
                            label="Message"
                            id="outlined-size-small"
                            defaultValue=""
                            value={message}
                            style={{ background: 'transparent', padding: '10px', width: '100%' }}
                            placeholder="Guest sharing message"
                            name={"message"}
                            onChange={ (e) => handleMessageChange(e) }
                        />
                        {messageErr && <p className="error-message">Please enter a Message.</p>}
                    </Box> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false); }}>Close</Button>
                    <Button onClick={(e) => { handleSend(e) }} variant="primary">Send</Button>
                </Modal.Footer>
            </Modal >
        </>
    );
};
  
export default ContactModalPopup;