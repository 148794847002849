import React, { useState } from "react";
import { Dropdown, Modal, Button } from "react-bootstrap";

const AdminTagImport = ({ item, Tags }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImportType, setSelectedImportType] = useState(null);
  const [file, setFile] = useState(null);
  const [successPayload, setSuccessPayload] = useState([]);
  const [failurePayload, setFailurePayload] = useState([]);

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-secondary" id={`export-{item._id}`}>
        Import Items
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {["Campaigns", "Projects", "Non Profits", "Donations"].map((type) => (
          <Dropdown.Item
            key={type}
            onClick={(e) => {
              setShowModal(true);
              setSelectedImportType(type);
            }}
          >
            {type}
          </Dropdown.Item>
        ))}
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedImportType(false);
            setFile(null);
            setSuccessPayload([]);
            setFailurePayload([]);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Import Tags - {selectedImportType}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFile(file);
                }}
              />
              <Button
                variant="primary"
                onClick={() => {
                  if (file) {
                    const formData = new FormData();
                    formData.append("file", file, file.name);
                    Tags.importItems(
                      item._id,
                      selectedImportType.toLowerCase().replace(" ", ""),
                      formData
                    )
                      .then((res) => {
                        setSuccessPayload(res.success_payload);
                        setFailurePayload(res.failures_payload);
                        if (!failurePayload.length) {
                          setSelectedImportType(null);
                          setFile(null);
                        }
                      })
                      .catch((res) => {
                        setSelectedImportType(null);
                      });
                  }
                }}
              >
                Upload
              </Button>
            </div>
            <div>
              {successPayload.length > 0 ||
                (failurePayload.length > 0 && (
                  <table className="table table-striped">
                    {selectedImportType === "Donations" ? (
                      <tr>
                        <th>Order Id</th>
                        <th>Entity</th>
                        <th>Action</th>
                        <th>Status</th>
                      </tr>
                    ) : (
                      <tr>
                        <th>Entity</th>
                        <th>Entity Id</th>
                        <th>Status</th>
                      </tr>
                    )}

                    {successPayload.map(({ entity }) =>
                      selectedImportType === "Donations" ? (
                        <tr key={entity.OrderId}>
                          <td>{entity.OrderId}</td>
                          <td>{entity.Entity}</td>
                          <td>{entity.Action}</td>
                          <td>{entity.status}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td>{entity.Entity}</td>
                          <td>{entity.Entity_ID}</td>
                          <td>Success</td>
                        </tr>
                      )
                    )}
                    {failurePayload.map(({ entity, error }) =>
                      selectedImportType === "Donations" ? (
                        <tr>
                          <td>{entity.OrderId}</td>
                          <td>{entity.Entity}</td>
                          <td>{entity.Action}</td>
                          <td>{entity.status}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td>{entity.Entity}</td>
                          <td>{entity.Entity_ID}</td>
                          <td>{error}</td>
                        </tr>
                      )
                    )}
                  </table>
                ))}
            </div>
          </Modal.Body>
        </Modal>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AdminTagImport;
