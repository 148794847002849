import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import DisbursementService from '../../services/disbursement-service';
import DonationService from '../../services/donation-service';

export const GET_DISBURSEMENTS_SETTLED_PG = 'GET_DISBURSEMENTS_SETTLED_PG';
export const GET_DISBURSEMENTS_SETTLED_PG_SUCCESS = 'GET_DISBURSEMENTS_SETTLED_PG_SUCCESS';
export const GET_DISBURSEMENTS_SETTLED_PG_FAIL = 'GET_DISBURSEMENTS_SETTLED_PG_FAIL';

export const GET_DISBURSEMENTS_SETTLED_STATS = 'GET_DISBURSEMENTS_SETTLED_STATS';
export const GET_DISBURSEMENTS_SETTLED_STATS_SUCCESS = 'GET_DISBURSEMENTS_SETTLED_STATS_SUCCESS';
export const GET_DISBURSEMENTS_SETTLED_STATS_FAIL = 'GET_DISBURSEMENTS_SETTLED_STATS_FAIL';

export const GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET = 'GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET';
export const GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET_SUCCESS = 'GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET_SUCCESS';
export const GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET_FAIL = 'GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET_FAIL';

export const GET_DISBURSEMENTS_SETTLED_SEND_REPORT = 'GET_DISBURSEMENTS_SETTLED_SEND_REPORT';
export const GET_DISBURSEMENTS_SETTLED_SEND_REPORT_SUCCESS = 'GET_DISBURSEMENTS_SETTLED_SEND_REPORT_SUCCESS';
export const GET_DISBURSEMENTS_SETTLED_SEND_REPORT_FAIL = 'GET_DISBURSEMENTS_SETTLED_SEND_REPORT_FAIL';

export const GET_DISBURSEMENTS_SETTLED_DOWNLOAD = 'GET_DISBURSEMENTS_SETTLED_DOWNLOAD';
export const GET_DISBURSEMENTS_SETTLED_DOWNLOAD_SUCCESS = 'GET_DISBURSEMENTS_SETTLED_DOWNLOAD_SUCCESS';
export const GET_DISBURSEMENTS_SETTLED_DOWNLOAD_FAIL = 'GET_DISBURSEMENTS_SETTLED_DOWNLOAD_FAIL';


export const DISBURSEMENTS_SETTLED_RESET = 'DISBURSEMENTS_SETTLED_RESET';

const initialState = {
  stats: [],
  pg: {},
  get_status: {},
  disbursements: [],
};

export const settledReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DISBURSEMENTS_SETTLED_PG:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pg: 'pending',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_PG_SUCCESS:
      return {
        ...state,
        pg: action.pg,
        get_status: {
          ...state.get_status,
          pg: 'success',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_PG_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pg: 'failed',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_STATS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          stat: 'pending',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats,
        get_status: {
          ...state.get_status,
          stat: 'success',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_STATS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          stat: 'failed',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          disbursement: 'pending',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET_SUCCESS:
      return {
        ...state,
        disbursements: action.disbursements,
        get_status: {
          ...state.get_status,
          disbursement: 'success',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          disbursement: 'failed',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_SEND_REPORT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['send_report_' + action.id]: 'pending',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_SEND_REPORT_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['send_report_' + action.id]: 'success',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_SEND_REPORT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['send_report_' + action.id]: 'failed',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_DOWNLOAD:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'pending',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_DOWNLOAD_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'success',
        },
      };
    case GET_DISBURSEMENTS_SETTLED_DOWNLOAD_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'failed',
        },
      };
    case DISBURSEMENTS_SETTLED_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getPG() {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_SETTLED_PG,
    });
    return DonationService.get({ path: ['payment-entities'] })
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_PG_SUCCESS,
          pg: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_PG_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getStats() {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_SETTLED_STATS,
    });
    const request = {
      path: ['stats'],
      method: 'post',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_STATS_SUCCESS,
          stats: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_STATS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getDisbursements() {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET,
    });
    const request = {
      path: ['batches'],
      params: { filter_by_status: 'SETTLED' },
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET_SUCCESS,
          disbursements: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_DISBURSEMENT_GET_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function resendDisbursementReport(disbursementNumber) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_SETTLED_SEND_REPORT,
      id: disbursementNumber,
    });
    const request = {
      path: [disbursementNumber, 'send-report'],
      method: 'POST',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_SEND_REPORT_SUCCESS,
          id: disbursementNumber,
        });
        if(response.message){
          dispatch(NotificationAction.addNotification(response.message, 'SUCCESS'));
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_SEND_REPORT_FAIL,
          id: disbursementNumber,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function downloadSettledBatch(data) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_SETTLED_DOWNLOAD,
    });
    const request = {
      path: ['batches', data, 'download'],
      method: 'POST',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_DOWNLOAD_SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_SETTLED_DOWNLOAD_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: DISBURSEMENTS_SETTLED_RESET,
    });
  };
}
