/**
 * Service function to make API call
 */
import memoize from 'memoize-one';

import _Error from '../utils/_error';
import API from '../utils/api';
import { endpoints } from '../config';
// import Helpers from '../utils/helpers';


export default class ConfigService {
  static requestAPI(endpoint = endpoints.config, request = {}) {
    return API.request(endpoint, request);
  }

  static cacheGet = memoize(this.requestAPI);

  static get(request) {
    return this.cacheGet(undefined, request);
  }
}
