import React, { Component } from 'react';

import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';

import Helpers from '../../utils/helpers';

import './ApprovalsRejectionsButtons.scss';

class ApprovalsRejectionsButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }  

  render() {
    const { children, getState, changeState, path } = this.props;    
    const state = getState(path);
    if (state === null) {
      return null;
    }
    if (state === 'approving') {
      return (
        <div className="approval-box">
          {children}
          <div className="btn-call approved"></div>
          <div className="ab-btn-wrapper">
            {/* <button type="button" className="btn btn-success">Approved</button> */}
            <button type="button" className="btn btn-danger" onClick={(e) => { e.preventDefault(); changeState(path, 'approvingToDefault') }}><Icon path={mdiCloseCircle} className="menu-arrow" size={0.60} color="#ffffff" /> Cancel</button>
          </div>
        </div>
      );
    }
    if (state === 'rejecting') {
      return (
        <div className="approval-box">
          {children}
          <div className="btn-call rejected"></div>
          <div className="ab-btn-wrapper">
            {/* <button type="button" className="btn btn-danger">Rejected</button> */}
            <button type="button" className="btn btn-danger" onClick={(e) => { e.preventDefault(); changeState(path, 'rejectingToDefault') }}><Icon path={mdiCloseCircle} className="menu-arrow" size={0.60} color="#ffffff" /> Cancel</button>
          </div>
        </div>
      );
    }
    if (state === 'default') {
      return (
        <div className="approval-box">
          {children}
          <div className="btn-call default"></div>
          <div className="ab-btn-wrapper">
            <button type="button" className="btn btn-success" onClick={(e) => { e.preventDefault(); changeState(path, 'defaultToApproving') }}><Icon path={mdiCheckCircle} className="menu-arrow" size={0.60} color="#ffffff" /> Approve</button>
            <button type="button" className="btn btn-danger" onClick={(e) => { e.preventDefault(); changeState(path, 'defaultToRejecting') }}><Icon path={mdiCloseCircle} className="menu-arrow" size={0.60} color="#ffffff" /> Reject</button>
          </div>
        </div>
      );
    }
    return children;
  }
}

export default ApprovalsRejectionsButtons;
