import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';

class ProjectGeneralRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
      projectLocation: '',
      prevValue: {},
    };
  }

  switchView = (view) => {
    this.setState({ view: view });
  }

  componentDidMount() {
    const value = this.getInputData(['project','sites']);
    const sites = value.sites || [];
    if (sites.length > 0) {
      const site = sites[0];
      let projectLocation = 'city';
      if (!site.city || site.city === '') {
        projectLocation = 'region';
      }
      if (!(site.region || site.city) || (site.region === '' && site.city === '')) {
        projectLocation = 'india';
      }
      this.setState({ projectLocation });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.value !== state.prevValue) {
      setData.prevValue = props.value;
      const sites = props.tabs[state.view].sites || [];
      if (sites.length > 0) {
        const site = sites[0];
        setData.projectLocation = 'city';
        if (!site.city || site.city === '') {
          setData.projectLocation = 'region';
        }
        if (!(site.region || site.city) || (site.region === '' && site.city === '')) {
          setData.projectLocation = 'india';
        }
      }
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if(val === '' && key[1] !== 'video' && key[1] !== 'donation_options') {
      return this.na;
    }
    return val;
  }

  projectLocationText = (location, type) => {
    switch (location) {
      case 'city':
        if (type === 'p') return 'Cities';
        return 'city';
      case 'region':
        if (type === 'p') return 'States';
        return 'state';
      default:
        if (type === 'p') return 'Countries';
        return 'country';
    }
    return '';
  }

  getErrorMessage = (forList) => {
    const { errors } = this.props;
    const forItem = forList.slice(1, forList.length).join('.');
    let returnValue = null;
    if (errors && errors.length > 0) {
      errors.forEach((item) => {
        if (item[forItem]) {
          returnValue = (
            <div className="error-message">
              {item[forItem]}
            </div>
          );
        }
      });
    }
    return returnValue;
  }

  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if(to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if(to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if(to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if(to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }

  render() {
    const { children, tabs, status } = this.props;
    const { projectLocation, view } = this.state;    

    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">Project General Details</h3>
                <p className={`ob-status ${this.getInputData(['nonprofit', 'status'])}`}>{this.getInputData(['nonprofit', 'status'])}</p>
                <div className="heading-bar-btn">
                  {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab=>{
                    if (tab === view) {
                      return (
                      <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                      );
                    }
                    return (
                    <button type="button" className="btn btn-outline-secondary btn-right" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                    );
                  })}
                </div>
              </div>
              <ApprovalsRejectionsButtons path={['project', 'name']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <h6>Name of the Project</h6>              
                {this.getErrorMessage(['project', 'name'])}
                <p className="text-muted">{this.getInputData(['project', 'name'])}</p>
                </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['project', 'issue']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <h6>Issue (What is the concern? - Max 200 words)</h6>
                {this.getErrorMessage(['project', 'issue'])}
                <div className="text-muted" dangerouslySetInnerHTML={{ __html: this.getInputData(['project', 'issue']) }}>
                </div>
              </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['project', 'action']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <h6>Action (What are you going to do about it? - Max 200 words)</h6>
                {this.getErrorMessage(['project', 'action'])}
                <div className="text-muted" dangerouslySetInnerHTML={{ __html: this.getInputData(['project', 'action']) }}>
                </div>
              </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['project', 'impact']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <h6>Impact (What is the impact that you wish to create? - Max 200 words)</h6>
                {this.getErrorMessage(['project', 'impact'])}
                <div className="text-muted" dangerouslySetInnerHTML={{ __html: this.getInputData(['project', 'impact']) }}>
                </div>
              </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['project', 'causes']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div className="row">
                  <div className="col-md-6">
                    <h6>Primary Cause</h6>
                    {this.getErrorMessage(['project', 'causes'])}
                    <p className="text-muted">{this.getInputData(['project', 'causes', 0])}</p>
                  </div>
                  <div className="col-md-6">
                    <h6>Secondary Cause</h6>
                    {this.getErrorMessage(['project', 'causes'])}
                    <p className="text-muted">{this.getInputData(['project', 'causes', 1])}</p>
                  </div>
                </div>
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Project Location(s)</h3>
              <ApprovalsRejectionsButtons path={['project', 'sites']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                {this.getErrorMessage(['project', 'sites'])}
                <div className="row">
                  <div className="col-md-6">
                    <h6>Project is</h6>
                    {(this.getInputData(['project', 'sites']).length === 0) ? (<p className="text-muted">Not Available</p>) : (<p className="text-muted">Implemented in single/multiple {this.projectLocationText(projectLocation)}</p>)}
                  </div>
                  <div className="col-md-6">
                    <h6>Project Location(s)</h6>
                    <div>
                      {(this.getInputData(['project', 'sites']).length > 0) ? (this.getInputData(['project', 'sites']).map(item => (
                        <label className="badge badge-primary" key={item.city + item.region + 'india'}>
                          {(item.city) ? `${item.city}, ${item.region}, India` : null}
                          {(!item.city && item.region) ? `${item.region}, India` : null}
                          {(!item.city && !item.region) ? 'India' : null}
                        </label>
                      ))) : (<p className="text-muted">Not Available</p>)}
                    </div>
                  </div>
                </div>
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Project Images</h3>
              <ApprovalsRejectionsButtons path={['project', 'cover_image']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <h6>Cover Image</h6>
                {this.getErrorMessage(['project', 'cover_image'])}
                <p>
                  <img className="width100" src={this.getInputData(['project', 'cover_image'])} alt="" />
                </p>
              </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['project', 'image']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <h6>Tile Image(Image used in listing-tiles)</h6>
                {this.getErrorMessage(['project', 'image'])}
                <p>
                  <img src={this.getInputData(['project', 'image'])} alt="" />
                </p>
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Donation Options</h3>
              <ApprovalsRejectionsButtons path={['project', 'donation_options']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th colSpan="2"> Amount </th>
                      <th> Donation Description </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> INR </td>
                      <td>
                        {this.getErrorMessage(['project', 'donation_options', 0, 'amount'])}
                        {(this.getErrorMessage(['project', 'donation_options', 0, 'amount'])) ? (
                          <br />
                        ) : null}
                        {this.getInputData(['project', 'donation_options', 0, 'amount'])}
                      </td>
                      <td>
                        {this.getErrorMessage(['project', 'donation_options', 0, 'title'])}
                        {(this.getErrorMessage(['project', 'donation_options', 0, 'title'])) ? (
                          <br />
                        ) : null}
                        {this.getInputData(['project', 'donation_options', 0, 'title'])}
                      </td>
                    </tr>
                    <tr>
                      <td> INR </td>
                      <td>
                        {this.getErrorMessage(['project', 'donation_options', 1, 'amount'])}
                        {(this.getErrorMessage(['project', 'donation_options', 1, 'amount'])) ? (
                          <br />
                        ) : null}
                        {this.getInputData(['project', 'donation_options', 1, 'amount'])}
                      </td>
                      <td>
                        {this.getErrorMessage(['project', 'donation_options', 1, 'title'])}
                        {(this.getErrorMessage(['project', 'donation_options', 1, 'title'])) ? (
                          <br />
                        ) : null}
                        {this.getInputData(['project', 'donation_options', 1, 'title'])}
                      </td>
                    </tr>
                    <tr>
                      <td> INR </td>
                      <td>
                        {this.getErrorMessage(['project', 'donation_options', 2, 'amount'])}
                        {(this.getErrorMessage(['project', 'donation_options', 2, 'amount'])) ? (
                          <br />
                        ) : null}
                        {this.getInputData(['project', 'donation_options', 2, 'amount'])}
                      </td>
                      <td>
                        {this.getErrorMessage(['project', 'donation_options', 2, 'title'])}
                        {(this.getErrorMessage(['project', 'donation_options', 2, 'title'])) ? (
                          <br />
                        ) : null}
                        {this.getInputData(['project', 'donation_options', 2, 'title'])}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Cost Break-Up &amp; Target Amount</h3>
              <ApprovalsRejectionsButtons path={['project', 'project_costs']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th> Particulars </th>
                      <th colSpan="2"> Amount </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(this.getInputData(['project', 'project_costs']) || []).map((item, index) => (
                      <tr key={item.title + index}>
                        <td>
                          {this.getErrorMessage(['project', 'project_costs', index, 'title'])}
                          {(this.getErrorMessage(['project', 'project_costs', index, 'title'])) ? (
                            <br />
                          ) : null}
                          {item.title}
                        </td>
                        <td> INR </td>
                        <td>
                          {this.getErrorMessage(['project', 'project_costs', index, 'title'])}
                          {(this.getErrorMessage(['project', 'project_costs', index, 'title'])) ? (
                            <br />
                          ) : null}
                          {item.value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="card-body">
                  <h6 className="text-right">Target amount</h6>
                  {this.getErrorMessage(['project', 'target_amount'])}
                  <p className="text-muted text-right">
                    <strong>INR</strong> {this.getInputData(['project', 'target_amount'])}
                  </p>
                </div>
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectGeneralRead;
