import React, { Component } from 'react'

import Icon from '@mdi/react';
import {
  mdiFloppy,
  mdiLoading,
  mdiChevronLeft
} from '@mdi/js';
class FbOnboardFinal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }  

  checkFacebookUrl = (url) => {
    const {addError, removeError} = this.props;
    const facebookUrlRegEx = /(?:(?:http|https):\/\/)?(?:www.|m.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\.-]+)$/;
    if(facebookUrlRegEx.test(url)){
      removeError('dataFbOnboardError','ngoFbUrl');      
      return true;
    }
    else{
      addError('dataFbOnboardError','ngoFbUrl','Enter Valid Facebook Page URL')
      return false;
    }
  }

  handleSubmit = () => {
    
    this.setState({...this.state, loading: true })

    const {state, addError, removeError, onSubmit} = this.props    
    const {dataFbOnboard} = state;  
    

    let errors = false

    for (var key of Object.keys(dataFbOnboard)) {
      if(dataFbOnboard[key] === '') {
        addError('dataFbOnboardError', key, 'Add data')
        errors = true
        this.setState({...this.state,loading: false});
      } else {
        removeError('dataFbOnboardError', key)        
      }
    }

    if(this.checkFacebookUrl(dataFbOnboard.ngoFbUrl) && !errors) {            
      onSubmit()     
    } 
    else{
      this.setState({...this.state,loading: false});
    }
  }
  

  render() {    
    const { state, setData, goBack} = this.props
    const { dataFbOnboard, error} = state
    const { loading} = this.state

		return (
			<div>
				<div>
					<title>Facebook Onboarding form</title>
					<meta name="description" content="Give.do is India’s largest and most trustworthy online donation platform. Donate to India’s most credible NGOs and causes including health, education, elderly care and more." />
				</div>        
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">NGO Details</h3>
                <div className="form-group">
                  <label>NGO's Facebook Page (URL)</label>
                  <input type="text" 
                    className="form-control" 
                    placeholder="Your NGO's facebook page URL." 
                    name="ngoFbUrl" 
                    value={dataFbOnboard.ngoFbUrl} 
                    onChange={(e) => setData(e, 'dataFbOnboard')}
                  />
                  <span className="field-valid-error-fbon">{error.dataFbOnboardError.findIndex(o => o.key === 'ngoFbUrl')>-1 && error.dataFbOnboardError[error.dataFbOnboardError.findIndex(o => o.key === 'ngoFbUrl')].value}</span>
                </div>

                <div className="form-group">
                  <label>Signatory Name</label>
                  <input type="text" className="form-control" placeholder="Name of Signatory" name='signatory_name' value={dataFbOnboard.signatory_name} 
                    onChange={(e) => setData(e, 'dataFbOnboard')}/>
                  <span className="field-valid-error-fbon">{error.dataFbOnboardError.findIndex(o => o.key === 'signatory_name')>-1 && error.dataFbOnboardError[error.dataFbOnboardError.findIndex(o => o.key === 'signatory_name')].value}</span>
                </div>

                <div className="form-group">
                  <label>Signatory Email Address</label>
                  <input type="email" className="form-control" placeholder="Email of Signatory" name='signatory_email' value={dataFbOnboard.signatory_email} onChange={(e) => setData(e, 'dataFbOnboard')}/>
                  <span className="field-valid-error-fbon">{error.dataFbOnboardError.findIndex(o => o.key === 'signatory_email')>-1 && error.dataFbOnboardError[error.dataFbOnboardError.findIndex(o => o.key === 'signatory_email')].value}</span>
                </div>

                <p>
                  Similar to requirements for NGOs (nonprofit organizations) in other countries, Facebook requires Indian charities to accept Terms &amp; Conditions. Once submitted and verified, your NGO maybe granted access to the charitable giving tools to begin raising funds on the Facebook platform.
                </p>
                <p>
                  Please enter your NGO Name as you would have registered in the same way with Give.do. Please enter your NGOs Facebook Page URL. If you do not have one, you might need to create one. The Page Admin(s) on your NGO’s Facebook Page will automatically be added as Admins for the Donation/Payment Settings as well, which can be accessed <a>here</a>. It would be ideal to enter the name and email address of a Page Admin.
                </p>
                <hr/>
                {/* TODO: Find link for Payment Setting, etc  */}
                <h4 className="mr-b-20">Facebook Terms of Use</h4>
                <div className="form-group form-check mr-b-20">
                  <input type="checkbox" className="form-check-input" value={true} name="accept_facebook_terms" id="isFacebookTermsAndConditionAccepted"
                    onChange={(e) => setData(e, 'dataFbOnboard')} required
                    {...(dataFbOnboard.accept_facebook_terms ? {checked:'checked'} : {})}
                  />
                  <label className="form-check-label" htmlFor="isFacebookTermsAndConditionAccepted">
                    On behalf of my organization, I accept Facebook's <a href={'https://www.facebook.com/legal/donation_button_terms'} target="_blank" rel="noopener noreferrer" style={{ color: '#59699B', textDecoration: 'underline', cursor: 'pointer'}}>Charitable Donation Terms</a>
                  </label>
                </div>
                <h4 className="mr-b-20">Razorpay Terms of Use</h4>
                <div className="form-group form-check mr-b-20">
                  <input type="checkbox" className="form-check-input" value={true} name="accept_razorpay_terms" id="isRazorpayTermsAndConditionAccepted"
                    onChange={(e) => setData(e, 'dataFbOnboard')} required 
                    {...(dataFbOnboard.accept_razorpay_terms ? {checked:'checked'} : {})}
                  />
                  <label className="form-check-label" htmlFor="isRazorpayTermsAndConditionAccepted">
                    On behalf of my organization, I accept Razorpay's <a href={'https://razorpay.com/terms/'} target="_blank" rel="noopener noreferrer" style={{ color: '#59699B', textDecoration: 'underline', cursor: 'pointer'}}>Terms of Use</a>
                  </label>
                </div>
              </div>

              <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  <div>
                    <span className="save-click-error">{(error.dataFbOnboardError.findIndex(o => o.key === 'signatoryEmail')>-1 || error.dataFbOnboardError.length>0) && 'Please correct all the errors'}</span>
                  </div>
                  <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper"> 
                    <button type="button" className="btn btn-outline-primary mr-2" onClick={goBack}>
                      <Icon path={mdiChevronLeft} className="menu-arrow" size={0.75} /> Back
                    </button>                   
                    <button type="submit" className="btn btn-primary mr-2"                       
                      onClick={this.handleSubmit} 
                      disabled={(dataFbOnboard.accept_facebook_terms === false || dataFbOnboard.accept_razorpay_terms === false) ? true : false}
                    >
                      <Icon path={loading ? mdiLoading: mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" {...(loading? {spin: 'spin'}: {})} /> Finish
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>                              
      </div>      
		);
	}
}

export default FbOnboardFinal
