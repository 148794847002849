import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { formatDistanceToNow, format } from 'date-fns';

import Loading from '../../../appComponents/Loading';

import * as DashboardActions from '../../../reducers/Crowdfunding/dashboardReducer';

import './Dashboard.scss';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { dashboard, Dashboard, match } = this.props;
    const { nonprofit } = match.params;
    if (!dashboard.get_status.nonprofit) {
      Dashboard.getNonprofit({ path: [nonprofit] });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dashboard, Dashboard } = this.props;
    const { nonprofit } = dashboard;
    if (nonprofit._id !== prevProps.dashboard.nonprofit._id) {
      Dashboard.getDonations(nonprofit);
      Dashboard.getCampaigns(nonprofit);
    }
  }

  componentWillUnmount() {
    const { Dashboard } = this.props;
    Dashboard.reset();
  }

  campaignListItemHTML = () => {
    const { dashboard } = this.props;
    if (!dashboard.get_status.campaign || dashboard.get_status.campaign === 'pending' && dashboard.campaigns.length === 0) {
      return (<Loading />);
    }
    if (dashboard.campaigns.length === 0) {
      return (
        <i className="align-center">No items available</i>
      );
    }
    return dashboard.campaigns.map(item => (
      <div className="feed-element" key={item.slug}>
        <span className="pull-left">
          <img alt="image" className="img-circle" src={item.creator.profile_pic} />
        </span>
        <div className="media-body ">
          <strong>{item.creator.name}</strong>
          &nbsp;created -&nbsp;
          <a target="_blank" href={item.permalink}>
            <strong>{item.title}</strong>
          </a>
          <br />
          <small className="text-muted">{formatDistanceToNow(new Date(item.created_at))}</small>
        </div>
      </div>
    ));
  }

  recentDonationsHTML = () => {
    const { dashboard } = this.props;
    if (!dashboard.get_status.donation || dashboard.get_status.donation === 'pending' && dashboard.donations.length === 0) {
      return (
      <Loading />
      );
    }
    if (dashboard.donations.length === 0) {
      return (
        <i className="align-center">No items available</i>
      );
    }
    return dashboard.donations.map(item => (
      <tr key={item.slug}>
        <td className="user-info"><span>{item.user.name}</span></td>
        <td>{item.currency_code}&nbsp;{item.amount}</td>
        <td className="text-right text-navy donation-date">{formatDistanceToNow(new Date(item.created_at))}</td>
      </tr>
    ));
  }

  statsHTML = () => {
    const { dashboard } = this.props;
      const { active_campaign_count, raised_amount, settled_amount, supporter_count } = dashboard.nonprofit.statistic || {};
    return (
      <div className="row">
        <div className="card-col col-xl-3 col-lg-3 col-md-3 col-12">
          <div className="ibox">
            <div className="ibox-title">
              <h5><i aria-hidden="true" className="fa fa-heart"></i> Amount Raised</h5>
            </div>
            <div className="ibox-content">
              {(raised_amount) ? (
                <h1 className="no-margins">{raised_amount.toLocaleString('en-in')}</h1>
              ): (
                <Loading />
              )}
            </div>
          </div>
        </div>
        <div className="card-col col-xl-3 col-lg-3 col-md-3 col-12">
          <div className="ibox">
            <div className="ibox-title">
              <h5><i aria-hidden="true" className="fa fa-heart"></i> Amount Disbursed</h5>
            </div>
            <div className="ibox-content">
              {(settled_amount) ? (
                <h1 className="no-margins">{settled_amount.toLocaleString('en-in')}</h1>
              ): (
                <Loading />
              )}
            </div>
          </div>
        </div>
        <div className="card-col col-xl-3 col-lg-3 col-md-3 col-12">
          <div className="ibox">
            <div className="ibox-title">
              <h5><i aria-hidden="true" className="fa fa-heart"></i> Supporters</h5>
            </div>
            <div className="ibox-content">
              {(supporter_count) ? (
                <h1 className="no-margins">{supporter_count.toLocaleString('en-in')}</h1>
              ): (
                <Loading />
              )}
            </div>
          </div>
        </div>
        <div className="card-col col-xl-3 col-lg-3 col-md-3 col-12">
          <div className="ibox">
            <div className="ibox-title">
              <h5><i aria-hidden="true" className="fa fa-heart"></i> Active Fundraisers</h5>
            </div>
            <div className="ibox-content">
              {(active_campaign_count) ? (
                <h1 className="no-margins">{active_campaign_count.toLocaleString('en-in')}</h1>
              ): (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

render() {
  const { match } = this.props;
  const { nonprofit } = match.params;
  return (
    <div className="content-wrapper crf-dashboard">
      <div className="row">
        <div className="col-12 grid-margin">
          {this.statsHTML()}
          <div className="row">
            <div className="card-col col-xl-7 col-lg-7 col-md-7 col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>Recent Donations</h5>
                  <div className="text-right">
                    <Link className="btn btn-primary btn-xs" to={`/crowdfunding/${nonprofit}/live`}><span>View All</span></Link>
                  </div>
                </div>
                <div className="ibox-content">
                  <table className="table table-hover no-margins recent-donation-list">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Amount</th>
                        <th className="text-right">Date <i className="fa fa-clock-o text-navy"></i></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.recentDonationsHTML()}
                    </tbody>
                  </table>
                  <div className="text-right">
                    <span className="label">Recent 10 donations</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-col col-xl-5 col-lg-5 col-md-5 col-12">
              <div className="ibox">
                <div className="ibox-title">
                  <h5>Recent Fundraisers Created</h5>
                  <div className="text-right">
                    <Link className="btn btn-primary btn-xs" to={`/crowdfunding/${nonprofit}/campaigns`}><span>View All</span></Link>
                  </div>
                </div>
                <div className="ibox-content">
                  <div>
                    <div className="feed-activity-list">
                      {this.campaignListItemHTML()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
}


const mapStateToProps = state => ({
  dashboard: state.crowdfundingDashboard,
});

const mapActionToProps = dispatch => ({
  Dashboard: bindActionCreators(DashboardActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Dashboard);
