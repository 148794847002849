import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Sidebar.css';

import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';

import Helpers from '../../utils/helpers';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: '',
    };
  }

  handelClickExpand = (which) => {
    const { expand } = this.state;
    if (expand === which) {
      this.setState({ expand: '' });
    } else {
      this.setState({ expand: which });
    }
  }

  shouldShowItemOnCompliance = (node) => {
    const { params } = this.props;
    if(node.show) {
      const condition = node.show;
      const condition1 = condition[0].split('.');
      const condition2 = condition[1].split('|');
      if (condition1[0] === 'params' && condition1[1] === 'compliance' && params.compliance) {
        return condition2.filter(i => params.compliance.includes(i)).length > 0;
      }
    }
    return true;
  }

  render() {
    const { expand } = this.state;
    const { location, routesList, params } = this.props;    
    const path = Helpers.locationMatch(routesList, location.pathname);
    return (
      <nav className="sidebar sidebar-offcanvas" id="primary-nav">
        <ul className="nav">
          {routesList.sidebar.map((p, index) => (
            (p.child) ? (
              this.shouldShowItemOnCompliance(p) ? (
                <li className={classnames('nav-item', { active: (path === p.path) })} key={p.title}>
                  <a className={classnames('nav-link', { collapsed: expand === index })} onClick={(e) => { e.preventDefault(); this.handelClickExpand(index); }} href={`#${p.title}`} data-toggle="collapse" aria-expanded="false" aria-controls="dropdown">
                    {p.icon}
                    <span className="menu-title">{p.title}</span>
                    <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#979797" />
                  </a>
                  <div className={classnames('collapse', { show: expand === index })} id="dashboard-dropdown">
                    <ul className="nav flex-column sub-menu">
                      {p.child.map(c => (
                        this.shouldShowItemOnCompliance(c) ? (
                          <li className={classnames('nav-item', { active: (path === c.path) })} key={c.title}>
                            <Link className="nav-link" to={Helpers.replaceParamsInURI(params, c.path)}>{c.title}</Link>
                          </li>
                        ) : null
                      ))}
                    </ul>
                  </div>
                </li>
              ) : null
            ) : (
                this.shouldShowItemOnCompliance(p) ? (
                  <li className={classnames('nav-item', { active: (path === p.path) })} key={p.title}>
                    <Link className="nav-link" to={Helpers.replaceParamsInURI(params, p.path)}>
                      {p.icon}
                      <span className="menu-title">{p.title}</span>
                    </Link>
                  </li>
                ) : null
              )
          ))}
        </ul>
      </nav>
    );
  }
}

export default Sidebar;
