import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import ProjectService from '../../services/project-service';

export const GET_SELFSERVE_LIST_PROJECTS = 'GET_SELFSERVE_LIST_PROJECTS';
export const GET_SELFSERVE_LIST_PROJECTS_SUCCESS = 'GET_SELFSERVE_LIST_PROJECTS_SUCCESS';
export const GET_SELFSERVE_LIST_PROJECTS_FAIL = 'GET_SELFSERVE_LIST_PROJECTS_FAIL';

export const PROJECT_LIST_RESET = 'PROJECT_LIST_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  projects: [],
  get_status: {},
  load_status: {},
};

export const projectSelfServeListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SELFSERVE_LIST_PROJECTS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'pending',
        },
      };
    case GET_SELFSERVE_LIST_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
        get_status: {
          ...state.get_status,
          project: 'success',
        },
        load_status: {
          ...state.load_status,
          project: action.load_status,
        }
      };
    case GET_SELFSERVE_LIST_PROJECTS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'failed',
        },
      };
    case PROJECT_LIST_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getProjects(request) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_SELFSERVE_LIST_PROJECTS,
    });
    const currentProjects = getState().projectSelfServeList.projects;
    const searchQuery =  request.q;
    delete request.q;
    const params = {
      skip: currentProjects.length,
      status: 'published',
      limit: 20,
      sort: '-updated_at',
      edits: undefined,
      ...request
    };
    if (searchQuery && searchQuery !== '') {
      params.q = searchQuery;
    }
    return ProjectService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_SELFSERVE_LIST_PROJECTS_SUCCESS,
            projects: currentProjects,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_SELFSERVE_LIST_PROJECTS_SUCCESS,
            projects: [...currentProjects, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_SELFSERVE_LIST_PROJECTS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: PROJECT_LIST_RESET,
    });
  };
}
