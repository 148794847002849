import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiPlus, mdiTrashCan } from '@mdi/js';

import Helpers from '../../utils/helpers';

class NonprofitOtherDetailsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: { _id: props.value._id },
    };
    const leaders = props.value.others && props.value.others.leaders;
    const { value } = props;
    const compliance = value['required_compliances'];
    if ((!leaders || leaders.length === 0) && compliance.includes('giveassured')) {
      this.handelInputChange(['nonprofit', 'others', 'leaders'], [{
        name: '',
        designation: 'ceo',
        profile: '',
      }, {
        name: '',
        designation: 'founder',
        profile: '',
      }]);
    }
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { value } = this.props;
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    if (onSubmit) {
      onSubmit(changes);
    }
  }

  addStaffRelatedBoard = () => {
    const staff_related_board = this.getInputData(['nonprofit', 'others', 'staff_related_board']);
    const staff = {
      name: '',
      designation: '',
      annual_salary: '',
      relation: '',
    };
    this.handelInputChange(['nonprofit', 'others', 'staff_related_board', staff_related_board.length], staff);
  }

  addLeader = (designation) => {
    const leaders = this.getInputData(['nonprofit', 'others', 'leaders']);
    const leader = {
      name: '',
      designation: designation,
      profile: '',
    };
    this.handelInputChange(['nonprofit', 'others', 'leaders', leaders.length], leader);
  }

  render() {
    const { children, value } = this.props;
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">Other details</h3>
                </div>
                {this.shouldShowItemOnCompliance('giveassured') ? (
                  <div>
                    <h4 className="mr-b-20 mr-t-20">Organisation Leaders</h4>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label> Name of CEO/Current leader</label>
                          {this.getInputErrors(['nonprofit', 'others', 'leaders', 0, 'name'])}
                          <input className="form-control" type="text" placeholder="Please fill full name of Current CEO/Head of the organisation" value={this.getInputData(['nonprofit', 'others', 'leaders', 0, 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'leaders', 0, 'name'], e); }} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label> Profile of CEO/Current leader</label>
                          {this.getInputErrors(['nonprofit', 'others', 'leaders', 0, 'profile'])}
                          <textarea className="form-control" placeholder="Please add 300 words about the current CEO/Head of the organisation" value={this.getInputData(['nonprofit', 'others', 'leaders', 0, 'profile'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'leaders', 0, 'profile'], e); }} />
                        </div>
                      </div>
                    </div>
                    {this.getInputData(['nonprofit', 'others', 'leaders']) && this.getInputData(['nonprofit', 'others', 'leaders']).map((founder, i) => (
                      founder.designation !== 'ceo' ? (
                        <div className="row" key={i}>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label> Name of Founder</label>
                              {this.getInputErrors(['nonprofit', 'others', 'leaders', i, 'name'])}
                              <input className="form-control" type="text" placeholder="Please fill full name(s) of Founder(s) of the organisation" value={this.getInputData(['nonprofit', 'others', 'leaders', i, 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'leaders', i, 'name'], e); }} />
                            </div>
                          </div>
                          <div className="col-md-10">
                            <div className="form-group">
                              <label> Profile of Founder(s)</label>
                              {this.getInputErrors(['nonprofit', 'others', 'leaders', i, 'profile'])}
                              <textarea className="form-control" placeholder="Please add 300 words about the current CEO/Head of the organisation" value={this.getInputData(['nonprofit', 'others', 'leaders', i, 'profile'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'leaders', i, 'profile'], e); }} />
                            </div>
                          </div>
                          <div className="col-md-2 dspl-flex flx-v-center flx-h-right">
                            <div className="form-group">
                              <button type="button" className="btn btn-light" onClick={(e) => { e.preventDefault(); this.handelInputChange(['nonprofit', 'others', 'leaders', i], null); }}>
                                <Icon path={mdiTrashCan} className="menu-arrow" size={0.60} color="#212529" />&nbsp;Remove
                    </button>
                            </div>
                          </div>
                        </div>
                      ) : null
                    ))}
                  </div>
                ) : null}

                {this.shouldShowItemOnCompliance('giveassured') ? (
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <button type="button" className="btn btn-success flt-right" onClick={(e) => { e.preventDefault(); this.addLeader('founder'); }}>
                          <Icon path={mdiPlus} className="menu-arrow" size={0.60} color="#ffffff" />&nbsp;Add
                        </button>
                        <div className="clear"></div>
                      </div>
                    </div>
                    <h4 className="mr-b-20 mr-t-20">Human resources</h4>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label> Do you have a Personnel Policy, which is known to all staff?</label>
                          {this.getInputErrors(['nonprofit', 'others', 'personnel_policy'])}
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-radio">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="personnel_policy" value="Yes" checked={this.getInputData(['nonprofit', 'others', 'personnel_policy']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'personnel_policy'], e); }} /> Yes <i className="input-helper"></i></label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-radio">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="personnel_policy" value="No" checked={this.getInputData(['nonprofit', 'others', 'personnel_policy']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'personnel_policy'], e); }} /> No <i className="input-helper"></i></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label> Are any of the staff members related to any Board members? </label>
                          {this.getInputErrors(['nonprofit', 'others', 'is_staff_related_board'])}
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-radio">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="is_staff_related_board" value="Yes" checked={this.getInputData(['nonprofit', 'others', 'is_staff_related_board']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'is_staff_related_board'], e); }} /> Yes <i className="input-helper"></i></label>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-radio">
                                <label className="form-check-label">
                                  <input type="radio" className="form-check-input" name="is_staff_related_board" value="No" checked={this.getInputData(['nonprofit', 'others', 'is_staff_related_board']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'is_staff_related_board'], e); }} /> No <i className="input-helper"></i></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {this.getInputData(['nonprofit', 'others', 'is_staff_related_board']) === 'Yes' ? (
                          <div className="form-group">
                            <label> Please give the details of the staff member (s) in the table below:</label>
                            <div className="row">
                              <div className="col-md-3">
                                <p>Name</p>
                              </div>
                              <div className="col-md-3">
                                <p>Designation</p>
                              </div>
                              <div className="col-md-2">
                                <p>Annual Salary(in Rs.)</p>
                              </div>
                              <div className="col-md-3">
                                <p>Relation with Board Member</p>
                              </div>
                              <div className="col-md-1">
                                <p>...</p>
                              </div>
                            </div>
                            {this.getInputData(['nonprofit', 'others', 'staff_related_board']) && this.getInputData(['nonprofit', 'others', 'staff_related_board']).map((m, i) => (
                              <div className="row tm-divider" key={i}>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    {this.getInputErrors(['nonprofit', 'others', 'staff_related_board', i, 'name'])}
                                    <input className="form-control" type="text" placeholder="Name" value={this.getInputData(['nonprofit', 'others', 'staff_related_board', i, 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'staff_related_board', i, 'name'], e); }} />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    {this.getInputErrors(['nonprofit', 'others', 'staff_related_board', i, 'designation'])}
                                    <input className="form-control" type="text" placeholder="Designation" value={this.getInputData(['nonprofit', 'others', 'staff_related_board', i, 'designation'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'staff_related_board', i, 'designation'], e); }} />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    {this.getInputErrors(['nonprofit', 'others', 'staff_related_board', i, 'annual_salary'])}
                                    <input className="form-control" type="number" placeholder="Annual Salary" value={this.getInputData(['nonprofit', 'others', 'staff_related_board', i, 'annual_salary'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'staff_related_board', i, 'annual_salary'], e); }} />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    {this.getInputErrors(['nonprofit', 'others', 'staff_related_board', i, 'relation'])}
                                    <input className="form-control" type="text" placeholder="Relation with Board Member" value={this.getInputData(['nonprofit', 'others', 'staff_related_board', i, 'relation'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'staff_related_board', i, 'relation'], e); }} />
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="form-group">
                                    <button type="button" className="btn btn-light" onClick={(e) => { e.preventDefault(); this.handelInputChange(['nonprofit', 'others', 'staff_related_board', i], null) }}>
                                      Remove
                                </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="row">
                              <div className="col-md-12">
                                <button type="button" className="btn btn-success mr-t-20 flt-right" onClick={(e) => { e.preventDefault(); this.addStaffRelatedBoard(); }}>
                                  <Icon path={mdiPlus} className="menu-arrow" size={0.60} color="#ffffff" />&nbsp;Add
                          </button>
                                <div className="clear"></div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.shouldShowItemOnCompliance('fbassured') ? (
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mr-b-20 mr-t-20">Facebook Admin</h4>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Name</label>
                            {this.getInputErrors(['nonprofit', 'others', 'facebook_admin', 'name'])}
                            <input className="form-control" type="text" placeholder="Admin name" value={this.getInputData(['nonprofit', 'others', 'facebook_admin', 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'facebook_admin', 'name'], e); }} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Designation</label>
                            {this.getInputErrors(['nonprofit', 'others', 'facebook_admin', 'designation'])}
                            <input className="form-control" type="text" placeholder="Designation" value={this.getInputData(['nonprofit', 'others', 'facebook_admin', 'designation'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'facebook_admin', 'designation'], e); }} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Date of birth</label>
                            {this.getInputErrors(['nonprofit', 'others', 'facebook_admin', 'date_of_birth'])}
                            <input className="form-control" type="text"
                              placeholder="Date of birth"
                              value={this.getInputData(['nonprofit', 'others', 'facebook_admin', 'date_of_birth'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'others', 'facebook_admin', 'date_of_birth'], e); }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default NonprofitOtherDetailsEdit;
