import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import Icon from '@mdi/react';
import { mdiPlayCircleOutline, mdiPlus, mdiChevronRight, mdiTrashCan } from '@mdi/js';

import ModalPopUp from '../ModalPopUp';
import ImageCropUpload from '../ImageCropUpload';

class GalleryEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageModal: false,
      videoModal: false,
      YoutubeURL: '',
      changes: {_id: props.value._id},
    };
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    if (onSubmit) {
      onSubmit(changes);
    }
  }

  addMedia = (uri, type) => {
    const gallery = this.getInputData(['', 'gallery']);
    let finalURI =  uri;
    if(type === 'gallery_video') {
      const youtubeId = Helpers.getYoutubeId(uri);
      if (youtubeId !== null) {
        finalURI = `https://www.youtube.com/embed/${youtubeId}`;
      }
    }
    const media = {
      label: "",
      media_type: type,
      uri: finalURI,
    };
    this.handelInputChange(['', 'gallery', gallery.length], media);
    this.setState({ YoutubeURL: '', videoModal: false, imageModal: false });
  }
  removeMedia = (index) => {
    this.handelInputChange(['', 'gallery', index], null);
  }

  closeImageModal = () => {
    this.setState({ imageModal: false });
  }

  openImageModal = () => {
    this.setState({ imageModal: true });
  }

  closeVideoModal = () => {
    this.setState({ videoModal: false });
  }

  openVideoModal = () => {
    this.setState({ videoModal: true });
  }

  YoutubeURLChange = (e) => {
    e.preventDefault();
    this.setState({ YoutubeURL: e.target.value });
  }

  render() {
    const { children } = this.props;
    const { imageModal, videoModal, YoutubeURL } = this.state;
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">Gallery</h3>
                </div>
                <div className="gallery-wrapper mr-b-20">
                  {/* <h6 className="mr-b-20">Album name</h6> */}
                  <div className="gl-list">
                    {this.getInputData(['nonprofit', 'gallery']) ? this.getInputData(['nonprofit', 'gallery']).map((d, i) => (
                      (d.media_type === 'gallery_image') ? (
                        <div className="gl-item" key={d.uri}>
                          <div className="img-wrapper">
                            {this.getInputErrors(['', 'gallery', i, 'uri'])}
                            <img src={d.uri} alt={d.label} />
                            <div className="img-delete" onClick={(e) => { e.preventDefault(); this.removeMedia(i); }}>
                              <Icon path={mdiTrashCan} size={.8} color="#ffffff" />
                            </div>
                          </div>
                          <div className="form-group">
                            {this.getInputErrors(['', 'gallery', i, 'label'])}
                            <input type="text" className="form-control" placeholder="Description" value={d.label} onChange={(e) => { e.preventDefault(); this.handelInputChange(['nonprofit', 'gallery', i, 'label'], e) }} />
                          </div>
                        </div>
                      ) : null
                    )) : null}
                    <div className="gl-item">
                      <div className="img-wrapper">
                        <button type="button" onClick={(e) => { e.preventDefault(); this.openImageModal() }}><Icon path={mdiPlus} className="menu-arrow" size={0.60} /> <span>Add Image</span></button>
                      </div>
                      {imageModal ? (
                        <ModalPopUp onClose={this.closeImageModal} title="Please select Gallery image" size="lg">
                          <ImageCropUpload label="Gallery" height={900} width={1600} value="" onChange={(e) => { this.addMedia(e, "gallery_image") }} error={this.getInputErrors(['nonprofit', 'gallery'])} />
                        </ModalPopUp>
                      ) : null}
                    </div>
                  </div>
                  <div className="gl-list">
                    {this.getInputData(['nonprofit', 'gallery']) ? this.getInputData(['nonprofit', 'gallery']).map((d, i) => (
                      (d.media_type === 'gallery_video') ? (
                        <div className="gl-item" key={d.uri}>
                          <div className="img-wrapper">
                            <img src={`https://i1.ytimg.com/vi/${Helpers.getYoutubeId(d.uri)}/mqdefault.jpg`} alt="" />
                            <Icon path={mdiPlayCircleOutline} className="menu-arrow play-icon" size={1.5} color="#c4302b" />
                            <div className="img-delete" onClick={(e) => { e.preventDefault(); this.removeMedia(i); }}>
                              <Icon path={mdiTrashCan} size={.8} color="#ffffff" />
                            </div>
                          </div>
                          {this.getInputErrors(['', 'gallery', i, 'uri'])}
                          <div className="form-group">
                            {this.getInputErrors(['', 'gallery', i, 'label'])}
                            <input type="text" className="form-control" placeholder="Description" value={d.label} onChange={(e) => { e.preventDefault(); this.handelInputChange(['nonprofit', 'gallery', i, 'label'], e) }} />
                          </div>
                        </div>
                      ) : null
                    )) : null}
                    <div className="gl-item">
                      <div className="img-wrapper">
                        <button type="button" onClick={(e) => { e.preventDefault(); this.openVideoModal() }}><Icon path={mdiPlus} className="menu-arrow" size={0.60} /> <span>Add Video</span></button>
                      </div>
                      {videoModal ? (
                        <ModalPopUp onClose={this.closeVideoModal} title="Please add Gallery video as youtube URL">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group zoom-wrapper">
                                <label>URL</label>
                                <input className="form-control" type="text" value={YoutubeURL} onChange={this.YoutubeURLChange} />
                              </div>
                            </div>
                            <div className="col-md-12 uimg-wrapper">
                              <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.addMedia(YoutubeURL, 'gallery_video') }}>
                                <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                                &nbsp;Save
                              </button>
                            </div>
                          </div>
                        </ModalPopUp>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default GalleryEdit;
