import Helpers from '../utils/helpers';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

const initialState = {
  nextId: 0,
  messages: [
    // {
    //   id: 0,
    //   type: 'ERROR','WARNING', 'SUCCESS'
    //   message: '',
    // }
  ],
};

export const notificationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case DISMISS_NOTIFICATION:
      return {
        ...state,
        messages: action.messages,
      };

    case ADD_NOTIFICATION:
      return {
        ...state,
        messages: action.messages,
        nextId: action.id,
      };
    default:
      return state;
  }
};


export const dismissNotification = (id) => {
  return (dispatch, getState) => {
    const state = getState().notification;
    const messages = state.messages.filter((message) => {
      if (message.id !== id) {
        return true;
      }
      return false;
    });
    dispatch({
      type: DISMISS_NOTIFICATION,
      messages,
    });
  };
};

export const addNotification = (message, type = 'ERROR') => {
  return (dispatch, getState) => {
    const state = getState().notification;
    const probableId = Helpers.objSearch(state.messages, message, 'message');
    let newMessages = [];
    const nextId = state.nextId;
    if (message && message.length >= 0) {
      if (probableId >= 0) {
        newMessages = state.messages.filter((item) => {
          if (item.id !== probableId) {
            return true;
          }
          return false;
        });
      } else {
        newMessages = [...state.messages];
      }
      newMessages.push({ id: nextId, type, message });
      dispatch({
        type: ADD_NOTIFICATION,
        messages: newMessages,
        id: nextId + 1,
      });
    }
  };
};
