import UserService from '../services/user-service';

import * as NotificationAction from './notificationReducer';

export const LOGIN_VERIFY = 'LOGIN_VERIFY';
export const LOGIN_VERIFY_SUCCESS = 'LOGIN_VERIFY_SUCCESS';
export const LOGIN_VERIFY_FAIL = 'LOGIN_VERIFY_FAIL';

export const LOGIN_OTP_SET = 'LOGIN_OTP_SET';
export const LOGIN_VIEW_SET = 'LOGIN_VIEW_SET';

export const LOGIN_AUTHORISE = 'LOGIN_AUTHORISE';
export const LOGIN_AUTHORISE_SUCCESS = 'LOGIN_AUTHORISE_SUCCESS';
export const LOGIN_AUTHORISE_FAIL = 'LOGIN_AUTHORISE_FAIL';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

export const USER_GET = 'USER_GET';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAIL = 'USER_GET_FAIL';
export const USER_SET = 'USER_SET';
export const USER_SET_SUCCESS = 'USER_SET_SUCCESS';
export const USER_SET_FAIL = 'USER_SET_FAIL';

const initialState = {
  view: {
    type: 'email',
    otpTimeout: 0,
  },
  user: {
  },
  loading: false,
  user_is_valid: null,
};

export const loginReducer = (state = initialState, action = {}) => {  
  switch (action.type) {
    case LOGIN_VERIFY:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        view: action.view,
      };
    case LOGIN_VERIFY_FAIL:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_AUTHORISE:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_AUTHORISE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        user_is_valid: true,
        view: {
          type: 'mobile',
          otpTimeout: 0,
        },
      };
    case LOGIN_AUTHORISE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
        user_is_valid: false,
      };
    case USER_GET:
      return {
        ...state,
        loading: true,
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        user_is_valid: true,
      };
    case USER_GET_FAIL:
      return {
        ...state,
        loading: false,
        user_is_valid: false,
      };
    case USER_SET:
      return {
        ...state,
        loading: true,
      };
    case USER_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        user_is_valid: true,
      };
    case USER_SET_FAIL:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_OTP_SET:
      return {
        ...state,
        view: {
          ...state.view,
          otpTimeout: action.seconds,
        },
      };
    case LOGIN_VIEW_SET:
      return {
        ...state,
        view: {
          ...state.type,
          type: action.view,
        },
      };
    default:
      return state;
  }
};

export function verify(data, viewType) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_VERIFY,
    });
    return UserService.verify(data)
      .then((response) => {
        const view = {
          type: viewType,
          otpTimeout: parseInt(response.data.otp_timeout, 10),
        };
        dispatch({
          type: LOGIN_VERIFY_SUCCESS,
          view,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOGIN_VERIFY_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function get() {
  return (dispatch) => {
    dispatch({
      type: USER_GET,
    });
    return UserService.get()
      .then((response) => {
        dispatch({
          type: USER_GET_SUCCESS,
          user: response.user,
        });
        return response;
      })
      .catch((error) => {        
        dispatch({
          type: USER_GET_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function set(data) {
  return (dispatch) => {
    dispatch({
      type: USER_SET,
    });
    return UserService.set(data)
      .then((response) => {
        dispatch({
          type: USER_SET_SUCCESS,
          user: response.user,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: USER_SET_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function authoriseUser(data) {
  return (dispatch) => {
        dispatch({
          type: LOGIN_AUTHORISE_SUCCESS,
          user: data.user,
        });
        return data;
  };
}

export function authorise(data) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_AUTHORISE,
    });
    return UserService.authorise(data)
      .then((response) => {
        dispatch({
          type: LOGIN_AUTHORISE_SUCCESS,
          user: response.user,
        });
        return response;
      })
      .catch((error) => {
        const {value}=error
        dispatch({
          type: LOGIN_AUTHORISE_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message || value?.error));
      });
  };
}

export function deauthorise() {
  return (dispatch) => {
    return UserService.deauthorise().then((response) => {
      dispatch({
        type: LOGIN_DEAUTHORISE,
      });
      return response;
    });
  };
}

export function otpSetSeconds(seconds) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_OTP_SET,
      seconds,
    });
  };
}

export function loginViewChange(view) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_VIEW_SET,
      view,
    });
  };
}
