import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';

export const GET_DOCUMENT_CHECK_NONPROFITS = 'GET_DOCUMENT_CHECK_NONPROFITS';
export const GET_DOCUMENT_CHECK_NONPROFITS_SUCCESS = 'GET_DOCUMENT_CHECK_NONPROFITS_SUCCESS';
export const GET_DOCUMENT_CHECK_NONPROFITS_FAIL = 'GET_DOCUMENT_CHECK_NONPROFITS_FAIL';

export const GET_CONTENT_CHECK_NONPROFITS = 'GET_CONTENT_CHECK_NONPROFITS';
export const GET_CONTENT_CHECK_NONPROFITS_SUCCESS = 'GET_CONTENT_CHECK_NONPROFITS_SUCCESS';
export const GET_CONTENT_CHECK_NONPROFITS_FAIL = 'GET_CONTENT_CHECK_NONPROFITS_FAIL';

export const GET_CAUSE_CHECK_NONPROFITS = 'GET_CAUSE_CHECK_NONPROFITS';
export const GET_CAUSE_CHECK_NONPROFITS_SUCCESS = 'GET_CAUSE_CHECK_NONPROFITS_SUCCESS';
export const GET_CAUSE_CHECK_NONPROFITS_FAIL = 'GET_CAUSE_CHECK_NONPROFITS_FAIL';

export const GET_FINAL_APPROVAL_NONPROFITS = 'GET_FINAL_APPROVAL_NONPROFITS';
export const GET_FINAL_APPROVAL_NONPROFITS_SUCCESS = 'GET_FINAL_APPROVAL_NONPROFITS_SUCCESS';
export const GET_FINAL_APPROVAL_NONPROFITS_FAIL = 'GET_FINAL_APPROVAL_NONPROFITS_FAIL';

export const GET_ALL_APPROVAL_NONPROFITS = 'GET_ALL_APPROVAL_NONPROFITS';
export const GET_ALL_APPROVAL_NONPROFITS_SUCCESS = 'GET_ALL_APPROVAL_NONPROFITS_SUCCESS';
export const GET_ALL_APPROVAL_NONPROFITS_FAIL = 'GET_ALL_APPROVAL_NONPROFITS_FAIL';

export const APPROVAL_RESET = 'APPROVAL_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  document_nonprofits: [],
  content_nonprofits: [],
  cause_nonprofits: [],
  final_nonprofits: [],
  all_nonprofits: [],
  get_status: {},
  load_status: {},
};

export const pendingApprovalsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DOCUMENT_CHECK_NONPROFITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          document_nonprofit: 'pending',
        },
      };
    case GET_DOCUMENT_CHECK_NONPROFITS_SUCCESS:
      return {
        ...state,
        document_nonprofits: action.nonprofits,
        get_status: {
          ...state.get_status,
          document_nonprofit: 'success',
        },
        load_status: {
          ...state.load_status,
          document_nonprofit: action.load_status,
        }
      };
    case GET_DOCUMENT_CHECK_NONPROFITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          document_nonprofit: 'failed',
        },
      };
    case GET_CONTENT_CHECK_NONPROFITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          content_nonprofit: 'pending',
        },
      };

    case GET_CONTENT_CHECK_NONPROFITS_SUCCESS:
      return {
        ...state,
        content_nonprofits: action.nonprofits,
        get_status: {
          ...state.get_status,
          content_nonprofit: 'success',
        },
        load_status: {
          ...state.load_status,
          content_nonprofit: action.load_status,
        }
      };
    case GET_CONTENT_CHECK_NONPROFITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          content_nonprofit: 'failed',
        },
      };
    case GET_CAUSE_CHECK_NONPROFITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          cause_nonprofit: 'pending',
        },
      };

    case GET_CAUSE_CHECK_NONPROFITS_SUCCESS:
      return {
        ...state,
        cause_nonprofits: action.nonprofits,
        get_status: {
          ...state.get_status,
          cause_nonprofit: 'success',
        },
        load_status: {
          ...state.load_status,
          cause_nonprofit: action.load_status,
        }
      };
    case GET_CAUSE_CHECK_NONPROFITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          cause_nonprofit: 'failed',
        },
      };
    case GET_FINAL_APPROVAL_NONPROFITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          final_nonprofit: 'pending',
        },
      };

    case GET_FINAL_APPROVAL_NONPROFITS_SUCCESS:
      return {
        ...state,
        final_nonprofits: action.nonprofits,
        get_status: {
          ...state.get_status,
          final_nonprofit: 'success',
        },
        load_status: {
          ...state.load_status,
          final_nonprofit: action.load_status,
        }
      };
    case GET_FINAL_APPROVAL_NONPROFITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          final_nonprofit: 'failed',
        },
      };

      // all pending approval
        case GET_ALL_APPROVAL_NONPROFITS:
        return {
            ...state,
            get_status: {
              ...state.get_status,
              all_nonprofit: 'pending',
            },
          };
        case GET_ALL_APPROVAL_NONPROFITS_SUCCESS:
          return {
            ...state,
            get_status: {
              ...state.get_status,
              all_nonprofit: 'success',
            },
          };
        case GET_ALL_APPROVAL_NONPROFITS_FAIL:
          return {
            ...state,
            get_status: {
              ...state.get_status,
              all_nonprofit: 'failed',
            },
          };

      case APPROVAL_RESET:
        return {
          ...initialState,
        };
      case LOGIN_DEAUTHORISE:
        return {
          ...initialState,
        };
    default:
      return state;
  }
};

export function getDocumentNonprofits(q) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_DOCUMENT_CHECK_NONPROFITS,
    });
    const currentNonprofits = getState().pendingApprovals.document_nonprofits;
    const params = {
      status: 'document_check',
      skip: currentNonprofits.length,
      limit: 5,
      sort: '-updated_at',
    };
    if (q !== '') {
      params.q = q;
    }
    return NonProfitService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_DOCUMENT_CHECK_NONPROFITS_SUCCESS,
            nonprofits: currentNonprofits,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_DOCUMENT_CHECK_NONPROFITS_SUCCESS,
            nonprofits: [...currentNonprofits, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DOCUMENT_CHECK_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getcontentNonprofits(q) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CONTENT_CHECK_NONPROFITS,
    });
    const currentNonprofits = getState().pendingApprovals.content_nonprofits;
    const params = {
      status: 'content_check',
      skip: currentNonprofits.length,
      limit: 5,
      sort: '-updated_at',
    };
    if (q !== '') {
      params.q = q;
    }
    return NonProfitService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_CONTENT_CHECK_NONPROFITS_SUCCESS,
            nonprofits: currentNonprofits,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_CONTENT_CHECK_NONPROFITS_SUCCESS,
            nonprofits: [...currentNonprofits, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CONTENT_CHECK_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getCauseNonprofits(q) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CAUSE_CHECK_NONPROFITS,
    });
    const currentNonprofits = getState().pendingApprovals.cause_nonprofits;
    const params = {
      status: 'cause_check',
      skip: currentNonprofits.length,
      limit: 5,
      sort: '-updated_at',
    };
    if (q !== '') {
      params.q = q;
    }
    return NonProfitService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_CAUSE_CHECK_NONPROFITS_SUCCESS,
            nonprofits: currentNonprofits,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_CAUSE_CHECK_NONPROFITS_SUCCESS,
            nonprofits: [...currentNonprofits, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CAUSE_CHECK_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getFinalNonprofits(q) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_FINAL_APPROVAL_NONPROFITS,
    });
    const currentNonprofits = getState().pendingApprovals.final_nonprofits;
    const params = {
      status: 'final_approval',
      skip: currentNonprofits.length,
      limit: 5,
      sort: '-updated_at',
    };
    if (q !== '') {
      params.q = q;
    }
    return NonProfitService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_FINAL_APPROVAL_NONPROFITS_SUCCESS,
            nonprofits: currentNonprofits,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_FINAL_APPROVAL_NONPROFITS_SUCCESS,
            nonprofits: [...currentNonprofits, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_FINAL_APPROVAL_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}


export function getAllPendingNonprofits(request) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ALL_APPROVAL_NONPROFITS,
    });
    const currentNonprofits = getState().pendingApprovals.all_nonprofits;
    const params = {
      status: 'document_check,content_check,content_check,final_approval',
      skip: currentNonprofits.length,
      limit: 1000,
      sort: '-updated_at',
    };
    if (request.q && request.q !== '') {
      params.q = request.q;
    }
    return NonProfitService.get({ params })
      .then((response) => {
        dispatch({
            type: GET_ALL_APPROVAL_NONPROFITS_SUCCESS,
          });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_FINAL_APPROVAL_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: APPROVAL_RESET,
    });
  };
}
