import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';

class NonprofitGoverningBodyRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
    };
  }

  switchView = (view) => {
    this.setState({ view: view });
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if (val === '' && key[1] !== 'video') {
      return this.na;
    }
    return val;
  }

  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if (to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if (to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if (to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  render() {
    const { children, tabs } = this.props;
    const { view } = this.state;
    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">Governing body details</h3>
                <div className="heading-bar-btn">
                  {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab => {
                    if (tab === view) {
                      return (
                        <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                      );
                    }
                    return (
                      <button type="button" className="btn btn-outline-secondary btn-left" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                    );
                  })}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'available']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Do you have a Statutory Governing Body?</h6>
                      <small>Board of Directors/Board of Trustees/Executive Committee can be considered</small>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'available'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'strength']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> What is the current strength of the body?</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'strength'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <div className="row">
                {/* {this.shouldShowItemOnCompliance('giveassured') ? (
                  <div className="col-lg-12 col-md-12">
                    <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'salary_process']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                      <div>
                        <h6> If any Board member received remuneration what was the process adopted to determine the salary?</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'salary_process'])}</p>
                      </div>
                    </ApprovalsRejectionsButtons>
                  </div>
                ) : null} */}
                {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'composition']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Current list of members comprising the body </h6>
                      <small>Please upload document containing the current list of members of the body on your letterhead (please include Name and Year of Appointment)</small>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'composition']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'governing_body', 'composition'])}>Preview</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div> ) : null }
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'number_of_meetings']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> How many meetings of Board were held in the previous Financial Year</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'number_of_meetings'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'minutes_of_meeting']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Minutes of Meeting </h6>
                      <small>Upload minutes of AGM held within the previous Financial Year or any latest meeting held within the previous Financial Year</small>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'minutes_of_meeting']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'governing_body', 'minutes_of_meeting'])}>Preview</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div> ) : null }
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'composition_change_as_of_date']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Was there a change in the Board composition as on date?</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'composition_change_as_of_date'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'proof_of_composition']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6>Proof of change in Board of Directors</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'proof_of_composition']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'governing_body', 'proof_of_composition'])}>Preview</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div> ) : null }
              </div>
              <div className="row">
              {this.shouldShowItemOnCompliance('fbassured') ? (
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'approving_signatory']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                    <h6> Document mentioning authorised signatories’ names</h6>
                    <small>Please upload document on your letterhead with attestation mentioning current authorised signatories’ names</small>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'approving_signatory']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'governing_body', 'approving_signatory'])}>Preview</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div> ) : null }
              </div>
              {this.shouldShowItemOnCompliance('fbassured') ? (
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'members']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> KYC details of members of the body</h6>
                      <small>Fill details of upto 10 members</small>
                      {(this.getInputData(['nonprofit', 'governing_body', 'members']) && this.getInputData(['nonprofit', 'governing_body', 'members']).length > 0) ? (
                        <table className="table table-bordered text-muted mr-b-20">
                          <thead>
                            <tr>
                              <th>Name <br></br><small>Please fill name as it appears on PAN Card</small> </th>
                              <th>Date of Birth</th>
                              <th>Gender</th>
                              <th>Position on Board</th>
                              <th>Remuneration</th>
                              <th>PAN/Passport</th>
                              <th>Address proof <br></br><small>Please upload both sides of Aadhar Card</small></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.getInputData(['nonprofit', 'governing_body', 'members']) !== this.na && this.getInputData(['nonprofit', 'governing_body', 'members']).length > 0 ? (
                              this.getInputData(['nonprofit', 'governing_body', 'members']).map((m, i) => (
                                <tr key={m.name+i}>
                                  <td>{m.name}</td>
                                  <td>{m.date_of_birth}</td>
                                  <td>{m.gender}</td>
                                  <td>{m.position}</td>
                                  <td>{m.remuneration}</td>
                                  <td>{m.id} {m.id_doc ? (<a className="text-muted" target="_blank" href={m.id_doc}>(Preview)</a>) : this.na}</td>
                                  <td>{m.address_doc ? (<a className="text-muted" target="_blank" href={m.address_doc}>Preview</a>) : this.na}</td>
                                </tr>
                              ))
                            ) : (this.getInputData(['nonprofit', 'governing_body', 'members']))}
                          </tbody>
                        </table>
                      ) : (
                          <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'members'])}</p>
                        )}
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div> ) : null }
              {this.shouldShowItemOnCompliance('fbassured') ? (
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'maximum_strength']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Maximum possible strength of the body as per the Constitution of the organisation</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'maximum_strength'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'minimum_strength']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Minimum possible strength of the body as per the Constitution of the organisation</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'minimum_strength'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div> ) : null }              
              <div className="row">
                {this.shouldShowItemOnCompliance('fbassured') ? (
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'moa']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Memorandum of Association+Articles of Association/Trust Deed/By-laws</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'moa']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'governing_body', 'moa'])}>Preview</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                ) : null }
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'governing_body', 'quorum']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Quorum of Board Meetings, as stipulated in your Articles of Association/ Trust Deed</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'governing_body', 'quorum'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NonprofitGoverningBodyRead;
