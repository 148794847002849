import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {  isWithinInterval } from 'date-fns';

const DateInput10BdBe = ({onChange}) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const currentDate = new Date();

const currentFinancialYearStart = new Date(currentDate.getFullYear(), 3, 1);

const financialYearStart = currentDate < currentFinancialYearStart
  ? new Date(currentDate.getFullYear() - 1, 3, 1)
  : currentFinancialYearStart;

const financialYearEnd = new Date(financialYearStart.getFullYear() + 1, 2, 31);






  const handleDateChange = (date) => {
    if (isWithinInterval(date, { start: financialYearStart, end: financialYearEnd })) {
      setSelectedDate(date);
      onChange(date)
    } else {
      setSelectedDate(null);
      console.log('Please select a date within the current financial year.');
    }
  };

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="dd/MM/yyyy"
      minDate={financialYearStart}
      maxDate={financialYearEnd}
      placeholderText="Select a date"
      autoFocus
    />
  );
};

export default DateInput10BdBe;
