import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import ProjectService from '../../services/project-service';

export const GET_FINAL_APPROVAL_PROJECTS = 'GET_FINAL_APPROVAL_PROJECTS';
export const GET_FINAL_APPROVAL_PROJECTS_SUCCESS = 'GET_FINAL_APPROVAL_PROJECTS_SUCCESS';
export const GET_FINAL_APPROVAL_PROJECTS_FAIL = 'GET_FINAL_APPROVAL_PROJECTS_FAIL';

export const PROJECT_APPROVAL_RESET = 'PROJECT_APPROVAL_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  final_projects: [],
  get_status: {},
  load_status: {},
};

export const projectApprovalsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_FINAL_APPROVAL_PROJECTS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          final_project: 'pending',
        },
      };
    case GET_FINAL_APPROVAL_PROJECTS_SUCCESS:
      return {
        ...state,
        final_projects: action.projects,
        get_status: {
          ...state.get_status,
          final_project: 'success',
        },
        load_status: {
          ...state.load_status,
          final_project: action.load_status,
        }
      };
    case GET_FINAL_APPROVAL_PROJECTS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          final_project: 'failed',
        },
      };
    case PROJECT_APPROVAL_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getFinalProjects(request) {

  return (dispatch, getState) => {
    dispatch({
      type: GET_FINAL_APPROVAL_PROJECTS,
    });
    const currentProjects = getState().projectApprovals.final_projects;
    const searchQuery =  request.q;
    delete request.q;
    const params = {
      parent_status: 'published',
      status: 'final_approval',
      skip: currentProjects.length,
      limit: 10,
      sort: '-updated_at',
      ...request
    };
    if (searchQuery && searchQuery !== '') {
      params.q = searchQuery
    }
    return ProjectService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_FINAL_APPROVAL_PROJECTS_SUCCESS,
            projects: currentProjects,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_FINAL_APPROVAL_PROJECTS_SUCCESS,
            projects: [...currentProjects, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_FINAL_APPROVAL_PROJECTS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: PROJECT_APPROVAL_RESET,
    });
  };
}
