import React from 'react'
import { Link } from 'react-router-dom';
import { formatDistanceToNow, format } from 'date-fns';

const Nonprofits10bdbeDownloaded = (props) => {
    const { tileProps,link } = props || {}
    const {  created_at, updated_at, user, nonprofit_id,nonprofit_name,queryParameter } = tileProps || {};
    const is10BdBeDownlodAvailable =
        user?.profile_pic?.length > 0 &&
        updated_at?.length > 0 &&
        created_at?.length > 0
    const {createdAfter,createdBefore}=queryParameter?.filter ||{}
    return (
        <div className="ngo-tiles">
            <Link to={{ pathname: `${link}${nonprofit_id}/donations`, state: nonprofit_id }}><p className='mt-4 p-1'><strong >{nonprofit_name}</strong></p></Link>
            <div className="ngo-tiles-wrapper">
                {is10BdBeDownlodAvailable ?
                    <div className="np-poc np-user" >
                        <img src={'https://cfstatic.give.do/a5e7242a-5b11-4947-9dd0-4fc18931fe5c.webp'} alt='profile-pic' />
                        <div className='name-wrap'>
                            <span>{user?.name}</span>
                            <span>{user?.email}</span>
                        </div>
                    </div> :
                    <div className="np-poc text-center" >
                        -No downloades yet-
                    </div>
                }

            </div>
            <div className="ngo-tiles-footer">
                <div className="updates">
                    <span>{updated_at ? formatDistanceToNow(new Date(updated_at)) : '-'}</span>
                    <small>Last updated</small>
                </div>
                <div className="updates right text-right">
                    <span>{created_at ? format(new Date(created_at), 'do MMM yyyy') : '-'}</span>
                    <small>Created</small>
                </div>
            </div>
            <div className="ngo-tiles-footer no-border">
                <div className="updates">
                    <span>{createdAfter ? format(new Date(createdAfter), 'do MMM yyyy') : '-'}</span>
                    <small>Duration-From</small>
                </div>
                <div className="updates right text-right">
                    <span>{createdBefore ? format(new Date(createdBefore), 'do MMM yyyy') : '-'}</span>
                    <small>To</small>
                </div>
            </div>

        </div>
    )

}
export default Nonprofits10bdbeDownloaded