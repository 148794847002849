import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiMinusCircle, mdiPlus } from '@mdi/js';
import Editor from '../Editor';
import ModalPopUp from '../ModalPopUp';
import ImageCropUpload from '../ImageCropUpload';
import Helpers from '../../utils/helpers';
import { format } from 'date-fns';

class CampaignEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addImageVideo: false,
      imageModalGallery: false,
      imageModalCampaign: false,
      videoModal: false,
      YoutubeURL: '',
      changes: {_id: props.value._id},
      selectedGalleryItem: 0,
      uploadImageIndex: null,
      anyChangeCampaignTemplates: false
    };
  }

  componentDidMount() {
    if (this.props.value.campaign_templates.length === 0) {
      this.setDefaultCampaignTemplate();
    }
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if(key[1] === 'campaign_templates' && key.length > 2) {
      this.setState({ anyChangeCampaignTemplates : true })
    }
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes, anyChangeCampaignTemplates } = this.state;
    e.preventDefault();
    if (onSubmit) {
      if(changes.hasOwnProperty('campaign_templates')) {
        if(!anyChangeCampaignTemplates) {
          let tempChanges = {...changes};
          delete tempChanges['campaign_templates'];
          this.setState({ changes : tempChanges }, () => {
            onSubmit(this.state.changes)
          })
        } else {
          onSubmit(changes);
        }
      } else {
        onSubmit(changes);
      }
    }
  }

  handleImageDeleteClick = (i, from) => {
    from === 'imageIcon' ? this.setState({selectedGalleryItem : i}) : this.setState({selectedGalleryItem : 0})
  }

  addMedia = (uri, type) => {
    const gallery = this.getInputData(['project', 'campaign_templates', 'gallery']);
    let finalURI =  uri;
    if(type === 'gallery_video') {
      const youtubeId = Helpers.getYoutubeId(uri);
      if (youtubeId !== null) {
        finalURI = `https://www.youtube.com/embed/${youtubeId}`;
      }
    }
    const label = type === 'gallery_image' ? 'Gallery Image' : 'Youtube'
    const media = {
      label: label,
      media_type: type,
      uri: finalURI,
    };
    if(uri !== null) {
      this.handelInputChange(['project', 'campaign_templates', 'gallery', gallery !== '' ? gallery.length : 0], media);
    }  
    this.setState({ YoutubeURL: '', videoModal: false, imageModalGallery: false, addImageVideo: false, selectedGalleryItem: gallery.length>0 ? gallery.length-1 : gallery.length });
  }

  addImage = (e, index) => {
    this.handelInputChange(['project', 'campaign_templates', 'donation_options', index, 'image'], e);
    this.setState({ imageModalCampaign: false, uploadImageIndex: null });
  }

  removeMedia = (index) => {    
    this.handelInputChange(['project', 'campaign_templates', 'gallery', index], null);
  }

  removeCampaignMedia = (index) => {
    this.handelInputChange(['project', 'campaign_templates', 'donation_options', index, 'image'], null);
  }

  closeImageModal = () => {
    this.setState({ imageModalGallery: false, imageModalCampaign: false });
  }

  openImageModal = (type) => {
    if(type === 'Gallery') {
      this.setState({ imageModalGallery: true });
    }
    if(type === 'Campaign') {
      this.setState({ imageModalCampaign: true });
    }
  }

  closeVideoModal = () => {
    this.setState({ videoModal: false });
  }

  openVideoModal = () => {
    this.setState({ videoModal: true });
  }

  YoutubeURLChange = (e) => {
    e.preventDefault();
    this.setState({ YoutubeURL: e.target.value });
  }

  getDateFromDays = (field) => {
    const days = this.getInputData(['project', 'campaign_templates', field]);
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + days);
    return days ? format(newDate, 'yyyy-MM-dd') : null;
  }

  setDefaultCampaignTemplate = () => {
    const campaignTemplateObject = {
      "title" : "",
      "description" : "", 
      "expiry_in_days" : 1,
      "target_amount" : null,
      "donation_options" : [{ amount: 0, currency_code: 'INR', title: null }, 
        { amount: 0, currency_code: 'INR', title: null }, 
        { amount: 0, currency_code: 'INR', title: null }], 
      "donation_type" : 'classic', 
      "gallery" : [], 
      "invite_templates" : { 
        "email" : { "subject" : "", "content" : "" }, 
        "guest_sharing_message" : "", 
        "donor_sharing_message" : "", 
        "creator_sharing_message" : ""
      }, 
      "show_project" : true
    };
    this.handelInputChange(['project', 'campaign_templates'], campaignTemplateObject);
  }

  render() {
    const { children } = this.props;
    const {addImageVideo, imageModalGallery, imageModalCampaign, videoModal, YoutubeURL, selectedGalleryItem} = this.state;

    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">Default Fundraiser Template</h3>
                </div>

                {this.props.login.user.is_admin && 
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <div className="form-group">
                          <h4>Fundraiser Title</h4>
                          <input type="text" className="form-control" placeholder="title" value={this.getInputData(['project', 'campaign_templates', 'title'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'title'], e.target.value)}} />
                          {this.getInputErrors(['project', 'campaign_templates', 'title'])}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                
                <div className="row border-bottom">
                  <div className="col-md-12">
                    <div className="campaign-gallery pb-3 mb-3">
                      <h4>Fundraiser Gallery</h4>
                      {/* showing cover image of the selected item of gallery */}
                      {(this.getInputData(['project', 'campaign_templates', 'gallery']) === this.na || this.getInputData(['project', 'campaign_templates', 'gallery']).length === 0) ? 
                        <div className="mr-t-20 mr-b-20">
                          <h5 className="txt-center"><i>Nothing here, no images or videos, click the plus button below to add images or videos (Add at least one image)</i></h5>
                        </div> :
                        <div className="cg-cover">
                          {this.getInputData(['project', 'campaign_templates', 'gallery'])[selectedGalleryItem].media_type === 'gallery_image' ? 
                            <img src={`${this.getInputData(['project', 'campaign_templates', 'gallery'])[selectedGalleryItem].uri}`}/> : 
                            <iframe width="100%" height="100%" src={`${this.getInputData(['project', 'campaign_templates', 'gallery'])[selectedGalleryItem].uri}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                          }
                        </div>
                      }
                      {/* shows list of images and video icons with delete functionality */}
                      <div className="gl-list">
                        {this.getInputData(['project', 'campaign_templates', 'gallery']) && this.getInputData(['project', 'campaign_templates', 'gallery']).map((d, i) => {
                          if (d.media_type === 'gallery_image') {
                            return (
                              <div className={`gl-item ${selectedGalleryItem === i ? 'gl-item-selected' : ''}`} key={d.uri}>
                                <div className="img-wrapper" onClick={() => this.handleImageDeleteClick(i, 'imageIcon')}>
                                  <img src={d.uri} alt="" />
                                  <button className="img-delete" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.removeMedia(i); this.handleImageDeleteClick(i, 'deleteIcon')}}>
                                    <Icon path={mdiMinusCircle} className="menu-arrow" size={0.7} color="#ff5050" />
                                  </button>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className={`gl-item ${selectedGalleryItem === i ? 'gl-item-selected' : ''}`} key={d.uri}>
                                <div className="img-wrapper" onClick={() => this.handleImageDeleteClick(i, 'imageIcon')}>
                                  <img src={`https://i1.ytimg.com/vi/${Helpers.getYoutubeId(d.uri)}/mqdefault.jpg`} alt="" />
                                  <button className="img-delete" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.removeMedia(i); this.handleImageDeleteClick(i, 'deleteIcon')}}>
                                    <Icon path={mdiMinusCircle} className="menu-arrow" size={0.7} color="#ff5050" />
                                  </button>
                                </div>
                              </div>
                            )
                          }
                        })}
                        <div className="gl-item">
                          <div className="img-wrapper" style={{backgroundColor:'#f2f2f2'}}>
                            <button type="button" onClick={() => this.setState({addImageVideo : true})}>
                              <Icon path={mdiPlus} className="menu-arrow" size={0.75} color="#000000" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {this.getInputErrors(['project', 'campaign_templates', 0, 'gallery'])}
                      {this.getInputData(['project', 'campaign_templates', 'gallery']) && this.getInputData(['project', 'campaign_templates', 'gallery']).map((item, idx) => this.getInputErrors(['project', 'campaign_templates', 0, 'gallery', idx, 'uri'])
                      )}
                    </div>
                  </div>
                  {/* add image */}
                  {addImageVideo &&
                    <div className="col-md-3">
                      <div className="gl-item">
                        <div className="img-wrapper">
                          <button type="button" onClick={(e) => { e.preventDefault(); this.openImageModal('Gallery') }}><Icon path={mdiPlus} className="menu-arrow" size={0.60} /> <span>Add Image</span></button>
                        </div>
                        {imageModalGallery ? (
                          <ModalPopUp onClose={this.closeImageModal} title="Please select Gallery image" size="lg">
                            <ImageCropUpload label="Gallery" height={900} width={1600} value="" onChange={(uri) => { this.addMedia(uri, "gallery_image") }} />
                          </ModalPopUp>
                        ) : null}
                      </div>
                    </div>
                  }
                  {/* add video */}
                  {addImageVideo &&
                    <div className="col-md-3">
                      <div className="gl-item">
                        <div className="img-wrapper">
                          <button type="button" onClick={(e) => { e.preventDefault(); this.openVideoModal() }}><Icon path={mdiPlus} className="menu-arrow" size={0.60} /> <span>Add Video</span></button>
                        </div>
                        {videoModal ? (
                          <ModalPopUp onClose={this.closeVideoModal} title="Please add Gallery video as youtube URL">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group zoom-wrapper">
                                  <label>URL</label>
                                  <input className="form-control" type="text" value={YoutubeURL} onChange={this.YoutubeURLChange} />
                                </div>
                              </div>
                              <div className="col-md-12 uimg-wrapper">
                                <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.addMedia(YoutubeURL, 'gallery_video') }}>
                                  <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                                  &nbsp;Save
                                </button>
                              </div>
                            </div>
                          </ModalPopUp>
                        ) : null}
                      </div>
                    </div>
                  }
                  
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div className="form-group">
                        <h4>Fundraiser Story</h4>
                        <Editor value={this.getInputData(['project', 'campaign_templates', 'description'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'description'], e)}} />
                        {this.getInputErrors(['project', 'campaign_templates', 0, 'description'])}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <div className="form-group">
                        <h4>Fundraiser Duration (in Days)</h4>
                        <p>(0 if you don't want to set default)</p>
                        <input type="number" className="form-control" value={this.getInputData(['project', 'campaign_templates', 'expiry_in_days'])} onChange={(e) => this.handelInputChange(['project', 'campaign_templates', 'expiry_in_days'], e.target.value)} />
                        {this.getInputErrors(['project', 'campaign_templates', 0, 'expiry_in_days'])}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pb-3 mb-3">
                      <div className="form-group">
                        <h4>Show project in the story?</h4>
                        <p>&nbsp;</p>
                        <select className="form-control" value={this.getInputData(['project', 'campaign_templates', 'show_project']) !== 'na' ? this.getInputData(['project', 'campaign_templates', 'show_project']) : null} onChange={(e) => this.handelInputChange(['project', 'campaign_templates', 'show_project'], e.target.value === 'true' ? true : false)}>
                          <option disabled selected value=''>Select an option</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {this.getInputErrors(['project', 'campaign_templates', 0, 'show_project'])}
                      </div>
                    </div>
                  </div>
                </div>

                {this.props.login.user.is_admin && 
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="form-group">
                          <h4>Fundraiser Target Amount</h4>
                          <input type="number" className="form-control" value={this.getInputData(['project', 'campaign_templates', 'target_amount'])} onChange={(e) => this.handelInputChange(['project', 'campaign_templates', 'target_amount'], e.target.value)} />
                          {this.getInputErrors(['project', 'campaign_templates', 0, 'target_amount'])}
                        </div>
                      </div>
                    </div>
                  </div>
                }

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <h4>Type of campaign</h4>
                      <select className="form-control" value={this.getInputData(['project', 'campaign_templates', 'donation_type'])} onChange={(e) => this.handelInputChange(['project', 'campaign_templates', 'donation_type'], e.target.value)}>
                        <option disabled selected value=''>Select an option</option>
                        <option value="classic">Classic campaign</option>
                        <option value="unit">Unit campaign</option>
                        <option value="reward">Reward campaign</option>
                      </select>
                      {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_type'])}
                    </div>
                  </div>

                  {this.getInputData(['project', 'campaign_templates', 'donation_type']) === 'classic' && 
                    <div className="col-md-12">
                      <div className="mt-3 mb-3 pb-3">
                        <h4>Donation Options</h4>
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <p>Amount</p>
                          </div>
                          <div className="col-md-8 col-sm-12">
                            <p>Donation Description</p>
                          </div>
                        </div>
                        <div className="row tm-divider">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">INR</span>
                                </div>
                                <input type="number" className="form-control" placeholder="Amount eg 500, 1000" value={this.getInputData(['project', 'campaign_templates', 'donation_options', 0, 'amount'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', 0, 'amount'], e.target.value); }} />
                              </div>
                              {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', 0, 'amount'])}
                            </div>
                          </div>
                          <div className="col-md-8 col-sm-12">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Description" value={this.getInputData(['project', 'campaign_templates', 'donation_options', 0, 'title'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', 0, 'title'], e.target.value); }} />
                              {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', 0, 'title'])}
                            </div>
                          </div>
                        </div>
                        <div className="row tm-divider">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">INR</span>
                                </div>
                                <input type="number" className="form-control" placeholder="Amount eg 500, 1000" value={this.getInputData(['project', 'campaign_templates', 'donation_options', 1, 'amount'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', 1, 'amount'], e.target.value); }} />
                              </div>
                              {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', 1, 'amount'])}
                            </div>
                          </div>
                          <div className="col-md-8 col-sm-12">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Description" value={this.getInputData(['project', 'campaign_templates', 'donation_options', 1, 'title'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', 1, 'title'], e.target.value); }} />
                              {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', 1, 'title'])}
                            </div>
                          </div>
                        </div>
                        <div className="row tm-divider">
                          <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">INR</span>
                                </div>
                                <input type="number" className="form-control" placeholder="Amount eg 500, 1000" value={this.getInputData(['project', 'campaign_templates', 'donation_options', 2, 'amount'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', 2, 'amount'], e.target.value); }} />
                              </div>
                              {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', 2, 'amount'])}
                            </div>
                          </div>
                          <div className="col-md-8 col-sm-12">
                            <div className="form-group">
                              <input type="text" className="form-control" placeholder="Description" value={this.getInputData(['project', 'campaign_templates', 'donation_options', 2, 'title'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', 2, 'title'], e.target.value); }} />
                              {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', 2, 'title'])}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {this.getInputData(['project', 'campaign_templates', 'donation_type']) === 'unit' && 
                    <div className="col-md-12">
                      <div className="mt-3 mb-3 pb-3">
                        <h4>Donation Options</h4>
                        <div className="row">
                          <div className="col-md-3 col-sm-12">
                            <p>Amount</p>
                          </div>
                          <div className="col-md-2 col-sm-12">
                            <p>Image</p>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <p>Title</p>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <p>Description</p>
                          </div>
                        </div>
                        {this.getInputData(['project', 'campaign_templates', 'donation_options']).map((item, idx) => {
                          return (
                            <div className="row tm-divider" key={idx}>
                              <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">INR</span>
                                    </div>
                                    <input type="number" className="form-control" placeholder="Amount eg 500, 1000" value={item.amount} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', idx, 'amount'], e.target.value); }} />
                                  </div>
                                  {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', 0, 'amount'])}
                                </div>
                              </div>
                              <div className="input-group col-md-2 col-sm-12">
                                {item.image ? 
                                  <div className="gl-item" style={{width: "130px"}}>
                                    <div className="img-wrapper">
                                      <img src={item.image} alt="" />
                                      <button className="img-delete-camp" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.removeCampaignMedia(idx); }}>
                                        <Icon path={mdiMinusCircle} className="menu-arrow" size={1} color="#ff5050" />
                                      </button>
                                    </div>
                                    {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', idx, 'image'])}
                                  </div> : 
                                  <div>
                                    <button className="file-upload-browse btn btn-info donation-img-btn" type="button" onClick={(e) => { e.preventDefault(); this.setState({ uploadImageIndex: idx}); this.openImageModal('Campaign') }}>Upload</button>
                                    {imageModalCampaign ?
                                      <ModalPopUp onClose={this.closeImageModal} title="Please select image" size="lg">
                                        <ImageCropUpload label="Campaign Image" height={200} width={200} value="" onChange={(e) => { this.addImage(e, this.state.uploadImageIndex) }} error={this.getInputErrors(['project', 'campaign_templates', 'donation_options'])} />
                                      </ModalPopUp> : null
                                    }
                                    {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', idx, 'image'])}
                                  </div>
                                }      
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                  <input type="text" className="form-control" placeholder="Title" value={item.title} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', idx, 'title'], e.target.value); }} />
                                  {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', idx, 'title'])}
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <input type="text" className="form-control" placeholder="Description" value={item.description} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', idx, 'description'], e.target.value); }} />
                                  {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', idx, 'description'])}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>                      

                    </div>
                  }

                  {this.getInputData(['project', 'campaign_templates', 'donation_type']) === 'reward' && 
                    <div className="col-md-12">
                      <div className="mt-3 mb-3 pb-3">
                        <h4>Donation Options</h4>
                        <div className="row">
                          <div className="col-md-3 col-sm-12">
                            <p>Amount</p>
                          </div>
                          <div className="col-md-2 col-sm-12">
                            <p>Image</p>
                          </div>
                          <div className="col-md-3 col-sm-12">
                            <p>Title</p>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <p>Description</p>
                          </div>
                        </div>
                        {this.getInputData(['project', 'campaign_templates', 'donation_options']).map((item, idx) => {
                          return (
                            <div className="row tm-divider">
                              <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">INR</span>
                                    </div>
                                    <input type="number" className="form-control" placeholder="Amount eg 500, 1000" value={item.amount} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', idx, 'amount'], e.target.value); }} />
                                  </div>
                                  {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', idx, 'amount'])}
                                </div>
                              </div>
                              <div className="input-group col-md-2 col-sm-12">
                                {item.image ? 
                                  <div className="gl-item" style={{width: "130px"}}>
                                    <div className="img-wrapper">
                                      <img src={item.image} alt="" />
                                      <button className="img-delete-camp" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.removeCampaignMedia(idx); }}>
                                        <Icon path={mdiMinusCircle} className="menu-arrow" size={1} color="#ff5050" />
                                      </button>
                                    </div>
                                    {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', idx, 'image'])}
                                  </div> : 
                                  <div>
                                    <button className="file-upload-browse btn btn-info donation-img-btn" type="button" onClick={(e) => { e.preventDefault(); this.setState({ uploadImageIndex: idx}); this.openImageModal('Campaign') }}>Upload</button>
                                    {imageModalCampaign ?
                                      <ModalPopUp onClose={this.closeImageModal} title="Please select image" size="lg">
                                        <ImageCropUpload label="Campaign Image" height={900} width={1600} value="" onChange={(e) => { this.addImage(e, this.state.uploadImageIndex) }} error={this.getInputErrors(['project', 'campaign_templates', 'donation_options'])} />
                                      </ModalPopUp> : null
                                    }
                                    {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', idx, 'image'])}
                                  </div>
                                }      
                              </div>
                              <div className="col-md-3 col-sm-12">
                                <div className="form-group">
                                  <input type="text" className="form-control" placeholder="Title" value={item.title} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', idx, 'title'], e.target.value); }} />
                                  {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', idx, 'title'])}
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                  <input type="text" className="form-control" placeholder="Description" value={item.description} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'donation_options', idx, 'description'], e.target.value); }} />
                                  {this.getInputErrors(['project', 'campaign_templates', 0, 'donation_options', idx, 'description'])}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  }
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="mt-3 mb-3 pb-3">
                      <h4>Communications Templates</h4> 
                      <h6 className="mt-3 mb-3">Invite via email</h6>
                      <div className="form-group">
                        <label>Subject</label>
                        <input type="text" className="form-control" placeholder="Subject" value={this.getInputData(['project', 'campaign_templates', 'invite_templates', 'email'])?.subject} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'invite_templates', 'email', 'subject'], e.target.value)}} />
                        {this.getInputErrors(['project', 'campaign_templates', 0, 'invite_templates', 'email', 'subject'])}
                      </div>
                      <div className="form-group">
                        <label>Body</label>
                        <Editor value={this.getInputData(['project', 'campaign_templates', 'invite_templates', 'email'])?.content} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'invite_templates', 'email', 'content'], e)}}/>
                        {this.getInputErrors(['project', 'campaign_templates', 0, 'invite_templates', 'email', 'content'])}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3 pb-3">
                      <h6 className="mb-3">Sharing content</h6>
                      <div className="form-group">
                        <label>For Owner of Fundraiser</label>
                        <textarea rows="3" className="form-control" placeholder="Creator sharing message" value={this.getInputData(['project', 'campaign_templates', 'invite_templates', 'creator_sharing_message'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'invite_templates', 'creator_sharing_message'], e.target.value)}} />
                        {this.getInputErrors(['project', 'campaign_templates', 0, 'invite_templates', 'creator_sharing_message'])}
                      </div>
                      <div className="form-group">
                        <label>For Visitors of Fundraiser</label>
                        <textarea rows="3" className="form-control" placeholder="Guest sharing message" value={this.getInputData(['project', 'campaign_templates', 'invite_templates', 'guest_sharing_message'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'invite_templates', 'guest_sharing_message'], e.target.value)}} />
                        {this.getInputErrors(['project', 'campaign_templates', 0, 'invite_templates', 'guest_sharing_message'])}
                      </div>
                      <div className="form-group">
                        <label>For Donors of Fundraiser</label>
                        <textarea rows="3" className="form-control" placeholder="Donor sharing message" value={this.getInputData(['project', 'campaign_templates', 'invite_templates', 'donor_sharing_message'])} onChange={(e) => { this.handelInputChange(['project', 'campaign_templates', 'invite_templates', 'donor_sharing_message'], e.target.value)}} />
                        {this.getInputErrors(['project', 'campaign_templates', 0, 'invite_templates', 'donor_sharing_message'])}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
});

export default connect(
  mapStateToProps,
  null
)(CampaignEdit);
