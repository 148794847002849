import React, { Component } from 'react';
import { format } from 'date-fns';

import Helpers from '../../utils/helpers';

class NonprofitSignatoryRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getInputData = (key) => {
    const { value } = this.props;
    const val = Helpers.getDataFromObject(value, key);
    if(val === '' && key[1] !== 'video') {
      return this.na;
    }
    return val;
  }

  render() {
    if (this.getInputData(['nonprofit', 'facebook_onboarding']) === this.na) {
      return null;
    }
    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3 className="mr-b-20"> Facebook Signatory Details</h3>
              <h6>Name</h6>
              <p className="text-muted">{this.getInputData(['nonprofit', 'facebook_onboarding', 'signatory_name'])}</p>
              <h6>Email</h6>
              <p className="text-muted">{this.getInputData(['nonprofit', 'facebook_onboarding', 'signatory_email'])}</p>
              <h6>Accepted on</h6>
              { this.getInputData(['nonprofit', 'facebook_onboarding', 'accepted_on']) !== this.na && 
                <p className="text-muted">{format(new Date(this.getInputData(['nonprofit', 'facebook_onboarding', 'accepted_on'])), 'do MMM yyyy H:mm')}</p> 
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NonprofitSignatoryRead;
