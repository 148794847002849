import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import * as projectApprovalsActions from '../../../reducers/AdminApprovals/projectApprovalsReducer';

import ProjectTile from '../../../components/ProjectTile';
import Loading from '../../../appComponents/Loading';
import Helpers from '../../../utils/helpers';
import FilterByInput from '../../../components/FilterByInput';

import './ProjectApprovals.scss';

class ProjectApprovals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q: '',
      query: {},
      filters: [{
        label: 'Search',
        type: 'text',
        query: 'q',
        placeholder: 'Search for Name',
      }, {
        label: 'Status',
        type: 'list',
        query: 'status',
        list: [{label: 'Pending', value: 'pending'}, {label: 'Published', value: 'published'}, {label: 'Final Approval', value: 'final_approval'}, {label: 'Archived', value: 'unpublished'}],
      }, {
        label: 'Nonprofit',
        type: 'service',
        query: 'nonprofit',
        service: 'nonprofits',
        placeholder: 'Type nonprofit name',
      }, {
        label: 'Created after',
        type: 'date',
        query: 'created_at',
        op: '>'
      }, {
        label: 'Created before',
        type: 'date',
        query: 'created_at',
        op: '<'
      }, {
        label: 'Updated after',
        type: 'date',
        query: 'updated_at',
        op: '>'
      }, {
        label: 'Updated before',
        type: 'date',
        query: 'updated_at',
        op: '<'
      }]
    };
  }

  componentDidMount() {
    const { projects, Projects } = this.props;
    const { q } = this.state;
    Projects.getFinalProjects({q});
    if (!projects.get_status.final_project) {
      Projects.getFinalProjects(q);
    }
  }
  componentWillUnmount() {
    const { Projects } = this.props;
    Projects.reset();
  }

  handelQ = (e) => {
    e.preventDefault();
    const { q } = this.state;
    if(q !== '' && e.target.value === '') {
      this.clearSearch();
    } else {
      this.setState({q: e.target.value});
    }
  }

  handelSearch = (e) => {
    e.preventDefault();
    const { q } = this.state;
    if (q.length >= 3) {
      const { Projects } = this.props;
      Projects.reset();
      Projects.getFinalProjects({q});
    }
  }

  clearSearch = () => {
    this.setState({q: ''});
    const { Projects } = this.props;
    Projects.reset();
    Projects.getFinalProjects('');
  }

  loadMore = () => {
    const { Projects } = this.props;
    const { q } = this.state;
    Projects.getFinalProjects(q);
  }

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshProjectList();
    });
  }

  refreshProjectList = () => {
    const { Projects } = this.props;
    const { query } = this.state;
    Projects.reset();
    Projects.getFinalProjects(query);
  }

  render() {
    const { projects, Projects } = this.props;
    const { q, filters } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row grid-margin">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Pending Project Onboarding Approvals</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} handelSearch={this.handelSearch} handelQ={this.handelQ} q={this.state.q} forApproval={true} clearSearch={this.clearSearch}/>
                <div className="row ngo-list-wrapper">
                  <div className="col-md-12 col-12 ngo-list-col">
                    <div className="column-list">
                      <h2>Approve & merge</h2>
                      <div className="p-tiles-wrapper mt-3">
                        {projects.final_projects.map(item => (
                          <ProjectTile value={item} key={item._id} link={"/approvals/project-onboarding/"} />
                        ))}
                        {projects.get_status.final_project === 'pending' ? (
                          <Loading />
                        ) : (projects.final_projects.length === 0) ? (
                          <i>No items available</i>
                        ): null}
                        {(projects.load_status.final_project) ? (
                          <div style={{ display: 'block', width: '100%', textAlign: 'center' }}><button type="button" className="btn btn-fw" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button></div>
                        ): null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projectApprovals,
});

const mapActionToProps = dispatch => ({
  Projects: bindActionCreators(projectApprovalsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(ProjectApprovals);
