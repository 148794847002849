import React, {Component} from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Icon from '@mdi/react';
import { mdiChevronRight, mdiLoading, mdiChevronLeft } from '@mdi/js';

import Loading from '../../appComponents/Loading';
import PhoneNumberInput from '../../components/PhoneInput';

import '../../appComponents/Login/Login.css';

import * as LoginAction from '../../reducers/loginReducer';
import * as ConfigAction from '../../reducers/configReducer';
import * as HomeActions from '../../reducers/homeReducer';


class FBOnboardLogin extends Component {
  constructor(props){
    super(props)
    this.state = {
      otpTimeout: 0,
      otp: '',
      mobile: '',
      email: '',
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);
    const { login, Login, Config, config, state } = this.props;
    if (login.view.otpTimeout > 0) {
      Login.otpSetSeconds(0);
      this.setState({ otpTimeout: login.view.otpTimeout }, this.otpTime);
    }

    if (!config.get_status.config) {
      Config.get();
    }
    
    if(login.user_is_valid === true && (login.user.is_email_verified && login.user.is_phone_verified)){            
      this.props.onLogin();      
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  }

  componentDidUpdate = () => {
    const { login, Login } = this.props;
    if (login.view.otpTimeout > 0) {
      Login.otpSetSeconds(0);
      this.setState({ otpTimeout: login.view.otpTimeout }, this.otpTime);
    }
    
    if(login.user_is_valid){      
      if(!login.user.is_phone_verified && (login.view.type !== 'mobile' && login.view.type !== 'mobileLogin' )){
        Login.loginViewChange('mobile');
      }
      else if(!login.user.is_email_verified && (login.view.type !== 'email' && login.view.type !== 'emailLogin' )){
        Login.loginViewChange('email');
      }
    }
  }

  otpTime = () => {
    let { otpTimeout } = this.state;
    if (otpTimeout && otpTimeout > 0) {
      this.setState({ otpTimeout: otpTimeout -= 1 });
      this.timer = setTimeout(this.otpTime, 1000);
    }
  }

  otpHTML = () => {
    const { otpTimeout } = this.state;
    if (otpTimeout > 0) {
      return (
        <label className="mt-2 text-right block text-small">
          Get another OTP ( Wait for 00:
          {(otpTimeout <= 9) ? `0${otpTimeout}` : otpTimeout}
          )
        </label>
      );
    }
    return (
      <a href="#" onClick={this.verifyUser} className="mt-2 text-right text-small block">
        Get another OTP
      </a>
    );
  }

  verifyUser = (e) => {
    e.preventDefault();
    const { mobile, email } = this.state;
    const { Login, login } = this.props;
    let data = {};
    let view = '';
    if (login.view.type === 'mobile' || login.view.type === 'mobileLogin') {
      data = {
        identifier: mobile,
        service: 'mobile',
      };
      view = 'mobileLogin';
    }
    if (login.view.type === 'email' || login.view.type === 'emailLogin') {
      data = {
        identifier: email,
        service: 'email',
      };
      view = 'emailLogin';
    }
    Login.verify(data, view)    
  }

  switchToMobile = (e) => {
    e.preventDefault();
    const { Login } = this.props;
    Login.loginViewChange('mobile');
  }

  switchToEmail = (e) => {
    e.preventDefault();
    const { Login } = this.props;
    Login.loginViewChange('email');
  }

  handelMobile = (value) => {
    this.setState({ mobile: value });
  }

  setField = (e) => {
    this.setState({...this.state, [e.target.name]:e.target.value });    
  }

  loginUser = async (e) => {

    const { onLogin } = this.props

    e.preventDefault();
    const {
      mobile,
      email,
      otp,
    } = this.state;
    const { Login, login } = this.props;
    let data = {};
    switch (login.view.type) {
      case 'mobileLogin':
        data = {
          identifier: mobile,
          service: 'mobile',
          token: otp,
        };
        break;
      case 'emailLogin':
        data = {
          identifier: email,
          service: 'email',
          token: otp,
        };
        break;
      default:
    }
    Login.authorise(data).then(response => {      
      onLogin();
    });    
  }

  submitButtonHTML = () => {
    const { login } = this.props;   
    return (
      <button type="submit" className="btn btn-primary submit-btn btn-block">
        <Icon
          path={login.loading? mdiLoading: mdiChevronRight}
          size={1}
          color="#ffffff"
        />
        &nbsp; SIGN IN
        {/* {login.user_is_valid === true? 'SIGN IN': 'Get OTP'} */}
      </button>
    );
  }

  loginHTML = () => {
    const {
      mobile,
      otp,
      email,
    } = this.state;
    const { login, config } = this.props;
    const { country_code } = config.defaults || {};

    const mobileView = (
      <form className="login-form" onSubmit={this.verifyUser}>
        <div className="form-group">
          <label className="label">Mobile number</label>
          <div className="input-group">
            <PhoneNumberInput value={mobile} name="mobile" onChange={this.handelMobile} countrycode={country_code} />
          </div>
        </div>
        <div className="form-group">
          {this.submitButtonHTML()}
        </div>

        {/* If User is logged in and only doing verification, don't display the click to move to Email Login  */}
        {login.user_is_valid === true? (
          <div className="text-block text-center my-3">
            <a href="#login" onClick={this.switchToEmail}>Click to login via email</a>
          </div>
        ): null}
      </form>
    );
    const emailView = (
      <form className="login-form" onSubmit={this.verifyUser}>
        <div className="form-group">
          <label className="label">Email</label>
          <div className="input-group">
            <input type="text" className="form-control" name="email" placeholder="XXXXXXXX@email.com" value={email} onChange={this.setField} />
          </div>
        </div>
        <div className="form-group">
          {this.submitButtonHTML()}
        </div>
        {/* If User is logged in and only doing verification, don't display the click to move to Mobile Login  */}
        {login.user_is_valid === true? (
          <div className="text-block text-center my-3">
            <a href="#login" onClick={this.switchToMobile}>Click to login via mobile</a>
          </div>
        ): null}
      </form>
    );
    const mobileLogin = (
      <form className="login-form" onSubmit={this.loginUser}>
        <div className="form-group">
          <label className="label">Mobile number</label>
          <div className="input-group">
            <PhoneNumberInput value={mobile} name="mobile" onChange={this.handelMobile} disabled />
            <div className="input-group-append mobile-input">
              <button className="btn btn-sm btn-light" type="button" onClick={this.switchToMobile}>Edit</button>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="label">OTP</label>
          <div className="form-group">
            <input type="password" className="form-control" name="otp" placeholder="****" value={otp} onChange={this.setField} />
            {this.otpHTML()}
          </div>
        </div>
        <div className="form-group">
          {this.submitButtonHTML()}
        </div>
        <div className="text-block text-center my-3">
          We just sent you a SMS
        </div>
        <div className="text-block text-center my-3 text-small">
          Check you inbox with a message and enter the OTP to resume your journey at here. If you don’t receive the message in a minute, please click on resend OTP link
        </div>
      </form>
    );
    const emailLogin = (
      <form className="login-form" onSubmit={this.loginUser}>
        <div className="form-group">
          <label className="label">Email</label>
          <div className="input-group">
            <input type="text" className="form-control" placeholder="XXXXXXXX@email.com" value={email} disabled />
            <div className="input-group-append mobile-input">
              <button className="btn btn-sm btn-light" type="button" onClick={this.switchToEmail}>Edit</button>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="label">OTP</label>
          <div className="form-group">
            <input type="password" className="form-control" placeholder="****" value={otp} name="otp" onChange={this.setField} />
            {this.otpHTML()}
          </div>
        </div>
        <div className="form-group">
          {this.submitButtonHTML()}
        </div>
        <div className="text-block text-center my-3">
          We just sent you a Email
        </div>
        <div className="text-block text-center my-3 text-small">
          Check you inbox with a email and enter the OTP to resume your journey at here. If you don’t receive email in a minute, please click on resend OTP link
        </div> 
      </form>
    );
    switch (login.view.type) {
      case 'mobileLogin':
        return mobileLogin;
      case 'emailLogin':
        return emailLogin;
      case 'email':
        return emailView;
      case 'mobile':
        return mobileView;
      default:
        return mobileView;
    }
  }

  handleSubmit = async () => {    
    const {state, setData} = this.props    
    if(this.props.state.error.dataInitialError.length == 0) {            
      let pair = {target: {'name': 'step', 'value': 'requiredDocuments'}};
      await setData(pair, 'main');
    } 
  }

loginInfo = () => {
  const { login, goBack, state } = this.props;

  let redirect_url_params = 'redirect=/facebook-onboarding?'
  
  if(state.dataPan.panCard) redirect_url_params += `pan_card=${state.dataPan.panCard}`
  if(state.dataInitial) redirect_url_params += `&nonprofit_details=${JSON.stringify(this.props.state.dataInitial)}`
  

  return (
    <>
    {login.user_is_valid && (login.user.is_email_verified === false || login.user.is_phone_verified === false) ? (
    <div className="row w-100 mx-auto">
      <div className="col-lg-6 mx-auto">
        <div className="auto-form-wrapper">
          {this.loginHTML()}
        </div>
        <p className="footer-text text-center">copyright © 2018 Give Foundation. All rights reserved.</p>
      </div>
    </div> ) : (
    <div>
      {this.props.state.nonprofit.name ? <p>Name of the nonprofit  : <strong>{this.props.state.nonprofit.name}</strong></p> : '' }
      <p>Please click on the Login button to continue </p>
    </div> ) }

      <div className="col-md-12 d-flex align-items-stretch">
        <div className="row flex-grow">
          <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
            <button type="button" className="btn btn-outline-primary mr-2" onClick={this.props.goBack}>
              <Icon path={mdiChevronLeft} className="menu-arrow" size={0.75}/> Back
            </button>
            {!login.user_is_valid &&
              <Link 
                to={`/login?${redirect_url_params}`} className="btn btn-primary"
              >
                Login
              </Link>
            }
          </div>
        </div>
      </div>
    </>
  )
}

  render(){
    const { login, location, goBack } = this.props;
    if (login.user_is_valid && (login.user.is_email_verified && login.user.is_phone_verified)) {
      // TODO: Check message here 
      return (<Loading message="Verifying user and updating your nonprofit data..." />);
    }

    return(
      <div className="container-scroller">        
        <div className="card">
          <div className="card-body">
            <h3 className="mr-b-20">
            {login.user_is_valid ? `Verify your ${(login.view.type === 'mobile' || login.view.type === 'mobileLogin') ? 'Phone' : 'Email'}` : 'Login'}
            </h3>
            <hr/>
            {login.user_is_valid && (login.user.is_email_verified === false && login.user.is_phone_verified === false) ? '' : this.loginInfo()}
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  login: state.login,
  config: state.config,
  home: state.home,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
  Home: bindActionCreators(HomeActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(FBOnboardLogin);