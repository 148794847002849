import React from 'react';
import { toast } from 'react-toastify';
import * as jsonpatch from 'fast-json-patch';
import _, { values } from 'lodash'
import FormatValidator from './formatValidator'
import { format } from 'date-fns'
import { apiUrl } from '../config';

export default class Helpers {
  static scriptLoaded = { nextId: 0, data: [] };
  static formFieldsFromSchema = []
  static formSteps = {}
  static formFieldElementTypes = [
    'input',
    'radio',
    'select',
    'textarea',
    'upload',
    'media',
  ]

  static objSearch(list, value, title) {
    if (title) {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i][title] === value) {
          return i;
        }
      }
    } else {
      for (let j = 0; j < list.length; j += 1) {
        if (list[j] === value) {
          return j;
        }
      }
    }
    return -1;
  }

  static routesCompare(a, b) {
    if (!a || !b) {
      return false;
    }
    const aa = a.split('/');
    const bb = b.split('/');
    let equal = false;
    if (aa.length === bb.length) {
      if (aa[0] === '' || bb[0] === '') {
        aa.splice(0, 1);
        bb.splice(0, 1);
      }
      equal = true;
      for (let i = 0; i < aa.length; i += 1) {
        if (aa[i] !== bb[i] && !(aa[i].indexOf(':') === 0 || bb[i].indexOf(':') === 0)) {
          equal = equal && false;
        }
      }
    }
    return equal;
  }

  static locationMatch(list, pathname) {
    if (this.routesCompare(list.path, pathname)) {
      return pathname;
    }
    for (let j = 0; j < list.sidebar.length; j += 1) {
      if (this.routesCompare(list.sidebar[j].path, pathname)) {
        return pathname;
      }
      if (list.sidebar[j].maskedChild) {
        for (let l = 0; l < list.sidebar[j].maskedChild.length; l += 1) {
          if (this.routesCompare(list.sidebar[j].maskedChild[l].path, pathname)) {
            return list.sidebar[j].path;
          }
        }
      }
      if (list.sidebar[j].child) {
        for (let k = 0; k < list.sidebar[j].child.length; k += 1) {
          if (this.routesCompare(list.sidebar[j].child[k].path, pathname)) {
            return pathname;
          }
          if (list.sidebar[j].child[k].maskedChild) {
            for (let l = 0; l < list.sidebar[j].child[k].maskedChild.length; l += 1) {
              if (this.routesCompare(list.sidebar[j].child[k].maskedChild[l].path, pathname)) {
                return list.sidebar[j].child[k].path;
              }
            }
          }
        }
      }
    }
    return '';
  }

  static snakeCaseToString(val) {
    return val.replace('_', ' ');
  }

  static replaceParamsInURI(params, uri) {
    let newURI = uri;
    Object.keys(params).forEach(item => {
      newURI = newURI.replace(`:${item}`, params[item]);
    });
    return newURI;
  }

  static loadScript(src, force) {
    return new Promise((resolve, reject) => {
      let s;
      if (this.objSearch(this.scriptLoaded.data, src, 'src') >= 0) {
        if (force === true) {
          const id = this.objSearch(this.scriptLoaded.data);
          const oldElement = document.getElementById(`dynscript-${id}`);
          s = document.createElement('script');
          s.src = src;
          s.id = `dynscript-${id}`;
          s.onload = resolve;
          s.onerror = reject;
          document.head.replaceChild(s, oldElement);
        } else {
          resolve();
        }
      } else {
        const id = this.scriptLoaded.nextId + 1;
        this.scriptLoaded.data.push({ id, src });
        s = document.createElement('script');
        s.src = src;
        s.id = `dynscript-${id}`;
        s.onload = resolve;
        s.onerror = reject;
        document.head.appendChild(s);
      }
    });
  }

  static getYoutubeId(url) {
    const regExp = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    if (match && match[1].length === 11) {
      return match[1];
    } else {
      return null;
    }
  }

  static getDataFromObject(data, key) {
    if (key.length === 2) {
      if (key[1] === 'video') {
        if (data.video) {
          const youtubeId = Helpers.getYoutubeId(data[key[1]]);
          if (youtubeId !== null) {
            return `https://www.youtube.com/watch?v=${youtubeId}`;
          } else {
            return data[key[1]];
          }
        }
      } else if (typeof data[key[1]] !== 'undefined') {
        return data[key[1]];
      }
    }
    if (key.length === 3) {
      if (key[1] === 'address') {
        if (data.address && data.address[key[2]]) {
          return data.address[key[2]];
        }
      } else if (key[1] === 'external_profiles') {
        const location = Helpers.objSearch(data.external_profiles, key[2], 'label');
        if (location >= 0 && data.external_profiles[location].uri) {
          return data.external_profiles[location].uri;
        }
      } else if (key[1] === 'documents') {
        if (data.documents) {
          const filteredData = data.documents.filter((i) => {
            const [document_type, fy] = key[2].split(':');
            return (i.document_type === document_type && i.fy === fy);
          });
          if (filteredData.length > 0) {
            return filteredData[0].uri;
          }
        }
      } else if (key[1] === 'campaign_templates') {
        if (data.campaign_templates.length > 0) {
          return data.campaign_templates[0][key[2]];
        } else {
          return '';
        }
      } else if (data[key[1]] && data[key[1]][key[2]]) {
        return data[key[1]][key[2]];
      }
    }
    if (key.length === 4) {
      if (key[1] === 'bank_accounts') {
        if (data.bank_accounts) {
          let index = data.bank_accounts.findIndex(item => item?.is_international === key[2])
          key[2] = index !== -1 ? index : data.bank_accounts.length
        }
        else {
          key[2] = 0
        }

        if (data.bank_accounts[key[2]] && data.bank_accounts[key[2]][key[3]]) {
          return data.bank_accounts[key[2]][key[3]];
        }
      } else if (key[1] === 'points_of_contact') {
        if (data.points_of_contact && data.points_of_contact.filter(m => (m.designation === key[2])).length > 0) {
          return data.points_of_contact.filter(m => (m.designation === key[2]))[0][key[3]]
        }
      } else if (key[1] === 'campaign_templates') {
        if (data[key[1]].length > 0 && data[key[1]][0].hasOwnProperty([key[2]])) {
          return data[key[1]][0][key[2]][key[3]];
        } else {
          return null
        }
      } else if (data[key[1]] && data[key[1]][key[2]] && data[key[1]][key[2]][key[3]]) {
        return data[key[1]][key[2]][key[3]];
      }
    }
    if (key.length === 5) {
      if (key[1] === 'campaign_templates') {
        return data[key[1]][0][key[2]][key[3]][key[4]];
      } else if (data[key[1]] && data[key[1]][key[2]] && data[key[1]][key[2]][key[3]] && data[key[1]][key[2]][key[3]][key[4]]) {
        return data[key[1]][key[2]][key[3]][key[4]];
      }
    }
    return '';
  }

  static saveValue = (value) => {
    if (value === null || value === '' || value === undefined) {
      return null;
    }
    return value;
  }

  /**
   * Introduced this function to replace bank_accounts node with a different array of bank accounts
   */
  static replaceBankAccountData(data, newBankAccountArray) {
    const newDataObject = { data: { ...data }, changes: {} };
    if (newDataObject.data.hasOwnProperty('bank_accounts')) {
      newDataObject.data['bank_accounts'] = newBankAccountArray;
      newDataObject.changes = {
        'bank_accounts': newBankAccountArray,
      }
    }
    return newDataObject;
  }

  static setDataToObject(data, key, e) {
    const returnData = {};
    if (key.length === 2) {
      if (
        key[1] === 'description'
        || key[1] === '12a_doc'
        || key[1] === '80g_doc'
        || key[1] === 'fcra_doc'
        || key[1] === 'image'
        || key[1] === 'cover_image'
        || key[1] === 'annual_report_doc'
        || key[1] === 'registration_doc'
        || key[1] === 'pan_card_doc'
        || key[1] === 'phone'
        || key[1] === 'address_proof_doc'
        || key[1] === 'highresolution_image'
        || key[1] === 'tags'
        || key[1] === 'priority'
        || key[1] === 'sites'
        || key[1] === 'issue'
        || key[1] === 'action'
        || key[1] === 'impact'
        || key[1] === 'cover_image'
        || key[1] === 'image'
        || key[1] === 'status'
        || key[1] === 'is_published'
        || key[1] === 'is_featured'
        || key[1] === 'demographics'
        || key[1] === 'causes'
      ) {
        returnData[key[1]] = this.saveValue(e);
      } else if (key[1] === 'video') {
        const youtubeId = Helpers.getYoutubeId(e.target.value);
        if (youtubeId !== null) {
          returnData[key[1]] = this.saveValue(`https://www.youtube.com/embed/${youtubeId}`);
        } else {
          returnData[key[1]] = this.saveValue(e.target.value);
        }
      } else if (key[1] === 'name' && data.isRegisteredName === 'yes') {
        returnData.official_name = this.saveValue(e.target.value);
      } else if (key[1] === 'is_4limb') {
        if (e.target.value === 'yes') {
          returnData.is_4limb = true;
        } else if (e.target.value === 'no') {
          returnData.retention_percent = 0;
          if (data['80g'] && data['80g_doc']) {
            returnData.is_4limb = false;
          }
        }
      } else if (key[1] === 'auto_receipt') {
        if (e.target.value === 'yes') {
          returnData.auto_receipt = true;
        } else if (e.target.value === 'no') {
          returnData.auto_receipt = false;
        }
      } else if (key[1] === 'project_costs') {
        let totalAmount = 0;
        e.forEach((item) => {
          if (parseInt(item.value, 10)) {
            totalAmount += parseInt(item.value, 10);
          }
        });
        returnData['project_costs'] = this.saveValue(e);
        returnData['target_amount'] = this.saveValue(totalAmount);
      } else if (key[1] === 'faqs') {
        let updatedFaqArray = [...data.faqs]
        updatedFaqArray.push(e);
        returnData[key[1]] = updatedFaqArray;
      } else if (key[1] === 'campaign_templates') {
        const campaignTemplate = [...data.campaign_templates]
        campaignTemplate.push(e)
        returnData[key[1]] = campaignTemplate;
      } else {
        returnData[key[1]] = this.saveValue(e.target.value);
      }
    }
    if (key.length === 3) {
      if (key[1] === 'address') {
        if (data.address) {
          returnData.address = { ...data.address };
        } else {
          returnData.address = {
            country_code: 'IN',
          }
        }
        if (key[2] === 'region') {
          returnData.address[key[2]] = this.saveValue(e);
        } else {
          returnData.address[key[2]] = this.saveValue(e.target.value);
        }
      } else if (key[1] === 'external_profiles') {
        if (data.external_profiles) {
          returnData.external_profiles = [...data.external_profiles];
        } else {
          returnData.external_profiles = [];
        }
        const location = Helpers.objSearch(returnData.external_profiles, key[2], 'label');
        if (location >= 0) {
          if (e.target.value !== '') {
            returnData.external_profiles[location].uri = e.target.value;
          } else {
            returnData.external_profiles.splice(location, 1);
          }
        } else {
          returnData.external_profiles.push({
            label: key[2],
            uri: e.target.value,
          });
        }
      } else if (key[1] === 'gallery' || key[1] === 'members' || key[1] === 'tags') {
        if (data[key[1]]) {
          returnData[key[1]] = [...data[key[1]]];
        } else {
          returnData[key[1]] = [];
        }
        if (e === null) {
          returnData[key[1]].splice(key[2], 1);
        } else if (typeof e === 'object' || typeof e === 'string') {
          returnData[key[1]][key[2]] = e;
        }
      } else if (key[1] === 'documents') {
        if (data.documents) {
          returnData.documents = [...data.documents];
        } else {
          returnData.documents = [];
        }
        if (e === null) {
          const filteredData = returnData.documents.filter((i) => {
            const [document_type, fy] = key[2].split(':');
            return (i.document_type === document_type && i.fy === fy);
          });
          if (filteredData.length > 0) {
            const index = returnData.documents.indexOf(filteredData[0]);
            returnData.documents.splice(index, 1);
          }
        } else if (typeof e === 'object') {
          returnData.documents.push(e);
        }
      } else if (key[1] === 'governing_body' && key[2] === 'strength') {
        const is_fbassured = data.required_compliances.includes('fbassured');
        if (data.governing_body) {
          returnData.governing_body = { ...data.governing_body };
        } else {
          returnData.governing_body = {};
        }
        if (e.target.value > 15) {
          returnData.governing_body.strength = '15';
        } else {
          returnData.governing_body.strength = e.target.value;
        }
        if (!returnData.governing_body.members) {
          returnData.governing_body.members = [];
        }
        const actualLength = parseInt(returnData.governing_body.members.length, 10);
        const requiredLength = parseInt(returnData.governing_body.strength, 10) > 10 ? 10 : parseInt(returnData.governing_body.strength, 10);
        if (actualLength < requiredLength) {
          for (let i = 0; i < requiredLength - actualLength; i++) {
            returnData.governing_body.members.push({
              name: '',
              date_of_birth: '',
              gender: '',
              position: '',
              remuneration: '',
              id: '',
              // id_doc: '', //Revoved as Single file upload give null on delete
            });
          }
        }
        if (actualLength > requiredLength) {
          returnData.governing_body.members = returnData.governing_body.members.slice(0, requiredLength);
        }
        if (!is_fbassured) {
          delete returnData.governing_body.members;
        }
      } else if (key[1] === 'required_compliances') {
        if (data.required_compliances) {
          returnData.required_compliances = [...data.required_compliances];
        } else {
          returnData.required_compliances = [];
        }
        if (e !== null) {
          returnData.required_compliances[key[2]] = e;
        } else {
          returnData[key[1]].splice(key[2], 1);
        }
        if (e === 'fbassured') {
          returnData.nteecc_category_codes = [];
          returnData.priority = '';
        }
      } else if (key[1] === 'campaign_templates') {
        returnData[key[1]] = [...data[key[1]]]
        if (key[2] === 'donation_type') {
          returnData[key[1]] = [...data[key[1]]]
          returnData[key[1]][0][key[2]] = e;
          let donationOptions = returnData[key[1]][0].donation_options
          if (e === 'classic') {
            if (Object.keys(donationOptions[0]).length > 3) {
              donationOptions.forEach(item => { delete item.description; delete item.image })
              returnData[key[1]][0]['donation_options'] = donationOptions
            }
          } else if (e === 'unit' || e === 'reward') {
            if (Object.keys(returnData[key[1]][0].donation_options[0]).length <= 3) {
              donationOptions = donationOptions.map(item => ({ ...item, description: '', image: null }))
              returnData[key[1]][0]['donation_options'] = donationOptions
            }
          }
        } else {
          returnData[key[1]][0][key[2]] = this.saveValue(e);
        }
      } else if (key[1] === 'faqs') {
        let updatedFaqArray = [...data.faqs]
        updatedFaqArray = updatedFaqArray.filter((item, idx) => idx !== key[2])
        returnData[key[1]] = updatedFaqArray;
      }
      else {
        if (typeof data[key[1]] !== 'undefined') {
          if (Array.isArray(data[key[1]])) {
            returnData[key[1]] = [...data[key[1]]];
          } else if (typeof data[key[1]] === 'object') {
            returnData[key[1]] = { ...data[key[1]] };
          }
        } else {
          if (Number.isInteger(key[2])) {
            returnData[key[1]] = [];
          } else {
            returnData[key[1]] = {};
          }
        }
        if (e && e.target) {
          returnData[key[1]][key[2]] = this.saveValue(e.target.value);
        } else {
          if (e !== null) {
            returnData[key[1]][key[2]] = this.saveValue(e);
          } else {
            if (Array.isArray(returnData[key[1]])) {
              returnData[key[1]].splice(key[2], 1);
            } else {
              delete returnData[key[1]][key[2]];
            }
          }
        }
        if (key[1] === 'others' && key[2] === 'is_staff_related_board' && e.target.value === 'No') {
          delete returnData.others.staff_related_board;
        }
      }
    }
    if (key.length === 4) {
      if (key[1] === 'bank_accounts') {

        // Transforms this [ nonprofit, bank_accounts, false, account_name]
        const is_international = key[2]
        if (data.bank_accounts) {
          let index = data.bank_accounts.findIndex(item => item?.is_international === key[2])
          key[2] = index !== -1 ? index : data.bank_accounts.length
        }
        else {
          key[2] = 0
        }
        // to this [ nonprofit, bank_accounts, 0, account_name]

        // Checking if bank_accounts exists, if yes sending updaring return data with existing value, else returning empty array
        if (data.bank_accounts) {
          returnData.bank_accounts = [...data.bank_accounts];
        } else {
          returnData.bank_accounts = [];
        }

        if (!returnData.bank_accounts[key[2]]) {
          returnData.bank_accounts[key[2]] = {
            account_name: '',
            account_number: '',
            account_type: '',
            is_international: is_international,
          };
        }
        if (key[3] === 'cancelled_cheque_doc') {
          returnData.bank_accounts[key[2]][key[3]] = e;
        } else {
          returnData.bank_accounts[key[2]][key[3]] = e.target.value;
        }

      } else if (key[1] === 'points_of_contact') {
        if (data.points_of_contact) {
          returnData.points_of_contact = [...data.points_of_contact];
        } else {
          returnData.points_of_contact = [];
        }
        if (returnData.points_of_contact.filter(m => (m.designation === key[2])).length > 0) {
          if (key[3] === 'phone' || key[3] === 'id_doc') {
            returnData.points_of_contact.filter(m => (m.designation === key[2]))[0][key[3]] = e;
          } else {
            returnData.points_of_contact.filter(m => (m.designation === key[2]))[0][key[3]] = e.target.value;
          }
        } else {
          const leader = {
            name: '',
            designation: key[2],
            email: '',
            phone: '',
          };
          if (key[3] === 'phone') {
            leader[key[3]] = e;
          } else {
            leader[key[3]] = e.target.value;
          }
          returnData.points_of_contact.push(leader);
        }
      } else if (key[1] === 'members') {
        if (data.members) {
          returnData.members = [...data.members];
        } else {
          returnData.members = [];
        }
        if (key[3] === 'phone') {
          returnData.members[key[2]][key[3]] = e;
        } else if (key[3] === 'is_primary_contact') {
          if (e.target.value === 'Yes') {
            returnData.members[key[2]][key[3]] = true;
          } else {
            returnData.members[key[2]][key[3]] = false;
          }
        } else {
          returnData.members[key[2]][key[3]] = e.target.value;
        }
      } else if (key[1] === 'project_costs' && key[3] === 'value') {
        let totalAmount = 0;
        const projectCosts = [...data.project_costs];
        projectCosts[key[2]][key[3]] = e.target.value;
        projectCosts.forEach((item) => {
          if (parseInt(item.value, 10)) {
            totalAmount += parseInt(item.value, 10);
          }
        });
        returnData['project_costs'] = this.saveValue(projectCosts);
        returnData['target_amount'] = this.saveValue(totalAmount);
      } else if (key[1] === 'donation_options') {
        let donationOptions = data.donation_options;
        if (!donationOptions || !donationOptions.length) {
          donationOptions = [{ amount: 0, currency_code: 'INR', title: '' }, { amount: 0, currency_code: 'INR', title: '' }, { amount: 0, currency_code: 'INR', title: '' }];
        }

        //BreakFix Logic Start - Below logic is added because while making the validations from backend, some projects got created with donationOptions length as 1, so adding below two conditions to rectify that, future project creations will not need these two conditions
        if (donationOptions.length === 1) {
          donationOptions.push({ amount: 0, currency_code: 'INR', title: '' }, { amount: 0, currency_code: 'INR', title: '' })
        }
        if (donationOptions.length === 2) {
          donationOptions.push({ amount: 0, currency_code: 'INR', title: '' })
        }
        //BreakFix Logic End

        if (key[3] === 'amount' && e.target.value === '') {
          donationOptions[key[2]][key[3]] = 0;
        } else {
          donationOptions[key[2]][key[3]] = e.target.value;
        }
        returnData['donation_options'] = this.saveValue(donationOptions);
      } else if (key[1] === 'campaign_templates') {
        returnData[key[1]] = [...data[key[1]]];
        if (key[2] === 'gallery') {
          if (e === null) {
            returnData[key[1]][0][key[2]].splice(key[3], 1);
          } else if (typeof e === 'object' || typeof e === 'string') {
            returnData[key[1]][0][key[2]][key[3]] = e;
          }
        } else if (key[2] === 'donation_options') {
          returnData[key[1]] = [...data[key[1]]];
          if (typeof e === 'object' || typeof e === 'string') {
            returnData[key[1]][0][key[2]][key[3]] = e;
          }
        } else if (key[2] === 'invite_templates') {
          returnData[key[1]] = [...data[key[1]]];
          returnData[key[1]][0][key[2]][key[3]] = this.saveValue(e);
        } else {
          returnData[key[1]][0][key[2]] = this.saveValue(e);
        }
      } else if (key[1] === 'faqs') {
        let updatedFaqArray = [...data.faqs]
        for (let i = 0; i < updatedFaqArray.length; i++) {
          if (i === key[2]) {
            updatedFaqArray[i][key[3]] = this.saveValue(e)
          }
        }
        returnData[key[1]] = updatedFaqArray;
      } else if (key[1] === 'demographics' && key[3] === 'roles'){
        returnData[key[1]] = [...data[key[1]]];
        returnData[key[1]][key[2]][key[3]] = [e.target.value]
      }
      
      else {
        if (typeof data[key[1]] !== 'undefined') {
          if (Array.isArray(data[key[1]])) {
            returnData[key[1]] = [...data[key[1]]];
          } else if (typeof data[key[1]] === 'object') {
            returnData[key[1]] = { ...data[key[1]] };
          }
        } else {
          if (Number.isInteger(key[2])) {
            returnData[key[1]] = [];
          } else {
            returnData[key[1]] = {};
          }
        }

        if (!returnData[key[1]][key[2]]) {
          if (Number.isInteger(key[3])) {
            returnData[key[1]][key[2]] = [];
          } else {
            returnData[key[1]][key[2]] = {};
          }
        }
        if (e && e.target) {
          returnData[key[1]][key[2]][key[3]] = this.saveValue(e.target.value);
        } else {
          if (e !== null) {
            returnData[key[1]][key[2]][key[3]] = this.saveValue(e);
          } else {
            if (Array.isArray(returnData[key[1]][key[2]])) {
              returnData[key[1]][key[2]].splice(key[3], 1);
            } else {
              delete returnData[key[1]][key[2]][key[3]];
            }
          }
        }
      }
    }
    if (key.length === 5) {
      if (key[1] === '') {
        //If required
      } else if (key[1] === 'campaign_templates') {
        returnData[key[1]] = [...data[key[1]]];
        if (key[2] === 'donation_options') {
          let donationOptions = data.campaign_templates[0].donation_options;
          if (key[4] === 'amount' && e === '') {
            donationOptions[key[3]][key[4]] = 0;
          } else if (key[4] === 'image' && e === null) {
            donationOptions[key[3]][key[4]] = null;
          } else {
            donationOptions[key[3]][key[4]] = e;
          }
          returnData['campaign_templates'][0]['donation_options'] = this.saveValue(donationOptions);
        } else if (key[2] === 'invite_templates') {
          if (key[3] === 'email') {
            let email = { subject: '', body: '' };
            returnData[key[1]] = [...data[key[1]]];
            if (!returnData[key[1]][0].hasOwnProperty(key[2])) {
              returnData[key[1]][0][key[2]] = { email: {}, sharing_content: {} };
            }
            if (!returnData[key[1]][0][key[2]].hasOwnProperty(key[3])) {
              returnData[key[1]][0][key[2]][key[3]] = email
            }
            returnData[key[1]][0][key[2]][key[3]][key[4]] = this.saveValue(e);
          } else if (key[3] === 'sharing_content') {
            let sharingContent = { creator: '', guest: '', donor: '' }
            returnData[key[1]] = [...data[key[1]]];
            if (!returnData[key[1]][0].hasOwnProperty(key[2])) {
              returnData[key[1]][0][key[2]] = { email: {}, sharing_content: {} };
            }
            if (!returnData[key[1]][0][key[2]].hasOwnProperty(key[3])) {
              returnData[key[1]][0][key[2]][key[3]] = sharingContent
            }
            returnData[key[1]][0][key[2]][key[3]][key[4]] = this.saveValue(e);
          }
        }
      } else if (key[1] === 'causes' && key[2] === 'subcauses') {
        returnData[key[1]] = [...data[key[1]]];
        const subCausesArr = e && e.split('-')
        console.log({e,subCausesArr});
        returnData[key[1]][key[3]][key[2]] = subCausesArr
      }

      else {
        if (typeof data[key[1]] !== 'undefined') {
          if (Array.isArray(data[key[1]])) {
            returnData[key[1]] = [...data[key[1]]];
          } else if (typeof data[key[1]] === 'object') {
            returnData[key[1]] = { ...data[key[1]] };
          }
        } else {
          if (Number.isInteger(key[2])) {
            returnData[key[1]] = [];
          } else {
            returnData[key[1]] = {};
          }
        }

        if (!returnData[key[1]][key[2]]) {
          if (Number.isInteger(key[3])) {
            returnData[key[1]][key[2]] = [];
          } else {
            returnData[key[1]][key[2]] = {};
          }
        }

        if (!returnData[key[1]][key[2]][key[3]]) {
          if (Number.isInteger(key[4])) {
            returnData[key[1]][key[2]][key[3]] = [];
          } else {
            returnData[key[1]][key[2]][key[3]] = {};
          }
        }
        if (e && e.target) {
          returnData[key[1]][key[2]][key[3]][key[4]] = this.saveValue(e.target.value);
        } else {
          if (e !== null) {
            returnData[key[1]][key[2]][key[3]][key[4]] = this.saveValue(e);
          } else {
            if (Array.isArray(returnData[key[1]][key[2]][key[3]])) {
              returnData[key[1]][key[2]][key[3]].splice(key[4], 1);
            } else {
              delete returnData[key[1]][key[2]][key[3]][key[4]];
            }
          }
        }
      }
    }

    if (Object.keys(returnData).length > 0) {
      return { data: { ...data, ...returnData }, changes: returnData };
    }
    return { data, changes: {} };
  }

  static getErrorMessageFromObject(errors, key, data, state) {
    
    let returnData = null;
    if (key[1] === 'external_profiles') {
      const external_profiles = this.getDataFromObject(data, ['nonprofit', 'external_profiles']);
      const index = Helpers.objSearch(external_profiles, key[2], 'label');
      if (index >= 0) {
        key[2] = index;
        key.push('uri');
      }
    }
    if (key[1] === 'points_of_contact') {
      const points_of_contact = this.getDataFromObject(data, ['nonprofit', 'points_of_contact']);
      const index = Helpers.objSearch(points_of_contact, key[2], 'designation');
      if (index >= 0) {
        key[2] = index;
      }
    }
    if (key[1] === 'bank_accounts') {
      const bank_accounts = this.getDataFromObject(data, ['nonprofit', 'bank_accounts'])
      const index = bank_accounts.findIndex(item => item?.is_international === key[2])
      if (index >= 0) {
        key[2] = index
      }
    }

    if (key[1] === 'documents') {
      // ['nonprofit', 'documents', 'auditor_report:2018-19']
      let index = -1;
      this.getDataFromObject(data, ['nonprofit', 'documents']).forEach(i => {
        const [document_type, fy] = key[2].split(':');
        if (i.document_type === document_type && i.fy === fy) {
          index = i;
        }
      });
      if (index >= 0) {
        key[2] = index;
      }
    }
    if (errors && errors.length > 0) {
      if (key[1] === 'isRegisteredName' && state.isRegisteredName === '') {
        return (
          <div className="error-message col-sm-12">
            Please select a option
          </div>
        );
      }
      if (key[1] === 'isFCRA' && state.isFCRA === '') {
        return (
          <div className="error-message col-sm-12">
            Please select a option
          </div>
        );
      }
      const forItem = key.slice(1, key.length).join('.');
      errors.forEach((item) => {
        if (item[forItem]) {
          returnData = (
            <div className="error-message">
              {item[forItem]}
            </div>
          );
        }
      });
    }
    return returnData;
  }

  static getSelectedFromEdits(key = [], edits = []) {
    const returnData = [];
    if (key.length === 2) {
      if (['name', 'email', 'official_name', 'description', 'address', 'gallery', 'external_profiles', 'registration_number', 'documents', 'bank_accounts', 'points_of_contact', 'causes', 'sites', 'donation_options', 'faqs', 'project_costs', 'impact', 'issue', 'action', 'cover_image', 'image', 'video', 'registration_type', 'phone', 'status', 'gst_details', '12a_expiry_date', '80g_expiry_date'].indexOf(key[1]) > -1) {
        const forItem = `/${key[1]}`;
        edits.forEach((edit, index) => {
          if (edit.path.includes(forItem)) {
            returnData.push(index);
          }
          if (['project_costs'].indexOf(key[1]) > -1 && edit.path.includes('target_amount')) {
            returnData.push(index);
          }
        });
      }
      if (['fcra', 'fcra_doc', 'fcra_validity', '12a', '12a_doc', '80g', 'pan_card', '80g_doc', 'pan_card_doc', 'annual_report_doc', 'address_proof_doc', 'highresolution_image', 'registration_doc', 'auditor_name'].indexOf(key[1]) > -1) {
        const forItem = `/${key[1]}`;
        edits.forEach((edit, index) => {
          if (edit.path === forItem) {
            returnData.push(index);
          }
        });
      }
      return returnData;
    }
    if (key.length === 3) {
      if (['campaign_templates'].indexOf(key[1]) > -1) {
        const forItem = `/${key[1]}`;
        edits.forEach((edit, index) => {
          if (edit.path.includes(forItem)) {
            returnData.push(index);
          }
        });
        return returnData;
      }
      // if(key[1] === 'documents') {
      //   const forItem = '/documents/';
      //   edits.forEach((edit, index) => {
      //     if (edit.path.includes(forItem) && edit.value) {
      //       const [document_type, fy] = key[2].split(':');
      //       if(edit.value.fy === fy && edit.value.document_type === document_type) {
      //         returnData.push(index);
      //       }
      //     }
      //   });
      //   return returnData;
      // }
      if (key[1] === 'others' && key[2] === 'leaders') {
        const forItem = '/others/leaders/';
        edits.forEach((edit, index) => {
          if (edit.path.includes(forItem)) {
            returnData.push(index);
          }
        });
        return returnData;
      }
      if (key[1] === 'governing_body' && key[2] === 'members') {
        const forItem = '/governing_body/members/';
        edits.forEach((edit, index) => {
          if (edit.path.includes(forItem)) {
            returnData.push(index);
          }
        });
        return returnData;
      }
      if (key[1] === 'others' && key[2] === 'staff_related_board') {
        const forItem = '/others/staff_related_board';
        edits.forEach((edit, index) => {
          if (edit.path.includes(forItem)) {
            returnData.push(index);
          }
        });
        return returnData;
      }
    }
    const forItem = '/' + key.slice(1, key.length).join('/');
    edits.forEach((edit, index) => {
      if (edit.path === forItem) {
        returnData.push(index);
      }
    });
    return returnData;
  }

  static createNonprofitWithDiff = (prevNonprofit) => {
    let nonprofit = JSON.parse(JSON.stringify(prevNonprofit));
    //apply diff
    const diff = nonprofit.edits || [];

    if (diff) {
      diff.forEach(element => {
        try {
          nonprofit = jsonpatch.applyOperation(nonprofit, element).newDocument;
        }
        catch (error) {
          // Do Nothing
        }
      });
    }
    return nonprofit;
  }

  /*
   This function is used to build the query object from the filters
   We should not encode the data in this function since this is called multiple times and the encoding
   depends on the content type and method of the HTTP request
   TODO  Move the function inside FilterByInput
   */

  static queryBuilderForFilterInput(options) {
    const query = {};
    options.forEach(option => {
      if (option.data && option.data.length > 0) {
        option.data.forEach(d => {
          Object.keys(d.data).forEach(j => {
            let value = d.data[j];

            // Force value to be casted to string on backend,
            // This is due to a bug where phone numbers are auto-casted to numbers and loose the + sign at their start
            // https://github.com/loris/api-query-params/issues/117
            if (j === 'members.phone' || j === 'creator.phone') {
              value = `string(${value})`;
            }

            if (typeof query[j] === 'undefined') {
              if (option?.filterType === 'multiple') {
                query[j] = [value]
              } else {
                query[j] = value
              }
            } else {
              if (option?.filterType === 'multiple') {
                query[j].push(value)
              } else {
                query[j] = value
              }
            }
          });
        });
      }
    });
    return query;
  }

  static convertJSONtoCSV(json, fileTitle) {
    if (!Array.isArray(json)) {
      return '';
    }
    const keys = [];
    let returnData = '';
    json.forEach(row => {
      Object.keys(row).forEach(col => {
        if (!keys.includes(col)) {
          keys.push(col);
        }
      });
    });
    returnData = `"${keys.join('","')}"`;
    json.forEach(row => {
      returnData = returnData + '\n';
      keys.forEach((key, index) => {
        returnData = returnData + `"${row[key] || ''}"`;
        if (index !== keys.length - 1) {
          returnData = returnData + ',';
        }
      });
    });

    const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    const blob = new Blob([returnData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  static addRemoveValuesArray(arr, value, operation) {
    let newArr = [...arr];
    if (operation === 'add') {
      if (newArr.indexOf(value) === -1) {
        newArr.push(value);
      }
    }
    if (operation === 'remove') {
      if (newArr.indexOf(value) > -1) {
        newArr = newArr.filter(i => i !== value);
      }
    }
    return newArr
  }

  static getFinancialYears = () => {
    const today = new Date(),
      year = today.getFullYear(),
      financialYears = [];

    for (let i = 0; i < 3; i++) {
      financialYears.push(`${year - i - 2}-${year - i - 1}`)
    }

    return financialYears;
  }

  // Following Functions are written for the updated onboarding flow. These aren't currently getting used, but will be used once the onboarding flow is set up.
  // Temporarily being merged to master to avoid issue with further rebasing etc.

  // Method to retrive steps involved in the form, based on the user admin status
  // TODO: Optimise code here
  static getFormSteps = (formJSON, isAdmin = false) => {
    let steps = [];
    if (Object.keys(formJSON).length) {
      if (formJSON.hasOwnProperty('properties')) {
        const child = formJSON.properties;
        Object.keys(child).forEach(item => {
          if (child[item].hasOwnProperty('element') && child[item].element === 'step') {
            if (child[item].hasOwnProperty('isAdmin') && child[item].isAdmin === true && isAdmin !== true) {
              // Do Nothing
            }
            else {
              steps.push({
                title: child[item].title,
                type: 'form',
                key: item,
              });
            }
          }
        })
        return steps;
      }
      else {
        console.error('JSON Input for creating form isn\'t valid');
        return false;
      }
    }
  }

  static getValuefromDependency = (key, dep) => {
    dep.forEach((item) => {
      if (this.getObjectfromPath(key) === dep.returnValue) {
        return this.getObjectfromPath(key)
      }
    })
  }

  // Method to get value from an object based on the object path
  // TODO: Check for fetching object data for array non-fixed indexes, eg external_profiles, others.members etc
  static getObjectfromPath = (object, path) => {
    if (path !== undefined) {
      return _.get(object, path.split('.'));
    }
    else {
      return null
    }
  }

  // Method to check the value at path,
  // eg if 'a.b.c' in object is supposed to be 4 and is 4 return true, otherwise false
  static isValueAtPath = (object, path, value) => {
    if (path !== undefined) {
      return _.get(object, path.split('.',)) === value
    }
    else {
      return false
    }
  }

  // Merging Two Array with same keys
  static mergeArray = (source, value) => {
    const merged = []
    for (let i = 0; i < Math.max(source.length, value.length); i++) {
      if (source[i] && !_.isObject(source[i])) {
        merged.push(source[i])
      }
      else if (value[i] && source[i] === undefined) { // Correction made to handle undefined values in project.causes
        merged.push(value[i])
      }
      else {
        merged.push({
          ...value[i],
          ...source[i]
        })
      }

    }
    return merged
  }

  // Setting value to a path in the object, similar to getObjectfromPath above
  static setPathToObject = (object, path, value) => {
    if (path !== undefined) {
      const newObject = _.set({}, path.split('.'), value)
      return _.mergeWith(object, newObject, (value, source) => {
        if (_.isArray(value)) {
          if (_.isArray(source)) {
            return this.mergeArray(source, value)
          }
          else {
            return source
          }
        }
      })
    }
    else {
      return false
    }
  }

  static unsetArrayKey = (object, path, index) => {
    if (path !== undefined && index !== undefined) {
      const arrayValue = this.getObjectfromPath(object, path)
      if (arrayValue) {
        arrayValue.splice(index, 1)
        return this.setPathToObject(object, path, arrayValue)
      }
    }
  }

  static omitPathsFromObject = (object, paths) => {
    return _.omit(object, paths)
  }

  static formatText = (text) => {

    // TODO: Until a better logic comes in mind, ... Rohit
    // return _.startCase(
    //   _.capitalize(text.replace(/_/g, ' '))
    // )
    if (text !== null && text !== undefined)
      return text.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    else
      return ''
  }

  static startCase = (text) => {
    return _.startCase(
      _.capitalize(text.replace(/_/g, ' '))
    )
  }

  static replaceIdentifierWithReplacers = (identifier, replacer) => {
    Object.keys(identifier).forEach(item => {
      identifier[item] = identifier[item].replace(
        identifier[item],
        replacer[identifier[item]
          .replace('{{', '')
          .replace('}}', '')]
      )
    })
    return identifier;
  }

  // Function to Validate form field values with the validation object in the schema
  static isValid = (data, validations) => {
    const validationKeys = _.keysIn(validations);
    const errors = [];
    validationKeys.map(key => {
      if (key !== 'required') {
        switch (key) {
          case 'minLength':
            if (data && data.length < validations[key] && data.length !== 0) {
              errors.push('Text length to be greated than or equal to ' + validations[key] + ' characters');
            }
            break;
          case 'maxLength':
            if (data.length > validations[key] && data.length !== 0) {
              errors.push('Text length to be within ' + validations[key] + ' characters');
            }
            break;
          case 'regex':
            const regex = new RegExp(validations[key])
            if (!regex.test(data)) {
              errors.push('Data doesn\'t match the required format')
            }
            break;
          case 'format':
            const format = validations[key]
            const checkresult = FormatValidator[format](data)
            if (checkresult !== true && checkresult.status === false) {
              errors.push(checkresult.message)
            }
            break;
          default:
            break;
        }
      }
    })
    return errors;
  }

  // Method to validate field data based on the validation array provided, returns error, if any
  // TODO: This is not fully tested, do take time to verify this  code
  static validateFormFields = (formData, validation) => {
    const noFCRAitems = [
      "nonprofits.bank_accounts.1.account_name",
      "nonprofits.bank_accounts.1.account_type",
      "nonprofits.bank_accounts.1.account_number",
      "nonprofits.bank_accounts.1.ifsc_code",
      "nonprofits.bank_accounts.1.cancelled_cheque_doc",
      "nonprofits.fcra",
      "nonprofits.fcra_doc",
      "nonprofits.fcra_validity"
    ]
    const errors = [];
    //remvoing `is_international` from the validation, since we are not using this for validation(Bank details step)
    validation = validation.filter((item) => item !== "nonprofits.is_international")
    if (formData.isRegisteredName === 'yes') {
      validation = validation.filter((item) => item !== "nonprofits.official_name")
    }
    if (formData.isFCRA === 'no' || formData.isFCRA === undefined) {
      validation = validation.filter(v => {
        return !noFCRAitems.includes(v);
      });
    }
    validation.forEach(keyIndex => {
      const value = _.get(formData, keyIndex.split('.'))
      if (value === undefined || value === null || value === '') {
        errors.push(keyIndex);
      }
      else if (typeof value === 'object') {
        switch (keyIndex) {
          case 'nonprofits.documents':
            Object.values(value).forEach((item, index) => {
              if (item.uri === undefined || item.uri === null || item.uri === '')
                errors.push(`${keyIndex}.${index}.uri`)
            })
            break
          case 'nonprofits.governing_body.members':

            const requiredKeys = [
              'name',
              'date_of_birth',
              'date_of_appointment',
              'id',
              'id_doc',
              'position',
              'remuneration',
            ]

            value.forEach((item, index) => {
              requiredKeys.forEach(key => {
                if (item[key] === undefined || item[key] === null || item[key] === null) {
                  errors.push(`${keyIndex}.${index}.${key}`)
                }
              })
            })
            break

          default:
            break;
        }
      }
    })

    return errors
  }


  static isEqual = (obj1, obj2) => {
    return _.isEqual(obj1, obj2)
  }

  static difference = (array, comparingArray) => {
    return _.difference(array, comparingArray)
  }

  static cloneObject = (object) => {
    return _.cloneDeep(object)
  }

  static formatDateTo = (dateValue, dateFormat = false) => {
    const dateFormats = [
      'dd/MM/yyyy',
      'dd-MM-yyyy',
      'yyyy-MM-d'
    ]
    if (dateFormat && dateFormats.indexOf(dateFormat) >= 0) {
      return format(new Date(dateValue), dateFormat)
    }
    else {
      return format(new Date(dateValue), 'dd-MM-yyyy')
    }
  }

  // Method to get a unique elements in an array
  static unique = (array) => {
    return _.uniq(array)
  }

  static generatePatch = (control, source) => {
    return jsonpatch.compare(control, source)
  }

  static getKeysFromPatch = (patch, collection = 'nonprofits') => {
    let keys = []
    patch.forEach(item => {
      if (item.path.indexOf('statistic') === -1)
        keys.push(`${collection}${item.path.replace(/\//g, '.')}`)
    })
    return keys
  }

  static getArrayIndex = (array, key, identifier) => {
    return _.findIndex(array, item => item[key] === identifier)
  }

  static arrayIndexSwitch = (array, oldIndex, newIndex) => {
    if (newIndex >= array.length) {
      const k = newIndex - array.length + 1
      while (k--) {
        array.push(undefined)
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])
    return array
  }

  // Get Index of Edits from Form Schema key

  /*
    This is currently a hard coded code, where in the path that's coming in from the schema and the form field is transformed to the edits path basis the values entered. For operations on objects, such as address, bank_details etc, we've done custom coding in the paths for the said transformation. 
  */

  static getIndexofEditsfromFormSchemaKey = (formSchema, edits) => {
    try {
      let indexArr = []
      formSchema.forEach((item) => {
        if (!item.hasOwnProperty('key')) {
          // Do Nothing 
        }
        else {
          let y;
          if (item.key.includes('documents')) {
            y = item.key.replaceAll('.', '/').split('nonprofits')[1].replace('/uri', '');
          }
          else if (item.key.includes('address')) {
            y = item.key.replaceAll('.', '/').split('nonprofits')[1]
              .replace('/street', '')
              .replace('/city', '')
              .replace('/region', '')
              .replace('/country_code', '')
              .replace('/area_code', '')
          }
          else if (item.key.includes('bank_accounts')) {
            y = item.key.replaceAll('.', '/').split('nonprofits')[1]
              .replace('/account_name', '')
              .replace('/account_number', '')
              .replace('/account_type', '')
              .replace('/account_ifsc_code', '')
              .replace('/account_cancelled_cheque_doc', '')
          }
          else if (item.key.includes('points_of_contact')) {
            y = item.key.replaceAll('.', '/').split('nonprofits')[1]
              .replace('/name', '')
              .replace('/phone', '')
              .replace('/email', '')
              .replace('/title', '')
          }
          else if (item.key.includes('donation_options')) {
            y = item.key.replaceAll('.', '/').split('projects')[1]
              .replace('amount', '')
              .replace('title', '')
          }
          else if (item.key.includes('project_costs')) {
            y = item.key.replaceAll('.', '/').split('projects')[1]
              .replace('value', '')
              .replace('title', '')
          }
          else if (item.key.includes('sites')) {
            y = item.key.replaceAll('.', '/').split('projects')[1]
              .replace('country_code', '')
              .replace('region', '')
              .replace('city', '')
          }
          else {
            if (item.key.indexOf('nonprofits') !== -1) y = item.key.replaceAll('.', '/').split('nonprofits')[1];
            else if (item.key.indexOf('projects') !== -1) y = item.key.replaceAll('.', '/').split('projects')[1];
          }
          let getIndex = edits.findIndex(x => x.path === y)
          if (getIndex !== -1) {
            indexArr.push(getIndex)
          }
        }
      })
      return indexArr;
    }
    catch (e) {
      return []
    }
  }

  static validateConditions = (conditions, value, data) => {
    let returnValue
    conditions.forEach(condition => {
      if (condition.value && condition.value[0] === '$') condition.value = this.getObjectfromPath(data, condition.value.replace('$', ''))

      switch (condition.op) {
        case 'eq': // equal          
          if (value === condition.value) {
            if (condition.return[0] !== '$') returnValue = condition.return
            else returnValue = this.getObjectfromPath(data, condition.return.replace('$', ''));
          }
          break;
        case 'neq': // Not Equal
          if (value !== condition.value) {
            if (condition.return[0] !== '$') returnValue = condition.return
            else returnValue = this.getObjectfromPath(data, condition.return.replace('$', ''));
          }
          break;
        case 'in': // Key In object
          if (value && value.hasOwnProperty(condition.key)) {
            if (condition.value) {
              if (value[condition.key] === condition.value) {
                if (condition.return[0] !== '$') returnValue = condition.return
                else returnValue = this.getObjectfromPath(data, condition.return.replace('$', ''));
              }
            }
            else {
              if (condition.return[0] !== '$') returnValue = condition.return
              else returnValue = this.getObjectfromPath(data, condition.return.replace('$', ''));
            }
          }
          break;
        case 'nin':  //Not In
          if (value && !value.hasOwnProperty(condition.key)) {
            if (condition.return[0] !== '$') returnValue = condition.return
            else returnValue = this.getObjectfromPath(data, condition.return.replace('$', ''));
          }
          break;
      }
    });
    return returnValue
  }

  static getConditionalValue = (formData, dependency) => {
    const value = this.getObjectfromPath(formData, dependency.key);
    return this.validateConditions(dependency.conditions, value, formData);
  }
}


export const downloadPdf = (fileUrl) => {
  const url = fileUrl;
  const formatArr = url.split('.');
  const fileName = formatArr[formatArr.length - 2].replace('com/', '').trim();
  const format = formatArr[formatArr.length - 1].trim();
  fetch(
    `${apiUrl}/files/downloadfile?fileLink=${url}`,
    {
      method: 'get',
      headers: {
        'Content-Type': `application/${format}`,
        'Accept': 'application/json',
        "authorization": "letzchange-receipts"
      },
    })
    .then(response => response.blob())
    .then(blobData => {
      if (blobData.size > 0) {
        const url = window.URL.createObjectURL(new Blob([blobData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.${format}`);
        document.body.appendChild(link);
        link.click();
      }
      else {
        toast.error('File not found. Please try again in sometime', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    })
    .catch(error => {
      toast.error('Error fetching PDF:', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error fetching PDF:', error);
    });
}

export const asyncDownloadFile = (fileUrl) => {
  return new Promise((resolve, reject) => {
    const url = fileUrl;
    const formatArr = url.split('.');
    const fileName = formatArr[formatArr.length - 2].replace('com/', '').trim();
    const format = formatArr[formatArr.length - 1].trim();
    fetch(
      `${apiUrl}/files/downloadfile?fileLink=${url}`,
      {
        method: 'get',
        headers: {
          'Content-Type': `application/${format}`,
          'Accept': 'application/json',
          "authorization": "letzchange-receipts"
        },
      })
      .then(response => response.blob())
      .then(blobData => {
        if (blobData.size > 0) {
          const url = window.URL.createObjectURL(new Blob([blobData]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}.${format}`);
          document.body.appendChild(link);
          link.click();
          resolve(); // Resolve the promise if download is successful
        } else {
          reject('File not found. Please try again in sometime'); // Reject the promise if file not found
        }
      })
      .catch(error => {
        reject('Error fetching PDF: ' + error); // Reject the promise if there's an error
      });
  });
};


// To validate created_at and other date formats

export const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;

export const isDateCorrectFormat = (str) => {
  try {
    return dateFormatRegex.test(str);
  } catch (error) {
    console.error("Error occurred while testing the date format:", error);
    return false;
  }
}