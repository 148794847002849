import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Redirect } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiLoading, mdiFloppy, mdiConsoleNetwork } from '@mdi/js';

import * as AdminNonprofitItemActions from '../../../reducers/Admin/adminNonprofitItemReducer';
import * as ConfigAction from '../../../reducers/configReducer';
import * as EventAction from '../../../reducers/eventReducer';
import * as NotificationAction from '../../../reducers/notificationReducer';
import * as ComplianceApprovalActions from '../../../reducers/AdminApprovals/complianceApprovalReducer';

import './AdminNonprofitItem.css';
import Helpers from '../../../utils/helpers';

import Loading from '../../../appComponents/Loading';
import NonprofitBasicDetailsRead from '../../../components/NonprofitBasicDetailsRead';
import NonprofitBasicDetailsEdit from '../../../components/NonprofitBasicDetailsEdit';
import NonprofitCauseEdit from '../../../components/NonprofitCauseEdit';
import NonprofitCauseRead from '../../../components/NonprofitCauseRead';
import NonprofitAdminSettingsRead from '../../../components/NonprofitAdminSettingsRead';
import NonprofitAdminSettingsEdit from '../../../components/NonprofitAdminSettingsEdit';
import GalleryRead from '../../../components/GalleryRead';
import GalleryEdit from '../../../components/GalleryEdit';
import NonprofitDocumentsRead from '../../../components/NonprofitDocumentsRead';
import NonprofitDocumentsEdit from '../../../components/NonprofitDocumentsEdit';
import NonprofitPointOfContactRead from '../../../components/NonprofitPointOfContactRead';
import NonprofitPointOfContactEdit from '../../../components/NonprofitPointOfContactEdit';
import NonprofitGoverningBodyRead from '../../../components/NonprofitGoverningBodyRead';
import NonprofitGoverningBodyEdit from '../../../components/NonprofitGoverningBodyEdit';
import NonprofitLegalInformationRead from '../../../components/NonprofitLegalInformationRead';
import NonprofitLegalInformationEdit from '../../../components/NonprofitLegalInformationEdit';
import NonprofitOtherDetailsRead from '../../../components/NonprofitOtherDetailsRead';
import NonprofitOtherDetailsEdit from '../../../components/NonprofitOtherDetailsEdit';
import NonprofitSignatoryRead from '../../../components/NonprofitSignatoryRead';
import ComplianceRenewalModal from '../../../components/ComplianceRenewalModal'
import AdminEntityTagWidget from '../AdminEntityTagWidget/AdminEntityTagWidget';

class AdminNonprofitItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readEdit: null,
      nonprofit: {},
      prevNonprofit: {},
      showComplianceRenewal: false,      
    };
  }

  componentDidMount() {
    const { Nonprofits, nonprofits, match, config, Config, event, Event } = this.props;
    const { id } = match.params;
    if (!nonprofits.get_status.nonprofit) {
      Nonprofits.getNonprofit({ path: [id] });
    }
    if (!config.get_status.config) {
      Config.get();
    }
    if (!event.get_status.event) {
      Event.get();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};

    if(Object.keys(props.complianceApproval.nonprofit).length && props.complianceApproval.nonprofit !== state.nonprofit){
      setData.prevNonprofit = props.complianceApproval.nonprofit;
      setData.nonprofit = Helpers.createNonprofitWithDiff(props.complianceApproval.nonprofit);
    }
    else if (props.nonprofits.nonprofit !== state.prevNonprofit) {
      setData.prevNonprofit = props.nonprofits.nonprofit;
      setData.nonprofit = Helpers.createNonprofitWithDiff(props.nonprofits.nonprofit);
    }

    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  componentWillUnmount() {
    const { Nonprofits } = this.props;
    Nonprofits.reset();
  }

  onNonprofitSave = (nonprofit) => {
    const { Nonprofits } = this.props;
    
    return Nonprofits.setNonprofit(nonprofit).then(() => {
      this.changeReadOrEditForm();
    }).catch((e)=>{
      console.error("Errors are", "==>>", JSON.stringify(e));
    });
  }

  onNonprofitChange = (nonprofit) => {
    this.setState({ nonprofit });
  }

  changeReadOrEditForm = (to) => {
    const { readEdit } = this.state;
    const { Notification, nonprofits } = this.props;

    if (to === undefined) {
      this.setState({ readEdit: null });
    } else if (to === null) {
      this.setState({ readEdit: null, nonprofit: Helpers.createNonprofitWithDiff(nonprofits.nonprofit) });
    } else if (to !== null) {
      if(readEdit !== null) {
        Notification.addNotification("Please Save/Cancel before editing another section");
      } else {
        this.setState({ readEdit: to });
      }
    }
  }

  showComplianceRenewalModal = () => {
    this.setState({
      showComplianceRenewal: !this.state.showComplianceRenewal
    })    
  }

  setComplianceRenewals = (compliances) => {    
    const { ComplianceApproval } = this.props    
    const { nonprofit } = this.state
    if(nonprofit._id){      
      ComplianceApproval.renewCompliances(nonprofit, compliances).then(response => {               
        this.showComplianceRenewalModal()   
        this.changeReadOrEditForm();        
      }).catch(error => {
        // Do Nothing 
      })      
    }    
  }

  buttonListHTML = (component) => {
    const { nonprofits } = this.props;
    const { readEdit } = this.state;

    if(readEdit === component) {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
          <button type="submit" className="btn btn-primary mr-2">
            {(nonprofits.set_status.nonprofit === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
              )}
            &nbsp;Save
          </button>
          <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.changeReadOrEditForm(null); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            &nbsp;Cancel
          </button>
        </div>
      );
    }

    if(component !== 'settings'){
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
          <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.changeReadOrEditForm(component); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            &nbsp;Edit
          </button>
        </div>
      );
    }
    else {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
          {this.state.nonprofit.approved_compliances.length ? (
            <button type="button" className="btn btn-primary mr-2" onClick={e => this.showComplianceRenewalModal()}>
              <Icon path={mdiConsoleNetwork} className="menu-arrow" size={0.75} color="#ffffff" />
              &nbsp;Initiate Renewals
            </button>
          ): null}
          <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.changeReadOrEditForm(component); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            &nbsp;Edit
          </button>
        </div>
      )
    }
  }

  parentButtonListHTML = () => {
    const { nonprofits } = this.props;
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper" key="buttons">
          <button type="button" className="btn btn-primary mr-2" onClick={e => { 
            e.preventDefault(); 
            this.checkNonprofitCompliance()
          }}>
            {(nonprofits.set_status.compliance === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiConsoleNetwork} className="menu-arrow" size={0.75} color="#ffffff" />
              )}
            &nbsp;Check compliance
          </button>
        </div>
      );
  }

  checkNonprofitCompliance = () => {
    const { Nonprofits, Notification } = this.props;
    const { nonprofit } = this.state;
    const approved_compliances = nonprofit.required_compliances.map(i => ({ id: i }));
    Nonprofits.checkNonprofitCompliance(nonprofit._id, { approved_compliances }).then(() => {
      // Do Nothing 
    }).catch((e) => {
      console.log("Errors are", "==>>", JSON.stringify(e));
    });
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { nonprofit } = this.state;
    const compliance = nonprofit['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  errorHTML = () => {
    const { nonprofits } = this.props;
    const { nonprofit: errors } = nonprofits.errors;    
    if (errors && errors.length > 0) {
      return (
        <div>
          {errors.map(i => (
            Object.values(i).map((j, idx) => (
              <div key={idx}>
                {j}
              </div>
            ))
          ))}
        </div>
      );
    }
    return null;
  }

  whichForm = () => {
    const { readEdit, nonprofit, prevNonprofit } = this.state;
    const { config, event, nonprofits } = this.props;
    let returnDOM = [];
    if(readEdit === 'general') {
      returnDOM.push(
        <NonprofitBasicDetailsEdit key="basic" value={nonprofit} errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config}>
          {this.buttonListHTML('general')}
        </NonprofitBasicDetailsEdit>
      );
    } else {
      returnDOM.push(
        <NonprofitBasicDetailsRead key="basic" tabs={{ Draft: nonprofit, Live: prevNonprofit }} changeReadOrEditForm={this.changeReadOrEditForm}>
          {this.buttonListHTML('general')}
        </NonprofitBasicDetailsRead>
      );
    }

    if(readEdit === 'cause') {
      returnDOM.push(
        <NonprofitCauseEdit key="cause" value={nonprofit} errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config}>
          {this.buttonListHTML('cause')}
        </NonprofitCauseEdit>
      );
    } else {
      returnDOM.push(
        <NonprofitCauseRead key="cause" value={nonprofit}>
          {this.buttonListHTML('cause')}
        </NonprofitCauseRead>
      );
    }

    returnDOM.push(
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">Tags</h3>
              </div>
              <AdminEntityTagWidget
                entity_id={nonprofit?._id}
                entity_type="nonprofits"
                isReadMode={false}
              />
            </div>
          </div>
        </div>
      </div>
    );

    if(readEdit === 'settings') {
      returnDOM.push(
        <NonprofitAdminSettingsEdit key="admin" value={nonprofit} errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config} event={event}>
          {this.buttonListHTML('settings')}
        </NonprofitAdminSettingsEdit>
      );
    } else {
      returnDOM.push(
        <NonprofitAdminSettingsRead key="admin" live={prevNonprofit}>
          {this.buttonListHTML('settings')}
        </NonprofitAdminSettingsRead>
      );
    }

    if(readEdit === 'gallery') {
      returnDOM.push(
        <GalleryEdit key="gallery" value={nonprofit} errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config}>
          {this.buttonListHTML('gallery')}
        </GalleryEdit>
      );
    } else {
      returnDOM.push(
        <GalleryRead key="gallery" tabs={{ Draft: nonprofit, Live: prevNonprofit }}>
          {this.buttonListHTML('gallery')}
        </GalleryRead>
      );
    }

    if (this.shouldShowItemOnCompliance('fbassured|giveassured')) {
      if (readEdit === 'documents') {
        returnDOM.push(
          <NonprofitDocumentsEdit key="documents" value={nonprofit} errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config} >
            {this.buttonListHTML('documents')}
          </NonprofitDocumentsEdit>
        );
      } else {
        returnDOM.push(
          <NonprofitDocumentsRead key="documents" value={nonprofit} tabs={{ Draft: nonprofit, Live: prevNonprofit }} >
            {this.buttonListHTML('documents')}
          </NonprofitDocumentsRead>
        );
      }

      if (readEdit === 'poc') {
        returnDOM.push(
          <NonprofitPointOfContactEdit key="poc" value={nonprofit} errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config}>
            {this.buttonListHTML('poc')}
          </NonprofitPointOfContactEdit>
        );
      } else {
        returnDOM.push(
          <NonprofitPointOfContactRead key="poc" tabs={{ Draft: nonprofit, Live: prevNonprofit }}>
            {this.buttonListHTML('poc')}
          </NonprofitPointOfContactRead>
        );
      }

      if (readEdit === 'governing') {
        returnDOM.push(
          <NonprofitGoverningBodyEdit key="governing" value={nonprofit} errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config}>
            {this.buttonListHTML('governing')}
          </NonprofitGoverningBodyEdit>
        );
      } else {
        returnDOM.push(
          <NonprofitGoverningBodyRead key="governing" tabs={{ Draft: nonprofit, Live: prevNonprofit }}>
            {this.buttonListHTML('governing')}
          </NonprofitGoverningBodyRead>
        );
      }

      if (readEdit === 'legal') {
        returnDOM.push(
          <NonprofitLegalInformationEdit key="legal" value={nonprofit}  errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config}>
            {this.buttonListHTML('legal')}
          </NonprofitLegalInformationEdit>
        );
      } else {
        returnDOM.push(
          <NonprofitLegalInformationRead key="legal" tabs={{ Draft: nonprofit, Live: prevNonprofit }}>
            {this.buttonListHTML('legal')}
          </NonprofitLegalInformationRead>
        );
      }

      if (readEdit === 'other') {
        returnDOM.push(
          <NonprofitOtherDetailsEdit key="other" value={nonprofit} errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config}>
            {this.buttonListHTML('other')}
          </NonprofitOtherDetailsEdit>
        );
      } else {
        returnDOM.push(
          <NonprofitOtherDetailsRead key="other" tabs={{ Draft: nonprofit, Live: prevNonprofit }}>
            {this.buttonListHTML('other')}
          </NonprofitOtherDetailsRead>
        );
      }
    }

    if (this.shouldShowItemOnCompliance('fbassured')) {
      returnDOM.push(
        <NonprofitSignatoryRead key="signatory" value={prevNonprofit} />
      );
    }

    returnDOM.push(this.errorHTML());
    returnDOM.push(this.parentButtonListHTML());

    if(this.state.showComplianceRenewal && this.state.nonprofit.approved_compliances.length !== 0){
      returnDOM.push(
        <ComplianceRenewalModal 
          key="compliance_renewal"
          nonprofit={this.state.nonprofit} 
          onClose={e => this.showComplianceRenewalModal()} 
          onSubmit={(compliances) => this.setComplianceRenewals(compliances)}
        />
      )
    }

    return (
      <div className="content-wrapper">
        {returnDOM}
      </div>
    );
  }

  render() {
    const { nonprofits } = this.props;
    if (!nonprofits.get_status.nonprofit || nonprofits.get_status.nonprofit === 'pending') {
      return (<Loading />);
    }
    if (!nonprofits.get_status.nonprofit || nonprofits.get_status.nonprofit === 'failed') {
      return (<Redirect to="/404"/>);
    }
    return this.whichForm();
  }
}

const mapStateToProps = state => ({
  nonprofits: state.adminNonprofitItem,
  config: state.config,
  event: state.event,
  complianceApproval: state.complianceApproval,
});

const mapActionToProps = dispatch => ({
  Nonprofits: bindActionCreators(AdminNonprofitItemActions, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
  Event: bindActionCreators(EventAction, dispatch),
  ComplianceApproval: bindActionCreators(ComplianceApprovalActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(AdminNonprofitItem);
