import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { formatDistanceToNow } from 'date-fns';
import Icon from '@mdi/react';
import {
mdiAlertCircle
} from '@mdi/js';
import Loading from '../../../appComponents/Loading';
import * as DashboardActions from '../../../reducers/Crowdfunding/dashboardReducer';
import * as NonprofitOnboarding from '../../../reducers/NGOOnboarding/homeReducer'
import Helpers from '../../../utils/helpers'
import ComplianceOnboardingNotification from '../../../components/ComplianceOnboardingNotification'

class AgreementOverview extends Component {

    componentDidMount() {
        const { dashboard, Dashboard, match, nonprofitOnboarding, Nonprofit } = this.props;
        const { nonprofit } = match.params;
        if (!dashboard.get_status.nonprofit) {
          Dashboard.getNonprofit({ path: [nonprofit] });
        }

        if(!nonprofitOnboarding.get_status.nonprofit) {
            Nonprofit.getNonprofit()
        }
      }
    
      componentDidUpdate(prevProps, prevState) {
        const { dashboard, Dashboard } = this.props;
        const { nonprofit } = dashboard;
        if (nonprofit._id !== prevProps.dashboard.nonprofit._id) {
          Dashboard.getDonations(nonprofit);
          Dashboard.getCampaigns(nonprofit);
        }
      }
    
      componentWillUnmount() {
        const { Dashboard } = this.props;
        Dashboard.reset();
      }
    
    recentDonationsHTML = () => {
      const { dashboard } = this.props;
      if (!dashboard.get_status.donation || dashboard.get_status.donation === 'pending' && dashboard.donations.length === 0) {
        return (
        <Loading />
        );
      }
      if (dashboard.donations.length === 0) {
        return (
          <i className="align-center">No items available</i>
        );
      }
      return dashboard.donations.map(item => (
        <tr key={item.slug}>
          <td className="user-info"><span>{item.user.name}</span></td>
          <td>{item.currency_code}&nbsp;{item.amount}</td>
          <td className="text-right text-navy donation-date">{formatDistanceToNow(new Date(item.created_at))}</td>
        </tr>
      ));
    }

    complianceNotification = () => {
      const { dashboard, match } = this.props      
      if(dashboard.nonprofit && dashboard.nonprofit.required_compliances){
        const required_compliances = dashboard.nonprofit.required_compliances
        const approved_compliances = dashboard.nonprofit.approved_compliances.map(item => { return item.id})        
        const nonapproved_compliances = Helpers.difference(required_compliances, approved_compliances);
        const pendingCompliances = Helpers.difference(nonapproved_compliances, dashboard.nonprofit.submitted_compliances)
        if (pendingCompliances.length) {
          const giveAssuredCompliance = pendingCompliances.filter(item => item !== 'givecompliant')
          return (    
            <div className="card-col col-xl-5 col-lg-5 col-md-5 col-12">
              <ComplianceOnboardingNotification nonprofit_id={match.params.nonprofit} giveAssuredCompliance={giveAssuredCompliance} />            
            </div>
          )
        }
      }
    }


    render() {
    const { match, dashboard } = this.props;
    const { nonprofit } = match.params;

        return (
            <div className="content-wrapper crf-dashboard">
            <div className="row">
              <div className="col-12 grid-margin">
                <div className="row">
                  <div className="card-col col-xl-7 col-lg-7 col-md-7 col-12">
                    <div className="ibox">
                      <div className="ibox-title">
                        <h5>Recent Donations</h5>
                        <div className="text-right">
                          <Link className="btn btn-primary btn-xs" to={`/crowdfunding/${nonprofit}/live`}><span>View All</span></Link>
                        </div>
                      </div>
                      <div className="ibox-content">
                        <table className="table table-hover no-margins recent-donation-list">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Amount</th>
                              <th className="text-right">Date <i className="fa fa-clock-o text-navy"></i></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.recentDonationsHTML()}
                          </tbody>
                        </table>
                        <div className="text-right">
                          <span className="label">Recent 10 donations</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.complianceNotification()}
                </div>
              </div>
            </div>
          </div>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.crowdfundingDashboard,
    nonprofitOnboarding: state.ngoOnboardingHome
  });
  
const mapActionToProps = dispatch => ({
  Dashboard: bindActionCreators(DashboardActions, dispatch),
  Nonprofit: bindActionCreators(NonprofitOnboarding, dispatch),
});
  

  export default connect(
    mapStateToProps,
    mapActionToProps,
  )(AgreementOverview)
