import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Icon from '@mdi/react';
import {
  mdiLoading,
  mdiChevronRight,
} from '@mdi/js';
import { format } from 'date-fns';

import Helpers from '../../../utils/helpers';

import * as CreateBulkCampaignActiions from '../../../reducers/Crowdfunding/createBulkCampaignReducer';

import './CreateBulkCampaign.scss';

import SingleFileInput from '../../../components/SingleFileInput/SingleFileInput';

class CreateBulkCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      prevGetStatus: {},
      sendNotification: 'yes'
    };
  }

  componentWillUnmount() {
    const { Campaign } = this.props;
    Campaign.reset();
  }

  handelFileInput = (e) => {
    this.setState({ file: e });
  }

  handelFileUpload = (e) => {
    const { Campaign } = this.props;
    const { file, sendNotification } = this.state;
    e.preventDefault();
    const data = new FormData();
    data.append('file', file);
    data.append('notify', sendNotification);
    Campaign.createBulkCampaign(data).then(data => {
      const fileName = `GiveFundraisers-create-bulk-campaign-${format(new Date(), 'HH:mm:ss')}`;
      const json = data.map((d) => ({
        "Copy fundraiser from URL": d.uri,
        "Name of User": d.name,
        "Phone/Email": d.identifier,
        "Status": d.status,
        "Remarks": d.Remarks,
        "New Fundraiser URL": d.new_uri,
      }));
      Helpers.convertJSONtoCSV(json, fileName);
    }).catch(e => {
      console.log('Error processing', e);
    });
  }

  handelBack = (e) => {
    const { Sync } = this.props;
    e.preventDefault();
    Sync.syncReset();
  }

  handelSendNotificationChange = (value) => {
    this.setState({ sendNotification: value });
  }

  render() {
    const { campaign } = this.props;
    const { sendNotification } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4>Create Bulk Fundraiser</h4>
                <form className="sync-wrapper" onSubmit={this.handelFileUpload}>
                  <SingleFileInput onChange={this.handelFileInput} accept={['text/csv','application/vnd.ms-excel']} />
                  <div className="form-group">
                    <label> Do you want to send notification to user?</label>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-radio">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="bulk_campaign" value="Yes" checked={sendNotification === 'yes'} onChange={(e) => { this.handelSendNotificationChange('yes'); }} /> Yes <i className="input-helper"></i></label>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-radio">
                          <label className="form-check-label">
                            <input type="radio" className="form-check-input" name="bulk_campaign" value="No" checked={sendNotification === 'no'} onChange={(e) => { this.handelSendNotificationChange('no'); }} /> No <i className="input-helper"></i></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary btn-fw">
                    {(campaign.get_status.bulk === 'pending') ? (
                      <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                    ) : (
                        <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                      )}
                    &nbsp;Submit
                      </button>
                  <a className="download-button" href="/bulk-campaign.csv" target="_blank">Download sample CSV</a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  campaign: state.createBulkCampaign,
});

const mapActionToProps = dispatch => ({
  Campaign: bindActionCreators(CreateBulkCampaignActiions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(CreateBulkCampaign);
