/*
 *  The component is used in Onboarding and dashboard products
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';

import * as LoginAction from '../../reducers/loginReducer';

import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';

class UserDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileDropdown: false,
    };
  }

  handleClickOutside = (e) => {
    const { profileDropdown } = this.state;
    if (profileDropdown === true) {
      e.preventDefault();
      this.setState({ profileDropdown: false });
    }
  }

  handelClickDropdown = (e) => {
    e.preventDefault();
    const { profileDropdown } = this.state;
    this.setState({ profileDropdown: !profileDropdown });
  }

  render() {
    const { profileDropdown } = this.state;
    const { login, Login } = this.props;
    return (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle no-after usern-a" href="#dropdown" data-toggle="dropdown" aria-expanded="false" onClick={this.handelClickDropdown}>
            <span className="usern">{login.user.name || 'User'}</span>
            <Icon path={mdiChevronDown} className="menu-icon" size={0.75} color="#777777" />
          </a>
          <div className={classnames('dropdown-menu', 'dropdown-menu-right', 'navbar-dropdown', { show: profileDropdown })} aria-labelledby="UserDropdown">
            <a className="dropdown-item" href="#dropdown-item" onClick={(e) => { e.preventDefault(); Login.deauthorise(); }}>
              Sign out
            </a>
          </div>
        </li>
      </ul>
    );
  }
}


const mapStateToProps = state => ({
  login: state.login,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(onClickOutside(UserDropdown));
