import API from "../utils/api";
import { endpoints } from "../config";
import _Error from "../utils/_error";

export default class UpdateService {
  static requestAPI(endpoint = endpoints.update, request = {}) {
    return API.request(endpoint, request).then(response => {
      return response
    })
  }

  static getUpdates(id, limit = null, next = null) {
    let _self = this;
    let params = {}
    if (limit) params['limit'] = limit
    if (next) params['next'] = next
    return _self.requestAPI({ url: `${endpoints.update.url}/${id}` }, {
      method: 'GET',
      auth: true,
      params
    }).then(response => {
      return response
    })
  }

  static createUpdate(data, id) {
    let _self = this;
    return _self.requestAPI({ url: `${endpoints.update.url}/${id}` }, {
      method: 'POST',
      data: data,
      auth: true
    })
  }

  static editUpdate(data, projectId, updateId) {
    let _self = this;
    return _self.requestAPI({ url: `${endpoints.update.url}/${projectId}/${updateId}` }, {
      method: 'PUT',
      data: data,
      auth: true
    })
  }

  static deleteUpdate(projectId, updateId) {
    let _self = this;
    return _self.requestAPI({ url: `${endpoints.update.url}/${projectId}/update/${updateId}/delete` }, {
      method: 'POST',
      data: {},
      auth: true
    })
  }

}
