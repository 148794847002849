import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import './NonprofitCauseRead.css';

class NonprofitCauseRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { value } = this.props;
    const val = Helpers.getDataFromObject(value, key);
    if(val === '') {
      return this.na;
    }
    return val;
  }

  render() {
    const { children } = this.props;
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Cause/Retention/Gateway</h3>
                {/* <p className="text-secondary"> A simple suggestion engine </p> */}
                <div className="form-group row">
                  <label className="col-md-12 col-form-label">Nonprofit is a 4-limb or Non 4-limb?</label>
                  <div className="col-sm-6">
                    <p className="text-muted">{this.getInputData(['nonprofit', 'is_4limb']) === true ? '4 Limb' : this.getInputData(['nonprofit', 'is_4limb']) === false ? 'Non 4 Limb' : this.getInputData(['nonprofit', 'is_4limb'])}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Retention %: {this.getInputData(['nonprofit', 'retention_percent'])}</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default NonprofitCauseRead;
