import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import fileUpload from '../../utils/fileUpload';
import * as NotificationAction from '../../reducers/notificationReducer';
import ImageResize from 'quill-image-resize-module-react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'react-quill/dist/quill.snow.css';
import './Editor.css';

const Parchment = Quill.import('parchment')
const BaseImage = Quill.import('formats/image')

Quill.register('modules/imageResize',ImageResize)

const ATTRIBUTES = [
  'alt',
  'height',
  'width',
  'style'
];

const WHITE_STYLE = ['margin', 'display', 'float'];

class Image extends BaseImage {
  static formats(domNode) {
    return ATTRIBUTES.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  
  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        if (name === 'style') {
          value = this.sanitize_style(value);
        }
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  sanitize_style(style) {
    let style_arr = style.split(";")
    let allow_style = "";
    style_arr.forEach((v, i) => {
      if (WHITE_STYLE.indexOf(v.trim().split(":")[0]) !== -1) {
        allow_style += v + ";"
      }
    })
    return allow_style;
  }
}


Quill.register(Image, true)

const formats = [
  'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'image', 'link', 'width', 'height', 'margin', 'block', 'align', 'size', 'imageWithStyle'
];

const Editor = (props) => {
    
  const { value, onChange, className, Notification } = props;
  const [ fileStatus, setFileStatus ] = useState('pending')
  const [ progress, setProgress ] = useState(0)
  const [ modules, setModules ] = useState({})
  
  const quillEditor = useRef()

  const imageHandler = (editorRef) => {

    const input = document.createElement('input')
    const editor = editorRef?.current.getEditor()    
    input.setAttribute('type','file')
    input.setAttribute('accept','image/*')
    input.click()

    input.onchange = async (e) => {
      
      const file = input.files[0]
      if (file) {
        setFileStatus('uploading');
        fileUpload.upload(file, { onUploadProgress: (e) => { onUploadProgress(e); }, cancelToken: (c) => { cancelToken(c); } }).then((response) => {          
          setFileStatus('uploaded')
          setProgress(0)
          const range = editor.getSelection(true)          
          editor.insertEmbed(range.index, 'image', response.file_url)
          editor.setSelection(range.index + 1)

          // onChange(returnData.uri);
        }).catch((error) => {
          if (!(error instanceof Error)) {
            Notification.addNotification(error.message);          
          }
          onChange(null);
          setFileStatus('pending')
          setProgress(0)          
        });
      }
    }
  }
  
  const onUploadProgress = (e) => {
    const percent = e.loaded / e.total * 100;
    const progress = parseFloat(percent);
    if (progress > 0 && progress <= 100) {
      setProgress(progress)
    } else {
      setProgress(0)
    }
  }

  const cancelToken = (e) => {

  }

  const change = (e) => {
    if (onChange) {      
      onChange(e);
    }
  };

  const container =  [
    [ 'bold',  'italic',  'underline',  'strike',  'blockquote' ],
    [
      { list: 'ordered' }, 
      { list: 'bullet' }
    ],
    [
      { align: [] }
    ],
    [ 'clean' ],
    [ 'link', 'image' ],
    [
      { color: [] }
    ],        
  ]

  useEffect(() => {
    setModules({
      toolbar: {
        container: container,
        handlers: {
          image: () => imageHandler(quillEditor),          
        }      
      },
      clipboard: {
        matchVisual: false
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        displaySize: true        
      }
    })
  },[quillEditor])

  return (
    <ReactQuill
      ref={quillEditor}
      defaultValue={value}
      onChange={(value, delta, source, editor) => {        
        if(editor.getText().trim() == "") {
          return change(null);
        } else if(source === "user") {
          return change(value); 
        }
      }}
      modules={modules}
      formats={formats}
      className={className}
    />
  );
}

const mapStateToProps = () => ({
});

const mapActionToProps = dispatch => ({
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Editor);
