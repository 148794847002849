import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as LoginAction from '../../../reducers/loginReducer';
import * as nonprofitsActions from '../../../reducers/Profile/nonprofitsReducer';
import classnames from 'classnames';
import Navbar from '../../../appComponents/Navbar';
import ViewType from '../../../appComponents/ViewType';
import ScrollToTop from '../../../appComponents/ScrollToTop';
import Footer from '../../../appComponents/Footer';
import ComplianceOnboardingNotification from '../../../components/ComplianceOnboardingNotification'

import Sidebar from '../../../appComponents/Sidebar';
import Helpers from './../../../utils/helpers'

import profileRoutes from '../../../routes/productRoutes/profile';
import MigrationNotification from '../../../appComponents/MigrationNotification';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nonprofit: {},
      requiresComplianceOnboarding: null
    }
  }

  componentDidMount() {
    const { nonprofits, Nonprofits, match } = this.props;
    const { nonprofit } = match.params;
    if (!nonprofits.get_status.nonprofit) {
      Nonprofits.getNonprofit({ path: [nonprofit] });
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const setData = {}
    if (props.nonprofits.nonprofit && !Helpers.isEqual(props.nonprofits.nonprofit, state.nonprofit)) {
      setData.nonprofits = props.nonprofits.nonprofit
      const requiredCompliances = props.nonprofits.nonprofit?.required_compliances
      const approvedCompliances = props.nonprofits.nonprofit?.approved_compliances
      if (requiredCompliances && requiredCompliances.length !== 0) {
        const difference = Helpers.difference(requiredCompliances, approvedCompliances)
        if (difference.length !== 0) {
          setData.requiresComplianceOnboarding = difference[0]
        }
      }
    }

    return setData
  }


  render() {
    const { Login, login, location, nonprofits, match } = this.props;
    const { nonprofit } = nonprofits || {}
    const { slug } = nonprofit || {}
    const params = { nonprofit: match.params.nonprofit, compliance: nonprofits.nonprofit.required_compliances }
    let userType = 'user';
    if (login.user.is_admin) {
      userType = 'admin';
    }
    const giveFoundationNpName = 'give-foundation'
    const giveDoNpName = 'give-do'
    const giveNpName = 'give'

    const checkIsGiveFoundation = () => slug === giveFoundationNpName || slug === giveNpName|| slug === giveDoNpName;
    const nonGiveSideBarRoutes = profileRoutes.sidebar.filter(item => item.title !== "10BD/BE")
    const sideBarRoutes = checkIsGiveFoundation() ? profileRoutes :  { ...profileRoutes, sidebar: nonGiveSideBarRoutes };
    return (
      <ScrollToTop location={location}>
        <div className="container-scroller">
          {/* Adds notification */}
          {/* <MigrationNotification /> */}
          <Navbar
            Login={Login}
            login={login}
            location={location}
            user={userType}
            routesList={profileRoutes}
            params={params}
          />
          <div className="container-fluid page-body-wrapper sidebar-fixed">
            <Sidebar
              location={location}
              // routesList={sideBarRoutes}
              routesList={profileRoutes}
              params={params}
            />
            <div className="main-panel">
              <ViewType user={userType} routesList={profileRoutes} />
              <Footer />
            </div>
          </div>
        </div>
      </ScrollToTop>
    );
  }
}
const mapStateToProps = state => ({
  login: state.login,
  nonprofits: state.profileNonprofits,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
  Nonprofits: bindActionCreators(nonprofitsActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
)(Home));
