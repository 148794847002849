/**
 * Service function to make API call
 */
import memoize from 'memoize-one';

import _Error from '../utils/_error';
import API from '../utils/api';
import { endpoints } from '../config';
import Storage from '../utils/storage';

export default class ProjectService {
  static requestAPI(endpoint = endpoints.projects, request = {}) {
    return API.request(endpoint, request);
  }

  static cacheGet = memoize(this.requestAPI);

  static get(request) {
    // also define getlist of project
    const token = Storage.getItem('token');
    if (token) {
      return this.cacheGet(undefined, request);
    }
    return Promise.reject(new _Error('', { status: 401 }));
  }

  static create(request) {
    return this.requestAPI(undefined, request);
  }

  static set(request) {
    let newRequest = request;
    if (request.data && request.data.nonprofit && typeof request.data.nonprofit === 'object') {
      newRequest = { ...request, data: { ...request.data } };
      delete newRequest.data.nonprofit;
    }
    return this.requestAPI(undefined, newRequest);
  }
}
