import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import DonationService from '../../services/donation-service';
import ReportService from "../../services/report-service";

export const GET_CROWDFUNDING_ALL_DONATIONS = 'GET_CROWDFUNDING_ALL_DONATIONS';
export const GET_CROWDFUNDING_ALL_DONATIONS_SUCCESS = 'GET_CROWDFUNDING_ALL_DONATIONS_SUCCESS';
export const GET_CROWDFUNDING_ALL_DONATIONS_FAIL = 'GET_CROWDFUNDING_ALL_DONATIONS_FAIL';

export const GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD = 'GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD';
export const GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD_SUCCESS = 'GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD_SUCCESS';
export const GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD_FAIL = 'GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD_FAIL';

export const CROWDFUNDING_DASHBOARD_RESET = 'CROWDFUNDING_DASHBOARD_RESET';

export const GENERATE_ALL_DONATIONS_REPORT_LINK = 'GENERATE_ALL_DONATIONS_REPORT_LINK';
export const GENERATE_ALL_DONATIONS_REPORT_LINK_SUCCESS = 'GENERATE_ALL_DONATIONS_REPORT_LINK_SUCCESS';
export const GENERATE_ALL_DONATIONS_REPORT_LINK_FAIL = 'GENERATE_ALL_DONATIONS_REPORT_LINK_FAIL';

export const GET_ALL_DONATIONS_REPORT_LINK = 'GET_ALL_DONATIONS_REPORT_LINK';
export const GET_ALL_DONATIONS_REPORT_LINK_SUCCESS = 'GET_ALL_DONATIONS_REPORT_LINK_SUCCESS';
export const GET_ALL_DONATIONS_REPORT_LINK_FAIL = 'GET_ALL_DONATIONS_REPORT_LINK_FAIL';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  donations: [],
  get_status: {},
  load_status: {},
};

export const allDonationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CROWDFUNDING_ALL_DONATIONS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation: 'pending',
        },
      };
    case GET_CROWDFUNDING_ALL_DONATIONS_SUCCESS:
      return {
        ...state,
        donations: action.donations,
        get_status: {
          ...state.get_status,
          donation: 'success',
        },
        load_status: {
          ...state.load_status,
          donation: action.load_status,
        }
      };
    case GET_CROWDFUNDING_ALL_DONATIONS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation: 'failed',
        },
      };
    case GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'pending',
        },
      };
    case GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'success',
        },
      };
    case GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'failed',
        },
      };
    case CROWDFUNDING_DASHBOARD_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    case GENERATE_ALL_DONATIONS_REPORT_LINK:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'pending',
        },
      };
    case GENERATE_ALL_DONATIONS_REPORT_LINK_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'success',
        },
      };
    case GENERATE_ALL_DONATIONS_REPORT_LINK_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'failed',
        },
      };
    case GET_ALL_DONATIONS_REPORT_LINK:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'pending',
        },
      };
    case GET_ALL_DONATIONS_REPORT_LINK_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'success',
        },
      };
    case GET_ALL_DONATIONS_REPORT_LINK_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'failed',
        },
      };
    default:
      return state;
  }
};

export function getDonations(request) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CROWDFUNDING_ALL_DONATIONS,
    });
    const currentDonations = getState().allNgoDonations.donations;
    const params = {
      ...request,
      skip: currentDonations.length,
      limit: 10,
    };
    return DonationService.get({ params })
      .then((response) => {
        if (response.supporters.length === 0) {
          dispatch({
            type: GET_CROWDFUNDING_ALL_DONATIONS_SUCCESS,
            donations: currentDonations,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_CROWDFUNDING_ALL_DONATIONS_SUCCESS,
            donations: [...currentDonations, ...response.supporters],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CROWDFUNDING_ALL_DONATIONS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getDonationsForDownload(request) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD,
    });
    const params = {
      ...request,
      skip: 0,
      limit: 1000,
    };
    return DonationService.get({ params })
      .then((response) => {
          dispatch({
            type: GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD_SUCCESS,
          });
        return response.supporters;
      })
      .catch((error) => {
        dispatch({
          type: GET_CROWDFUNDING_ALL_DONATIONS_FOR_DOWNLOAD_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function generateReportLink({...query}, exportFilters=[], nonprofit ='' ) {
  return (dispatch) => {
    dispatch({
      type: GENERATE_ALL_DONATIONS_REPORT_LINK,
    });
    if(!query.after || !query.before){  // Required Fields
      dispatch({
        type: GENERATE_ALL_DONATIONS_REPORT_LINK_FAIL,
      });
      dispatch(NotificationAction.addNotification('Created after and Created before are required fields'));
      return;
    }
    const request = {
      data:{
        selector: exportFilters,
        filter: {
          startDate: query.after.replaceAll('%3A',':'),
          endDate: query.before.replaceAll('%3A',':'),
          phone: query.filter_by_user_phone || '',
          orderId: query.filter_by_order_id || '',
          email: query.filter_by_user_email || '',
          campaigns: query.filter_by_campaign  || '',
          projects: query.filter_by_project || [],
          nonprofitId: nonprofit.filter_by_nonprofit || '',
          status : 'ALL'
        },
      },
      method: 'post'
    };
    return ReportService.get(request)
      .then((response) => {
        dispatch({
          type: GENERATE_ALL_DONATIONS_REPORT_LINK_SUCCESS,
        });
        dispatch(NotificationAction.addNotification('Your request has been received. Please check the Queued Downloads for the status of your request.', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GENERATE_ALL_DONATIONS_REPORT_LINK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
    };
  }

export function getReportLinks() {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_DONATIONS_REPORT_LINK,
    });
    return ReportService.get()
      .then((response) => {
        dispatch({
          type: GET_ALL_DONATIONS_REPORT_LINK_SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_DONATIONS_REPORT_LINK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
    };
  }


export function reset() {
  return (dispatch) => {
    dispatch({
      type: CROWDFUNDING_DASHBOARD_RESET,
    });
  };
}
