import * as NotificationAction from './notificationReducer';
import ConfigService from '../services/config-service';

export const GET_CONFIG = 'GET_CONFIG';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAIL = 'GET_CONFIG_FAIL';

export const CONFIG_RESET = 'CONFIG_RESET';

const initialState = {
  get_status: {},
};

export const configReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CONFIG:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          config: 'pending',
        },
      };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.config,
        get_status: {
          ...state.get_status,
          config: 'success',
        },
      };
    case GET_CONFIG_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          config: 'failed',
        },
      };
    default:
      return state;
  }
};

export function get() {
  return (dispatch) => {
    dispatch({
      type: GET_CONFIG,
    });
    return ConfigService.get()
      .then((response) => {
        dispatch({
          type: GET_CONFIG_SUCCESS,
          config: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CONFIG_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: CONFIG_RESET,
    });
  };
}
