import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';

class NonprofitLegalInformationRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
    };
  }

  switchView = (view) => {
    this.setState({ view: view });
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if(val === '' && key[1] !== 'video') {
      return this.na;
    }
    return val;
  }

  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if(to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if(to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if(to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if(to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  render() {
    const { children, tabs } = this.props;
    const { view } = this.state;
    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">Legal information</h3>
                <div className="heading-bar-btn">
                  {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab => {
                    if (tab === view) {
                      return (
                        <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                      );
                    }
                    return (
                      <button type="button" className="btn btn-outline-secondary btn-left" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                    );
                  })}
                </div>
              </div>
              <h4 className="mr-b-20">Registration &amp; Legal Information</h4>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'legal', '12a_revoked']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Has your 12A ever been revoked?</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'legal', '12a_revoked'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'legal', '12a_revoked_reason']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Please provide details of why it was revoked and when it was restored?</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'legal', '12a_revoked_reason'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'pending_litigation_organization']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6>Is there a pending litigation, by any party against the organization?</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'pending_litigation_organization'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'pending_litigation_organization_doc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6>If Yes, then please upload a declaration form.</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'pending_litigation_organization_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'legal', 'pending_litigation_organization_doc'])}>Preview</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'pending_litigation_party']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6>Is there a pending litigation, by the organization against any party?</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'pending_litigation_party'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'pending_litigation_party_doc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6>If Yes, then please upload a declaration form.</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'pending_litigation_party_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'legal', 'pending_litigation_party_doc'])}>Preview</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <h4 className="mr-b-20 mr-t-20">Norms for 2020-21</h4>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'status_questioned']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Has your non-profit status been questioned under any income tax assessment?</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'status_questioned'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'legal', '80g_valid']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Do you have a valid 80 G?</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'legal', '80g_valid'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'fcra_revoked']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Has your FCRA registration been revoked or suspended?</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'fcra_revoked'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'half_fcra_funds_on_overheads']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                      <div>
                        <h6> Have you spent more than 20% of FCRA funds on overheads?</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'half_fcra_funds_on_overheads'])}</p>
                      </div>
                    </ApprovalsRejectionsButtons>
                  </div>
                </div>
              ) : null}
              {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'transactions_conflict']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                      <div>
                        <h6> Are there material transactions involving conflict of interest between a Board/ Staff member of your organization, unless specifically approved in a Board meeting where the concerned member has not voted?</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'transactions_conflict'])}</p>
                      </div>
                    </ApprovalsRejectionsButtons>
                  </div>
                </div>
              ) : null}
              {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'separate_fcra']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                      <div>
                        <h6> You are recording/ will record donations received and expenses incurred from Give.do/Give USA funds separately, either as a separate cost centre or group in electronic form, or by maintaining a separate register for all such donations and expenses in physical form, so as to generate a current "utilization statement" at short notice.</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'separate_fcra'])}</p>
                      </div>
                    </ApprovalsRejectionsButtons>
                  </div>
                </div>
              ) : null}
              {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'serious_audit_notes']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                      <div>
                        <h6> Are there any serious audit notes on any material point?</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'serious_audit_notes'])}</p>
                      </div>
                    </ApprovalsRejectionsButtons>
                  </div>
                </div>
              ) : null}
              {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <ApprovalsRejectionsButtons path={['nonprofit', 'legal', 'will_submit_fuc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                      <div>
                        <h6> The NGO will submit Chartered Accountant certified Annual Fund Utilization Certificates for funds received in the financial year, through Give.do/ GiveUSA, latest by Oct 31st of the next financial year</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'legal', 'will_submit_fuc'])}</p>
                      </div>
                    </ApprovalsRejectionsButtons>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NonprofitLegalInformationRead;
