import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classnames from 'classnames';

import TabPreDisbursement from '../TabPreDisbursement';
import TabGenerateDisbursement from '../TabGenerateDisbursement';
import TabDisbursement from '../TabDisbursement';

import * as DisbursementActions from '../../../reducers/Disbursements/disbursementReducer';
import * as NotificationActions from '../../../reducers/notificationReducer';
import Stats from '../Stats';

class Disbursement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'disbursement',
      prevGetStatus: {},
    };
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.disbursement.get_status !== state.prevGetStatus && Object.keys(props.disbursement.get_status).length) {
      setData.prevGetStatus = props.disbursement.get_status;
      if (!props.disbursement.get_status.stat) {
        props.Disbursement.getStats();
      }
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  componentDidMount() {
    const { disbursement, Disbursement } = this.props;
    if (!disbursement.get_status.pg) {
      Disbursement.getPG();
    }
    if (!disbursement.get_status.stat) {
      Disbursement.getStats();
    }
  }

  componentWillUnmount() {
    const { Disbursement } = this.props;
    Disbursement.reset();
  }

  changeDisbursementTabs = (to) => {
    this.setState({ view: to });
  }

  disbursementMenu = () => {
    const { view } = this.state;
    return (
      <ul className="table-tab">
        <li className={classnames({ active: (view === 'disbursement') })}><a href="#disbursement" onClick={(e) => { e.preventDefault(); this.changeDisbursementTabs('disbursement') }}>Disbursement</a></li>
        <li className={classnames({active: (view === 'pre')})}><a href="#pre" onClick={(e) => { e.preventDefault(); this.changeDisbursementTabs('pre') }}>Pre-disbursement</a></li>
        <li className={classnames({ active: (view === 'generate') })}><a href="#generate" onClick={(e) => { e.preventDefault(); this.changeDisbursementTabs('generate') }}>Generate Pre-disbursement</a></li>
      </ul>
    );
  }

  renderDisbursementTabs = () => {
    const { view } = this.state;
    const { disbursement, Notification, Disbursement } = this.props;
    switch (view) {
      case 'disbursement':
        return (
          <TabDisbursement disbursement={disbursement} Notification={Notification} Disbursement={Disbursement}>
            {this.disbursementMenu()}
          </TabDisbursement>
        );
      case 'pre':
        return (
          <TabPreDisbursement disbursement={disbursement} Notification={Notification} Disbursement={Disbursement} changeDisbursementTabs={this.changeDisbursementTabs}>
            {this.disbursementMenu()}
          </TabPreDisbursement>
        );
      case 'generate':
        return (
          <TabGenerateDisbursement disbursement={disbursement} Notification={Notification} Disbursement={Disbursement}>
            {this.disbursementMenu()}
          </TabGenerateDisbursement>
        );
      default:
        return null;
    }
  }

  render() {
    const { disbursement } = this.props;
    return (
      <div className="content-wrapper">
        <div className="row">
          <Stats value={disbursement.stats} loading={disbursement.get_status.stat === 'pending'} />
          {this.renderDisbursementTabs()}
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  disbursement: state.disbursementsDisbursements,
});

const mapActionToProps = dispatch => ({
  Disbursement: bindActionCreators(DisbursementActions, dispatch),
  Notification: bindActionCreators(NotificationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Disbursement);