import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link, Redirect } from 'react-router-dom';

import Icon from '@mdi/react';
import {
  mdiPlus,
} from '@mdi/js';

import * as projectsReducer from '../../../reducers/Profile/projectsReducer';

import './Project.css';

import Loading from '../../../appComponents/Loading';
import ProjectTile from '../../../components/ProjectTile/ProjectTile';

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { projects, Projects, match } = this.props;
    const { nonprofit } = match.params;
    if (!projects.get_status.project) {
      Projects.getProject({ params: { nonprofit } });
    }
  }

  componentWillUnmount() {
    // const { Projects } = this.props;
    // Projects.reset();
  }

  render() {
    const { projects, match } = this.props;
    const { nonprofit } = match.params;
    if (!projects.get_status.project || projects.get_status.project === 'pending') {
      return (<Loading />);
    }
    if (!projects.get_status.project || projects.get_status.project === 'failed') {
      return (<Redirect to="/404"/>);
    }
    return (
      <div className="col-md-12 grid-margin">
        <div className="p-tiles-wrapper mt-3">
          <Link to={'projects/add-project'} className="project-tiles">
            <Icon path={mdiPlus} size={9} color="#4a4a4a" />
            <div className="p-2 pb-0">
              <p>Add a project</p>
            </div>
          </Link>
          {projects.projects.map(item => (
            <ProjectTile value={item} key={item._id} link={`/profile/${nonprofit}/projects/`} />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.profileProjects,
});

const mapActionToProps = dispatch => ({
  Projects: bindActionCreators(projectsReducer, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Project);
