import React, { Component } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import Icon from '@mdi/react';
import { mdiChevronRight, mdiFloppy, mdiMinus, mdiMinusCircle, mdiPlus, mdiTrashCan } from '@mdi/js';
import Editor from '../Editor';
import ImageCropUpload from '../ImageCropUpload';
import Helpers from '../../utils/helpers';
import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';

class FaqRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
      projectLocation: '',
      prevValue: {},
      selectedGalleryItem: 0,
    };
  }

  switchView = (view) => {
    this.setState({ view: view });
  }

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if((val === '' || val === null) && key[1] !== 'video' && key[1] !== 'donation_options') {
      return this.na;
    }
    return val;
  }

  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if(to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if(to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if(to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if(to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }

  render() {
    const { children, tabs, status } = this.props;
    const { view } = this.state;

    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3 className="mr-b-20">FAQ for Project</h3>
              
                <div className="heading-bar">
                  <h4 className="mr-b-20">FAQ's</h4>
                  <div className="heading-bar-btn">
                    {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab=>{
                      if (tab === view) {
                        return (
                        <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                        );
                      }
                      return (
                      <button type="button" className="btn btn-outline-secondary btn-right" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                      );
                    })}
                  </div>
                </div>         
                <ApprovalsRejectionsButtons path={['project', 'faqs']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>     
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      {this.getInputData(['project', 'faqs']) && this.getInputData(['project', 'faqs']).length > 0 ?
                        this.getInputData(['project', 'faqs']).map((item, idx) => 
                          <div key={idx} className="mb-2">
                            <p className="mr-b-0">{item.question}</p>
                            <p className="text-muted">{item.answer}</p>
                            {/* <div>
                              <span>{`Ques ${idx+1}: `}</span>
                              <span className="mr-b-0">{item.question}</span>
                            </div>
                            <div className="text-muted">
                              <span>{`Ans ${idx+1}: `}</span>
                              <span>{item.answer}</span>
                            </div> */}
                          </div>
                        ) : null
                      }
                    </div>
                  </div>
                </div>
              </ApprovalsRejectionsButtons>
            </div>         

            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default FaqRead;
