import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiPlus, mdiTrashCan } from '@mdi/js';
import Helpers from '../../utils/helpers';
class FaqEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: {_id: props.value._id},
    };
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes} = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      value.faqs.forEach((item) => {
        if(item.index === undefined && item.index !== key[2]) { 
          item.index = key[2]
          item.answerError = ''
          item.questionError = ''
        }
      });
      this.setState({ changes: { ...changes, ...change.changes}});
      onChange(change.data);
    }
  }

  validate = (values) => { 
    return values.map((item) => { 
      let question = item.question.trim();
      let answer = item.answer.trim();
      if(item.question && question.length < 10){
        item.questionError =
        "Please enter a question with atleast 10 characters!";
      } else if (item.question && question.length > 10) {
        item.questionError = '';
      }
       if(item.answer && answer.length < 10 ){
        item.answerError =
        "Please enter a answer with atleast 10 characters!";
      } else if(item.answer && answer.length > 10 ) {
        item.answerError =
        "";
      }
      if(!item.question) {
        item.questionError =
        "Question is required!";
      } 
      if(!item.answer) {
        item.answerError =
        "Answer is required!";
      }
      return item
    })
  };

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    if (onSubmit) {
      let count = 0;
      const result = {...changes}
      const faqItems = this.validate(changes.faqs)
      result.faqs = [...faqItems]
      faqItems.map((item) => {
        if(item.questionError || item.answerError) {
          count ++;
        } 
      })
      if(count === 0) {
        onSubmit(changes)
      }
      else {
        this.setState({
          changes : result
        })
      }
    }
  }

  addFaq = () => {
    let newFaqItem = {
      "question": "",
      "answer": ""
    }
    this.handelInputChange(['project', 'faqs'], newFaqItem);
  }

  render() {
    const { children, value } = this.props;
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">FAQ for Project</h3>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3 pb-3">
                      {this.getInputData(['project', 'faqs']).length > 0? 
                       this.getInputData(['project', 'faqs']).map((item, idx) => {
                        return (
                          <div className="c-que-wrapper" key={idx}>
                            <div className="form-group">
                              <label>Question</label>
                              <input type="text" className="form-control" placeholder="question" value={item.question} onChange={(e) => { this.handelInputChange(['project', 'faqs', idx, 'question'], e.target.value)}}/>
                                {<p className="error-message col-sm-12">{item.questionError}</p>}
                            </div>
                            <div className="form-group">
                              <label>Answer</label>
                              <textarea rows="3" className="form-control" placeholder="answer" value={item.answer} onChange={(e) => { this.handelInputChange(['project', 'faqs', idx, 'answer'], e.target.value)}} />
                              {<p className="error-message col-sm-12">{item.answerError}</p>}
                            </div>
                            <div className="row flex-grow">
                              <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                                <button type="button" className="btn btn-light mr-2 flx-h-right" onClick={() => this.handelInputChange(['project', 'faqs', idx], null)}><Icon path={mdiTrashCan} className="menu-arrow" size={0.75} color="#000000" /> Delete</button>
                              </div>
                            </div>
                          </div>)}) 
                        : null
                        }
                      <div className="row flex-grow">
                        <div className="col-md-12 grid-margin stretch-card flx-h-left np-btn-wrapper">
                          <button type="button" className="btn btn-primary mr-2" onClick={this.addFaq}><Icon path={mdiPlus} className="menu-arrow" size={0.75} color="#ffffff" /> Add</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default FaqEdit;
