import React, { Component } from 'react';

import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronRight, mdiLoading } from '@mdi/js';

import Loading from '../../../appComponents/Loading';
import Failed from '../../../appComponents/Failed';
import { downloadPdf } from '../../../utils/helpers';
import DateInput from '../../../components/DateInput';
import { format } from 'date-fns';
import { baseUrl } from '../../../config';
import Storage from '../../../utils/storage';
import SingleFileInput from "../../../components/SingleFileInput/SingleFileInput";

class TabDisbursementRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utrNumber: '',
      actualDisbursementDate: '',
      displayDatePicker: false,
      disableButton: false
    };
    this.calendarRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.calendarRef.current && !this.calendarRef.current.contains(event.target)) {
      this.setState({ displayDatePicker: false });
    }
  }

  handleUTRFieldChange(e) {
    const { value } = e.target;
    const { actualDisbursementDate, displayDatePicker } = this.state;
    this.setState({
      utrNumber: value,
      actualDisbursementDate,
      displayDatePicker
    });
  }

  handleDatePickerChange = (e) => {
    const date = new Date(e);
    const { utrNumber } = this.state;
    this.setState({
      utrNumber,
      actualDisbursementDate: format(date, 'yyyy-MM-dd'),
      displayDatePicker: false
    });
  }

  handleDisplayDatePicker() {
    if (this.calendarRef?.current) {
      this.calendarRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const { utrNumber, actualDisbursementDate } = this.state;
    this.setState({
      utrNumber,
      actualDisbursementDate,
      displayDatePicker: true
    });
  }

  handleDatePickerBlur = () => {
    const { utrNumber, actualDisbursementDate } = this.state;
    this.setState({
      utrNumber,
      actualDisbursementDate,
      displayDatePicker: false
    });
  }

  determineIfAllowToSettle = () => {
    const { item } = this.props;
    if (this.state.disableButton) {
      if (item.utr_number && item.actual_disbursement_date) {
        return false;
      } else if (this.state.actualDisbursementDate === '' || this.state.utrNumber === '') {
        return true;
      } else {
        return true;
      }
    } else {
      if (item.utr_number && item.actual_disbursement_date) {
        return false;
      } else if (this.state.actualDisbursementDate === '' || this.state.utrNumber === '') {
        return true;
      } else {
        return false;
      }
    }
  }

  handleSettleButtonClick = async (item) => {
    const { utrNumber, actualDisbursementDate, displayDatePicker } = this.state;
    const {utr_number,actual_disbursement_date}=item
    const actaullDData = actualDisbursementDate || actual_disbursement_date
    const utrNum = utrNumber ||utr_number
    const token = Storage.getItem('token');
    const requestBody = JSON.stringify({
      utr_number: utrNum,
      actual_disbursement_date: format(new Date(actaullDData), 'yyyy-MM-dd')
    });
    const isUtrNumberAndDisDatePresent = utr_number?.length && actual_disbursement_date?.length;
    if (!isUtrNumberAndDisDatePresent) {
      try {
        const updateDisbursement = await fetch(`${baseUrl}/api/donations/disbursements/${item.disbursement_number}/update`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'content-type': 'application/json'
          },
          body: requestBody
        });
        const updateApiResponse = await updateDisbursement.json();
        if (updateApiResponse.status_code === 200) {
          this.props.markAsSettleDisbursement(item.disbursement_number);
          this.setState({
            utrNumber,
            actualDisbursementDate,
            displayDatePicker,
            disableButton: true
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      this.props.markAsSettleDisbursement(item.disbursement_number);
      this.setState({
        utrNumber,
        actualDisbursementDate,
        displayDatePicker,
        disableButton: true
      });
    }


  }

  render() {
    const { item, disbursement } = this.props;
    return (
      <li className="table-list-item" key={item.disbursement_number}>
        <div className="row">
          <div className="col-md-2 col-12 table-col dl-mob">
            <strong className="show-mobile">NGO Name</strong>
            <span>{item.nonprofit.name}</span>
          </div>
          <div className="col-md-2 col-12 table-col dl-mob">
            <strong className="show-mobile">UTR Number</strong>
            {item.utr_number ? <span>{item.utr_number}</span> : <input style={{
              width: '100%'
            }} onChange={(e) => this.handleUTRFieldChange(e)} value={this.state.utrNumber} />}
          </div>
          <div className="col-md-2 col-12 table-col dl-mob" ref={this.calendarRef}>
            <strong className="show-mobile">Actual date of disbursement</strong>
            {item.actual_disbursement_date ? <span>{item.actual_disbursement_date}</span> : (
              <>
                <button
                  style={{
                    border: 'none',
                    background: 'transparent',
                  }}
                  onClick={() => this.handleDisplayDatePicker()}
                >{this.state.actualDisbursementDate !== '' ? this.state.actualDisbursementDate : 'Add Date'}</button>
                {this.state.displayDatePicker && <DateInput onChange={this.handleDatePickerChange} position="bottomLeft" />}
              </>)
            }
          </div>
          <div className="col-md-1 col-12 table-col dl-mob">
            <strong className="show-mobile">Disbursement No</strong>
            <span>{item.disbursement_number}</span>
          </div>
          <div className="col-md-1 col-12 table-col dl-mob">
            <strong className="show-mobile">No. of donations</strong>
            <span>{item.donation_count}</span>
          </div>
          <div className="col-md-1 col-12 table-col dl-mob">
            <strong className="show-mobile">Retention</strong>
            <span>{`${item.currency_code} ${item.payable_amount}`}</span>
          </div>
          <div className="col-md-1 col-12 table-col dl-mob txt-right">
            <strong className="show-mobile">Amount</strong>
            <span>{`${item.currency_code} ${item.payable_amount}`}</span>
          </div>
          <div className="col-md-2 col-12 table-col dl-mob txt-right">
            {(item.status === 'APPROVED') ? (
              <button
                disabled={this.determineIfAllowToSettle()}
                className="btn btn-success btn-sm"
                onClick={(e) => { e.preventDefault(); this.handleSettleButtonClick(item); }}
              >
                {(disbursement.get_status['settle_' + item.disbursement_number] === 'pending') ? (
                  <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                ) : (
                  <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                )}
                &nbsp;Mark settle
              </button>
            ) : (
              <button className="btn btn-success btn-sm" onClick={(e) => { e.preventDefault(); this.resendDisbursementReport(item.disbursement_number); }}>
                {(disbursement.get_status['send_report_' + item.disbursement_number] === 'pending') ? (
                  <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                ) : (
                  <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                )}
                &nbsp;Resend report
              </button>
            )}
          </div>
        </div>
      </li>
    );
  }
}

class TabDisbursement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      loadingButton: null,
    };
  }

  componentDidMount() {
    const { disbursement, Disbursement } = this.props;
    if (!disbursement.get_status.disbursement || disbursement.get_status.disbursement !== 'pending') {
      Disbursement.getDisbursements();
    }
  }

  componentWillUnmount() {
    const { Disbursement } = this.props;
    Disbursement.resetTab();
  }

  downloadDisbursementBatch = (batchNo) => {
    const { Disbursement } = this.props;
    this.setState({ loadingButton: 'disbursement' });
    Disbursement.downloadDisbursementBatch(batchNo, 'disburseTab').then(response => {
      if (response && response.report_uri) {
        // const fileTab = window.open(response.report_uri, '_blank');
        // fileTab.focus();
        downloadPdf(response.report_uri);
      }
      this.setState({ loadingButton: null });
    });
  }

  downloadBankDisbursementBatch = (batchNo) => {
    const { Disbursement } = this.props;
    this.setState({ loadingButton: 'bank' });
    Disbursement.downloadBankDisbursementBatch(batchNo).then(response => {
      if (response && response.report_uri) {
        // const fileTab = window.open(response.report_uri, '_blank');
        // fileTab.focus();
        downloadPdf(response.report_uri);
      }
      this.setState({ loadingButton: null });
    });
  }

  markAsSettleDisbursementBatch = (batchNo) => {
    const { Disbursement } = this.props;
    if (window.confirm('Do you want to mark the disbursment batch as settled?\nNOTE: This will send notification to all partners!')) {
      Disbursement.markAsSettleDisbursementBatch(batchNo);
    }
  }

  markAsSettleDisbursement = (disbursementNo) => {
    const { Disbursement } = this.props;
    if (window.confirm('Do you want to mark the disbursment as settled?\nNOTE: This will send notification to the partners!')) {
      Disbursement.markAsSettleDisbursement(disbursementNo);
    }
  }

  resendDisbursementReport = (disbursementNo) => {
    const { Disbursement } = this.props;
    Disbursement.resendDisbursementReport(disbursementNo);
  }

  ngoHTML = (disbursements) => {
    const { disbursement } = this.props;
    return (
      <div className="col-md-12 table-col table-list-detail">
        <ul>
          <li className="table-head">
            <div className="row">
              <div className="col-md-2 col-12 table-col dl-mob">
                NGO Name
              </div>
              <div className="col-md-2 col-12 table-col dl-mob">
                UTR Number
              </div>
              <div className="col-md-2 col-12 table-col dl-mob">
                Actual Date of Disbursement
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                Disbursement No
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                No. of donations
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                Retention
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                Amount
              </div>
              <div className="col-md-2 col-12 table-col dl-mob txt-right">
                Actions
              </div>
            </div>
          </li>
          {disbursements.map((item, key) => (
            <TabDisbursementRow key={key} item={item} disbursement={disbursement} markAsSettleDisbursement={this.markAsSettleDisbursement} />
          ))}
        </ul>
      </div>
    );
  }

  
uploadSyncFile = (file, batchNo) => {
    const { Disbursement } = this.props;
    const data = new FormData();
    data.append('report', file);
    Disbursement.syncDisbursementBatch(data, batchNo, 'disburseTab').then((r) => {
      Disbursement.getDisbursements();
    });
  }

  actionsHTML = (batchNo) => {
    const { disbursement } = this.props;
    const { loadingButton } = this.state;
    return (
      <div className="col-md-12 table-col tl-btn-group">
        <SingleFileInput onChange={(e) => { this.uploadSyncFile(e, batchNo) }} data={batchNo}>
          <button type="button" className="btn btn-primary">
            {(disbursement.get_status.sync === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            )}
            &nbsp;Sync UTR
          </button>
        </SingleFileInput>
        <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.downloadBankDisbursementBatch(batchNo); }}>
          {(disbursement.get_status.download === 'pending' && loadingButton === 'bank' ) ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          )}
          &nbsp;Download Bank file
        </button>
        <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.downloadDisbursementBatch(batchNo); }}>
          {(disbursement.get_status.download === 'pending' && loadingButton === 'disbursement') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          )}
          &nbsp;Download
        </button>
        {/**Commented this button since we do not require this anymore */}
        {/* <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.markAsSettleDisbursementBatch(batchNo); }}>
          {(disbursement.get_status.settle === 'pending') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          )}
          &nbsp;Mark all settle
        </button> */}
      </div>
    );
  }

  toggleDetails = (batchNo) => {
    const { details } = this.state;
    if (details === batchNo) {
      this.setState({ details: null });
    } else {
      this.setState({ details: batchNo });
    }
  }

  disbursementHTML = () => {
    const { details } = this.state;
    const { disbursement } = this.props;
    return disbursement.disbursements.map(item => (
      <li className="table-list-item" key={item._id}>
        <div className="row">
          <div className="col-md-12 table-col">
            <div className="row">
              <div className="col-md-2 col-12 table-col tfirst-col dl-mob">
                <strong className="show-mobile">Disbursement Batch No</strong>
                {details ? (
                  <Icon path={mdiChevronDown} className="menu-icon" size={0.75} color="#979797" />
                ) : (
                  <Icon path={mdiChevronRight} className="menu-icon" size={0.75} color="#979797" />
                )}
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item._id}</span></a>
              </div>
              <div className="col-md-3 col-12 table-col dl-mob">
                <strong className="show-mobile">Disbursement range</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{`${item.from_date} - ${item.to_date}`}</span></a>
              </div>
              <div className="col-md-3 col-12 table-col dl-mob">
                <strong className="show-mobile">Disbursement Date</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item.created_at}</span></a>
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                <strong className="show-mobile">NGOs</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item.disbursements.length}</span></a>
              </div>
              <div className="col-md-2 col-12 table-col dl-mob">
                <strong className="show-mobile">Entity</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item.payment_entity}</span></a>
              </div>
              <div className="col-md-1 col-12 table-col dl-mob txt-right">
                <strong className="show-mobile">Amount</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{`${item.currency_code} ${item.payable_amount}`}</span></a>
              </div>
              <div className="col-12 table-col show-mobile">
                {(details === item._id) ? (
                  <a href="#details" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id); }}>Hide Nonprofit details</a>
                ) : (
                  <a href="#details" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id); }}>View Nonprofit details</a>
                )}
              </div>
              {(details === item._id) ? this.actionsHTML(item._id) : null}
              {(details === item._id) ? this.ngoHTML(item.disbursements) : null}
              <div className="show-mobile">
                {this.actionsHTML(item._id)}
              </div>
            </div>
          </div>
        </div>
      </li>
    ));
  }

  render() {
    const { children, disbursement } = this.props;
    return (
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <div className="table-tab-wrapper">
              {children}
            </div>
            <div className="table-list">
              <ul>
                <li className="table-head">
                  <div className="row">
                    <div className="col-md-12 table-col">
                      <div className="row">
                        <div className="col-md-2 table-col dl-mob">Disbursement Batch No</div>
                        <div className="col-md-3 table-col dl-mob">Disbursement range</div>
                        <div className="col-md-3 table-col dl-mob">Disbursement Date</div>
                        <div className="col-md-1 table-col dl-mob">NGOs</div>
                        <div className="col-md-2 table-col dl-mob">Entity</div>
                        <div className="col-md-1 table-col txt-right">Amount</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  {(!disbursement.get_status.disbursement || disbursement.get_status.disbursement === 'pending') ? (<Loading />) : null}
                  {(disbursement.get_status.disbursement === 'failed') ? (<Failed />) : null}
                </li>
                {this.disbursementHTML()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TabDisbursement;
