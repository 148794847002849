import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';

class NonprofitOtherDetailsRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
    };
  }

  switchView = (view) => {
    this.setState({ view: view });
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if(val === '' && key[1] !== 'video') {
      return this.na;
    }
    return val;
  }

  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }


  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if(to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if(to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if(to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if(to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }

  render() {
    const { children, tabs } = this.props;
    const { view } = this.state;
    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">Other details</h3>
                <div className="heading-bar-btn">
                  {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab => {
                    if (tab === view) {
                      return (
                        <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                      );
                    }
                    return (
                      <button type="button" className="btn btn-outline-secondary btn-left" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                    );
                  })}
                </div>
              </div>
              {this.shouldShowItemOnCompliance('giveassured') ? (
                <div>
                  <ApprovalsRejectionsButtons path={['nonprofit', 'others', 'leaders']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <h4 className="mr-b-20">Organisation Leaders</h4>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <h6> Name of CEO/Current leader</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'leaders', 0, 'name'])}</p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <h6> Profile of CEO/Current leader</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'leaders', 0, 'profile'])}</p>
                      </div>
                    </div>
                    {this.getInputData(['nonprofit', 'others', 'leaders']) !== this.na && this.getInputData(['nonprofit', 'others', 'leaders']).length > 0 ? (
                      this.getInputData(['nonprofit', 'others', 'leaders']) && this.getInputData(['nonprofit', 'others', 'leaders']).map((founder, i) => (
                        founder.designation !== 'ceo' ? (
                          <div className="row" key={i}>
                            <div className="col-lg-6 col-md-12">
                              <h6> Name of Founder(s)</h6>
                              <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'leaders', i, 'name'])}</p>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <h6> Profile of Founder(s)</h6>
                              <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'leaders', i, 'profile'])}</p>
                            </div>
                          </div>
                        ) : null
                      ))
                    ) : (
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <h6> Name of Founder(s)</h6>
                            <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'leaders', 1, 'name'])}</p>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <h6> Profile of Founder(s)</h6>
                            <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'leaders', 1, 'profile'])}</p>
                          </div>
                        </div>
                      )}
                  </ApprovalsRejectionsButtons>
                  <h4 className="mr-b-20 mr-t-20">Human resources</h4>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <ApprovalsRejectionsButtons path={['nonprofit', 'others', 'personnel_policy']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                        <div>
                          <h6> Do you have a Personnel Policy, which is known to all staff?</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'personnel_policy'])}</p>
                        </div>
                      </ApprovalsRejectionsButtons>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <ApprovalsRejectionsButtons path={['nonprofit', 'others', 'is_staff_related_board']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                        <div>
                          <h6> Are any of the staff members related to any Board members?</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'is_staff_related_board'])}</p>
                        </div>
                      </ApprovalsRejectionsButtons>
                    </div>
                  </div>
                  <ApprovalsRejectionsButtons path={['nonprofit', 'others', 'staff_related_board']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div>
                          <h6> Details of the staff member (s) in the table below:</h6>
                          <div className="row mr-t-5 tm-divider pd-b-20">
                            <div className="col-lg-3 col-md-12">Name</div>
                            <div className="col-lg-3 col-md-12">Designation</div>
                            <div className="col-lg-3 col-md-12">Annual Salary(in Rs.)</div>
                            <div className="col-lg-3 col-md-12">Relation with Board Member</div>
                          </div>
                          {(this.getInputData(['nonprofit', 'others', 'is_staff_related_board']) === 'Yes') ? (
                            this.getInputData(['nonprofit', 'others', 'staff_related_board']) !== this.na && this.getInputData(['nonprofit', 'others', 'staff_related_board']).length > 0 ? this.getInputData(['nonprofit', 'others', 'staff_related_board']).map((m, i) => (
                              <div className="row mr-t-5 tm-divider pd-b-20" key={i}>
                                <div className="col-lg-3 col-md-12 text-muted">{m.name}</div>
                                <div className="col-lg-3 col-md-12 text-muted">{m.designation}</div>
                                <div className="col-lg-3 col-md-12 text-muted">{m.annual_salary}</div>
                                <div className="col-lg-3 col-md-12 text-muted">{m.relation}</div>
                              </div>
                            )) : this.getInputData(['nonprofit', 'others', 'staff_related_board'])
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              ) : null}
              {this.shouldShowItemOnCompliance('fbassured') ? (
                <React.Fragment>
                  <h6> Details of the facebook admin:</h6>
                  <div className="row">
                    <div className="col-md-4">
                      <ApprovalsRejectionsButtons path={['nonprofit', 'others', 'facebook_admin', 'name']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                        <div>
                          <h6> Name</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'facebook_admin', 'name'])}</p>
                        </div>
                      </ApprovalsRejectionsButtons>
                    </div>
                    <div className="col-md-4">
                      <ApprovalsRejectionsButtons path={['nonprofit', 'others', 'facebook_admin', 'designation']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                        <div>
                          <h6> Designation</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'facebook_admin', 'designation'])}</p>
                        </div>
                      </ApprovalsRejectionsButtons>
                    </div>
                    <div className="col-md-4">
                      <ApprovalsRejectionsButtons path={['nonprofit', 'others', 'facebook_admin', 'date_of_birth']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                        <div>
                          <h6> Date of birth</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'others', 'facebook_admin', 'date_of_birth'])}</p>
                        </div>
                      </ApprovalsRejectionsButtons>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NonprofitOtherDetailsRead;
