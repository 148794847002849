import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { format } from 'date-fns';
import classnames from 'classnames';

import * as filterByActions from '../../reducers/Other/filterByReducer';
import * as notificationActions from '../../reducers/notificationReducer';

import SelectInput from '../SelectInput';
import DateInput from '../DateInput';
import Loading from '../../appComponents/Loading';
import PhoneInput from 'react-phone-number-input';
import SearchWithSuggestion from '../SearchWithSuggestion/SearchWithSuggestion';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import './FilterByInput.scss'
import DateInput10BdBe from '../DateInput10BdBe';


// const options = [{
//   label: 'Search',
//   type: 'text',
// type: [{
//   type: 'default',
//   label: 'Name',
//   query: 'q',
// }, {
//   type: 'email',
//   label: 'Email id',
//   query: 'filter_by_user_email',
// }, {
//   type: 'phone',
//   label: 'Mobile number',
//   query: 'filter_by_user_phone',
// }, {
//   type: 'order',
//   label: 'Order Id',
//   query: 'filter_by_order_id',
// }, {
//   type: 'disbursement',
//   label: 'Disbursement Id',
//   query: 'filter_by_disbursement_number',
// }],
//   query: 'q',
//   placeholder: 'Search for OrderId, Mobile, Name, Email',
// }, {
//   label: 'Created at after',
//   type: 'date',
//   query: 'created_at',
//   op: '>='
// }, {
//   label: 'Created at before',
//   type: 'date',
//   query: 'created_at',
//   op: '<='
// }, {
//   label: 'Status',
//   type: 'list',
//   query: 'filter_by_status',
//   list: [{label: 'Pending', value: 'PENDING'}, {label: 'User Confirmed', value: 'USERCONFIRMED'}, {label: 'Confirmed', value: 'CONFIRMED'}, {label: 'Disbursed', value: 'DISBURSED'}, {label: 'Settled', value: 'SETTLED'}, {label: 'Failed', value: 'FAILED'}, {label: 'Refunded', value: 'REFUNDED'}],
// }, {
//   label: 'Nonprofit',
//   type: 'service',
//   query: 'filter_by_nonprofit',
//   query: 'nonprofit._id',
//   placeholder: 'Type nonprofit name',
//   service: 'nonprofits',
// }, {
//   label: 'Project',
//   type: 'service',
//   query: 'filter_by_project',
//   placeholder: 'Type project name',
//   service: 'projects'
//   ristrictions: { "nonprofit": props.match.params.nonprofit },
// }, {
//   label: 'Campaign',
//   type: 'service',
//   query: 'filter_by_campaign',
//   placeholder: 'Type campaign title',
//   service: 'campaigns'
//   ristrictions: { "nonprofit": props.match.params.nonprofit },
// }, {
//   label: 'Settlement Id',
//   type: 'text',
//   query: 'filter_by_payout_id',
//   placeholder: 'Enter Payout ID or press Enter',
//   allowed: '',
// }, {
//   label: 'Entity::Gateway',
//   type: 'custom',
// }];
// data = [{
//   title: '',
//   data: {q: ''} || {q:[{value: '', op: ''}]}
// }]

class FilterByInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_option: null,
      q: '',
      text: '',
    }
  }

  qTimer = null;
  timer = null;

  componentWillUnmount() {
    const { Filter } = this.props;
    Filter.reset();
  }

  handelQ = (e) => {
    e.preventDefault();
    const { value } = this.props;
    let q = e.target.value;
    this.setState({ q }, () => {
      if ((q.length >= 3 || q.length == 0) && !Array.isArray(value.find(i => (i.label === 'Search')).type)) {
        if (window) {
          if (this.qTimer) {
            window.clearTimeout(this.qTimer);
          }
          this.qTimer = window.setTimeout(() => {
            this.updateQ();
          }, 1200);
        } else {
          this.updateQ();
        }
      }
    });
  }

  updateQ = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { value, onChange } = this.props;
    const { q } = this.state;
    const option = value.find(i => i.label === 'Search');
    const newOption = { ...option };
    let newOptions;
    if (q === '') {
      delete newOption.data;
      newOptions = value.map(item => (item.label === option.label) ? newOption : item);
    } else {
      newOption.data = [{ data: { [value.find(i => i.label === 'Search').query]: q }, title: `Search: ${q}` }];
      newOptions = value.map(item => (item.label === option.label) ? newOption : item);
    }
    if (onChange) {
      onChange(newOptions);
    }
  }

  changeDate = (e) => {
    const { selected_option } = this.state;
    const date = new Date(e);
    selected_option.label.includes('after') ? date.setHours(0, 0, 0) : date.setHours(23, 59, 59);
    const newOption = { ...selected_option };
    const data = selected_option.op ? { [selected_option.query + selected_option.op]: date.toISOString() } : { [selected_option.query]: date.toISOString() }
    newOption.data = [{ title: `${newOption.label}${newOption.op ? ' ' : ' : '}${format(new Date(e), 'dd/MM/yyyy')}`, data }];
    this.setState({ selected_option: null }, this.updateFilterValues(newOption));
  }

  changeList = (e) => {
    const { selected_option } = this.state;
    const newOption = { ...selected_option };
    const data = { [selected_option.query]: e.value }
    if (newOption.hasOwnProperty('data')) {
      const dataItem = { title: `${newOption.label} : ${e.label}`, data };
      newOption.data.push(dataItem);
    } else {
      newOption.data = [{ title: `${newOption.label} : ${e.label}`, data }];
    }
    this.setState({ selected_option: null }, this.updateFilterValues(newOption));
  }

  handelText = (e) => {
    const { Filter } = this.props;
    const { selected_option } = this.state;
    let text;
    if (e && e.target && (e.target.value || e.target.value === '')) {
      text = e.target.value;
    } else {
      text = e;
    }
    this.setState({ text });
    if (selected_option.service) {
      if (text.length === 0) {
        Filter.resetService();
      } else if (text.length >= 3) {
        const ristrictions = selected_option.ristrictions ? selected_option.ristrictions : {};
        if (window) {
          if (this.timer) {
            window.clearTimeout(this.timer);
          }
          this.timer = window.setTimeout(() => {
            Filter.getService(selected_option.service, { q: text, ...ristrictions });
          }, 1200);
        } else {
          Filter.getService(selected_option.service, { q: text, ...ristrictions });
        }
      }
    }
  }

  changeText = (e) => {
    e.preventDefault();
    const { Notification } = this.props;
    const { selected_option, text } = this.state;
    const newOption = { ...selected_option };
    if ((text === '' && selected_option.allowed === '') || text !== '') {
      const title = (text === '') ? 'Not available' : text;
      const value = (text === '') ? '' : text;
      const data = { [selected_option.query]: value }
      newOption.data = [{ title: `${newOption.label} : ${title}`, data }];
      this.setState({ selected_option: null, text: '' }, this.updateFilterValues(newOption));
    } else {
      Notification.addNotification(`Blank ${selected_option.label} not allowed`);
    }
  }

  changeService = (e) => {
    const { Filter } = this.props;
    const { selected_option } = this.state;
    const newOption = { ...selected_option };
    const data = { [selected_option.query]: e._id }
    if (newOption.hasOwnProperty('data')) {
      if (selected_option.service === 'nonprofits' || selected_option.service === 'projects') {
        const dataItem = { title: `${newOption.label}: ${e.name}`, data };
        newOption.data.push(dataItem);
      }
      if (selected_option.service === 'campaigns') {
        const dataItem = { title: `${newOption.label} : ${e.title}`, data };
        newOption.data.push(dataItem);
      }
    } else {
      if (selected_option.service === 'nonprofits' || selected_option.service === 'projects') {
        newOption.data = [{ title: `${newOption.label}: ${e.name}`, data }];
      }
      if (selected_option.service === 'campaigns') {
        newOption.data = [{ title: `${newOption.label} : ${e.title}`, data }];
      }
    }
    this.setState({ selected_option: null, text: '' }, this.updateFilterValues(newOption));
    Filter.resetService();
  }

  changeEntityGatewaySelect = (e) => {
    const { selected_option } = this.state;
    const newOption = { ...selected_option };
    const query = ['filter_by_payment_entity', 'filter_by_gateway'];
    const value = e.value.split("::");
    const data = { [query[0]]: value[0], [query[1]]: value[1] }
    newOption.data = [{ title: `${newOption.label} : ${e.value}`, data }];
    this.setState({ selected_option: null }, this.updateFilterValues(newOption));
  }

  handleSingleSelector = (e) => {
    const { selected_option } = this.state;
    const newOption = { ...selected_option };
    const data = { [selected_option.query]: selected_option.selectorValue }
    newOption.data = [{ title: newOption.label, data }];
    this.setState({ selected_option: null }, this.updateFilterValues(newOption));
  }

  filterTypeValueHTML = (filters) => {
    const { filter, Filter,is10BdBe } = this.props;
    const { selected_option, text } = this.state;
    switch (selected_option?.type) {
      case 'date':
        return (
          <div className="form-group">
            {is10BdBe?
            <DateInput10BdBe onChange={this.changeDate}/>
            :
            <DateInput onChange={this.changeDate} position="bottomLeft" />
        }</div>
        );
      case 'list':
        // passing the filters so that it's readily available since eventlist is null the first time this case is true for events hence selected_option.list is also null and state update only happens
        // once you have selected other filters.
        // TODO find a better way of updating the state for dynamic list //
        const selectedoOptionVariant = selected_option.label === "Event" ? filters.filter(option => option.label === "Event")[0].list : selected_option.list;
        return (
          <div className="form-group">
            <SelectInput options={selectedoOptionVariant} onChange={this.changeList} />
          </div>
        );
      case 'text':
        return (
          <form onSubmit={this.changeText}>
            <div className="pbar-wrapper">
              <input type="text" className="form-control" placeholder={selected_option?.placeholder} value={text} onChange={this.handelText} />
              <div className="btn-wrapper">
                <button type="submit" className="btn btn-sm btn-primary">Go</button>
              </div>
            </div>
          </form>
        );
      case 'phone':
        return (
          <form onSubmit={this.changeText}>
            <div className="pbar-wrapper">
              <PhoneInput className="form-control" placeholder={selected_option?.placeholder} value={text} onChange={this.handelText} />
              <div className="btn-wrapper">
                <button type="submit" className="btn btn-sm btn-primary">Go</button>
              </div>
            </div>
          </form>
        );
      case 'service':
        return (
          <div className="form-group d-flex search-field">
            <div className={classnames('input-group')}>
              <input type="text" className="form-control" placeholder={selected_option?.placeholder} value={text} onChange={this.handelText} />
              <div style={{ position: "absolute", marginTop: "32px", width: "100%", zIndex: "1", background: '#fff' }}>
                {(text.length === 24 && text.indexOf(' ') < 0) ? (
                  <div style={{ padding: "5px" }}>
                    <a href="#link" onClick={(e) => { e.preventDefault(); this.changeService({ _id: text, name: text, title: text }) }}>
                      {selected_option.label} ID: {text}
                    </a>
                  </div>
                ) : null}
                {filter.services.map(item => (
                  <div style={{ padding: "5px" }} key={item._id}>
                    <a href="#link" onClick={(e) => { e.preventDefault(); this.changeService(item) }}>
                      {item.name || item.title}
                    </a>
                  </div>
                ))}
                {filter.services.length === 0 && filter.get_status.service === 'success' ? (
                  <div style={{ padding: "5px" }}>
                    <i>0 search results</i>
                  </div>
                ) : null}
                {(filter.get_status.service === 'pending') ? (
                  <div style={{ padding: "5px" }}>
                    <Loading />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      case 'single_selector':
        this.handleSingleSelector();
      case 'custom':
        if (selected_option.label === 'Entity::Gateway') {
          if (!filter.get_status.pg) {
            Filter.getPG();
          }
          const PGE = [];
          Object.keys(filter.pg).forEach(i => (filter.pg[i].gateway.forEach(j => { PGE.push({ label: `${i}::${j}`, value: `${i}::${j}` }) })));
          return (
            <div className="form-group">
              {(filter.get_status.pg === 'pending') ? (
                <div style={{ padding: "5px" }}>
                  <Loading />
                </div>
              ) : (
                <SelectInput options={PGE} onChange={this.changeEntityGatewaySelect} />
              )}
            </div>
          );
        }
        return null;
      default:
        return (
          <div>
            - Please select an option to filter
          </div>
        );
    }
  }

  changeSuggestions = (e) => {
    const { value, onChange } = this.props;
    const newOptions = value.map(item => (item.label === 'Search') ? e : item);
    if (onChange) {
      onChange(newOptions);
    }
  }

  searchHTML = () => {
    const { value,isOnlySearch } = this.props;
    const { q } = this.state;
    const search = value.find(i => (i.label === 'Search'));
    if (Array.isArray(search.type)) {
      return (
        <SearchWithSuggestion value={search} onChange={this.changeSuggestions} isOnlySearch={isOnlySearch}/>
      );
    } else if (search.type === 'text') {
      return (
        <div className="form-group d-flex search-field">
          <div className="input-group">
            <input type="text" className="form-control" placeholder={value.find(i => (i.label === 'Search')).placeholder} value={q} onChange={this.handelQ} />
          </div>
        </div>
      );
    }
    return null;
  }

  optionTypeChange = (e) => {
    delete e.value;
    this.setState({ selected_option: e });
  }

  removeAppliedFilter = (option) => {
    const { value, onChange } = this.props;
    const newOptions = value.map(item => (item.label === option.label) ? option : item);
    if (onChange) {
      onChange(newOptions);
    }
  }

  updateFilterValues = (option) => {
    const { value, onChange } = this.props;
    const newOptions = value.map(item => (item.label === option.label) ? option : item);
    if (onChange) {
      onChange(newOptions);
    }
  }

  removeSearchFilter = (option) => {
    const { value, onChange } = this.props;
    const newOption = { ...option };
    const newOptions = value.map(item => (item.label === option.label) ? newOption : item);
    if (onChange) {
      onChange(newOptions);
    }
  }

  filtersHTML = () => {
    const { value,nonProfitCount } = this.props||{};
    const filters = [];
    value.filter(option => (option.data && !(option.label === 'Search' && !Array.isArray(option.type)))).forEach(option => {
      option.data.forEach(f => {
        const newOption = { ...option };
        delete newOption.data;
        const newData = option.data.filter(i => (i !== f));
        if (newData.length > 0) {
          newOption.data = newData;
        }
        filters.push(
          <button type="button" className="btn btn-outline-primary btn-rounded btn-fw mr-3 mt-3" key={f.title} onClick={e => { e.preventDefault(); this.removeAppliedFilter(newOption); }}>{`${f.title}${nonProfitCount ? `(${nonProfitCount})`:''}`}</button>
        );
      });
    });
    return filters;
  }

  render() {
    const { value, q, handelSearch, handelQ, forApproval, clearSearch, isOnlySearch } = this.props;
    const { selected_option } = this.state;
              
    return (
      isOnlySearch ?
        <div className="row">
          <div className="col-md-6">
            <div className="row justify-content-start">
              <div className="col-md-4">
                <div className="form-group">
                  <SelectInput
                    options={value.filter(option => !((option.data && option?.filterType !== 'multiple') || option.label === 'Search')).map(i => ({ ...i, value: i.label }))}
                    onChange={this.optionTypeChange}
                    selectedOption={selected_option}
                  />
                </div>
              </div>
              <div className="col-md-6">
                {this.filterTypeValueHTML(value)}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row justify-content-end">
              <div className="col-md-6">
                {this.searchHTML()}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            {this.filtersHTML()}
          </div>
        </div>
        :
        <div className="row">
          <div className="col-md-6">
            <div className="row justify-content-start">
              <div className="col-md-4">
                <div className="form-group">
                  <SelectInput
                    options={value.filter(option => !((option.data && option?.filterType !== 'multiple') || option.label === 'Search')).map(i => ({ ...i, value: i.label }))}
                    onChange={this.optionTypeChange}
                    selectedOption={selected_option}
                  />
                </div>
              </div>
              <div className="col-md-6">
                {this.filterTypeValueHTML(value)}
              </div>
            </div>
          </div>
          {
            forApproval ?
              <div className="col-md-6 col-12">
                <form onSubmit={handelSearch}>
                  <div className="form-group d-flex search-field search-field-mobile">
                    <div className={classnames('input-group', { active: (q !== '') })}>
                      <input type="text" className="form-control" placeholder="Search Here" value={q} onChange={handelQ} />
                      <div className="search-close">
                        <button type="button" onClick={clearSearch}><Icon path={mdiClose} className="menu-arrow" size={0.75} color="#979797" /></button>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary ml-3">Search</button>
                  </div>
                </form>
              </div> :
              <div className="col-md-6">
                <div className="row justify-content-end">
                  <div className="col-md-6">
                    {this.searchHTML()}
                  </div>
                </div>
              </div>
          }
          <div className="col-md-12">
            {this.filtersHTML()}
          </div>
        </div>
    );
  }
}


const mapStateToProps = state => ({
  filter: state.filterBy,
});

const mapActionToProps = dispatch => ({
  Filter: bindActionCreators(filterByActions, dispatch),
  Notification: bindActionCreators(notificationActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(FilterByInput);
