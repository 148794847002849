import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import ProjectService from '../../services/project-service';

export const GET_PROJECT_APPROVAL = 'GET_PROJECT_APPROVAL';
export const GET_PROJECT_APPROVAL_SUCCESS = 'GET_PROJECT_APPROVAL_SUCCESS';
export const GET_PROJECT_APPROVAL_FAIL = 'GET_PROJECT_APPROVAL_FAIL';

export const SET_PROJECT_APPROVAL = 'SET_PROJECT_APPROVAL';
export const SET_PROJECT_APPROVAL_SUCCESS = 'SET_PROJECT_APPROVAL_SUCCESS';
export const SET_PROJECT_APPROVAL_FAIL = 'SET_PROJECT_APPROVAL_FAIL';

export const APPROVAL_RESET = 'APPROVAL_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  project: {},
  get_status: {},
  set_status: {},
  errors: {},
};

export const projectApprovalsItemReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PROJECT_APPROVAL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'pending',
        },
      };
    case GET_PROJECT_APPROVAL_SUCCESS:
      return {
        ...state,
        project: action.project,
        get_status: {
          ...state.get_status,
          project: 'success',
        },
      };
    case GET_PROJECT_APPROVAL_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'failed',
        },
      };
    case SET_PROJECT_APPROVAL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'pending',
        },
        errors: {
          ...state.errors,
          project: {},
        },
      };

    case SET_PROJECT_APPROVAL_SUCCESS:
      return {
        ...state,
        project: action.project,
        set_status: {
          ...state.set_status,
          project: 'success',
        },
      };
    case SET_PROJECT_APPROVAL_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'failed',
        },
        errors: {
          ...state.errors,
          project: action.errors,
        },
      };
    case APPROVAL_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getProject(id) {
  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_APPROVAL,
    });
    const request = {
      path: [id],
    };
    return ProjectService.get(request)
      .then((response) => {
        dispatch({
          type: GET_PROJECT_APPROVAL_SUCCESS,
          project: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_PROJECT_APPROVAL_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
        return error;
      });
  };
}

export function setProject(data) {
  return (dispatch) => {
    dispatch({
      type: SET_PROJECT_APPROVAL,
    });
    return ProjectService.set({ data, method: 'put', path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_PROJECT_APPROVAL_SUCCESS,
          project: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_PROJECT_APPROVAL_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Project form'));
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_PROJECT_APPROVAL_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
        } else {
          dispatch({
            type: SET_PROJECT_APPROVAL_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function setProjectAP(id, data) {
  return (dispatch) => {
    dispatch({
      type: SET_PROJECT_APPROVAL,
    });
    const request = {};
    request.method = 'put';
    request.path = [id, 'apply-edits'];
    request.data = data;
    return ProjectService.set(request)
      .then((response) => {
        dispatch({
          type: SET_PROJECT_APPROVAL_SUCCESS,
          project: response.data,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response.data;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_PROJECT_APPROVAL_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));
          throw error.value.errors;
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_PROJECT_APPROVAL_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
          throw error.value.error;
        } else {
          dispatch({
            type: SET_PROJECT_APPROVAL_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: APPROVAL_RESET,
    });
  };
}
