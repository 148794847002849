import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OverlayTrigger, Form, Popover, ListGroup, Button, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import classnames from "classnames";
import { format } from "date-fns";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline, mdiChevronRight, mdiLoading, mdiFilter } from "@mdi/js";

import * as adminSubscriptionListAction from "../../../reducers/Admin/adminSubscriptionListReducer";
import * as eventAction from "../../../reducers/eventReducer";
import "./AdminSubscriptionList.scss";

import Loading from "../../../appComponents/Loading";
import FilterByInput from "../../../components/FilterByInput";
import Helpers from "../../../utils/helpers";
import ContactModalPopup from "../AdminDonationsList/ContactModal";


class AdminSubscriptionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDetails: "",
      eventList: [],
      query: {},
      currentTab: 'export',
      filters: [
        {
          label: 'Search',
          type: [
            {
              type: 'default',
              label: 'Name',
              query: 'filter_by_user_name',
            }, {
              type: 'email',
              label: 'Email id',
              query: 'filter_by_user_email',
            }, {
              type: 'phone',
              label: 'Mobile number',
              query: 'filter_by_user_phone',
            }, , {
              type: 'subscription',
              label: 'Subscription Id',
              query: 'filter_by_subscription_id',
            }],
          placeholder: 'Search for Subscription id',
        }, {
          label: 'Created after',
          type: 'date',
          query: 'created_after',
        }, {
          label: 'Created before',
          type: 'date',
          query: 'created_before',
        }, {
          label: 'Status of subscription',
          type: 'list',
          query: 'status',
          list: [{ label: 'Created', value: 'created' }, { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' }, { label: 'Pending', value: 'pending' },
          { label: 'Halted', value: 'halted' }],
          filterType: 'multiple'
        }, {
          label: 'Updated after',
          type: 'date',
          query: 'updated_before',
        }, {
          label: 'Updated before',
          type: 'date',
          query: 'updated_after',
        },
        {
          label: 'Nonprofit',
          type: 'service',
          query: 'nonprofit',
          placeholder: 'Type nonprofit title',
          service: 'nonprofits'
        },
        {
          label: 'Fundraiser',
          type: 'service',
          query: 'fundraisers',
          placeholder: 'Type fundraiser title',
          service: 'campaigns'
        },
        {
          label: 'Payment gateway',
          type: 'list',
          query: 'gateway',
          list: [{ label: 'Razorpay', value: 'razorpay' }, { label: 'Stripe', value: 'stripe' }],
          filterType: 'multiple'
        }
      ],
      exportFilters: [],
      reportLinks: [],
      loader: false,
      minOrderIdLimit:3
    };
  }

  componentDidMount() {
    const { Subscription, subscription, event, Event } = this.props;
    if (!subscription.get_status.subscriptions) {
      Subscription.getSubscriptions();
    }
    if (!event.get_status.event) {
      Event.get().then((events) => {
        events &&
          events?.length > 0 &&
          this.setState({
            eventList: events.map((event) => {
              return [
                {
                  label: event.name,
                  value: event.event_id,
                },
              ];
            }),
          });
      });
    }
  }

  componentDidUpdate() {
    let updatedFilters = this.state.filters;
    if (this.state.eventList?.length > 0) {
      for (let e = 0; e < updatedFilters?.length; e++) {
        if (updatedFilters[e].label === "Event") {
          updatedFilters[e].list = this.state.eventList.map((list) => {
            return list[0];
          });
        }
      }
      if (this.state.filters.filter((filter) => filter.label === "Event")[0]?.list?.length === 0) {
        this.setState({
          filters: updatedFilters,
        });
      }
    }
  }
  componentWillUnmount() {
    const { Subscription } = this.props;
    Subscription.reset();
  }

  loadMore = () => {
    const { Subscription } = this.props;
    const { query } = this.state;
    Subscription.getSubscriptions(query);
  };

  showDetailsClick = (id) => {
    const { viewDetails } = this.state;
    if (id === viewDetails) {
      this.setState({ viewDetails: "" });
    } else {
      this.setState({ viewDetails: id });
    }
  };

  refreshSubscriptionList = () => {
    const { Subscription } = this.props;
    const { query } = this.state;
    Subscription.reset();
    Subscription.getSubscriptions(query);
  };

  reSyncSubscription = (order_id) => {
    const { getSubscriptions } = this.props;
    getSubscriptions.getReSyncSubscription(order_id);
  };

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshSubscriptionList();
    });
  };

  fitersForExport = () => {
      this.setState({ exportFilters: ['additional_fields'] });
  };

  addToFilter = (type, value) => {
    const { filters } = this.state;
    const newOption = { ...filters.find((i) => i.label === type) };
    newOption.data = value;
    const newOptions = filters.map((item) => (item.label === type ? newOption : item));
    this.setState(
      { filters: newOptions, query: Helpers.queryBuilderForFilterInput(newOptions) },
      () => {
        this.refreshSubscriptionList();
      }
    );
  };

  recursiveObjSearch = (obj, returnData) => {
    const rd = returnData;
    if (typeof obj === "object" && obj !== null) {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (typeof value !== "object") {
          rd[key] = value;
        } else if (typeof value === "object") {
          this.recursiveObjSearch(value, rd);
        }
      });
    }
    return rd;
  };

  generateReportLink = (e) => {
    e.preventDefault();
    this.setState({ loader: true })
    const { Subscription } = this.props;
    const { query, exportFilters } = this.state;
    Subscription.generateReportLink({ ...query }, exportFilters)?.then((data) => {
      if (data !== undefined) {
        Subscription.getReportLinks().then((reportsData) => {
          this.setState({ reportLinks: reportsData, currentTab: 'downloads', loader: false });
        });
      }
      else {
        this.setState({ currentTab: 'export', loader: false });
      }
    });
  };

  getReportLinks = (e, isExport) => {
    e.preventDefault();
    const { Subscription } = this.props;
    this.setState({
      loader: true
    })
    if (isExport) {
      this.setState({
        currentTab: isExport
      })
    }
    Subscription.getReportLinks().then((data) => {
      setTimeout(() => {
        this.setState({
          loader: false
        })
      }, 2000);
      this.setState({ reportLinks: data });
    });
  };

  addToFilterHTML = (type, value) => (
    <span className={`filter-wrapper ${value && value[0] && value[0].title && value[0].title.includes('cancelled') && 'cancelled-wrap'}`}>
      <button
        type="button"
        className="btn btn-rounded btn-inverse-outline-primary btn-filters"
        onClick={(e) => {
          e.preventDefault();
          this.addToFilter(type, value);
        }}
      >
        <Icon path={mdiFilter} size={0.5} color="#2196f3" />
        <span>Apply as filter</span>
      </button>
    </span>
  );

  onTabChange = (e, tab) => {
    this.setState({
      currentTab: tab
    })
    if (tab === 'downloads') {
      this.getReportLinks(e, 'downloads')
    }
  };

  popover = () => {
    const { donation } = this.props;
    const { reportLinks } = this.state;
    const fieldNames = {
      basic:
        "(Date, Time, Donor Name, Email, Phone Number, Project/Fundraiser, Subscription ID, Amount, Next Charge date, Status)",
      nonprofit: "(ID, Name, URL)",
      receipt: "(URL)",
      disbursement:
        "(Converted Currency, Converted Amount, Retention Amount, Payable Amount, Disbursement Number, Disbursement Date,  Settlement Date)",
      finance: "(Donor Name, Donor Mobile Number, Donor Email, Donor PAN, Donor Address Line 1, Donor Address Line 2, Donor Zipcode / Pincode, Payment Entity, Gateway, Transaction Reference, Payout Id, Payment Processor fee, Donor GST Number, Donor GST Address Line 1, Donor GST Address Line 2, Donor GST City, Donor GST Region, State Code, Donor GST Country, Donor GST Area Code, IP Address, Latitude, Longitude)",
    };
    return (
      <Popover id="popover-basic-1">
        <Popover.Content>
          <Row>
            <Col md={12} xs={12} className="export-download">
              <ul className="nav nav-tabs tab-basic" role="tablist">
                <li className="nav-item">
                  <a className={`${`nav-link ${this.state.currentTab === 'export' ? `active` : ''} show`}`} id="export-tab" data-toggle="tab" href="#export" onClick={(e) => this.onTabChange(e, 'export')} >Export</a>
                </li>
                <li className="nav-item">
                  <a className={`${`nav-link ${this.state.currentTab === 'downloads' ? `active` : ''} show`}`} id="download-tab" data-toggle="tab" href="#download" onClick={(e) => this.onTabChange(e, 'downloads')} >Downloads</a>
                </li>
              </ul>
              <div className="tab-content tab-content-basic">
                {
                  this.state.currentTab === 'export' ?
                    <div className="tab-pane fade show active">
                      <Col md={12} xs={12} className="export-filters">
                        <Popover.Title as="h3">Select Fields</Popover.Title>
                        <Form.Check className="popover-checkbox" type="checkbox" id="basic" label="Basic Details" disabled checked />
                        <div className="popover-checkbox-div" htmlFor="basic">{fieldNames.basic}</div>

                        <Form.Check className="popover-checkbox" type="checkbox" label="Additional Fields" id="additional" onClick={() => this.fitersForExport()} style={{ marginTop: '10px' }} />
                        <div className="popover-checkbox-div" htmlFor="additional" ></div>
                        <div className="text-center">
                          <Button className="btn btn-sm" onClick={this.generateReportLink}>{donation && donation?.get_status.generate_report === '' ? 'Export to CSV' : donation?.get_status.generate_report === 'pending' ? 'Generating...' : 'Export to CSV'}</Button>
                        </div>
                      </Col>
                    </div> :
                    <div className="tab-pane fade show active" >
                      <Col md={12} xs={12}>
                        <Popover.Title as="h3">
                          <span>Queued for download</span>
                          <button type="button" className="btn btn-inverse-link" onClick={this.getReportLinks}>
                            <div className="export">
                              <div className={`${this.state.loader ? `export-loader-spin` : `export-loader`}`}></div>
                            </div>
                          </button>
                        </Popover.Title>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Requested</th>
                              <th>Tags</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(!reportLinks || reportLinks?.length === 0) &&
                              <i className="align-center">No items available</i>}
                              
                            {reportLinks && reportLinks.map((link, index) => {
                              let filterAppl = link.queryParameter.filter;
                              return (
                                <tr key={index}>
                                  <td style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>
                                    <div className="download-tag-wrapper">
                                      <span className="download-tag">{format(new Date(link.created_at), "MMM do, yyyy HH:mm aa")}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="download-tag-wrapper">
                                      {['a'].map(item => (
                                        <>
                                          {
                                            filterAppl &&
                                            <>
                                              {filterAppl.createdAfter ? <span className="download-tag">Created before: {format(new Date(filterAppl.createdAfter), "MMM do, yyyy")}</span> : ''}
                                              {filterAppl.createdBefore ? <span className="download-tag">Created after: {format(new Date(filterAppl.createdBefore), "MMM do, yyyy")}</span> : ''}
                                              {filterAppl.gateway?.length ? <span className="download-tag">Gateway: {filterAppl.gateway&&filterAppl.gateway.toString()}</span> : null}
                                              {filterAppl.nonprofits?.length ? <span className="download-tag">Nonprofit: {filterAppl.nonprofits&&filterAppl.nonprofits.toString()}</span> : null}
                                              {filterAppl.campaigns?.length ? <span className="download-tag">Fundraiser: {filterAppl.campaigns}</span> : null}
                                              {filterAppl.status?.length ? <span className="download-tag">Status: {filterAppl.status&&filterAppl.status.toString()}</span> : null}
                                            </>
                                          }
                                        </>
                                      ))}
                                    </div>
                                  </td>
                                  <td>
                                    <a
                                      className={`btn btn-xs ${link.status === 'completed' ? 'btn-success' : 'disabled btn-secondary'}`}
                                      href={link.dataLink}
                                      download={`GiveFundraisers-live-${format(new Date(), 'HH:mm:ss')}`}
                                    >{link.status === 'completed' ? 'Download' : link.status}</a></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        {/* <ListGroup className="text-center">
                          {(!reportLinks || reportLinks.length === 0) &&
                            <i className="align-center">No items available</i>}
                          {reportLinks && reportLinks.map((link, index) => {
                            return (
                              <ListGroup.Item key={index}></ListGroup.Item>
                            )
                          })}
                        </ListGroup> */}
                      </Col>
                    </div>}
              </div>
            </Col>
          </Row>
          <Row></Row>
        </Popover.Content>
      </Popover>
    );
  };

  subscriptionsHTML = () => {
    const { subscription } = this.props;
    const { viewDetails,minOrderIdLimit } = this.state;
    if (
      !subscription.get_status.subscription ||
      (subscription.get_status.subscription === "pending" && subscription.subscriptions.length === 0)
    ) {
      return <Loading />;
    }
    if (subscription.subscriptions.length === 0) {
      return <i className="align-center">No items available</i>;
    }
    return subscription.subscriptions.map((item) => (
      <li className="table-list-item" key={item.order_id}>
        <div className="row">
          <div className="col-md-12 table-col">
            <div className="row">
              <div className="col-md-1 col-12 table-col dl-mob">
                <small style={{ display: 'block' }}>{format(new Date(item?.created_at), 'do MMM yyyy')}</small>
                <small style={{ display: 'block' }}>{format(new Date(item?.created_at), 'HH:mm')}</small>
              </div>
              {item?.donations && item?.donations.length > 0 && item?.donations[0]?.hasOwnProperty('user') ? (
                <div className="col-md-2 col-12 table-col dl-mob user-info-livedis">
                  <img alt="image" className="img-circle width-30" src={item?.donations[0]?.user.profile_pic} />
                  <div className="name-email">
                    <span>{item?.donations[0]?.user.name}</span>
                    <small>
                      {item?.donations[0]?.user.email}
                      {item?.donations[0]?.user.is_email_verified ? (
                        <Icon path={mdiCheckCircleOutline} size={0.75} color="#777777" />
                      ) : null}
                    </small>
                  </div>
                </div>
              ) : (
                <div className="col-md-2 col-12 table-col dl-mob user-info-livedis">
                  User Not Available
                </div>
              )}
              <div className="col-md-3 col-12 table-col dl-mob">
                {item?.campaign ? (
                  <div className="row">
                    {item?.campaign ? (
                      <div className="col-md-12">
                        <strong>(C) </strong> <a target="_blank" href={item?.campaign?.permalink}>{item?.campaign?.title}</a>
                        {this.addToFilterHTML('Fundraiser', [{ title: `Fundraiser: ${item?.campaign?.title}`, data: { campaign: item?.campaign_id } }])}
                      </div>) : null}
                  </div>
                ) : null}
              </div>
              <div className="col-md-2 col-12 table-col dl-mob sub_id_wrap">
                {item?.subscription_id}
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                <div>
                  {item.currency}&nbsp;{item.amount}
                </div>
                <div>
                  <small>
                    {item.retention_percent !== undefined
                      ? `Retention %: ${item.retention_percent}`
                      : null}
                  </small>
                </div>
                <div>
                  <small>{item.tip_percent !== 0 ? `Tip %: ${(Math.round(item.tip_percent * 100) / 100).toFixed(2)
                    }` : null}</small>
                </div>
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                {item?.next_payment_date &&
                  <>
                    <small style={{ display: 'block' }}>{format(new Date(item.next_payment_date), 'do MMM yyyy')}</small>
                    <small style={{ display: 'block' }}>{format(new Date(item.next_payment_date), 'HH:mm')}</small>
                  </>}
              </div>
              <div className="col-md-1 col-12 table-col dl-mob user-info-livedis">
                <div
                  className={classnames(
                    "badge badge-pill ellipsis",
                    { "badge-warning": item.status === "pending" },
                    { "badge-dark": item.status === "halted" },
                    { "badge-success": item.status === "active" },
                    { "badge-info": item.status === "created" },
                    { "badge-danger": item.status === "cancelled" },
                    { "badge-primary": item.status === "active" }
                  )}
                >
                  {item.status}
                </div>
                {this.addToFilterHTML("Status of subscription", [
                  { title: `Status: ${item.status}`, data: { status: item.status } },
                ])}
              </div>
              <div className="col-md-1 col-12 table-col dl-mob-right">
                {viewDetails === item.parent_order_id ? (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showDetailsClick(item.parent_order_id);
                    }}
                  >
                    Hide Details
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showDetailsClick(item.parent_order_id);
                    }}
                  >
                    View Details
                  </button>
                )}
              </div>
            </div>
          </div>
          {viewDetails === item.parent_order_id ? (
            <div className="col-md-12 disbursement-view-details">
              <div className="ibox float-e-margins">
                <div className="ibox-content ibox-heading">
                  <div className="row">
                    <div className="col-md-12">
                      <h6 className="left">Other details</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-5 bold">Order IDs :</div>
                        <div className="col-md-7 dl-mob sub_id_wrap orderid-wrap">
                          {item?.donations && item?.donations.slice(0, minOrderIdLimit).map((payment, i) => (<>
                            <span>{`${payment?.order_id}${item.donations.length > 1 ? (i === item.donations.length - 1 ? '' : ',') : ''}`}</span>
                          </>))}
                          {item.donations.length > 3 && minOrderIdLimit<=3?
                          <span className="seemore-btn" onClick={()=>{
                            this.setState({minOrderIdLimit:item.donations.length})
                          }}>...See More</span>:
                        ( minOrderIdLimit === item.donations.length&&minOrderIdLimit!==3&&<span className="seemore-btn" onClick={()=>{
                            this.setState({minOrderIdLimit:3})
                          }}>...See Less</span>)
                          }
                        </div>
                        <div className="col-md-5 bold">Recent order payment attempts:</div>
                        <div className="col-md-7 dl-mob">
                          {item?.recent_order_payment_attempts}
                        </div>
                        <div className="col-md-5 bold">Number of Payments:</div>
                        <div className="col-md-7 dl-mob">
                          {item?.payments && item?.payments?.length}
                        </div>
                        <div className="col-md-5 bold">Last charge date:</div>
                        <div className="col-md-7 dl-mob">

                          {item?.last_payment_date && <>
                            {`${format(new Date(item.last_payment_date), 'do MMM yyyy')} ${format(new Date(item.last_payment_date), 'HH:mm')}`}
                          </>
                          }
                        </div>
                        <div className="col-md-5 bold">Created at:</div>
                        <div className="col-md-7 dl-mob">
                          {item?.created_at && <>
                            {`${format(new Date(item?.created_at), 'do MMM yyyy')} ${format(new Date(item?.created_at), 'HH:mm')}`}
                          </>}
                        </div>

                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-5 bold">Updated at:</div>
                        <div className="col-md-7 dl-mob">
                          {item?.updated_at && <>
                            {`${format(new Date(item.updated_at), 'do MMM yyyy')} ${format(new Date(item.updated_at), 'HH:mm')}`}
                          </>}
                        </div>
                        <div className="col-md-5 bold">Canceled at:</div>
                        <div className="col-md-7">
                          {item?.cancelled_at && <>
                            {`${format(new Date(item.cancelled_at), 'do MMM yyyy')} ${format(new Date(item.cancelled_at), 'HH:mm')}`}
                          </>}
                        </div>
                        <div className="col-md-5 bold">Status Updated at:</div>
                        <div className="col-md-7">
                          {item?.status_updated_at && <>
                            {`${format(new Date(item.status_updated_at), 'do MMM yyyy')} ${format(new Date(item.status_updated_at), 'HH:mm')}`}
                          </>}
                        </div>
                        <div className="col-md-5 bold">Payment Method:</div>
                        <div className="col-md-7 dl-mob">{item?.method}</div>
                        <div className="col-md-5 bold">Payment Gateway:</div>
                        <div className="col-md-7 dl-mob">{item?.gateway}</div>

                        <div className="col-md-5 bold">Resync :</div>
                        <div className="col-md-7">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              this.reSyncSubscription(item.order_id);
                            }}
                          >
                            {subscription.get_status["resync_" + item.order_id] === "pending" ? (
                              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                            ) : (
                              <Icon
                                path={mdiChevronRight}
                                className="menu-arrow"
                                size={0.75}
                                color="#ffffff"
                              />
                            )}
                            &nbsp;ReSync
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {item.additional_fields && Object.keys(item.additional_fields).length > 0
                      ? Object.keys(item.additional_fields).map((key, index) => (
                        <div className="col-md-12" key={index}>
                          <div className="row">
                            <div className="col-md-2">{Helpers.snakeCaseToString(key)} :</div>
                            <div className="col-md-10">
                              {item.additional_fields[key] != null &&
                                typeof item.additional_fields[key] === "object" ? (
                                <div className="row">
                                  <div className="col-md-2 pd-l-0">
                                    {Object.keys(item.additional_fields[key]).map((keyPair, index) => (
                                      <div key={index}>{keyPair}</div>
                                    ))}
                                  </div>
                                  <div className="col-md-10 word-break">
                                    {Object.values(item.additional_fields[key]).map(
                                      (valuePair, index) => (
                                        <div key={index}> - {valuePair}</div>
                                      )
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-md-12 pd-l-0">
                                    {item.additional_fields[key]}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                      : null}
                  </div>

                </div>
              </div>
            </div>
          ) : null}
        </div>
      </li>
    ));
  };

  render() {
    const { subscription } = this.props;
    const { filters, createTag } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">All Subscriptions</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} />
                <div className="row justify-content-end">
                  <div className="col-md-12 col-12 text-right">
                    <OverlayTrigger
                      trigger="click"
                      placement={isMobile ? "bottom-end" : "left-start"}
                      overlay={this.popover()}
                      rootClose
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={(e) => this.getReportLinks(e, "export")}
                      >
                        Proceed to Export
                      </button>
                    </OverlayTrigger>
                    <div>
                      <i>
                        <small className="text-muted">CSV file contains max of 1000 entries</small>
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card-col col-xl-12 col-12">
                <div className="ibox-content">
                  <div className="table-list live-dis-table">
                    <ul>
                      <li className="table-head">
                        <div className="row">
                          <div className="col-md-12 table-col">
                            <div className="row">
                              <div className="col-md-1 table-col dl-mob">Date</div>
                              <div className="col-md-2 table-col dl-mob">Donor</div>
                              <div className="col-md-3 table-col dl-mob">
                                Project(P) / Fundraiser(F)
                              </div>
                              <div className="col-md-2 table-col dl-mob">
                                Subscription ID
                              </div>
                              <div className="col-md-1 table-col dl-mob">Amount</div>
                              <div className="col-md-1 table-col dl-mob">Next Charge Date</div>
                              <div className="col-md-1 table-col dl-mob">Status</div>
                              <div className="col-md-1 table-col dl-mob-right">Details</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {subscription.subscriptions.length > 0 &&
                        this.subscriptionsHTML()}
                    </ul>
                    <div className="row">
                      {subscription && subscription?.load_status.subscription ? (
                        <div className="col-md-12 text-center">
                          <button
                            type="button"
                            className="btn btn-fw align-center"
                            onClick={(e) => {
                              e.preventDefault();
                              this.loadMore();
                            }}
                          >
                            Load more
                          </button>
                        </div>
                      ) : null}
                      {subscription && subscription?.get_status.subscription === "pending" &&
                        subscription?.subscriptions?.length !== 0 ? (
                        <div className="col-md-12">
                          <Loading />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subscription: state.adminSubScriptionList,
  event: state.event,
});

const mapActionToProps = (dispatch) => ({
  Subscription: bindActionCreators(adminSubscriptionListAction, dispatch),
  Event: bindActionCreators(eventAction, dispatch),
});

export default connect(mapStateToProps, mapActionToProps)(AdminSubscriptionList);
