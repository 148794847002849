import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import ReportService from '../../services/report-service';
import Helpers from '../../utils/helpers';
import SubscriptionService from '../../services/subscription-service';
import ReportSubscriptionService from '../../services/report-subscription-service';

export const GET_SUBSCRIPTION_LIST = 'GET_SUBSCRIPTION_LIST';
export const GET_SUBSCRIPTION_LIST_SUCCESS = 'GET_SUBSCRIPTION_LIST_SUCCESS';
export const GET_SUBSCRIPTION_LIST_FAIL = 'GET_SUBSCRIPTION_LIST_FAIL';

export const GET_SUBSCRIPTION_RESYNC = 'GET_SUBSCRIPTION_RESYNC';
export const GET_SUBSCRIPTION_RESYNC_SUCCESS = 'GET_SUBSCRIPTION_RESYNC_SUCCESS';
export const GET_SUBSCRIPTION_RESYNC_FAIL = 'GET_SUBSCRIPTION_RESYNC_FAIL';

export const GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD = 'GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD';
export const GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD_SUCCESS = 'GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD_SUCCESS';
export const GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD_FAIL = 'GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD_FAIL';

export const GENERATE_REPORT_LINK = 'GENERATE_REPORT_LINK';
export const GENERATE_REPORT_LINK_SUCCESS = 'GENERATE_REPORT_LINK_SUCCESS';
export const GENERATE_REPORT_LINK_FAIL = 'GENERATE_REPORT_LINK_FAIL';

export const GET_REPORT_LINK = 'GET_REPORT_LINK';
export const GET_REPORT_LINK_SUCCESS = 'GET_REPORT_LINK_SUCCESS';
export const GET_REPORT_LINK_FAIL = 'GET_REPORT_LINK_FAIL';

export const SUBSCRIPTION_LIST_RESET = 'SUBSCRIPTION_LIST_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  subscriptions: [],
  get_status: {},
  load_status: {},
};

export const adminSubscriptionListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          subscription: 'pending',
        },
      };
    case GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        subscriptions: action.subscriptions,
        get_status: {
          ...state.get_status,
          subscription: 'success',
        },
        load_status: {
          ...state.load_status,
          subscription: action.load_status,
        }
      };
    case GET_SUBSCRIPTION_LIST_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          subscription: 'failed',
        },
      };
    case GET_SUBSCRIPTION_RESYNC:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['resync_' + action.id]: 'pending',
        },
      };
    case GET_SUBSCRIPTION_RESYNC_SUCCESS:
      return {
        ...state,
        subscriptions: action.donations,
        get_status: {
          ...state.get_status,
          ['resync_' + action.id]: 'success',
        },
      };
    case GET_SUBSCRIPTION_RESYNC_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['resync_' + action.id]: 'failed',
        },
      };
    case SUBSCRIPTION_LIST_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    case GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'pending',
        },
      };
    case GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'success',
        },
      };
    case GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'failed',
        },
      };
    case GENERATE_REPORT_LINK:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'pending',
        },
      };
    case GENERATE_REPORT_LINK_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'success',
        },
      };
    case GENERATE_REPORT_LINK_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'failed',
        },
      };
    case GET_REPORT_LINK:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'pending',
        },
      };
    case GET_REPORT_LINK_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'success',
        },
      };
    case GET_REPORT_LINK_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'failed',
        },
      };
    default:
      return state;
  }
};

export function getSubscriptions(filter = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_SUBSCRIPTION_LIST,
    });
    const currentSubscriptions = getState().adminSubScriptionList?.subscriptions;
    const params = {
      ...filter,
      skip:currentSubscriptions&& currentSubscriptions?.length,
      limit: 10,
    };
    return SubscriptionService.get({ params })
      .then((response) => {
          if (response.length === 0) {
          dispatch({
            type: GET_SUBSCRIPTION_LIST_SUCCESS,
            subscriptions: currentSubscriptions,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_SUBSCRIPTION_LIST_SUCCESS,
            subscriptions: [...currentSubscriptions, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_SUBSCRIPTION_LIST_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}
// resync will be picked up later
export function getReSyncSubscription(orderId) {
  return (dispatch, getState) => {
    const currentSubscriptions = [...getState().adminSubscriptionList.subscriptions];
    dispatch({
      type: GET_SUBSCRIPTION_RESYNC,
      id: orderId,
    });
    const request = {
      path: [orderId, 're-confirm'],
      method: 'post'
    };
    return SubscriptionService.get(request)
      .then((response) => {
        const index = Helpers.objSearch(currentSubscriptions, orderId, 'order_id');
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_SUBSCRIPTION_RESYNC_FAIL,
          id: orderId,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getDonationsForDownload(request) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD,
    });
    const params = {
      ...request,
      skip: 0,
      limit: 1000,
    };
    return SubscriptionService.get({ params })
      .then((response) => {
          dispatch({
            type: GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD_SUCCESS,
          });
        return response.supporters;
      })
      .catch((error) => {
        dispatch({
          type: GET_ADMIN_SUBSCRIPTION_FOR_DOWNLOAD_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
    };
  }

export function generateReportLink({...query}, exportFilters=[]) {
  return (dispatch) => {
    dispatch({
      type: GENERATE_REPORT_LINK,
    });
    if(!query.created_after || !query.created_before){  // Required Fields
      dispatch({
        type: GENERATE_REPORT_LINK_FAIL,
      });
      dispatch(NotificationAction.addNotification('Created after and Created before are required fields'));
      return;
    }
    const request = {
      data:{
        selector: exportFilters,
        filter: {
          createdAfter: query.created_after,
          createdBefore: query.created_before,
          updatedAfter:query.updated_after,
          updatedBefore:query.updated_before,
          status: query.status || [],
          campaigns: query.fundraisers  || [],
          gateway: query?.gateway || [],
          nonprofits:query.nonprofit
        },
      },
      method: 'post'
    };
    return ReportSubscriptionService.get(request)
      .then((response) => {
        dispatch({
          type: GENERATE_REPORT_LINK_SUCCESS,
        });
        dispatch(NotificationAction.addNotification('Your request has been received. Please check the Queued Downloads for the status of your request.', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GENERATE_REPORT_LINK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
    };
  }

export function getReportLinks() {
  return (dispatch) => {
    dispatch({
      type: GET_REPORT_LINK,
    });
    return ReportSubscriptionService.get()
      .then((response) => {
        dispatch({
          type: GET_REPORT_LINK_SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_REPORT_LINK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
    };
  }

export function reset() {
  return (dispatch) => {
    dispatch({
      type: SUBSCRIPTION_LIST_RESET,
    });
  };
}
