
import memoize from 'memoize-one';
import _Error from '../utils/_error';
import API from '../utils/api';
import { endpoints } from '../config';

export default class TagsService {
  static requestAPI(endpoint = endpoints.tags, request = {}) {
    return API.request(endpoint, request);
  }

  static cacheGet = memoize(this.requestAPI);

  static get(id, filter) {    
    let params = {}    
    if (filter)
      params = { 
        ...filter 
      }    

    return this.requestAPI( undefined, {
      method: 'GET',
      auth: true,
      params
    }).then(response => {
      return response
    })    
  }


  static unDelete(id){
    return this.requestAPI({
      url: `${endpoints.tags.url}${id}`
    },{
      method: 'PUT',
      data: {
       status: 'active'
      },
      auth: true
    }).then(response => {
      return response
    })
  }

  static remove(id){
    return this.requestAPI({
      url: `${endpoints.tags.url}${id}`
    },{
      method: 'DELETE',
      auth: true
    }).then(response => {
      return response
    })
  }


  static getTagsforEntity(request, filter = {}) {    
    
    let params = {}    
    if (filter) params = {  ...filter  }

    return this.requestAPI(
      {
        url: `${endpoints.tags.url}${request.type}/${request.entity_id}`
      }, 
      {
        method: 'GET',
        auth: true,
        params
      }
    ).then(response => {
      return response
    })
  }


  static createTag(data) {    
    return this.requestAPI( undefined, {
      method: 'POST',
      data: data,
      auth: true
    })    
  }

  static editTag(data, id) {    
    return this.requestAPI({
      url: `${endpoints.tags.url}${id}`
    }, {
      method: 'PUT',
      data: data,
      auth: true
    })
  }
    
  static updateTagsForEntity(params, request){
    return this.requestAPI({
      url: `${endpoints.tags.url}${params.type}/${params.entity_id}/update`
    }, {
      method: 'PUT',
      data: request,
      auth: true
    })
  }

  static exportItems(id, tagType){
    return this.requestAPI({
      url: `${endpoints.tags.url}${id}/items/${tagType}/export`
    }, {
      method: 'GET',
      auth: true
    })
  }

  static importItems(id, tagType, data){
    return this.requestAPI({
      url: `${endpoints.tags.url}${tagType}/${id}/upload`
    }, {
      method: 'POST',
      data: data,
      auth: true
    })
  }

  static removeTagsForEntity(params){
    return this.requestAPI({
      url: `${endpoints.tags.url}${params.type}/${params.entity_id}/remove/${params.id}`
    }, {
      method: 'DELETE',      
      auth: true
    })
  }
}
