import memoize from "memoize-one";

import API from "../utils/api";
import { endpoints } from "../config";

export default class BdBeService {

  static requestAPI(query, request = {}) {
    const { isQuerySearch } = query
    // const url = isQuerySearch ? endpoints.searchNonprofits10bdBe :
    //   (status && status.length&& (status !== 'downloaded' && status !== 'download-pending')) ? { url: `${endpoints.statusNonprofits10bdBe.url}`, auth: true } :
    //   status === 'download-pending'?{ url: `${endpoints.bdBe.url}`, auth: true }:
    //   endpoints.bdBe;
    const url = isQuerySearch ? endpoints.searchNonprofits10bdBe : { url: `${endpoints.bdBe.url}`, auth: true }
    return API.request(url, request);
  }

  static cacheGet = memoize(this.requestAPI);

  static get(query, request) {
    return this.cacheGet(query, request);
  }
  static set(data, request = {}) {
    const { id, isReject, isSendEmail, rejectReason } = data
    const url = {
      url: isReject ? `${endpoints.reject10bdbeNonprofits.url}/${id}` : isSendEmail ?
        `${endpoints.sendEmail10bdbeNonprofits.url}/${id}`
        : `${endpoints.approve10bdbeNonprofits.url}/${id}`, auth: true
    }

    return API.request(url, {
      method: 'post', 
      data: {
        reject_reason: rejectReason
      }
    });
  }
}


