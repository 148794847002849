import * as NotificationAction from "../notificationReducer";
import TagsService from "../../services/tag-service";

export const GET_TAGS = "GET_TAGS";
export const CREATE_TAG = "CREATE_TAG";
export const GET_TAGS_FOR_ENTITY = "GET_TAGS_FOR_ENTITY";
export const SET_TAGS = "SET_TAGS";
export const DELETE_TAG = "DELETE_TAG";
export const UNDELETE_TAG = "UNDELETE_TAG";
export const UPDATE_TAGS_FOR_ENTITY = "UPDATE_TAGS_FOR_ENTITY";
export const REMOVE_TAGS_FOR_ENTITY = "REMOVE_TAGS_FOR_ENTITY";
export const EXPORT_TAG_ITEMS = "EXPORT_TAG_ITEMS";
export const IMPORT_TAG_ITEMS = "IMPORT_TAG_ITEMS";

export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const UNDELETE_TAG_SUCCESS = "UNDELETE_TAG_SUCCESS";
export const GET_TAGS_FOR_ENTITY_SUCCESS = "GET_TAGS_FOR_ENTITY_SUCCESS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const SET_TAGS_SUCCESS = "SET_TAGS_SUCCESS";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const UPDATE_TAGS_FOR_ENTITY_SUCCESS = "UPDATE_TAGS_FOR_ENTITY_SUCCESS";
export const REMOVE_TAGS_FOR_ENTITY_SUCCESS = "REMOVE_TAGS_FOR_ENTITY_SUCCESS";
export const EXPORT_TAG_ITEMS_SUCCESS = "EXPORT_TAG_ITEMS_SUCCESS";
export const IMPORT_TAG_ITEMS_SUCCESS = "IMPORT_TAG_ITEMS_SUCCESS";

export const GET_TAGS_FAIL = "GET_TAGS_FAIL";
export const DELETE_TAG_FAIL = "DELETE_TAG_FAIL";
export const UNDELETE_TAG_FAIL = "UNDELETE_TAG_FAIL";
export const SET_TAGS_FAIL = "SET_TAGS_FAIL";
export const GET_TAGS_FOR_ENTITY_FAIL = "GET_TAGS_FOR_ENTITY_FAIL";
export const CREATE_TAG_FAIL = "CREATE_TAG_FAIL";
export const UPDATE_TAGS_FOR_ENTITY_FAIL = "UPDATE_TAGS_FOR_ENTITY_FAIL";
export const REMOVE_TAGS_FOR_ENTITY_FAIL = "REMOVE_TAGS_FOR_ENTITY_FAIL";
export const EXPORT_TAG_ITEMS_FAIL = "EXPORT_TAG_ITEMS_FAIL";
export const IMPORT_TAG_ITEMS_FAIL = "IMPORT_TAG_ITEMS_FAIL";

const initialState = {
  get_status: {},
  tags: {},
  entityTags: {},
  load_status: {},
};

export const tagsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TAGS:
    case UNDELETE_TAG:
    case REMOVE_TAGS_FOR_ENTITY:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          tags: "pending",
        },
      }; 
    case CREATE_TAG:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          tag: "pending",
        },
      };
    case GET_TAGS_FOR_ENTITY:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          entity: "pending",
        },
      };
    case SET_TAGS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          tags: "pending",
        },
      };
    case DELETE_TAG:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          tag: "pending",
        },
      };
    case UPDATE_TAGS_FOR_ENTITY:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          entityTags: "pending",
        },
      };
    case EXPORT_TAG_ITEMS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          exportItems: "pending",
        },
      };
    case IMPORT_TAG_ITEMS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          importItems: "pending",
        },
      };
    case GET_TAGS_SUCCESS:
      return {
        ...action.tags,
        get_status: {
          ...state.get_status,
          tags: "success",
        },
        load_status: {
          ...state.load_status,
          tags: action.load_status,
        },
      };
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        ...action.tag,
        get_status: {
          ...state.get_status,
          tag: "success",
        },
      };
    case GET_TAGS_FOR_ENTITY_SUCCESS:
      return {
        ...state,
        entityTags: action.entityTags,
        get_status: {
          ...state.get_status,
          entityTags: "success",
        },
      };
    case GET_TAGS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          tags: "failed",
        },
      };
    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          tag: "failed",
        },
      };
    case GET_TAGS_FOR_ENTITY_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          tags: "failed",
        },
      };
    default:
      return state;
  }
};

export function get(request) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_TAGS,
    });

    const currentTags = getState().tags.data || [];

    const params = {
      ...request,
    };

    return TagsService.get(undefined, params)
      .then((response) => {
        if (response && response?.data?.length === 0) {
          dispatch({
            type: GET_TAGS_SUCCESS,
            tags: request.skip === 0 ? { data: [] } : { data: currentTags },
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_TAGS_SUCCESS,
            tags: {
              ...response,
              data: request.skip === 0 ? response.data : [...currentTags, ...response.data],
            },
            load_status: response.data.length === request.limit,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_TAGS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function create(request) {
  return (dispatch) => {
    dispatch({
      type: CREATE_TAG,
    });

    return TagsService.createTag(request)
      .then((response) => {
        dispatch({
          type: CREATE_TAG_SUCCESS,
          tags: response,
        });
        dispatch(
          NotificationAction.addNotification(`Tag ${request.title} Created Successfully`, "SUCCESS")
        );
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_TAG_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function remove(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_TAG,
    });

    return TagsService.remove(id)
      .then((response) => {
        dispatch({
          type: DELETE_TAG_SUCCESS,
          tags: response,
        });
        dispatch(NotificationAction.addNotification(`Tag ${id} Archived Successfully`, "SUCCESS"));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_TAG_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function unDelete(id) {
  return (dispatch) => {
    dispatch({
      type: UNDELETE_TAG,
    });

    return TagsService.unDelete(id)
      .then((response) => {
        dispatch({
          type: UNDELETE_TAG_SUCCESS,
          tags: response,
        });
        dispatch(
          NotificationAction.addNotification(`Tag ${id} Unarchived Successfully`, "SUCCESS")
        );
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UNDELETE_TAG_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function edit(request, id, showNotification = true) {
  return (dispatch) => {
    dispatch({
      type: CREATE_TAG,
    });

    return TagsService.editTag(request, id)
      .then((response) => {
        dispatch({
          type: CREATE_TAG_SUCCESS,
          tags: response,
        });
        if (showNotification) {
          dispatch(NotificationAction.addNotification(`Tag ${id} Edited Successfully`, "SUCCESS"));
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_TAG_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getTagsForEntity(request, filter = {}) {
  return (dispatch) => {
    dispatch({
      type: GET_TAGS_FOR_ENTITY,
    });

    return TagsService.getTagsforEntity(request, filter)
      .then((response) => {
        dispatch({
          type: GET_TAGS_FOR_ENTITY_SUCCESS,
          entityTags: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_TAGS_FOR_ENTITY_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function updateTagsForEntity(params, request) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TAGS_FOR_ENTITY,
    });

    return TagsService.updateTagsForEntity(params, request)
      .then((response) => {
        dispatch({
          type: UPDATE_TAGS_FOR_ENTITY_SUCCESS,
          entityTags: response,
        });
        dispatch(
          NotificationAction.addNotification(
            `Tag(s) Updated for ${params.type} id ${params.entity_id} Successfully`,
            "SUCCESS"
          )
        );
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_TAGS_FOR_ENTITY_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function exportItems(id, tagType) {
  return (dispatch) => {
    dispatch({
      type: EXPORT_TAG_ITEMS,
    });
    return TagsService.exportItems(id, tagType)
      .then((response) => {
        dispatch({
          type: EXPORT_TAG_ITEMS_SUCCESS,
          exportURL: response,
        });

        if (response.success) {
          dispatch(
            NotificationAction.addNotification(`Tag ${id} Exported Successfully`, "SUCCESS")
          );
        } else {
          dispatch(NotificationAction.addNotification("204: " + response.message));
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: EXPORT_TAG_ITEMS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function importItems(id, tagType, data) {
  return (dispatch) => {
    dispatch({
      type: IMPORT_TAG_ITEMS,
    });

    return TagsService.importItems(id, tagType, data)
      .then((response) => {
        dispatch({
          type: IMPORT_TAG_ITEMS_SUCCESS,
          exportURL: response,
        });
        if (response.message) {
          dispatch(NotificationAction.addNotification("204: " + response.message));
        } else {
          dispatch(
            NotificationAction.addNotification(`Tag ${id} Imported Successfully`, "SUCCESS")
          );
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: IMPORT_TAG_ITEMS_FAIL,
        });
        dispatch(NotificationAction.addNotification("Error: " + error.message));
      });
  };
}

export function removeTagsForEntity(params) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_TAGS_FOR_ENTITY,
    });

    return TagsService.removeTagsForEntity(params)
      .then((response) => {
        dispatch({
          type: REMOVE_TAGS_FOR_ENTITY_SUCCESS,
          entityTags: response,
        });
        dispatch(
          NotificationAction.addNotification(
            `Tag ${response.data.title} Removed from ${params.type} id ${params.entity_id} Successfully`,
            "SUCCESS"
          )
        );
        return response;
      })
      .catch((error) => {
        dispatch({
          type: REMOVE_TAGS_FOR_ENTITY_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}
