import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminNonprofit10BdBeListActions from '../../../reducers/Admin/admin10BdBeListReducer';
import { Link } from 'react-router-dom';


class Nonprofits10bdbeApproved extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  handleSendEmailNonprofit = () => {
    const {  nonprofit } = this.props.tileProps || {};
    const { Nonprofits } = this.props;
    Nonprofits.sendEmailNonprofits(nonprofit).then((res) => {
      console.log(res);
    })
  }

  render() {
    const { link,_id, total_donors, total_emails_sent, docs } = this.props.tileProps||{};

    const { name, image } = docs?.[0]?.nonprofit ?? {};
    const { name: userName, email } = docs?.[0]?.user ?? {}
    return (
      <div className="ngo-tiles">
        <div className="amount-wrap">
          <>
            <div className='amount-block'>
              <span>{total_donors}</span>
              <small className='text-left'>No of Donors</small>
            </div>
            <div className='amount-block flex-end'>
              <span>{total_emails_sent}</span>
              <small className='text-right'>Email Sent</small>
            </div>
          </>

        </div>
        <Link to={{ pathname: `${link}${_id}/donations`, state: this.props.tileProps }}>
          <p className='mt-4 p-1 np-wrap'>
            <img src={image} alt='profile-pic' />
            <strong >{name}</strong></p>
        </Link>
        <div className="ngo-tiles-wrapper">

          <div className="np-poc np-user" >
            <div className='name-wrap'>
              <span>{userName}</span>
              <span>{email}</span>
            </div>
          </div>

        </div>
        {/* <div className="ngo-tiles-footer">
          <div className="updates">
            <span>{updated_at ? formatDistanceToNow(new Date(updated_at)) : '-'}</span>
            <small>Last updated</small>
          </div>
          <div className="updates right text-right">
            <span>{created_at ? format(new Date(created_at), 'do MMM yyyy') : '-'}</span>
            <small>Created</small>
          </div>
        </div>
        <div className="ngo-tiles-footer no-border">
          <div className="updates">
            <span>{createdAfter ? format(new Date(createdAfter), 'do MMM yyyy') : '-'}</span>
            <small>Duration-From</small>
          </div>
          <div className="updates right text-right">
            <span>{createdBefore ? format(new Date(createdBefore), 'do MMM yyyy') : '-'}</span>
            <small>To</small>
          </div>
        </div> */}
        <div className='button-wrap'>
          <>
            <button className="btn btn-primary view-details-btn" type="submit" >View Details </button>
            <button className="btn btn-primary mr-3" onClick={this.handleSendEmailNonprofit}>Send Email Again </button>
          </>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  nonprofits: state.adminNonprofit10BdBeList
});

const mapActionToProps = dispatch => ({
  Nonprofits: bindActionCreators(adminNonprofit10BdBeListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Nonprofits10bdbeApproved);