import React, { Component } from 'react';

import { format } from 'date-fns';
import classnames from 'classnames';

import './SearchWithSuggestion.scss'

class SearchWithSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q: '',
      activeIndex: 0,
      q_options_text: ''
    }
  }

  handelQ = (e) => {
    e.preventDefault();
    let q = e.target.value;
    this.setState({ q });
  }

  handelKeyUp = (e) => {
    e.preventDefault();
    const { activeIndex } = this.state;
    const length = this.searchSuggestions() && this.searchSuggestions().length;
    if(e.keyCode === 38 && length) {
      //Up prressed
      if (length > 1 && activeIndex > 0) {
        this.setState({ activeIndex: activeIndex - 1 });
      } else {
        this.setState({ activeIndex: length - 1 });
      }
    }
    if(e.keyCode === 40 && length) {
      //Down prressed
      if (activeIndex < length - 1) {
        this.setState({ activeIndex: activeIndex + 1 });
      } else {
        this.setState({ activeIndex: 0 });
      }
    }
  }

  updateQ = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { value, onChange } = this.props;
    const { q, activeIndex, q_options_text } = this.state;
    const selectedQuery = this.searchSuggestions()[activeIndex];
    if (q !== '' && onChange && selectedQuery) {
      const selected = value.type.find(i => i?.query === selectedQuery);
      const selectedType = value.type.find(i => i?.query === selectedQuery).type;
      const newValue = { ...value };
      let newData = [];
      if(newValue.data) {
        newData = [...newValue.data];
      }
      const data = {
        data: {[selected.query]: selectedType === 'options' ? q_options_text : q.trim() },
        title: `${[selected.label]}: ${selectedType === 'options' ? q_options_text : q.trim()}`,
      }
      if (newData.find(i => Object.keys(i.data).includes(selected.query))) {
        newData = newData.map(i => (Object.keys(i.data).includes(selected.query) ? data : i));
      } else {
        newData.push(data);
      }
      // { ...value };
      newValue.data = newData;
    //   delete newOption.data;
    //   newOptions = value.map(item => (item.label === option.label) ? newOption : item);
    // } else {
    //   newOption.data = { data: { [value.find(i => i.label === 'Search').query]: q }, title: `Search: ${q}` };
    //   newOptions = value.map(item => (item.label === option.label) ? newOption : item);
      this.setState({ q: '', activeIndex: 0, q_options_text: '' });
      onChange(newValue)
    }
  }

  mouseOverSuggestions = (index) => {
    if(this.searchSuggestions().length > 0) {
      let activeIndex = this.searchSuggestions().indexOf(index);
      this.setState({ activeIndex: activeIndex });
    }    
  }

  checkOptions = (list, q) => {    
    let len = q.length;
    let count = 0;
    let value = ''
    for(let i=0; i<list.length; i++) {
      if(list[i].substring(0,len).toLowerCase() === q.toLowerCase() && len > 2) {
        count++;
        value = list[i];
      }      
    }
    if(count === 1) {
      if(this.state.q_options_text !== value) {
        this.setState({q_options_text: value})
      }
    } else if(this.state.q_options_text !== this.state.q) {
      this.setState({q_options_text: q})
    }
    return count == 1 ? {exist: true, value: value} : {exist: false, value: ''}
  }

  searchSuggestions = (rtype) => {
    const { q, activeIndex } = this.state;
    const { value,isOnlySearch } = this.props;
    const type = value.type;
    const suggestions = [];
    const isNumber = !isOnlySearch;
    //email format below requires correct email format with username proceeded by domain name
    const emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;

    //phone format below requires the country code initially like +91 proceeded by phone number
    const phoneFormat = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/gm;

    //pan number format below expects the india PAN number with first 5 alphabets proceeded by 4 numbers and lastly single number
    const panFormat = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    //this checks if the string contains any number
    const number = /\d+/;

    let index = 0;
    if (type.filter(i => i.type === 'default').length>0 
    && q.length > 0 
    &&   (!isNumber || !q.match(number)) 
    && !q.match(emailFormat)
    &&q.slice(0, 4)!=='sub_') {
      if (rtype === 'html') {
        const staticIndex = index;
        type.filter(i => i.type === 'default').map(i => {
          suggestions.push(
            <div key={i.query} onClick={this.updateQ} onMouseOver={(e) => { e.preventDefault(); this.mouseOverSuggestions(i.query) }} className={classnames('d-flex justify-content-between py-2 border-bottom', { active: (activeIndex === index) })}>
              <div className="wrapper">
                <div className="badge badge-outline-primary">In {i.type === 'default' ? i.label : i.query}</div>
                <p className="mb-0 mt-2">{`Show results for: ${q}`}</p>
              </div>
            </div>
          )
          index++;
        })
      } else {
        type.filter(i => i.type === 'default').map(i => suggestions.push(i.query));
      }
    }
    if (type.filter(i => i.type === 'email').length>0 && q.match(emailFormat)) {
      if (rtype === 'html') {
        const staticIndex = index;
        type.filter(i => i.type === 'email').map(i => {
          suggestions.push(
            <div key={i.query} onClick={this.updateQ} onMouseOver={(e) => { e.preventDefault(); this.mouseOverSuggestions(i.query) }} className={classnames('justify-content-between py-2 border-bottom', { active: (activeIndex === index) })}>
              <div className="wrapper">
                <div className="badge badge-outline-primary">In {i.label}</div>
                <p className="mb-0 mt-2">{`Show results for: ${q}`}</p>
              </div>
            </div>
          );
          index++;
        })
      } else {
        type.filter(i => i.type === 'email').map(i => suggestions.push(i.query));
      }
    }

    if (type.filter(i => i.type === 'phone').length>0 && (q.match(phoneFormat))) {
      if (rtype === 'html') {
        const staticIndex = index;
        type.filter(i => i.type === 'phone').map(i => {
          suggestions.push(
            <div key={i.query} onClick={this.updateQ} onMouseOver={(e) => { e.preventDefault(); this.mouseOverSuggestions(i.query) }} className={classnames('d-flex justify-content-between py-2 border-bottom', { active: (activeIndex === index) })}>
              <div className="wrapper">
                <div className="badge badge-outline-primary">In {i.label}</div>
                <p className="mb-0 mt-2">{`Show results for: ${q}`}</p>
              </div>
            </div>
          )
          index++;
        })
      } else {
        type.filter(i => i.type === 'phone').map(i => suggestions.push(i.query));
      }
    }

    if (type.filter(i => i.type === 'order').length>0 && (q.indexOf('LC') === 0) && q.length === 15) {
      if (rtype === 'html') {
        const staticIndex = index;
        type.filter(i => i.type === 'order').map(i => {
          suggestions.push(
            <div key="order" onClick={this.updateQ} onMouseOver={(e) => { e.preventDefault(); this.mouseOverSuggestions(i.query) }} className={classnames('d-flex justify-content-between py-2 border-bottom', { active: (activeIndex === index) })}>
              <div className="wrapper">
                <div className="badge badge-outline-primary">In {i.label}</div>
                <p className="mb-0 mt-2">{`Show results for: ${q}`}</p>
              </div>
            </div>
          )
          index++;
        })
      } else {
        type.filter(i => i.type === 'order').map(i => suggestions.push(i.query));
      }
    }
    if (type.filter(i => i.type === 'subscription').length>0 &&q&& q.trim().slice(0, 4)==='sub_') {
      if (rtype === 'html') {
        const staticIndex = index;
        type.filter(i => i.type === 'subscription').map(i => {
          suggestions.push(
            <div key="order" onClick={this.updateQ} onMouseOver={(e) => { e.preventDefault(); this.mouseOverSuggestions(i.query) }} className={classnames('d-flex justify-content-between py-2 border-bottom', { active: (activeIndex === index) })}>
              <div className="wrapper">
                <div className="badge badge-outline-primary">In {i.label}</div>
                <p className="mb-0 mt-2">{`Show results for: ${q}`}</p>
              </div>
            </div>
          )
          index++;
        })
      } else {
        type.filter(i => i.type === 'subscription').map(i => suggestions.push(i.query));
      }
    }

    if (type.filter(i => i.type === 'disbursement').length>0 && (q.indexOf('LCD') === 0) && q.length === 16) {
      if (rtype === 'html') {
        const staticIndex = index;
        type.filter(i => i.type === 'disbursement').map(i => {
          suggestions.push(
            <div key={i.query} onClick={this.updateQ} onMouseOver={(e) => { e.preventDefault(); this.mouseOverSuggestions(i.query) }} className={classnames('d-flex justify-content-between py-2 border-bottom', { active: (activeIndex === index) })}>
              <div className="wrapper">
                <div className="badge badge-outline-primary">In {i.label}</div>
                <p className="mb-0 mt-2">{`Show results for: ${q}`}</p>
              </div>
            </div>
          )
          index++;
        })
      } else {
        type.filter(i => i.type === 'disbursement').map(i => suggestions.push(i.query));
      }
    }

    if (type.filter(i => i.type === 'pan').length>0 && (q.match(panFormat))) {
      if (rtype === 'html') {
        const staticIndex = index;
        type.filter(i => i.type === 'pan').map(i => {
          suggestions.push(
            <div key={i.query} onClick={this.updateQ} onMouseOver={(e) => { e.preventDefault(); this.mouseOverSuggestions(i.query) }} className={classnames('d-flex justify-content-between py-2 border-bottom', { active: (activeIndex === index) })}>
              <div className="wrapper">
                <div className="badge badge-outline-primary">In {i.label}</div>
                <p className="mb-0 mt-2">{`Show results for: ${q}`}</p>
              </div>
            </div>
          )
          index++;
          })
      } else {
        type.filter(i => i.type === 'pan').map(i => suggestions.push(i.query));
      }
    }

    if (type.filter(i => i.type === 'options').length>0 && !q.match(number) && !q.match(emailFormat) && this.checkOptions(type.find(i => i.type === 'options').list, q).exist) {
      let optionValue = this.checkOptions(type.find(i => i.type === 'options').list, q).value;
      if (rtype === 'html') {
        const staticIndex = index;
        type.filter(i => i.type === 'options').map(i => {
          suggestions.push(
            <div key={i.query} onClick={this.updateQ} onMouseOver={(e) => { e.preventDefault(); this.mouseOverSuggestions(i.query) }} className={classnames('d-flex justify-content-between py-2 border-bottom', { active: (activeIndex === index) })}>
              <div className="wrapper">
                <div className="badge badge-outline-primary">In {i.label}</div>
                <p className="mb-0 mt-2">{`Show results for: ${optionValue ? optionValue : q}`}</p>
              </div>
            </div>
          )
          index++;
        })
      } else {
        type.filter(i => i.type === 'options').map(i => suggestions.push(i.query));
      }
    }

    if (type.filter(i => i.type === 'text').length>0 && q.length > 0 && !q.match(number) && !q.match(emailFormat)) {
      if (rtype === 'html') {
        const staticIndex = index;
        type.filter(i => i.type === 'text').map(i => {
          suggestions.push(
            <div key={i.query} onClick={this.updateQ} onMouseOver={(e) => { e.preventDefault(); this.mouseOverSuggestions(i.query) }} className={classnames('d-flex justify-content-between py-2 border-bottom', { active: (activeIndex === index) })}>
              <div className="wrapper">
                <div className="badge badge-outline-primary">In {i.label}</div>
                <p className="mb-0 mt-2">{`Show results for: ${q}`}</p>
              </div>
            </div>
          )
          index++;
        })        
      } else {
        type.filter(i => i.type === 'text').map(i => suggestions.push(i.query));
      }
    }
    return suggestions;
  }


  render() {
    const { value } = this.props;
    const { q } = this.state;
    return (
      <form onSubmit={this.updateQ} className="search-suggestions-wrapper">
        <div className="form-group d-flex search-field">
          <div className="input-group">
            <input type="text" className="form-control" placeholder={value.placeholder} value={q} onChange={this.handelQ} onKeyUp={this.handelKeyUp} />
          </div>
        </div>
        <div className="search-suggestions">
          {this.searchSuggestions('html')}
        </div>
      </form>
    );
  }
}
export default SearchWithSuggestion;
