import React from 'react';
import Icon from '@mdi/react';
import { mdiTelevision, mdiTag } from '@mdi/js';

import AdminDonationsList from '../../productComponents/Admin/AdminDonationsList';
import AdminTagsList from '../../productComponents/Admin/AdminTagsList';
import AdminNonprofitList from '../../productComponents/Admin/AdminNonprofitList';
import AdminNonprofitItem from '../../productComponents/Admin/AdminNonprofitItem';
import AdminCampaignsList from '../../productComponents/Admin/AdminCampaignsList';
import AdminSubscriptionList from '../../productComponents/Admin/AdminSubscriptionList';
import Admin10BeBdNonprofits from '../../productComponents/Admin/Admin10BeBdNonprofits';
import Admin10BdBeDonations from '../../productComponents/Admin/Admin10BdBeDonations';



const routes = {
  title: 'Admin',
  path: '/admin',
  default_path: '/admin/donations',
  for_user: 'admin',
  sidebar: [
    {
      title: 'Donations',
      path: '/admin/donations',
      icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
      component: AdminDonationsList,
    },
    {
      title: 'Subscriptions',
      path: '/admin/subscriptions',
      icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
      component: AdminSubscriptionList,
    },
    {
      title: 'NonProfits',
      path: '/admin/nonprofits',
      icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
      component: AdminNonprofitList,
      maskedChild: [
        {
          path: '/admin/nonprofits/:id/preview',
          component: AdminNonprofitItem,
        }
      ]
    },
    
    {
      title: 'Campaigns',
      icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
      path: '/admin/campaigns',
      component: AdminCampaignsList,
    },
    {
      title: 'Tags',
      icon: (<Icon path={mdiTag} className="menu-icon" size={0.75} color="#979797" />),
      path: '/admin/tags',
      component: AdminTagsList,
    },
    {
      title: '10BD/BE',
      icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
      path: '/admin/10bdbe',
      component: Admin10BeBdNonprofits,
      maskedChild: [
        {
          path: '/admin/10bdbe/:id/donations',
          component: Admin10BdBeDonations,
        }
      ]
    }
  ]
};

export default routes;
