import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';

import PhoneNumberInput from '../../../components/PhoneInput/PhoneInput';

import './Profile.css';
import { otpInputMaxLength } from '../../../utils/constant';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',
      otpTimeout: 0,
      phoneInputCountryLength:0,
      phoneInputError: false,
      phoneInputMessage: ''
    };
  }

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  }

  componentDidUpdate = () => {
    const { Login, login } = this.props;
    if (login.view && login.view.otpTimeout > 0) {
      Login.otpSetSeconds(0);
      this.setState({ otpTimeout: login.view.otpTimeout }, this.otpTime);
    }
  }

  otpTime = () => {
    let { otpTimeout } = this.state;
    if (otpTimeout && otpTimeout > 0) {
      this.setState({ otpTimeout: otpTimeout -= 1 });
      this.timer = setTimeout(this.otpTime, 1000);
    }
  }

  otpHTML = (type) => {
    const { otpTimeout } = this.state;
    if (otpTimeout > 0) {
      return (
        <label className="mt-2 text-right block text-small">
          Get another OTP ( Wait for 00:
          {(otpTimeout <= 9) ? `0${otpTimeout}` : otpTimeout}
          )
        </label>
      );
    }
    return (
      <a href="#login" onClick={(e) => { e.preventDefault(); this.verifyUser(type); }} className="mt-2 text-right text-small block">
        Get another OTP
      </a>
    );
  }

  verifyUser = (type) => {
    const { Login, value } = this.props;
    let data = {};
    let view = '';
    if (type === 'mobile') {
      data = {
        identifier: value.phone,
        service: 'mobile',
      };
      view = 'mobileLogin';
    }
    if (type === 'email') {
      data = {
        identifier: value.email,
        service: 'email',
      };
      view = 'emailLogin';
    }
    if ((type === 'mobile'&&value.phone) || (type === 'email'&&value.email)) {
      !this.state.phoneInputError&&  Login.verify(data, view); }
    else {
      this.setState({ phoneInputError:true,phoneInputMessage:'Please fill this field' })
    }

  }

  loginUser = (type) => {
    const {
      otp,
    } = this.state;
    const { Login, value } = this.props;
    let data = {};
    if (type === 'mobile') {
      data = {
        identifier: value.phone,
        service: 'mobile',
        token: otp,
      };
    }
    if (type === 'email') {
      data = {
        identifier: value.email,
        service: 'email',
        token: otp,
      };
    }
    Login.authorise(data);
  }

  handelMobile = (e) => {
    const { value, onChange } = this.props;
    const data = { ...value };
    data.phone = e;
    if (onChange) {
      onChange(data);
    }
    if(e.length){
      this.setState({phoneInputError:false})
    }
  }

  handleMobileBlur=(error,countryPhoneLength)=>{
    const { value, onMobileBlur } = this.props;
    const data = { ...value };
    this.setState({phoneInputCountryLength:countryPhoneLength})
    if(error && error.isMobileEmpty){
      this.setState({phoneInputError:true,phoneInputMessage:'Please enter valid phone number'},()=>{
        data.phoneInputError = this.state.phoneInputError
        if (onMobileBlur) {
          onMobileBlur(data);
        }
      })
    }
  }

  handelEmail = (e) => {
    const { value, onChange } = this.props;
    const data = { ...value };
    data.email = e.target.value;
    if (onChange) {
      onChange(data);
    }
  }

  handelOTP = (e) => {
    this.setState({ otp: e.target.value });
  }

  handelName = (e) => {
    const { value, onChange } = this.props;
    const data = { ...value };
    data.name = e.target.value;
    if (onChange) {
      onChange(data);
    }
  }


  mobileVerifyHTML = () => {
    const { otp, phoneInputError,phoneInputMessage,countryPhoneLength } = this.state;
    const { login, Login, value, config } = this.props;
    const { country_code } = config.defaults || {};
    if (login.view.type === 'mobileLogin') {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div className="input-group phone-field">
                <PhoneNumberInput value={value.phone} onChange={this.handelMobile} onPhoneBlur={this.handleMobileBlur} disabled />
                <span className="input-group-append phone-append">
                  {(value.is_phone_verified) ? (
                    <button type="button" className="btn btn-success btn-with-input" disabled>
                      <Icon
                        path={mdiCheckboxMarkedCircleOutline}
                        size={1}
                        color="white"
                      />
                    </button>
                  ) : (
                    <button className="btn btn-info btn-with-input" type="button" onClick={(e) => { e.preventDefault(); Login.loginViewChange('mobile'); }}>Edit</button>
                  )}
                </span>
              </div>
              {phoneInputError && <div className='errorContainer-profile'>{phoneInputMessage}</div>}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="input-group">
                <input type="text" className="form-control"  maxLength={otpInputMaxLength} placeholder="OTP" value={otp} onChange={this.handelOTP} />
                <span className="input-group-append">
                  <button className="btn btn-info btn-with-input" type="button" onClick={(e) => { e.preventDefault(); this.loginUser('mobile'); }}>Verify</button>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            {this.otpHTML('mobile')}
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="input-group phone-field">
            <PhoneNumberInput value={value.phone} onChange={this.handelMobile} disabled={(value.is_phone_verified)} countrycode={country_code} onPhoneBlur={this.handleMobileBlur} />
            <span className="input-group-append phone-append">
              {(value.is_phone_verified) ? (
                <button type="button" className="btn btn-success btn-with-input" disabled>
                  <Icon
                    path={mdiCheckboxMarkedCircleOutline}
                    size={1}
                    color="white"
                  />
                </button>
              ) : (
                <button 
                className="btn btn-info btn-with-input" 
                type="button" 
                onClick={(e) => { e.preventDefault(); this.verifyUser('mobile'); }} 
            disabled={phoneInputError}
                >Send OTP
                </button>
              )}
            </span>
          </div>
          {phoneInputError && <div className='errorContainer-profile'>{phoneInputMessage}</div>}


        </div>
      </div>
    );
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  }

  emailVerifyHTML = () => {
    const { otp,phoneInputError } = this.state;
    const { login, Login, value } = this.props;
    if (login.view && login.view.type === 'emailLogin') {
      return (
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div className="input-group phone-field">
                <input type="text" className="form-control" placeholder="Email Id." value={value.email} onChange={this.handelEmail} disabled={(value.is_email_verified)} />
                <span className="input-group-append">
                  {(value.is_email_verified) ? (
                    <button type="button" className="btn btn-success btn-with-input" disabled>
                      <Icon
                        path={mdiCheckboxMarkedCircleOutline}
                        size={1}
                        color="white"
                      />
                    </button>
                  ) : (
                    <button className="btn btn-info btn-with-input" type="button" onClick={(e) => { e.preventDefault(); Login.loginViewChange('email'); }}>Edit</button>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <div className="input-group">
                <input type="text" className="form-control"  maxLength={otpInputMaxLength} placeholder="OTP" value={otp} onChange={this.handelOTP} />
                <span className="input-group-append">
                  <button className="btn btn-info btn-with-input" type="button" onClick={(e) => { e.preventDefault(); this.loginUser('email'); }}>Verify</button>
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            {this.otpHTML('email')}
          </div>
        </div>
      );
    }
    return (
      <div className="input-group col-xs-12">
        <input type="text" className="form-control" placeholder="Email Id." value={value.email} onChange={this.handelEmail} disabled={(value.is_email_verified)} />
        <span className="input-group-append">
          {(value.is_email_verified) ? (
            <button type="button" className="btn btn-success btn-with-input" disabled>
              <Icon
                path={mdiCheckboxMarkedCircleOutline}
                size={1}
                color="white"
              />
            </button>
          ) : (
            <button 
            className="btn btn-info btn-with-input" 
            type="button" 
            disabled={phoneInputError}
            onClick={(e) => { e.preventDefault(); this.verifyUser('email'); }}>Send OTP</button>
          )}
        </span>
      </div>
    );
  }

  render() {
    const { children, value } = this.props;
    return (
      <form onSubmit={this.handelSubmit}>

        <div className="row justify-content-md-center">
          <div className="col-12 stretch-card grid-margin profile-wrapper">
            <div className="row">
              <div className="col-12 stretch-card grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Member Details</h4>
                    <p className="card-description">Please enter the following details and verify mobile and email. If already verified, proceed with next.</p>
                    <div className="form-group">
                      <label>Name of contact person</label>
                      <input className="form-control" type="text" placeholder="Name" value={value.name} onChange={this.handelName} />
                    </div>
                    <div className="form-group">
                      <label>Mobile</label>
                      {this.mobileVerifyHTML()}
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      {this.emailVerifyHTML()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Profile;
