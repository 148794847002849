import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import * as LoginAction from '../../../reducers/loginReducer';

import Navbar from '../../../appComponents/Navbar';
import ViewType from '../../../appComponents/ViewType';
import ScrollToTop from '../../../appComponents/ScrollToTop';
import Footer from '../../../appComponents/Footer';

import Sidebar from '../../../appComponents/Sidebar';

import disbursementRoutes from '../../../routes/productRoutes/disbursements';
import MigrationNotification from '../../../appComponents/MigrationNotification';

class Home extends Component {
  componentDidMount() {
    // ComponentDidMount
  }

  render() {
    const { Login, login, location, params } = this.props;
    let userType = 'user';
    if (login.user.is_admin) {
      userType = 'admin';
    }
    return (
      <ScrollToTop location={location}>
        <div className="container-scroller">
          {/* Adds notification */}
          {/* <MigrationNotification /> */}
          <Navbar
            Login={Login}
            login={login}
            location={location}
            user={userType}
            routesList={disbursementRoutes}
            params={params}
          />
          <div className="container-fluid page-body-wrapper sidebar-fixed">
            <Sidebar
              location={location}
              routesList={disbursementRoutes}
              params={params}
            />
            <div className="main-panel">
              <ViewType user={userType} routesList={disbursementRoutes} />
              <Footer />
            </div>
          </div>
        </div>
      </ScrollToTop>
    );
  }
}
const mapStateToProps = state => ({
  login: state.login,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
)(Home));
