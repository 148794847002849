import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SelectInput from "../../../components/SelectInput";
import DonationService from "../../../services/donation-service";
import TagsService from "../../../services/tag-service";

const CreateAdminDonationTags = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [donation, setDonation] = useState(props.item);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTagsOptions(
      tagsOptions.filter(({ value }) => !selectedTags.map((tag) => tag.value).includes(value))
    );
  }, [selectedTags]);

  useEffect(() => {
    const loadData = async () => {
      const tags = await TagsService.get(undefined, { status: "active" });
      const options = [];
      if (tags.data) {
        tags.data.map((tagItem) => {
          if (!donation.tags?.map((tag) => tag.id).includes(tagItem.id)) {
            options.push({
              value: tagItem._id,
              label: tagItem.title,
            });
          }
        });
      }
      setTagsOptions(options);
    };
    loadData();
  }, []);

  const handleSave = async () => {
    const { order_id } = props.item;
    setLoading(true);
    const res = await DonationService.updateTagsForDonation(
      { order_id },
      {
        tags: selectedTags.map(({ value }) => value),
      }
    );
    setDonation(res.donation);
    setSelectedTags([]);
    setLoading(false);
    setShowModal(false);
  };

  const handleDelete = async (tag) => {
    const { order_id } = props.item;
    if (window.confirm(`Are you sure to delete ${tag.name}?`)) {
      const res = await DonationService.deleteTagFromDonation({ order_id, tag_id: tag.id });
      setDonation(res.donation);
      setSelectedTags([]);
    }
  };

  return (
    <div class="col-md-12 mt-2">
      <div className="mr-2">
        <h6>
          Tags &nbsp;
          <button
            type="button"
            className="btn btn-rounded btn-inverse-outline-primary text-bold"
            style={{ padding: "8px 10px" }}
            onClick={() => setShowModal(true)}
          >
            <span>+ Add Tag</span>
          </button>
        </h6>
      </div>
      <div className="d-flex align-items-center flex-wrap">
        {donation?.tags?.map((tag, index) => (
          <div key={index} className="badge badge-pill ellipsis badge-primary mt-2">
            {tag.name}
            <a
              href="#/"
              onClick={() => handleDelete(tag)}
              style={{
                color: "white",
                textDecoration: "none",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              x
            </a>
          </div>
        ))}
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Tag to Donation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {selectedTags.length ? <h6 className="mb-0">Selected Tags</h6> : <></>}
            {selectedTags?.map((tag, index) => (
              <div key={index} className="badge badge-pill ellipsis badge-primary mt-2">
                {tag.label}
                <a
                  href="#/"
                  onClick={() => {
                    setSelectedTags(selectedTags.filter(({ value }) => tag.value !== value));
                  }}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}
                >
                  x
                </a>
              </div>
            ))}
          </div>
          <br />
          <div>
            <label>Select a Tag</label>
            <SelectInput
              options={tagsOptions}
              onChange={(e) => {
                setSelectedTags([...selectedTags, e]);
              }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Close
          </Button>
          <Button onClick={handleSave} variant="primary" disabled={!selectedTags.length || loading}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateAdminDonationTags;
