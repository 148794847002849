import React, { Component } from 'react';

import './SingleFileInput.css';

import Dropzone from 'react-dropzone';

import uploadImage from '../../resources/images/upload.png';

class SingleFileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  componentDidMount() {
  }

  addFile = (Files) => {
    const { onChange, data } = this.props;
    const File = Files[0];
    this.setState({ selected: File.name });
    if (onChange) {
      if(data) {
        onChange(File, data);
      } else {
        onChange(File);
      }
    }
  }

  render() {
    const { selected } = this.state;
    const { error, children, accept } = this.props;
    if (children) {
      return (
        <Dropzone onDrop={this.addFile} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {children}
            </div>
          )}
        </Dropzone>
      );
    }
    return (
      <div className="form-group">
        {error}
        <div className="input-group col-xs-12">
          <Dropzone onDrop={this.addFile} multiple={false} accept={accept}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="file-wrapper">
                <div className="file-message">
                  <input {...getInputProps()} />
                  <img className="file-icon" src={uploadImage} alt="upload icon" />
                  {selected ? (<p>Selected: {selected}</p>) : (<p>Or click to select file to upload</p>)}
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}

export default SingleFileInput;
