import React, { Component } from 'react';

class NonprofitInfoRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getStateData = (from) => {
    const { state } = this;
    const { value } = this.props;
    if (state[from[0]] && from.length === 1 && from[0] !== 'nonprofit') {
      return state[from[0]];
    }
    if (value && value[from[1]] && from.length === 2 && from[0] === 'nonprofit') {
      return value[from[1]];
    }
    return '';
  }

  render() {
    const { children } = this.props;
    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3 className="mr-b-20">Nonprofit General Details</h3>
              <h6>Name of the Organization</h6>
              <p className="text-muted">{this.getStateData(['nonprofit', 'name']) || 'Nonprofit name not available'}</p>
              <h6>Registered Name (as per the Registration Certificate/PAN Card)</h6>
              <p className="text-muted">{this.getStateData(['nonprofit', 'official_name']) || 'Name not available'}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NonprofitInfoRead;
