import React, { Component } from 'react';
import { Calendar } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './DateInput.css';

class DateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }  

  selectDate = (f) => {
    const { onChange } = this.props;    
    if (onChange) {
      onChange(f);
    }
  }  

  render() {
    const { value } = this.props;    
    return <Calendar className='date-picker' date={value} onChange={this.selectDate} />
  }
}

export default DateInput;
