import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import SingleFileUpload from '../SingleFileUpload/SingleFileUpload';

class NonprofitGoverningBodyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: {_id: props.value._id},
    };
  }

  componentDidMount() {
    if (!this.getInputData(['nonprofit', 'governing_body', 'strength']) && this.getInputData(['nonprofit', 'required_compliances', 'fbassured'])) {
      this.handelInputChange(['nonprofit', 'governing_body', 'strength'], { target: { value: 1 } });
    }
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    if (onSubmit) {
      onSubmit(changes);
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { value } = this.props;
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  render() {
    const { children } = this.props;
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">Governing body details</h3>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Do you have a Statutory Governing Body?</label>
                      <div>
                      <small>Board of Directors/Board of Trustees/Executive Committee can be considered</small>
                      </div>
                      {this.getInputErrors(['nonprofit', 'governing_body', 'available'])}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="GoverningBodyAvailable" value="Yes" checked={this.getInputData(['nonprofit', 'governing_body', 'available']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'available'], e); }} /> Yes <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="GoverningBodyAvailable" value="No" checked={this.getInputData(['nonprofit', 'governing_body', 'available']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'available'], e); }} /> No <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> What is the current strength of the body? </label>
                      {this.getInputErrors(['nonprofit', 'governing_body', 'strength'])}
                      <select className="form-control" value={this.getInputData(['nonprofit', 'governing_body', 'strength'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'strength'], e); }}>
                        <option disabled hidden value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* {this.shouldShowItemOnCompliance('giveassured') ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> If any Board member received remuneration what was the process adopted to determine the salary?</label>
                        {this.getInputErrors(['nonprofit', 'governing_body', 'salary_process'])}
                        <textarea className="form-control" placeholder="Please add 300 words about the process adopted" value={this.getInputData(['nonprofit', 'governing_body', 'salary_process'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'salary_process'], e); }} />
                      </div>
                    </div>
                  </div>
                ) : null} */}
                {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Current list of members comprising the body </label>
                      <div>
                      <small>Please upload document containing the current list of members of the body on your letterhead (please include Name and Year of Appointment)</small>
                      </div>
                      <div className="input">
                        <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'governing_body', 'composition'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'composition'], e); }} error={this.getInputErrors(['nonprofit', 'governing_body', 'composition'])} />
                      </div>
                    </div>
                  </div>
                </div> ) : null }
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> How many meetings of Board were held in the previous Financial Year</label>
                      {this.getInputErrors(['nonprofit', 'governing_body', 'number_of_meetings'])}
                      <input className="form-control" type="number" placeholder="" value={this.getInputData(['nonprofit', 'governing_body', 'number_of_meetings'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'number_of_meetings'], e); }} />
                    </div>
                  </div>
                </div>
                {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                    <label> Minutes of Meeting </label>
                    <div>
                    <small>Upload minutes of AGM held within the previous Financial Year or any latest meeting held within the previous Financial Year</small>
                    </div>
                      <div className="input">
                        <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'governing_body', 'minutes_of_meeting'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'minutes_of_meeting'], e); }} error={this.getInputErrors(['nonprofit', 'governing_body', 'minutes_of_meeting'])} />
                      </div>
                    </div>
                  </div>
                </div> ) : null }
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Was there a change in the Board composition as on date?</label>
                      {this.getInputErrors(['nonprofit', 'governing_body', 'composition_change_as_of_date'])}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="GoverningBodyChange" value="Yes" checked={this.getInputData(['nonprofit', 'governing_body', 'composition_change_as_of_date']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'composition_change_as_of_date'], e); }} /> Yes <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="GoverningBodyChange" value="No" checked={this.getInputData(['nonprofit', 'governing_body', 'composition_change_as_of_date']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'composition_change_as_of_date'], e); }} /> No <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {(this.getInputData(['nonprofit', 'governing_body', 'composition_change_as_of_date']) === 'Yes') && this.shouldShowItemOnCompliance('giveassured') ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Proof of change in Board of Directors</label>
                        <div className="input">
                          <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'governing_body', 'proof_of_composition'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'proof_of_composition'], e); }} error={this.getInputErrors(['nonprofit', 'governing_body', 'proof_of_composition'])} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null }
                {this.shouldShowItemOnCompliance('fbassured') ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Document mentioning authorised signatories’ names</label>
                      <div>
                      <small>Please upload document on your letterhead with attestation mentioning current authorised signatories’ names</small>
                      </div>
                      <div className="input">
                        <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'governing_body', 'approving_signatory'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'approving_signatory'], e); }} error={this.getInputErrors(['nonprofit', 'governing_body', 'approving_signatory'])} />
                      </div>
                    </div>
                  </div>
                </div> ) : null }
                {this.shouldShowItemOnCompliance('fbassured') ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> KYC details of members of the body</label>
                      <small>Fill details of upto 10 members</small>
                      {this.getInputErrors(['nonprofit', 'governing_body', 'members'])}
                      {this.getInputData(['nonprofit', 'governing_body', 'members']) && this.getInputData(['nonprofit', 'governing_body', 'members']).length > 0 ? (
                        this.getInputData(['nonprofit', 'governing_body', 'members']).map((k, i) => (
                          <div key={i}>
                            <div className="row">
                              <div className="col-md-3">
                                <p style={{ marginBottom: '3px' }}>Name </p>
                                <small>Please fill name as it appears on PAN Card</small>
                              </div>
                              <div className="col-md-3">
                                <p>Date of Birth</p>
                              </div>
                              <div className="col-md-3">
                                <p>Gender</p>
                              </div>
                              <div className="col-md-3">
                                <p>Position on Board</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                {this.getInputErrors(['nonprofit', 'governing_body', 'members', i, 'name'])}
                                <input className="form-control" type="text" placeholder="" value={this.getInputData(['nonprofit', 'governing_body', 'members', i, 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'members', i, 'name'], e); }} />
                              </div>
                              <div className="col-md-3">
                                {this.getInputErrors(['nonprofit', 'governing_body', 'members', i, 'date_of_birth'])}
                                <input className="form-control" type="text" placeholder="DD/MM/yyyy eg. 25/01/1980" value={this.getInputData(['nonprofit', 'governing_body', 'members', i, 'date_of_birth'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'members', i, 'date_of_birth'], e); }} />
                              </div>
                              <div className="col-md-3">
                                {this.getInputErrors(['nonprofit', 'governing_body', 'members', i, 'gender'])}
                                <select className="form-control" value={this.getInputData(['nonprofit', 'governing_body', 'members', i, 'gender'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'members', i, 'gender'], e); }}>
                                  <option disabled hidden value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                              <div className="col-md-3">
                                {this.getInputErrors(['nonprofit', 'governing_body', 'members', i, 'position'])}
                                <input className="form-control" type="text" placeholder="" value={this.getInputData(['nonprofit', 'governing_body', 'members', i, 'position'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'members', i, 'position'], e); }} />
                              </div>
                            </div>
                            <div className="row mr-t-20">
                              <div className="col-md-3">
                                <p>Remuneration</p>
                              </div>
                              
                              <div className="col-md-3">
                                <p>{i===0 ? 'PAN Card Number' : 'PAN/Passport Number'}</p>
                              </div>
                              <div className="col-md-3"> 
                                <p>{i===0 ? 'PAN Card Doc' : 'PAN/Passport Upload'}</p>
                              </div>
                              {
                                i === 0 ? (<div className="col-md-3">
                                            <p style={{ marginBottom: '3px' }}>Address ID Proof</p>
                                            <small>Please upload both sides of Aadhar Card</small>
                                        </div>) : null}
                             
                            </div>
                            <div className="row">
                              <div className="col-md-3">
                                {this.getInputErrors(['nonprofit', 'governing_body', 'members', i, 'remuneration'])}
                                <select className="form-control" value={this.getInputData(['nonprofit', 'governing_body', 'members', i, 'remuneration'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'members', i, 'remuneration'], e); }}>
                                  <option disabled hidden value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                              <div className="col-md-3">
                                {this.getInputErrors(['nonprofit', 'governing_body', 'members', i, 'id'])}
                                <input className="form-control" type="text" placeholder="Number" value={this.getInputData(['nonprofit', 'governing_body', 'members', i, 'id'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'members', i, 'id'], e); }} />
                              </div>
                              <div className="col-md-3">
                                <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'governing_body', 'members', i, 'id_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'members', i, 'id_doc'], e); }} error={this.getInputErrors(['nonprofit', 'governing_body', 'members', i, 'id_doc'])} />
                              </div>
                               {i === 0 ? (<div className="col-md-3">
                                    <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'governing_body', 'members', i, 'address_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'members', i, 'address_doc'], e); }} error={this.getInputErrors(['nonprofit', 'governing_body', 'members', i, 'address_doc'])} />
                                    </div> ) : null
                                }

                            </div>
                          </div>
                        )
                            
                        )
                      ) : (
                          <div className="row">
                            <div className="col-md-12">
                              <p className="text-muted"><i>Please answer the question - "What is the current strength of the body?" to enable this field!</i></p>
                        </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div> ) : null }
                {this.shouldShowItemOnCompliance('fbassured') ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Maximum possible strength of the body as per the Constitution of the organisation</label>
                      {this.getInputErrors(['nonprofit', 'governing_body', 'maximum_strength'])}
                      <input className="form-control" type="number" placeholder="" value={this.getInputData(['nonprofit', 'governing_body', 'maximum_strength'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'maximum_strength'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Minimum possible strength of the body as per the Constitution of the organisation</label>
                      {this.getInputErrors(['nonprofit', 'governing_body', 'minimum_strength'])}
                      <input className="form-control" type="number" placeholder="" value={this.getInputData(['nonprofit', 'governing_body', 'minimum_strength'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'minimum_strength'], e); }} />
                    </div>
                  </div>
                </div> ) : null }
                {this.shouldShowItemOnCompliance('fbassured') ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Memorandum of Association+Articles of Association/Trust Deed/By-laws</label>
                      <div className="input">
                        <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'governing_body', 'moa'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'moa'], e); }} error={this.getInputErrors(['nonprofit', 'governing_body', 'moa'])} />
                      </div>
                    </div>
                  </div>
                </div> ) : null }
                {this.shouldShowItemOnCompliance('giveassured') ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Quorum of Board Meetings, as stipulated in your Articles of Association/ Trust Deed</label>
                      {this.getInputErrors(['nonprofit', 'governing_body', 'quorum'])}
                      <input className="form-control" type="number" placeholder="" value={this.getInputData(['nonprofit', 'governing_body', 'quorum'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'governing_body', 'quorum'], e); }} />
                    </div>
                  </div>
                </div> ) : null }
              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default NonprofitGoverningBodyEdit;
