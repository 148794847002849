import React, { Component } from 'react';

import Icon from '@mdi/react';
import {
  mdiChevronRight,
  mdiChevronLeft,
} from '@mdi/js';

export default class FbOnboardDataAvailability extends Component {

  componentDidMount(){
		window.scrollTo(0, 0);
  }
  
  handleSubmit = async () => {
    const {state, addError, removeError, setData, onSubmit} = this.props
    const {dataAvailability} = state;
    for (var key of Object.keys(dataAvailability)) {
      if(dataAvailability[key] === '') {
        await addError('dataAvailabilityError', key, 'Add data')
      } else {
        await removeError('dataAvailabilityError', key)
      }
    }      
    if(this.props.state.error.dataAvailabilityError.length == 0) {
      onSubmit()      
    } 
  }
  render() {
    const { state, setData, goBack } = this.props
    const { error} = state

    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3 className="mr-b-20">Step 3 - Availability of Data</h3>
              <p>Please go through the data that will be made available by Facebook, once the non profit goes live and fundraising activity begins. We would recommend that you discuss this internally, especially with your finance team/auditor before you agree on the terms.</p>

              <div className="form-group row">
                <label className="col-md-12 col-form-label">Please note the details that will be shared by Facebook: - Name of donor, amount donated, date of donation, postal address (optional field, can be available if the donor has shared it), E-mail id (optional field, will only be shared if the donor chooses to share it).  No other information will be provided by the donor.</label>
                <div className="col-sm-12">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" className="form-check-input" id='sharing' value="yes" name="dataAvailabilitySharing" onChange={(e) => setData(e, 'dataAvailability')} 
                        checked={state.dataAvailability.dataAvailabilitySharing === "yes"}
                      /> Agree - We choose to go ahead with the onboarding <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" className="form-check-input" id='disagreeSharing' value="no" name="dataAvailabilitySharing" onChange={(e) => setData(e, 'dataAvailability')}
                      checked={state.dataAvailability.dataAvailabilitySharing === "no"}
                      /> Disagree - We will require more information about the donors. <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
                <span className="field-valid-error-row">{error.dataAvailabilityError.findIndex(o => o.key === 'dataAvailabilitySharing')>-1 && error.dataAvailabilityError[error.dataAvailabilityError.findIndex(o => o.key === 'dataAvailabilitySharing')].value}</span>
              </div>
              
              <div className="form-group row">
                <label className="col-md-12 col-form-label">
                  <p>The Non Profit will have to issue tax receipts to donors and Facebook or Give.do will not issue them on behalf of the Non Profit</p>
                  <small>Please check with your auditor/finance team. The donor data is collected on an optional basis. </small>
                </label>
                <div className="col-sm-12">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" className="form-check-input" id='receipt' value={true} name="dataAvailabilityReceipt" onChange={(e) => setData(e, 'dataAvailability')}
                       checked={state.dataAvailability.dataAvailabilityReceipt === true}
                      /> Agree <i className="input-helper"
                      ></i>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" className="form-check-input" id='disagreeReceipt' value={false} name="dataAvailabilityReceipt" onChange={(e) => setData(e, 'dataAvailability')} 
                        checked={state.dataAvailability.dataAvailabilityReceipt === false}
                      /> Disagree <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
                <span className="field-valid-error-row">{error.dataAvailabilityError.findIndex(o => o.key === 'dataAvailabilityReceipt')>-1 && error.dataAvailabilityError[error.dataAvailabilityError.findIndex(o => o.key === 'dataAvailabilityReceipt')].value}</span>
              </div>
              <p style={{color: 'red',fontSize: 'small',marginLeft: '12px'}}>{state.errorMessage}</p>
            </div>

            <span className="save-click-error">{(error.dataAvailabilityError.findIndex(o => o.key === 'dataAvailabilityReceipt')>-1 || error.dataAvailabilityError.length>0) && 'Please correct all the errors'}</span>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                    <button type="button" className="btn btn-outline-primary mr-2" onClick={goBack}>
                      <Icon path={mdiChevronLeft} className="menu-arrow" size={0.75} /> Back
                    </button>
                    <button type="submit" className="btn btn-primary mr-2" onClick={this.handleSubmit} disabled={!state.isEnable}>
                      <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" /> Next
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
