/*
  Note: This file contains the code and the related components for the New Onboarding Flow. 
  Based on the compliance fields within the nonprofit data, it fetched the form schema from the core service and generates a dynamic form. 
  Updated: 25 Feb 2020.
*/

// TODO: Improvements to be made, 
// • Fetch the form based on nonprofit.required_compliances field 
// • Removed empty array value before submitting the form 

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';

import * as LoginAction from '../../../reducers/loginReducer';
import * as ComplianceOnboarding from '../../../reducers/NGOOnboarding/homeReducer';
import * as NotificationAction from '../../../reducers/notificationReducer';
import * as ConfigAction from '../../../reducers/configReducer';

// Page Modules 
import ScrollToTop from '../../../appComponents/ScrollToTop';
import FormNav from '../FormNavigation';
import ActionStatus from '../../../appComponents/ActionStatus'

// Form Generation Component 
import Form from '../../../components/OnboardingComponents/Form'

import Helpers from '../../../utils/helpers'
import DynamicFormHelper from '../../../utils/dynamicform'
import _ from 'lodash'

class Onboarding extends Component{
  constructor(props){
    super(props)
    this.state = {
      schema: {}, // Schema received from API Call
      prevSchema: {}, // Replica of schema
      formValues: { // Form Values, for nonprofits and projects
        nonprofits: {},
        projects: {},
      },
      steps: {}, // Array of step details 
      previousStep: null, // Previous step key
      currentStep : null, // Currenct step key
      nextStep: null, // Next step key
      currentStepSchema: {}, // Schema of selected form Step Key
      profile: {}, // User profile
      prevProfile: {}, // Replica user profile
      requiredFields: [],
      formSchemaValidation: [],
      stepOnRoute: null,
      user: {},
      errors: {},
      thisnonprofit: {},
      thisproject: {},
      submittedForApproval: false,
      success_message: '',
      stepErrors: {}, 
      isNGOApproved: false,
      formErrors: false
    }
  }    

  componentDidMount(){
    const { ComplianceOnboarding, complianceOnboarding, login, Config, config, match, noParams } = this.props;
    const { prevProfile } = this.state;



    // Accepted Route for the page will /onboarding/:nonprofit/:compliance. Checking for the parameters to validate the case.    
    let nonprofit, compliance;
    if(noParams){
      nonprofit = complianceOnboarding.nonprofit._id;
      compliance = "givecompliant"
      this.setState({
        formValues: { ...this.state.formValues, nonprofits: Helpers.createNonprofitWithDiff(Helpers.cloneObject(complianceOnboarding.nonprofit)), projects: complianceOnboarding.project },
        thisnonprofit: Helpers.createNonprofitWithDiff(complianceOnboarding.nonprofit), 
      })
    }else{
      nonprofit = match.params.nonprofit;
      compliance = match.params.compliance
    }     

    if (!complianceOnboarding.get_status.nonprofit) {
    ComplianceOnboarding.getNonprofit().then(response => {
      let thisnonprofit = response.find(item => item._id === nonprofit)
        if(thisnonprofit !== undefined && !_.isEqual(thisnonprofit,this.state.formValues.nonprofit)){          

          if(thisnonprofit.approved_compliances && thisnonprofit.approved_compliances.length !== 0){
            const complianceApprovalCheck = thisnonprofit.approved_compliances.find(item => item.id === match.params.compliance)
            if(complianceApprovalCheck && Object.keys(complianceApprovalCheck).length !== 0){
              this.setState({
                isNGOApproved: true,
                success_message: `Your compliance onboarding/renewal request has been approved already. If you need to update any information for the Nonprofit, please log in to the profile page and update the information.`
              })
            }
          }

          if(thisnonprofit.submitted_compliances.indexOf(compliance) === -1){
            this.setState({
              formValues: {
                ...this.state.formValues, 
                nonprofits: Helpers.createNonprofitWithDiff(thisnonprofit) 
              }, 
              thisnonprofit: Helpers.createNonprofitWithDiff(thisnonprofit)
            })          
          }
          else{
            this.setState({
              submittedForApproval: true,
              success_message: `Thank you for filling in the information. We will get back to you with an update on the same. If you have entered incorrect information, or have any queries, please write to <a href="mailto:partners@give.do">partners@give.do<a>`
            })
          }
        }
        else if(thisnonprofit === undefined){
          // Redirect the USER to a 404 Page?
        }

        ComplianceOnboarding.getProject(thisnonprofit._id).then(response => {
          if(response.length >= 0){
            this.setState({
              formValues: {
                ...this.state.formValues,
                projects: Helpers.cloneObject(response[0])
              },
              thisproject: Helpers.cloneObject(response[0])
            })
          }
        }).catch(error => {
          console.error(error)
          // Do Nothing 
        })
      }).catch(error => {
        console.error(error)
        // Do Nothing 
      })
    }

    if (!config.get_status.config) 
      Config.get()

    if(login.user !== prevProfile) 
      this.setState({ 
        profile: login.user, 
        prevProfile: login.user 
      })

    if(!complianceOnboarding.get_status.schema)
      ComplianceOnboarding.getFormSchema({nonprofit: nonprofit, compliance: compliance})      
  }  

  static getDerivedStateFromProps(props, state) {    
    const setData = {};
    const { step } = props.match.params;
    
    // Checking for User login 
    if(props.login.user !== undefined && props.login.user !== state.user){
      setData.user = props.login.user 
    }
    
    // Checking if the schema is loaded, if loaded, assigning that to a state variable 
    if(props.complianceOnboarding.get_status.schema === 'success'){      
      if (props.complianceOnboarding.schema !== undefined && props.complianceOnboarding.schema !== state.prevSchema) {
        setData.prevSchema = props.complianceOnboarding.schema;
        setData.schema = { ...props.complianceOnboarding.schema };       
        if(Object.keys(setData.schema).length){  
          const steps = Helpers.getFormSteps(setData.schema, props.login.user.is_admin);
          if(steps){
            setData.steps = steps
            if(step === undefined){
              setData.currentStep = steps[0].key
              setData.nextStep = steps[1].key
              setData.previousStep = null
              const currentStepSchema = DynamicFormHelper.getThisFormSchema(setData.schema, setData.currentStep);                   
              if(currentStepSchema){
                setData.currentStepSchema = currentStepSchema
              }        
            }
            else{   
              setData.stepOnRoute = setData.currentStep = step
              const stepIndex = steps.findIndex(item => item.key === step)
              if(stepIndex !== -1){
                setData.nextStep = (stepIndex + 1) >= steps.length ? null : steps[stepIndex + 1].key
                setData.previousStep = stepIndex <= 0 ? null : steps[stepIndex - 1].key
                const currentStepSchema = DynamicFormHelper.getThisFormSchema(setData.schema, setData.currentStep);                 
                if(currentStepSchema){
                  setData.currentStepSchema = currentStepSchema
                }     
              }
              else{
                setData.errors = state.errors
                setData.errors['schema'] = 'Invalid Step Key'
              }
            }
          }
          setData.validations = DynamicFormHelper.getValidationsFromSchema(setData.schema);          
        }
      }
    }
    else{
      if(props.complianceOnboarding.errors && props.complianceOnboarding.errors.schema && !Helpers.isEqual(props.complianceOnboarding.errors.schema, state.errors.schema)){        
        setData.errors = state.errors
        setData.errors['schema'] = props.complianceOnboarding.errors.schema?.message
      }
    }      
    if(props.complianceOnboarding.errors 
      && props.complianceOnboarding.errors.nonprofit 
      && !Helpers.isEqual(props.complianceOnboarding.errors.nonprofit, state.errors.nonprofit)
    ){
      if(Object.keys(props.complianceOnboarding.errors.nonprofit).length !== 0){                
        setData.errors = state.errors                
        setData.requiredFields = props.complianceOnboarding?.errors?.nonprofit
      }
    }    
    

    if(props.complianceOnboarding.project !== state.thisproject){
      setData.thisproject = props.complianceOnboarding.project;
      setData.formValues = { ...state.formValues, projects: props.complianceOnboarding.project}
    }

    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
    
  }  


  getNextStep = (step) => {    
    let index = _.findIndex(this.state.steps, item => item.key === step)          
    if((index + 1) >= this.state.steps.length){
      return null
    }
    else{
      return this.state.steps[index + 1].key
    }
  }

  getPrevStep = (step) => {
    let index = _.findIndex(this.state.steps, item => item.key === step)
    if((index - 1) < 0){
      return null
    }
    else{
      return this.state.steps[index - 1].key
    }
  }
 
  onFormChange = (step) => {
    const stepSchema = DynamicFormHelper.getThisFormSchema(this.state.schema, step)        
    const nextStep = this.getNextStep(step)
    const prevStep = this.getPrevStep(step)
    this.setState({ 
      currentStepSchema: stepSchema, 
      currentStep: step, 
      nextStep: nextStep, 
      previousStep: prevStep 
    })
  }

  saveForm = (data, isSubmittingForApproval = false) => {    
    const { ComplianceOnboarding, match, noParams } = this.props 
    let compliance;
    if(noParams){
      compliance = "givecompliant"
    }else{
      compliance = match.params.compliance
    } 
    const { validations } = this.state

    // If form doesn't data just needs to be saved, set the data with the current status             
    let nonprofit_id

    const errors = Helpers.validateFormFields(data, validations?.required)    
    data = DynamicFormHelper.clearFormData(data)

    if(isSubmittingForApproval){
      this.setState({      
        formSchemaValidation: errors
      })   
      if(errors.length){
        this.getStepWiseErrors(errors)
      }
      else{
        this.setState({
          stepErrors: {}
        })
      }
    }
    else{
      this.setState({
        stepErrors: {}
      })
    }

    if(data.nonprofits){      
      const nonprofitDiffData = DynamicFormHelper.getObjectDiff(data.nonprofits, this.state.thisnonprofit)      
      if (data.nonprofits._id) {
        
        if(!isSubmittingForApproval){
          ComplianceOnboarding.setNonprofit({
            ...nonprofitDiffData,
            status: data.nonprofits.status
          }).then(response => {
            // DO Nothing
          }).catch(error => {                    
            if(error?.value?.errors){              
              const errors_transformed = DynamicFormHelper.transformAPIRequestErrors(error?.value?.errors)              
              this.setState({
                formErrors: errors_transformed
              })
              if(errors_transformed) this.getStepWiseErrors(Object.keys(errors_transformed))
            }
          })
        }
        else{
          if(errors.length === 0){
            ComplianceOnboarding.setNonprofit({
              ...nonprofitDiffData,
              status: data.nonprofits.status === 'pending' ? 'document_check' : data.nonprofits.status,  
              submitted_compliances: [...(data.nonprofits.submitted_compliances || []) , compliance]
            }).then(response => {
              this.setState({
                submittedForApproval: true,
                success_message: `Thank you for filling in the information. We will get back to you with an update on the same. If you have entered incorrect information, or have any queries, please write to <a href="mailto:partners@give.do">partners@give.do<a>`
              })
            }).catch(error => {
              if(error?.value?.errors){              
                const errors_transformed = DynamicFormHelper.transformAPIRequestErrors(error?.value?.errors)                
                this.setState({
                  formErrors: errors_transformed
                })
                if(errors_transformed) this.getStepWiseErrors(Object.keys(errors_transformed))
              }
            })
          }
          else{
            this.props.Notification.addNotification('Please resolve all errors before submitting the form')
          }          
        }
      } else {
        ComplianceOnboarding.createNonprofit(data.nonprofits).then(response => {
          nonprofit_id = response._id
        });
      }
    }
        
    //Removed data.nonprofits.status !== 'pending' not removed
    if(data.nonprofits.status !== 'published' &&  data.projects){
      if(data.projects._id){
        
        // Set Project 
        const project = DynamicFormHelper.getObjectDiff(data.projects, this.state.thisproject)        
        if(!isSubmittingForApproval){          
          ComplianceOnboarding.setProject(project)
        }
        else{
          // Set with final aproval status 
          if(errors.length === 0){
            ComplianceOnboarding.setProject({
              ...project,
              status: 'final_approval'
            })
          }
          else{
            this.props.Notification.addNotification('Please resolve all errors before submitting the form')
          }
        }
      }
      else{
        // Create Project         
        if(this.state.formValues.nonprofits && this.state.formValues.nonprofits._id){
          ComplianceOnboarding.createProject({
            ...data.projects,
            nonprofit: this.state.formValues.nonprofits._id
          })
        }
        else{
          console.error('Nonprofit Needs to be created first')
        }
      }
    }
  }

  getStepWiseErrors = (errors) => {  
    const stepErrors = {}
    errors.map(item => {      
      let stepKey = DynamicFormHelper.getStepDetailsfromKey(item, this.state.schema)
      if(stepKey === undefined) stepKey = item.replace('nonprofits.','').split('.')[0] + ' (not in this form)'
      if(stepErrors[stepKey]){
        stepErrors[stepKey].push(item)
      }
      else{
        stepErrors[stepKey] = [item]
      }      
    })

    this.setState({      
      stepErrors: stepErrors
    })
    
  }

  render(){
    const { 
      steps, 
      currentStep, 
      currentStepSchema, 
      nextStep, 
      previousStep, 
      formValues,
      requiredFields,
      formSchemaValidation,
      errors,
      submittedForApproval,
      success_message,
      stepErrors,
      isNGOApproved,
      formErrors
    } = this.state
  
    return (      
      <ScrollToTop location={{ pathname: `/`}}>
        <div className="container-scroller">          

          {(!submittedForApproval && !isNGOApproved) ? (
            <FormNav steps={steps} currentStep={currentStep}  noHeader={true}/>
          ): (
            <FormNav  noHeader={true}/>
          )}

          <div className="container-fluid page-body-wrapper">
            <div className="main-panel container">              
              <div className="content-wrapper">

                {Object.keys(stepErrors).length ? (
                  <React.Fragment>  
                    <div className="row justify-content-md-center">
                      <div className="col-md-12 form-alert warning grid-margin">
                        <div className="card">
                        <span>Some required fields don't have values entered in them. Rest of the fields have been saved. It's likely that some of the errors may not be part of this form. For those, please go to your profile and update information.<br/></span>
                        {Object.keys(stepErrors).length && <ul>
                          {Object.keys(stepErrors).map((item, index) => {                                                        
                            return (
                              <li key={index}>
                                <a                                   
                                  onClick={e => this.onFormChange(item)}
                                  href="#"
                                >
                                  {Helpers.formatText(item.replace(/_/g,' '))}
                                </a> 
                                &nbsp; has {stepErrors[item].length} error(s)
                              </li>
                            )                           
                        })}
                        </ul>}
                        </div>
                      </div>     
                    </div>               
                  </React.Fragment>                  
                ): null }
                {isNGOApproved === true ? (
                  <ActionStatus message={success_message}/>
                ) :
                submittedForApproval === true ? (
                  <ActionStatus message={success_message}/>
                ) : Object.keys(errors).length !== 0 ? (
                  <ActionStatus errors={errors}/>
                ): (
                  <Form
                    key={1}                                       
                    onChange={this.onFormChange} 
                    formSchema={currentStepSchema} 
                    step={currentStep}                     
                    values={formValues}
                    requiredFields={formSchemaValidation}                     
                    errors={formErrors || DynamicFormHelper.transformAPIRequestErrors(requiredFields)}                   
                    onSubmit={this.saveForm}
                    isAdmin={this.state.user.is_admin}
                    actions={{
                      stepchange:{
                        previous: previousStep,
                        next: nextStep,
                        // method: onStepChange
                      },
                      submit: {
                        label: 'Save'
                      }
                    }}
                  />
                )}                  
              </div>
            </div>
          </div>             
        </div>
      </ScrollToTop>
    )
  }

}

const mapStateToProps = state => ({
  login: state.login,
  complianceOnboarding: state.complianceOnboarding,
  config: state.config,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
  ComplianceOnboarding: bindActionCreators(ComplianceOnboarding, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
)(Onboarding));