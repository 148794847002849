import React from 'react';
import classnames from 'classnames';

import './FacebookOnboardingNav.css';

function FormNav(props) {
  const { form, steps, currentStep } = props;  
  return (    
    <div className="ob-step-container">
      <div className="fb-ob-step-wrapper">
        <div className="ob-steps">          
          {Object.keys(steps).map((step_name, index) => {
            return (
              <div className="fb-step-circle-outer" key={index}>
                <div className="fb-sb-left"></div>
                <div className={classnames('step-circle', { active: (currentStep === step_name) })}>{index + 1}</div>
              </div>
            )
          })}
        </div>
        <div className="fb-ob-step-title">
          {Object.values(steps).map((step_name,index) => {
            return (
              <span key={index}>
                <a onClick={(e) => { e.preventDefault(); }} href="#profile">
                  <span className="menu-title">{step_name}</span>
                </a>
              </span>
            )
          })}          
        </div>
      </div>
    </div>        
  );
}

export default FormNav;
