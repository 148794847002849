import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import { Divider, Grid, makeStyles } from "@material-ui/core";
import Spinner from 'react-bootstrap/Spinner';

const useStyles = makeStyles((theme) => ({
    uploadErrorMsg: {
        color: '#FF5050',
        fontSize: '12px'
    },
    tHeader: {
        marginTop: '10px',
        padding: '0.5em',
        background: '#e9e9ee',
        borderBottom: '1px solid #ccc'
    },
    tBody: {
        padding: '0.5em',
        borderBottom: '1px solid #ccc'
    },
    secBtn: {
        color: '#212529',
        backgroundColor: '#c0c2c3',
        borderColor: '#c0c2c3'
    },
    tCenter: {
        fontSize: '12px',
        justifyContent: 'center',
        padding: '1em 0'
    },
    tRight: {
        textAlign: 'right'
    }
}));
const Admin10BdBeUploadModal = ({ showModal, setShowModal, handleUpload, isUploading, latestUploadedFile,latestUploadedFileStatus }) => {
    const classes = useStyles()
    const inputRef10beSheet = useRef(null)
    const inputRef10beCertificate = useRef(null)
    const [fileInfo, setFileInfo] = useState([])
    const [uploadError, setUploadError] = useState({})
    const [finalSubmitEnabled, setFinalSubmitEnabled] = useState(false);
    const [isCsvUploaded, setIsCsvUploaded] = useState(false);

    const handleAdd = (e) => {
        setUploadError({})
        if (e.target.name === '10beSheet') {
            if (inputRef10beSheet.current) inputRef10beSheet.current.click()
        } else {
            if (inputRef10beCertificate.current) inputRef10beCertificate.current.click()
        }
    }
    const handleUploadFiles = (e) => {
        const uName = e.target.name
        const file = e.target.files[0];
        let errorMessage = ''
        if (file) {
            if (uName === '10beSheet') {
                if (!file.name.endsWith('.csv')) errorMessage = 'File type not supported'
            } else {
                if (!file.name.endsWith('.zip')) errorMessage = 'File type not supported'
            }
            if (file.size === 0) errorMessage = 'File cannot be empty'
            const fileSizeInKB = Math.round(file.size / 1000); // Get file size in kilobytes

            // 300mb
            if (fileSizeInKB >= 300000) errorMessage = 'File size exceeds 300 MB'

        }
        if (errorMessage) {
            const errObj = { field: uName, errorMessage }
            setUploadError(errObj)
        } else {
            setFileInfo((prevFileInfo) => [...prevFileInfo, { file, uploadedFile: uName }]);
        }
    }
    const getFileSizeInKB = (size) => {
        return `${Math.round(size / 1024)}Kb`;
    }
    const handleRemove = (uFile) => {
        setFileInfo((prevFileInfo) => prevFileInfo.filter(info => info.uploadedFile !== uFile));
        setIsCsvUploaded(false)
          // Clear file input value
        inputRef10beSheet.current.value = '';
        inputRef10beCertificate.current.value = '';
    };

    const handleSubmit = () => {
        handleUpload(fileInfo)
    }
    useEffect(() => {
        if (fileInfo) {
            const is10BESheetExits = fileInfo.some(info => info.uploadedFile === "10beSheet");
            const is10BECerificateEcist = fileInfo.some(info => info.uploadedFile === "10beCertificateFolder");
            if(isCsvUploaded) {
                setFinalSubmitEnabled(is10BECerificateEcist)
            } else {
                setFinalSubmitEnabled(is10BESheetExits && is10BECerificateEcist);
            }
        }
    }, [fileInfo]);

    // Resetting the uploaded files
    useEffect(() => {
        setFileInfo([])
    }, [showModal])

    // checking if csv file is uploaded
    useEffect(() => {
        let checkStatus = latestUploadedFileStatus === 'SUBMITTED' && latestUploadedFile?.hasOwnProperty('csvUri')
        setIsCsvUploaded(checkStatus);
      }, [latestUploadedFile]);

    return (
        <Modal
            show={showModal}
            size="md"
            onHide={() => { setShowModal(false); }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Upload 10BE Sheet & Certificate Folder</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '10px 25px' }}>
                <Grid container item style={{ marginBottom: 10 }}>
                    <Grid container>
                        <Grid item md={6}>
                            <input ref={inputRef10beSheet} type='file' id='file-upload' multiple={false} name='10beSheet' onChange={handleUploadFiles} className='d-none' />
                            <Button variant="primary" name='10beSheet' onClick={handleAdd}
                            disabled={fileInfo.some(info => info.uploadedFile === "10beSheet") || latestUploadedFileStatus === 'SUBMITTED'}
                            >Upload 10BE Sheet</Button><br />
                            <i>
                                <small className="text-muted">*Please upload only csv files</small>
                            </i>
                            {uploadError && uploadError.field === '10beSheet' && <div className={classes.uploadErrorMsg}>
                                {uploadError.errorMessage}
                            </div>}
                        </Grid>
                        <Grid item md={6} className={classes.tRight}>
                            <input ref={inputRef10beCertificate} type='file' id='file-upload' multiple={false} name='10beCertificateFolder' onChange={handleUploadFiles} className='d-none' />
                            <Button variant="primary" name='10beCertificateFolder' onClick={handleAdd}
                                disabled={fileInfo.some(info => info.uploadedFile === "10beCertificateFolder")}
                            >Upload Certificate Folder</Button>
                            <i>
                                <small className="text-muted">*Please upload only zip folder</small>
                            </i>
                            {uploadError && uploadError.field === '10beCertificateFolder' && <div className={classes.uploadErrorMsg}>
                                {uploadError.errorMessage}
                            </div>}
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <Grid container className={classes.tHeader}>
                            <Grid item md={6}>File Name</Grid>
                            <Grid item md={3}>Size</Grid>
                            <Grid item md={3}>Action</Grid>
                        </Grid>
                        {fileInfo && fileInfo.length ? fileInfo.map((_u) => (
                            <Grid container className={classes.tBody} key={_u.uploadedFile}>
                                <Grid item md={6}>{_u.file.name}</Grid>
                                <Grid item md={3}>{getFileSizeInKB(_u.file.size)}</Grid>
                                <Grid item md={3}>
                                    <Button variant="seconary" disabled={isUploading} className={classes.secBtn} onClick={() => handleRemove(_u.uploadedFile)}>Remove</Button>
                                </Grid>
                            </Grid>
                        ))
                            :
                            <Grid container className={classes.tCenter}>
                                - No Uplods -
                            </Grid>
                        }
                    </Grid>

                </Grid>
                <Divider style={{ marginBottom: 20 }} />
                <div>
                    <i>
                        <small className="text-muted">*Please upload both document to submit for approval</small>
                    </i>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={!finalSubmitEnabled || isUploading} onClick={handleSubmit} className='d-flex align-items-center m-auto'>
                    {isUploading ?
                        <>
                            <Spinner animation="border" size="sm" />
                            <span className='ml-2'>
                                Uploading...
                            </span>
                        </> :
                        'Submit For Approval'
                    }

                </Button>
            </Modal.Footer>
        </Modal >
    )
}
export default React.memo(Admin10BdBeUploadModal)