import React, { Component } from 'react';

import SingleFileUpload from '../SingleFileUpload/SingleFileUpload';

import Helpers from '../../utils/helpers';

class NonprofitLegalInformationEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: {_id: props.value._id},
    };
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    if (onSubmit) {
      onSubmit(changes);
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { value } = this.props;
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  render() {
    const { children } = this.props;
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">Legal information</h3>
                </div>
                <h4 className="mr-b-20">Registration &amp; Legal Information</h4>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Has your 12A ever been revoked?</label>
                      {this.getInputErrors(['nonprofit', 'legal', '12a_revoked'])}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="12a_revoked" value="Yes" checked={this.getInputData(['nonprofit', 'legal', '12a_revoked']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', '12a_revoked'], e); }} /> Yes <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="12a_revoked" value="No" checked={this.getInputData(['nonprofit', 'legal', '12a_revoked']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', '12a_revoked'], e); }} /> No <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.getInputData(['nonprofit', 'legal', '12a_revoked']) === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Please provide details of why it was revoked and when it was restored?</label>
                        {this.getInputErrors(['nonprofit', 'legal', '12a_revoked_reason'])}
                        <textarea className="form-control" type="text" placeholder="Please provide details of why it was revoked and when it was restored?" value={this.getInputData(['nonprofit', 'legal', '12a_revoked_reason'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', '12a_revoked_reason'], e); }}></textarea>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Provide details of pending litigation, by any party, against the organization, if any</label>
                      {this.getInputErrors(['nonprofit', 'legal', 'pending_litigation_organization'])}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="pending_litigation_organization" value="Yes" checked={this.getInputData(['nonprofit', 'legal', 'pending_litigation_organization']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'pending_litigation_organization'], e); }} /> Yes <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="pending_litigation_organization" value="No" checked={this.getInputData(['nonprofit', 'legal', 'pending_litigation_organization']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'pending_litigation_organization'], e); }} /> No <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.getInputData(['nonprofit', 'legal', 'pending_litigation_organization']) === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Upload a declaration form</label>
                        <div className="input">
                          <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'legal', 'pending_litigation_organization_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'pending_litigation_organization_doc'], e); }} error={this.getInputErrors(['nonprofit', 'legal', 'pending_litigation_organization_doc'])} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Provide details of pending litigation, by the organization against any party, if applicable</label>
                      {this.getInputErrors(['nonprofit', 'legal', 'pending_litigation_party'])}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="pending_litigation_party" value="Yes" checked={this.getInputData(['nonprofit', 'legal', 'pending_litigation_party']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'pending_litigation_party'], e); }} /> Yes <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="pending_litigation_party" value="No" checked={this.getInputData(['nonprofit', 'legal', 'pending_litigation_party']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'pending_litigation_party'], e); }} /> No <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.getInputData(['nonprofit', 'legal', 'pending_litigation_party']) === 'Yes' ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Upload a declaration form</label>
                        <div className="input">
                          <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'legal', 'pending_litigation_party_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'pending_litigation_party_doc'], e); }} error={this.getInputErrors(['nonprofit', 'legal', 'pending_litigation_party_doc'])} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <h4 className="mr-b-20 mr-t-20">Norms for 2020-21</h4>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Has your non-profit status been questioned under any income tax assessment?</label>
                      {this.getInputErrors(['nonprofit', 'legal', 'status_questioned'])}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="status_questioned" value="Yes" checked={this.getInputData(['nonprofit', 'legal', 'status_questioned']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'status_questioned'], e); }} /> Yes <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="status_questioned" value="No" checked={this.getInputData(['nonprofit', 'legal', 'status_questioned']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'status_questioned'], e); }} /> No <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Do you have a valid 80 G?</label>
                      {this.getInputErrors(['nonprofit', 'legal', '80g_valid'])}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="80g_valid" value="Yes" checked={this.getInputData(['nonprofit', 'legal', '80g_valid']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', '80g_valid'], e); }} /> Yes <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="80g_valid" value="No" checked={this.getInputData(['nonprofit', 'legal', '80g_valid']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', '80g_valid'], e); }} /> No <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label> Has your FCRA registration been revoked or suspended?</label>
                      {this.getInputErrors(['nonprofit', 'legal', 'fcra_revoked'])}
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="fcra_revoked" value="Yes" checked={this.getInputData(['nonprofit', 'legal', 'fcra_revoked']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'fcra_revoked'], e); }} /> Yes <i className="input-helper"></i></label>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-radio">
                            <label className="form-check-label">
                              <input type="radio" className="form-check-input" name="fcra_revoked" value="No" checked={this.getInputData(['nonprofit', 'legal', 'fcra_revoked']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'fcra_revoked'], e); }} /> No <i className="input-helper"></i></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.shouldShowItemOnCompliance('giveassured') ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Have you spent more than 20% of FCRA funds on overheads? </label>
                        {this.getInputErrors(['nonprofit', 'legal', 'half_fcra_funds_on_overheads'])}
                        <select className="form-control" value={this.getInputData(['nonprofit', 'legal', 'half_fcra_funds_on_overheads'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'half_fcra_funds_on_overheads'], e); }}>
                          <option disabled hidden value="">Select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="na">NA</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.shouldShowItemOnCompliance('giveassured') ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Are there material transactions involving conflict of interest between a Board/ Staff member of your organization, unless specifically approved in a Board meeting where the concerned member has not voted?</label>
                        {this.getInputErrors(['nonprofit', 'legal', 'transactions_conflict'])}
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-radio">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="transactions_conflict" value="Yes" checked={this.getInputData(['nonprofit', 'legal', 'transactions_conflict']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'transactions_conflict'], e); }} /> Yes <i className="input-helper"></i></label>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-radio">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="transactions_conflict" value="No" checked={this.getInputData(['nonprofit', 'legal', 'transactions_conflict']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'transactions_conflict'], e); }} /> No <i className="input-helper"></i></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.shouldShowItemOnCompliance('giveassured') ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> You are recording/will record donations received and expenses incurred from Give.do/Give USA funds separately, either as a separate cost centre or group in electronic form, or by maintaining a separate register for all such donations and expenses in physical form, so as to generate a current "utilization statement" at short notice.</label>
                        {this.getInputErrors(['nonprofit', 'legal', 'separate_fcra'])}
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-radio">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="separate_fcra" value="Yes" checked={this.getInputData(['nonprofit', 'legal', 'separate_fcra']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'separate_fcra'], e); }} /> Yes <i className="input-helper"></i></label>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-radio">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="separate_fcra" value="No" checked={this.getInputData(['nonprofit', 'legal', 'separate_fcra']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'separate_fcra'], e); }} /> No <i className="input-helper"></i></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.shouldShowItemOnCompliance('giveassured') ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> Are there any serious audit notes on any material point?</label>
                        {this.getInputErrors(['nonprofit', 'legal', 'serious_audit_notes'])}
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-radio">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="serious_audit_notes" value="Yes" checked={this.getInputData(['nonprofit', 'legal', 'serious_audit_notes']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'serious_audit_notes'], e); }} /> Yes <i className="input-helper"></i></label>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-radio">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="serious_audit_notes" value="No" checked={this.getInputData(['nonprofit', 'legal', 'serious_audit_notes']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'serious_audit_notes'], e); }} /> No <i className="input-helper"></i></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.shouldShowItemOnCompliance('giveassured') ? (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label> The NGO will submit Chartered Accountant certified Annual Fund Utilization Certificates for funds received in the financial year, through Give.do/ GiveUSA, as per timelines communicated by Give.do</label>
                        {this.getInputErrors(['nonprofit', 'legal', 'will_submit_fuc'])}
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-radio">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="will_submit_fuc" value="Yes" checked={this.getInputData(['nonprofit', 'legal', 'will_submit_fuc']) === 'Yes'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'will_submit_fuc'], e); }} /> Yes <i className="input-helper"></i></label>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-radio">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="will_submit_fuc" value="No" checked={this.getInputData(['nonprofit', 'legal', 'will_submit_fuc']) === 'No'} onChange={(e) => { this.handelInputChange(['nonprofit', 'legal', 'will_submit_fuc'], e); }} /> No <i className="input-helper"></i></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default NonprofitLegalInformationEdit;
