import React, { useState, useEffect } from 'react'
import Helpers from '../../utils/helpers'
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js'

const ActionStatus = React.memo(props => {

  const { errors } = props

  const [ title, setTItle ] = useState(null)
  const [ message, setMessage ] = useState(null)

  useEffect(() => {
    if(errors !== undefined){      
      if(Object.keys(errors).length === 1){
        if(Object.keys(errors)[0] === 'schema')
          setTItle('Onboarding/Renewals Form Error')
        else
          setTItle(`${Object.keys(errors)[0]} Error`)
        setMessage(Object.values(errors)[0])
      }
    }
  }, [errors])

  const formatTitle = (title) => {
    return Helpers.formatText(title)
  }

  return (
    <div className="row justify-content-md-center">
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            {errors !== undefined ? (
              <>                
                <h3 className="mr-b-20" style={{ textAlign: 'center' }}>                  
                  <Icon path={mdiAlertCircleOutline} className="menu-arrow" size={2} color="#FF5050" />
                  <br/><br/>
                  {formatTitle(title)}
                </h3>
                <hr/>
                <p style={{textAlign: 'center'}}>
                  {Helpers.formatText(message)}
                </p>                
              </>
            ): (
              <>
                <h3 className="mr-b-20" style={{ textAlign: 'center' }}>
                  <Icon path={mdiAlertCircleOutline} className="menu-arrow" size={2} color="#008c8c" />
                  <br/><br/>
                  Success                  
                </h3>
                <hr/>
                <p style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: props.message ? props.message : ''}}>                  
                </p> 
              </>
            )}
            
          </div>
        </div>
      </div>
    </div>  
  )
})

export default ActionStatus