import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import CampaignService from '../../services/campaign-service';

export const SET_CREATE_BULK_CAMPAIGN = 'SET_CREATE_BULK_CAMPAIGN';
export const SET_CREATE_BULK_CAMPAIGN_SUCCESS = 'SET_CREATE_BULK_CAMPAIGN_SUCCESS';
export const SET_CREATE_BULK_CAMPAIGN_FAIL = 'SET_CREATE_BULK_CAMPAIGN_FAIL';
export const SET_CREATE_BULK_CAMPAIGN_RESET = 'SET_CREATE_BULK_CAMPAIGN_RESET';

export const CREATE_BULK_CAMPAIGN_RESET = 'CREATE_BULK_CAMPAIGN_RESET';

const initialState = {
  bulk_campaign_response: {},
  get_status: {},
};

export const createBulkCampaignReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CREATE_BULK_CAMPAIGN:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          bulk: 'pending',
        },
      };
    case SET_CREATE_BULK_CAMPAIGN_SUCCESS:
      return {
        ...state,
        bulk_campaign_response: action.bulk_campaign_response,
        get_status: {
          ...state.get_status,
          bulk: 'success',
        },
      };
    case SET_CREATE_BULK_CAMPAIGN_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          bulk: 'failed',
        },
      };
    case SET_CREATE_BULK_CAMPAIGN_RESET:
      return {
        ...state,
        bulk_campaign_response: {},
        stats: [],
        get_status: {
          ...state.get_status,
          stat: null,
        }
      };
    case CREATE_BULK_CAMPAIGN_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function createBulkCampaign(data) {
  return (dispatch) => {
    dispatch({
      type: SET_CREATE_BULK_CAMPAIGN,
    });
    const request = {data, path: ['bulk']};
    return CampaignService.set(request)
      .then((response) => {
        dispatch({
          type: SET_CREATE_BULK_CAMPAIGN_SUCCESS,
          bulk_campaign_response: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: SET_CREATE_BULK_CAMPAIGN_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function syncReset(request) {
  return (dispatch) => {
    dispatch({
      type: SET_CREATE_BULK_CAMPAIGN_RESET,
    });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: CREATE_BULK_CAMPAIGN_RESET,
    });
  };
}
