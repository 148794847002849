import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { baseUrl } from '../../config';

const routerList = (props) => {
  const { user, routesList } = props;
  const returnRoutes = [];  
  routesList.sidebar.forEach((item) => {
    if (item.child) {
      item.child.forEach(child => {
        if (child.maskedChild) {
          child.maskedChild.forEach(cItem => {
            returnRoutes.push({
              path: cItem.path,
              component: cItem.component,
            });
          });
        }
        returnRoutes.push({
          path: child.path,
          component: child.component,
        });
      });
    } else {
      if (item.maskedChild) {
        item.maskedChild.forEach(cItem => {
          returnRoutes.push({
            path: cItem.path,
            component: cItem.component,
          });
        });
      }
      returnRoutes.push({
        path: item.path,
        component: item.component,
      });
    }
  });  
  return returnRoutes;
}

const ViewType = (props) => (
  <Switch>
    {routerList(props).map(item => (
      <Route
        key={item.path}
        exact
        path={item.path}
        component={item.component}
      />
    ))}
    <Route path="/admin/*" component={() => {global.window.location.href=`${baseUrl}/404`; return null; }} />
    <Route path="/approvals/*" component={() => {global.window.location.href=`${baseUrl}/404`; return null; }} />
    <Route path="/disbursements/*" component={() => {global.window.location.href=`${baseUrl}/404`; return null; }} />
    <Route path="/support/*" component={() => {global.window.location.href=`${baseUrl}/404`; return null; }} />
  </Switch>
);

export default ViewType;
