/**
 * Service function to make API call
 */
import memoize from 'memoize-one';

import _Error from '../utils/_error';
import API from '../utils/api';
import { endpoints } from '../config';
import Storage from '../utils/storage';
export default class NonProfitService {
  static requestAPI(endpoint = endpoints.nonprofits, request = {}) {
    return API.request(endpoint, request);
  }

  static cacheGet = memoize(this.requestAPI);

  static get(request) {
    // Note: Commented the code as there isn't a need to check the token from storage here
    // also define getlist of nonprofit
    const token = Storage.getItem('token');
    if (token) {      
      return this.cacheGet(undefined, request);
    }
    return Promise.reject(new _Error('', { status: 401 }));
  }

  static create(request) {
    return this.requestAPI(undefined, request);
  }

  static set(request) {
    return this.requestAPI(undefined, request);
  }

  static reject(request) {
    return this.requestAPI(undefined, request);
  }

  // Method to fetch schema of the form based on the request provided
  // TODO: Currently not passsing the required_compliances feild as a request, update this once picked up again 
  static getSchema(request){    
    const token = Storage.getItem('token')    
    if(token){
      return this.requestAPI({
        ...endpoints.onboardingFormSchema, 
        url: endpoints.onboardingFormSchema.url.replace(':nonprofit', request.nonprofit) + (request.compliance ? '?compliances=' + request.compliance : '')
      });
    }
    return Promise.reject(new Error('Cannot Fetch the Form, Unauthorised User', {status: 401}))
  }  

  static complianceRenewal(id, request){    
    const token = Storage.getItem('token')    
    if(token){
      return this.requestAPI({
        ...endpoints.renewal, 
        url: endpoints.renewal.url.replace(':nonprofit', id)
      }, request);
    }
    return Promise.reject(new Error('Cannot Renew Compliances, Unauthorised User', {status: 401}))
  }  
}
