import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Nav from '../Nav';

// import * as OnboardingNonprofitActions from '../../../reducers/Admin/adminNonprofitListReducer';
import *  as OnboardingNonprofitActions from '../../../reducers/NGOOnboarding/homeReducer';
import * as HomeActions from '../../../reducers/homeReducer';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiFloppy, mdiLoading } from '@mdi/js';
import './WelcomeOnboarding.css';

function WelcomeOnboarding(props) {
  const { home, Nonprofits, params } = props;
  const [ is12AChecked, handleCheck ] = useState(false);
  const [ nonprofitName, setNonprofitName ] = useState(null);
  const [ isFreshNGO, setIsFreshNGO ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if(home.nonprofits && home.nonprofits.length === 0){
      setIsFreshNGO(true);
    }else if(home.nonprofits && home.nonprofits.length >= 1){
      if(params && params.nonprofit){
        setNonprofitName(home.nonprofits.filter(nonprofit => nonprofit._id === params.nonprofit)[0].name);
      }
      else setNonprofitName(home.nonprofits[0].name)      
    }
  },[home, params])

  const handleSubmit = () => {
    setIsLoading(true);
    let path = '/onboarding'
    if(isFreshNGO){
      return Nonprofits.createNonprofit({name: nonprofitName}).then(response => {
        setIsLoading(false);                
        return window.location.href = path
        
      }).catch(error => {
        setIsLoading(false);
        console.error(error)
        return false
      })	
    }else{
      if(params && params.nonprofit) path += `/${params.nonprofit}`
      return window.location.href = path;
    }
  }


  return (
    <div className="container-scroller">
      <Nav form={null} />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel container">
          <div className="content-wrapper">
            <div className="row help-wrapper">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h3 className="mr-b-20">Welcome to Give.do: Initiating your on-boarding</h3>
                    <p className="text-muted">
                      You are about to initiate your registration process with Give.do. 
                      Please click on <span className="text-dark">'Proceed with on-boarding'</span> to continue.
                    </p>
                    <p className="text-muted">
                      If your organisation is already listed on Give.do, contact support on <a href="mailto:partnership@give.do">partnership@give.do</a> for the login credentials to your non-profit dashboard.
                    </p>
                    <p className="text-muted">
                      We request if you could ensure that all fields are completed to the best of your knowledge before submission. This shall assist us to publish your profiles at the earliest after you submit your form for review to our team.
                    </p>
                    <p className="text-muted">
                      Please feel free to reach-out to us on <a href="mailto:membership@give.do">membership@give.do</a> in case of any query. We're looking forward to having you as a part of our growing family.
                    </p>
                    {home.nonprofits.length /* && nonprofitName */ && !isFreshNGO ? 
                      <div className='form-group'>
                        <label>Name of your Organisation: <span className='welcome-ngo-name'>{nonprofitName}</span></label>
                      </div>
                      : null }
                    {isFreshNGO /*|| (home.nonprofits.length && !home.nonprofits[0].name)?*/ ? 
                      <div className='form-group'>
                        <label>Name of your Organisation</label>
                        <input type="text" className='form-control' placeholder='Registered name of the Nonprofit' onChange={(e) => setNonprofitName(e.target.value)}/>
                      </div>
                      : null }
                    <label className="checbox-wrapper">
                    <input type="checkbox" value={is12AChecked} onChange={(e) => handleCheck(e.target.checked)}/>
                      <p className="text-muted">Do you have 12A Certificate ?</p>
                      
                  </label>
                  </div>
                 
                </div>
              </div>
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                    <button type="button" className={`btn btn-success mr-2 ${is12AChecked && !isLoading && nonprofitName ? '': ' disabled-link'}`} name="save" onClick={() => handleSubmit()}>
                    {isLoading ? <Icon path={mdiLoading} size={0.75} color="#ffffff" spin /> : <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />}
                      &nbsp; Proceed with onboarding
                    </button>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  }

const mapStateToProps = state => ({
  nonprofits: state.onboardingNonprofit,
  home: state.home
});

const mapActionToProps = dispatch => ({
  Nonprofits: bindActionCreators(OnboardingNonprofitActions, dispatch),
  Home: bindActionCreators(HomeActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(WelcomeOnboarding);