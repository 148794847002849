import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as NotificationAction from '../../reducers/notificationReducer';
import './Login.css';

import * as LoginAction from '../../reducers/loginReducer';
import * as ConfigAction from '../../reducers/configReducer';

import { baseUrl, GOOGLE_CLIENT_ID, FACEBOOK_APP_ID, SUPPORT_EMAIL_ID } from '../../config';
import Storage from "../../utils/storage"

const loginMethods = [
  'EMAIL-OTP',
  'PHONE-OTP',
  'EMAIL-PASSWORD',
  'PHONE-PASSWORD',
  'SOCIAL-FACEBOOK',
  'SOCIAL-GOOGLE',    
]

const TNC_LINK = `${baseUrl}/terms-conditions`
const PRIVACY_LINK = `${baseUrl}/privacy`
const SUPPORT_ID = SUPPORT_EMAIL_ID

const availableDomains = ['*']

const LoginModule = React.lazy(() => import('@giveindia/loginmodule'))
class LoginContainer extends Component {

  constructor(props) {
    super(props);
  }

  onLoginSuccess = (response) => {
    const { Login } = this.props;
    Login.authoriseUser(response)
    if (response && response.token) {
      Storage.setItem('token', response.token);
    }
  }

  onLoginFailure = (response) => {
    const { Notification } = this.props;
    Notification.addNotification(response.error);
  }

  render() {
    const { login, location } = this.props;
    if(login.user_is_valid && window.location.search) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const redirect_url = urlParams.get('redirect')
      const nonprofit_details = urlParams.get('nonprofit_details')
      if(nonprofit_details){
        return (
          <Redirect to={`${redirect_url}&nonprofit_details=${nonprofit_details}`} /> 
        )
      }
      else{
        return (
          <Redirect to={redirect_url} />
        )
      }            
    }
    if (login.user_is_valid) {
      return (<Redirect to={''} />);
    }

    return (
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper auth-page">
          <div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
            <div className="row w-100 mx-auto">
              <div className="col-lg-4 mx-auto">
                <div className="auto-form-wrapper">
                  <div className="brand-logo">
                    <img src={'https://cfstatic.give.do/logo.png'} alt={"Give.do Logo"} className={'header_logo'}  />
                    </div>
                  <Suspense fallback={<div>Loading...</div>}>
                    <LoginModule
                      OTPLength={4}
                      baseURL={baseUrl}
                      loginMethods={loginMethods}
                      availableDomains={availableDomains}
                      identifier={null}            
                      onSuccess={this.onLoginSuccess}
                      onFailure={this.onLoginFailure}
                      tncLink={TNC_LINK}
                      privacyLink={PRIVACY_LINK}
                      supportId={SUPPORT_ID}  
                    />
                    </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  login: state.login,
  config: state.config,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(LoginContainer);
