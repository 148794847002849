import React from 'react';
import Icon from '@mdi/react';
import { mdiTelevision, mdiAutorenew, mdiFileCheck, mdiFileSend } from '@mdi/js';

import SyncDonation from '../../productComponents/Disbursements/SyncDonation';
import Disbursement from '../../productComponents/Disbursements/Disbursement';
import Settled from '../../productComponents/Disbursements/Settled';

const routes = {
  title: 'Disbursements',
  path: '/disbursements',
  default_path: '/disbursements/sync-donation',
  for_user: 'admin',
  sidebar: [{
    title: 'Sync Donation',
    path: '/disbursements/sync-donation',
    icon: (<Icon path={mdiAutorenew} className="menu-icon" size={0.75} color="#979797" />),
    component: SyncDonation,
  }, {
    title: 'Disbursement',
    path: '/disbursements/disbursement',
    icon: (<Icon path={mdiFileSend} className="menu-icon" size={0.75} color="#979797" />),
    component: Disbursement,
  }, {
    title: 'Settled',
    path: '/disbursements/settled',
    icon: (<Icon path={mdiFileCheck} className="menu-icon" size={0.75} color="#979797" />),
    component: Settled,
  }],
};

export default routes;
