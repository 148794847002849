import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './SingleFileUpload.css';

import Dropzone from 'react-dropzone';

import Icon from '@mdi/react';
import { mdiCancel, mdiEye, mdiTrashCanOutline } from '@mdi/js';

import * as NotificationAction from '../../reducers/notificationReducer';
import fileUpload from '../../utils/fileUpload';
import '../../components/Form/FormInput.css'

/*
 * @params - label
 * @params - url
 */

class SingleFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileStatus: 'pending',
      progress: 0,
      cancel: null,
      file: {},
      prevValue: '',
    };
  }

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setState({ fileStatus: 'uploaded', file: { uri: value } });
    } else {
      this.setState({ fileStatus: 'pending' });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    const { prevValue } = state;
    const setData = {};
    if(value !== prevValue) {
      setData.prevValue = value;
      if (value) {
        setData.fileStatus = 'uploaded';
        setData.file = { uri: value };
      } else {
        setData.fileStatus = 'pending';
      }
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  addFile = (Files) => {
    const { onChange, Notification } = this.props;
    const File = Files[0];
    // If file is not PDF then show notification.
    if (File) {
      this.setState({ fileStatus: 'uploading' });
      fileUpload.upload(File, { onUploadProgress: (e) => { this.onUploadProgress(e); }, cancelToken: (c) => { this.cancelToken(c); } }).then((response) => {
        const returnData = { uri: response.file_url, label: File.name };
        this.setState({ fileStatus: 'uploaded', progress: 0, file: returnData });        
        onChange(returnData.uri);
      }).catch((error) => {
        if (!(error instanceof Error)) {
          Notification.addNotification(error.message);          
        }
        onChange(null);
        this.setState({ fileStatus: 'pending', progress: 0 });
      });
    }
  }

  onUploadProgress = (e) => {
    const percent = e.loaded / e.total * 100;
    const progress = parseFloat(percent);
    if (progress > 0 && progress <= 100) {
      this.setState({ progress });
    } else {
      this.setState({ progress: 0 });
    }
  }

  cancelToken = (c) => {
    this.setState({ cancel: c });
  }

  deleteFile = (e) => {
    const { onChange } = this.props;
    e.preventDefault();
    this.setState({ fileStatus: 'pending', file: {}, progress: 0 });
    onChange(null);
  }

  fileHTML = () => {
    const { fileStatus, progress, cancel, file } = this.state;
    const { label, disabled } = this.props;
    switch (fileStatus) {
      case 'pending':
        return (
          <div className="input-group col-xs-12">
            <input type="text" className="form-control file-upload-info ufile-label" disabled placeholder={`Please click upload a to select file for ${label} (PDF/JPEG/PNG files only)`} />
            <Dropzone onDrop={this.addFile} multiple={false} disabled={disabled} accept={['application/pdf', 'image/jpeg', 'image/png']}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <span className="input-group-append">
                    <button className="file-upload-browse btn btn-info" disabled={disabled} type="button">Upload</button>
                  </span>
                </div>
              )}
            </Dropzone>
          </div>
        );
      case 'uploading':
        return (
          <div className="pbar-wrapper">
            <div className="progress progress-xl">
              <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                {progress.toFixed(2)}
                %
              </div>
            </div>
            <button type="button" className="btn btn-light" onClick={(e) => { e.preventDefault(); cancel(); }}>
              <Icon path={mdiCancel} className="menu-arrow" size={0.60} color="#212529" />
              &nbsp;Cancel
            </button>
          </div>
        );
      case 'uploaded':
        return (
          <div className="pbar-wrapper">
            {(file.label) ? (
              <div className="text-muted ufile-label-wrapper">
                <a href={file.uri} className="ufile-label" target="_blank">Uploaded file {file.label}</a>
                <a href={file.uri} className="preview-icon" target="_blank"><Icon path={mdiEye} className="menu-eye" size={0.60} color="#212529" /></a>
              </div>
            ) : (
              <div className="text-muted ufile-label-wrapper">
                <a href={file.uri} className="ufile-label" target="_blank">{label}</a>
                <a href={file.uri} className="preview-icon" target="_blank"><Icon path={mdiEye} className="menu-eye" size={0.60} color="#212529" /></a>
              </div>
            )}
            <div className="btn-wrapper">
              <button type="button" className="btn btn-light" onClick={this.deleteFile}>
                <Icon path={mdiTrashCanOutline} className="menu-arrow" size={0.60} color="#212529" />
                &nbsp;Delete
              </button>
            </div>
          </div>
        );
      default:
    }
  }

  render() {
    const { label, error, description, disabled } = this.props;
    return (
      <div className="form-group">
        {label ? (<label>{label}</label>) : null}
        {description ? (<><br/><small dangerouslySetInnerHTML={{__html: description}}></small></>) : null} 
        {/* // TODO Stlye Changes */}
        {error}
        {this.fileHTML()}
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapActionToProps = dispatch => ({
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(SingleFileUpload);
