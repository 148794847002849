import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import NonprofitTile from '../../../components/NonprofitTile';
import FilterByInput from '../../../components/FilterByInput';

import * as adminNonprofitListActions from '../../../reducers/Admin/adminNonprofitListReducer';

import './AdminNonprofitList.scss';
import Loading from '../../../appComponents/Loading';
import Helpers from '../../../utils/helpers';

class AdminNonprofits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {},
      filters:[{
        label: 'Search',
        type: [{
          type: 'default',
          label: 'Name',
          query: 'q',
        }, {
          type: 'text',
          label: 'POC Name',
          query: 'members.name',
        }, {
          type: 'email',
          label: 'POC Email',
          query: 'members.email',
        }, {
          type: 'phone',
          label: 'POC Phone',
          query: 'members.phone',
        }, {
          type: 'pan',
          label: 'PAN Number',
          query: 'pan_card',
        }, {
          type: 'text',
          label: 'City',
          query: 'address.city',
        }, {
          type: 'options',
          label: 'State',
          query: 'address.region',
          list : ["Andhra Pradesh", "Andaman and Nicobar Islands", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttarakhand", "Uttar Pradesh", "West Bengal"]
        }],
        placeholder: 'Search for Name, POC(Name, email, phone), PAN, City, State',
      }, {
        label: 'Status',
        type: 'list',
        query: 'status',
        list: [{label: 'Pending', value: 'pending'}, {label: 'Document Check', value: 'document_check'}, {label: 'Content Check', value: 'content_check'}, {label: 'Cause Check', value: 'cause_check'}, {label: 'Final Approval', value: 'final_approval'}, {label: 'Published', value: 'published'}, {label: 'Archived', value: 'archived'}, {label: 'Un Published', value: 'unpublished'}],
      }, {
        label: 'Created after',
        type: 'date',
        query: 'created_at',
        op: '>'
      }, {
        label: 'Created before',
        type: 'date',
        query: 'created_at',
        op: '<'
      }, {
        label: 'Updated after',
        type: 'date',
        query: 'updated_at',
        op: '>'
      }, {
        label: 'Updated before',
        type: 'date',
        query: 'updated_at',
        op: '<'
      }],
    };
  }

  componentDidMount() {
    const { Nonprofits } = this.props;
    Nonprofits.getNonprofits();
  }

  componentWillUnmount() {
    const { Nonprofits } = this.props;
    Nonprofits.reset();
  }

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshNonprofitList();
    });
  }

  refreshNonprofitList = () => {
    const { Nonprofits } = this.props;
    const { query } = this.state;
    Nonprofits.reset();
    Nonprofits.getNonprofits(query);
  }

  loadMore = () => {
    const { Nonprofits } = this.props;
    const { query } = this.state;
    Nonprofits.getNonprofits(query);
  }

  NonprofitListHTML = () => {
    const { nonprofits } = this.props;
    if (!nonprofits.get_status.nonprofit || nonprofits.get_status.nonprofit === 'pending' && nonprofits.nonprofits.length === 0) {
      return (<Loading />);
    }
    if (nonprofits.nonprofits.length === 0) {
      return(
        <i className="align-center">No items available</i>
      );
    }
    return nonprofits.nonprofits.map(item => (
      <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col" key={item.slug}>
        <NonprofitTile value={item} link={'/admin/nonprofits/'} />
      </div>
    ));
  }

  render() {
    const { nonprofits } = this.props;
    const { filters } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">NonProfit List</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} />
                <div className="row ngo-list-wrapper">
                  {this.NonprofitListHTML()}
                </div>
                <div className="row">
                  {(nonprofits.load_status.nonprofit) ? (
                    <div className="col-md-12">
                      <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                    </div>
                  ) : null}
                  {(nonprofits.get_status.nonprofit === 'pending' && nonprofits.nonprofits.length !== 0) ? (
                    <div className="col-md-12">
                      <Loading />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  nonprofits: state.adminNonprofitList,
});

const mapActionToProps = dispatch => ({
  Nonprofits: bindActionCreators(adminNonprofitListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(AdminNonprofits);
