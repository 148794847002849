import * as NotificationAction from './notificationReducer';
import CausesService from '../services/causes-service';

export const GET_CONFIG_CAUSES = 'GET_CONFIG_CAUSES';
export const GET_CONFIG_CAUSES_SUCCESS = 'GET_CONFIG_CAUSES_SUCCESS';
export const GET_CONFIG_CAUSES_FAIL = 'GET_CONFIG_CAUSES_FAIL';

export const GET_CONFIG_DEMOGRAPHICS = 'GET_CONFIG_DEMOGRAPHICS';
export const GET_CONFIG_DEMOGRAPHICS_SUCCESS = 'GET_CONFIG_DEMOGRAPHICS_SUCCESS';
export const GET_CONFIG_DEMOGRAPHICS_FAIL = 'GET_CONFIG_DEMOGRAPHICS_FAIL';



const initialState = {
 isFetching:false,
 causes:[],
 demographics:[]
};

export const causeAndDemographicReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CONFIG_CAUSES:
      return {
        ...state,
        isFetching:true,
      };
    case GET_CONFIG_CAUSES_SUCCESS:
      return {
        ...state,
        isFetching:false,
        causes:action.payload
      };
    case GET_CONFIG_CAUSES_FAIL:
      return {
        ...state,
        isFetching:false,
        causes:[]
      };
      case GET_CONFIG_DEMOGRAPHICS:
        return {
          ...state,
          isFetching:true,
        };
      case GET_CONFIG_DEMOGRAPHICS_SUCCESS:
        return {
          ...state,
          isFetching:false,
          demographics:action.payload
        };
      case GET_CONFIG_DEMOGRAPHICS_FAIL:
        return {
          ...state,
          isFetching:false,
          causes:[]
        };
    default:
      return state;
  }
};

export function getCauses() {
  return (dispatch) => {
    dispatch({
      type: GET_CONFIG_CAUSES,
    });
    return CausesService.getCauses()
      .then((response) => {
        dispatch({
          type: GET_CONFIG_CAUSES_SUCCESS,
          payload: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CONFIG_CAUSES_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}
export function getDemographics() {
    return (dispatch) => {
      dispatch({
        type: GET_CONFIG_DEMOGRAPHICS,
      });
      return CausesService.getDemographics()
        .then((response) => {
          dispatch({
            type: GET_CONFIG_DEMOGRAPHICS_SUCCESS,
            payload: response,
          });
          return response;
        })
        .catch((error) => {
          dispatch({
            type: GET_CONFIG_DEMOGRAPHICS_FAIL,
          });
          dispatch(NotificationAction.addNotification(error.message));
        });
    };
  }


