/*
 * fileUpload service function
 * responsible for uploading file to the server
 * endpont: object of config
 */

import axios from 'axios';
import _Error from './_error';
import Storage from './storage';
import API from './api';
import { endpoints } from '../config';


class fileUpload {
  static getS3URL(endpoint, file) {
    const arrayData = {
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        file_name: file.name,
        mime_type: file.type,
      },
      method: 'POST',
      auth: false,
      path: ['gets3url'],
    };
    return API.request(endpoint, arrayData);
  }

  static upload(file, array_data = {}) {
    return this.getS3URL(endpoints.file, file).then((response) => {
      const endpoint = {
        auth: false,
        url: response.data.url,
      };
      // Cache the file for a long time
      if(!array_data['headers']) {
        array_data['headers'] = {};
      }
      array_data['headers']['Cache-Control'] = 'public, max-age=31536000';
      return this.request(endpoint, file, array_data).then(() => response.data);
    });
  }

  static request(endpoint, file, array_data = {}) {
    let headers = {};
    if (array_data.headers) {
      headers = array_data.headers;
    }
    if (!headers['Content-Type']) {
      headers['Content-Type'] = file.type;
    }
    if (!headers.Accept) {
      headers.Accept = 'application/json';
    }

    const headerAuth = (array_data.auth !== undefined) ? array_data.auth : endpoint.auth;
    if (headerAuth) {
      const token = Storage.getItem('token');
      if (token) {
        headers.Authorization = `${token}`;
      }
    }

    let url = (array_data.path) ? endpoint.url + array_data.path.join('/') : endpoint.url;

    if (array_data.params !== null && array_data.params !== {} && array_data.params !== undefined) {
      let params;
      Object.keys(array_data.params).forEach((key) => {
        if (params !== undefined) {
          params = `${params}&${key}=${array_data.params[key]}`;
        } else {
          params = `${key}=${array_data.params[key]}`;
        }
      });
      url = `${url}?${params}`;
    }
    let method = 'put';
    if (array_data.method !== undefined) {
      method = array_data.method;
    }

    const req = {
      method,
      headers: (headers === {}) ? null : headers,
      data: file,
    };

    if (array_data.onUploadProgress) {
      req.onUploadProgress = array_data.onUploadProgress;
    }

    if (array_data.cancelToken) {
      const CancelToken = axios.CancelToken;
      req.cancelToken = new CancelToken(array_data.cancelToken);
    }

    return axios({ url, ...req })
      .then((response) => {
        let returnData = {};
        const statusCode = response.status;
        let headerContentType = null;
        switch (statusCode) {
          case 200:
          case 201:
          case 204:
            return response.data;
          case 401:
            headerContentType = response.headers['content-type'];
            if (headerContentType !== null && headerContentType.indexOf('application/json') >= 0) {
              returnData = response.data;
              returnData.status = response.status;
              return returnData;
            }
            return response.data();
          case 400:
          case 403:
          case 429:
          case 405:
            headerContentType = response.headers.get('content-type');
            if (headerContentType !== null && headerContentType.indexOf('application/json') >= 0) {
              if (response.data.error) {
                throw new _Error(response.data.error);
              } else if (response.data.errors) {
                throw new _Error('', response.data.errors);
              } else if (response.data.length > 0) {
                throw new _Error(response.data);
              } else {
                throw new _Error();
              }
            }
            throw new _Error();
          case 404:
            throw new _Error('', { location: '/not-found' });
          default:
            throw new _Error();
        }
      }).catch((response) => {
        if (response instanceof Error) {
          if (response.message === 'Failed to fetch') {
            throw new _Error('Please check your Internet connection');
          }
          if (response.message) {
            throw new _Error(response.message);
          }
          throw new _Error();
        } else if (!axios.isCancel(response)) {
          throw response;
        } else {
          throw new Error();
        }
      });
  }
}

export default fileUpload;
