/**
 * Created by nitinkushwaha on 29/08/16.
 */
class Storage {
  constructor() {
    this.MemoryStorage = {};
  }

  static setItem(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      this.MemoryStorage[key] = value;
    }
    return true;
  }

  static getItem(key) {
    try {
      localStorage.setItem('test', 'test'); /* For Safari incognito, since local storage is non existent */
      if (localStorage.getItem(key)) {
        return localStorage.getItem(key);
      }
    } catch (e) {
      if (this.MemoryStorage[key]) {
        return this.MemoryStorage[key];
      }
    }
    return null;
  }

  static removeItem(key) {
    try {
      localStorage.setItem('test', 'test'); /* For Safari incognito, since local storage is non existen t */
      localStorage.removeItem(key);
    } catch (e) {
      delete this.MemoryStorage[key];
    }
    return true;
  }
}

export default Storage;
