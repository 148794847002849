import React from 'react'
import classnames from 'classnames';


import UserDropdown from '../../../appComponents/UserDropdown';
import logo from '../../../resources/images/giveindia.svg';

import '../../../productComponents/NGOOnboarding/Nav/Nav.css'

const FormNavigation = (props) => {

  const { steps, currentStep, noHeader } = props;    

  return (
    <div>
      {noHeader ? 
      <nav className="navbar horizontal-layout col-lg-12 col-12 p-0">
        <div className="container d-flex flex-row nav-top mr-b-20">
          
          <div className="text-center navbar-brand-wrapper d-flex align-items-top">
            <a className="navbar-brand brand-logo" href="/">
              <img src={logo} alt="logo" />
            </a>
            <a className="navbar-brand brand-logo-mini" href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center">
            <UserDropdown />
          </div>
        </div>
      </nav>
       : null}
      {(steps && steps.length) ? (
            <div className="ob-step-container">
              <div className="ob-step-wrapper wide">
                <div className="ob-steps">
                  {steps.map((step, index) => {
                    return (
                      <div className="step-circle-outer" key={index}>
                        {/* For the first element, don't display the left bar  */}
                        {index > 0 && (<div className="sb-left"></div>)}
                        <div className={classnames('step-circle', { active: (currentStep === step.key) })}>{index + 1}</div>
                        {/* For the first element, don't display the right bar  */}
                        {index < (steps.length - 1) && (<div className="sb-right"></div>)}
                      </div>
                    )
                  })}                                    
                </div>
                <div className="ob-step-title">
                {steps.map((step, index) => {
                  return (
                    <span key={index}>
                      <a onClick={(e) => { e.preventDefault(); }} href="#profile">
                        <span className="menu-title">{step.title}</span>
                      </a>
                    </span>
                  )
                })}                  
                </div>
              </div>
            </div>
        ) : null}
    </div>
  )
}

export default FormNavigation