import React, { Component } from 'react';

import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronRight, mdiArrowDown, mdiArrowRight, mdiLoading } from '@mdi/js';

import Loading from '../../../appComponents/Loading';
import Failed from '../../../appComponents/Failed';
import SingleFileInput from '../../../components/SingleFileInput/SingleFileInput';
import { downloadPdf } from '../../../utils/helpers';

class TabPreDisbursement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
    };
  }

  componentDidMount() {
    const { disbursement, Disbursement } = this.props;
    if (!disbursement.get_status.pre_disbursement || disbursement.get_status.pre_disbursement !== 'pending') {
      Disbursement.getPreDisbursements();
    }
  }
  componentWillUnmount () {
    const { Disbursement } = this.props;
    Disbursement.resetTab();
  }

  downloadDisbursementBatch = (batchNo) => {
    const { Disbursement } = this.props;
    Disbursement.downloadDisbursementBatch(batchNo, 'preDisburseTab').then(response => {
      if(response && response.report_uri) {
        downloadPdf(response.report_uri);
      }
    });
  }

  approveDisbursementBatch = (batchNo) => {
    const { Disbursement, changeDisbursementTabs } = this.props;
    if (window.confirm('Do you want to approve the Disbursment batch?')) {
      Disbursement.approveDisbursementBatch(batchNo).then(response => {
        if (response && response.message) {
          changeDisbursementTabs('disbursement');
          const { disbursement, Disbursement } = this.props;
          if (!disbursement.get_status.pre_disbursement || disbursement.get_status.pre_disbursement !== 'pending') {
            Disbursement.getPreDisbursements();
          }
        }
      });
    }
  }

  deleteDisbursementBatch = (batchNo) => {
    const { Disbursement } = this.props;
    if (window.confirm('Do you want to delete the Disbursment batch?')) {
      Disbursement.deleteDisbursementBatch(batchNo);
    }
  }

  uploadSyncFile = (file, batchNo) => {
    const { Disbursement } = this.props;
    const data = new FormData();
    data.append('report', file);
    Disbursement.syncDisbursementBatch(data, batchNo, 'preDisburseTab').then((r) => {
      Disbursement.getPreDisbursements();
    });
  }

  actionsHTML = (batchNo) => {
    const { disbursement } = this.props;
    return(
      <div className="col-md-12 table-col dl-mob-right tl-btn-group">
        <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.downloadDisbursementBatch(batchNo); }}>
          {(disbursement.get_status.download === 'pending') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          )}
          &nbsp;Download
        </button>
        <SingleFileInput onChange={(e) => { this.uploadSyncFile(e, batchNo) }} data={batchNo}>
          <button type="button" className="btn btn-primary">
            {(disbursement.get_status.sync === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            )}
            &nbsp;Sync
          </button>
        </SingleFileInput>
        <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.approveDisbursementBatch(batchNo); }}>
          {(disbursement.get_status.approve === 'pending') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          )}
          &nbsp;Approve Disbursement
        </button>
        <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.deleteDisbursementBatch(batchNo); }}>
        {(disbursement.get_status.delete === 'pending') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          )}
          &nbsp;Delete
        </button>
      </div>
    );
  }

  syncHTML= (batchNo) => {
    const { disbursement } = this.props;
    const { data } = disbursement.sync;
    return (
      <div>
        <h5 className="mt-4 text-center">Successfully synced {data.updates.length} NGOs</h5>
        {data.errors.length ? (
          <table className="table table-bordered table-info">
            <thead>
              <tr>
                <th> Type </th>
                <th> Id </th>
                <th> Row </th>
                <th> Message </th>
              </tr>
            </thead>
            <tbody>
              {data.updates.map(item => (
                <tr>
                  <td> {item.type} </td>
                  <td> {item.disbursement_number ? item.disbursement_number : ''} {item.order_id ? item.order_id : ''} </td>
                  <td> {item.row} </td>
                  <td> {item.message} </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        <h5 className="mt-4 text-center text-danger">Problem in {data.errors.length} NGOs</h5>
        {data.errors.length ? (
          <table className="table table-bordered table-danger">
            <thead>
              <tr>
                <th> Type </th>
                <th> Id </th>
                <th> Row </th>
                <th> Message </th>
              </tr>
            </thead>
            <tbody>
              {data.errors.map(item => (
                <tr>
                  <td> {item.type} </td>
                  <td> {item.disbursement_number ? item.disbursement_number : ''} {item.order_id ? item.order_id : ''} </td>
                  <td> {item.row} </td>
                  <td> {item.message} </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    );
  }

  ngoHTML = (disbursement) => {
    return (
      <div className="col-md-12 table-col table-list-detail">
        <ul>
          <li className="table-head">
            <div className="row">
              <div className="col-md-6 col-12 table-col dl-mob">
                NGO Name
              </div>
              <div className="col-md-2 col-12 table-col dl-mob">
                Disbursement No
              </div>
              <div className="col-md-2 col-12 table-col dl-mob">
                No. of donations
              </div>
              <div className="col-md-2 col-12 table-col dl-mob txt-right">
                Amount
              </div>
            </div>
          </li>
          {disbursement.map(item => (
            <li className="table-list-item" key={item.disbursement_number}>
              <div className="row">
                <div className="col-md-6 col-12 table-col dl-mob">
                  <strong className="show-mobile">NGO Name</strong>
                  <span>{item.nonprofit.name}</span>
                </div>
                <div className="col-md-2 col-12 table-col dl-mob">
                  <strong className="show-mobile">Disbursement No</strong>
                  <span>{item.disbursement_number}</span>
                </div>
                <div className="col-md-2 col-12 table-col dl-mob">
                  <strong className="show-mobile">No. of donations</strong>
                  <span>{item.donation_count}</span>
                </div>
                <div className="col-md-2 col-12 table-col dl-mob txt-right">
                  <strong className="show-mobile">Amount</strong>
                  <span>{`${item.currency_code} ${item.payable_amount}`}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  toggleDetails = (batchNo) => {
    const { details } = this.state;
    if (details === batchNo) {
      this.setState({ details: null });
    } else {
      this.setState({ details: batchNo });
    }
  }

  preDisbursementHTML = () => {
    const { disbursement } = this.props;
    const { details } = this.state;
    return disbursement.pre_disbursements.map(item=>(
      <li className="table-list-item" key={item._id}>
        <div className="row">
          <div className="col-md-12 table-col">
            <div className="row">
              <div className="col-md-3 col-12 table-col tfirst-col dl-mob">
                <strong className="show-mobile">Disbursement Batch No</strong>
                {details ? (
                  <Icon path={mdiChevronDown} className="menu-icon" size={0.75} color="#979797" />
                ) : (
                    <Icon path={mdiChevronRight} className="menu-icon" size={0.75} color="#979797" />
                )}
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item._id}</span></a>
              </div>
              <div className="col-md-3 col-12 table-col dl-mob">
                <strong className="show-mobile">Disbursement range</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{`${item.from_date} - ${item.to_date}`}</span></a>
              </div>
              <div className="col-md-3 col-12 table-col dl-mob">
                <strong className="show-mobile">Pre-disbursement date</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item.created_at}</span></a>
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                <strong className="show-mobile">NGOs</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item.disbursements.length}</span></a>
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                <strong className="show-mobile">Entity</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item.payment_entity}</span></a>
              </div>
              <div className="col-md-1 col-12 table-col dl-mob txt-right">
                <strong className="show-mobile">Amount</strong>
                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{`${item.currency_code} ${item.payable_amount}`}</span></a>
              </div>
              <div className="col-12 table-col show-mobile">
                {(details === item._id) ? (
                  <a href="#details" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id); }}>Hide Nonprofit details</a>
                ) : (
                  <a href="#details" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id); }}>View Nonprofit details</a>
                )}
              </div>
              {(details === item._id) ? this.actionsHTML(item._id) : null}
              {(details === item._id && disbursement.sync.batchNo === item._id) ? this.syncHTML(item._id) : null}
              {(details === item._id) ? this.ngoHTML(item.disbursements) : null}
              <div className="show-mobile">
                {this.actionsHTML(item._id)}
              </div>
            </div>
          </div>
        </div>
      </li>
    ))
  }

  render() {
    const { children, disbursement } = this.props;
    return (
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            <div className="table-tab-wrapper">
              {children}
            </div>
            <div className="table-list">
              <ul>
                <li className="table-head">
                  <div className="row">
                    <div className="col-md-12 table-col">
                      <div className="row">
                        <div className="col-md-3 table-col dl-mob">Disbursement Batch No</div>
                        <div className="col-md-3 table-col dl-mob">Disbursement range</div>
                        <div className="col-md-3 table-col dl-mob">Pre-disbursement date</div>
                        <div className="col-md-1 table-col dl-mob">NGOs</div>
                        <div className="col-md-1 table-col dl-mob">Entity</div>
                        <div className="col-md-1 table-col txt-right">Payable amount</div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  {(!disbursement.get_status.pre_disbursement || disbursement.get_status.pre_disbursement === 'pending') ? (<Loading />) : null}
                  {(disbursement.get_status.pre_disbursement === 'failed') ? (<Failed />) : null}
                </li>
                {this.preDisbursementHTML()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TabPreDisbursement;
