import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { formatDistanceToNow, format } from 'date-fns';
import Icon from '@mdi/react';
import { mdiFilter } from '@mdi/js';

import Loading from '../../../appComponents/Loading';
import Helpers from '../../../utils/helpers';
import * as campaignSupportersAction from '../../../reducers/Crowdfunding/campaignSupportersReducer';

import FilterByInput from '../../../components/FilterByInput';

import './Campaigns.scss';

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDetails: '',
      query: {},
      filters: [{
        label: 'Search',
        type: 'text',
        query: 'q',
        placeholder: 'Search for Name',
      }, {
        label: 'Project',
        type: 'service',
        query: 'project._id',
        service: 'projects',
        placeholder: 'Type project name',
        ristrictions: { "nonprofit": props.match.params.nonprofit },
      }, {
        label: 'Created after',
        type: 'date',
        query: 'created_at',
        op: '>'
      }, {
        label: 'Created before',
        type: 'date',
        query: 'created_at',
        op: '<'
      }],
    };
  }

  componentDidMount() {
    const { supporters, Supporters, match } = this.props;
    const { nonprofit } = match.params;
    if (!supporters.get_status.donation) {
      Supporters.getCampaigns({ "nonprofit._id": nonprofit });
    }
  }

  componentWillUnmount() {
    const { Supporters } = this.props;
    Supporters.reset();
  }

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshCampaignList();
    });
  }

  addToFilter = (type, value) => {
    const { filters } = this.state;
    const newOption = { ...filters.find(i => i.label === type) };
    newOption.data = [{...value}];
    const newOptions = filters.map(item => (item.label === type) ? newOption : item);
    this.setState({ filters: newOptions, query: Helpers.queryBuilderForFilterInput(newOptions) }, () => {
      this.refreshCampaignList();
    });
  }

  addToFilterHTML = (type, value) => (
    <span className="filter-wrapper">
      <button type="button" className="btn btn-rounded btn-inverse-outline-primary btn-filters" onClick={(e) => { e.preventDefault(); this.addToFilter(type, value); }}>
        <Icon path={mdiFilter} size={0.5} color="#2196f3" /><span>Apply as filter</span>
      </button>
    </span>
  )

  refreshCampaignList = () => {
    const { Supporters, match } = this.props;
    const { query } = this.state;
    const { nonprofit } = match.params;
    Supporters.reset();
    Supporters.getCampaigns({ ...query, "nonprofit._id": nonprofit });
  }

  loadMore = () => {
    const { Supporters, match } = this.props;
    const { query } = this.state;
    const { nonprofit } = match.params;
    Supporters.getCampaigns({ ...query, "nonprofit._id": nonprofit });
  }

  showDetailsClick = (id) => {
    const { viewDetails } = this.state;
    if (id === viewDetails) {
      this.setState({ viewDetails: '' });
    } else {
      this.setState({ viewDetails: id });
    }
  }

  downloadCampaignList = (e) => {
    e.preventDefault();
    const { Supporters, match } = this.props;
    const { nonprofit } = match.params;
    const { query } = this.state;
    Supporters.getCampaignsForDownload({...query, "nonprofit._id": nonprofit }).then((data) => {
      const fileName = `GiveFundraisers-campaigns-${nonprofit}-${format(new Date(), 'HH:mm:ss')}`;
      const json = data.map((d) => {
        const returnData = ({
          "Date": format(new Date(d.created_at), 'do MMM yyyy'),
          "Time": format(new Date(d.created_at), 'HH:mm'),
          "Fundraiser ID": d._id || '',
          "Fundraiser Name": d.title || '',
          "Fundraiser Link": d.permalink || '',
          "Project ID": (d.project ? d.project._id : ''),
          "Project Name": (d.project ? d.project.title : ''),
          "Project Link": (d.project ? d.project.permalink : ''),
          "Creator Name": (d.creator ? d.creator.name : ''),
          "Creator Email": (d.creator ? d.creator.email : ''),
          "Creator Phone": (d.creator ? d.creator.phone : ''),
          "Days Left": (d.days_remaining ? d.days_remaining : ''),
          "Target Amount": (d.target_amount ? d.target_amount : ''),
          "Raised Amount": (d.statistic ? d.statistic.raised_amount : ''),
          "No of Supporters": (d.statistic ? d.statistic.supporter_count : ''),
          "Fundraiser Closed": (d.is_closed === true ? 'YES' : 'NO'),
          "Tags": d.tags ? d.tags.join(',  ') : '',
        });
        return returnData;
      });
      Helpers.convertJSONtoCSV(json, fileName);
    });
  }

  campaignListItemHTML = () => {
    const { supporters } = this.props;
    const { viewDetails } = this.state;
    if (!supporters.get_status.campaign || supporters.get_status.campaign === 'pending' && supporters.campaigns.length === 0) {
      return (<Loading />);
    }
    if (supporters.campaigns.length === 0) {
      return (
        <i className="align-center">No items available</i>
      );
    }
    return supporters.campaigns.map(item => (
      <li className="table-list-item" key={item.slug}>
        <div className="row">
          <div className="col-md-12 table-col">
            <div className="row">
              <div className="col-md-3 table-col dl-mob">
                <div className="feed-element">
                  <span className="pull-left">
                    <img alt="image" className="img-circle" src={item.creator.profile_pic} />
                  </span>
                  <div className="media-body">
                    <strong>{item.creator.name}</strong>
                    &nbsp;created -&nbsp;
                    <strong>
                      <a className="" target="_blank" href={item.permalink}>
                        {item.title}
                      </a>
                    </strong>
                    <br />
                    <small className="text-muted">{formatDistanceToNow(new Date(item.created_at))}</small>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 table-col dl-mob">
                {item.project ? (<div>
                  <a target="_blank" href={item.project.permalink}>{item.project.name}</a>
                  {this.addToFilterHTML('Project', { title: `Project: ${item.project.name}`, data: { "project._id": item.project._id } })}
                </div>) : null}
              </div>
              <div className="col-md-1 table-col dl-mob">
                <strong className="show-mobile">Days Left</strong>
                <span>&nbsp;{item.days_remaining}</span>
              </div>
              <div className="col-md-1 table-col dl-mob">
                <strong className="show-mobile">Target Amount</strong>
                <span>&nbsp;₹{item.target_amount?.toLocaleString('en-in')}</span>
              </div>
              <div className="col-md-1 table-col dl-mob">
                <strong className="show-mobile">Raised Amount</strong>
                <span>&nbsp;₹{item.statistic.raised_amount?.toLocaleString('en-in')}</span>
              </div>
              <div className="col-md-2 table-col dl-mob-right">
                {item.tags ? (item.tags.map(i => (
                  <div class="badge badge-pill ellipsis badge-primary">{item.tags}</div>
                ))) : null}
              </div>
              <div className="col-md-1 col-12 table-col dl-mob-right">
                {(viewDetails === item._id) ? (
                  <button type="button" className="btn btn-outline-secondary" onClick={(e) => { e.preventDefault(); this.showDetailsClick(item._id) }}>Hide Details</button>
                ) : (
                    <button type="button" className="btn btn-outline-secondary" onClick={(e) => { e.preventDefault(); this.showDetailsClick(item._id) }}>View Details</button>
                  )}
              </div>
            </div>
          </div>
          {(viewDetails === item._id) ? (
            <div className="col-md-12 disbursement-view-details">
              <div className="ibox float-e-margins">
                <div className="ibox-content ibox-heading">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <h6 className="left">Other details</h6> */}
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3 bold">Email:</div>
                        <div className="col-md-9 dl-mob">{item.creator?.email}</div>
                        <div className="col-md-3 bold">Is Team:</div>
                        <div className="col-md-9 dl-mob">{(item.is_team) ? 'YES' : 'NO'}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3 bold">Phone:</div>
                        <div className="col-md-9">{item.creator?.phone}</div>
                        {item.is_team ? (<>
                          <div className="col-md-3 bold">Team name :</div>
                          <div className="col-md-9">
                            {item.team_name}
                          </div>
                        </>) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </li>
    ));
  }

  render() {
    const { supporters } = this.props;
    const { filters } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Fundraisers</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} />
                <div className="row justify-content-end">
                  <div className="col-md-12 col-12 text-right">
                    <button type="button" className="btn btn-secondary" onClick={this.downloadCampaignList}>
                      {supporters.get_status.download === 'pending' ? 'Downloading...' : 'Export to CSV'}
                    </button>
                    <div>
                      <i><small className="text-muted">CSV file contains max of 1000 entries</small></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card-col col-xl-12 col-12">
                <div className="ibox-content">
                  <div className="table-list live-dis-table">
                    <ul>
                      <li className="table-head">
                        <div className="row">
                          <div className="col-md-12 table-col">
                            <div className="row">
                              <div className="col-md-3 table-col dl-mob">Fundraiser Name</div>
                              <div className="col-md-3 table-col dl-mob">Project</div>
                              <div className="col-md-1 table-col dl-mob">Days Left</div>
                              <div className="col-md-1 table-col dl-mob">Target Amount</div>
                              <div className="col-md-1 table-col dl-mob">Raised Amount</div>
                              <div className="col-md-2 table-col dl-mob">Tags</div>
                              <div className="col-md-1 table-col dl-mob">Details</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {this.campaignListItemHTML()}
                    </ul>
                    <div className="row">
                      {(supporters.load_status.campaign) ? (
                        <div className="col-md-12 text-center">
                          <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                        </div>
                      ) : null}
                      {(supporters.get_status.campaign === 'pending' && supporters.campaigns.length !== 0) ? (
                        <div className="col-md-12">
                          <Loading />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  supporters: state.crowdfundingCampaignSupporters,
});

const mapActionToProps = dispatch => ({
  Supporters: bindActionCreators(campaignSupportersAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Campaigns);
