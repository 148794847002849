import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

import NonprofitTile from '../../../components/NonprofitTile';
import FilterByInput from '../../../components/FilterByInput';

import * as adminNonprofit10BdBeListActions from '../../../reducers/Admin/admin10BdBeListReducer';

import '../AdminNonprofitList/AdminNonprofitList.scss';
import Loading from '../../../appComponents/Loading';
import Helpers from '../../../utils/helpers';

class Admin10BeBdNonprofits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {},
      filters: [
        {
          label: 'Search',
          type: [{
            type: 'default',
            label: 'Nonprofit Name',
            query: 'q',
          },],
          placeholder: 'Search for Nonrofit Name',
        },
        {
          label: 'Status',
          type: 'list',
          query: 'status',
          list: [{ label: 'Pending Approval', value: 'pending' }, { label: 'Approved', value: 'approved' }, { label: 'Downloaded', value: 'downloaded' }, { label: 'Pending Download', value: 'download-pending' }, { label: 'Rejected', value: 'rejected' }],
        }
      ],
      fYears: [
        { value: 'fy2023-2024', label: 'FY 2023-2024' }
      ],
      nonProfitsList:[]
    };
  }
  componentDidMount() {
    // const { Nonprofits } = this.props;
    // Nonprofits.getNonprofits();
    const filters = [
      {
        "label": "Search",
        "type": [
          {
            "type": "default",
            "label": "Nonprofit Name",
            "query": "q"
          }
        ],
        "placeholder": "Search for Nonrofit Name"
      },
      {
        "label": "Status",
        "type": "list",
        "query": "status",
        "list": [
          {
            "label": "Pending Approval",
            "value": "pending"
          },
          {
            "label": "Approved",
            "value": "approved"
          },
          {
            "label": "Downloaded",
            "value": "downloaded"
          },
          {
            "label": "Pending Downloaded",
            "value": "download-pending"
          },
          {
            "label": "Rejected",
            "value": "rejected"
          }
        ],
        "data": [
          {
            "title": "Status : Pending Approval",
            "data": {
              "status": "pending"
            }
          }
        ]
      }
    ]
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshNonprofitList();
    });
  }
 
  componentWillUnmount() {
    const { Nonprofits } = this.props;
    Nonprofits.reset();
  }

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters),nonProfitsList:[] }, () => {
      this.refreshNonprofitList();
    });
  }

  refreshNonprofitList = () => {
    const { Nonprofits } = this.props;
    const { query } = this.state;
    const searchQuery = { ...query, isSearch: query.q ? true : undefined }
    Nonprofits.reset();
    Nonprofits.getNonprofits(searchQuery).then(()=>{
    const { nonprofits } = this.props;
    this.setState({ nonProfitsList: nonprofits?.nonprofits10BdBe });
    })
  }

  loadMore = () => {
    const { Nonprofits } = this.props;
    const { query } = this.state;
    Nonprofits.getNonprofits(query).then(()=>{
      const { nonprofits } = this.props;
      this.setState({ nonProfitsList: nonprofits?.nonprofits10BdBe });
    })
  }

  NonprofitListHTML = () => {
    const { nonprofits } = this.props;
    const { query,nonProfitsList } = this.state;
    if (!nonprofits.get_status.nonprofit || (nonprofits.get_status.nonprofit === 'pending' && nonprofits.nonprofits?.nonprofits10BdBe?.length === 0)) {
      return (<Loading />);
    }
    if (nonProfitsList?.length === 0) {
      return (
        <i className="align-center">No items available</i>
      );
    }
    const handleApprove = async (status) => {
      const { Nonprofits } = this.props;
      await Nonprofits.reset()
      await Nonprofits.getNonprofits({ status }).then((res)=>{
        this.setState({ nonProfitsList: res.data },()=>{
          this.NonprofitListHTML()
        });
      });
     
    }
    return nonProfitsList.map(item => (
      <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col" key={item.slug}>
        <NonprofitTile value={item} link={'/admin/10bdbe/'} is10BdBe query={query} handleApprove={handleApprove} />
      </div>
    ));
  }

  render() {
    const { nonprofits } = this.props;
    const { nonprofit_count } = nonprofits || ''
    const { filters, fYears, query } = this.state;
    const isValidLength = nonprofits?.nonprofits10BdBe && nonprofits?.nonprofits10BdBe.length !== 0 && nonprofits?.nonprofits10BdBe.length % 10 === 0;
    const { status } = query || ''
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <h3 className="mr-b-20">10BD/BE</h3>
                  <div className="col-md-3">
                    <Select isMulti={false} defaultValue={fYears[0]} isDisabled options={fYears} />
                  </div>
                </div>
                <FilterByInput nonProfitCount={nonprofit_count} value={filters} onChange={this.filterOnChange} isOnlySearch={status === 'downloaded'} />
                {Object.keys(query).length === 0 &&
                  <button disabled={(status === 'downloaded' || status === '') || !status} type="button" className="btn btn-outline-primary btn-rounded btn-fw mr-3 mt-3"
                  >Status: Downloaded</button>

                }
                <div className="row ngo-list-wrapper">
                  {this.NonprofitListHTML()}
                </div>
                <div className="row">
                  {(isValidLength) ? (
                    <div className="col-md-12 text-center">
                      <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                    </div>
                  ) : null}
                  {(nonprofits.get_status.nonprofit === 'pending' && nonprofits.nonprofits?.nonprofits10BdBe.length !== 0) ? (
                    <div className="col-md-12 d-flex">
                      <Loading />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  nonprofits: state.adminNonprofit10BdBeList
});

const mapActionToProps = dispatch => ({
  Nonprofits: bindActionCreators(adminNonprofit10BdBeListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Admin10BeBdNonprofits);
