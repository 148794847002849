import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as NonprofitsActions from '../../../reducers/AdminApprovals/nonprofitSelfServeItemReducer';
import * as NotificationAction from '../../../reducers/notificationReducer';

import { Redirect } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiLoading, mdiFloppy, mdiConsoleNetwork } from '@mdi/js';

import Helpers from '../../../utils/helpers';

import Loading from '../../../appComponents/Loading';
import NonprofitBasicDetailsRead from '../../../components/NonprofitBasicDetailsRead';
import GalleryRead from '../../../components/GalleryRead';
import NonprofitDocumentsRead from '../../../components/NonprofitDocumentsRead';
import NonprofitPointOfContactRead from '../../../components/NonprofitPointOfContactRead';
import NonprofitGoverningBodyRead from '../../../components/NonprofitGoverningBodyRead';
import NonprofitLegalInformationRead from '../../../components/NonprofitLegalInformationRead';
import NonprofitOtherDetailsRead from '../../../components/NonprofitOtherDetailsRead';
import NonprofitSignatoryRead from '../../../components/NonprofitSignatoryRead';

class SelfserveNonprofitsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nonprofit: {},
      prevNonprofit: {},
      approvals: [],
      rejections: [],
    };
  }

  componentDidMount() {
    const { nonprofits, Nonprofits, match } = this.props;
    const { id } = match.params;
    if (!nonprofits.get_status.nonprofit) {
      Nonprofits.getNonprofit({ path: [id] });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.nonprofits.nonprofit !== state.prevNonprofit) {
      setData.prevNonprofit = props.nonprofits.nonprofit;
      setData.nonprofit = Helpers.createNonprofitWithDiff(props.nonprofits.nonprofit);
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  componentWillUnmount() {
    const { Nonprofits } = this.props;
    Nonprofits.reset();
  }

  onNonprofitSave = () => {
    const { Nonprofits, Notification } = this.props;
    const { nonprofit, approvals, rejections } = this.state;    
    Nonprofits.setNonprofitAP(nonprofit._id, { approve: approvals, reject: rejections }).then(() => {
      this.setState({ approvals: [], rejections: [] });
    }).catch((e) => {
      console.log("Errors are", "==>>", JSON.stringify(e));
    });
  }

  checkNonprofitCompliance = () => {
    const { Nonprofits, Notification } = this.props;
    const { nonprofit } = this.state;
    const approved_compliances = nonprofit.required_compliances.map(i => ({ id: i }));
    Nonprofits.checkNonprofitCompliance(nonprofit._id, { approved_compliances }).then(() => {
      console.log('Done');
  }).catch((e) => {
      console.log("Errors are", "==>>", JSON.stringify(e));
    });
  }

  approveAll = () => {
    const { nonprofit } = this.state;
    const { edits } = nonprofit;
    const approvals = edits.map((k, i) => i);
    this.setState({ approvals, rejections: [] }, () => { console.log('Approvals=>', this.state.approvals) });
  }
  rejectAll = () => {
    const { nonprofit } = this.state;
    const { edits } = nonprofit;
    const rejections = edits.map((k, i) => i);
    this.setState({ rejections, approvals: [] }, () => { console.log('Rejections=>', this.state.rejections) });
  }

  buttonListHTML = () => {
    const { nonprofits } = this.props;
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper" key="buttons">
          {/* <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.approveAll(); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            &nbsp;Mark all as Approved
          </button>
          <button type="button" className="btn btn-danger mr-2" onClick={(e) => { e.preventDefault(); this.rejectAll(); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            &nbsp;Mark all as Rejected
          </button> */}
          <button type="button" className="btn btn-primary mr-2" onClick={(e) => { e.preventDefault(); this.onNonprofitSave(); }} disabled={(this.state.approvals.length + this.state.rejections.length) < 1}>
            {(nonprofits.set_status.nonprofit === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
              )}
            &nbsp;Submit changes
          </button>
          <button type="button" className="btn btn-primary mr-2" onClick={(e) => { e.preventDefault(); this.checkNonprofitCompliance(); }}>
            {(nonprofits.set_status.compliance === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiConsoleNetwork} className="menu-arrow" size={0.75} color="#ffffff" />
              )}
            &nbsp;Check compliance
          </button>
        </div>
      );
  }

  approvalRejectionChange = (value, to, type) => {
    const { approvals, rejections } = this.state;
    if (type === 'add') {
      if (to === 'approvals') {
        this.setState({ approvals: [...approvals, ...value] }, () => { console.log('Approvals=>', this.state.approvals) });
      } else if (to === 'rejections') {
        this.setState({ rejections: [...rejections, ...value] }, () => { console.log('Rejections=>', this.state.rejections) });
      }
    } else if (type === 'remove') {
      if (to === 'approvals') {
        this.setState({ approvals: approvals.filter(i => !value.includes(i)) }, () => { console.log('Approvals=>', this.state.approvals) });
      } else if (to === 'rejections') {
        this.setState({ rejections: rejections.filter(i => !value.includes(i)) }, () => { console.log('Rejections=>', this.state.rejections) });
      }
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { nonprofit } = this.state;
    const compliance = nonprofit['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  errorHTML = () => {
    const { nonprofits } = this.props;
    const { nonprofit: errors } = nonprofits.errors;
    if (errors && errors.length > 0) {
      return (
        <div>
          {errors.map(i => (
            Object.values(i).map(j => (
              <div>
                {j}
              </div>
            ))
          ))}
        </div>
      );
    }
    return null;
  }

  whichForm = () => {
    const { nonprofit, prevNonprofit, approvals, rejections } = this.state;
    const { compliance } = nonprofit;
    let returnDOM = [];
    returnDOM.push(
      <NonprofitBasicDetailsRead key="basic" tabs={{ Approvals: nonprofit, Live: prevNonprofit }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} />
    );
    returnDOM.push(
      <GalleryRead key="gallery" tabs={{ Approvals: nonprofit, Live: prevNonprofit }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} />
    );
    if (this.shouldShowItemOnCompliance('fbassured|giveassured')) {
      returnDOM.push(
        <NonprofitDocumentsRead key="documents" tabs={{ Approvals: nonprofit, Live: prevNonprofit }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} />
      );
      returnDOM.push(
        <NonprofitPointOfContactRead key="poc" tabs={{ Approvals: nonprofit, Live: prevNonprofit }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} />
      );
      returnDOM.push(
        <NonprofitGoverningBodyRead key="governing" tabs={{ Approvals: nonprofit, Live: prevNonprofit }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} />
      );
      returnDOM.push(
        <NonprofitLegalInformationRead key="legal" tabs={{ Approvals: nonprofit, Live: prevNonprofit }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} />
      );
      returnDOM.push(
        <NonprofitOtherDetailsRead key="other" tabs={{ Approvals: nonprofit, Live: prevNonprofit }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} />
      );
    }

    if (this.shouldShowItemOnCompliance('fbassured')) {
      returnDOM.push(
        <NonprofitSignatoryRead key="signatory" value={nonprofit} />
      );
    }

    returnDOM.push(this.errorHTML());
    returnDOM.push(this.buttonListHTML());
    return (
      <div className="content-wrapper">
        {returnDOM}
      </div>
    );
  }

  render() {
    const { nonprofits } = this.props;
    if (!nonprofits.get_status.nonprofit || nonprofits.get_status.nonprofit === 'pending') {
      return (<Loading />);
    }
    if (!nonprofits.get_status.nonprofit || nonprofits.get_status.nonprofit === 'failed') {
      return (<Redirect to="/404" />);
    }
    return this.whichForm();
  }
}

const mapStateToProps = state => ({
  nonprofits: state.nonprofitSelfServeItem,
});

const mapActionToProps = dispatch => ({
  Nonprofits: bindActionCreators(NonprofitsActions, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(SelfserveNonprofitsItem);
