import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { OverlayTrigger, Form, Popover, Button, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import classnames from "classnames";
import { format } from "date-fns";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline, mdiChevronRight, mdiLoading, mdiFilter } from "@mdi/js";

import * as adminDonationListAction from "../../../reducers/Admin/adminDonationListReducer";
import * as eventAction from "../../../reducers/eventReducer";
import "./AdminDonationsList.scss";

import Loading from "../../../appComponents/Loading";
import FilterByInput from "../../../components/FilterByInput";
import Helpers, { downloadPdf, isDateCorrectFormat } from "../../../utils/helpers";
import CreateAdminDonationTags from "./CreateAdminDonationTags";
import ContactModalPopup from "./ContactModal";



class AdminDonationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDetails: "",
      eventList: [],
      query: {},
      currentTab: 'export',
      filters: [
        {
          label: 'Search',
          type: [{
            type: 'default',
            label: 'Name',
            query: 'q',
          }, {
            type: 'email',
            label: 'Email id',
            query: 'filter_by_user_email',
          }, {
            type: 'phone',
            label: 'Mobile number',
            query: 'filter_by_user_phone',
          }, {
            type: 'order',
            label: 'Order Id',
            query: 'filter_by_order_id',
          }, {
            type: 'disbursement',
            label: 'Disbursement Id',
            query: 'filter_by_disbursement_number',
          }],
          placeholder: 'Search for Name, Email, Phone, Order id, Disbursement id',
        }, {
          label: 'Created after',
          type: 'date',
          query: 'after',
        }, {
          label: 'Created before',
          type: 'date',
          query: 'before',
        }, {
          label: 'Status',
          type: 'list',
          query: 'filter_by_status',
          list: [{ label: 'Pending', value: 'PENDING' }, { label: 'Amount Confirmed', value: 'AMOUNTCONFIRMED' }, { label: 'User Confirmed', value: 'USERCONFIRMED' }, { label: 'Confirmed', value: 'CONFIRMED' }, { label: 'Disbursed', value: 'DISBURSED' }, { label: 'Settled', value: 'SETTLED' }, { label: 'Failed', value: 'FAILED' }, { label: 'Refunded', value: 'REFUNDED' }],
          filterType: 'multiple'
        }, {
          label: 'Nonprofit',
          type: 'service',
          query: 'filter_by_nonprofit',
          placeholder: 'Type nonprofit name',
          service: 'nonprofits'
        }, {
          label: 'Project',
          type: 'service',
          query: 'filter_by_project',
          placeholder: 'Type project name',
          service: 'projects'
        }, {
          label: 'Fundraiser',
          type: 'service',
          query: 'filter_by_campaign',
          placeholder: 'Type fundraiser title',
          service: 'campaigns'
        },
        {
          label: 'Event',
          type: 'list',
          query: 'filter_by_event',
        },
        {
          label: 'Settlement Id',
          type: 'text',
          query: 'filter_by_payout_id',
          placeholder: 'Enter Settlement ID or press Enter',
          allowed: '',
        }, {
          label: 'Entity::Gateway',
          type: 'custom',
        }],
      exportFilters: [],
      reportLinks: [],
      loader: false
    };
  }

  componentDidMount() {
    const { donation, Donation, event, Event } = this.props;
    if (!donation.get_status.donation) {
      Donation.getDonations();
    }
    if (!event.get_status.event) {
      Event.get().then((events) => {
        events &&
          events.length > 0 &&
          this.setState({
            eventList: events.map((event) => {
              return [
                {
                  label: event.name,
                  value: event.event_id,
                },
              ];
            }),
          });
      });
    }
  }

  componentDidUpdate() {
    let updatedFilters = this.state.filters;
    if (this.state.eventList.length > 0) {
      for (let e = 0; e < updatedFilters.length; e++) {
        if (updatedFilters[e].label === "Event") {
          updatedFilters[e].list = this.state.eventList.map((list) => {
            return list[0];
          });
        }
      }
      if (this.state.filters.filter((filter) => filter.label === "Event")[0].list.length === 0) {
        this.setState({
          filters: updatedFilters,
        });
      }
    }
  }
  componentWillUnmount() {
    const { Donation } = this.props;
    Donation.reset();
  }

  loadMore = () => {
    const { Donation } = this.props;
    const { query } = this.state;
    Donation.getDonations(query);
  };

  showDetailsClick = (id) => {
    const { viewDetails } = this.state;
    if (id === viewDetails) {
      this.setState({ viewDetails: "" });
    } else {
      this.setState({ viewDetails: id });
    }
  };

  refreshDonationList = () => {
    const { Donation } = this.props;
    const { query } = this.state;
    Donation.reset();
    Donation.getDonations(query);
  };

  reSyncDonation = (order_id) => {
    const { Donation } = this.props;
    Donation.getReSyncDonation(order_id);
  };

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshDonationList();
    });
  };

  fitersForExport = (filter) => {
    const { exportFilters } = this.state;
    if (!exportFilters.includes(filter))
      this.setState({ exportFilters: [...exportFilters, filter] });
    else
      this.setState({
        exportFilters: exportFilters.filter((exportFilters) => exportFilters !== filter),
      });
  };

  addToFilter = (type, value) => {
    const { filters } = this.state;
    const newOption = { ...filters.find((i) => i.label === type) };
    newOption.data = value;
    const newOptions = filters.map((item) => (item.label === type ? newOption : item));
    this.setState(
      { filters: newOptions, query: Helpers.queryBuilderForFilterInput(newOptions) },
      () => {
        this.refreshDonationList();
      }
    );
  };

  recursiveObjSearch = (obj, returnData) => {
    const rd = returnData;
    if (typeof obj === "object" && obj !== null) {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (typeof value !== "object") {
          rd[key] = value;
        } else if (typeof value === "object") {
          this.recursiveObjSearch(value, rd);
        }
      });
    }
    return rd;
  };

  generateReportLink = (e) => {
    e.preventDefault();
    this.setState({ loader: true })
    const { Donation } = this.props;
    const { query, exportFilters } = this.state;
    Donation.generateReportLink({ ...query }, exportFilters)?.then((data) => {
      if (data !== undefined) {
        Donation.getReportLinks().then((reportsData) => {
          this.setState({ reportLinks: reportsData, currentTab: 'downloads', loader: false });
        });
      }
      else {
        this.setState({ currentTab: 'export', loader: false });
      }
    });
  };

  getReportLinks = (e, isExport) => {
    e.preventDefault();
    const { Donation } = this.props;
    this.setState({
      loader: true
    })
    if (isExport) {
      this.setState({
        currentTab: isExport
      })
    }
    Donation.getReportLinks().then((data) => {
      setTimeout(() => {
        this.setState({
          loader: false
        })
      }, 2000);
      this.setState({ reportLinks: data });
    });
  };

  addToFilterHTML = (type, value) => (
    <span className="filter-wrapper">
      <button
        type="button"
        className="btn btn-rounded btn-inverse-outline-primary btn-filters"
        onClick={(e) => {
          e.preventDefault();
          this.addToFilter(type, value);
        }}
      >
        <Icon path={mdiFilter} size={0.5} color="#2196f3" />
        <span>Apply as filter</span>
      </button>
    </span>
  );

  onTabChange = (e, tab) => {
    this.setState({
      currentTab: tab
    })
    if (tab === 'downloads') {
      this.getReportLinks(e, 'downloads')
    }
  };



  popover = () => {
    const { donation } = this.props;
    const { reportLinks } = this.state;
    const fieldNames = {
      basic:
        "(Date, Time, Transaction ID, Status, Campaign ID, Campaign Name, Campaign Link, Fundraiser Name, Project ID, Project Name, Project Link, Donation Currency, Donation Amount, Donation Tip percent, Tip Amount, Retention Amount, Approx amount in INR, Donor ID, Donor City, Donor State, Donor Country, Referrer, Event, Subscription Type, Parent Donation, tags)",
      nonprofit: "(ID, Name, URL)",
      receipt: "(URL)",
      disbursement:
        "(Converted Currency, Converted Amount, Retention Amount, Payable Amount, Disbursement Number, Disbursement Date,  Settlement Date)",
      finance: "(Donor Name, Donor Mobile Number, Donor Email, Donor PAN, Donor Address Line 1, Donor Address Line 2, Donor Zipcode / Pincode, Payment Entity, Gateway, Transaction Reference, UTR Number, Payout Id, Payment Processor fee, Donor GST Number, Donor GST Address Line 1, Donor GST Address Line 2, Donor GST City, Donor GST Region, State Code, Donor GST Country, Donor GST Area Code, IP Address, Latitude, Longitude)",
    };
    return (
      <Popover id="popover-basic-1">
        <Popover.Content>
          <Row>
            <Col md={12} xs={12} className="export-download">
              <ul className="nav nav-tabs tab-basic" role="tablist">
                <li className="nav-item">
                  <a className={`${`nav-link ${this.state.currentTab === 'export' ? `active` : ''} show`}`} id="export-tab" data-toggle="tab" href="#export" onClick={(e) => this.onTabChange(e, 'export')} >Export</a>
                </li>
                <li className="nav-item">
                  <a className={`${`nav-link ${this.state.currentTab === 'downloads' ? `active` : ''} show`}`} id="download-tab" data-toggle="tab" href="#download" onClick={(e) => this.onTabChange(e, 'downloads')} >Downloads</a>
                </li>
              </ul>
              <div className="tab-content tab-content-basic">
                {
                  this.state.currentTab === 'export' ?
                    <div className="tab-pane fade show active">
                      <Col md={12} xs={12} className="export-filters">
                        <Popover.Title as="h3">Select Fields</Popover.Title>
                        <Form.Check className="popover-checkbox" type="checkbox" id="basic" label="Basic Details" disabled checked />
                        <div className="popover-checkbox-div" htmlFor="basic">{fieldNames.basic}</div>
                        <Form.Check className="popover-checkbox" type="checkbox" id="nonprofit" label="Nonprofit" onClick={() => this.fitersForExport('nonprofit')} />
                        <div className="popover-checkbox-div" htmlFor="nonprofit">{fieldNames.nonprofit}</div>
                        <Form.Check className="popover-checkbox" type="checkbox" id="receipt" label="Receipt" onClick={() => this.fitersForExport('receipt')} />
                        <div className="popover-checkbox-div" htmlFor="receipt">{fieldNames.receipt}</div>
                        <Form.Check className="popover-checkbox" type="checkbox" id="disbursement" label="Disbursement" onClick={() => this.fitersForExport('disbursement')} />
                        <div className="popover-checkbox-div" htmlFor="disbursement">{fieldNames.disbursement}</div>
                        <Form.Check className="popover-checkbox" type="checkbox" id="finance" label="Finance" onClick={() => this.fitersForExport('finance')} />
                        <div className="popover-checkbox-div" htmlFor="finance" >{fieldNames.finance}</div>
                        <Form.Check className="popover-checkbox" type="checkbox" label="Additional Fields" id="additional" onClick={() => this.fitersForExport('additional_fields')} style={{ marginTop: '10px' }} />
                        <div className="popover-checkbox-div" htmlFor="additional" ></div>
                        <div className="text-center">
                          <Button className="btn btn-sm" onClick={this.generateReportLink}>{donation.get_status.generate_report === '' ? 'Export to CSV' : donation.get_status.generate_report === 'pending' ? 'Generating...' : 'Export to CSV'}</Button>
                        </div>
                      </Col>
                    </div> :
                    <div className="tab-pane fade show active" >
                      <Col md={12} xs={12}>
                        <Popover.Title as="h3">
                          <span>Queued for download</span>
                          <button type="button" className="btn btn-inverse-link" onClick={this.getReportLinks}>
                            <div className="export">
                              <div className={`${this.state.loader ? `export-loader-spin` : `export-loader`}`}></div>
                            </div>
                          </button>
                        </Popover.Title>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Requested</th>
                              <th>Tags</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(!reportLinks || reportLinks.length === 0) &&
                              <i className="align-center">No items available</i>}
                            {reportLinks && reportLinks.map((link, index) => {
                              let filterAppl = link.queryParameter.filter;

                              return (
                                <tr key={index}>
                                  <td style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>
                                    <div className="download-tag-wrapper">
                                      <span className="download-tag">{format(new Date(link.created_at), "MMM do, yyyy HH:mm aa")}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="download-tag-wrapper">
                                      {['a'].map(item => (
                                        <>
                                          {
                                            filterAppl &&
                                            <>
                                              {filterAppl.endDate ? <span className="download-tag">Created before: {format(new Date(filterAppl.endDate), "MMM do, yyyy")}</span> : ''}
                                              {filterAppl.startDate ? <span className="download-tag">Created after: {format(new Date(filterAppl.startDate), "MMM do, yyyy")}</span> : ''}
                                              {filterAppl.gateway.length ? <span className="download-tag">Gateway: {filterAppl.gateway}</span> : null}
                                              {filterAppl.payment_entity.length ? <span className="download-tag">Payment Entity: {filterAppl.payment_entity}</span> : null}
                                              {filterAppl.settlementId.length ? <span className="download-tag">Settlement Id{filterAppl.settlementId}</span> : null}
                                              {filterAppl.projects.length ? <span className="download-tag">Project: {filterAppl.projects}</span> : null}
                                              {filterAppl.nonprofits.length ? <span className="download-tag">Nonprofit: {filterAppl.nonprofits}</span> : null}
                                              {filterAppl.campaigns.length ? <span className="download-tag">Fundraiser: {filterAppl.campaigns}</span> : null}
                                              {filterAppl.tag ? <span className="download-tag">Event: {filterAppl.tag}</span> : ''}
                                              {filterAppl.status.length ? <span className="download-tag">Status: {filterAppl.status}</span> : null}
                                            </>
                                          }
                                        </>
                                      ))}
                                    </div>
                                  </td>
                                  <td><a className={`btn btn-xs ${link.status === 'completed' ? 'btn-success' : 'disabled btn-secondary'}`} href={link.dataLink} download={`GiveFundraisers-live-${format(new Date(), 'HH:mm:ss')}`}>{link.status === 'completed' ? 'Download' : link.status}</a></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        {/* <ListGroup className="text-center">
                      {(!reportLinks || reportLinks.length === 0) &&
                        <i className="align-center">No items available</i>}
                      {reportLinks && reportLinks.map((link, index) => {
                        return (
                          <ListGroup.Item key={index}></ListGroup.Item>
                        )
                      })}
                    </ListGroup> */}
                      </Col>
                    </div>}
              </div>
            </Col>
          </Row>
          <Row></Row>
        </Popover.Content>
      </Popover>
    );
  };



  donationsHTML = () => {
    const { donation } = this.props;
    const { viewDetails } = this.state;
    if (
      !donation.get_status.donation ||
      (donation.get_status.donation === "pending" && donation.donations.length === 0)
    ) {
      return <Loading />;
    }
    if (donation.donations.length === 0) {
      return <i className="align-center">No items available</i>;
    }
    return donation.donations.map((item) => (
      <li className="table-list-item" key={item.order_id}>
        <div className="row">
          <div className="col-md-12 table-col">
            <div className="row">
              <div className="col-md-1 col-12 table-col dl-mob">
                {
                  item?.created_at && isDateCorrectFormat(item?.created_at) ?
                    <>
                      <small style={{ display: 'block' }}>{format(new Date(item?.created_at), 'do MMM yyyy')}</small>
                      <small style={{ display: 'block' }}>{format(new Date(item?.created_at), 'HH:mm')}</small>

                    </> : null
                }
              </div>
              {item.user ? (
                <div className="col-md-3 col-12 table-col dl-mob user-info-livedis">
                  <img alt="image" className="img-circle width-30" src={item.user.profile_pic} />
                  <div className="name-email">
                    <span>{item.user.name}</span>
                    <small>
                      {item.user.email}
                      {item.user.is_email_verified ? (
                        <Icon path={mdiCheckCircleOutline} size={0.75} color="#777777" />
                      ) : null}
                    </small>
                  </div>
                </div>
              ) : (
                <div className="col-md-3 col-12 table-col dl-mob user-info-livedis">
                  User Not Available
                </div>
              )}
              <div className="col-md-4 col-12 table-col dl-mob">
                {item.nonprofit ? (
                  <div className="row">
                    <div className="col-md-12">
                      <strong>(N) </strong> <a target="_blank" href={item.nonprofit.permalink}>{item.nonprofit.name}</a>
                      {this.addToFilterHTML('Nonprofit', [{ title: `Nonprofit: ${item.nonprofit.name}`, data: { filter_by_nonprofit: item.nonprofit._id } }])}
                    </div>
                    {item.project ? (<div className="col-md-12">
                      <strong>(P) </strong> <a target="_blank" href={item.project.permalink}>{item.project.name}</a>
                      {this.addToFilterHTML('Project', [{ title: `Project: ${item.project.name}`, data: { filter_by_project: item.project._id } }])}
                    </div>) : null}
                    {item.campaign ? (<div className="col-md-12">
                      <strong>(C) </strong> <a target="_blank" href={item.campaign.permalink}>{item.campaign.title}</a>
                      {this.addToFilterHTML('Fundraiser', [{ title: `Fundraiser: ${item.campaign.title}`, data: { filter_by_campaign: item.campaign._id } }])}
                    </div>) : null}
                  </div>
                ) : null}
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                <div>
                  {item.currency_code}&nbsp;{item.amount}
                </div>
                <div>
                  <small>
                    {item.retention_percent !== undefined
                      ? `Retention %: ${item.retention_percent}`
                      : null}
                  </small>
                </div>
                <div>
                  <small>{item.tip_percent !== 0 ? `Tip %: ${item.tip_percent}` : null}</small>
                </div>
              </div>
              <div className="col-md-2 col-12 table-col dl-mob user-info-livedis">
                <div
                  className={classnames(
                    "badge badge-pill ellipsis",
                    { "badge-warning": item.status === "USERCONFIRMED" },
                    { "badge-dark": item.status === "AMOUNTCONFIRMED" },
                    { "badge-success": item.status === "CONFIRMED" },
                    { "badge-info": item.status === "DISBURSED" || item.status === "SETTLED" },
                    { "badge-danger": item.status === "FAILED" || item.status === "REFUNDED" },
                    { "badge-primary": item.status === "PENDING" || item.status === "PENDING" }
                  )}
                >
                  {item.status}
                </div>
                {this.addToFilterHTML("Status", [
                  { title: `Status: ${item.status}`, data: { filter_by_status: item.status } },
                ])}
              </div>
              <div className="col-md-1 col-12 table-col dl-mob-right">
                {viewDetails === item.order_id ? (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showDetailsClick(item.order_id);
                    }}
                  >
                    Hide Details
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showDetailsClick(item.order_id);
                    }}
                  >
                    View Details
                  </button>
                )}
              </div>
            </div>
          </div>
          {viewDetails === item.order_id ? (
            <div className="col-md-12 disbursement-view-details">
              <div className="ibox float-e-margins">
                <div className="ibox-content ibox-heading">
                  <div className="row">
                    <div className="col-md-12">
                      <h6 className="left">Other details</h6>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4 bold">Order Id :</div>
                        <div className="col-md-8 dl-mob">{item.order_id}</div>
                        <div className="col-md-4 bold">Preferred Tax Exemption :</div>
                        <div className="col-md-8 dl-mob">
                          {item.user && item.user.preferred_tax_exemption ? (
                            <span>{item.user.preferred_tax_exemption}</span>
                          ) : (
                            "NA"
                          )}
                        </div>
                        <div className="col-md-4 bold">Mobile No :</div>
                        <div className="col-md-8 dl-mob">
                          {item.user && item.user.phone ? (
                            <span>
                              {item.user.phone}{" "}
                              {item.user.is_phone_verified ? (
                                <Icon path={mdiCheckCircleOutline} size={0.75} color="#777777" />
                              ) : null}
                            </span>
                          ) : (
                            "NA"
                          )}
                        </div>
                        <div className="col-md-4 bold">Donor GST #:</div>
                        <div className="col-md-8 dl-mob">
                          {(item.user && item.user.gst_details?.gst_number) ? (
                            <span>
                              {item.user.gst_details.gst_number}
                            </span>
                          ) : 'NA'
                          }
                        </div>
                        <div className="col-md-4 bold">Address :</div>
                        <div className="col-md-8">
                          {item.user && item.user.address ? item.user.address.formatted : "NA"}
                        </div>
                        <div className="col-md-4 bold">Is Anonymous :</div>
                        <div className="col-md-8 dl-mob">{item.is_anonymous ? "YES" : "NO"}</div>

                        <div className="col-md-4 bold">Receipt :</div>
                        {item.receipt_uri ? (
                          <div className="col-md-8">
                            <div
                              onClick={() => {
                                downloadPdf(item.receipt_uri)
                              }}
                              className="download-title"
                            >
                              Download
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-8">{item.receipt_reason}</div>
                        )}
                        <div className="col-md-4 bold">Invoice :</div>
                        {item.invoice_uri ? (
                          <div className="col-md-8">

                            <div
                              onClick={() => {
                                downloadPdf(item.invoice_uri)
                              }}
                              className="download-title"

                            >
                              Download Tip Invoice
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-8">Not Available</div>
                        )}
                        <div className="col-md-4 bold">Is Supercampaign:</div>
                        <div className="col-md-8">
                          {item?.supercampaign && Object.keys(item.supercampaign).length > 0 ? 'Yes' : 'No'}
                        </div>
                        <div className="col-md-4 bold">Is Assigned:</div>
                        <div className="col-md-8">
                          {item?.supercampaign && Object.keys(item.supercampaign).length > 0 && item?.supercampaign?.is_assigned ? 'Yes' : 'No'}
                        </div>
                      </div>

                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4 bold">Entity::Gateway :</div>
                        <div className="col-md-8 dl-mob">
                          {item.gateway && item.payment_entity ? (
                            <span>{`${item.payment_entity}::${item.gateway}`} </span>
                          ) : (
                            "NA"
                          )}
                        </div>
                        <div className="col-md-4 bold">Settlement Id :</div>
                        <div className="col-md-8 dl-mob">
                          {item.payout_id ? <span>{item.payout_id} </span> : "NA"}
                        </div>
                        <div className="col-md-4 bold">Disbursement number :</div>
                        <div className="col-md-8 dl-mob">
                          {item.disbursement_number ? (
                            <span>{item.disbursement_number} </span>
                          ) : (
                            "NA"
                          )}
                        </div>
                        <div className="col-md-4 bold">Disbursement date :</div>
                        <div className="col-md-8 dl-mob">{(item.disbursement_date) ? (<span>{format(new Date(item.disbursement_date), 'do MMM yyyy')} </span>) : 'NA'}</div>
                        <div className="col-md-4 bold">UTR Number :</div>
                        <div className="col-md-8">{(item.utr_number) ? (<span>{item.utr_number} </span>) : 'NA'}</div>
                        <div className="col-md-4 bold">Actual date of disbursement :</div>
                        {
                          item?.actual_disbursement_date && isDateCorrectFormat(item?.actual_disbursement_date) ?
                            <>
                        <div className="col-md-8">{(item.actual_disbursement_date) ? (<span>{format(new Date(item.actual_disbursement_date), 'do MMM yyyy')}</span>) : 'NA'}</div>
                           </> : <div className="col-md-8">{null}</div>
                        }
                        <div className="col-md-4 bold">Settlement date :</div>
                        <div className="col-md-8 dl-mob">{(item.settlement_date) ? (<span>{format(new Date(item.settlement_date), 'do MMM yyyy')} </span>) : 'NA'}</div>
                        <div className="col-md-4 bold">Virtual Account Number :</div>
                        <div className="col-md-8 dl-mob">{(item?.gateway === 'NEFT') ? (<span>{item?.transaction_params?.razorpay_virtual_account_id} </span>) : 'NA'}</div>
                        <div className="col-md-4 bold">Parent Donation Id :</div>
                        <div className="col-md-8 dl-mob">{item.parent_donation ? (<span>{item.parent_donation} </span>) : ("NA")}</div>
                        <div className="col-md-4 bold">Subscription Id :</div>
                        <div className="col-md-8 dl-mob">{item.subscription_id ? (<span>{item.subscription_id} </span>) : ("NA")}</div>
                        <div className="col-md-4 bold">Give Subscription Id :</div>
                        <div className="col-md-8 dl-mob">{item.give_subscription_id ? (<span>{item.give_subscription_id} </span>) : ("NA")}</div>
                        <div className="col-md-4 bold">Payment Method :</div>
                        <div className="col-md-8 dl-mob">{item?.payment_method ? (<span>{item.payment_method} </span>) : ("NA")}</div>
                        <div className="col-md-4 bold">Resync :</div>
                        <div className="col-md-8">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              this.reSyncDonation(item.order_id);
                            }}
                          >
                            {donation.get_status["resync_" + item.order_id] === "pending" ? (
                              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                            ) : (
                              <Icon
                                path={mdiChevronRight}
                                className="menu-arrow"
                                size={0.75}
                                color="#ffffff"
                              />
                            )}
                            &nbsp;ReSync
                          </button>
                        </div>
                        {
                          (item.user && item.user !== null && (item.status === 'PENDING' || item.status === 'USERCONFIRMED')) ? (<>
                            <div className="col-md-4 bold pt-5">Contact :</div>
                            <div className="col-md-8">
                              <ContactModalPopup item={item} />
                            </div></>) : null
                        }

                        {
                          (item.user && item.user !== null && (item.status === 'PENDING' || item.status === 'USERCONFIRMED')) ? (<>
                            <div className="col-md-4 bold">LastSent :</div>
                            <div className="col-md-8">
                              {(item.drop_off_reminders) ? (new Date(Date.parse(item.drop_off_reminders.slice(-1)[0].timestamp))).toLocaleString() : 'NA'}
                            </div></>) : null
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {item.additional_fields && Object.keys(item.additional_fields).length > 0
                      ? Object.keys(item.additional_fields).map((key, index) => (
                        <div className="col-md-12" key={index}>
                          <div className="row">
                            <div className="col-md-2">{Helpers.snakeCaseToString(key)} :</div>
                            <div className="col-md-10">
                              {item.additional_fields[key] != null &&
                                typeof item.additional_fields[key] === "object" ? (
                                <div className="row">
                                  <div className="col-md-2 pd-l-0">
                                    {Object.keys(item.additional_fields[key]).map((keyPair, index) => (
                                      <div key={index}>{keyPair}</div>
                                    ))}
                                  </div>
                                  <div className="col-md-10 word-break">
                                    {Object.values(item.additional_fields[key]).map(
                                      (valuePair, index) => {
                                        if(typeof valuePair !== 'object'){
                                          return (
                                            <div key={index}> - {valuePair}</div>
                                          )
                                        }
                                        else{
                                          try{
                                            Object.values(valuePair).map((item, index) => {
                                              return (
                                                <div key={index}> - {item}</div>
                                              )
                                            })
                                          }
                                          catch(e){
                                            // Do Nothing. 
                                            // Unable to get additional field values
                                          }
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-md-12 pd-l-0">
                                    {item.additional_fields[key]}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                      : null}
                  </div>
                  <div className="row">
                    <CreateAdminDonationTags item={item} />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </li >
    ));
  };

  render() {
    const { donation } = this.props;
    const { filters, createTag } = this.state;

    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">All donation</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} />
                <div className="row justify-content-end">
                  <div className="col-md-12 col-12 text-right">
                    <OverlayTrigger
                      trigger="click"
                      placement={isMobile ? "bottom-end" : "left-start"}
                      overlay={this.popover()}
                      rootClose
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={(e) => this.getReportLinks(e, "export")}
                      >
                        Proceed to Export
                      </button>
                    </OverlayTrigger>
                    <div>
                      <i>
                        <small className="text-muted">CSV file contains max of 1000 entries</small>
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card-col col-xl-12 col-12">
                <div className="ibox-content">
                  <div className="table-list live-dis-table">
                    <ul>
                      <li className="table-head">
                        <div className="row">
                          <div className="col-md-12 table-col">
                            <div className="row">
                              <div className="col-md-1 table-col dl-mob">Date</div>
                              <div className="col-md-3 table-col dl-mob">Donor</div>
                              <div className="col-md-4 table-col dl-mob">
                                Project(P) / Fundraiser(F)
                              </div>
                              <div className="col-md-1 table-col dl-mob">Amount</div>
                              <div className="col-md-2 table-col dl-mob">Status</div>
                              <div className="col-md-1 table-col dl-mob-right">Details</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {donation.donations.length > 0 &&
                        this.donationsHTML()}
                    </ul>
                    <div className="row">
                      {donation.load_status.donation ? (
                        <div className="col-md-12 text-center">
                          <button
                            type="button"
                            className="btn btn-fw align-center"
                            onClick={(e) => {
                              e.preventDefault();
                              this.loadMore();
                            }}
                          >
                            Load more
                          </button>
                        </div>
                      ) : null}
                      {donation.get_status.donation === "pending" &&
                        donation.donations.length !== 0 ? (
                        <div className="col-md-12">
                          <Loading />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  donation: state.adminDonationList,
  event: state.event,
});

const mapActionToProps = (dispatch) => ({
  Donation: bindActionCreators(adminDonationListAction, dispatch),
  Event: bindActionCreators(eventAction, dispatch),
});

export default connect(mapStateToProps, mapActionToProps)(AdminDonationsList);
