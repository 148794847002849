import memoize from "memoize-one";

import _Error from "../utils/_error";
import API from "../utils/api";
import { endpoints } from "../config";
// import Helpers from '../utils/helpers';

export default class SubscriptionService {
  static requestAPI(endpoint = endpoints.subscriptions, request = {}) {
    return API.request(endpoint, request);
  }

  static cacheGet = memoize(this.requestAPI);

  static get(request) {
    return this.cacheGet(undefined, request);
  }


}