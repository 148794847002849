import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';

export const GET_LIST_NONPROFITS = 'GET_LIST_NONPROFITS';
export const GET_LIST_NONPROFITS_SUCCESS = 'GET_LIST_NONPROFITS_SUCCESS';
export const GET_LIST_NONPROFITS_FAIL = 'GET_LIST_NONPROFITS_FAIL';

export const GET_DOWNLOAD_LIST_NONPROFITS = 'GET_DOWNLOAD_LIST_NONPROFITS';
export const GET_DOWNLOAD_LIST_NONPROFITS_SUCCESS = 'GET_DOWNLOAD_LIST_NONPROFITS_SUCCESS';
export const GET_DOWNLOAD_LIST_NONPROFITS_FAIL = 'GET_DOWNLOAD_LIST_NONPROFITS_FAIL';

export const NONPROFIT_LIST_RESET = 'NONPROFIT_LIST_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  nonprofits: [],
  get_status: {},
  load_status: {},
};

export const ngoListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LIST_NONPROFITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
      };
    case GET_LIST_NONPROFITS_SUCCESS:
      return {
        ...state,
        nonprofits: action.nonprofits,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
        load_status: {
          ...state.load_status,
          nonprofit: action.load_status,
        }
      };
    case GET_LIST_NONPROFITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
      };
    case GET_DOWNLOAD_LIST_NONPROFITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'pending',
        },
      };
    case GET_DOWNLOAD_LIST_NONPROFITS_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'success',
        },
      };
    case GET_DOWNLOAD_LIST_NONPROFITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'failed',
        },
      };
    case NONPROFIT_LIST_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getNonprofits(request) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_LIST_NONPROFITS,
    });
    const currentNonprofits = getState().ngoList.nonprofits;
    const params = {
      skip: currentNonprofits.length,
      status: 'pending,document_check',
      limit: 20,
      sort: '-updated_at',
    };
    if (request.q && request.q !== '') {
      params.q = request.q;
    }
    return NonProfitService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_LIST_NONPROFITS_SUCCESS,
            nonprofits: currentNonprofits,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_LIST_NONPROFITS_SUCCESS,
            nonprofits: [...currentNonprofits, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getNonprofitsForDownload(request) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_DOWNLOAD_LIST_NONPROFITS,
    });
    const params = {
      skip: 0,
      status: 'pending,document_check',
      limit: 1000,
      sort: '-updated_at',
     
    };
    if (request.q && request.q !== '') {
      params.q = request.q;
    }
    return NonProfitService.get({ params })
      .then((response) => {
        dispatch({
            type: GET_DOWNLOAD_LIST_NONPROFITS_SUCCESS,
          });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DOWNLOAD_LIST_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: NONPROFIT_LIST_RESET,
    });
  };
}
