import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';

export const GET_ONBOARDING_STATS = 'GET_ONBOARDING_STATS';
export const GET_ONBOARDING_STATS_SUCCESS = 'GET_ONBOARDING_STATS_SUCCESS';
export const GET_ONBOARDING_STATS_FAIL = 'GET_ONBOARDING_STATS_FAIL';

export const ADMIN_ONBOARDING_DASHBOARD_RESET = 'ADMIN_ONBOARDING_DASHBOARD_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  stats: {},
  get_status: {},
};

export const dashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ONBOARDING_STATS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          stat: 'pending',
        },
      };
    case GET_ONBOARDING_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats,
        get_status: {
          ...state.get_status,
          stat: 'success',
        },
        load_status: {
          ...state.load_status,
          nonprofit: action.load_status,
        }
      };
    case GET_ONBOARDING_STATS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          stat: 'failed',
        },
      };
    case ADMIN_ONBOARDING_DASHBOARD_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getStats() {
  return (dispatch) => {
    dispatch({
      type: GET_ONBOARDING_STATS,
    });
    return NonProfitService.get({path: ['status', 'stats']})
      .then((response) => {
        dispatch({
          type: GET_ONBOARDING_STATS_SUCCESS,
          stats: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_ONBOARDING_STATS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: ADMIN_ONBOARDING_DASHBOARD_RESET,
    });
  };
}
