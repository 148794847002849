import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';

var previousYears;
class NonprofitDocumentsRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
      expand: 0, 
    };
  }

  switchView = (view) => {
    this.setState({ view: view });
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if(val === '' && key[1] !== 'video') {
      return this.na;
    }
    return val;
  }

  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if(to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if(to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if(to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if(to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  expandReport = (yearVar) => {
    this.state.expand === yearVar ? 
    this.setState({
      expand : 0, 
    }) : this.setState({
      expand : yearVar
    })
  }

  renderFinancialReports = (yearVar) => {
    return(
      <>
        <div className="row">
        <div className="col-lg-6 col-md-12">
        {/* <ApprovalsRejectionsButtons path={['nonprofit', 'documents', 'auditor_report:2018-19']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}> */}
        <div>
          <h6> Auditor's Report</h6>
          <p className="text-muted"> {this.getInputData(['nonprofit', 'documents', `auditor_report:${yearVar}`]) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'documents', `auditor_report:${yearVar}`])}>(Preview)</a>) : this.na}</p>
        </div>
        {/* </ApprovalsRejectionsButtons> */}
        </div>
        <div className="col-lg-6 col-md-12">
        {/* <ApprovalsRejectionsButtons path={['nonprofit', 'documents', 'income_expenditure:2018-19']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}> */}
        <div>
          <h6> Income &amp; Expenditure Report</h6>
          <p className="text-muted"> {this.getInputData(['nonprofit', 'documents', `income_expenditure:${yearVar}`]) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'documents', `income_expenditure:${yearVar}`])}>(Preview)</a>) : this.na}</p>
        </div>
        {/* </ApprovalsRejectionsButtons> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
        {/* <ApprovalsRejectionsButtons path={['nonprofit', 'documents', 'balance_sheet:2018-19']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}> */}
        <div>
          <h6> Balance Sheet</h6>
          <p className="text-muted"> {this.getInputData(['nonprofit', 'documents', `balance_sheet:${yearVar}`]) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'documents', `balance_sheet:${yearVar}`])}>(Preview)</a>) : this.na}</p>
        </div>
        {/* </ApprovalsRejectionsButtons> */}
        </div>
        <div className="col-lg-6 col-md-12">
        {/* <ApprovalsRejectionsButtons path={['nonprofit', 'documents', 'schedule:2018-19']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}> */}
        <div>
          <h6> Schedules</h6>
          <small>Schedules/Annexures/Statement to Balance sheet allowed. If schedules included within Balance Sheet, upload complete financials here. In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence</small>
          <p className="text-muted"> {this.getInputData(['nonprofit', 'documents', `schedule:${yearVar}`]) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'documents', `schedule:${yearVar}`])}>(Preview)</a>) : this.na}</p>
        </div>
        {/* </ApprovalsRejectionsButtons> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
        {/* <ApprovalsRejectionsButtons path={['nonprofit', 'documents', 'account_notes:2018-19']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}> */}
        <div>
          <h6> Notes on Accounts</h6>
          <small>Notes to accounts/notes at the end of Financial Statement/Significant Accounting policies to be accepted. In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence</small>
          <p className="text-muted"> {this.getInputData(['nonprofit', 'documents', `account_notes:${yearVar}`]) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'documents', `account_notes:${yearVar}`])}>(Preview)</a>) : this.na}</p>
        </div>
        {/* </ApprovalsRejectionsButtons> */}
        </div>
        <div className="col-lg-6 col-md-12">
        {/* <ApprovalsRejectionsButtons path={['nonprofit', 'documents', 'annual_report:2018-19']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}> */}
        <div>
          <h6> Annual Report</h6>
          <small>In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence</small>
          <p className="text-muted"> {this.getInputData(['nonprofit', 'documents', `annual_report:${yearVar}`]) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'documents', `annual_report:${yearVar}`])}>(Preview)</a>) : this.na}</p>
        </div>
        {/* </ApprovalsRejectionsButtons> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
        {/* <ApprovalsRejectionsButtons path={['nonprofit', 'documents', 'income_tax_return:2018-19']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}> */}
        <div>
          <h6> Income Tax Returns</h6>
          <p className="text-muted"> {this.getInputData(['nonprofit', 'documents', `income_tax_return:${yearVar}`]) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'documents', `income_tax_return:${yearVar}`])}>(Preview)</a>) : this.na}</p>
        </div>
        {/* </ApprovalsRejectionsButtons> */}
        </div>
        <div className="col-lg-6 col-md-12">
        {/* <ApprovalsRejectionsButtons path={['nonprofit', 'documents', 'fcra_return:2018-19']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}> */}
        <div>
          <h6>FCRA Returns</h6>
          <small>In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence</small>
          <p className="text-muted"> {this.getInputData(['nonprofit', 'documents', `fcra_return:${yearVar}`]) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'documents', `fcra_return:${yearVar}`])}>(Preview)</a>) : this.na}</p>
        </div>
        {/* </ApprovalsRejectionsButtons> */}
        </div>
      </div>
      </> 
    )
  }

  financialYearHtmlDocument = (year) => {
    const yearVar = year;
    return (
      <div key={year}>
        {
          previousYears.includes(yearVar) ? 
          <><h4 className="mr-b-20">Financial Year {yearVar}<sub style={{color: '#007bff', marginLeft:'10px', bottom : '0px', fontSize: "0.875rem", cursor:"pointer"}} onClick={() => {this.expandReport(yearVar)}}>{this.state.expand === yearVar ? "(Collapse)" : "(Expand)" }</sub></h4> 
          { this.state.expand === yearVar ?  
          this.renderFinancialReports(yearVar)
        : null }
        </> 
        :
        <>
        <h4 className="mr-b-20">Financial Year {yearVar}</h4>
        { this.renderFinancialReports(yearVar) }
        </> }
      </div> 
    )
  }

  render() {
    const { children, tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];

    const uniqueYears = value && value.documents && value.documents.map((fy) => fy.fy).reduce((acc, c) => {
      !acc.hash[c] && acc.result.push(c);
      acc.hash[c] = true;
      return acc;
    }, {result: [], hash: {}});
    const financialYears = uniqueYears.result
    const currentYears = financialYears.sort().reverse().splice(0,3);
    previousYears = financialYears.filter((fy) => { return !currentYears.includes(fy)})
  
    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">Media &amp; Documents</h3>
                <div className="heading-bar-btn">
                  {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab => {
                    if (tab === view) {
                      return (
                        <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                      );
                    }
                    return (
                      <button type="button" className="btn btn-outline-secondary btn-left" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                    );
                  })}
                </div>
              </div>
              <h4 className="mr-b-20">General documents</h4>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'address_proof_doc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Address proof</h6>
                      <small>Telephone/Electricity/Wifi bill; Rent/Lease Agreement allowed. In case document in the name of the organisation is not available, add Telephone/Electricity/Wifi bill; Rent/Lease Agreement in the name of the individual/organisation with attested declaration mentioning that the organisation has been given permission to use the said premises. </small>
                      <p className="text-muted"> {this.getInputData(['nonprofit', 'address_proof_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'address_proof_doc'])}>(Preview)</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'highresolution_image']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> High resolution logo vector</h6>
                      <p className="text-muted"> {this.getInputData(['nonprofit', 'highresolution_image']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'highresolution_image'])}>(Preview)</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <ApprovalsRejectionsButtons path={['nonprofit', 'documents']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                {/* {this.shouldShowItemOnCompliance('fbassured') ?
                    financialYears.map((year, idx) => (
                      this.financialYearHtmlDocument(year)
                    )) : 
                    this.financialYearHtmlDocument(financialYears[0])
                } */}
                {
                currentYears && currentYears.map((year, idx) => (
                    this.financialYearHtmlDocument(year)
                  )) 
                }
                {
                  previousYears && previousYears.map((year, idx) => (
                    this.financialYearHtmlDocument(year)
                  )) 
                }
                {/* <FinancialYearRead yearOne='2018' yearTwo='2019' getInputData={this.getInputData}/>
                {this.shouldShowItemOnCompliance('fbassured') ? <FinancialYearRead yearOne='2017' yearTwo='2018' getInputData={this.getInputData}/> : null }
                {this.shouldShowItemOnCompliance('fbassured') ? <FinancialYearRead yearOne='2016' yearTwo='2017' getInputData={this.getInputData}/> : null}                 */}
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NonprofitDocumentsRead;
