import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import DisbursementService from '../../services/disbursement-service';
import DonationService from '../../services/donation-service';

export const GET_DISBURSEMENTS_SYNC_PG = 'GET_DISBURSEMENTS_SYNC_PG';
export const GET_DISBURSEMENTS_SYNC_PG_SUCCESS = 'GET_DISBURSEMENTS_SYNC_PG_SUCCESS';
export const GET_DISBURSEMENTS_SYNC_PG_FAIL = 'GET_DISBURSEMENTS_SYNC_PG_FAIL';

export const GET_DISBURSEMENTS_SYNC_STATS = 'GET_DISBURSEMENTS_SYNC_STATS';
export const GET_DISBURSEMENTS_SYNC_STATS_SUCCESS = 'GET_DISBURSEMENTS_SYNC_STATS_SUCCESS';
export const GET_DISBURSEMENTS_SYNC_STATS_FAIL = 'GET_DISBURSEMENTS_SYNC_STATS_FAIL';

export const GET_DISBURSEMENTS_SYNC_RESPONSE = 'GET_DISBURSEMENTS_SYNC_RESPONSE';
export const GET_DISBURSEMENTS_SYNC_RESPONSE_SUCCESS = 'GET_DISBURSEMENTS_SYNC_RESPONSE_SUCCESS';
export const GET_DISBURSEMENTS_SYNC_RESPONSE_FAIL = 'GET_DISBURSEMENTS_SYNC_RESPONSE_FAIL';
export const GET_DISBURSEMENTS_SYNC_RESPONSE_RESET = 'GET_DISBURSEMENTS_SYNC_RESPONSE_RESET';

export const DISBURSEMENTS_SYNC_RESET = 'DISBURSEMENTS_SYNC_RESET';

const initialState = {
  stats: [],
  pg: {},
  sync_response: {},
  get_status: {},
};

export const syncReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DISBURSEMENTS_SYNC_PG:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pg: 'pending',
        },
      };
    case GET_DISBURSEMENTS_SYNC_PG_SUCCESS:
      return {
        ...state,
        pg: action.pg,
        get_status: {
          ...state.get_status,
          pg: 'success',
        },
      };
    case GET_DISBURSEMENTS_SYNC_PG_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pg: 'failed',
        },
      };
    case GET_DISBURSEMENTS_SYNC_STATS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          stat: 'pending',
        },
      };
    case GET_DISBURSEMENTS_SYNC_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats,
        get_status: {
          ...state.get_status,
          stat: 'success',
        },
      };
    case GET_DISBURSEMENTS_SYNC_STATS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          stat: 'failed',
        },
      };
    case GET_DISBURSEMENTS_SYNC_RESPONSE:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          sync: 'pending',
        },
      };
    case GET_DISBURSEMENTS_SYNC_RESPONSE_SUCCESS:
      return {
        ...state,
        sync_response: action.sync_response,
        get_status: {
          ...state.get_status,
          sync: 'success',
        },
      };
    case GET_DISBURSEMENTS_SYNC_RESPONSE_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          sync: 'failed',
        },
      };
    case GET_DISBURSEMENTS_SYNC_RESPONSE_RESET:
      return {
        ...state,
        sync_response: {},
        stats: [],
        get_status: {
          ...state.get_status,
          stat: null,
        }
      };
    case DISBURSEMENTS_SYNC_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getPG() {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_SYNC_PG,
    });
    return DonationService.get({ path: ['payment-entities'] })
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_SYNC_PG_SUCCESS,
          pg: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_SYNC_PG_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getStats() {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_SYNC_STATS,
    });
    const request = {
      path: ['stats'],
      method: 'post',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_SYNC_STATS_SUCCESS,
          stats: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_SYNC_STATS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function syncDonations(data) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_SYNC_RESPONSE,
    });
    const request = {data, path: ['sync']};
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_SYNC_RESPONSE_SUCCESS,
          sync_response: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_SYNC_RESPONSE_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function syncReset(request) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_SYNC_RESPONSE_RESET,
    });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: DISBURSEMENTS_SYNC_RESET,
    });
  };
}
