import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';
import ReactAvatarEditor from 'react-avatar-editor';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiCancel, mdiTrashCanOutline } from '@mdi/js';

import * as NotificationAction from '../../reducers/notificationReducer';
import fileUpload from '../../utils/fileUpload';

import uploadImage from '../../resources/images/upload.png';

import './ImageCropUpload.css';


class ImageCropUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      range: 1,
      fileStatus: 'pending',
      prevValue: {},
    };
  }

  componentDidMount() {
    const { value } = this.props;
    if (value || value !== '') {
      this.setState({ fileStatus: 'uploaded' });
    } else {
      this.setState({ fileStatus: 'pending' });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.value !== state.prevValue) {
      setData.prevValue = props.value;
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  dataURLtoBlob = (dataURL, imageType) => {
    const binary = atob(dataURL.split(',')[1]);
    const array = [];
    let i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i += 1;
    }
    return new Blob([new Uint8Array(array)], { type: imageType });
  };

  saveFile = () => {
    const { image } = this.state;
    const { onChange, Notification, label } = this.props;
    if (this.element) {
      const canvas = this.element.getImageScaledToCanvas();

      // Create a white background for the image
      const context = canvas.getContext('2d');
      context.save();
      context.globalCompositeOperation = "destination-over";
      context.fillStyle = 'rgba(255, 255, 255, 1)';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.restore();

      const rawImage = canvas.toDataURL('image/jpeg', 0.7);
      const imageType = `image/${rawImage.split(';')[0].split('/')[1]}`;
      const blob = (this.dataURLtoBlob(rawImage, imageType));

      const file = new File([blob], image.name, { type: imageType, lastModified: Date.now() });

      this.setState({ fileStatus: 'uploading' });
      fileUpload.upload(file, { name: label, type: image.type }).then((response) => {
        const returnData = { uri: response.file_url };
        this.setState({ fileStatus: 'uploaded' });
        onChange(returnData.uri);
      }).catch((error) => {
        if (!(error instanceof Error)) {
          Notification.addNotification(error.message);          
        }
        onChange(null);
        this.setState({ fileStatus: 'pending' });
      });
    }
  }

  addFile = (dropped) => {
    this.setState({ image: dropped[0], range:1 });
  }

  rangeChange = (e) => {
    this.setState({ range: parseFloat(e.target.value) });
  }

  resetEditor = () => {
    this.setState({ image: null });
  }

  deleteFile = (e) => {
    const { onChange } = this.props;
    e.preventDefault();
    this.setState({ fileStatus: 'pending', image: null });
    onChange(null);
  }

  cancelToken = (c) => {
    this.setState({ cancel: c });
  }

  editorHTML = () => {
    const { fileStatus, cancel, range, image } = this.state;
    const { width, height, value, label } = this.props;
    switch (fileStatus) {
      case 'pending':
        return (
          <div className="row">
            <div className="col-md-12 uimg-wrapper">
              <ReactAvatarEditor width={width} height={height} image={image} scale={range} border={1} color={[0,0,0,1]} ref={(e) => { this.element = e; }} />
            </div>
            <div className="col-md-12">
              <div className="form-group zoom-wrapper">
                <label>Zoom</label>
                <input className="form-control" type="range" step="0.01" min="0" max="2" value={range} onChange={this.rangeChange} />
              </div>
            </div>
            <div className="col-md-12 uimg-wrapper">
              <button type="button" className="btn btn-success mr-2" onClick={this.saveFile}>
                <Icon path={mdiChevronLeft} className="menu-arrow" size={0.75} color="#ffffff" />
                &nbsp;Save
              </button>
              <button type="button" className="btn btn-light" onClick={this.resetEditor}>
                <Icon path={mdiCancel} className="menu-arrow" size={0.60} color="#212529" />
                &nbsp;Cancel
              </button>
            </div>
          </div>
        );
      case 'uploading':
        return (
          <div className="row">
            <div className="col-md-12 uimg-wrapper">
              <ReactAvatarEditor width={width} height={height} image={image} scale={range} border={1} color={[0,0,0,1]} ref={(e) => { this.element = e; }} />
            </div>
            <div className="col-md-12">
              <div className="dot-opacity-loader">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="col-md-12 uimg-wrapper">
              <button type="button" className="btn btn-light" onClick={(e) => { e.preventDefault(); cancel(); }}>
                <Icon path={mdiCancel} className="menu-arrow" size={0.60} color="#212529" />
                &nbsp;Cancel
              </button>
            </div>
          </div>
        );
      case 'uploaded':
        return (
          <div className="row">
            <div className="col-md-12 uimg-wrapper">
              <img alt={label} src={value} height={height} width={width} />
            </div>
            <div className="col-md-12 uimg-wrapper mr-t-20">
              <button type="button" className="btn btn-light" onClick={this.deleteFile}>
                <Icon path={mdiTrashCanOutline} className="menu-arrow" size={0.60} color="#212529" />
                &nbsp;Delete
              </button>
            </div>
          </div>
        );
      default:
    }
    return null;
  }

  render() {
    const { image } = this.state;
    const { title, value, error } = this.props;
    if (image || value) {
      return (
        <div className="row">
          <div className="col-md-12">
            <p>{title}</p>
            {error}
          </div>
          <div className="col-md-12">
            {this.editorHTML()}
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-md-12">
          {title ? (<p>{title}</p>) : null}
          {error}
        </div>
        <div className="col-md-12">
          <Dropzone onDrop={this.addFile} multiple={false} accept={['image/jpeg', 'image/png']}>
            {({ getRootProps, getInputProps }) => (
              <section {...getRootProps()} className="file-wrapper">
                <div className="file-message">
                  <input {...getInputProps()} />
                  <img className="file-icon" src={uploadImage} alt="upload icon" />
                  <p>Drag and drop a image here or click</p>
                  <p>(Only *.jpeg and *.png images will be accepted)</p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}


const mapStateToProps = () => ({
});

const mapActionToProps = dispatch => ({
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(ImageCropUpload);
