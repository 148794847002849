import React, { Component } from 'react'
import Helpers from '../../utils/helpers';
import './form.scss'

class OnboardingForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ngoName: this.props.ngoName,
            url: this.props.url,
            signatoryName: this.props.signatoryName,
            signatoryEmail: this.props.signatoryEmail,
            pan: this.props.pan
			
        }
    }

    getInputErrors = (key) => {
        const { formErrors } = this.props;
        const { state } = this;
        return Helpers.getErrorMessageFromObject(formErrors, key, state);
      }



    render() {
        return (
            <div className="fb-onboarding-form">
                <h3 className="_form-header">NGO Details</h3>
                <div className="container" style={{marginBottom:'18px'}}>
                <form>
                    <div className="form-fiels-container">
                        <div className="display-flex">
                        
                            <div className="mr-50 flex-1" id="onboard-input-field-name">
                                <label htmlFor="ngoname">NGO Name</label>
                                <input type="text" id="ngoname" required onChange={(e) => this.props.handleInputChange(e) }/>
                                {this.getInputErrors(['', 'name'])}
                            </div>
                            
                            <div className='flex-1 onboard-input-field' id="onboard-input-field-url">
                                <label htmlFor="url">NGO's Facebook Page (URL)</label>
                                <input type="text" id="url" required onChange={ (e) => this.props.handleInputChange(e) }/>
                                    {this.getInputErrors(['', 'external_profiles'])}
                            </div>
                        </div>

                        <div className="display-flex">
                            <div className="mr-50 flex-1 onboard-input-field" id="onboard-input-field-pan">
                                <label htmlFor="pan">NGO PAN Number</label>
                                <input type="text" id="pan" required onChange={(e) => this.props.handleInputChange(e)}/>
                                {this.getInputErrors([,  'pan_card'])}
                            </div>
                            <div className="flex-1"></div>
    
                        </div>
                        
                        <div className="display-flex">
                            <div className="mr-50 flex-1 onboard-input-field" id="onboard-input-field-sname">
                                <label htmlFor="signName">Signatory Name</label>
                                <input type="text" id="signName" required onChange={(e) => this.props.handleInputChange(e)}/>
                                {this.getInputErrors(['', 'facebook_onboarding', 'signatory_name'])}
                            </div>
    
                            <div className="flex-1 onboard-input-field" id="onboard-input-field-email">
                                <label htmlFor="signemail">Signatory Email Address</label>
                                <input type="email" id="signemail" required onChange={(e) => this.props.handleInputChange(e)}/>
                                {this.getInputErrors([ '','facebook_onboarding', 'signatory_email'])}
                            </div>
                        </div>

                        
                    </div>


                   
                </form>
                <p>
                    Similar to requirements for NGOs (nonprofit organizations) in other countries, Facebook requires Indian charities to accept Terms & Conditions. Once submitted and verified, your NGO maybe granted access to the charitable giving tools to begin raising funds on the Facebook platform. 
                </p>
                <p>
                    Please enter your NGO Name as you would have registered in the same way with Give.do. Please enter your NGOs Facebook Page URL. If you do not have one, you might need to create one. The Page Admin(s) on your NGO’s Facebook Page will automatically be added as Admins for the Donation/Payment Settings as well, which can be accessed <a>here</a>. It would be ideal to enter the name and email address of a Page Admin.     
    
                </p>
                </div>
            </div>
    
        )
    }
   
}

export default OnboardingForm
