import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classnames from 'classnames';
import { format } from 'date-fns';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline, mdiChevronRight, mdiLoading , mdiFilter} from '@mdi/js';

import * as supportDonationListAction from '../../../reducers/Support/supportDonationListReducer';
import './SupportDonationsList.scss';

import Loading from '../../../appComponents/Loading';
import FilterByInput from '../../../components/FilterByInput';
import Helpers, { downloadPdf } from '../../../utils/helpers';

class SupportDonationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {},
      filters:[{
        label: 'Search',
        type: [{
          type: 'email',
          label: 'Phone/Email',
          query: 'q',
        }, {
          type: 'phone',
          label: 'Phone/Email',
          query: 'q',
        }],
        placeholder: 'Search for Mobile, Email',
      }, {
        label: 'Created after',
        type: 'date',
        query: 'after',
      }, {
        label: 'Created before',
        type: 'date',
        query: 'before',
      }, {
        label: 'Platform',
        type: 'list',
        query: 'platform',
        list: [{label: 'Fundraisers', value: 'fundraisers'}, {label: 'Subscription', value: 'subscription'}, {label: 'Payroll', value: 'payroll'}, {label: 'Facebook', value: 'facebook'}, {label: 'FANKIND', value: 'fankind'}, {label: 'GooglePay', value: 'googlepay'}, {label: 'PhonePe', value: 'phonepe'}, {label: 'Nuclei', value: 'nuclei'}],
      }],
    };
  }

  componentWillUnmount() {
    const { Donation } = this.props;
    Donation.reset();
  }

  loadMore = () => {
    const { Donation } = this.props;
    const { query } = this.state;
    Donation.getDonations(query);
  }

  refreshDonationList = () => {
    const { Donation } = this.props;
    const { query } = this.state;
    Donation.reset();
    Donation.getDonations(query);
  }

  reSyncDonation = (order_id) => {
    const { Donation } = this.props;
    Donation.getReSyncDonation(order_id);
  }

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshDonationList();
    });
  }

  donationsHTML = () => {
    const { donation } = this.props;
    const { query } = this.state;
    if (!donation.get_status.donation || (!query.q || query.q.length < 3)) {
      return (
        <i className="align-center">Please search for email or phone number</i>
      );
    }
    if (donation.get_status.donation === 'pending' && donation.donations.length === 0) {
      return (<Loading />);
    }
    if (donation.donations.length === 0) {
      return (
        <i className="align-center">No items available</i>
      );
    }
    return donation.donations.map(item => (
      <li className="table-list-item" key={item.receipt_uri + item.created_at}>
        <div className="row">
          <div className="col-md-12 table-col">
            <div className="row">
              <div className="col-md-1 col-12 table-col dl-mob">
                <small style={{ display: 'block' }}>{format(new Date(item?.created_at), 'do MMM yyyy')}</small>
                <small style={{ display: 'block' }}>{format(new Date(item?.created_at), 'HH:mm')}</small>
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                <span style={{ display: 'block' }}>{item.platform || 'Not Available'}</span>
              </div>
              {(item.user) ? (
                <div className="col-md-6 col-12 table-col dl-mob user-info-livedis">
                  <div className="name-email">
                    <span>{item.user.name}</span>
                    {item.user.email ? <small>{item.user.email}</small> : null}
                    {item.user.phone ? <small>{item.user.phone}</small> : null}
                  </div>
                </div>
              ) : (
                  <div className="col-md-6 col-12 table-col dl-mob user-info-livedis">
                    User Not Available
                  </div>
              )}
              <div className="col-md-2 col-12 table-col dl-mob">{item.currency_code}&nbsp;{item.amount}</div>
              <div className="col-md-2 col-12 table-col dl-mob-right">
                {item.receipt_uri ? (
                  <div className="col-md-8 download-title"  onClick={() => {
                    downloadPdf(item.receipt_uri)
                  }}>
                    Download
                  </div>
                ) : (
                    <div className="col-md-8">Not available</div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </li>
    ));
  }

  render() {
    const { donation } = this.props;
    const { filters } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Donations across platforms</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} />
              </div>
            </div>
            <div className="row">
              <div className="card-col col-xl-12 col-12">
              <div className="ibox-content">
                  <div className="table-list live-dis-table">
                    <ul>
                      <li className="table-head">
                        <div className="row">
                          <div className="col-md-12 table-col">
                            <div className="row">
                              <div className="col-md-1 table-col dl-mob">Date</div>
                              <div className="col-md-1 table-col dl-mob">Platform</div>
                              <div className="col-md-6 table-col dl-mob">Donor</div>
                              <div className="col-md-2 table-col dl-mob">Amount</div>
                              <div className="col-md-2 table-col dl-mob-right">Receipt</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {this.donationsHTML()}
                    </ul>
                    <div className="row">
                      {(donation.load_status.donation) ? (
                        <div className="col-md-12 text-center">
                          <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                        </div>
                      ) : null}
                      {(donation.get_status.donation === 'pending' && donation.donations.length !== 0) ? (
                        <div className="col-md-12">
                          <Loading />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  donation: state.supportDonationList,
});

const mapActionToProps = dispatch => ({
  Donation: bindActionCreators(supportDonationListAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(SupportDonationsList);
