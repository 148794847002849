import React from 'react';
import Icon from '@mdi/react';
import { mdiTelevision } from '@mdi/js';

import SupportDonationsList from '../../productComponents/Support/SupportDonationsList';

const routes = {
  title: 'Support',
  path: '/support',
  default_path: '/support/donations',
  for_user: 'admin',
  sidebar: [{
    title: 'Donations',
    path: '/support/donations',
    icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
    component: SupportDonationsList,
  }],
};

export default routes;
