import React, { useEffect, useState } from "react";
import Helpers from "../../../utils/helpers";

const AdminTagCreate = (props) => {
  const [tagData, setTagData] = useState(null);

  const handleChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setTagData({
      ...tagData,
      ...Helpers.setPathToObject(tagData, e.target.name, value),
    });
  };

  const { onSubmit, tag, mode } = props;

  const handleSubmit = () => {
    onSubmit(tagData);
  };

  const canSubmit = () => (tagData && !!tagData.expiry && !!tagData.title && !!tagData.meta?.team);

  useEffect(() => {
    if (tag && Object.keys(tag).length) {
      setTagData({
        ...tag,
      });
    }
  }, [tag]);

  return (
    <div className="card-body">
      <h3 className="mr-b-20">{mode === "create" ? `Create New Tag` : `Edit ${tagData?.title}`}</h3>
      <div className="row justify-content-start ">
        <div className="col-md-4 col-4">
          <div className="form-group">
            <label>Tag Name</label>
            <input
              className="form-control"
              type="text"
              name="title"
              value={tagData?.title || ''}
              placeholder="Tag Name"
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-md-4 col-4">
          <div className="form-group">
            <label>Team</label>
            <input
              className="form-control"
              type="text"
              name="meta.team"
              value={tagData?.meta?.team || ''}
              placeholder="Tag Name"
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-md-4 col-4">
          <div className="form-group">
            <label>Expires On</label>
            <input
              className="form-control"
              type="date"
              name="expiry"
              value={tagData?.expiry?.split('T')[0] || ''}
              placeholder="Expires On"
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className="col-md-12 col-12">
          <div className="form-group">
            <label>Tag Description</label>
            <textarea
              className="form-control"
              name="meta.description"
              placeholder="Tag Description"
              value={tagData?.meta?.description || ""}
              onChange={(e) => handleChange(e)}
            ></textarea>
          </div>
        </div>
        <div className="col-md-6 col-6">
          <div className="form-check mt-0">
            <input
              type="checkbox"
              className="form-check-input"
              id="allow-inhertinance"
              name="allow_inheritance"
              value={true}
              onChange={(e) => handleChange(e)}
              checked={!!tagData?.allow_inheritance || tagData?.allow_inheritance === "true"}
            />
            <label className="form-check-label" htmlFor="allow-inhertinance">
              Should tag be passed ?
            </label>
          </div>
        </div>
        <div className="col-md-12 col-12">
          <button type="button" className="btn btn-primary" onClick={handleSubmit}>
            {mode === 'create' ? `Create Tag` : `Save Changes` }
          </button>
        </div>
      </div>
    </div>
  )
}

export default AdminTagCreate;
