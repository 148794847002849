import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OverlayTrigger, Form, Popover, ListGroup, Button, Row, Col } from 'react-bootstrap';

import { format } from 'date-fns';

import Icon from '@mdi/react';
import { mdiFilter } from '@mdi/js';
import { isMobile } from 'react-device-detect';

import * as liveDonationAction from '../../../reducers/Crowdfunding/liveDonationReducer';

import './LiveDonations.scss';

import Loading from '../../../appComponents/Loading';
import FilterByInput from '../../../components/FilterByInput';
import Helpers, { downloadPdf } from '../../../utils/helpers';

class LiveDonations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDetails: '',
      currentTab: 'export',
      query: {},
      filters: [{
        label: 'Search',
        type: [{
          type: 'default',
          label: 'Name',
          query: 'q',
        }, {
          type: 'email',
          label: 'Email id',
          query: 'filter_by_user_email',
        }, {
          type: 'phone',
          label: 'Mobile number',
          query: 'filter_by_user_phone',
        }, {
          type: 'order',
          label: 'Order Id',
          query: 'filter_by_order_id',
        }, {
          type: 'disbursement',
          label: 'Disbursement Id',
          query: 'filter_by_disbursement_number',
        }],
        placeholder: 'Search for OrderId, Mobile, Name, Email',
      }, {
        label: 'Order Id',
        type: 'text',
        query: 'filter_by_order_id',
        placeholder: 'Enter Order ID and press Enter',
      }, {
        label: 'Phone',
        type: 'phone',
        query: 'filter_by_user_phone',
        placeholder: 'Enter Phone number and press Enter',
      }, {
        label: 'Email Id',
        type: 'text',
        query: 'filter_by_user_email',
        placeholder: 'Enter email id and press Enter',
      }, {
        label: 'Created after',
        type: 'date',
        query: 'after',
      }, {
        label: 'Created before',
        type: 'date',
        query: 'before',
      }, {
        label: 'Project',
        type: 'service',
        query: 'filter_by_project',
        placeholder: 'Type project name',
        service: 'projects',
        ristrictions: { "nonprofit": props.match.params.nonprofit },
      }, {
        label: 'Fundraiser',
        type: 'service',
        query: 'filter_by_campaign',
        placeholder: 'Type fundraiser title',
        service: 'campaigns',
        ristrictions: { "nonprofit._id": props.match.params.nonprofit },
      }],
      exportFilters: [],
      reportLinks: [],
      loader: false
    };
  }

  componentDidMount() {
    const { live, Live, match } = this.props;
    const { nonprofit } = match.params;
    if (!live.get_status.donation) {
      Live.getDonations({ filter_by_nonprofit: nonprofit });
    }
  }

  componentWillUnmount() {
    const { Live } = this.props;
    Live.reset();
  }

  loadMore = () => {
    const { Live, match } = this.props;
    const { query } = this.state;
    const { nonprofit } = match.params;
    Live.getDonations({ ...query, filter_by_nonprofit: nonprofit });
  }

  showDetailsClick = (id) => {
    const { viewDetails } = this.state;
    if (id === viewDetails) {
      this.setState({ viewDetails: '' });
    } else {
      this.setState({ viewDetails: id });
    }
  }

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshDonationList();
    });
  }

  addToFilter = (type, value) => {
    const { filters } = this.state;
    const newOption = { ...filters.find(i => i.label === type) };
    newOption.data = value;
    const newOptions = filters.map(item => (item.label === type) ? newOption : item);
    this.setState({ filters: newOptions, query: Helpers.queryBuilderForFilterInput(newOptions) }, () => {
      this.refreshDonationList();
    });
  }

  addToFilterHTML = (type, value) => (
    <span className="filter-wrapper">
      <button type="button" className="btn btn-rounded btn-inverse-outline-primary btn-filters" onClick={(e) => { e.preventDefault(); this.addToFilter(type, value); }}>
        <Icon path={mdiFilter} size={0.5} color="#2196f3" /><span>Apply as filter</span>
      </button>
    </span>
  )

  refreshDonationList = () => {
    const { Live, match } = this.props;
    const { query } = this.state;
    const { nonprofit } = match.params;
    Live.reset();
    Live.getDonations({ ...query, filter_by_nonprofit: nonprofit });
  }

  recursiveObjSearch = (obj, returnData) => {
    const rd = returnData;
    if (typeof obj === 'object' && obj !== null) {
      Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (typeof value !== 'object') {
          rd[key] = value;
        } else if (typeof value === 'object') {
          this.recursiveObjSearch(value, rd);
        }
      });
    }
    return rd;
  };

  downloadDonationList = (e) => {
    e.preventDefault();
    const { Live, match } = this.props;
    const { query } = this.state;
    const { nonprofit } = match.params;
    Live.getDonationsForDownload({ ...query, filter_by_nonprofit: nonprofit }).then((data) => {
      const fileName = `GiveFundraisers-live-${nonprofit}-${format(new Date(), 'HH:mm:ss')}`;
      const json = data.map((d) => {
        const returnData = ({
          "Date": format(new Date(d.created_at), 'do MMM yyyy'),
          "Time": format(new Date(d.created_at), 'HH:mm'),
          "Transaction ID": d.order_id,
          "Donation Currency": d.currency_code,
          "Donation Amount": d.amount,
          "Donor Name": (d.user ? d.user.name : ''),
          "Donor Email": (d.user ? d.user.email : ''),
          "Donor Mobile Number": (d.user ? d.user.phone : ''),
          "Fundraiser ID": (d.campaign ? d.campaign._id : ''),
          "Fundraiser Name": (d.campaign ? d.campaign.title : ''),
          "Fundraiser Link": (d.campaign ? d.campaign.permalink : ''),
          "Project ID": (d.project ? d.project._id : ''),
          "Project Name": (d.project ? d.project.name : ''),
          "Project Link": (d.project ? d.project.permalink : ''),
          "Referer": (d.referrer ? JSON.stringify(d.referrer) : ''),
          "Redirect URL": d.redirect_url,
          "Donor Address Line 1": (d.user && d.user.address && d.user.address.street ? d.user.address.street : ''),
          "Donor Address Line 2": (d.user && d.user.address && d.user.address.locality ? d.user.address.locality : ''),
          "Donor City": (d.user && d.user.address && d.user.address.city ? d.user.address.city : ''),
          "Donor Town / State": (d.user && d.user.address && d.user.address.region ? d.user.address.region : ''),
          "Donor Country": (d.user && d.user.address && d.user.address?.country ? d.user.address?.country : ''),
          "Donor Zipcode / Pincode": (d.user && d.user.address && d.user.address.area_code ? d.user.address.area_code : ''),
          "Donor Complete Address": (d.user && d.user.address && d.user.address.formatted ? d.user.address.formatted : ''),
          "Donor PAN": (d.user ? d.user.pan_card : ''),
        });
        if (d.additional_fields && Object.keys(d.additional_fields).length > 0) {
          this.recursiveObjSearch(d.additional_fields, returnData);
          // Object.keys(d.additional_fields).forEach(key => {
          //   if(key === 'source_medium') {
          //     if(d.additional_fields['source_medium'].hasOwnProperty('referrer')) {
          //       returnData['Source Medium'] = d.additional_fields[key].referrer
          //     }
          //   } else {
          //     returnData[key] = d.additional_fields[key];
          //   }
          // });
        }
        return returnData;
      });
      Helpers.convertJSONtoCSV(json, fileName);
    });
  }


  generateReportLink = (e) => {
    e.preventDefault();
    const { match } = this.props;
    const { nonprofit } = match.params;
    const { Live } = this.props;
    const { query, exportFilters } = this.state;
    Live.generateReportLink({ ...query }, exportFilters, { filter_by_nonprofit: nonprofit })?.then((data) => {
      Live.getReportLinks().then((data) => {
        this.setState({ reportLinks: data, currentTab: 'downloads', loader: true });
        setTimeout(() => {
          this.setState({
            loader: false
          })
        }, 2000);
      });
    });
  }

  getReportLinks = (e, isExport) => {
    e.preventDefault();
    const { Live } = this.props;
    this.setState({ loader: true })
    if (isExport) {
      this.setState({
        currentTab: isExport
      })
    }
    Live.getReportLinks().then((data) => {
      setTimeout(() => {
        this.setState({
          loader: false
        })
      }, 2000);
      this.setState({ reportLinks: data });
    });
  }

  onTabChange = (e, tab) => {
    this.setState({
      currentTab: tab
    })
    if (tab === 'downloads') {
      this.getReportLinks(e, 'downloads')
    }
  }

  fitersForExport = (filter) => {
    const { exportFilters } = this.state;
    if (!exportFilters.includes(filter))
      this.setState({ exportFilters: [...exportFilters, filter] });
    else
      this.setState({ exportFilters: exportFilters.filter(exportFilters => exportFilters !== filter) });
  }

  popover = () => {
    const { live } = this.props;
    const { reportLinks } = this.state;
    const fieldNames = {
      'basic': '(Date, Time, Transaction ID, Donation Currency, Donation Amount, Donor Name, Donor Email, Donor Mobile Number, Donor Address Line 1, Donor Address Line 2, Donor City, Donor Town / State, Donor Country, Donor Zipcode / Pincode, Donor Complete Address, Donor PAN)',
      'nonprofit': '(Fundraiser ID, Fundraiser Name, Fundraiser Link)',
      'project': '(Project ID, Project Name, Project Link)',
      'receipt': '(Receipt URL)',
      'disbursement': '(Disbursement Number, Disbursement Date,  Settlement Date)',
      'additional_fields': '(Referer, Redirect URL)'
    }

    return (
      <Popover id="popover-basic-1">
        <Popover.Content>
          <Row>
            <Col md={12} xs={12} className="export-download">
              <ul className="nav nav-tabs tab-basic" role="tablist">
                <li className="nav-item">
                  <a className={`${`nav-link ${this.state.currentTab === 'export' ? `active` : ''} show`}`} id="export-tab" data-toggle="tab" onClick={(e) => this.onTabChange(e, 'export')} href="#export">Export</a>
                </li>
                <li className="nav-item">
                  <a className={`${`nav-link ${this.state.currentTab === 'downloads' ? `active` : ''} show`}`} id="download-tab" data-toggle="tab" href="#download" onClick={(e) => this.onTabChange(e, 'downloads')}>Downloads</a>
                </li>
              </ul>
              <div className="tab-content tab-content-basic">
                {
                  this.state.currentTab === 'export' ?
                    <div className="tab-pane fade show active">
                      <Col md={12} xs={12} className="export-filters">
                        <Popover.Title as="h3">Select Fields</Popover.Title>
                        <Form.Check className="popover-checkbox" type="checkbox" id="basic" label="Basic Details" disabled checked />
                        <div className="popover-checkbox-div" htmlFor="basic">{fieldNames.basic}</div>
                        <Form.Check className="popover-checkbox" id="campaign" type="checkbox" label="Campaign" onClick={() => this.fitersForExport('campaign')} />
                        <div className="popover-checkbox-div" htmlFor="campaign">{fieldNames.nonprofit}</div>
                        <Form.Check className="popover-checkbox" id="project" type="checkbox" label="Project" onClick={() => this.fitersForExport('project')} />
                        <div className="popover-checkbox-div" htmlFor="project" >{fieldNames.project}</div>
                        <Form.Check className="popover-checkbox" id="receipt" type="checkbox" label="Receipt" onClick={() => this.fitersForExport('receipt')} />
                        <div className="popover-checkbox-div" htmlFor="receipt">{fieldNames.receipt}</div>
                        <Form.Check className="popover-checkbox" id="disbursement" type="checkbox" label="Disbursement" onClick={() => this.fitersForExport('disbursement')} />
                        <div className="popover-checkbox-div" htmlFor="disbursement">{fieldNames.disbursement}</div>
                        <Form.Check className="popover-checkbox" id="additional" type="checkbox" label="Additional Fields" onClick={() => this.fitersForExport('additional_fields')} style={{ marginTop: '10px' }} />
                        <div className="popover-checkbox-div" htmlFor="additional" >{fieldNames.additional_fields}</div>

                        <div className="text-center">
                          <Button className="btn btn-sm" onClick={this.generateReportLink}>{live.get_status.generate_report === '' ? 'Export to CSV' : live.get_status.generate_report === 'pending' ? 'Generating...' : 'Export to CSV'}</Button>
                        </div>
                      </Col>
                    </div>
                    :
                    <div className="tab-pane fade show active">
                      <Col md={12} xs={12}>
                        <Popover.Title as="h3">
                          <span>Queued for download</span>
                          <button type="button" className="btn btn-inverse-link" onClick={this.getReportLinks}>
                            <div className="export">
                              <div className={`${this.state.loader ? `export-loader-spin` : `export-loader`}`}></div>
                            </div>
                          </button>
                        </Popover.Title>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Requested</th>
                              <th>Tags</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {(!reportLinks || reportLinks.length === 0) &&
                      <i className="align-center">No items available</i>} */}
                            {reportLinks && reportLinks.map((link, index) => {
                              let filterAppl = link.queryParameter.filter;
                              return (
                                <tr key={index}>
                                  <td style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>
                                    <div className="download-tag-wrapper">
                                      <span className="download-tag">{format(new Date(link.created_at), "MMM do, yyyy HH:mm aa")}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="download-tag-wrapper">
                                      {['a'].map(item => (
                                        <>
                                          {
                                            filterAppl &&
                                            <>
                                              {filterAppl.endDate ? <span className="download-tag">Created before: {format(new Date(filterAppl.endDate), "MMM do, yyyy")}</span> : ''}
                                              {filterAppl.startDate ? <span className="download-tag">Created after: {format(new Date(filterAppl.startDate), "MMM do, yyyy")}</span> : ''}
                                              {filterAppl.email ? <span className="download-tag">Email Id: {filterAppl.email}</span> : ''}
                                              {filterAppl.campaigns ? <span className="download-tag">Fundraiser: {filterAppl.campaigns}</span> : ''}
                                              {filterAppl.nonprofitId ? <span className="download-tag">nonprofitId: {filterAppl.nonprofitId}</span> : ''}
                                              {filterAppl.orderId ? <span className="download-tag">Order Id: {filterAppl.orderId}</span> : ''}
                                              {filterAppl.phone ? <span className="download-tag">Phone: {filterAppl.phone}</span> : ''}
                                              {filterAppl.projects.length ? <span className="download-tag">Project: {filterAppl.projects}</span> : null}
                                            </>
                                          }
                                        </>
                                      ))}
                                    </div>
                                  </td>
                                  <td><a className={`btn btn-xs ${link.status === 'completed' ? 'btn-success' : 'disabled btn-secondary'}`} href={link.dataLink} download={`GiveFundraisers-live-${format(new Date(), 'HH:mm:ss')}`}>{link.status === 'completed' ? 'Download' : link.status}</a></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        {/* <ListGroup className="text-center">
                  {(!reportLinks || reportLinks.length === 0) &&
                    <i className="align-center">No items available</i>}
                  {reportLinks && reportLinks.map((link, index) => {
                    return (
                      <ListGroup.Item key={index}></ListGroup.Item>
                    )
                  })}
                </ListGroup> */}
                      </Col>
                    </div>
                }
              </div>
            </Col>
          </Row>
          <Row>
          </Row>
        </Popover.Content>
      </Popover>
    )
  }

  donationsHTML = () => {
    const { live } = this.props;
    const { viewDetails } = this.state;
    if (!live.get_status.donation || live.get_status.donation === 'pending' && live.donations.length === 0) {
      return (<Loading />);
    }
    if (live.donations.length === 0) {
      return (
        <i className="align-center">No items available</i>
      );
    }
    return live.donations.map(item => (
      <li className="table-list-item" key={item.order_id}>
        <div className="row">
          <div className="col-md-12 table-col">
            <div className="row">
              <div className="col-md-2 col-12 table-col dl-mob">
                <small style={{ display: 'block' }}>{format(new Date(item?.created_at), 'do MMM yyyy')}</small>
                <small style={{ display: 'block' }}>{format(new Date(item?.created_at), 'HH:mm')}</small>
              </div>
              {(item.user) ? (
                <div className="col-md-3 col-12 table-col dl-mob user-info-livedis">
                  <img alt="image" className="img-circle width-30" src={item.user.profile_pic} />
                  <div className="name-email">
                    <span>{item.user.name}</span>
                    <small>{item.user.email}</small>
                  </div>
                </div>
              ) : (
                <div className="col-md-3 col-12 table-col dl-mob user-info-livedis">
                  User Not Available
                </div>
              )}
              <div className="col-md-5 col-12 table-col dl-mob">
                <div className="row">
                  {item.project ? (<div className="col-12">
                    <strong>(P) </strong> <a target="_blank" href={item.project.permalink}>{item.project.name}</a>
                    {this.addToFilterHTML('Project', [{ title: `Project: ${item.project.name}`, data: { filter_by_project: item.project._id } }])}
                  </div>) : null}
                  {item.campaign ? (<div className="col-12">
                    <strong>(C) </strong> <a target="_blank" href={item.campaign.permalink}>{item.campaign.title}</a>
                    {this.addToFilterHTML('Fundraiser', [{ title: `Fundraiser: ${item.campaign.title}`, data: { filter_by_campaign: item.campaign._id } }])}
                  </div>) : null}
                </div>
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                {item.currency_code}&nbsp;{item.amount.toLocaleString('en-in')}
              </div>
              <div className="col-md-1 col-12 table-col dl-mob-right">
                {(viewDetails === item.order_id) ? (
                  <button type="button" className="btn btn-outline-secondary" onClick={(e) => { e.preventDefault(); this.showDetailsClick(item.order_id) }}>Hide Details</button>
                ) : (
                  <button type="button" className="btn btn-outline-secondary" onClick={(e) => { e.preventDefault(); this.showDetailsClick(item.order_id) }}>View Details</button>
                )}
              </div>
            </div>
          </div>
          {(viewDetails === item.order_id) ? (
            <div className="col-md-12 disbursement-view-details">
              <div className="ibox float-e-margins">
                <div className="ibox-content ibox-heading">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <h6 className="left">Other details</h6> */}
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4 bold">Order Id :</div>
                        <div className="col-md-8 dl-mob">{item.order_id}</div>
                        <div className="col-md-4 bold">Is Anonymous :</div>
                        <div className="col-md-8 dl-mob">{(item.is_anonymous) ? 'YES' : 'NO'}</div>
                        <div className="col-md-4 bold">Mobile No :</div>
                        <div className="col-md-8 dl-mob">{(item.user && item.user.phone) ? (<span>{item.user.phone}</span>) : 'NA'}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-4 bold">Address :</div>
                        <div className="col-md-8">{(item.user && item.user.address) ? item.user.address.formatted : 'NA'}</div>
                        <div className="col-md-4 bold">Receipt URL :</div>
                        {item.receipt_uri ? (
                          <div className="col-md-8">
                            <div
                              className="download-title"
                              onClick={() => {
                                downloadPdf(item.receipt_uri)
                              }}
                            >Download
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-8">Not available</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </li>
    ));
  }

  render() {
    const { live } = this.props;
    const { filters } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">All donations which are not yet disbursed</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} />
                <div className="row justify-content-end">
                  <div className="col-md-12 col-12 text-right">
                    <OverlayTrigger trigger="click" placement={isMobile ? "bottom-end" : "left-start"} overlay={this.popover()} rootClose>
                      <button type="button" className="btn btn-secondary" onClick={(e) => this.getReportLinks(e, 'export')}>
                        Proceed to Export
                      </button>
                    </OverlayTrigger>
                    <div>
                      <i><small className="text-muted">CSV file contains max of 1000 entries</small></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card-col col-xl-12 col-12">
                <div className="ibox-content">
                  <div className="table-list live-dis-table">
                    <ul>
                      <li className="table-head">
                        <div className="row">
                          <div className="col-md-12 table-col">
                            <div className="row">
                              <div className="col-md-2 table-col dl-mob">Date</div>
                              <div className="col-md-3 table-col dl-mob">Donor</div>
                              <div className="col-md-5 table-col dl-mob">Project(P) / Fundraisers(F)</div>
                              <div className="col-md-1 table-col dl-mob">Amount</div>
                              <div className="col-md-1 table-col dl-mob-right">Details</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {this.donationsHTML()}
                    </ul>
                    <div className="row">
                      {(live.load_status.donation) ? (
                        <div className="col-md-12 text-center">
                          <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                        </div>
                      ) : null}
                      {(live.get_status.donation === 'pending' && live.donations.length !== 0) ? (
                        <div className="col-md-12">
                          <Loading />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  live: state.crowdfundingLiveDonation,
});

const mapActionToProps = dispatch => ({
  Live: bindActionCreators(liveDonationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(LiveDonations);
