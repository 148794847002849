import React from 'react';
import { formatDistanceToNow, format } from 'date-fns';

import { Link } from 'react-router-dom';

import './ProjectTile.scss';
import { isTemplateElement } from '@babel/types';

function ProjectTile(props) {
  const { value, link } = props;
  const { name, image, _id, nonprofit, created_at, updated_at, status } = value;
  return (
    <div className="project-tiles">
      <div className="ob-status pending">{status}</div>
      <Link to={`${link}${_id}`}>
        <div style={{ height: '145px', width: '218px' }}>
          {image ? (
            <img className="card-img-top" src={image} alt="card images" />
          ): (
            <span>Image not available</span>
          )}
        </div>
        <div className="p-2 pb-0">
          <div className="p-name">{name ? name : 'Name not available'}</div>
          <div className="border-bar"></div>
          <div className="by-ngo">
            {(nonprofit && nonprofit.image) ? (<img src={nonprofit.image} alt="" />) : null}
            <div className="ngo-name">
              {(nonprofit && nonprofit.name) ? (<b>{nonprofit.name}</b>) : 'Nonprofit not available'}
            </div>
          </div>
          <div className="d-flex justify-content-between border-top py-2 mt-3">
            <div className="text-muted d-flex flx-dir-col align-items-start">
              <p className="mb-0">Created</p>
              <small>{format(new Date(created_at), 'do MMM yyyy')}</small>
            </div>
            <div className="text-muted d-flex flx-dir-col align-items-end">
              <p className="mb-0">Updated</p>
              <small>{format(new Date(updated_at), 'do MMM yyyy')}</small>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default ProjectTile;
