import React, { Component } from 'react';

import Icon from '@mdi/react';
import { mdiPlus, mdiClose, mdiTrashCanOutline } from '@mdi/js';

import './ProjectOnboardingEdit.css';
import GoogleCityInput from '../GoogleCityInput/GoogleCityInput';
import SelectInput from '../SelectInput';
import ImageCropUpload from '../ImageCropUpload';
import Editor from '../Editor';

import Helpers from '../../utils/helpers';

const regionOptions = [{ label: 'Andaman & Nicobar Islands', value: 'Andaman & Nicobar Islands' }, { label: 'Andhra Pradesh', value: 'Andhra Pradesh' }, { label: 'Assam', value: 'Assam' }, { label: 'Chandigarh', value: 'Chandigarh' }, { label: 'Dadra & Nagar Haveli', value: 'Dadra & Nagar Haveli' }, { label: 'Delhi', value: 'Delhi' }, { label: 'Gujarat', value: 'Gujarat' }, { label: 'Haryana', value: 'Haryana' }, { label: 'Himachal Pradesh', value: 'Himachal Pradesh' }, { label: 'Jammu & Kashmir', value: 'Jammu & Kashmir' }, { label: 'Kerala', value: 'Kerala' }, { label: 'Lakshadweep', value: 'Lakshadweep' }, { label: 'Maharashtra', value: 'Maharashtra' }, { label: 'Manipur', value: 'Manipur' }, { label: 'Meghalaya', value: 'Meghalaya' }, { label: 'Karnataka', value: 'Karnataka' }, { label: 'Nagaland', value: 'Nagaland' }, { label: 'Odisha', value: 'Odisha' }, { label: 'Pondicherry', value: 'Pondicherry' }, { label: 'Punjab', value: 'Punjab' }, { label: 'Rajasthan', value: 'Rajasthan' }, { label: 'Tamil Nadu', value: 'Tamil Nadu' }, { label: 'Tripura', value: 'Tripura' }, { label: 'West Bengal', value: 'West Bengal' }, { label: 'Sikkim', value: 'Sikkim' }, { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' }, { label: 'Mizoram', value: 'Mizoram' }, { label: 'Daman & Diu', value: 'Daman & Diu' }, { label: 'Goa', value: 'Goa' }, { label: 'Bihar', value: 'Bihar' }, { label: 'Madhya Pradesh', value: 'Madhya Pradesh' }, { label: 'Uttar Pradesh', value: 'Uttar Pradesh' }, { label: 'Chattisgarh', value: 'Chattisgarh' }, { label: 'Jharkhand', value: 'Jharkhand' }, { label: 'Uttarakhand', value: 'Uttarakhand' }, { label: 'Telangana', value: 'Telangana' }];

class ProjectOnboardingEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectLocation: '',
      isChecked: false,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const sites = value.sites || [];
    const tags = value.tags || [];
    if (sites.length > 0) {
      const site = sites[0];
      let projectLocation = 'city';
      if (!site.city || site.city === '') {
        projectLocation = 'region';
      }
      if (!(site.region || site.city) || (site.region === '' && site.city === '')) {
        projectLocation = 'india';
      }
      this.setState({ projectLocation });
    }
    if (!value.project_costs || (value.project_costs  && value.project_costs.length === 0)) {
      this.setDefaultProjectCost();
    }
    if(tags.length > 0) {
      if(tags.indexOf('indiafightscorona') > -1) {
        this.setState({ isChecked : true })
      }
    }
  }

  getStateData = (from) => {
    const { state } = this;
    const { value } = this.props;
    if (state[from[0]] && from.length === 1 && from[0] !== 'project') {
      return state[from[0]];
    }
    if (value[from[1]] && from.length === 2 && from[0] === 'project') {
      return value[from[1]];
    }
    if (value.causes && from.length === 3 && from[0] === 'project' && from[1] === 'causes') {
      if (value.causes[from[2]]) {
        return value.causes[from[2]];
      }
    }
    if (value.donation_options && from.length === 4 && from[0] === 'project' && from[1] === 'donation_options' && (from[2] === 0 || from[2] === 1 || from[2] === 2)) {
      if (value.donation_options[from[2]] && value.donation_options[from[2]][from[3]] && value.donation_options[from[2]][from[3]] !== 0) {
        return value.donation_options[from[2]][from[3]];
      }
    }
    if (value.project_costs && from.length === 4 && from[0] === 'project' && from[1] === 'project_costs') {
      if (value.project_costs[from[2]] && value.project_costs[from[2]][from[3]] && value.project_costs[from[2]][from[3]] !== 0) {
        return value.project_costs[from[2]][from[3]];
      }
    }
    return '';
  }

  saveValue = (value) => {
    if(value === null || value === '' || value === undefined) {
      return null;
    }
    return value;
  }

  setStateData = (e, to) => {
    const { value, onChange } = this.props;
    const state = {};
    const data = { ...value };
    let dataUpdated = false;

    if (to.length === 1 && to[0] !== 'project') {
      state[to[0]] = e.target.value;
      if (to[0] === 'projectLocation') {
        dataUpdated = true;
        if (e.target.value === 'india') {
          data.sites = [{ country_code: 'IN' }];
        } else {
          data.sites = [];
        }
      }
    }
    if (to.length === 2 && to[0] === 'project') {
      dataUpdated = true;
      if (to[1] === 'sites' || to[1] === 'issue' || to[1] === 'action' || to[1] === 'impact' || to[1] === 'cover_image' || to[1] === 'image' || to[1] === 'project_costs') {
        data[to[1]] = this.saveValue(e);
      } else if(to[1] === 'tags') {
        this.setState({isChecked: !this.state.isChecked}, () => {
          if(data[to[1]] !== undefined) {
            const newArr = !this.state.isChecked ? Helpers.addRemoveValuesArray(data[to[1]], 'indiafightscorona', 'remove') : Helpers.addRemoveValuesArray(data[to[1]], 'indiafightscorona', 'add');
            data[to[1]] = [...newArr]
          } else {
            const newArr = this.state.isChecked && Helpers.addRemoveValuesArray([], 'indiafightscorona', 'add');
            data['tags'] = [...newArr]
          }  
        })
      } 
      else {
        data[to[1]] = this.saveValue(e.target.value);
      }
    }
    if (to.length > 2 && to[0] === 'project' && to[1] === 'causes') {
      dataUpdated = true;
      if (!data.causes) {
        data.causes = [];
      }
      data.causes[to[2]] = this.saveValue(e.target.value);
    }
    if (to.length > 3 && to[0] === 'project' && to[1] === 'donation_options' && (to[2] === 0 || to[2] === 1 || to[2] === 2)) {
      dataUpdated = true;
      if (!data.donation_options) {
        data.donation_options = [];
      }
      if (!data.donation_options[to[2]]) {
        data.donation_options[to[2]] = {
          amount: 0,
          currency_code: 'INR',
          title: '',
        };
      }
      data.donation_options[to[2]][to[3]] = e.target.value;
    }
    if (to.length > 3 && to[0] === 'project' && to[1] === 'project_costs') {
      dataUpdated = true;
      const projectCost = [...data.project_costs];
      projectCost[to[2]][to[3]] = e.target.value;
      data.project_costs = projectCost;
      if (to[3] === 'value') {
        let totalAmount = 0;
        projectCost.forEach((item) => {
          if (parseInt(item.value, 10)) {
            totalAmount += parseInt(item.value, 10);
          }
        });
        data.target_amount = totalAmount;
      }
    }
    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
    if (dataUpdated && onChange) {
      onChange(data);
    }
  }

  setDefaultProjectCost = () => {
    const projectCost = [{
      title: 'Administrative Cost (rent,electricity etc.)',
      value: 0,
    }, {
      title: 'Salary',
      value: 0,
    }, {
      title: 'Direct Project Costs',
      value: 0,
    }, {
      title: 'Infrastructure (computers, land, vehicle, etc)',
      value: 0,
    }, {
      title: 'Transportation',
      value: 0,
    }, {
      title: 'Awareness & Empowerment (capacity building, workshops,meetings)',
      value: 0,
    }, {
      title: 'Fundraising Expenses',
      value: 0,
    }];
    this.setStateData(projectCost, ['project', 'project_costs']);
  }

  removeProjectCost = (index) => {
    const { value, onChange } = this.props;
    const data = { ...value };
    const projectCost = [...data.project_costs];
    projectCost.splice(index, 1);
    data.project_costs = projectCost;
    let totalAmount = 0;
    projectCost.forEach((item) => {
      if (parseInt(item.value, 10)) {
        totalAmount += parseInt(item.value, 10);
      }
    });
    data.target_amount = totalAmount;
    if (onChange) {
      onChange(data);
    }
  }

  addProjectCost = () => {
    const { value, onChange } = this.props;
    const data = { ...value };
    const projectCost = [...data.project_costs];
    projectCost.push({
      title: '',
      value: 0,
    });
    data.project_costs = projectCost;
    if (onChange) {
      onChange(data);
    }
  }

  projectLocationText = (location, type) => {
    switch (location) {
      case 'city':
        if (type === 'p') return 'Cities';
        return 'city';
      case 'region':
        if (type === 'p') return 'States';
        return 'state';
      default:
    }
    return '';
  }

  setCitySelect = (city) => {
    const { value } = this.props;
    const { sites } = value;
    sites.push(city);
    this.setStateData(sites, ['project', 'sites']);
  }

  setRegionSelect = (regions) => {
    const sites = regions.map(item => ({
      country_code: 'IN',
      region: item.value,
    }));
    this.setStateData(sites, ['project', 'sites']);
  }

  getRegionSelectValue = () => {
    const sites = this.getStateData(['project', 'sites']);
    const returnValue = sites.map(item => ({
      label: item.region,
      value: item.region,
    }));
    return returnValue;
  }

  deleteCity = (index) => {
    const { value, onChange } = this.props;
    const data = { ...value };
    const sites = [...data.sites];
    sites.splice(index, 1);
    data.sites = [...sites];
    if (onChange) {
      onChange(data);
    }
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    e.preventDefault();
    if (onSubmit) {
      onSubmit();
    }
  }

  getErrorMessage = (forList) => {
    const { errors } = this.props;
    const forItem = forList.slice(1, forList.length).join('.');
    let returnValue = null;
    if (errors && errors.length > 0) {
      errors.forEach((item) => {
        if (item[forItem]) {
          returnValue = (
            <div className="error-message">
              {item[forItem]}
            </div>
          );
        } else if(forList[1] === 'sites') {
          if(Object.keys(item)[0].indexOf('sites') > -1) {
            returnValue = (
              <div className="error-message">
                {Object.values(item)[0]}
              </div>
            );
          }
        }
      });
    }
    return returnValue;
  }

  render() {
    const { children, causes } = this.props;
    return (
      <form autoComplete="off" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">General Details</h3>
                {/* <p className="text-secondary"> Nonprofit general details </p> */}

                <div className="form-group">
                  <label>Name of the Project</label>
                  {this.getErrorMessage(['project', 'name'])}
                  <input type="text" className="form-control" placeholder="Project name" value={this.getStateData(['project', 'name'])} onChange={(e) => { this.setStateData(e, ['project', 'name']); }} />
                </div>
                <div className="form-group">
                  <label>Issue (What is the concern? - Max 200 words)</label>
                  {this.getErrorMessage(['project', 'issue'])}
                  <Editor value={this.getStateData(['project', 'issue'])} onChange={(e) => { this.setStateData(e, ['project', 'issue']); }} />
                </div>
                <div className="form-group">
                  <label>Action (What are you going to do about it? - Max 200 words)</label>
                  {this.getErrorMessage(['project', 'action'])}
                  <Editor value={this.getStateData(['project', 'action'])} onChange={(e) => { this.setStateData(e, ['project', 'action']); }} />
                </div>
                <div className="form-group">
                  <label>Impact (What is the impact that you wish to create? - Max 200 words)</label>
                  {this.getErrorMessage(['project', 'impact'])}
                  <Editor value={this.getStateData(['project', 'impact'])} onChange={(e) => { this.setStateData(e, ['project', 'impact']); }} />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Primary Cause</label>
                      {this.getErrorMessage(['project', 'causes'])}
                      <select className="form-control" value={this.getStateData(['project', 'causes', 0])} onChange={(e) => { this.setStateData(e, ['project', 'causes', 0]); }}>
                        <option disabled hidden value="">Select</option>
                        {causes.map(item => (
                          <option value={item.name} key={item.slug}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Secondary Cause</label>
                      {this.getErrorMessage(['project', 'causes'])}
                      <select className="form-control" value={this.getStateData(['project', 'causes', 1])} onChange={(e) => { this.setStateData(e, ['project', 'causes', 1]); }}>
                        <option disabled hidden value="">Select</option>
                        {causes.map(item => (
                          <option value={item.name} key={item.slug}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Project Location(s):</h3>
                {/* <p className="text-secondary"> You can select multiple locations(locations can include city, state or just india) </p> */}
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Project is</label>
                      {this.getErrorMessage(['project', 'sites'])}
                      <select className="form-control" value={this.getStateData(['projectLocation'])} onChange={(e) => { this.setStateData(e, ['projectLocation']); }}>
                        <option value="" disabled hidden>Select</option>
                        <option value="city">Implemented in single/multiple Cities</option>
                        <option value="region">Implemented in single/multiple States</option>
                        <option value="india">Implemented PAN India</option>
                      </select>
                    </div>
                  </div>
                  {(this.getStateData(['projectLocation']) === 'city') ? (
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Type {this.projectLocationText(this.getStateData(['projectLocation']), 's')} name and select from suggestions</label>
                        <GoogleCityInput onSelect={this.setCitySelect} />
                      </div>
                    </div>
                  ) : null}
                  {((this.getStateData(['projectLocation']) === 'city') && this.getStateData(['project', 'sites']).length > 0) ? (
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Project Location(s) selected (You can select multiple {this.projectLocationText(this.getStateData(['projectLocation']), 'p')})</label>
                        <div>
                          {this.getStateData(['project', 'sites']).map((item, index) => (
                            <button type="button" className="badge badge-primary" key={index} onClick={() => { this.deleteCity(index); }}>
                              {(item.city) ? `${item.city}, ${item.region}, India` : null}
                              {(!item.city) ? `${item.region}, India` : null}
                              <Icon path={mdiClose} className="menu-arrow" size={0.60} color="#ffffff" />
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {(this.getStateData(['projectLocation']) === 'region') ? (
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Type {this.projectLocationText(this.getStateData(['projectLocation']), 's')} name and select from suggestions</label>
                        <SelectInput options={regionOptions} onChange={(e) => { this.setRegionSelect(e); }} isMulti defaultValue={this.getRegionSelectValue()} />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Project Images</h3>
                {/* <p className="text-secondary"> Nonprofit general details </p> */}
                <ImageCropUpload title="Please select Cover Image for Project" label="Cover Image" height={300} width={1200} value={this.getStateData(['project', 'cover_image'])} onChange={(e) => { this.setStateData(e, ['project', 'cover_image']); }} error={this.getErrorMessage(['project', 'cover_image'])} />
                <ImageCropUpload title="Please select Tile Image for Project" label="Tile Image" height={200} width={300} value={this.getStateData(['project', 'image'])} onChange={(e) => { this.setStateData(e, ['project', 'image']); }} error={this.getErrorMessage(['project', 'image'])} />
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Donation Options</h3>
                {this.getErrorMessage(['project', 'donation_options'])}
                {/* <p className="text-secondary"> Nonprofit general details </p> */}
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <p>Amount</p>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <p>Donation Description</p>
                  </div>
                </div>
                <div className="row tm-divider">
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">INR</span>
                        </div>
                        <input type="number" className="form-control" placeholder="Amount eg 500, 1000" value={this.getStateData(['project', 'donation_options', 0, 'amount'])} onChange={(e) => { this.setStateData(e, ['project', 'donation_options', 0, 'amount']); }} />
                      </div>
                      {this.getErrorMessage(['project', 'donation_options', 0, 'amount'])}
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Description" value={this.getStateData(['project', 'donation_options', 0, 'title'])} onChange={(e) => { this.setStateData(e, ['project', 'donation_options', 0, 'title']); }} />
                      {this.getErrorMessage(['project', 'donation_options', 0, 'title'])}
                    </div>
                  </div>
                </div>
                <div className="row tm-divider">
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">INR</span>
                        </div>
                        <input type="number" className="form-control" placeholder="Amount eg 500, 1000" value={this.getStateData(['project', 'donation_options', 1, 'amount'])} onChange={(e) => { this.setStateData(e, ['project', 'donation_options', 1, 'amount']); }} />
                      </div>
                      {this.getErrorMessage(['project', 'donation_options', 1, 'amount'])}
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Description" value={this.getStateData(['project', 'donation_options', 1, 'title'])} onChange={(e) => { this.setStateData(e, ['project', 'donation_options', 1, 'title']); }} />
                      {this.getErrorMessage(['project', 'donation_options', 1, 'title'])}
                    </div>
                  </div>
                </div>
                <div className="row tm-divider">
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">INR</span>
                        </div>
                        <input type="number" className="form-control" placeholder="Editable Option. Amount eg 500, 1000" value={this.getStateData(['project', 'donation_options', 2, 'amount'])} onChange={(e) => { this.setStateData(e, ['project', 'donation_options', 2, 'amount']); }} />
                      </div>
                      {this.getErrorMessage(['project', 'donation_options', 2, 'amount'])}
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Description" value={this.getStateData(['project', 'donation_options', 2, 'title'])} onChange={(e) => { this.setStateData(e, ['project', 'donation_options', 2, 'title']); }} />
                      {this.getErrorMessage(['project', 'donation_options', 2, 'title'])}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Cost Break-Up & Target Amount</h3>
                {/* <p className="text-secondary"> Nonprofit general details </p> */}
                <div className="row">
                  <div className="col-lg-6 col-md-5">
                    <p>Particulars</p>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <p>Amount</p>
                  </div>
                </div>
                {(this.getStateData(['project', 'project_costs']) || []).map((item, index) => (
                  <div className="row tm-divider" key={index}>
                    <div className="col-lg-6 col-md-5">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Particulars" value={this.getStateData(['project', 'project_costs', index, 'title'])} onChange={(e) => { this.setStateData(e, ['project', 'project_costs', index, 'title']); }} />
                        {this.getErrorMessage(['project', 'project_costs', index, 'title'])}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">INR</span>
                          </div>
                          <input type="number" className="form-control" placeholder="Amount" value={(this.getStateData(['project', 'project_costs', index, 'value']))} onChange={(e) => { this.setStateData(e, ['project', 'project_costs', index, 'value']); }} />
                        </div>
                        {this.getErrorMessage(['project', 'project_costs', index, 'value'])}
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 tm-remove">
                      <button type="button" className="btn btn-light" onClick={() => { this.removeProjectCost(index); }}>
                        <Icon path={mdiTrashCanOutline} className="menu-arrow" size={0.60} color="#333333" />
                        &nbsp;Remove
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-md-12">
                    <button type="button" className="btn btn-info mr-2" onClick={() => { this.addProjectCost(); }}>
                      <Icon path={mdiPlus} className="menu-arrow" size={0.60} color="#ffffff" />
                      &nbsp;Add
                    </button>
                  </div>
                </div>
                <div className="row justify-content-end mr-t-20">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Target amount</label>
                      {this.getErrorMessage(['project', 'target_amount'])}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">INR</span>
                        </div>
                        <input type="text" className="form-control" placeholder="0" disabled value={this.getStateData(['project', 'target_amount'])} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Project Tagging</h3>
                <input onChange={(e) => { this.setStateData(e, ['project', 'tags']); }} type="checkbox" checked={this.state.isChecked}/>
                <label style={{marginLeft:'5px'}}>Are you raising funds for COVID-19 support?</label>
              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ProjectOnboardingEdit;
