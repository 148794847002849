import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helpers from '../../utils/helpers';
import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';
class CampaignRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
      projectLocation: '',
      prevValue: {},
      selectedGalleryItem: 0,
    };
  }

  switchView = (view) => {
    this.setState({ view: view });
  }

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if((val === '' || val === null || val === undefined) && key[1] !== 'video' && key[1] !== 'donation_options') {
      return this.na;
    }
    return val;
  }

  na = (<i>Not available</i>);

  getErrorMessage = (forList) => {
    const { errors } = this.props;
    const forItem = forList.slice(1, forList.length).join('.');
    let returnValue = null;
    if (errors && errors.length > 0) {
      errors.forEach((item) => {
        if (item[forItem]) {
          returnValue = (
            <div className="error-message">
              {item[forItem]}
            </div>
          );
        }
      });
    }
    return returnValue;
  }

  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if(to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if(to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if(to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if(to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }

  render() {
    const { children, tabs } = this.props;
    const { view, selectedGalleryItem } = this.state;

    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
            
              <div className="heading-bar">
              <h3 className="mr-b-20">Default Fundraiser Template</h3>
                <div className="heading-bar-btn">
                  {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab=>{
                    if (tab === view) {
                      return (
                      <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                      );
                    }
                    return (
                    <button type="button" className="btn btn-outline-secondary btn-right" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                    );
                  })}
                </div>
              </div>

              <ApprovalsRejectionsButtons path={['project', 'campaign_templates', 0]} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
              
                <div>

                  {this.props.login.user.is_admin && 
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <h4>Fundraiser Title</h4>
                          {this.getInputData(['project', 'campaign_templates', 'title']) !== this.na ? 
                            <p className="text-muted">{this.getInputData(['project', 'campaign_templates', 'title'])}</p> : 
                            <i className="text-muted">Not available</i>
                          }
                        </div>
                      </div>
                    </div>
                  }

                  <div className="row">
                    <div className="col-md-12">
                      <div className="campaign-gallery pb-3 mb-3">
                        <h4>Fundraiser Gallery</h4>
                        <div className="mr-t-20 mr-b-20">
                          {(this.getInputData(['project', 'campaign_templates', 'gallery']) === this.na) ? (
                            <h5 className="txt-center"><i className="text-muted">Nothing here, Please click on edit and add images or videos</i></h5>
                          ) :
                            <div>
                              <div className="cg-cover">
                                {(this.getInputData(['project', 'campaign_templates', 'gallery']) === this.na || this.getInputData(['project', 'campaign_templates', 'gallery']).length === 0) ? 
                                  <h5 className="txt-center"><i className="text-muted">Nothing here, no images or videos</i></h5> : 
                                  this.getInputData(['project', 'campaign_templates', 'gallery'])[selectedGalleryItem].media_type === 'gallery_image' ? 
                                      <img src={`${this.getInputData(['project', 'campaign_templates', 'gallery'])[selectedGalleryItem].uri}`}/> : 
                                      <iframe width="100%" height="100%" src={`${this.getInputData(['project', 'campaign_templates', 'gallery'])[selectedGalleryItem].uri}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
                              </div>
                              <div className="gl-list">
                                {(this.getInputData(['project', 'campaign_templates', 'gallery']) !== this.na && this.getInputData(['project', 'campaign_templates', 'gallery']).length > 0) ? this.getInputData(['project', 'campaign_templates', 'gallery']).map((d, i) => {
                                  if (d.media_type === 'gallery_image') {
                                    return (
                                      <div className={`gl-item ${selectedGalleryItem === i ? 'gl-item-selected' : ''}`} key={d.uri}>
                                        <div className="img-wrapper" onClick={() => this.setState({selectedGalleryItem : i})}>
                                          <img src={d.uri} alt="" />
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className={`gl-item ${selectedGalleryItem === i ? 'gl-item-selected' : ''}`} key={d.uri}>
                                        <div className="img-wrapper" onClick={() => this.setState({selectedGalleryItem : i})}>
                                          <img src={`https://i1.ytimg.com/vi/${Helpers.getYoutubeId(d.uri)}/mqdefault.jpg`} alt="" />
                                        </div>
                                      </div>
                                    )
                                  }
                                }) : null}
                              </div>
                            </div>
                          }
                        </div>
                        
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="pb-3 mb-3">
                        <h4>Fundraiser Story</h4>                      
                        {this.getInputData(['project', 'campaign_templates', 'description']) !== this.na ? 
                          <div className="text-muted" dangerouslySetInnerHTML={{ __html: this.getInputData(['project', 'campaign_templates', 'description']) }}></div> :
                          <i className="text-muted">Not available</i>
                        }
                      </div>
                    </div>
                  </div>
              
                  <div className="row">
                    <div className="col-md-6">
                      <div className="pb-3 mb-3">
                        <h4>Fundraiser Duration (in Days)</h4>
                        <p>(0 if you don't want to set default)</p>
                        {this.getInputData(['project', 'campaign_templates', 'expiry_in_days']) !== this.na ? 
                          <p className="text-muted mr-b-0">{this.getInputData(['project', 'campaign_templates', 'expiry_in_days'])}</p> : 
                          <i className="text-muted">Not available</i>
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pb-3 mb-3">
                        <h4>Show project in the story?</h4>
                        <p>&nbsp;</p>                      
                        {this.getInputData(['project', 'campaign_templates', 'show_project']) !== this.na ? 
                          <p className="text-muted mr-b-0">{this.getInputData(['project', 'campaign_templates', 'show_project']) ? 'Yes' : 'No'}</p> :
                          <i className="text-muted">Not available</i>
                        }
                      </div>
                    </div>
                  </div>

                  {this.props.login.user.is_admin && 
                    <div className="row">
                      <div className="col-md-6">
                        <div className="pb-3 mb-3">
                          <h4>Fundraiser Target Amount</h4>
                          {this.getInputData(['project', 'campaign_templates', 'target_amount']) !== this.na ? 
                            <p className="text-muted mr-b-0">{this.getInputData(['project', 'campaign_templates', 'target_amount'])}</p> : 
                            <i className="text-muted">Not available</i>
                          }
                        </div>
                      </div>
                    </div>
                  }

                  <div className="row">
                    <div className="col-md-12">
                      <div className="pb-3 mb-3">
                        <h4>Type of campaign</h4>
                        {this.getInputData(['project', 'campaign_templates', 'donation_type']) !== this.na ? 
                          <p className="text-muted">{`${this.getInputData(['project', 'campaign_templates', 'donation_type']).charAt(0).toUpperCase() + this.getInputData(['project', 'campaign_templates', 'donation_type']).slice(1)} campaign`}</p> :
                          <i className="text-muted">Not available</i>
                        }

                        {this.getInputData(['project', 'campaign_templates', 'donation_type']) === 'classic' ? 
                          <div className="col-md-12">
                            <div className="mt-3 pb-3 mb-3">
                              <h4>Donation Options</h4>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th colSpan="2">Amount</th>
                                    <th>Donation Description</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.getInputData(['project', 'campaign_templates', 'donation_options']).map((item, idx) => {
                                    return (
                                      <tr key={idx}>
                                        <td>{item.currency_code}</td>
                                        <td className="text-muted">{item.amount}</td>
                                        <td className="text-muted">{item.title}</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div> : null
                        }

                        {this.getInputData(['project', 'campaign_templates', 'donation_type']) === 'unit' ?  
                          <div className="col-md-12">
                            <div className="mt-3 pb-3 mb-3">
                              <h4>Donation Options</h4>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th width="20%" colSpan="2"> Amount </th>
                                    <th width="20%"> Image </th>
                                    <th width="20%"> Title </th>
                                    <th width="40%"> Description </th>
                                  </tr>
                                </thead>
                                <tbody>
                                {this.getInputData(['project', 'campaign_templates', 'donation_options']).map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>{item.currency_code}</td>
                                      <td className="text-muted">{item.amount}</td>
                                      <td className="text-muted uc-donation-img">
                                        <div className="img-wrapper">
                                          <img src={item.image} />
                                        </div>
                                      </td>
                                      <td className="text-muted">{item.title}</td>
                                      <td className="text-muted">{item.description}</td>
                                    </tr>
                                  )
                                })}          
                                </tbody>
                              </table>
                            </div>
                          </div> : null
                        }

                          {this.getInputData(['project', 'campaign_templates', 'donation_type']) === 'reward' ?  
                            <div className="col-md-12">
                              <div className="mt-3 pb-3 mb-3">
                                <h4>Donation Options</h4>
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="20%" colSpan="2">Amount</th>
                                      <th width="20%">Image</th>
                                      <th width="20%">Title</th>
                                      <th width="40%">Description</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.getInputData(['project', 'campaign_templates', 'donation_options']).map((item, idx) => {
                                      return (
                                        <tr key={idx}>
                                          <td>{item.currency_code}</td>
                                          <td className="text-muted">{item.amount}</td>
                                          <td className="rc-donation-img">
                                            <div className="img-wrapper">
                                              <img src={item.image} />
                                            </div>
                                          </td>
                                          <td className="text-muted">{item.title}</td>
                                          <td className="text-muted" dangerouslySetInnerHTML={{ __html: item.description }}>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div> : null
                          }
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="pb-3 mb-3">
                        <h4>Communications Templates</h4>                    
                        <h5 className="mt-3">Invite via email</h5>
                        {this.getInputData(['project', 'campaign_templates', 'invite_templates', 'email']) !== this.na ? 
                          <div>
                            <h6>Subject</h6>
                            {this.getInputData(['project', 'campaign_templates', 'invite_templates', 'email', 'subject']) !== this.na ? 
                              <p className="text-muted">{this.getInputData(['project', 'campaign_templates', 'invite_templates', 'email']).subject}</p> :
                              <p><i className="text-muted mb-1">Not available</i></p>
                            }
                            <h6>Body</h6>
                            {this.getInputData(['project', 'campaign_templates', 'invite_templates', 'email', 'subject']) !== this.na ? 
                              <p className="text-muted" dangerouslySetInnerHTML={{ __html: this.getInputData(['project', 'campaign_templates', 'invite_templates', 'email']).content }}></p> :
                              <p><i className="text-muted mb-1">Not available</i></p>
                            }
                          </div> :
                          <i className="text-muted">Not available</i>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                          <h5>Sharing content</h5>
                          {this.getInputData(['project', 'campaign_templates', 'invite_templates']) ?
                            <div>
                              <h6 className="mt-3">For Owner of Fundraiser</h6>
                              {this.getInputData(['project', 'campaign_templates', 'invite_templates']).creator_sharing_message ? 
                                <p className="text-muted" dangerouslySetInnerHTML={{ __html: this.getInputData(['project', 'campaign_templates', 'invite_templates']).creator_sharing_message }}></p> : 
                                <p><i className="text-muted">Not available</i></p>
                              }
                              <h6>For Visitors of Fundraiser</h6>
                              {this.getInputData(['project', 'campaign_templates', 'invite_templates']).guest_sharing_message ? 
                                <p className="text-muted" dangerouslySetInnerHTML={{ __html: this.getInputData(['project', 'campaign_templates', 'invite_templates']).guest_sharing_message }}></p> :
                                <p><i className="text-muted">Not available</i></p>
                              }
                              <h6>For Donors of Fundraiser</h6>
                              {this.getInputData(['project', 'campaign_templates', 'invite_templates']).donor_sharing_message ? 
                                <p className="text-muted" dangerouslySetInnerHTML={{ __html: this.getInputData(['project', 'campaign_templates', 'invite_templates']).donor_sharing_message }}></p> :
                                <p><i className="text-muted">Not available</i></p>
                              }
                            </div> :
                            <p><i className="text-muted">Not available</i></p>
                          }          
                      </div>
                    </div>
                  </div>
                </div>
              </ApprovalsRejectionsButtons>              
            </div>

            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
});

export default connect(
  mapStateToProps,
  null
)(CampaignRead);
