import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import './NonprofitCauseEdit.css';

class NonprofitCauseEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: {_id: props.value._id},
    };
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    if (onSubmit) {
      onSubmit(changes);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Cause/Retention</h3>
                {/* <p className="text-secondary"> A simple suggestion engine </p> */}
                <div className="form-group row">
                  <label className="col-md-12 col-form-label">Nonprofit is a 4-limb or Non 4-limb?</label>
                  {this.getInputErrors(['nonprofit', 'is_4limb'])}
                  <div className="col-sm-6">
                    <div className="form-radio">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="is_4limb" value="yes" checked={this.getInputData(['nonprofit', 'is_4limb']) === true} onChange={(e) => { this.handelInputChange(['nonprofit', 'is_4limb'], e); }} /> 4 Limb <i className="input-helper"></i></label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-radio">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="is_4limb" value="no" checked={this.getInputData(['nonprofit', 'is_4limb']) === false} onChange={(e) => { this.handelInputChange(['nonprofit', 'is_4limb'], e); }} /> Non 4-Limb <i className="input-helper"></i></label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Retention %: {this.getInputData(['nonprofit', 'retention_percent'])}</label>
                      {this.getInputErrors(['nonprofit', 'retention_percent'])}
                      <input className="form-control" type="range" placeholder="Retention %" value={this.getInputData(['nonprofit', 'retention_percent'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'retention_percent'], e); }} max="10" min="3" step="0.5" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default NonprofitCauseEdit;
