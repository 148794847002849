import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/project-service';
import ProjectService from '../../services/project-service';

export const GET_LIST_ADMIN_PROJECTS = 'GET_LIST_ADMIN_PROJECTS';
export const GET_LIST_ADMIN_PROJECTS_SUCCESS = 'GET_LIST_ADMIN_PROJECTS_SUCCESS';
export const GET_LIST_ADMIN_PROJECTS_FAIL = 'GET_LIST_ADMIN_PROJECTS_FAIL';

export const ADMIN_PROJECT_LIST_RESET = 'ADMIN_PROJECT_LIST_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  projects: [],
  get_status: {},
  load_status: {},
};

export const adminProjectListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LIST_ADMIN_PROJECTS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'pending',
        },
      };
    case GET_LIST_ADMIN_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
        get_status: {
          ...state.get_status,
          project: 'success',
        },
        load_status: {
          ...state.load_status,
          project: action.load_status,
        }
      };
    case GET_LIST_ADMIN_PROJECTS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'failed',
        },
      };
    case ADMIN_PROJECT_LIST_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getProjects(query) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_LIST_ADMIN_PROJECTS,
    });
    const currentProjects = getState().adminProjectList.projects;
    const params = {
      skip: currentProjects.length,
      limit: 20,
      sort: '-updated_at',
      ...query
    };
    return ProjectService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_LIST_ADMIN_PROJECTS_SUCCESS,
            projects: currentProjects,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_LIST_ADMIN_PROJECTS_SUCCESS,
            projects: [...currentProjects, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_ADMIN_PROJECTS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: ADMIN_PROJECT_LIST_RESET,
    });
  };
}
