import React, { Component } from 'react';
import { Button, Modal } from "react-bootstrap";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminNonprofit10BdBeListActions from '../../../reducers/Admin/admin10BdBeListReducer';
import Spinner from 'react-bootstrap/Spinner';

import { Link } from 'react-router-dom';
import { TextField, TextareaAutosize } from '@material-ui/core';

class Nonprofits10bdbePending extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            showRejectModal: false,
            rejectReason: ''
        }
    }

    handleApproveNonprofit = () => {
        this.setState({ isLoader: true })
        const { _id } = this.props.tileProps || {};
        const { Nonprofits, status, handleApprove } = this.props;
        Nonprofits.approveNonprofits(_id)
            .then((res) => {
                this.setState({ isLoader: false })
                handleApprove(status)
            })
            .catch((err) => {
                this.setState({ isLoader: false })
            })
    }
    handleRejectNonprofit = () => {
        const { _id } = this.props.tileProps || {};
        const { Nonprofits, handleApprove, status } = this.props;
        const { rejectReason } = this.state
        Nonprofits.rejectNonprofits(_id, rejectReason)
            .then((res) => {
                this.setState({ isLoader: false })
                handleApprove(status)
            })
            .catch((err) => {
                this.setState({ isLoader: false })
            })
    }
    handleSendEmailNonprofit = () => {
        const { _id } = this.props.tileProps || {};
        const { Nonprofits, handleApprove, status } = this.props;
        Nonprofits.sendEmailNonprofits(_id)
            .then((res) => {
                this.setState({ isLoader: false })
                handleApprove(status)
            })
            .catch((err) => {
                this.setState({ isLoader: false })
            })
    }

    render() {
        const { link } = this.props || {}
        const { total_10bd_available_amount, total_10be_available_amount, _id, image, name, matched_count, total_count } = this.props.tileProps || {}
        const { status } = this.props;
        const { isLoader, showRejectModal, rejectReason } = this.state
        return (
            <div className="ngo-tiles">
                <div className="amount-wrap">
                    <>
                        <div className='amount-block'>
                            <span>{total_10bd_available_amount ? Math.round(total_10bd_available_amount) : 0}</span>
                            <small className='text-left'>10BD Downloaded Amount(INR)</small>
                        </div>
                        <div className='amount-block flex-end'>
                            <span>{total_10be_available_amount ? Math.round(total_10be_available_amount) : 0}</span>
                            <small className='text-right'>10BE available Amount after submission(INR)</small>
                        </div>
                    </>
                </div>
                <div className='amount-block'>
                    <small><b>{matched_count}</b> out of <b>{total_count}</b> PAN amounts match</small>
                </div>
                <Link to={{ pathname: `${link}${_id}/donations`, state: _id }}>
                    <p className='mt-4 p-1 np-wrap'>
                        <img src={image} alt='profile-pic' />
                        <strong >{name}</strong></p>
                </Link>


                <div className='button-wrap'>
                    <>
                        <Link className='btn-color' to={{ pathname: `${link}${_id}/donations`, state: _id }}>
                            <button className="btn btn-primary view-details-btn" type="submit">
                                View Details
                            </button>
                        </Link>
                        {(status === 'rejected' || status === 'download-pending') ? '' :
                            status === 'approved' ?
                                <button className="btn btn-primary mr-3" onClick={this.handleSendEmailNonprofit}>Send Email Again </button>
                                :
                                <>
                                    <button className="btn btn-primary mr-3" onClick={this.handleApproveNonprofit}>Approve </button>
                                    <button className="btn btn-danger" onClick={() => { this.setState({ showRejectModal: true }) }}>Reject</button>
                                </>
                        }
                    </>
                </div>
                {isLoader && <div className='loader-wrap'>
                    <div>
                        <Spinner animation="border" size="md" className='spinner' />
                    </div>
                </div>}
                <Modal
                    show={showRejectModal}
                    size="md"
                    onHide={() => { this.setState({ showRejectModal: false }) }}
                >
                    <Modal.Header >
                        <Modal.Title className='d-flex align-items-center modalTitle'>
                            Please Enter Reject Reason
                            <span className='modalClose' onClick={() => {
                                this.setState({ showRejectModal: false })
                            }}>x</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '10px 25px' }}>

                        <TextareaAutosize
                            rows={4}
                            id="outlined-size-small"
                            defaultValue=""
                            variant="outlined"
                            size="small"
                            value={rejectReason}
                            name={"reason"}
                            className='w-100'
                            style={{background:'transparent'}}
                            placeholder='Please Enter the reason'
                            onChange={e => {
                                const { value } = e.target || '';
                                this.setState({ rejectReason: value })
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" disabled={rejectReason.length < 1} onClick={this.handleRejectNonprofit} className='d-flex align-items-center m-auto'>
                            Reject </Button>
                    </Modal.Footer>
                </Modal >
            </div>
        )
    }
}

const mapStateToProps = state => ({
    nonprofits: state.adminNonprofit10BdBeList
});

const mapActionToProps = dispatch => ({
    Nonprofits: bindActionCreators(adminNonprofit10BdBeListActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapActionToProps,
)(Nonprofits10bdbePending);