/*
	This component takes care of rending the fields and the error for the form Input elements based on the type of the form element.
	This also handles the state managemt of the form inputs that have been done for the respecive form fields 	
*/

import React, { useEffect, useState } from 'react'

import SingleFileUpload from '../SingleFileUpload/SingleFileUpload';
import ImageCropUpload from '../ImageCropUpload';
import Editor from '../Editor';
import SelectInput from '../SelectInput';
import PhoneNumberInput from '../PhoneInput';
import Icon from '@mdi/react';
import {
	mdiDelete,
  mdiPlus
} from '@mdi/js'

import Helpers from '../../utils/helpers';
import './FormInput.css'

const FormInput = (props) => {

	// Getting Props Values 
	const { onChange, value, isReadMode, formData, setCheckuiErrors } = props;
	
	const [ formItem, setFormItem ] = useState(null);
	const [ errors, setErrors] = useState([])
	const [ realTimeError, setRealTimeError ] = useState(null)
	const [ elementKey, setElementKey ] = useState(null);	
	
	// Setting default Country code as +91 for India, for Phone Input fields 
	const country_code = 'IN'

	// Method to handle input value change 
	const handleChange = (e, isInvisible = false) => {
		if(e !== undefined){			
			let fieldValue = e !== null && e.target ? e.target.value : e
			if(e?.target?.type === 'checkbox'){
				fieldValue = e.target.checked
			}
			const errors = Helpers.isValid(fieldValue, formItem.validations);			
			handleErrors(errors)
			setCheckuiErrors(errors)
			if(formItem.hidden === undefined){
				if(formItem.validations && formItem.validations.format === 'date' && (formItem.validations.type !== 'datetime')){
					onChange({
						name: elementKey,
						value: handleDateInputValue(fieldValue, 'dd/MM/yyyy'),
					})
				}
				else{
					if(e && e.target && e.target.type === 'checkbox'){						
						onChange({
							name: elementKey,
							value: fieldValue ? e.target?.value : null, 
							isInvisible: isInvisible
						})
					}
					else{
						onChange({
							name: elementKey,
							value: fieldValue,
							isInvisible: isInvisible
						});
					}
				}
			}			
		}
	}

	const handleCountryChange = (key, value) => {
		onChange({
			name: key,
			value: value
		});
	}	

	const handleMultiSelectInputChange = (e) => {
		const values = e
		let multiSelectValues = []
		
		values.forEach(element => {
			multiSelectValues.push(element.value)
		});		

		handleChange(Helpers.unique([...value, ...multiSelectValues]))
	}

	const handleErrors = (err) => {
		setRealTimeError(err);		
	}

	const increment = (e, key) => {		
		onChange({
			name: key,
			value: ++e.target.value,			
		})
	}

	const decrement = (e,key)  => {
		onChange({
			name: key,
			value: --e.target.value,
			controlArrayKey: props?.controlArrayKey,
			index: props?.index
		})
	}

	const valueToLabel = (value) => {
		const options = getSelectOptions(formItem.enum)
    const index = Helpers.objSearch(options, value, 'value');
    if (index > -1) {
      return options[index].label;
    }
    return value;
  }

	const handleDateInputValue = (value, format = false) => {			
		if(value !== undefined && value !== null){
			value = value.replace(/\//g, "-")
			if(value.indexOf('-')){
				// format could be either 'yyyy-MM-dd' or 'dd-mm-yyyy'
				let dateItems = value.split('-')
				if(dateItems[0].length === 2){
					// if format is dd-mm-yyyy
					value = [ dateItems[2], dateItems[1], dateItems[0]].join('-')				
				}
			}
			return Helpers.formatDateTo(value, format)
		}
		else{
			return ""
		}
	}

	// Function to generate input options in case of Select, Radio and Checkbox fields 
	const generateOptions = (values, type, name = null, value = null) => {
		
		// Customising input values for select element of causes,
		// to ensure that the user isn't able to select duplicated values in the form 
		let disabled_values = {};
		if(type === 'select' && elementKey.includes('nonprofits.causes')){
			const causes = formData.nonprofits.causes.map(item => item.name);			
			causes.forEach(item => {
				disabled_values = {
					...disabled_values,
					[item]: true
				}
			})
		}

		switch(type){
			case 'radio':
				return (					
						Object.keys(values).map((option, key) => (
							<div className="col-sm-6" key={key}>
								<div className="form-radio">
									<label className="form-check-label">
										<input type="radio" className="form-check-input" 
											name={elementKey} 
											value={option} 
											onChange={e => handleChange(e)}
											checked={value!== null ? value === option : false}
										/> 
										{values[option]} <i className="input-helper"></i>
									</label>
								</div>
							</div>
						))
				)
			case 'select':
				return (
					Object.keys(values).map((option, key) => (
						// Setting disabled value, if the disabled fields are configured
						<option value={option} key={key} disabled={disabled_values?.[option] || false}>{values[option]}</option>
					))
				)
			default: 
				break;
		}
	}

	const showDescription = (item) => {		
		if(item && item.description){			
			return (
				<p className="formDescription" dangerouslySetInnerHTML={{__html: item.description}}></p>
			)
		}
		else{
			return null
		}
	}

	const getSelectOptions = (values) => {
		const returnData = []
		Object.keys(values).map(item => {
			returnData.push({
				value: item,
				label: `${item}::${values[item]}`
			}) 
		})
		return returnData
	}
	
	// TODO: Add documentation to help addition of more fields types in the future easy 
  const ElementToDisplay = (item) => {	
    if(item !== null && item.length !== 0){			
      if(item.hasOwnProperty('element')){
				const inputProps = {};
				if(item.validations && item.validations.format === 'date'){
					inputProps['placeholder'] = 'dd-mm-yyyy'
					inputProps['min'] = '01-01-1950'
				}

        switch(item.element){
					case 'input':
						if(isReadMode && isReadMode === true){
							return (							
								<div className="form-group">
									{ item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null }
									{ showDescription(item) }
									<p className="form-input-display">
									{ 
										value !== undefined && typeof value === 'string' ?
											(
												(item.validations && item.validations.format === 'date') ? 
													handleDateInputValue(value,'yyyy-MM-dd'): 
													value
											) 
											: 'Not Available'
									}
									</p>
								</div>
							)
						}
						else{       
							return (
								<div className="form-group">
									{ item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null }								
									{ showDescription(item) }
									<input 
										className="form-control" 									
										readOnly={ isReadMode ? isReadMode : false }
										disabled={item.disabled ? true : false}
										type={item.visibility === undefined ? (item.validations?.format ? item.validations.format : 'text') : 'hidden'} 									
										name={elementKey}
										max={item.validations?.max || undefined}
										value={
											(item.validations && item.validations.format === 'date' && item.validations.type !== 'datetime') ? 
												handleDateInputValue(value,'yyyy-MM-dd') 
												: (value !== undefined ? value : '')
										}
										placeholder={item.placeholder ? item.placeholder: item.title}
										{...inputProps}
										onChange={e => handleChange(e)}
									/>
								</div>								
							)
						}
					case 'tel':
						if(isReadMode && isReadMode === true){
							return (							
								<div className="form-group">
									{item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null}
									{ showDescription(item) }
									<p className="form-input-display">
									{ value !== undefined ? ((item.validations && item.validations.format === 'date') ? handleDateInputValue(value,'yyyy-MM-dd') : value) : 'Not Available'}
									</p>
								</div>
							)
						}
						else{						
							return (
								<div className="form-group">
									{item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null}								
									{ showDescription(item) }
									<PhoneNumberInput 
										countrycode={country_code} 
										value={value} 
										onChange={e => handleChange(e)} 
										onCountryChange={e => handleCountryChange('nonprofits.address.country_code', e)}
										onPhoneBlur={()=>{}}
									/>									
								</div>								
							)
						}
					case 'radio':
						if(isReadMode && isReadMode === true){
							return (							
								<div className="form-group">
									{ item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null }
									{ showDescription(item) }																	
									<p className="form-input-display">
									{ value !== undefined ?  item.enum[value] : 'Not Available'}
									</p>
								</div>
							)
						}
						else{
							return (
								<div className="form-group row">
									<div className="col-md-12">
										<label className="col-form-label">{item.title} {item.validations?.required? `*`: ``}</label>								
										{ showDescription(item) }
									</div>
									{item.hasOwnProperty('enum') ? generateOptions(item.enum, item.element, item.key, value) : null}
								</div>
							)
						}						
					case 'checkbox':
						if(isReadMode && isReadMode === true){
							return (							
								<div className="form-group">
									{ item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null }																	
									<p className="form-input-display">
									{ value !== undefined ? value : 'Not Available'}
									</p>
								</div>
							)
						}
						else{
							return (
								<div className="form-group">
										<div className="form-check">																							
												<input type="checkbox" className="form-check-input" id={`check-${elementKey}`}
													name={elementKey} 
													value={item.value} 
													onChange={e => handleChange(e)}
													{...(value === item.value ? {checked: true} : null)}
												/> 												
												<label className="form-check-label" htmlFor={`check-${elementKey}`}>
													{item.title} {item.validations?.required? `*`: ``} {item.description}
												</label>
											{/* { showDescription(item) } */}
										</div>
									</div>
							)
						}
					case 'textarea':
						if(isReadMode && isReadMode === true){
							return (							
								<div className="form-group">
									{ item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null }
									{ showDescription(item) }	
									<p className="form-input-display"									
										dangerouslySetInnerHTML={{ __html: value !== undefined ? value : 'Not Available' }}
									></p>
								</div>
							)
						}
						else{
							// if(value !== undefined){
							return (
								<div className="form-group">
									<label>{item.title} {item.validations?.required? `*`: ``}</label>								
									{ showDescription(item) }
									<Editor value={value} name={elementKey} onChange={handleChange} />
								</div>
							)
							// }
						}
					case 'select':
						if(isReadMode && isReadMode === true){
							return (							
								<div className="form-group">
									{ item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null }
									{ showDescription(item) }	
									<p className="form-input-display">
									{ value !== undefined ? item.enum[value] : 'Not Available'}
									</p>
								</div>
							)
						}
						else{
							return (
								<div className="form-group">
									<label>{item.title} {item.validations?.required? `*`: ``}</label>		
									{ showDescription(item) }					
									<select className="form-control" value={value} onChange={handleChange} name={elementKey} data-attr={elementKey}>
										<option value="">Select</option>
										{item.hasOwnProperty('enum') ? generateOptions(item.enum, item.element) : null}									
									</select>
								</div>
							)
						}
					case 'upload':						
						if(isReadMode && isReadMode === true){							
							return (							
								<div className="form-group">
									{ 
										item.visibility === undefined ? 
											(
												<label>{
													// Temporary Enhancement to Update the Label for Income Tax Return for the recent year.
													item.title ? 
														(item.identifier?.fy ? 
															`${item.title} - ${item?.identifier?.document_type === 'income_tax_return' ? 
																item.identifier.fy.split('-').map((item, index) => { 
																	return Number(item) + 1
																}).join('-') 
																: item.identifier.fy}`
															: `${item.title} ${item.validations?.required? `*`: ``}`)
														: (item.identifier ? `${generateTitle(item.identifier)}` : '')} {item.validations?.required? `*`: ``
													}
												</label>
											) : 
											null 										
									}
									{ showDescription(item) }									
									<p className="form-input-display">
										{(value !== undefined && value !== null) ? (
											<a href={value}>{item.title ? `${item.title}` : ( item.identifier ? `${generateTitle(item.identifier)}` : '')} (Preview)</a>
										) : 'Not Available'}
									</p>
								</div>
							)
						}
						else{
							return (
								<SingleFileUpload 
									label={
										// Temporary Enhancement to Update the Label for Income Tax Return for the recent year.
										item.title ? 
											(
												item.identifier?.fy ? 
													`${item.title} - ${item?.identifier?.document_type === 'income_tax_return' ? 
														item.identifier.fy.split('-').map((item, index) => { 
															return Number(item) + 1
														}).join('-') 
														: item.identifier.fy}`
													: `${item.title} ${item.validations?.required? `*`: ``}`
											) 
											: 
											( 
												item.identifier ? 
													`${generateTitle(item.identifier)}` 
													: ''
											)
									} 
									value={value} 
									onChange={e => handleChange(e)} 
									description={item.description ? `${item.description} (Upload *.pdf, *png, or *.jpg files only)` : `(Upload *.pdf, *png, or *.jpg files only)`}
									// error={handleChange}
								/>
							)
						}
					case 'media':
						if(isReadMode && isReadMode === true){
							return (							
								<div className="form-group">
									{ item.visibility === undefined ? (<label>{item.title ? `${item.title}` : ( item.identifier ? `${generateTitle(item.identifier)}` : '')} {item.validations?.required? `*`: ``}</label>) : null }
									<br/>
									<div className="image-container">
										<img src={value} />
									</div>
								</div>
							)
						}
						else{
							return (
								<div className="card-body">
									<h3>{item.title} {item.validations?.required? `*`: ``}</h3>
										{ showDescription(item) }
										<ImageCropUpload 
											title={item.title}
											height={item.validations?.size?.height || 300} 
											width={item.validations?.size?.width || 300} 
											value={value ? value: ""} 
											onChange={e => handleChange(e)} 
											error={handleChange} 
										/>
								</div>
							)
						}
					case 'multi-select':	
						if(isReadMode && isReadMode === true){
							return (
								<div>
									{ item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null }
									<div className="tags-wrapper">
										{value && value.length > 0 ? (
											value.map((i, index) => (
												<label 
													key={i} 
													className="badge badge-primary" 
													// onClick={(e) => { e.preventDefault(); this.removeCategoryCodeOptions(index) }}
												>
														{valueToLabel(i)}
												</label>
											))
										) : value}
									</div>
								</div>
							)
						}
						else{										
							return (
								<div>
									{ item.visibility === undefined ? (<label>{item.title.replace("_", " ")} {item.validations?.required? `*`: ``}</label>) : null }
									<div className="tags-wrapper">
										{value && value.length > 0 ? (
											value.map((i, index) => (
												<label 
													key={i} 
													className="badge badge-primary" 
													// onClick={(e) => { e.preventDefault(); this.removeCategoryCodeOptions(index) }}
												>
														{valueToLabel(i)}
												</label>
											))
										) : value}
									</div>
									<SelectInput isMulti={true} options={item.enum? getSelectOptions(item.enum) : null} onChange={handleMultiSelectInputChange} />
								</div>
							)
						}
					case 'section':					
						return (							
							<div className="mr-b-20">
								<h4 className="mr-b-10">
									{item.title} {item.validations?.required? `*`: ``}																
								</h4>
								{ showDescription(item) }
							</div>
						)					
					case 'row':
						return (							
							<h5 className="mr-b-20">
								{item.title} {item.validations?.required? `*`: ``}
								{ showDescription(item) }
							</h5>														
						)				
					case 'increment-button': {
						if(isReadMode && isReadMode === true){
							// Do Nothing 
						}
						else{
							let addValue = (value !== undefined && value !== NaN && !(value < 0)) ? value : 0;							
							
							return (
								<div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
									<button type="button" className="btn btn-primary mr-2" value={addValue === -1 ? 0:addValue} onClick={e => increment(e, item.key)}>
										<Icon path={mdiPlus} className="menu-arrow" size={0.75} color="#ffffff" />
										&nbsp; {item.title} {item.validations?.required? `*`: ``}
									</button>
								</div>
							)
						}
						break;
					}
					case 'decrement-button': {
						if(isReadMode && isReadMode === true){
							// Do Nothing 
						}
						else{
							let decrementValue = (value !== undefined && value !== NaN && !(value < 0)) ? value : 0;							
							return (
								<div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
									<button type="button" className="btn btn-light" value={decrementValue === -1 ? 0 : decrementValue} onClick={e => decrement(e, item.key)}>
										<Icon path={mdiDelete} className="menu-arrow" size={0.75} color="#000000" />
										&nbsp; {item.title} {item.validations?.required? `*`: ``}
									</button>
								</div>
							)
							
						}
						break;
					}	
					default : {
						break
					}		
        }
      }
      else{	
					
				return (
					<p>{item.title}{item.validations?.required ? `*`: ``}</p>
				)
      }
    }
	}
	
	const displayError = () => {

		if(realTimeError !== null && realTimeError.length){
			return (
				<div className="errorContainer">
					{realTimeError[0]}
				</div>
			)
		}

		if(errors.length){
			return (
				<div className="errorContainer">
					{errors[0]}
				</div>
			)
		}
		else{
			return null;
		}
	}

	const generateTitle = (identifier) => {		
		return Helpers.formatText(Object.values(identifier).reverse().join(": ").replace(/_/g," "))
	}

	const generateErrorMessage = (item) => {
		return (item.title ? item.title : 'Field') + ' is required'
	}

	// Function to display the error fields on the UI 
  useEffect(() => {	
		
		if(props.item && props.item !== formItem){			
			setFormItem(props.item);
			if(props.item.key && props.item.key !== elementKey){
				setElementKey(props.item.key)
			}
		}

		// Identify the fields key from list of props, or fetch it from the key in the item sent from schema
		if(formItem && formItem !== null){			
			if(props.isRequiredError && 				
				formItem.key === props.isRequiredError
			){							
				setErrors([generateErrorMessage(formItem)]);
			}
			else if(props.isValidationError && 				
				Object.keys(props.isValidationError).indexOf(formItem.key) >= 0	
			){
				setErrors([props.isValidationError[formItem.key]])
			}
			else if(				
				generateErrorMessage(formItem) !== errors[0]
			){
				setErrors([])
			}
			else{
				setErrors([])
			}			
		}		

	},[props, formItem])

	useEffect(() => {		
		if(formItem !== null && formItem.visibility === false && isReadMode !== true){			
			handleChange(formItem.value, true)
		}
		if(formItem && formItem.value){			
			if(!isReadMode) handleChange(formItem.value, true)
		}
	},[formItem, isReadMode])
	
	
  return (
		<>	   
			{ ElementToDisplay(formItem) }			
			{ displayError() }
		</>
  )
}

export default FormInput; 