import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import classnames from 'classnames';

import Icon from '@mdi/react';
import { mdiMenu, mdiChevronDown } from '@mdi/js';

import device from '../../resources/images/giveindia-device-logo.svg';

import routes from '../../routes/productRoutes';
import Helpers from '../../utils/helpers';
import UserDropdown from '../UserDropdown/UserDropdown';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: false,
    };
  }

  menuDesktopClick = (e) => {
    e.preventDefault();
    const element = document.body;
    element.classList.toggle('sidebar-icon-only');
  }

  menuMobileClick = (e) => {
    e.preventDefault();
    const element = document.getElementById('primary-nav');
    element.classList.toggle('active');
  }

  mobileMenuClick = (e) => {
    e.preventDefault();
    const { mobile } = this.state;
    this.setState({ mobile: !mobile });
  }

  render() {
    const { user, routesList, params } = this.props;
    const { mobile } = this.state;
    return (
      <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
          <a className="navbar-brand brand-logo" href="/">
            <img  src={'https://cfstatic.give.do/logo.png'} className={'header_logo'} alt="" />
          </a>
          <a className="navbar-brand brand-logo-mini" href="/">
            <img src={device} alt="logo" />
          </a>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize" onClick={this.menuDesktopClick}>
            <Icon path={mdiMenu} size={1} color="#FFFFFF" />
          </button>
          <ul className="navbar-nav navbar-nav-left header-links">
            {routes.filter(item => (user === item.for_user)).map(item => (
              <li className={classnames('nav-item d-none d-md-flex', { active: (routesList.default_path === item.default_path) })} key={item.title}>
                <Link to={Helpers.replaceParamsInURI(params, item.default_path)} className="nav-link">{item.title}</Link>
              </li>
            ))}
            <li className="nav-item dropdown d-md-none">
              <a className="nav-link dropdown-toggle px-0 no-after" id="quickDropdown" onClick={this.mobileMenuClick} href="#menu" data-toggle="dropdown" aria-expanded="false">Menu <Icon path={mdiChevronDown} className="menu-icon" size={0.75} color="#ffffff" /></a>
              <div className={classnames('dropdown-menu', 'dropdown-menu-right', 'navbar-dropdown' ,'pt-3', {show: mobile})} aria-labelledby="quickDropdown">
                {routes.filter(item => (user === item.for_user)).map(item => (
                  <div key={item.title}>
                    <Link to={Helpers.replaceParamsInURI(params, item.default_path)} className="dropdown-item">{item.title}</Link>
                  </div>
                ))}
              </div>
            </li>
          </ul>
          <UserDropdown />
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" onClick={this.menuMobileClick}>
            <Icon path={mdiMenu} size={1} color="#FFFFFF" />
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
