import { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as PendingApprovalsItemActions from '../../../reducers/AdminApprovals/pendingApprovalsItemReducer';
import Helpers from "../../../utils/helpers";
import DynamicFormHelper from "../../../utils/dynamicform";
import Form from "../../../components/OnboardingComponents/Form";
import FormActionControls from "../../../components/OnboardingComponents/FormActionControls";
import Editor from "../../../components/Editor";
import * as NotificationAction from '../../../reducers/notificationReducer';

const PendingApprovalsItem = (props) => {
  const { match, PendingApprovalsItem, pendingApprovalsItem, home, Home } = props

  const [ nonprofitID, setNonprofitID ] = useState(null);
  const [ project, setProject ] = useState(null)
  const [ nonprofit, setNonprofit ] = useState(null)
  const [ compliance, setCompliance ] = useState('givecompliant');
  const [ formValues, setFormValues ] = useState({})
  const [ formSchema, setFormSchema ] = useState({})
  const [ stepWiseSchema, setStepWiseSchema ] = useState([])
  const [ validations, setValidations ] = useState(null)
  const [ errors, setErrors ] = useState([])
  const [ stepErrors, setStepErrors ] = useState({})
  const [ changes, setChanges ] = useState({})
  const [ nonprofitChanges, setNonprofitChanges ] = useState({})
  const [ projectChanges, setProjectChanges ] = useState({})
  const [ formSchemaValidation, setFormSchemaValidation] = useState({})
  const [ isNGOApproved, setIsNGOApproved ] = useState(false)
  const [ showRejectActions, setShowRejectActions] = useState(false)

  const [ rejectionEmailData, setRejectionEmailData ] = useState({})

  const nextStatus = {
    'document_check': 'content_check',
    'content_check': 'cause_check',
    'cause_check': 'final_approval',
    'final_approval': 'published',    
  }

  useEffect(() => {
    if(match && match.params){
      if(match.params.id){
        setNonprofitID(match.params.id)
      }  
    }    
  }, [match])

  useEffect(() => {
    if(nonprofitID && nonprofitID !== null){      
      PendingApprovalsItem.getNonprofit({ path: [nonprofitID]})
    }    
  }, [nonprofitID])

  useEffect(() => {     
    if(pendingApprovalsItem && pendingApprovalsItem.nonprofit && pendingApprovalsItem.nonprofit._id){
      if(pendingApprovalsItem.nonprofit._id !== formValues.nonprofits?._id){     
        setNonprofit(Helpers.createNonprofitWithDiff(Helpers.cloneObject(pendingApprovalsItem.nonprofit)))
      }
      
      if(pendingApprovalsItem.nonprofit.approved_compliances && pendingApprovalsItem.nonprofit.approved_compliances.length !== 0){
        const PendingApprovalsItemCheck = pendingApprovalsItem.nonprofit.approved_compliances.find(item => item.id === match.params.compliance)
        if(PendingApprovalsItemCheck && Object.keys(PendingApprovalsItemCheck).length !== 0){
          setIsNGOApproved(true)
        }
      }
    }

    if(pendingApprovalsItem.nonprofit && pendingApprovalsItem.nonprofit.required_compliances){       
      PendingApprovalsItem.getFormSchema({ nonprofit: pendingApprovalsItem.nonprofit._id, compliance: ['givecompliant'] })      
    }
//removed Project call
    // if(pendingApprovalsItem.nonprofit && pendingApprovalsItem.nonprofit._id !== undefined){
    //   // Getting project information for the loaded nonprofit       
    //   PendingApprovalsItem.getProject({params: { nonprofit: pendingApprovalsItem.nonprofit._id }})        
    // }
  }, [pendingApprovalsItem.nonprofit])

  // useEffect(() => {
  //   const { project }  = pendingApprovalsItem
  //   if(project && project._id){      
  //     setProject(Helpers.createNonprofitWithDiff(project))

  //     const project_changes = Helpers.getKeysFromPatch(
  //       Helpers.generatePatch(
  //         project, 
  //         Helpers.createNonprofitWithDiff(
  //           Helpers.cloneObject(project)
  //         )
  //       ),
  //       'projects'
  //     )

  //     if(project_changes.length){
  //       const projectChangeMapping = DynamicFormHelper.mapErrorToStepKey(project_changes, pendingApprovalsItem.schema)
  //       if(projectChangeMapping && projectChangeMapping.project){          
  //         setProjectChanges(projectChangeMapping)
  //       }
  //     }

  //   }                    
  // },[pendingApprovalsItem.project])

  useEffect(() => {
    if( nonprofit?._id){
      setFormValues({
        nonprofits: nonprofit,
        projects: project
      })
    }
  }, [ nonprofit])


  useEffect(() => {     
    if(pendingApprovalsItem && pendingApprovalsItem.nonprofit && pendingApprovalsItem.nonprofit._id && pendingApprovalsItem.schema){       
      const changes = Helpers.getKeysFromPatch(
        Helpers.generatePatch(
          pendingApprovalsItem.nonprofit, 
          Helpers.createNonprofitWithDiff(
            Helpers.cloneObject(pendingApprovalsItem.nonprofit)
          )
        )
      )      

      if(changes.length && pendingApprovalsItem.schema){        
        setNonprofitChanges(DynamicFormHelper.mapErrorToStepKey(changes, pendingApprovalsItem.schema))
      }      
    }  
    
    if(pendingApprovalsItem && pendingApprovalsItem.schema){      
      setFormSchema(pendingApprovalsItem.schema)
      const validations = DynamicFormHelper.getValidationsFromSchema(pendingApprovalsItem.schema);       
      setValidations(validations)
    }
  }, [pendingApprovalsItem.schema]) 

  useEffect(() => {
    if(formSchema){      
      // Passing IsAdmin value as false 
      const steps = Helpers.getFormSteps(formSchema, true);
      if(steps !== undefined){
        const stepSchema = {}
        steps.forEach(item => {          
          stepSchema[item.key] = DynamicFormHelper.getThisFormSchema(formSchema, item.key)
        })       
        setStepWiseSchema(stepSchema)
      }
    }
  }, [formSchema])  

  useEffect(() => {    
    if(pendingApprovalsItem.get_status && pendingApprovalsItem.get_status.nonprofit === 'failed'){      
      setErrors(pendingApprovalsItem.errors.nonprofit)
    }

    if(pendingApprovalsItem.set_status && pendingApprovalsItem.set_status.nonprofit === 'failed'){      
      setErrors(pendingApprovalsItem.errors.nonprofit)
    }
  },[pendingApprovalsItem.get_status, pendingApprovalsItem.set_status])

  useEffect(() => {    
    const stepErrors = DynamicFormHelper.mapErrorToStepKey(errors, formSchema)
    setStepErrors(stepErrors)
  }, [errors])  


  const saveNonProfitData = (data) => {    
    data = DynamicFormHelper.clearFormData(data)
    if(data.nonprofits){      
      const nonprofitDiffData = DynamicFormHelper.getObjectDiff(data.nonprofits, pendingApprovalsItem.nonprofit)      
      PendingApprovalsItem.setNonprofit(nonprofitDiffData)
    }
  }

  const approveRejectChanges = async (data, isRejection = false, edits = false, is_project = false) => {
    if(data.edits && data.edits.length >= 0){  
      let approveRejectionIndexes      
      if(!edits || is_project){
        approveRejectionIndexes = data.edits.map((item, index) => index)      
      }
      else{        
        approveRejectionIndexes = edits
      }      
      if(!isRejection && !is_project){
        return await PendingApprovalsItem.setNonprofitWithEdits(data._id, { approve: approveRejectionIndexes })
      }
      // else if(!isRejection && is_project){
      //   return await PendingApprovalsItem.setProjectWithEdits(data._id, { approve: approveRejectionIndexes })
      // }
      else{                
        initiateRejection()
      }
    }
    else{
      return nonprofit
    }
  }

  const approveOnboardingCompliance = async () => {       
    const complianceCheckData = DynamicFormHelper.clearFormData(formValues)
    let nonprofit = complianceCheckData.nonprofits    
    let project = complianceCheckData.projects
    
    const errors = Helpers.validateFormFields(complianceCheckData, validations?.required)
    if(errors.length === 0){
      setFormSchemaValidation({})
      if(nonprofit.edits && nonprofit.edits.length > 0){
        try{
          nonprofit = await approveRejectChanges(nonprofit)
        }
        catch(e){
          setErrors(e)
        }
      }          
    
      // if(project && project.edits && project.edits.length > 0){
      //   try{
      //     project = await approveRejectChanges(project, false, project.edits.map((item, index) => index), true)
      //   }
      //   catch(e){
      //     setErrors(e)
      //   }        
      // }
      
      if(nonprofit){        
        try{          
          await PendingApprovalsItem.setNonprofit({
            _id: nonprofit._id,
            approved_compliances: nonprofit.status === 'final_approval' ? [
              ...( nonprofit.approved_compliances || [] ),
              {
                id: compliance
              }
            ]: undefined,
            status: nextStatus[nonprofit.status],
            submitted_compliances: nonprofit.submitted_compliances ? nonprofit.submitted_compliances.filter(item => item !== compliance ) : []
          })


          // if(project){
          //   try{
          //     await PendingApprovalsItem.setProject({
          //       ...project,
          //       status: nextStatus[nonprofit.status] === 'published' ? 'published' : 'final_approval'
          //     })
          //   }
          //   catch(e){
          //     return false
          //   }
          // }

          return window.location.href = '/approvals/nonprofit-onboarding';
        }
        catch(e){
          console.error('Error Approving Nonprofit', e)          
        }
      }      
    }
    else{      
      setFormSchemaValidation(errors)
      props.Notification.addNotification('Please resolve all errors before submitting the form')
    }
  }

  const initiateRejection = () => {
    setShowRejectActions(true)
  }

  const approveRejectSection = async (indexes, action = 'approve', is_project = false) => {    
    await approveRejectChanges(!is_project ? formValues.nonprofits : formValues.projects, action === 'approve' ? false : true, indexes, is_project)
  }

  const handleRejectionEmailChanges = (e, key = undefined) => {
    if(e){
      if (key) {
        setRejectionEmailData({
          ...rejectionEmailData,
          [e.target?.name || key]: e.target?.value || e
        })
      } else {
        setRejectionEmailData({
          ...rejectionEmailData,
          [e.target?.name]: e.target?.value
        })
      }
    }
  }  

  const rejectNonprofit = () => {     
    if(rejectionEmailData.subject && rejectionEmailData.body){
      const { nonprofits } = formValues
      PendingApprovalsItem.rejectNonprofit({ 
        _id: nonprofits._id,
        submitted_compliances: nonprofits.submitted_compliances.filter(item => item !== compliance ),
        email: rejectionEmailData,
        compliance_key: 'givecompliant'
      })
        .then(() => {
          return window.location.href = '/approvals/nonprofit-onboarding';
        })
        .catch(e => {
          console.error('Error rejecting Nonprofit', e)
        })
    }
  }

  const cancelRejection = () => {
    setShowRejectActions(false)
    setRejectionEmailData({})
  }

  useEffect(() => {     
    setChanges({
      ...nonprofitChanges,
      ...projectChanges
    })
  }, [nonprofitChanges])

  return (
    <div className="content-wrapper">
      <div className="row justify-content-md-center">        
        <div className="col-md-12 grid-margin">
          <div className="card">
            <div className="card-body">
              {formValues?.nonprofits && (                
                <h4>Nonprofit Name: <strong>{formValues?.nonprofits?.name}</strong></h4>                
              )}
            </div>
          </div>
        </div>
        {!isNGOApproved ? (
          <>
          <div className="col-md-12 grid-margin">                    
              { (stepWiseSchema && Object.keys(stepWiseSchema).length) ? Object.keys(stepWiseSchema).map((item, index) => (              
                <Form
                  key={index}                  
                  index={index}
                  formSchema={stepWiseSchema[item]}
                  step={item}
                  values={formValues}
                  isReadMode={true}
                  requiredFields={formSchemaValidation}                     
                  errors={errors ? DynamicFormHelper.transformAPIRequestErrors(errors): false}
                  onSubmit={e => saveNonProfitData(e)}
                  isAdmin={true}
                  isApprovals={true}
                  stepHasError={stepErrors[item] ? stepErrors[item] : null}
                  stepHasChanges={Object.keys(changes).indexOf(item) >= 0 ? changes[item].length : false}
                  isCollapseable={true} // Since project details aren't stored in edit, displaying the section in whole 
                  actions={{}}
                  methods={{
                    approveRejectSection: approveRejectSection
                  }}                  
                />              
              )) : null}
          </div>
          
          <FormActionControls 
            view={match.params.status}
            actions={{            
              approve: {
                label: 'Approve All',
                method: approveOnboardingCompliance,
              },
              reject: {
                label: 'Reject All',
                method: initiateRejection,
                btnClass: 'btn-danger'
              },
              delete: {
                label: 'Delete NGO',
                btnClass: 'btn-danger',
                method: rejectNonprofit
              }
            }}
          />

          { showRejectActions && (
            <div className="col-md-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <p>
                    From: <strong><em>membership@giveindia.org</em></strong><br/>
                    To: <strong><em>{formValues.nonprofits?.email}</em></strong>
                  </p>
                  <div className="form-group">
                    <label>Email Subject</label>
                    <input 
                      className="form-control"										
                      name="subject"
                      value={rejectionEmailData?.subject}										
                      placeholder=" Application Rejected because of Registration Details"										
                      onChange={handleRejectionEmailChanges}
                    />                  
                  </div>				
                  <div className="form-group">
                    <label>Email Body</label>																
                    <Editor value={rejectionEmailData?.body} name="body" 
                      onChange={e => handleRejectionEmailChanges(e, 'body')} 
                    />
                  </div>
                  <button className="btn btn-danger" type="submit" onClick={() => rejectNonprofit()}>Reject &amp; Email </button>
                  &nbsp; <button className="btn btn-primary" type="submit" onClick={cancelRejection}>Cancel </button>
                </div>
              </div>
            </div> 
          )}

          </>
        ): null}
      </div>
    </div>
  )
};

const mapStateToProps = state => ({
  pendingApprovalsItem: state.pendingApprovalsItem,
});

const mapActionToProps = dispatch => ({
  PendingApprovalsItem: bindActionCreators(PendingApprovalsItemActions, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(PendingApprovalsItem);