import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Switch, Route } from 'react-router';

import * as LoginAction from '../../reducers/loginReducer';
import * as HomeActions from '../../reducers/homeReducer';

import Loading from '../../appComponents/Loading';

import AdminHome from '../../productComponents/Admin/AdminHome';
import AdminOnboardingHome from '../../productComponents/AdminApprovals/AdminOnboardingHome';
import CrowdfundingHome from '../../productComponents/Crowdfunding/CrowdfundingHome';
import DisbursementHome from '../../productComponents/Disbursements/DisbursementHome';
import NGOOnboardingHome from '../../productComponents/NGOOnboarding/NGOOnboardingHome';
import WelcomeOnboarding from '../../productComponents/NGOOnboarding/WelcomeOnboarding';
import ProfileHome from '../../productComponents/Profile/ProfileHome';
import SupportHome from '../../productComponents/Support/SupportHome';
import NonProfit from "../../productComponents/NonProfit/NonProfit";

import Onboarding from '../../productComponents/NGOOnboarding/UnifiedOnboarding/UnifiedOnboarding' 
import { baseUrl } from '../../config';

class HomeRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params:{},
      prevNonprofits: [],
    };
  }
  componentDidMount() {
    const { login, home, Home } = this.props;
    if (!home.get_status.nonprofit && login.user.is_admin === false) {
      Home.getNonprofits();
    }    
  }

  componentDidUpdate() {
    const { login, home, Home } = this.props;
    if (!home.get_status.nonprofit && login.user.is_admin === false) {
      Home.getNonprofits();
    }
  }

  setParams = (new_params) => {
    const { params } = this.state;
    this.setState({ params: new_params });
  }

  Admin = (props) => (<AdminHome {...props} params={this.state.params} />);
  AdminOnboarding = (props) => (<AdminOnboardingHome {...props} params={this.state.params} />);
  Crowdfunding = (props) => (<CrowdfundingHome {...props} />);
  Disbursement = (props) => (<DisbursementHome {...props} params={this.state.params} />);
  Onboarding = (props) => (<NGOOnboardingHome {...props} params={this.state.params} />);
  WelcomeOnboarding = (props) => (<WelcomeOnboarding {...props} params={this.state.params} />);
  Profile = (props) => (<ProfileHome {...props} />);
  Support = (props) => (<SupportHome {...props} params={this.state.params} />);
  NewOnboarding = (props) => (<Onboarding {...props} params={this.state.params}/>)

  render() {    
    const { login, location, home } = this.props;
    let userType = 'user';

    if (login.user.is_admin) {
      userType = 'admin';
    }

    if (login.user.is_admin === true && (location.pathname === "/" || location.pathname === '/welcome-to-onboarding')) {
      return (
        <Redirect
          to={{
            pathname: '/admin/donations',
          }}
        />
      );
    }

    if (login.user.is_admin === false && (!home.get_status.nonprofit || home.get_status.nonprofit === 'pending')) {
      return (<Loading />);
    }

    if (login.user.is_admin === false && location.pathname === '/') {
      if(home.nonprofits.length === 0) {
        
        return ( <Redirect to={{ pathname: '/welcome-to-onboarding' }} /> );
      }
      if(home.nonprofits.length === 1) {
        if(home.nonprofits[0].status === 'published' || home.nonprofits[0].status === 'unpublished') {
          return <Redirect to={{ pathname: `/profile/${home.nonprofits[0]._id}/nonprofit/basic` }} /> ;      
        } else {
          return <Redirect to={{ pathname: '/welcome-to-onboarding' }} /> ;
        }
      }
      return (<NonProfit home={home} setParams={this.setParams} params={this.state.params} login={login}/>);
    }

    return (
      <Switch>
        <Route path="/admin" component={this.Admin} />
        <Route path="/approvals" component={this.AdminOnboarding} />
        <Route path="/crowdfunding/:nonprofit" component={this.Crowdfunding} />
        <Route path="/disbursements" component={this.Disbursement} />
        <Route path="/welcome-to-onboarding" component={this.WelcomeOnboarding} params={this.state.params}/>
        <Route path="/onboarding" component={this.Onboarding} params={this.state.params}/>
        <Route path="/profile/:nonprofit" component={this.Profile} />
        <Route path="/support" component={this.Support} />
        <Route path="/new-onboarding/:nonprofit/:compliance?/:step?" component={this.NewOnboarding} />
        <Route path="*" component={() => {global.window.location.href=`${baseUrl}/404`; return null; }} />
        {/* TODO: New Onboarding Update - Pandemonium
          • Update the route route to /onboarding once done
          • See if we should be using /onboarding/:nonprofit for selecting Nonprofits.
          • Alternatively, if no nonprofit selected, give user option to select a nonprofit.
        */}
        {/* <Route component={NoMatch} /> */}
      </Switch>
    );
    // return null;
  }
}
const mapStateToProps = state => ({
  login: state.login,
  home: state.home,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
  Home: bindActionCreators(HomeActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
)(HomeRoutes));

