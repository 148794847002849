import React, { Component } from 'react';

import Icon from '@mdi/react';
import { mdiPlus, mdiClose, mdiTrashCanOutline } from '@mdi/js';

import './ProjectGeneralEdit.css';
import GoogleCityInput from '../GoogleCityInput/GoogleCityInput';
import SelectInput from '../SelectInput';
import ImageCropUpload from '../ImageCropUpload';
import Editor from '../Editor';

import Helpers from '../../utils/helpers';

const regionOptions = [{ label: 'Andaman & Nicobar Islands', value: 'Andaman & Nicobar Islands' }, { label: 'Andhra Pradesh', value: 'Andhra Pradesh' }, { label: 'Assam', value: 'Assam' }, { label: 'Chandigarh', value: 'Chandigarh' }, { label: 'Dadra & Nagar Haveli', value: 'Dadra & Nagar Haveli' }, { label: 'Delhi', value: 'Delhi' }, { label: 'Gujarat', value: 'Gujarat' }, { label: 'Haryana', value: 'Haryana' }, { label: 'Himachal Pradesh', value: 'Himachal Pradesh' }, { label: 'Jammu & Kashmir', value: 'Jammu & Kashmir' }, { label: 'Kerala', value: 'Kerala' }, { label: 'Lakshadweep', value: 'Lakshadweep' }, { label: 'Maharashtra', value: 'Maharashtra' }, { label: 'Manipur', value: 'Manipur' }, { label: 'Meghalaya', value: 'Meghalaya' }, { label: 'Karnataka', value: 'Karnataka' }, { label: 'Nagaland', value: 'Nagaland' }, { label: 'Odisha', value: 'Odisha' }, { label: 'Pondicherry', value: 'Pondicherry' }, { label: 'Punjab', value: 'Punjab' }, { label: 'Rajasthan', value: 'Rajasthan' }, { label: 'Tamil Nadu', value: 'Tamil Nadu' }, { label: 'Tripura', value: 'Tripura' }, { label: 'West Bengal', value: 'West Bengal' }, { label: 'Sikkim', value: 'Sikkim' }, { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' }, { label: 'Mizoram', value: 'Mizoram' }, { label: 'Daman & Diu', value: 'Daman & Diu' }, { label: 'Goa', value: 'Goa' }, { label: 'Bihar', value: 'Bihar' }, { label: 'Madhya Pradesh', value: 'Madhya Pradesh' }, { label: 'Uttar Pradesh', value: 'Uttar Pradesh' }, { label: 'Chattisgarh', value: 'Chattisgarh' }, { label: 'Jharkhand', value: 'Jharkhand' }, { label: 'Uttarakhand', value: 'Uttarakhand' }, { label: 'Telangana', value: 'Telangana' }];

class ProjectGeneralEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectLocation: '',
      changes: {_id: props.value._id},
    };
  }

  componentDidMount() {
    const { value } = this.props;
    const sites = value.sites || [];
    if (sites.length > 0) {
      const site = sites[0];
      let projectLocation = 'city';
      if (!site.city || site.city === '') {
        projectLocation = 'region';
      }
      if (!(site.region || site.city) || (site.region === '' && site.city === '')) {
        projectLocation = 'india';
      }
      this.setState({ projectLocation });
    }
    if (!value.project_costs || (value.project_costs  && value.project_costs.length === 0)) {
      this.setDefaultProjectCost();
    }
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } }, );
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  setDefaultProjectCost = () => {
    const projectCost = [{
      title: 'Administrative Cost (rent,electricity etc.)',
      value: 0,
    }, {
      title: 'Salary',
      value: 0,
    }, {
      title: 'Direct Project Costs',
      value: 0,
    }, {
      title: 'Infrastructure (computers, land, vehicle, etc)',
      value: 0,
    }, {
      title: 'Transportation',
      value: 0,
    }, {
      title: 'Awareness & Empowerment (capacity building, workshops,meetings)',
      value: 0,
    }, {
      title: 'Fundraising Expenses',
      value: 0,
    }];
    this.handelInputChange(['project', 'project_costs'], projectCost);
  }

  changeProjectLocation = (e) => {
    let sites = [];
    if (e.target.value === 'india') {
      sites = [{ country_code: 'IN' }];
    }
    this.handelInputChange(['project', 'sites'], sites);
    this.setState({ projectLocation: e.target.value });
  }

  removeProjectCost = (index) => {
    const projectCost = [...this.getInputData(['project', 'project_costs'])];
    projectCost.splice(index, 1);
    this.handelInputChange(['project', 'project_costs'], projectCost);
  }

  addProjectCost = () => {
    const projectCost = [...this.getInputData(['project', 'project_costs'])];
    projectCost.push({
      title: '',
      value: 0,
    });
    this.handelInputChange(['project', 'project_costs'], projectCost);
  }

  projectLocationText = (location, type) => {
    switch (location) {
      case 'city':
        if (type === 'p') return 'Cities';
        return 'city';
      case 'region':
        if (type === 'p') return 'States';
        return 'state';
      default:
    }
    return '';
  }

  setCitySelect = (city) => {
    const sites = [...this.getInputData(['project', 'sites'])];
    sites.push(city);
    this.handelInputChange(['project', 'sites'], sites);
  }

  setRegionSelect = (regions) => {
    const sites = regions.map(item => ({
      country_code: 'IN',
      region: item.value,
    }));
    this.handelInputChange(['project', 'sites'], sites);
  }

  getRegionSelectValue = () => {
    const sites = this.getInputData(['project', 'sites']);
    const returnValue = sites.map(item => ({
      label: item.region,
      value: item.region,
    }));
    return returnValue;
  }

  deleteCity = (index) => {
    const sites = [...this.getInputData(['project', 'sites'])];
    sites.splice(index, 1);
    this.handelInputChange(['project', 'sites'], sites);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    if (onSubmit) {
      onSubmit(changes);
    }
  }

  // Method to check project as featured or not featured on the nonprofit page 
  toggleProjectPublish = (id) => {
    const isFeatured = this.getInputData([null, 'is_featured'])
    this.handelInputChange([null, 'is_featured'], !isFeatured);
  }


  render() {
    const { children, causes, value } = this.props;
    const { projectLocation } = this.state;
    return (
      <form autoComplete="off" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
              <div className="heading-bar feature-unfeature">
                <h3 className="mr-b-20">General Details</h3>
                <div className="heading-bar-btn feature-unfeature">
                  <p>Feature this project on {value.nonprofit.name ? value.nonprofit.name + '\'s' : 'the Non-Profit\'s'} page?</p>
                  <button type="button" 
                    className={`btn ${this.getInputData(['value','is_featured'])? 'btn-primary' : 'btn-outline-secondary' } btn-left`} 
                    style={{height:'fit-content'}} 
                    onClick={(e) => {e.preventDefault(); this.toggleProjectPublish()}}>
                    Yes
                  </button>
                  <button type="button" 
                    className={`btn ${!this.getInputData(['value','is_featured'])? 'btn-primary' : 'btn-outline-secondary' } btn-right`} 
                    style={{height:'fit-content'}} 
                    onClick={(e) => {e.preventDefault(); this.toggleProjectPublish()}}>
                    No
                  </button>                    
                </div>
              </div>
                {/* <p className="text-secondary"> Nonprofit general details </p> */}

                <div className="form-group">
                  <label>Name of the Project</label>
                  {this.getInputErrors(['project', 'name'])}
                  <input type="text" className="form-control" placeholder="Project name" value={this.getInputData(['project', 'name'])} onChange={(e) => { this.handelInputChange(['project', 'name'], e); }} />
                </div>
                <div className="form-group">
                  <label>Issue (What is the concern? - Max 200 words)</label>
                  {this.getInputErrors(['project', 'issue'])}
                  <Editor value={this.getInputData(['project', 'issue'])} onChange={(e) => { this.handelInputChange(['project', 'issue'], e); }} />
                </div>
                <div className="form-group">
                  <label>Action (What are you going to do about it? - Max 200 words)</label>
                  {this.getInputErrors(['project', 'action'])}
                  <Editor value={this.getInputData(['project', 'action'])} onChange={(e) => { this.handelInputChange(['project', 'action'], e); }} />
                </div>
                <div className="form-group">
                  <label>Impact (What is the impact that you wish to create? - Max 200 words)</label>
                  {this.getInputErrors(['project', 'impact'])}
                  <Editor value={this.getInputData(['project', 'impact'])} onChange={(e) => { this.handelInputChange(['project', 'impact'], e); }} />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Primary Cause</label>
                      {this.getInputErrors(['project', 'causes'])}
                      <select className="form-control" value={this.getInputData(['project', 'causes', 0])} onChange={(e) => { this.handelInputChange(['project', 'causes', 0], e); }}>
                        <option disabled hidden value="">Select</option>
                        {causes.map(item => (
                          <option value={item.name} key={item.slug}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Secondary Cause</label>
                      {this.getInputErrors(['project', 'causes'])}
                      <select className="form-control" value={this.getInputData(['project', 'causes', 1])} onChange={(e) => { this.handelInputChange(['project', 'causes', 1], e); }}>
                        <option disabled hidden value="">Select</option>
                        {causes.map(item => (
                          <option value={item.name} key={item.slug}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Project Location(s):</h3>
                {/* <p className="text-secondary"> You can select multiple locations(locations can include city, state or just india) </p> */}
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Project is</label>
                      {this.getInputErrors(['project', 'sites'])}
                      <select className="form-control" value={projectLocation} onChange={(e) => { this.changeProjectLocation(e); }}>
                        <option value="" disabled hidden>Select</option>
                        <option value="city">Implemented in single/multiple Cities</option>
                        <option value="region">Implemented in single/multiple States</option>
                        <option value="india">Implemented PAN India</option>
                      </select>
                    </div>
                  </div>
                  {(projectLocation === 'city') ? (
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Type {this.projectLocationText(projectLocation, 's')} name and select from suggestions</label>
                        <GoogleCityInput onSelect={this.setCitySelect} />
                      </div>
                    </div>
                  ) : null}
                  {((projectLocation === 'city') && this.getInputData(['project', 'sites']).length > 0) ? (
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Project Location(s) selected (You can select multiple {this.projectLocationText(projectLocation, 'p')})</label>
                        <div>
                          {this.getInputData(['project', 'sites']).map((item, index) => (
                            <button type="button" className="badge badge-primary" key={index} onClick={() => { this.deleteCity(index); }}>
                              {(item.city) ? `${item.city}, ${item.region}, India` : null}
                              {(!item.city) ? `${item.region}, India` : null}
                              <Icon path={mdiClose} className="menu-arrow" size={0.60} color="#ffffff" />
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {(projectLocation === 'region') ? (
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Type {this.projectLocationText(projectLocation, 's')} name and select from suggestions</label>
                        <SelectInput options={regionOptions} onChange={(e) => { this.setRegionSelect(e); }} isMulti defaultValue={this.getRegionSelectValue()} />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Project Images</h3>
                {/* <p className="text-secondary"> Nonprofit general details </p> */}
                <ImageCropUpload title="Please select Cover Image for Project" label="Cover Image" height={300} width={1200} value={this.getInputData(['project', 'cover_image'])} onChange={(e) => { this.handelInputChange(['project', 'cover_image'], e); }} error={this.getInputErrors(['project', 'cover_image'])} />
                <ImageCropUpload title="Please select Tile Image for Project" label="Tile Image" height={200} width={300} value={this.getInputData(['project', 'image'])} onChange={(e) => { this.handelInputChange(['project', 'image'], e); }} error={this.getInputErrors(['project', 'image'])} />
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Donation Options</h3>
                {this.getInputErrors(['project', 'donation_options'])}
                {/* <p className="text-secondary"> Nonprofit general details </p> */}
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <p>Amount</p>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <p>Donation Description</p>
                  </div>
                </div>
                <div className="row tm-divider">
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">INR</span>
                        </div>
                        <input type="number" className="form-control" placeholder="Amount eg 500, 1000" value={this.getInputData(['project', 'donation_options', 0, 'amount'])} onChange={(e) => { this.handelInputChange(['project', 'donation_options', 0, 'amount'], e); }} />
                      </div>
                      {this.getInputErrors(['project', 'donation_options', 0, 'amount'])}
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Description" value={this.getInputData(['project', 'donation_options', 0, 'title'])} onChange={(e) => { this.handelInputChange(['project', 'donation_options', 0, 'title'], e); }} />
                      {this.getInputErrors(['project', 'donation_options', 0, 'title'])}
                    </div>
                  </div>
                </div>
                <div className="row tm-divider">
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">INR</span>
                        </div>
                        <input type="number" className="form-control" placeholder="Amount eg 500, 1000" value={this.getInputData(['project', 'donation_options', 1, 'amount'])} onChange={(e) => { this.handelInputChange(['project', 'donation_options', 1, 'amount'], e); }} />
                      </div>
                      {this.getInputErrors(['project', 'donation_options', 1, 'amount'])}
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Description" value={this.getInputData(['project', 'donation_options', 1, 'title'])} onChange={(e) => { this.handelInputChange(['project', 'donation_options', 1, 'title'], e); }} />
                      {this.getInputErrors(['project', 'donation_options', 1, 'title'])}
                    </div>
                  </div>
                </div>
                <div className="row tm-divider">
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">INR</span>
                        </div>
                        <input type="number" className="form-control" placeholder="Editable Option. Amount eg 500, 1000" value={this.getInputData(['project', 'donation_options', 2, 'amount'])} onChange={(e) => { this.handelInputChange(['project', 'donation_options', 2, 'amount'], e); }} />
                      </div>
                      {this.getInputErrors(['project', 'donation_options', 2, 'amount'])}
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Description" value={this.getInputData(['project', 'donation_options', 2, 'title'])} onChange={(e) => { this.handelInputChange(['project', 'donation_options', 2, 'title'], e); }} />
                      {this.getInputErrors(['project', 'donation_options', 2, 'title'])}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Cost Break-Up & Target Amount</h3>
                {/* <p className="text-secondary"> Nonprofit general details </p> */}
                <div className="row">
                  <div className="col-lg-6 col-md-5">
                    <p>Particulars</p>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <p>Amount</p>
                  </div>
                </div>
                {(this.getInputData(['project', 'project_costs']) || []).map((item, index) => (
                  <div className="row tm-divider" key={index}>
                    <div className="col-lg-6 col-md-5">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Particulars" value={this.getInputData(['project', 'project_costs', index, 'title'])} onChange={(e) => { this.handelInputChange(['project', 'project_costs', index, 'title'], e); }} />
                        {this.getInputErrors(['project', 'project_costs', index, 'title'])}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">INR</span>
                          </div>
                          <input type="number" className="form-control" placeholder="Amount" value={(this.getInputData(['project', 'project_costs', index, 'value']))} onChange={(e) => { this.handelInputChange(['project', 'project_costs', index, 'value'], e); }} />
                        </div>
                        {this.getInputErrors(['project', 'project_costs', index, 'value'])}
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-3 tm-remove">
                      <button type="button" className="btn btn-light" onClick={() => { this.removeProjectCost(index); }}>
                        <Icon path={mdiTrashCanOutline} className="menu-arrow" size={0.60} color="#333333" />
                        &nbsp;Remove
                      </button>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-md-12">
                    <button type="button" className="btn btn-info mr-2" onClick={() => { this.addProjectCost(); }}>
                      <Icon path={mdiPlus} className="menu-arrow" size={0.60} color="#ffffff" />
                      &nbsp;Add
                    </button>
                  </div>
                </div>
                <div className="row justify-content-end mr-t-20">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Target amount</label>
                      {this.getInputErrors(['project', 'target_amount'])}
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">INR</span>
                        </div>
                        <input type="text" className="form-control" placeholder="0" disabled value={this.getInputData(['project', 'target_amount'])} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ProjectGeneralEdit;
