import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';
import ProjectService from '../../services/project-service';

export const GET_PROFILE_PROJECT = 'GET_PROFILE_PROJECT';
export const GET_PROFILE_PROJECT_SUCCESS = 'GET_PROFILE_PROJECT_SUCCESS';
export const GET_PROFILE_PROJECT_FAIL = 'GET_PROFILE_PROJECT_FAIL';

export const PROFILE_PROJECT_RESET = 'PROFILE_PROJECT_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  projects: [],
  get_status: {},
  set_status: {},
  errors: {},
};

export const projectsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PROFILE_PROJECT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'pending',
        },
      };
    case GET_PROFILE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: action.projects,
        get_status: {
          ...state.get_status,
          project: 'success',
        },
      };
    case GET_PROFILE_PROJECT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'failed',
        },
      };
    case PROFILE_PROJECT_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getProject(request) {
  return (dispatch) => {
    dispatch({
      type: GET_PROFILE_PROJECT,
    });
    return ProjectService.get(request)
      .then((response) => {
        dispatch({
          type: GET_PROFILE_PROJECT_SUCCESS,
          projects: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_PROFILE_PROJECT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: PROFILE_PROJECT_RESET,
    });
  };
}
