import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

import Helpers from '../../utils/helpers';

class NonprofitAwardsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getStateData = (from) => {
    const { state } = this;
    const { value } = this.props;
    if (state[from[0]] && from.length === 1 && from[0] !== 'nonprofit') {
      return state[from[0]];
    }
    if (value[from[1]] && from.length === 2 && from[0] === 'nonprofit') {
      if (from[1] === 'video') {
        if (value[from[1]] && value[from[1]] !== '') {
          return (<iframe width="100%" height="300" src={value.video} frameBorder="0" allowFullScreen></iframe>);
        } else {
          return null;
        }
      }
      return value[from[1]];
    }
    if (value.address && from.length === 3 && from[0] === 'nonprofit' && from[1] === 'address') {
      return value.address[from[2]];
    }
    if (value.external_profiles && from.length > 2 && from[0] === 'nonprofit' && from[1] === 'external_profiles') {
      const location = Helpers.objSearch(value.external_profiles, from[2], 'label');
      if (location >= 0) {
        return value.external_profiles[location].uri;
      }
    }
    if (value.bank_accounts && from.length === 4 && from[0] === 'nonprofit' && from[1] === 'bank_accounts' && (from[2] === 0 || from[2] === 1)) {
      if (value.bank_accounts[from[2]]) {
        return value.bank_accounts[from[2]][from[3]];
      }
    }
    return '';
  }

  getErrorMessage = (forList) => {
    const { errors } = this.props;
    const { isRegisteredName, isFCRA } = this.state;
    if (forList[1] === 'external_profiles') {
      const external_profiles = this.getStateData(['nonprofit', 'external_profiles']);
      const index = Helpers.objSearch(external_profiles, forList[2], 'label');
      if (index >= 0) {
        forList[2] = index;
        forList.push('uri');
      }
    }
    const forItem = forList.slice(1, forList.length).join('.');
    let returnValue = null;
    if (errors && errors.length > 0) {
      if (forList[1] === 'isRegisteredName' && isRegisteredName === '') {
        returnValue = (
          <div className="error-message col-sm-12">
            Please select a option
          </div>
        );
      }
      if (forList[1] === 'isFCRA' && isFCRA === '') {
        returnValue = (
          <div className="error-message col-sm-12">
            Please select a option
          </div>
        );
      }
      errors.forEach((item) => {
        if (item[forItem]) {
          returnValue = (
            <div className="error-message">
              {item[forItem]}
            </div>
          );
        }
      });
    }
    return returnValue;
  }

  render() {
    const { children } = this.props;
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">Awards and Recognitions</h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> 2019-20</label>
                      <input className="form-control" type="text" placeholder="Mention the awards and recognitions, N/A if not available" value="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> 2018-19</label>
                      <input className="form-control" type="text" placeholder="Mention the awards and recognitions, N/A if not available" value="" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> 2017-18</label>
                      <input className="form-control" type="text" placeholder="Mention the awards and recognitions, N/A if not available" value="" />
                    </div>
                  </div>
                </div>

              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default NonprofitAwardsEdit;
