/*
  This component is used to generate the form from the schema that it recieved. 
  If you've landed on this page for the first time, start with the functional component named 'Form'.
  It then calles a component that renders recursively based on the form schema value.
*/

// TODO: Improvements to be made 
// • Optimise compontent render, by checking state and props checking and memoising 

import React, { useEffect, useState } from 'react'

import Helpers from '../../utils/helpers';
import DynamicFormHelper from '../../utils/dynamicform'

import FormInput from '../Form/FormInput'
import FormActionControls from './FormActionControls'
import RepeatingFields from './RepeatingFields'

import Loading from '../../appComponents/Loading'

import './Form.scss'

const Form = (props) => {

  const [ formSchema, setFormSchema] = useState({})  
  const [ inputSchema, setInputSchema] = useState({})
  const [ formData, setFormData ] = useState({})
  const [ requiredFields, setrequiredFields ] = useState([])  
  const [ errors, setErrors ] = useState({})
  const [ hasSaveError, setHasSaveError ] = useState(false)
  const [ isFormCollapsed, setIsFormCollapsed ] = useState(false)
  const [ formActions, setFormActions ] = useState({})
  const [ isRead, setIsRead ] = useState(false)
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ formFields, setFormFields ] = useState([])
  const [ edits, setEdits ] = useState([])
  const [ filledFields, setFilledFields ] = useState(0)

  const { isReadMode, isCollapseable, index, actions, stepHasError, stepHasChanges, isApprovals, step, methods, setCheckuiErrors } = props;
  useEffect(() => {    
    if(props.values && Object.keys(props.values).length > 0){      
      setFormData({
        ...formData,
        ...props.values
      })    
      setIsLoaded(true)
    }
  },[props.values])

  useEffect(() => {
    // Updating Errors passed from the parent to state     
    if(props.errors && !Helpers.isEqual(props.errors, errors)){      
      setErrors(props.errors)
    }
  },[props.errors])

  useEffect(() => {    
    if(isRead){      
      const formAction = formActions
      delete formAction.submit 
      setFormActions(formAction)
    }
    else{
      setFormActions({
        ...formActions,
        submit: {
          label: 'Save',
          method: formActionHandler
        }
      })
    }
  }, [isRead])  

  useEffect(() => {
    // Checking if the recieved schema is same as the one in the state     
    if(props.formSchema && !Helpers.isEqual(props.formSchema,formSchema)){
      // Setting the FormSchema value to state  
      setInputSchema(props.formSchema);      
      let formFields = DynamicFormHelper.getFormFieldsFromSchema(props.formSchema, true, isApprovals)
      setFormSchema(formFields)
    }
   
  },[props.formSchema])


  useEffect(() => {
    // Updating Errors passed from the parent to state     
    if(props.requiredFields && !Helpers.isEqual(props.requiredFields, requiredFields)){      
      setrequiredFields(props.requiredFields)
    }
  },[props.requiredFields])

  useEffect(() => {
    if(isCollapseable){
      if(stepHasChanges !== undefined){
        if(stepHasChanges)
          setIsFormCollapsed(false)
        else{
          setIsFormCollapsed(true)
        }
      }
      else if(index){
        if(index !== 0){
          setIsFormCollapsed(isCollapseable)
        }
      }
    }
  }, [isCollapseable, index, stepHasChanges])

  useEffect(() => {    
    setIsRead(isReadMode)
  }, [isReadMode])  

  useEffect(() => {    
    // Creating form actions' object based on the values that have been passed from parent props
    const formAction = {}
    if(actions.stepchange){
      formAction['stepchange'] = actions.stepchange
      formAction.stepchange.method = onStepChange
    }
    
    if(actions.submit){
      formAction['submit'] = actions.submit
      formAction.submit.method = formActionHandler
    }

    if(actions.editorread){
      formAction['editorread'] = actions.editorread
      formAction.editorread.method = toggleReadEditMode
      if(!isRead){
        formAction['submit'] = {
          label: 'Save',
          method: formActionHandler
        }
      }
    }    

    if(isRead && edits.length > 0){
      formAction['sectionApprove'] = {
        label: 'Approve',
        method: handleApproveSection
      }

      formAction['sectionReject'] = {
        label: 'Reject',
        method: handleRejectSection
      }     
    }    

    setFormActions(formAction)
  },[actions, isRead, edits])


  useEffect(() => {    
    if(formSchema.length > 0){      
      const formFields = getFormField(formSchema);
      setFormFields(formFields);      
      let is_project = false       
      if(formFields && formFields[0]?.collection === 'projects') is_project = true
      checkFormProgress(formFields, formData)
      let editsIndex = Helpers.getIndexofEditsfromFormSchemaKey(formFields, is_project ? 
        (formData?.projects?.edits ? formData?.projects?.edits : []): 
        (formData?.nonprofits?.edits ? formData?.nonprofits?.edits : [])        
      )      
      setEdits(editsIndex)
    }
  }, [formSchema, formData, step])

  useEffect(() => {
    //to prevent error on the step loading
    if (step) {
      setrequiredFields([])
      setErrors([])
    }
  }, [step])
  
  const formChangeHandler = (e) => { 
    if(e && e.value !== undefined){  
      if(e.controlArrayKey && e.index !== undefined){                 
        setFormData({
          ...formData,
          ...Helpers.setPathToObject(
            Helpers.unsetArrayKey(formData, e.controlArrayKey, e.index), 
            e.name,
            e.value
          )
        })
      }
      else{        
        setFormData({
          ...formData,
          ...Helpers.setPathToObject(formData,e.name,e.value)
        })
      }   
    }
  }

  const formActionHandler = async (e) => {      
    const { onSubmit} = props;         
    if(e.target && e.target.name === 'save'){  
      onSubmit(formData)
    }
    else if(e.target && e.target.name === 'submit'){          
      onSubmit(formData, true)       
    }
    else{
      // Do Nothing
    }      
  }
  
  const onStepChange = async (step) => {
    const { onChange } = props    
    onChange(step, formData);
  }

  const getValue = (path) => {
    return Helpers.getObjectfromPath(formData, path)
  }

  const setValue = (path, value) => {
    if(value) return Helpers.setPathToObject(formData, path, value)
  }

  const getArrayIndex = (key, identifier) => {  
    let index = DynamicFormHelper.getArrayFieldIndex(formData, key.split('.{{index}}.')[0], identifier)  
    return index;
  }

  const toggleCollapseForm = () => {  
    setIsFormCollapsed(!isFormCollapsed)
  }

  const toggleReadEditMode = () => {    
    setIsRead(!isRead)
  }

  const handleApproveSection = async () => {
    const input = formSchema.filter(item => item.element === 'input')[0];

    if(methods && methods.approveRejectSection){      
      await methods.approveRejectSection(edits, 'approve', input?.collection === 'projects')
    }
  }

  const handleRejectSection = async () => {
    const input = formSchema.filter(item => item.element === 'input')[0];
    if(methods && methods.approveRejectSection){
      await methods.approveRejectSection(edits, 'reject', input?.collection === 'projects')      
    }
  }

  const getFormField = (fields) => {
    const formFieldElementTypes = [
      'input',
      'radio',
      'select',
      'textarea',
      'upload',
      'media', 
      'multi-select',
      'tel',
      'checkbox',
    ]
    let newArray = [];
    fields.forEach((item) => {
      formFieldElementTypes.forEach((x) => {
        if(x === item.element && item.visibility !== false){
          if(item.dependency){            
            if(Helpers.getObjectfromPath(formData, item.dependency.key) === item.dependency.on) newArray.push(item)            
          }
          else{
            newArray.push(item);
          }
        }
      })
    })
    return newArray;
  }

  const checkFormProgress = (formInputs, formData) => {
    let count = 0;
    if(formInputs.length > 0){
      formInputs.forEach((item) => {
        let val = Helpers.getObjectfromPath(formData, item.key)
        if(val){
          count++
        }
      })
    }
    setFilledFields(count)
  }

  return (    
    <form className="ob-np-form" name="" onSubmit={formActionHandler}> 
      <div className="row justify-content-md-center">      
        <div className="col-md-12 grid-margin">              
          <div className="card">
            {isLoaded === true ? (
              <div className="card-body">
                { stepHasError && (
                  <div className="section-errors">
                    <span>Section has {stepHasError.length} error(s).</span>
                  </div>
                ) }

                { stepHasChanges && (
                  <div className="section-changes">
                    <span>Section has {/*stepHasChanges */} unapproved changes(s).</span>
                  </div>
                ) }

                { inputSchema.title && (<h3 className="form-heading mr-b-20">{inputSchema.title} <div className="progress"><div className="progress-bar progress-bar-striped bg-success" style={{width: `${filledFields / formFields.length * 100}%`  }} > </div></div><span className="percent-text">{(filledFields / formFields.length * 100).toFixed()}%</span></h3>) }
                { inputSchema.description && (<p className="mr-b-10" dangerouslySetInnerHTML={{__html: inputSchema.description}}></p>) }
                { isCollapseable && (
                  <button className="collapseButton btn" type="button" onClick={toggleCollapseForm}> { isFormCollapsed ? 'Expand': 'Collapse'} </button>
                )}
                { ((inputSchema.title || inputSchema.description) && !isFormCollapsed) && (<hr/>)}                
                <div className={isFormCollapsed ? `collapse`: ``}>
                  <div className="row">
                    {formSchema && Object.keys(formSchema).length !== 0 && formSchema.map((formItem, i) => {                      
                                            
                      if(formItem.key && formItem.identifier) {                      
                        if(formItem.key.indexOf('{{index}}') > -1){                  
                          const index = getArrayIndex(formItem.key, formItem.identifier)                        
                          formItem.key = formItem.key.replace(/{{index}}/g, index);                        
                        }
                      }
                      
                      if(formItem.conditionalValue) {           
                        const conditional = Helpers.getConditionalValue(formData, formItem.conditionalValue)                        
                        const value = getValue(formItem.key)
                        if((!value || (formItem.conditionalValue && formItem.dependency && (value !== conditional))) && getValue){
                          setValue(formItem.key, conditional)
                        }
                      }
                   
                      if(formItem.dependency && formItem.dependency !== undefined && formItem.dependency !== null){                        
                        const key = formItem.dependency.key
                        const value = formItem.dependency.on                          
                        if(getValue(key) !== value){
                          return null
                        }
                      }

                      // For repeating fields that are depenedent on a key value 
                      if(formItem.repeaton && formItem.repeaton !== null && typeof formItem.repeaton === 'string'){                      
                        return (
                          <RepeatingFields
                            key={i} 
                            element={formItem}
                            formData={formData}
                            formChangeHandler={formChangeHandler}
                            getValue={getValue}
                            requiredFields={requiredFields}
                            errors={errors}
                            isReadMode={isRead}
                          />      
                        )                                  
                      }
                      else{                        
                        return (
                          <div key={i} 
                            className={
                              (formItem.is_column? 'col-sm-12 col-lg-6 col-md-6 col-xs-12' : 'col-lg-12 col-sm-12 col-md-12') + 
                              (formItem.visibility === false ? ' hidden': '')
                          }>
                            <FormInput 
                              item={formItem}
                              key={i}
                              onChange={formChangeHandler}  
                              isReadMode={isRead}
                              value={getValue(formItem.key) !== undefined ? getValue(formItem.key) : formItem.value}
                              isRequiredError={requiredFields.length > 0 && requiredFields.indexOf(formItem.key) >= 0 ? formItem.key : false}
                              isValidationError={Object.keys(errors).length > 0 && Object.keys(errors).indexOf(formItem.key) >= 0 ? {[formItem.key]: errors[formItem.key]} : false}
                              formData={formData}
                              setCheckuiErrors={setCheckuiErrors}
                            />
                          </div>
                        )
                      }
                    })                  
                    }
                  </div>              
                  {props.actions !== false && (
                    <FormActionControls
                      key={props.index ? props.index : 1}
                      formActionHandler={formActionHandler}                
                      hasSaveError={hasSaveError}
                      actions={formActions}
                      isReadMode={isRead}
                    />
                  )}  
                </div>
              </div>    
            ): (
              <Loading />
            )}      
          </div>              
        </div>              
      </div>          
    </form>      
  )
}

export default Form;