import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Redirect } from 'react-router-dom';

import Icon from '@mdi/react';
import {
  mdiPlus,
  mdiLoading,
  mdiChevronRight,
  mdiFloppy,
} from '@mdi/js';

import * as profileProjectItemAction from '../../../reducers/Profile/projectItemReducer';
import * as ConfigAction from '../../../reducers/configReducer';
import * as NotificationAction from '../../../reducers/notificationReducer';
import * as Updates from "../../../reducers/Admin/adminProjectUpdatesReducer";

import Helpers from '../../../utils/helpers';

import Loading from '../../../appComponents/Loading';

import ProjectGeneralRead from '../../../components/ProjectGeneralRead';
import ProjectGeneralEdit from '../../../components/ProjectGeneralEdit';
import ProjectOnboardingEdit from '../../../components/ProjectOnboardingEdit';
import GalleryRead from '../../../components/GalleryRead';
import GalleryEdit from '../../../components/GalleryEdit';
import UpdateRead from '../../../components/UpdateRead';
import CampaignRead from '../../../components/CampaignRead'
import CampaignEdit from '../../../components/CampaignEdit'
import FaqRead from '../../../components/FaqRead';
import FaqEdit from '../../../components/FaqEdit';

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readEdit: null,
      project: {},
      prevProject: {},
      buttonClicked : ''
    };
  }

  componentDidMount() {
    const { projects, Projects, match, config, Config, Updates } = this.props;
    const { project } = match.params;
    Updates.load(project);
    if (!projects.get_status.project && project !== 'add-project') {
      Projects.getProject(project);
    }
    if (!config.get_status.config) {
      Config.get();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.projects.project !== state.prevProject && Object.keys(props.projects.project).length > 0) {
      setData.prevProject = props.projects.project;
      setData.project = Helpers.createNonprofitWithDiff(props.projects.project);
      // setData.project = { ...props.projects.project };
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  componentWillUnmount() {
    const { Projects } = this.props;
    Projects.reset();
  }

  onProjectSave = (project) => {
    const { Projects, match } = this.props;
    const { nonprofit } = match.params;
    const { project: data, buttonClicked } = this.state;
    this.setState({buttonClicked : 'save'})
    if (data._id) {
      Projects.setProject(project).then(() => {
        this.changeReadOrEditForm();
      }).catch((e) => {
        console.log("Errors are", "==>>", JSON.stringify(e));
      });
    } else {
      Projects.createProject({ ...data, nonprofit, status: 'final_approval' }).then(res => {
        this.setState({project : res})
      });
    }
  }

  onProjectChange = (project) => {
    this.setState({ project });
  }

  changeReadOrEditForm = (to) => {
    const { readEdit } = this.state;
    const { Notification, projects } = this.props;
    if (to === undefined) {
      this.setState({ readEdit: null });
    } else if (to === null) {
      this.setState({ readEdit: null, project: JSON.parse(JSON.stringify(projects.project)) });
    } else if (to !== null) {
      if(readEdit !== null) {
        Notification.addNotification("Please Save/Cancel before editing another section");
      } else {
        this.setState({ readEdit: to });
      }
    }
  }

  onProjectSubmit = () => {
    const { Projects, match } = this.props;
    const { nonprofit, project } = match.params;
    const { project: data } = this.state;
    this.setState({buttonClicked : 'saveAndSubmit'})
    if (data._id) {
      return Projects.setProject({ ...data, status: 'final_approval' });
    } else {
      return Projects.createProject({ ...data, nonprofit, status: 'final_approval' });
    }
  }

  buttonListHTML = (component) => {
    const { projects, location } = this.props;
    const { readEdit, project, buttonClicked } = this.state;
    if (readEdit === component) {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
          <button type="submit" className="btn btn-primary mr-2">
            {(projects.set_status.project === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
              )}
                  &nbsp;Save
                </button>
          <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.changeReadOrEditForm(null); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                  &nbsp;Cancel
                </button>
        </div>
      );
    }
    if (component === 'addProject' || location.pathname.includes('add-project')) {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
          {!project.hasOwnProperty('_id') ?
          <button type="submit" className="btn btn-success mr-2">
            {(projects.set_status.project === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
              <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
            )}&nbsp;Save
          </button> :
          <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.onProjectSubmit(); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            &nbsp;Save & Submit for review
          </button>}
        </div>
      )
    }
    if (component === 'general') {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
          <button type="submit" className="btn btn-success mr-2">
            {(projects.set_status.project === 'pending' && buttonClicked === 'save') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
              <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
            )}&nbsp;Save
          </button>
          <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.onProjectSubmit(); }}>
          {(projects.set_status.project === 'pending' && buttonClicked === 'saveAndSubmit') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            )}
            &nbsp;Save & Submit for review
          </button>
        </div>
      )
    }
    return (
      <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
        <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.changeReadOrEditForm(component); }}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
              &nbsp;Edit
            </button>
      </div>
    );
  }

  whichForm = (status) => {
    const { readEdit, project, prevProject } = this.state;
    const { projects, config, event, updates, Updates } = this.props;
    let returnDOM = [];
    if (readEdit === 'general' || readEdit === 'published') {
      returnDOM.push(
        <ProjectGeneralEdit key={status} value={project} setProject={this.props.Projects.setProject} errors={projects.errors.project} onSubmit={this.onProjectSave} onChange={this.onProjectChange} causes={config.causes || []}>
          {this.buttonListHTML(status)}
        </ProjectGeneralEdit>
      );
    } else {
      returnDOM.push(
        <ProjectGeneralRead key={status} tabs={{ Draft: project, Live: prevProject }} errors={projects.errors.project}>
          {this.buttonListHTML(status)}
        </ProjectGeneralRead>
      );
    }
    
    if (readEdit === 'gallery') {
      returnDOM.push(
        <GalleryEdit key="gallery" value={project} errors={projects.errors.project} onSubmit={this.onProjectSave} onChange={this.onProjectChange} causes={config.causes || []}>
          {this.buttonListHTML('gallery')}
        </GalleryEdit>
      );
    }else{
      returnDOM.push(
        <GalleryRead key="gallery" tabs={{ Draft: project, Live: prevProject }} errors={projects.errors.project}>
          {this.buttonListHTML('gallery')}
        </GalleryRead>
      );
    }

    if (readEdit === 'updates') {
      returnDOM.push(
        <></>
      );
    } else {
      returnDOM.push(
        <UpdateRead key="updates" Updates={Updates} match={this.props.match.params.project} updates={updates} errors={updates.errors} >
          {this.buttonListHTML('updates')}
        </UpdateRead>
      );
    }

    if (readEdit === 'campaign') {
      returnDOM.push(
        <CampaignEdit key={'campaign'} value={project} setProject={this.props.Projects.setProject} errors={projects.errors.project} onSubmit={this.onProjectSave} onChange={this.onProjectChange} causes={config.causes || []}>
          {this.buttonListHTML('campaign')}
        </CampaignEdit>
      );
    } else {
      returnDOM.push(
        <CampaignRead key={'campaign'} tabs={{ Draft: project, Live: prevProject }} errors={projects.errors.project}>
          {this.buttonListHTML('campaign')}
        </CampaignRead>
      );
    }

    if (readEdit === 'faq') {
      returnDOM.push(
        <FaqEdit key={'faq'} value={project} setProject={this.props.Projects.setProject} errors={projects.errors.project} onSubmit={this.onProjectSave} onChange={this.onProjectChange} causes={config.causes || []}>
          {this.buttonListHTML('faq')}
        </FaqEdit>
      );
    } else {
      returnDOM.push(
        <FaqRead key={'faq'} tabs={{ Draft: project, Live: prevProject }} errors={projects.errors.project}>
          {this.buttonListHTML('faq')}
        </FaqRead>
      );
    }

    return (<>
      {returnDOM}
    </>);
  }

  render() {
    const { config, projects } = this.props;
    const { project } = this.state;
    if (projects.get_status.project === 'pending') {
      return (<Loading />);
    }
    if (projects.get_status.project === 'failed') {
      return (<Redirect to="/404" />);
    }
    if (projects.project.status === 'published') {
      return this.whichForm('published');
    }
    if (projects.project.status && projects.project.status !== 'pending') {
      return (
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h5 className="mr-b-20" style={{ textAlign: 'center' }}>Thank You for the Submission</h5>
                <p>Your forms will be subjected to an internal verification, post which we will be queuing your project to go live on the platform. Should there be any discrepancies, we shall get back to you at the earliest. </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (this.props.location.pathname.includes('add-project')) {
      if(!project._id) {
        return (
          <ProjectOnboardingEdit value={project} errors={projects.errors.project} onSubmit={this.onProjectSave} onChange={this.onProjectChange} causes={config.causes || []}>
            {this.buttonListHTML('addProject')}
          </ProjectOnboardingEdit>
        )
      } else {
        return <ProjectGeneralEdit key="general" value={project} setProject={this.props.Projects.setProject} errors={projects.errors.project} onSubmit={this.onProjectSave} onChange={this.onProjectChange} causes={config.causes || []}>
          {this.buttonListHTML('addProject')}
        </ProjectGeneralEdit>
      }

    }
    if (projects.project.status && projects.project.status === 'pending') {
      // return this.whichForm();
      return (
        <ProjectGeneralEdit key="general" value={project} setProject={this.props.Projects.setProject} errors={projects.errors.project} onSubmit={this.onProjectSave} onChange={this.onProjectChange} causes={config.causes || []}>
          {this.buttonListHTML('general')}
        </ProjectGeneralEdit>
      )
    }
    return null;
  }
}

const mapStateToProps = state => ({
  projects: state.profileProjectItem,
  config: state.config,
  updates: state.adminProjectUpdates
});

const mapActionToProps = dispatch => ({
  Projects: bindActionCreators(profileProjectItemAction, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
  Updates : bindActionCreators(Updates, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Project);
