import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { format } from 'date-fns';
import Icon from '@mdi/react';
import { mdiFilter, mdiChevronDown, mdiChevronRight, mdiLoading } from '@mdi/js';

import './DisbursementList.scss';

import Loading from '../../../appComponents/Loading';
import FilterByInput from '../../../components/FilterByInput';
import Helpers from '../../../utils/helpers';

import * as DisbursementActions from '../../../reducers/Disbursements/disbursementReducer';

class DisbursementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nonprofit: '',
      query: {},
      filters: [
        {
          label: 'Search',
          type: null,
          placeholder: 'Search',
        },
        {
          label: 'Created after',
          type: 'date',
          query: 'after',
        }, {
          label: 'Created before',
          type: 'date',
          query: 'before',
        }
      ],
    };
  }

  componentDidMount = () => {
    const { Disbursement, match } = this.props;
    const nonprofit = match.params.nonprofit;
    Disbursement.getNonProfitDisbursements(nonprofit);
    this.setState({nonprofit});
  };

  componentWillUnmount() {
    const { Disbursement } = this.props;
    Disbursement.reset();
  }

  loadMore = () => {
    const { Disbursement } = this.props;
    const { nonprofit, query } = this.state;
    Disbursement.getNonProfitDisbursements(nonprofit, query);
  }

  resendDisbursementReport = (disbursementNo) => {
    const { Disbursement } = this.props;
    Disbursement.resendDisbursementReport(disbursementNo);
  }

  refreshDisbursementList = () => {
    const { Disbursement } = this.props;
    const { nonprofit, query } = this.state;
    Disbursement.reset();
    Disbursement.getNonProfitDisbursements(nonprofit, query);
  }

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshDisbursementList();
    });
  };

  addToFilter = (type, value) => {
    const { filters } = this.state;
    const newOption = { ...filters.find((i) => i.label === type) };
    newOption.data = value;
    const newOptions = filters.map((item) => (item.label === type ? newOption : item));
    this.setState(
      { filters: newOptions, query: Helpers.queryBuilderForFilterInput(newOptions) },
      () => {
        this.refreshDisbursementList();
      }
    );
  };

  addToFilterHTML = (type, value) => (
    <span className='filter-wrapper'>
      <button
        type='button'
        className='btn btn-rounded btn-inverse-outline-primary btn-filters'
        onClick={(e) => {
          e.preventDefault();
          this.addToFilter(type, value);
        }}
      >
        <Icon path={mdiFilter} size={0.5} color='#2196f3' />
        <span>Apply as filter</span>
      </button>
    </span>
  );

  disbursementsHTML = () => {
    const { disbursement } = this.props;
    if (
      !disbursement.get_status.disbursement ||
      (disbursement.get_status.disbursement === 'pending' && disbursement.disbursements.length === 0)
    ) {
      return <Loading />;
    }
    if (disbursement.disbursements.length === 0) {
      return <i className='align-center'>No items available</i>;
    }
    return disbursement.disbursements.map((item, index) => (
      <li className='table-list-item' key={index}>
        <div className='row'>
          <div className='col-md-12 table-col'>
            <div className='row'>
              <div className='col-md-2 table-col dl-mob'>{item.disbursement_number ? item.disbursement_number : 'NA'}</div>
              <div className='col-md-2 table-col dl-mob'>{item.donation_count ? item.donation_count : 'NA'}</div>
              <div className='col-md-1 table-col dl-mob'>{item.payment_entity ? item.payment_entity : 'NA'}</div>
              <div className='col-md-2 table-col dl-mob-right'>{item.currency_code ? item.currency_code : 'NA'} { ' ' } {item.donated_amount ? item.donated_amount : 'NA'}</div>
              <div className='col-md-1 table-col dl-mob'>{item.settled_at ? (<span>{format(new Date(item.settled_at), 'do MMM yyyy')}</span>) : 'NA'}</div>
              <div className="col-md-2 table-col dl-mob-right">
                <button className="btn btn-success btn-sm" onClick={(e) => { e.preventDefault(); this.resendDisbursementReport(item.disbursement_number); }}>
                  {(disbursement.get_status['send_report_' + item.disbursement_number] === 'pending') ? (
                    <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                  ) : (
                    <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                  )}
                  &nbsp;Resend report
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    ));
  };

  render() {
    const { disbursement } = this.props;
    const { filters } = this.state;
    return (
      <div className='content-wrapper'>
        <div className='row'>
          <div className='col-12 grid-margin'>
            <div className='card'>
              <div className='card-body'>
                <h3 className='mr-b-20'>All disbursements</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} />
              </div>
            </div>
            <div className='row'>
              <div className='card-col col-xl-12 col-12'>
                <div className='ibox-content'>
                  <div className='table-list live-dis-table'>
                    <ul>
                      <li className='table-head'>
                        <div className='row'>
                          <div className='col-md-12 table-col'>
                            <div className='row'>
                              <div className='col-md-2 table-col dl-mob'>Disbursement No</div>
                              <div className='col-md-2 table-col dl-mob'>No of donations</div>
                              <div className='col-md-1 table-col dl-mob'>Entity</div>
                              <div className='col-md-2 table-col dl-mob-right'>Amount</div>
                              <div className='col-md-1 table-col dl-mob'>Settled on</div>
                              <div className='col-md-2 table-col dl-mob-right'>Actions</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {this.disbursementsHTML()}
                    </ul>
                    <div className="row">
                      {(disbursement.load_status.disbursement) ? (
                        <div className="col-md-12 text-center">
                          <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                        </div>
                      ) : null}
                      {(disbursement.get_status.disbursement === 'pending' && disbursement.disbursements.length !== 0) ? (
                        <div className="col-md-12">
                          <Loading />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  disbursement: state.disbursementsDisbursements
});

const mapActionToProps = (dispatch) => ({
  Disbursement: bindActionCreators(DisbursementActions, dispatch)
});

export default connect(mapStateToProps, mapActionToProps)(DisbursementList);
