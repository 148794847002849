import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classnames from 'classnames';

import ProjectTile from '../../../components/ProjectTile';

import * as ProjectListActions from '../../../reducers/AdminApprovals/projectListReducer';

import './ProjectList.scss';
import Loading from '../../../appComponents/Loading';
import Helpers from '../../../utils/helpers';
import FilterByInput from '../../../components/FilterByInput';
class ProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q: '',
      query: {},
      filters: [{
        label: 'Search',
        type: 'text',
        query: 'q',
        placeholder: 'Search for Name',
      }, {
        label: 'Status',
        type: 'list',
        query: 'status',
        list: [{label: 'Pending', value: 'pending'}, {label: 'Published', value: 'published'}, {label: 'Final Approval', value: 'final_approval'}, {label: 'Archived', value: 'unpublished'}],
      }, {
        label: 'Nonprofit',
        type: 'service',
        query: 'nonprofit',
        service: 'nonprofits',
        placeholder: 'Type nonprofit name',
      }, {
        label: 'Created after',
        type: 'date',
        query: 'created_at',
        op: '>'
      }, {
        label: 'Created before',
        type: 'date',
        query: 'created_at',
        op: '<'
      }, {
        label: 'Updated after',
        type: 'date',
        query: 'updated_at',
        op: '>'
      }, {
        label: 'Updated before',
        type: 'date',
        query: 'updated_at',
        op: '<'
      }]
    };
  }

  componentDidMount() {
    const { Projects } = this.props;
    const { q } = this.state;
    Projects.getProjects({ q });
  }

  componentWillUnmount() {
    const { Projects } = this.props;
    Projects.reset();
  }

  handelQ = (e) => {
    e.preventDefault();
    this.setState({ q: e.target.value });
  }

  handelSearch = (e) => {
    e.preventDefault();
    const { q } = this.state;
    const { Projects } = this.props;
    if (q.length >= 3) {
      Projects.reset();
      Projects.getProjects({ q });
    } else if (q.length === 0) {
      Projects.reset();
      Projects.getProjects({ q: '' });
    }
  }

  clearSearch = () => {
    this.setState({q: ''});
    const { Projects } = this.props;
    Projects.reset();
    Projects.getProjects({ q: '' });
  }

  loadMore = () => {
    const { Projects } = this.props;
    const { q } = this.state;
    Projects.getProjects({ q });
  }

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshProjectList();
    });
  }

  refreshProjectList = () => {
    const { Projects } = this.props;
    const { query } = this.state;
    Projects.reset();
    Projects.getProjects(query);
  }

  ProjectListHTML = () => {
    const { projects } = this.props;
    if (!projects.get_status.project || projects.get_status.project === 'pending' && projects.projects.length === 0) {
      return (<Loading />);
    }
    if (projects.projects.length === 0) {
      return(
        <i className="align-center">No items available</i>
      );
    }
    return projects.projects.map(item => (
      <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col" key={item.slug}>
        <ProjectTile value={item} link={"/approvals/project-onboarding/"} />
      </div>
    ));
  }

  render() {
    const { projects } = this.props;
    const { q, filters } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Pending Project List</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} handelSearch={this.handelSearch} handelQ={this.handelQ} q={this.state.q} forApproval={true} clearSearch={this.clearSearch}/>
                <div className="row ngo-list-wrapper">
                  {this.ProjectListHTML()}
                </div>
                <div className="row">
                  {(projects.load_status.project) ? (
                    <div className="col-md-12">
                      <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                    </div>
                  ) : null}
                  {(projects.get_status.project === 'pending' && projects.projects.length !== 0) ? (
                    <div className="col-md-12">
                      <Loading />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.projectsList,
});

const mapActionToProps = dispatch => ({
  Projects: bindActionCreators(ProjectListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(ProjectList);
