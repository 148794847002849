import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';

export const GET_PROFILE_NONPROFIT_ITEM = 'GET_PROFILE_NONPROFIT_ITEM';
export const GET_PROFILE_NONPROFIT_ITEM_SUCCESS = 'GET_PROFILE_NONPROFIT_ITEM_SUCCESS';
export const GET_PROFILE_NONPROFIT_ITEM_FAIL = 'GET_PROFILE_NONPROFIT_ITEM_FAIL';

export const SET_PROFILE_NONPROFIT_ITEM = 'SET_PROFILE_NONPROFIT_ITEM';
export const SET_PROFILE_NONPROFIT_ITEM_SUCCESS = 'SET_PROFILE_NONPROFIT_ITEM_SUCCESS';
export const SET_PROFILE_NONPROFIT_ITEM_FAIL = 'SET_PROFILE_NONPROFIT_ITEM_FAIL';

export const SET_NONPROFIT_TNC = 'SET_NONPROFIT_TNC';
export const SET_NONPROFIT_TNC_SUCCESS = 'SET_NONPROFIT_TNC_SUCCESS';
export const SET_NONPROFIT_TNC_FAIL = 'SET_NONPROFIT_TNC_FAIL';

export const APPROVAL_RESET = 'APPROVAL_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  nonprofit: {},
  get_status: {},
  set_status: {},
  errors: {},
};

export const nonprofitsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PROFILE_NONPROFIT_ITEM:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };
    case GET_PROFILE_NONPROFIT_ITEM_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
      };
    case GET_PROFILE_NONPROFIT_ITEM_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
      };
    case SET_PROFILE_NONPROFIT_ITEM:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case SET_PROFILE_NONPROFIT_ITEM_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        set_status: {
          ...state.set_status,
          nonprofit: 'success',
        },
      };
    case SET_PROFILE_NONPROFIT_ITEM_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors,
        },
      };
    case SET_NONPROFIT_TNC:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };
    case SET_NONPROFIT_TNC_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        set_status: {
          ...state.set_status,
          nonprofit: 'success',
        },
      };
    case SET_NONPROFIT_TNC_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors,
        },
      };
    case APPROVAL_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getNonprofit(request) {
  return (dispatch) => {
    dispatch({
      type: GET_PROFILE_NONPROFIT_ITEM,
    });
    return NonProfitService.get(request)
      .then((response) => {
        dispatch({
          type: GET_PROFILE_NONPROFIT_ITEM_SUCCESS,
          nonprofit: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_PROFILE_NONPROFIT_ITEM_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

// Added tnc specific reducers
export function updateNonProfitTnc(data) {
  return (dispatch) => {
    dispatch({
      type: SET_NONPROFIT_TNC,
    });
    return NonProfitService.set({ data: {}, method: 'put', path: [data] })
      .then((response) => {
        dispatch({
          type: SET_NONPROFIT_TNC_SUCCESS,
          nonprofit: response,
        });        
        return response;
      })
      .catch((err) => {
        dispatch({
          type: SET_NONPROFIT_TNC_FAIL,
          errors: {},
        });
        dispatch(NotificationAction.addNotification(err.message));
        throw err;
      })
  }
}

export function setNonprofit(data) {
  return (dispatch) => {
    dispatch({
      type: SET_PROFILE_NONPROFIT_ITEM,
    });
    return NonProfitService.set({ data, method: 'put', path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_PROFILE_NONPROFIT_ITEM_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_PROFILE_NONPROFIT_ITEM_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));
          throw error.value.errors;
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_PROFILE_NONPROFIT_ITEM_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
          throw error.value.error;
        } else {
          dispatch({
            type: SET_PROFILE_NONPROFIT_ITEM_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: APPROVAL_RESET,
    });
  };
}
