import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OverlayTrigger, Form, Popover, ListGroup, Button, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { formatDistanceToNow, format, parseISO } from "date-fns";

import Icon from "@mdi/react";
import { mdiFilter } from "@mdi/js";

import Loading from "../../../appComponents/Loading";
import FilterByInput from "../../../components/FilterByInput";
import * as adminCampaignListAction from "../../../reducers/Admin/adminCampaignListReducer";

import Helpers from "../../../utils/helpers";

import "./AdminCampaignsList.scss";
import { unset } from "lodash";

class AdminCampaignsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDetails: "",
      query: {},
      filters: [
        {
          label: "Search",
          type: [
            {
              type: "default",
              label: "Title & Fundraiser Name(Team name)",
              query: "q",
            },
            {
              type: "email",
              label: "Fundraiser Email",
              query: "creator.email",
            },
            {
              type: "phone",
              label: "Fundraiser Phone",
              query: "creator.phone",
            },
          ],
          placeholder: "Search for Title, Fundraiser(Name, email, phone)",
        },
        {
          label: "Main Fundaisers Only",
          type: "single_selector",
          query: "parent_data",
          selectorValue: false,
        },
        {
          label: "Supporting Fundaisers Only",
          type: "single_selector",
          query: "parent_data._id",
          selectorValue: "",
        },
        {
          label: "Nonprofit",
          type: "service",
          query: "nonprofit._id",
          service: "nonprofits",
          placeholder: "Type nonprofit name",
        },
        {
          label: "Project",
          type: "service",
          query: "project._id",
          service: "projects",
          placeholder: "Type project name",
        },
        {
          label: "Created after",
          type: "date",
          query: "created_at",
          op: ">",
        },
        {
          label: "Created before",
          type: "date",
          query: "created_at",
          op: "<",
        },
      ],
      exportFilters: [],
      reportLinks: [],
      currentTab: 'export',
      loader : false
    };
  }



  componentDidMount() {
    const { campaigns, Campaigns } = this.props;
    if (!campaigns.get_status.campaign) {
      Campaigns.getCampaigns();
    }
  }

  componentWillUnmount() {
    const { Campaigns } = this.props;
    Campaigns.reset();
  }

  refreshCampaignList = () => {
    const { Campaigns } = this.props;
    const { query } = this.state;
    Campaigns.reset();
    Campaigns.getCampaigns(query);
  };

  reSyncDonation = (order_id) => {
    const { Donation } = this.props;
    Donation.getReSyncDonation(order_id);
  };

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshCampaignList();
    });
  };

  fitersForExport = (filter) => {
    const { exportFilters } = this.state;
    if (!exportFilters.includes(filter))
      this.setState({ exportFilters: [...exportFilters, filter] });
    else
      this.setState({
        exportFilters: exportFilters.filter((exportFilters) => exportFilters !== filter),
      });
  };

  addToFilter = (type, value) => {
    const { filters } = this.state;
    const newOption = { ...filters.find((i) => i.label === type) };
    newOption.data = [{...value}];
    const newOptions = filters.map((item) => (item.label === type ? newOption : item));
    this.setState(
      { filters: newOptions, query: Helpers.queryBuilderForFilterInput(newOptions) },
      () => {
        this.refreshCampaignList();
      }
    );
  };

  generateReportLink = (e) => {
    e.preventDefault();
    const { Campaigns } = this.props;
    const { query, exportFilters } = this.state;
    let exportQuery = JSON.parse(JSON.stringify(query));

    // convert created_at to after or before
    if(exportQuery['created_at>']) {
      exportQuery.after = query['created_at>'];
      delete(exportQuery['created_at>']);
    }
    if(exportQuery['created_at<']) {
      exportQuery.before = exportQuery['created_at<'];
      delete(exportQuery['created_at<']);
    }

    Campaigns.generateReportLink({ ...exportQuery }, exportFilters)?.then((data) => {
      Campaigns.getReportLinks().then((data) => {
        this.setState({ reportLinks: data,  currentTab : 'downloads', loader: true });
        setTimeout(() => {
          this.setState({
            loader : false
          })
        }, 2000);
      });
    });
  };

  getReportLinks = (e, isExport) => {
    e.preventDefault();
    const { Campaigns } = this.props;
    this.setState({
      loader : true
    })
    if(isExport) {
      this.setState({
        currentTab : isExport
      })
    }
    Campaigns.getReportLinks().then((data) => {
      setTimeout(() => {
        this.setState({
          loader : false
        })
      }, 2000);
      this.setState({ reportLinks: data});
    });
  };

  addToFilterHTML = (type, value) => (
    <span className="filter-wrapper">
      <button
        type="button"
        className="btn btn-rounded btn-inverse-outline-primary btn-filters"
        onClick={(e) => {
          e.preventDefault();
          this.addToFilter(type, value);
        }}
      >
        <Icon path={mdiFilter} size={0.5} color="#2196f3" />
        <span>Apply as filter</span>
      </button>
    </span>
  );

  loadMore = () => {
    const { Campaigns } = this.props;
    const { query } = this.state;
    Campaigns.getCampaigns(query);
  };

  showDetailsClick = (id) => {
    const { viewDetails } = this.state;
    if (id === viewDetails) {
      this.setState({ viewDetails: "" });
    } else {
      this.setState({ viewDetails: id });
    }
  };

  onTabChange = (e, tab) => {
    this.setState({
      currentTab : tab
    })
    if(tab === 'downloads') {
      this.getReportLinks(e, 'downloads')
    }
  }

  downloadDailyStats = (id) => {
    this.props.Campaigns.downloadDailyStats(id).then((data) => {
      if (data) {
        const fileName = `GiveFundraisers-daily-stats-${format(new Date(), "HH:mm:ss")}`;
        const json = Object.entries(data).map((d) => ({
          "Created date": format(new Date(d[0]), "do MMM yyyy"),
          INR: d[1].INR && d[1].INR.amount ? d[1].INR.amount : "",
          USD: d[1].USD && d[1].USD.amount ? d[1].USD.amount : "",
          "USD In INR": d[1].USD && d[1].USD.amount_in_inr ? d[1].USD.amount_in_inr : "",
          "Total Amount in INR": d[1].total_amount_in_inr,
        }));
        Helpers.convertJSONtoCSV(json, fileName);
      }
    });
  };

  campaignListItemHTML = () => {
    const { campaigns } = this.props;
    const { viewDetails } = this.state;
    if (
      !campaigns.get_status.campaign ||
      (campaigns.get_status.campaign === "pending" && campaigns.campaigns.length === 0)
    ) {
      return <Loading />;
    }
    if (campaigns.campaigns.length === 0) {
      return <i className="align-center">No items available</i>;
    }
    return campaigns.campaigns.map((item) => (
      <li className="table-list-item" key={item.slug}>
        <div className="row">
          <div className="col-md-12 table-col">
            <div className="row">
              <div className="col-md-3 table-col dl-mob">
                <div className="feed-element">
                  <span className="pull-left">
                    <img alt="image" className="img-circle" src={item.creator.profile_pic} />
                  </span>
                  <div className="media-body">
                    <strong>{item.creator.name}</strong>
                    &nbsp;created -&nbsp;
                    <strong>
                      <a className="" target="_blank" href={item.permalink}>
                        {item.title}
                      </a>
                    </strong>
                    <br />
                    {item.parent_data && (
                      <>
                        Main Fundraiser -&nbsp;
                        <strong>
                          <a className="" target="_blank" href={item.parent_data.permalink}>
                            {item.parent_data.title}
                          </a>
                        </strong>
                        <br />
                      </>
                    )}
                    <small className="text-muted">{formatDistanceToNow(new Date(item.created_at))}</small>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 table-col dl-mob">
                <div className="row">
                  {item.nonprofit ? (
                    <div className="col-12">
                      <strong>(N) </strong>{" "}
                      <a target="_blank" href={item.nonprofit.permalink}>
                        {item.nonprofit.name}
                      </a>
                      {this.addToFilterHTML("Nonprofit", {
                        title: `Nonprofit: ${item.nonprofit.name}`,
                        data: { "nonprofit._id": item.nonprofit._id },
                      })}
                    </div>
                  ) : null}
                  {item.project ? (
                    <div className="col-12">
                      <strong>(P) </strong>{" "}
                      <a target="_blank" href={item.project.permalink}>
                        {item.project.name}
                      </a>
                      {this.addToFilterHTML("Project", {
                        title: `Project: ${item.project.name}`,
                        data: { "project._id": item.project._id },
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-1 table-col dl-mob">
                <strong className="show-mobile">Days Left</strong>
                <span>&nbsp;{item.days_remaining}</span>
              </div>
              <div className="col-md-1 table-col dl-mob">
                <strong className="show-mobile">Target Amount</strong>
                <span>&nbsp;₹{item.target_amount?.toLocaleString("en-in")}</span>
              </div>
              <div className="col-md-1 table-col dl-mob">
                <strong className="show-mobile">Raised Amount</strong>
                <span>&nbsp;₹{item.statistic.raised_amount?.toLocaleString("en-in")}</span>
              </div>
              <div className="col-md-2 table-col dl-mob-right">
                {item.tags.length > 0
                  ? item.tags.map((tag) => (
                      <div className="badge badge-pill ellipsis badge-primary" key={tag}>
                        {tag}
                      </div>
                    ))
                  : null}
              </div>
              <div className="col-md-1 col-12 table-col dl-mob-right">
                {viewDetails === item._id ? (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showDetailsClick(item._id);
                    }}
                  >
                    Hide Details
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      this.showDetailsClick(item._id);
                    }}
                  >
                    View Details
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-outline-secondary dwl-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    this.downloadDailyStats(item._id);
                  }}
                >
                  Download daily stats
                </button>
              </div>
            </div>
          </div>
          {viewDetails === item._id ? (
            <div className="col-md-12 disbursement-view-details">
              <div className="ibox float-e-margins">
                <div className="ibox-content ibox-heading">
                  <div className="row">
                    <div className="col-md-12">{/* <h6 className="left">Other details</h6> */}</div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3 bold">Email:</div>
                        <div className="col-md-9 dl-mob">{item.creator?.email}</div>
                        <div className="col-md-3 bold">Is Team:</div>
                        <div className="col-md-9 dl-mob">{item.is_team ? "YES" : "NO"}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3 bold">Phone:</div>
                        <div className="col-md-9">{item.creator?.phone}</div>
                        {item.is_team ? (
                          <>
                            <div className="col-md-3 bold">Team name :</div>
                            <div className="col-md-9">{item.team_name}</div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </li>
    ));
  };


  popover = () => {
    const { campaigns } = this.props;
    const { reportLinks } = this.state;
    const fieldNames = {
      basic:
        "(Starting Date, End Date, Fundraiser Type, Fundraiser Title, Fundraiser Link, Fundraiser ID, Supporters, Amount raised, Target, Owner Name, Owner Email, Owner Phone)",
      project:
        "(Project title, Project link, Project ID, Primary Cause, Secondary Cause, Project Location)",
      nonprofit: "(NGO ID, NGO Name, NGO PAN, 4 limb, FCRA, NGO Address)",
      finance:
        "(INR confirmed, USD confirmed, GBP confirmed, INR settled, USD settled, GBP settled)",
      additional_fields: "(Tags)",
    };

    return (
      <Popover id="popover-basic-1">
        <Popover.Content>
            <Row>
                <Col md={12} xs={12} className="export-download">
                <ul className="nav nav-tabs tab-basic" role="tablist">
                    <li className="nav-item">
                    <a className={`${`nav-link ${this.state.currentTab === 'export' ? `active` : ''} show`}`}  id="export-tab" data-toggle="tab" href="#export" onClick={(e) => this.onTabChange(e, 'export')} >Export</a>
                    </li>
                    <li className="nav-item">
                    <a className={`${`nav-link ${this.state.currentTab === 'downloads' ? `active` : ''} show`}`}  id="download-tab" data-toggle="tab" href="#download" onClick={(e) => this.onTabChange(e, 'downloads')} >Downloads</a>
                    </li>
                </ul>
                <div className="tab-content tab-content-basic">
              {
                  this.state.currentTab === 'export' ?
                <div className="tab-pane fade show active" >
                  <Col md={12} xs={12} className="export-filters">
                    <Popover.Title as="h3">Select Fields</Popover.Title>
                    <Form.Check className="popover-checkbox" type="checkbox"  id= "basic" label="Basic Details" disabled checked />
                    <div className="popover-checkbox-div" htmlFor="basic">{fieldNames.basic}</div>
                    <Form.Check className="popover-checkbox" id="project" type="checkbox" label="Project" onClick={() => this.fitersForExport('project')} />
                    <div className="popover-checkbox-div" htmlFor="project">{fieldNames.project}</div>
                    <Form.Check className="popover-checkbox" type="checkbox" id="nonprofit" label="Nonprofit" onClick={() => this.fitersForExport('nonprofit')} />
                    <div className="popover-checkbox-div" htmlFor="nonprofit">{fieldNames.nonprofit}</div>
                    <Form.Check className="popover-checkbox" type="checkbox" id="finance" label="Finance" onClick={() => this.fitersForExport('finance')} />
                    <div className="popover-checkbox-div" htmlFor="finance">{fieldNames.finance}</div>
                    <Form.Check className="popover-checkbox" type="checkbox" id="additional" label="Additional Fields" onClick={() => this.fitersForExport('additional_fields')} style={{ marginTop: '10px' }} />
                    <div className="popover-checkbox-div" htmlFor="additional"></div>
                    <div className="text-center">
                      <Button className="btn btn-sm" onClick={this.generateReportLink}>{campaigns.get_status.generate_report === '' ? 'Export to CSV' : campaigns.get_status.generate_report === 'pending' ? 'Generating...' : 'Export to CSV'}</Button>
                    </div>
                  </Col>
                </div> :
                <div className="tab-pane fade show active">
                  <Col md={12} xs={12}>
                    <Popover.Title as="h3">
                      <span>Queued for download</span>
                      <button type="button" className="btn btn-inverse-link" onClick={this.getReportLinks}>
                        <div className="export">
                        <div className={`${this.state.loader ? `export-loader-spin` : `export-loader`}`}></div>
                        </div>
                      </button>
                    </Popover.Title>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Requested</th>
                          <th>Tags</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(!reportLinks || reportLinks.length === 0) &&
                          <i className="align-center">No items available</i>}
                        {reportLinks && reportLinks.map((link, index) => {
                          let filterAppl = link.queryParameter.filter;
                          return (
                            <tr key={index}>
                              <td style={{ maxWidth:"100%",whiteSpace:"nowrap"}}>
                                <div className="download-tag-wrapper">
                                  <span className="download-tag">{format(new Date(link.created_at), "MMM do, yyyy HH:mm aa")}</span>
                                </div>
                              </td>
                              <td >
                                <div className="download-tag-wrapper">
                                  {['a'].map(item => (
                                   <>
                                   {
                                     filterAppl &&
                                     <>
                                     { filterAppl.createdBefore ?  <span className="download-tag">Created before: {format(new Date(filterAppl.createdBefore), "MMM do, yyyy")}</span> : ''}
                                     { filterAppl.createdAfter ?  <span className="download-tag">Created after: {format(new Date(filterAppl.createdAfter), "MMM do, yyyy")}</span> : ''}
                                     { filterAppl.projects.length ? <span className="download-tag">Project: {filterAppl.projects}</span> : null}
                                     { filterAppl.nonprofits.length ? <span className="download-tag">Nonprofit: {filterAppl.nonprofits}</span> : null}
                                     </>
                                   }
                                   </>
                                  ))}
                                </div>
                              </td>
                              <td><a className={`btn btn-xs ${link.status === 'completed' ? 'btn-success' :'disabled btn-secondary'}`} href={link.dataLink} download={`GiveFundraisers-live-${format(new Date(), 'HH:mm:ss')}`}>{link.status === 'completed' ? 'Download' : link.status}</a></td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    {/* <ListGroup className="text-center">
                      {(!reportLinks || reportLinks.length === 0) &&
                        <i className="align-center">No items available</i>}
                      {reportLinks && reportLinks.map((link, index) => {
                        return (
                          <ListGroup.Item key={index}></ListGroup.Item>
                        )
                      })}
                    </ListGroup> */}
                  </Col>
                </div> }
              </div>
              </Col>
            </Row>
            <Row>
          </Row>
        </Popover.Content>
      </Popover> )}

  render() {
    const { campaigns } = this.props;
    const { filters } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Fundraisers</h3>
                <FilterByInput value={filters} onChange={this.filterOnChange} />
                <div className="row justify-content-end">
                  <div className="col-md-12 col-12 text-right">
                    <OverlayTrigger
                      trigger="click"
                      placement={isMobile ? "bottom-end" : "left-start"}
                      overlay={this.popover()}
                      rootClose
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={(e) => this.getReportLinks(e, 'export')}
                      >
                        Proceed to Export
                      </button>
                    </OverlayTrigger>
                    <div>
                      <i>
                        <small className="text-muted">CSV file contains max of 10000 entries</small>
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card-col col-xl-12 col-12">
                <div className="ibox-content">
                  <div className="table-list live-dis-table">
                    <ul>
                      <li className="table-head">
                        <div className="row">
                          <div className="col-md-12 table-col">
                            <div className="row">
                              <div className="col-md-3 table-col dl-mob">Fundraiser Name</div>
                              <div className="col-md-3 table-col dl-mob">
                                Nonprofit(N) / Project(P)
                              </div>
                              <div className="col-md-1 table-col dl-mob">Days Left</div>
                              <div className="col-md-1 table-col dl-mob">Target Amount</div>
                              <div className="col-md-1 table-col dl-mob">Raised Amount</div>
                              <div className="col-md-2 table-col dl-mob">Tags</div>
                              <div className="col-md-1 table-col dl-mob">Details</div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {this.campaignListItemHTML()}
                    </ul>
                    <div className="row">
                      {campaigns.load_status.campaign ? (
                        <div className="col-md-12 text-center">
                          <button
                            type="button"
                            className="btn btn-fw align-center"
                            onClick={(e) => {
                              e.preventDefault();
                              this.loadMore();
                            }}
                          >
                            Load more
                          </button>
                        </div>
                      ) : null}
                      {campaigns.get_status.campaign === "pending" &&
                      campaigns.campaigns.length !== 0 ? (
                        <div className="col-md-12">
                          <Loading />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.adminCampaignList,
});

const mapActionToProps = (dispatch) => ({
  Campaigns: bindActionCreators(adminCampaignListAction, dispatch),
});

export default connect(mapStateToProps, mapActionToProps)(AdminCampaignsList);
