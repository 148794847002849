import React from 'react';
import classnames from 'classnames';

import './Nav.css';

import UserDropdown from '../../../appComponents/UserDropdown';

function Nav(props) {
  const { form } = props;
  return (
    <div>
      <nav className="navbar horizontal-layout col-lg-12 col-12 p-0">
        <div className="container d-flex flex-row nav-top mr-b-20">
          <div className="text-center navbar-brand-wrapper d-flex align-items-top">
            <a className="navbar-brand brand-logo" href="/">
                <img  src={'https://cfstatic.give.do/logo.png'} className={'header_logo'} alt="" />
            </a>
            <a className="navbar-brand brand-logo-mini" href="/">
                <img  src={'https://cfstatic.give.do/logo.png'} className={'header_logo'} alt="" />
            </a>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center">
            <UserDropdown />
          </div>
        </div>
      </nav>
      {/* {(form) ? (
            <div className="ob-step-container">
              <div className="ob-step-wrapper">
                <div className="ob-steps">
                  <div className="step-circle-outer">
                    <div className={classnames('step-circle', { active: (form === 'profile') })}>1</div>
                    <div className="sb-right"></div>
                  </div>
                  <div className="step-circle-outer">
                    <div className="sb-left"></div>
                    <div className={classnames('step-circle', { active: (form === 'nonprofit') })}>2</div>
                    <div className="sb-right"></div>
                  </div>
                  <div className="step-circle-outer">
                    <div className="sb-left"></div>
                    <div className={classnames('step-circle', { active: (form === 'project') })}>3</div>
                  </div>
                </div>
                <div className="ob-step-title">
                  <span>
                    <a onClick={(e) => { e.preventDefault(); }} href="#profile">
                      <span className="menu-title">Profile</span>
                    </a>
                  </span>
                  <span>
                    <a onClick={(e) => { e.preventDefault(); }} href="#nonprofit">
                      <span className="menu-title">Non-Profits</span>
                    </a>
                  </span>
                  <span>
                    <a onClick={(e) => { e.preventDefault(); }} href="#project">
                      <span className="menu-title">Projects</span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
        ) : null} */}
    </div>
  );
}

export default Nav;
