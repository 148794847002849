import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import classnames from 'classnames';

import './LandingPage.scss';

import FundraisersLogo from '../../../resources/images/fundraisers-logo.svg';
import CharitableGiving from '../../../resources/images/facebook-charitable-giving.png';
import VerifiedCharity from '../../../resources/images/verified-charity.png';
import ZeroCost from '../../../resources/images/zero-cost.png';
import GetOnboarded from '../../../resources/images/get-onboarded.png';
import OnboardingProcess from '../../../resources/images/onboarding-process.png';
import FbIcon from '../../../resources/images/fb-icon.png';
import ScreenDemo from '../../../resources/images/screen-demo.png';

class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeFAQ: null,
			activeHowCollectDonation: 0
		}
	}

	FAQs = [{
		question: 'What are the benefits available to a charity on Facebook?',
		answer: 'A verified charity on Facebook will be able to raise funds through different features on Facebook such as user-initiated birthday fundraisers, donate button enabled livestreaming and posts. The verified charities will themselves be able to do the same as above through their official verified page.',
	}, {
		question: 'How can a charity become verified on Facebook?',
		answer: 'A charity has to be registered with Give.do and finish the compliance procedure with Give.do in order to become a verified charity on Facebook.',
	}, {
		question: 'What are the charges associated?',
		answer: 'Registration and onboarding for a charity will happen at zero cost. Moreover, all donations will be collected at zero cost from the donors (e.g. no payment gateway charges) and all donations will be transferred to charities at zero cost (e.g. no retention).',
	}, {
		question: 'What is the eligibility criteria to become a verified charity?',
		answer: 'A charity has to hold a valid 80G, 12A and FCRA certificate in order to be eligible to start the compliance procedure for onboarding on Facebook. Once the charity declares that they possess these documents and agrees to the donor data availability clause, they will have to register with Give.do and finish the compliance procedure before becoming a verified charity.',
	}, {
		question: 'Who will issue the tax exemption certificates to the donors?',
		answer: 'The charity has to issue tax exemption certificates to the donors. A declaration will be taken from the donors which will contain their email ID, postal address and mention whether they are Indian or foreign nationals. The charity can make use of such reports and contact the donors as they wish.',
	}, {
		question: 'When will the donations reach the charity?',
		answer: 'All donations collected in a day will be transferred to the charity’s account within 2-3 working days.',
	}, {
		question: 'What kind of donor data will be available to the charity?',
		answer: 'All donations collected will be bifurcated into foreign or Indian funds based on declarations from the donors. Two types of reports thereafter will be made available (foreign/Indian funds) containing name of the donor, email ID of the donions, or, amount donated, date of donation, postal address. No other information of the donor will be collected.',
	}];

	HowCollectDonation = [{
		title: 'Birthday Fundraisers',
		description: 'Facebook users get a nudge before their birthday prompting them to start a fundraiser for a charity of their choice. The users can choose from nonprofits on Facebook and invite their friends to support their cause in honor of their birthday. Users can create a birthday fundraiser in the 30 days leading up their birthday by visiting facebook.com/fundraisers.',
		image: ScreenDemo
	}, {
		title: 'Charity Fundraisers',
		description: 'Any user can start a fundraiser for a charity (verified by Give.do and Facebook) of their choice from the “Create” button on their profile page where they can view the list of charities. This option is open to users who have an FB profile/charity/business/public figure FB page',
		image: ScreenDemo
	}, {
		title: 'Donate Button enabled Posts',
		description: 'To make it easier to collect donations during a Live broadcast, people can add a donate button directly to their Live broadcasts to raise money for a nonprofit. Viewers can donate as they watch, or give once the broadcast has ended and is posted.',
		image: ScreenDemo
	}, {
		title: 'Donate Button enabled Facebook Live',
		description: 'To make it easier to collect donations during a Live broadcast, people can add a donate button directly to their Live broadcasts to raise money for a nonprofit. Viewers can donate as they watch, or give once the broadcast has ended and is posted.',
		image: ScreenDemo
	}, {
		title: 'Charity Streaming',
		description: 'Live Streamers can seamlessly raise money for a charity from their followers without leaving Facebook. If they choose, viewers of streamers can leave a donation and a message for the streamer to encourage them during their stream.',
		image: ScreenDemo
	}];

	toggleFAQs = (i) => {
		const { activeFAQ } = this.state;
		if (i === activeFAQ) {
			this.setState({ activeFAQ: null });
		} else {
			this.setState({ activeFAQ: i });
		}
	}

	toggleHowCollectDonation = (i) => {
		const { activeHowCollectDonation } = this.state;
		this.setState({ activeHowCollectDonation: i });
	}

	render() {
		const { activeFAQ, activeHowCollectDonation } = this.state;
		return (
			<div className="fb-landing-wrapper">
				<div className="fbl-header">
					<div className="logo">
						<img src={FundraisersLogo} alt="" />
					</div>
					{/* <div className="fbl-nav">
						<ul>
							<li><a href="">About Us</a></li>
							<li><a href="">Help</a></li>
							<li><a href="">Login</a></li>
						</ul>
					</div> */}
				</div>
				<div className="fbl-banner">
					<div className="banner-content-wrapper">
						<div className="banner-content">
							<div className="banner-title">
								Raise money and awareness for your cause on Facebook
							</div>
							<div className="banner-subtitle">
								Facebook Charitable Giving in India launched in March 2020 with a goal of enabling users across India to fundraise for charities and causes they care for. Eligible charities can get themselves on-boarded for this product and raise funds at zero costs after completing the compliance procedure with Give.do.
							</div>
							<Link to="/facebook-onboarding"><button className="banner-cta">Sign Up for Charitable Giving on Facebook</button></Link>
						</div>
					</div>
				</div>
				<div className="fbl-info">
					<div className="fbl-info-title">How can you collect donations on FB?</div>
					<div className="fbl-info-content">
						<p>Currently, Facebook Charitable Giving enables the following ways of fundraising for a charity: </p>
					</div>
				</div>
				<div className="fbl-screens">
					{this.HowCollectDonation.map((item, i) => (
						<div className={classnames("fbl-screens-content", {active: (i === activeHowCollectDonation)})} id="birthday-fundraisers">
							<div className="screen-wrapper">
								<img src={item.image} alt="" />
							</div>
							<div className="fbl-tab-content">
								<div className="fbl-screens-title">{item.title}</div>
								<p>{item.description}</p>
							</div>
						</div>
					))}
					<div className="fbl-screens-tab">
						<ul>
							{this.HowCollectDonation.map(((item, i) => (
								<li className={classnames({ active: (i === activeHowCollectDonation) })} onClick={(e) => { e.preventDefault(); this.toggleHowCollectDonation(i); }}>{item.title}</li>
							)))}
						</ul>
					</div>
				</div>
				<div className="cta-wrapper">
					<Link to="/facebook-onboarding"><button className="gi-btn">Sign Up for Charitable Giving on Facebook</button></Link>
				</div>
				<div className="more-info-title">
					Need more information? 
				</div>
				<div className="more-info-wrapper wrapper-gray">
					<div className="more-info">
						<img src={CharitableGiving} alt="" />
						<div className="more-info-content">
							<div className="mic-title">Who can receive funds on Facebook Charitable Giving</div>
							<p>Verified charities registered in India holding a valid 80G, 12A and FCRA certificate can collect funds through this product after finishing the compliance procedure with Give.do</p>
						</div>
					</div>
				</div>
				<div className="more-info-wrapper">
					<div className="more-info">
						<div className="more-info-content">
							<div className="mic-title">How can one become a ‘Verified charity’ in order to fundraise</div>
							<p>An eligible charity has to sign up on GiveIndia’s portal and finish the compliance procedure</p>
						</div>
						<img src={VerifiedCharity} alt="" />
					</div>
				</div>
				<div className="more-info-wrapper wrapper-gray">
					<div className="more-info">
						<img src={ZeroCost} alt="" />
						<div className="more-info-content">
							<div className="mic-title">What is the cost of fundraising on Facebook</div>
							<p>The fundraising happens at zero cost. 100% of the amounts raised from the users are transferred to the charities at no deductions/retention/charges. No extra amounts are charged from the donors e.g. payment gateway charges etc.</p>
						</div>
					</div>
				</div>
				<div className="more-info-wrapper">
					<div className="more-info">
						<div className="more-info-content">
							<div className="mic-title">What are the requirements to get onboarded for Facebook Charitable Giving</div>
							<p>A charity has to be registered in India holding a valid 80G, 12A and FCRA certificate to be eligible for the onboarding process. They have to submit information and documents pertaining to the organization, audited financials and the management body as part of the onboarding compliance procedure</p>
						</div>
						<img src={GetOnboarded} alt="" />
					</div>
				</div>

				<div className="more-info-wrapper wrapper-gray onboarding-process">
					<div className="more-info">
						<div className="more-info-content">
							<div className="mic-title">Onboarding process</div>
							<p></p>
						</div>
						<img src={OnboardingProcess} alt="" />
					</div>
				</div>
				<div className="flp-process">
					<img src={FundraisersLogo} alt="" />
					<div className="process-title">Onboard on Give Fundraisers</div>
					<div className="process-content">
						Any charity who wishes to be a verified charity on Facebook in order to avail of the fundraising features is mandatorily required to be registered with Give.do first. The registration process requires creation of the charity’s profile along with a crowdfunding project page. Registration documents, bank account details and other basic details of the charity are collected and verified at this stage. Once the registration is complete and the profile is published on GiveIndia’s crowdfunding site on fundraisers.giveindia.org, the charity is redirected to the Facebook compliance portion on GiveIndia’s portal.
					</div>
				</div>
				<div className="flp-process">
					<img src={FbIcon} alt="" />
					<div className="process-title">Onboard on Facebook</div>
					<div className="process-content">
						The Facebook compliance procedure has to be completed on GiveIndia’s portal. Documents and information pertaining to the NGO’s financials, Governing body and other details are collected and verified before being shared with Facebook and Razorpay for final approval. Process flow and documents required are shared in advance with the charities interested in being onboarded on Facebook.
					</div>
				</div>

				<div className="cta-wrapper">
					<Link to="/facebook-onboarding"><button className="gi-btn">Sign Up for Charitable Giving on Facebook</button></Link>
				</div>

				<div className="faq-wrapper">
					<div className="faq-title">Frequently Asked Questions</div>
					<div class="faq-content">
						{this.FAQs.map((item, i)=>(
							<div class="faq-item">
								<div class="item-wrapper">
									<a class="count" href="#" onClick={(e) => { e.preventDefault(); this.toggleFAQs(i); }}>{i + 1}</a>
									<div class="q-wrapper">
										<div class="question">
											<a href="#" onClick={(e) => { e.preventDefault(); this.toggleFAQs(i); }}>{item.question}</a>
										</div>
										<div class={classnames('answer', { active: (activeFAQ === i) })}>
											<p>{item.answer}</p>
										</div>
									</div>
								</div>
								<div class="toggle-btn"><a class="show" href="#" onClick={(e) => { e.preventDefault(); this.toggleFAQs(i); }}>{(activeFAQ === i) ? '-' : '+'}</a></div>
							</div>
						))}
					</div>
					<div className="more-faqs">
						<a href="https://cfstatic.giveindia.org/e6aef47d-13e7-4812-a7e3-4cafe5cbc956.pdf" target="_blank">View more FAQ's</a>
					</div>
				</div>
    
				<div className="gi-footer">
					<div className="footer-bottom">
						<div className="fbottom-left">
						<p>© Give.do</p>
						<p>Donations through this platform are tax exempted under 80G & 501(c)(3)</p>
						</div>
						<div className="fbottom-right">
						<ul>
							<li><a href="https://www.giveindia.org/terms">Terms</a></li>
							<li><a href="https://www.giveindia.org/privacy">Privacy Policy</a></li>
							<li><a href="https://www.giveindia.org/sitemap.xml">Site Map</a></li>
						</ul>
						<ul>
							<li>
								<a href="https://www.facebook.com/GiveIndia/" target="_blank">
									<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path fill="#999999" d="M9.1,0.1V2H8C7.6,2,7.3,2.1,7.1,2.3C7,2.4,6.9,2.7,6.9,3v1.4H9L8.8,6.5H6.9V12H4.7V6.5H2.9V4.4h1.8V2.8 c0-0.9,0.3-1.6,0.7-2.1C6,0.2,6.6,0,7.5,0C8.2,0,8.7,0,9.1,0.1z" fill-rule="evenodd"></path></svg>
								</a>
							</li>
							<li>
								<a href="https://twitter.com/giveindia/" target="_blank">
									<svg width="20" height="20" viewBox="0 0 612 612" xmlns="http://www.w3.org/2000/svg"><path fill="#999999" d="M612,116.258c-22.525,9.981-46.694,16.75-72.088,19.772c25.929-15.527,45.777-40.155,55.184-69.411 c-24.322,14.379-51.169,24.82-79.775,30.48c-22.907-24.437-55.49-39.658-91.63-39.658c-69.334,0-125.551,56.217-125.551,125.513 c0,9.828,1.109,19.427,3.251,28.606C197.065,206.32,104.556,156.337,42.641,80.386c-10.823,18.51-16.98,40.078-16.98,63.101 c0,43.559,22.181,81.993,55.835,104.479c-20.575-0.688-39.926-6.348-56.867-15.756v1.568c0,60.806,43.291,111.554,100.693,123.104 c-10.517,2.83-21.607,4.398-33.08,4.398c-8.107,0-15.947-0.803-23.634-2.333c15.985,49.907,62.336,86.199,117.253,87.194 c-42.947,33.654-97.099,53.655-155.916,53.655c-10.134,0-20.116-0.612-29.944-1.721c55.567,35.681,121.536,56.485,192.438,56.485 c230.948,0,357.188-191.291,357.188-357.188l-0.421-16.253C573.872,163.526,595.211,141.422,612,116.258z"></path></svg>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/give_india/" target="_blank">
									<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill="#999999" d="M10,4.9c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1C15.1,7.2,12.8,4.9,10,4.9L10,4.9 z M10,13.3c-1.8,0-3.3-1.5-3.3-3.3S8.2,6.7,10,6.7s3.3,1.5,3.3,3.3C13.3,11.8,11.8,13.3,10,13.3z"></path><path fill="#999999" d="M14.1,0.1C12.3,0,7.7,0,5.9,0.1c-1.6,0.1-3,0.5-4.2,1.6C-0.2,3.6,0,6.2,0,10c0,3.9-0.2,6.4,1.7,8.3 C3.6,20.2,6.3,20,10,20c3.8,0,5.2,0,6.5-0.5c1.8-0.7,3.2-2.4,3.4-5.3c0.1-1.8,0.1-6.4,0-8.2C19.7,2.4,17.9,0.3,14.1,0.1L14.1,0.1z M17,17c-1.3,1.3-3,1.1-7,1.1c-4.2,0-5.8,0.1-7-1.2c-1.4-1.4-1.1-3.6-1.1-7c0-4.6-0.5-7.9,4.1-8.1c1.1,0,1.4,0,4,0l0,0 c4.4,0,7.9-0.5,8.1,4.1c0,1.1,0.1,1.4,0.1,4C18.2,14.1,18.2,15.8,17,17L17,17z"></path><circle class="st0" cx="15.3" cy="4.7" r="1.2" fill="#999999"></circle></svg>
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/company/giveindia/" target="_blank">
									<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill="#999999" d="M18.1,0.1H1.9c-1,0-1.8,0.8-1.8,1.8v16.2c0,1,0.8,1.8,1.8,1.8h16.2c1,0,1.8-0.8,1.8-1.8V1.9 C19.9,0.9,19.1,0.1,18.1,0.1z M6.2,17.2c0,0.3-0.2,0.5-0.5,0.5H3.5c-0.3,0-0.5-0.2-0.5-0.5V7.9c0-0.3,0.2-0.5,0.5-0.5h2.2 c0.3,0,0.5,0.2,0.5,0.5V17.2z M4.6,6.5c-1.2,0-2.1-0.9-2.1-2.1s0.9-2.1,2.1-2.1s2.1,0.9,2.1,2.1S5.8,6.5,4.6,6.5z M17.8,17.2 c0,0.3-0.2,0.5-0.5,0.5H15c-0.3,0-0.5-0.2-0.5-0.5v-4.4c0-0.7,0.2-2.9-1.7-2.9c-1.5,0-1.8,1.5-1.8,2.2v5c0,0.3-0.2,0.5-0.5,0.5H8.2 c-0.3,0-0.5-0.2-0.5-0.5V7.9c0-0.3,0.2-0.5,0.5-0.5h2.3c0.3,0,0.5,0.2,0.5,0.5v0.8c0.5-0.8,1.4-1.4,3.1-1.4c3.8,0,3.8,3.6,3.8,5.5 L17.8,17.2L17.8,17.2z"></path></svg>
								</a>
							</li>
						</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LandingPage;