import React, { Component } from 'react';

import classnames from 'classnames';

class NotificationsWidget extends Component {
  progressDurationInitial = 5000;

  constructor(props) {
    super(props);
    // this.state = { percent: 100 };
    this.progressDurationLeft = this.progressDurationInitial;
  }

  componentDidMount() {
    this.startProgress();
  }

  componentWillUnmount() {
    this.progressDurationLeft = 0;
  }

  startProgress() {
    const { Notification, id } = this.props;
    if (this.progressDurationLeft > 0) {
      this.progressDurationLeft = this.progressDurationLeft - (20);
      // this.setState({ percent: parseInt(this.progressDurationLeft / this.progressDurationInitial * 100) });
      setTimeout(() => {
        this.startProgress();
      }, 20);
    } else {
      Notification.dismissNotification(id);
    }
  }

  progressDurationLeft;

  render() {
    const { Notification, id, message, type } = this.props;
    return (
      <div className="toggle-msg" style={{ display: 'block' }} onClick={() => { this.progressDurationLeft = 0; Notification.dismissNotification(id); }}>
        <div className={classnames('notification-bar', { error: (type === 'ERROR') }, { success: (type === 'SUCCESS') }, { warning: (type === 'WARNING') })}>
          <div className="notification">
            <div className="">{message}</div>
            <button onClick={() => { this.progressDurationLeft = 0; Notification.dismissNotification(this.props.id); }} type="button"
              className="dismiss" role="button"
            > Dismiss
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationsWidget;
