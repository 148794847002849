import React, { Component } from 'react';

class ProjectOnboardingRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectLocation: '',
      prevValue: {},
      view: Object.keys(props.tabs)[0],
    };
  }

  componentDidMount() {
    const  value  = this.getStateData(['project', 'sites']);
    const sites = value.sites || [];
    if (sites.length > 0) {
      const site = sites[0];
      let projectLocation = 'city';
      if (!site.city || site.city === '') {
        projectLocation = 'region';
      }
      if (!(site.region || site.city) || (site.region === '' && site.city === '')) {
        projectLocation = 'india';
      }
      this.setState({ projectLocation });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.value !== state.prevValue) {
      setData.prevValue = props.value;
      // const sites = props.value.sites || [];
      const sites = props.tabs[state.view].sites || [];
      if (sites.length > 0) {
        const site = sites[0];
        setData.projectLocation = 'city';
        if (!site.city || site.city === '') {
          setData.projectLocation = 'region';
        }
        if (!(site.region || site.city) || (site.region === '' && site.city === '')) {
          setData.projectLocation = 'india';
        }
      }
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  getStateData = (from) => {
    const { state } = this;
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    if (state[from[0]] && from.length === 1 && from[0] !== 'project') {
      return state[from[0]];
    }
    if (value[from[1]] && from.length === 2 && from[0] === 'project') {
      return value[from[1]];
    }
    if (value.causes && from.length === 3 && from[0] === 'project' && from[1] === 'causes') {
      if (!value.causes[from[2]]) {
        return '';
      }
      return value.causes[from[2]];
    }
    if (value.donation_options && from.length === 4 && from[0] === 'project' && from[1] === 'donation_options' && (from[2] === 0 || from[2] === 1 || from[2] === 2)) {
      if (value.donation_options[from[2]]) {
        if (from[3] === 'amount' && value.donation_options[from[2]][from[3]] === 0) {
          return '';
        }
        return value.donation_options[from[2]][from[3]];
      }
    }
    if (value.project_costs && from.length === 4 && from[0] === 'project' && from[1] === 'project_costs') {
      if (value.project_costs[from[2]]) {
        if (from[3] === 'value' && value.project_costs[from[2]][from[3]] === 0) {
          return '';
        }
        return value.project_costs[from[2]][from[3]];
      }
    }
    return '';
  }

  projectLocationText = (location, type) => {
    switch (location) {
      case 'city':
        if (type === 'p') return 'Cities';
        return 'city';
      case 'region':
        if (type === 'p') return 'States';
        return 'state';
      default:
        if (type === 'p') return 'Countries';
        return 'country';
    }
    return '';
  }

  getErrorMessage = (forList) => {
    const { errors } = this.props;
    const forItem = forList.slice(1, forList.length).join('.');
    let returnValue = null;
    if (errors && errors.length > 0) {
      errors.forEach((item) => {
        if (item[forItem]) {
          returnValue = (
            <div className="error-message">
              {item[forItem]}
            </div>
          );
        }
      });
    }
    return returnValue;
  }

  render() {
    const { children } = this.props;
    const { projectLocation } = this.state;
    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3 className="mr-b-20">Project General Details</h3>
              <h6>Name of the Project</h6>
              {this.getErrorMessage(['project', 'name'])}
              <p className="text-muted">{this.getStateData(['project', 'name'])}</p>
              <h6>Issue (What is the concern? - Max 200 words)</h6>
              {this.getErrorMessage(['project', 'issue'])}
              <div className="text-muted" dangerouslySetInnerHTML={{ __html: this.getStateData(['project', 'issue']) }}>
              </div>
              <h6>Action (What are you going to do about it? - Max 200 words)</h6>
              {this.getErrorMessage(['project', 'action'])}
              <div className="text-muted" dangerouslySetInnerHTML={{ __html: this.getStateData(['project', 'action']) }}>
              </div>
              <h6>Impact (What is the impact that you wish to create? - Max 200 words)</h6>
              {this.getErrorMessage(['project', 'impact'])}
              <div className="text-muted" dangerouslySetInnerHTML={{ __html: this.getStateData(['project', 'impact']) }}>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h6>Primary Cause</h6>
                  {this.getErrorMessage(['project', 'causes'])}
                  <p className="text-muted">{this.getStateData(['project', 'causes', 0])}</p>
                </div>
                <div className="col-md-6">
                  <h6>Secondary Cause</h6>
                  {this.getErrorMessage(['project', 'causes'])}
                  <p className="text-muted">{this.getStateData(['project', 'causes', 1])}</p>
                </div>
              </div>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Project Location(s):</h3>
              {this.getErrorMessage(['project', 'sites'])}
              <div className="row">
                <div className="col-md-6">
                  <h6>Project is</h6>
                  {(this.getStateData(['project', 'sites']).length === 0) ? (<p className="text-muted">Not Available</p>) : (<p className="text-muted">Implemented in single/multiple {this.projectLocationText(projectLocation)}</p>)}
                </div>
                <div className="col-md-6">
                  <h6>Project Location(s)</h6>
                  <div>
                    {(this.getStateData(['project', 'sites']).length > 0) ? (this.getStateData(['project', 'sites']).map(item => (
                      <label className="badge badge-primary" key={item.city + item.region + 'india'}>
                        {(item.city) ? `${item.city}, ${item.region}, India` : null}
                        {(!item.city && item.region) ? `${item.region}, India` : null}
                        {(!item.city && !item.region) ? 'India' : null}
                      </label>
                    ))) : (<p className="text-muted">Not Available</p>)}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Project Images</h3>
              <h6>Cover Image</h6>
              {this.getErrorMessage(['project', 'cover_image'])}
              <p>
                <img className="width100" src={this.getStateData(['project', 'cover_image'])} alt="" />
              </p>
              <h6>Tile Image(Image used in listing-tiles)</h6>
              {this.getErrorMessage(['project', 'image'])}
              <p>
                <img src={this.getStateData(['project', 'image'])} alt="" />
              </p>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Donation Options</h3>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan="2"> Amount </th>
                    <th> Donation Description </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> INR </td>
                    <td>
                      {this.getErrorMessage(['project', 'donation_options', 0, 'amount'])}
                      {(this.getErrorMessage(['project', 'donation_options', 0, 'amount'])) ? (
                        <br />
                      ) : null}
                      {this.getStateData(['project', 'donation_options', 0, 'amount'])}
                    </td>
                    <td>
                      {this.getErrorMessage(['project', 'donation_options', 0, 'title'])}
                      {(this.getErrorMessage(['project', 'donation_options', 0, 'title'])) ? (
                        <br />
                      ) : null}
                      {this.getStateData(['project', 'donation_options', 0, 'title'])}
                    </td>
                  </tr>
                  <tr>
                    <td> INR </td>
                    <td>
                      {this.getErrorMessage(['project', 'donation_options', 1, 'amount'])}
                      {(this.getErrorMessage(['project', 'donation_options', 1, 'amount'])) ? (
                        <br />
                      ) : null}
                      {this.getStateData(['project', 'donation_options', 1, 'amount'])}
                    </td>
                    <td>
                      {this.getErrorMessage(['project', 'donation_options', 1, 'title'])}
                      {(this.getErrorMessage(['project', 'donation_options', 1, 'title'])) ? (
                        <br />
                      ) : null}
                      {this.getStateData(['project', 'donation_options', 1, 'title'])}
                    </td>
                  </tr>
                  <tr>
                    <td> INR </td>
                    <td>
                      {this.getErrorMessage(['project', 'donation_options', 2, 'amount'])}
                      {(this.getErrorMessage(['project', 'donation_options', 2, 'amount'])) ? (
                        <br />
                      ) : null}
                      {this.getStateData(['project', 'donation_options', 2, 'amount'])}
                    </td>
                    <td>
                      {this.getErrorMessage(['project', 'donation_options', 2, 'title'])}
                      {(this.getErrorMessage(['project', 'donation_options', 2, 'title'])) ? (
                        <br />
                      ) : null}
                      {this.getStateData(['project', 'donation_options', 2, 'title'])}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Cost Break-Up & Target Amount</h3>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th> Particulars </th>
                    <th colSpan="2"> Amount </th>
                  </tr>
                </thead>
                <tbody>
                  {(this.getStateData(['project', 'project_costs']) || []).map((item, index) => (
                    <tr key={item.title + index}>
                      <td>
                        {this.getErrorMessage(['project', 'project_costs', index, 'title'])}
                        {(this.getErrorMessage(['project', 'project_costs', index, 'title'])) ? (
                          <br />
                        ) : null}
                        {item.title}
                      </td>
                      <td> INR </td>
                      <td>
                        {this.getErrorMessage(['project', 'project_costs', index, 'title'])}
                        {(this.getErrorMessage(['project', 'project_costs', index, 'title'])) ? (
                          <br />
                        ) : null}
                        {item.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="card-body">
              <h6 className="text-right">Target amount</h6>
              {this.getErrorMessage(['project', 'target_amount'])}
              <p className="text-muted text-right">
                <strong>INR</strong> {this.getStateData(['project', 'target_amount'])}
                </p>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Project Tagging</h3>
              <label>The project will raise funds for COVID-19 support - <span className="text-muted">{this.getStateData(['project', 'tags']) && this.getStateData(['project', 'tags']).findIndex(i => i === 'indiafightscorona') > -1 ? 'Yes' : 'No'}</span></label>
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectOnboardingRead;
