import * as NotificationAction from './notificationReducer';
// import Helpers from '../../utils/helpers';
import NonProfitService from '../services/nonprofit-service';

export const GET_HOME_NONPROFITS = 'GET_HOME_NONPROFITS';
export const GET_HOME_NONPROFITS_SUCCESS = 'GET_HOME_NONPROFITS_SUCCESS';
export const GET_HOME_NONPROFITS_FAIL = 'GET_HOME_NONPROFITS_FAIL';
export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  nonprofits: [],
  get_status: {},
};

export const homeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_HOME_NONPROFITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case GET_HOME_NONPROFITS_SUCCESS:
      return {
        ...state,
        nonprofits: action.nonprofits,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
      };
    case GET_HOME_NONPROFITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getNonprofits() {
  return (dispatch) => {
    dispatch({
      type: GET_HOME_NONPROFITS,
    });
    return NonProfitService.get()
      .then((response) => {
        dispatch({
          type: GET_HOME_NONPROFITS_SUCCESS,
          nonprofits: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_HOME_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}
