import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';
import ProjectService from '../../services/project-service';

export const GET_SELFSERVE_ITEM_NONPROFIT = 'GET_SELFSERVE_ITEM_NONPROFIT';
export const GET_SELFSERVE_ITEM_NONPROFIT_SUCCESS = 'GET_SELFSERVE_ITEM_NONPROFIT_SUCCESS';
export const GET_SELFSERVE_ITEM_NONPROFIT_FAIL = 'GET_SELFSERVE_ITEM_NONPROFIT_FAIL';

export const SET_SELFSERVE_ITEM_NONPROFIT = 'SET_SELFSERVE_ITEM_NONPROFIT';
export const SET_SELFSERVE_ITEM_NONPROFIT_SUCCESS = 'SET_SELFSERVE_ITEM_NONPROFIT_SUCCESS';
export const SET_SELFSERVE_ITEM_NONPROFIT_FAIL = 'SET_SELFSERVE_ITEM_NONPROFIT_FAIL';

export const CHECK_SELFSERVE_NONPROFIT_COMPLIANCE = 'CHECK_SELFSERVE_NONPROFIT_COMPLIANCE';
export const CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_SUCCESS = 'CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_SUCCESS';
export const CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_FAIL = 'CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_FAIL';

export const APPROVAL_RESET = 'APPROVAL_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  nonprofit: {},
  get_status: {},
  set_status: {},
  errors: {},
};

export const nonprofitSelfServeItemReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SELFSERVE_ITEM_NONPROFIT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };
    case GET_SELFSERVE_ITEM_NONPROFIT_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
      };
    case GET_SELFSERVE_ITEM_NONPROFIT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
      };
    case SET_SELFSERVE_ITEM_NONPROFIT:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case SET_SELFSERVE_ITEM_NONPROFIT_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        set_status: {
          ...state.set_status,
          nonprofit: 'success',
        },
      };
    case SET_SELFSERVE_ITEM_NONPROFIT_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors,
        },
      };
    case CHECK_SELFSERVE_NONPROFIT_COMPLIANCE:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          compliance: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        set_status: {
          ...state.set_status,
          compliance: 'success',
        },
      };
    case CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          compliance: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors,
        },
      };
    case APPROVAL_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getNonprofit(request) {
  return (dispatch) => {
    dispatch({
      type: GET_SELFSERVE_ITEM_NONPROFIT,
    });
    return NonProfitService.get(request)
      .then((response) => {
        dispatch({
          type: GET_SELFSERVE_ITEM_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_SELFSERVE_ITEM_NONPROFIT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function setNonprofitAP(id, data) {
  return (dispatch) => {
    dispatch({
      type: SET_SELFSERVE_ITEM_NONPROFIT,
    });
    const request = {};
    request.method = 'put';
    request.path = [id, 'apply-edits'];
    request.data = data;
    return NonProfitService.set(request)
      .then((response) => {
        dispatch({
          type: SET_SELFSERVE_ITEM_NONPROFIT_SUCCESS,
          nonprofit: response.data,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response.data;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_SELFSERVE_ITEM_NONPROFIT_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));
          throw error.value.errors;
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_SELFSERVE_ITEM_NONPROFIT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
          throw error.value.error;
        } else {
          dispatch({
            type: SET_SELFSERVE_ITEM_NONPROFIT_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function checkNonprofitCompliance(id, data) {
  return (dispatch) => {
    dispatch({
      type: CHECK_SELFSERVE_NONPROFIT_COMPLIANCE,
    });
    const request = {};
    request.method = 'put';
    request.path = [id];
    request.data = data;
    return NonProfitService.set(request)
      .then((response) => {
        dispatch({
          type: CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));
          throw error.value.errors;
        } else if (error.value && error.value.error) {
          dispatch({
            type: CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
          throw error.value.error;
        } else {
          dispatch({
            type: CHECK_SELFSERVE_NONPROFIT_COMPLIANCE_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: APPROVAL_RESET,
    });
  };
}
