import React, { Component } from 'react';
import Editor from '../../components/Editor';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import * as NGOOnboardingHomeAction from '../../reducers/NGOOnboarding/homeReducer'
import * as LoginAction from '../../reducers/loginReducer';



import Icon from '@mdi/react';
import {
  mdiFloppy,
  mdiHelpCircle,
  mdiChevronRight,
  mdiChevronLeft,
  mdiLoading
} from '@mdi/js';


export class FbOnboardInitialDetails extends Component {
  constructor(props) {
		super(props);
    this.state = {  
      isloading: false   
    }
  }    

  handleSubmit = async () => {
    const { state, addError, removeError } = this.props
    const { dataInitial } = state
    let errors = false
    this.setState({...this.state, isloading: true });   

    if(!dataInitial.organizationName){
      addError('dataInitialError', 'organizationName', 'Please Enter Organisation Name')
      errors = true  
    }
    else removeError('dataInitialError', 'organizationName')

    if(dataInitial.is12A === undefined || dataInitial.is12A === ''){ 
      addError('dataInitialError', 'is12A', 'Please fill is12A availabilty') 
      errors = true
    }
    else removeError('dataInitialError', 'is12A')

    if(dataInitial.is80G === undefined || dataInitial.is80G === ''){
      addError('dataInitialError', 'is80G', 'Please fill is80G availabilty')  
      errors = true
    }
    else removeError('dataInitialError', 'is12A')

    if(dataInitial.isFCRA === undefined || dataInitial.isFCRA === ''){
      addError('dataInitialError', 'isFCRA', 'Please fill isFCRA availabilty')  
      errors = true
    }
    else removeError('dataInitialError', 'isFCRA')
    if(!errors) {
      this.props.onSubmit();
    }
    else{
      this.setState({...this.state, isloading: false });
    }
  }
  
  render() {
    const { state, setData, setEditorData, goBack } = this.props

    const { dataInitial, dataPan, error } = state
    const { organizationName, interventionGeographical } = dataInitial;
    const { nonprofit , isloading} = this.state;

    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">

            <div className="card-body">
              <h3 className="mr-b-20">Step 2 - Initial Details</h3>
              
              <div className="form-group">
                <label>Name of the Organization</label>
                <input type="text" className="form-control" placeholder="As per documents" name="organizationName" checked={state.dataInitial.organizationName === 'organizationName'} value={organizationName} onChange={(e) => setData(e, 'dataInitial')} disabled={Object.keys(state.nonprofit).length === 0 ? false : true}/>
                <span className="field-valid-error">{error.dataInitialError.findIndex(o => o.key === 'organizationName')>-1 && error.dataInitialError[error.dataInitialError.findIndex(o => o.key === 'organizationName')].value}</span>
              </div>

              <div className="form-group">
                <label>PAN Card</label>
                <input type="text" className="form-control" placeholder="PAN Card Number" value={dataPan.panCard} disabled={true}/>                
              </div>                          
              
              <div className="form-group row">
                <label className="col-md-12 col-form-label">Do you have a valid 12A?</label>
                <div className="col-sm-6">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" 
                        className="form-check-input" 
                        value={true} 
                        checked={state.dataInitial.is12A === true} 
                        name="is12A" 
                        onChange={(e) => setData(e, 'dataInitial')} 
                      /> 
                        Yes &nbsp;<i className="input-helper" style={{fontSize: '12px'}}>(Required for Facebook)</i>
                    </label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" 
                        className="form-check-input" 
                        value={false} 
                        checked={state.dataInitial.is12A === false} 
                        name="is12A" 
                        onChange={(e) => setData(e, 'dataInitial')} 
                      /> 
                        No <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
                <span className="field-valid-error-row">{error.dataInitialError.findIndex(o => o.key === 'is12A')>-1 && error.dataInitialError[error.dataInitialError.findIndex(o => o.key === 'is12A')].value}</span>
              </div>
              
              <div className="form-group row">
                <label className="col-md-12 col-form-label">Do you have a valid 80G?</label>
                <div className="col-sm-6">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" className="form-check-input" value={true} checked={state.dataInitial.is80G === true} name="is80G" onChange={(e) => setData(e, 'dataInitial')} /> Yes &nbsp;<i className="input-helper" style={{fontSize: '12px'}}>(Required for Facebook)</i>
                    </label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" className="form-check-input" value={false} checked={state.dataInitial.is80G === false} name="is80G" onChange={(e) => setData(e, 'dataInitial')} /> No <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
                <span className="field-valid-error-row">{error.dataInitialError.findIndex(o => o.key === 'is80G')>-1 && error.dataInitialError[error.dataInitialError.findIndex(o => o.key === 'is80G')].value}</span>
              </div>
              
              <div className="form-group row">
                <label className="col-md-12 col-form-label">Do you have a valid FCRA certificate?</label>
                <div className="col-sm-6">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" className="form-check-input" value={true} checked={state.dataInitial.isFCRA === true} name="isFCRA" onChange={(e) => setData(e, 'dataInitial')} /> Yes &nbsp;<i className="input-helper" style={{fontSize: '12px'}}>(Required for Facebook)</i>
                    </label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-radio">
                    <label className="form-check-label">
                      <input type="radio" className="form-check-input" value={false} checked={state.dataInitial.isFCRA === false} name="isFCRA" onChange={(e) => setData(e, 'dataInitial')} /> No <i className="input-helper"></i>
                    </label>
                  </div>
                </div>
                <span className="field-valid-error-row">{error.dataInitialError.findIndex(o => o.key === 'isFCRA')>-1 && error.dataInitialError[error.dataInitialError.findIndex(o => o.key === 'isFCRA')].value}</span>
              </div>          
            </div>

            <span className="save-click-error">{(error.dataInitialError.findIndex(o => o.key === 'organizationName')>-1 || error.dataInitialError.length>0) && 'Please correct all the errors'}</span>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                    <button type="button" className="btn btn-outline-primary mr-2" onClick={goBack}>
                      <Icon path={mdiChevronLeft} className="menu-arrow" size={0.75}/> Back
                    </button>
                    <button type="submit" className="btn btn-primary mr-2" onClick={this.handleSubmit} >
                      <Icon path={isloading ? mdiLoading: mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" {...(isloading? {spin: 'spin'}: {})} /> Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  login: state.login,	
	nonprofitData: state.ngoOnboardingHome,	
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),	
	NGOOnboardingHome: bindActionCreators(NGOOnboardingHomeAction, dispatch),  	
});

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
)(FbOnboardInitialDetails));
