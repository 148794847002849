import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';
import CampaignService from '../../services/campaign-service';
import EventService from '../../services/event-service';
import FundraiserReportService from '../../services/fundraiser-report-service';

export const GET_CROWDFUNDING_CAMPAIGNS = 'GET_CROWDFUNDING_CAMPAIGNS';
export const GET_CROWDFUNDING_CAMPAIGNS_SUCCESS = 'GET_CROWDFUNDING_CAMPAIGNS_SUCCESS';
export const GET_CROWDFUNDING_CAMPAIGNS_FAIL = 'GET_CROWDFUNDING_CAMPAIGNS_FAIL';

export const GENERATE_REPORT_LINK = 'GENERATE_REPORT_LINK';
export const GENERATE_REPORT_LINK_SUCCESS = 'GENERATE_REPORT_LINK_SUCCESS';
export const GENERATE_REPORT_LINK_FAIL = 'GENERATE_REPORT_LINK_FAIL';

export const GET_DAILY_STATS = 'GET_DAILY_STATS'
export const GET_DAILY_STATS_SUCCESS = 'GET_DAILY_STATS_SUCCESS';
export const GET_DAILY_STATS_FAIL = 'GET_DAILY_STATS_FAIL'

export const GET_REPORT_LINK = 'GET_REPORT_LINK';
export const GET_REPORT_LINK_SUCCESS = 'GET_REPORT_LINK_SUCCESS';
export const GET_REPORT_LINK_FAIL = 'GET_REPORT_LINK_FAIL';

export const CROWDFUNDING_CAMPAIGNS_RESET = 'CROWDFUNDING_CAMPAIGNS_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  campaigns: [],
  get_status: {},
  load_status: {},
  data: [],
};

export const adminCampaignListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CROWDFUNDING_CAMPAIGNS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          campaign: 'pending',
        },
      };
    case GET_CROWDFUNDING_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.campaigns,
        get_status: {
          ...state.get_status,
          campaign: 'success',
        },
        load_status: {
          ...state.load_status,
          campaign: action.load_status,
        }
      };
    case GET_CROWDFUNDING_CAMPAIGNS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          campaign: 'failed',
        },
      };

    case GET_DAILY_STATS:
      return {
        ...state,
        data: 'pending'
      }

    case GET_DAILY_STATS_SUCCESS:
      return {
        ...state,
        data: [action.data],
        get_status: {
          ...state.get_status,
          data: 'success',
        },
      }
    case GET_DAILY_STATS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          data: 'failed',
        },
      }

    case CROWDFUNDING_CAMPAIGNS_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    case GENERATE_REPORT_LINK:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'pending',
        },
      };
    case GENERATE_REPORT_LINK_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'success',
        },
      };
    case GENERATE_REPORT_LINK_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'failed',
        },
      };
    case GET_REPORT_LINK:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'pending',
        },
      };
    case GET_REPORT_LINK_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'success',
        },
      };
    case GET_REPORT_LINK_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'failed',
        },
      };
    default:
      return state;
  }
};

export function getCampaigns(query) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CROWDFUNDING_CAMPAIGNS,
    });
    const currentCampaigns = getState().adminCampaignList.campaigns;
    const params = {
      scope: 'global',
      skip: currentCampaigns.length,
      limit: 10,
      ...query,
    };
    return CampaignService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_CROWDFUNDING_CAMPAIGNS_SUCCESS,
            campaigns: currentCampaigns,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_CROWDFUNDING_CAMPAIGNS_SUCCESS,
            campaigns: [...currentCampaigns, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CROWDFUNDING_CAMPAIGNS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function downloadDailyStats(query) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_DAILY_STATS,
    });

    return EventService.get({ path: ['campaigns', query, 'donations', 'stats'], auth: true })
      .then((response) => {
        dispatch({
          type: GET_DAILY_STATS_SUCCESS,
          data: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DAILY_STATS_FAIL,
          data: error
        });
        dispatch(NotificationAction.addNotification('Stats not found'));
      });
  };
}

export function generateReportLink({ ...query }, exportFilters = []) {
  return (dispatch) => {
    dispatch({
      type: GENERATE_REPORT_LINK,
    });

    if (!query.after || !query.before) {  // Required Fields
      dispatch({
        type: GENERATE_REPORT_LINK_FAIL,
      });
      dispatch(NotificationAction.addNotification('Created after and Created before are required fields'));
      return;
    }
    
    const request = {
      data: {
        selector: exportFilters,
        filter: {
          createdAfter: query.after,
          createdBefore: query.before,
          supporting_fundraiser: query['parent_data._id'] === "" ? true  : false,
          projects: query['project._id'] || [],
          nonprofits: query['nonprofit._id'] || [],
        },
      },
      method: 'post'
    };
    return FundraiserReportService.get(request)
      .then((response) => {
        dispatch({
          type: GENERATE_REPORT_LINK_SUCCESS,
        });
        dispatch(NotificationAction.addNotification('Your request has been received. Please check the Queued Downloads for the status of your request.', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GENERATE_REPORT_LINK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getReportLinks() {
  return (dispatch) => {
    dispatch({
      type: GET_REPORT_LINK,
    });
    return FundraiserReportService.get()
      .then((response) => {
        dispatch({
          type: GET_REPORT_LINK_SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_REPORT_LINK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: CROWDFUNDING_CAMPAIGNS_RESET,
    });
  };
}
