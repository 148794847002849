import React, { useState } from 'react'
import './ComplianceModal.css'

const ComplianceRenewalModal = (props) => {


  const [ renewalCompliances, setRenewalCompliances ] = useState([])
  const [ error, setError ] = useState(false)

  const onClose = () => {
    props.onClose()
  }

  const handleChange = (e) => {
    if(renewalCompliances.indexOf(e.target.value) == -1){
      const complianceKeys = renewalCompliances
      complianceKeys.push(e.target.value)    
      setRenewalCompliances(complianceKeys)
    }
  }
  
  const complianceName = (id) => {
    if(id === 'fbassured'){
      return 'Facebook Assured'
    }
    else if(id === 'giveassured'){
      return 'Give Assured'
    }
    else if(id === 'givetrusted'){
      return 'Give Trusted'
    }
    else{
      return id
    }
  }

  const initiateRenewals = () => {    
    if(renewalCompliances.length !== 0){
      props.onSubmit(renewalCompliances)
    }
    else{
      setError('Please select atleast one compliance.')
    }
  }

  
  return (
    <div className="modal" id="complianceModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Compliance Renewals</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => onClose()}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Select Compliance(s) that needs to be renewed
            <div>
            {props.nonprofit.approved_compliances.map(item => {
              return (
                <label className="compliance_key" key={item.id}> 
                  <input type="checkbox" required name="compianceKeys" value={item.id} onChange={e => handleChange(e)}/> {complianceName(item.id)}                  
                </label>
              )
            })}
            </div>
            {error ? (
              <div className="errorContainer compliances">{error}</div>
            ): null}
          </div>
          <div className="modal-footer">                
            <button type="button" className="btn btn-primary" onClick={e => initiateRenewals()}>Initiate Renewals</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComplianceRenewalModal