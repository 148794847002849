import React, { useState } from 'react';
import Loading from '../../../appComponents/Loading';

const Stats = (props) => {
  const { value } = props;
  const [details, setDetails] = useState('sync');
  return (
    <div className="col-12">
      <div className="row">
        <div className="card-col col-md-12 col-12" style={{paddingLeft: '5px', paddingRight: '5px'}}>
          <div className="ibox" style={{marginBottom: '10px'}}>
            <div className="ibox-title">
              <h5><i aria-hidden="true" className="fa fa-heart"></i> Pending Sync</h5>
              <div className="wrapper d-flex">
                <div className="d-flex align-items-center mr-3">
                  <a href="#" onClick={(e) => { e.preventDefault(); if (details === 'sync') { setDetails(null); } else { setDetails('sync'); } }}><p className="mb-0 ml-2 text-muted">{details === 'sync' ? 'Hide details' : 'View details'}</p></a>
                </div>
              </div>
            </div>
            <div className="ibox-content" style={{ padding: '0' }}>
            {details === 'sync' ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Entity Gateway</th>
                      <th>Amount</th>
                      <th>Count</th>
                      <th>From</th>
                      <th>To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.filter(item => item.type === 'pending_sync').map(item => (
                      <tr key={`${item.payment_entity}${item.gateway}${item.total_amount}`}>
                        <td>{item.payment_entity} {item.gateway}</td>
                        <td>{`${item.currency_code} ${item.total_amount}`}</td>
                        <td>{item.donation_count}</td>
                        <td>{item.from_date}</td>
                        <td>{item.to_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            ): null}
              {props.loading ? (<Loading />) : null}
            </div>
          </div>
        </div>
        <div className="card-col col-md-12 col-12" style={{paddingLeft: '5px', paddingRight: '5px'}}>
          <div className="ibox">
            <div className="ibox-title">
              <h5><i aria-hidden="true" className="fa fa-heart"></i> Pending Disbursement</h5>
              <div className="wrapper d-flex">
                <div className="d-flex align-items-center mr-3">
                <a href="#" onClick={(e) => { e.preventDefault(); if (details === 'disbursement') { setDetails(null); } else { setDetails('disbursement'); } }}><p className="mb-0 ml-2 text-muted">{details === 'disbursement' ? 'Hide details' : 'View details'}</p></a>
                </div>
              </div>
            </div>
            <div className="ibox-content" style={{ padding: '0' }}>
              {details === 'disbursement' ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Entity Gateway</th>
                      <th>Amount</th>
                      <th>Count</th>
                      <th>From</th>
                      <th>To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.filter(item => item.type === 'pending_disbursment').map(item => (
                      <tr key={`${item.payment_entity}${item.gateway}${item.total_amount}`}>
                        <td>{item.payment_entity} {item.gateway}</td>
                        <td>{`${item.currency_code} ${item.total_amount}`}</td>
                        <td>{item.donation_count}</td>
                        <td>{item.from_date}</td>
                        <td>{item.to_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ): null}
              {props.loading ? (<Loading />) : null}
            </div>
          </div>
        </div>
        <div className="card-col col-md-12 col-12" style={{paddingLeft: '5px', paddingRight: '5px'}}>
          <div className="ibox">
            <div className="ibox-title">
              <h5><i aria-hidden="true" className="fa fa-heart"></i> Pending Settlement</h5>
              <div className="wrapper d-flex">
                <div className="d-flex align-items-center mr-3">
                <a href="#" onClick={(e) => { e.preventDefault(); if (details === 'settlement') { setDetails(null); } else { setDetails('settlement'); } }}><p className="mb-0 ml-2 text-muted">{details === 'settlement' ? 'Hide details' : 'View details'}</p></a>
                </div>
              </div>
            </div>
            <div className="ibox-content" style={{ padding: '0' }}>
              {details === 'settlement' ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Entity Gateway</th>
                      <th>Amount</th>
                      <th>Count</th>
                      <th>From</th>
                      <th>To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.filter(item => item.type === 'pending_settlement').map(item => (
                      <tr key={`${item.payment_entity}${item.gateway}${item.total_amount}`}>
                        <td>{item.payment_entity} {item.gateway}</td>
                        <td>{`${item.currency_code} ${item.total_amount}`}</td>
                        <td>{item.donation_count}</td>
                        <td>{item.from_date}</td>
                        <td>{item.to_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
              {props.loading ? (<Loading />) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Stats;
