import React, { Component } from "react";
import Icon from "@mdi/react";
import { mdiPlayCircleOutline, mdiChevronRight, mdiPlus, mdiFloppy, mdiTrashCan } from "@mdi/js";
import Carousel, { Modal, ModalGateway } from 'react-images';
import Editor from "../Editor";
import ModalPopUp from "../ModalPopUp";
import { format } from 'date-fns';
import ImageCropUpload from "../ImageCropUpload";

import './updateRead.scss'

const ImageGallery = (props) => {
  return (
    <ModalGateway>
      {props.modelOpen === 'gallery' ? (
        <Modal onClose={props.closeModal}>
          <Carousel views={props.gallery} />
        </Modal>
      ) : null}
    </ModalGateway>
  )
}

class UpdateRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addUpdates: [],
      addnewUpdates: [],
      title: "",
      description: "",
      gallery: [],
      editId: null,
      editTitle: '',
      editDescription: '',
      editGallery: [],
      updateId: [],
      imageModal: false,
      videoModal: false,
      YoutubeURL: '',
      isClicked: false,
      isEditClicked: false,
      modelOpen: null,
      currentModelImage: 0,
      isDisabled: false,
      errors: {},
      galleryImage: null,
    };
  }

  openModal = (modal, i, images) => {
    this.setState({ modelOpen: modal, currentModelImage: i, galleryImage: images });
  }

  closeModal = () => {
    this.setState({ modelOpen: null, currentModelImage: 0 });
  }

  closeImageModal = () => {
    this.setState({ imageModal: false });
  }

  openImageModal = (id) => {
    this.setState({ imageModal: true });
  }

  closeVideoModal = () => {
    this.setState({ videoModal: false });
  }

  openVideoModal = () => {
    this.setState({ videoModal: true });
  }

  YoutubeURLChange = (e) => {
    e.preventDefault();
    this.setState({ YoutubeURL: e.target.value });
  }

  static getDerivedStateFromProps(props, state) {
    let stateData = {}
    if (props.updates.data !== state.addUpdates) {
      stateData = { ...state, addUpdates: props.updates.data }
    }
    else if (props.updates.errors !== state.errors && props.updates.errors.hasOwnProperty('data')) {
      stateData = { ...state, errors: props.updates.errors.data }
    }
    else {
      stateData = state
    }
    return stateData
  }

  handelSubmit = (e) => {
    const { Updates, match, updates } = this.props;
    const { title, description, gallery, editTitle, editId, editDescription, editGallery } = this.state;
    e.preventDefault();

    if (editId) {
      Updates.editUpdate(editTitle, editDescription, editGallery, match, editId).then(() => {
        this.cancelEdit();
      }).catch((error) => {
        // Do Nothing
      })
    }
    else if (editId === null) {
      Updates.createUpdate(title, description, gallery, match).then(() => {
        this.cancelNewUpdate();
      }).catch((error) => {
        // Do Nothing
      })
    }
    else {
      return
    }
  }

  handleEditonChange = (e) => {
    if (!e?.target) {
      this.setState({
        description: e,
        editDescription: e
      });
    } else if (e?.target) {
      this.setState({
        title: e.target.value,
        editTitle: e.target.value
      });
    }
  };

  youtubeValidation = (uri) => {
    const regExp = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = uri.match(regExp);
    if (match && match[1].length == 11) {
      return match[1];
    } else {
      return null;
    }
  }

  addMedia = (uri, type) => {
    let finalURI = uri;
    if (type === 'gallery_video') {
      const youtubeId = this.youtubeValidation(uri)
      if (youtubeId !== null) {
        finalURI = `https://www.youtube.com/embed/${youtubeId}`;
      }
    }
    const media = {
      label: "",
      media_type: type,
      uri: finalURI,
    };

    let newGallery = this.state.gallery
    newGallery.push(media)
    this.setState({ imageModal: false, videoModal: false, YoutubeURL: '', gallery: newGallery, addnewUpdates: [{ gallery: [...newGallery] }] });
  }

  addMediaonEdit = (uri, type) => {
    let finalURI = uri;
    if (type === 'gallery_video') {
      const youtubeId = this.youtubeValidation(uri)
      if (youtubeId !== null) {
        finalURI = `https://www.youtube.com/embed/${youtubeId}`;
      }
    }
    const media = {
      label: "",
      media_type: type,
      uri: finalURI,
    };
    let updateGallery = this.state.editGallery
    updateGallery.push(media)
    this.setState({ imageModal: false, videoModal: false, YoutubeURL: '', editGallery: updateGallery });
  }

  handleAddUpdates = () => {
    const obj = { gallery: this.state.gallery };
    this.setState((prev) => {
      return {
        addnewUpdates: [...prev.addnewUpdates.concat(obj)],
        isClicked: true,
      };
    });
  };

  removeMediaonUpdate = (index, id) => {
    let update = this.state.addnewUpdates.map((item, i) => {
      if (item._id === id) {
        let DeleteValue = item.gallery.filter((itemValue, i) => i !== index)
        item.gallery[index] = DeleteValue
        return item
      }
      return item
    });

    let deleteImage = this.state.gallery.filter((item, i) => {
      return i !== index
    })
    this.setState({
      gallery: deleteImage,
      addnewUpdates: update
    })
  }

  removeMedia = (idx) => {
    let deleteImage = this.state.editGallery.filter((item, i) => {
      return i !== idx
    })
    this.setState({
      editGallery: deleteImage,
    })
  }

  onDescriptionChange = (e, ind) => {
    let update = this.state.gallery.map((item, i) => {
      if (i === ind) {
        item.label = e.target.value
        return item
      }
      return item
    });

    let updateLabel = this.state.editGallery.map((item, indexx) => {
      if (indexx === ind) {
        item.label = e.target.value
        return item
      }
      return item
    })
    this.setState({
      gallery: update,
      editGallery: updateLabel
    })
  }

  onEditClicked = (value) => {
    const selectedUpdate = this.props.updates.data.filter(update => update._id === value)[0]
    this.setState({
      editId: selectedUpdate._id,
      editTitle: selectedUpdate.title,
      editDescription: selectedUpdate.description,
      editGallery: selectedUpdate.gallery,
      isEditClicked: true,
    })
  }

  onRemoveUpdateClicked = (deleteId) => {
    const { Updates, match, updates } = this.props;
    if (window && window.confirm('Are you sure?')) {
      if (deleteId) {
        const { Updates, match, updates } = this.props;
        Updates.deleteUpdate(match, deleteId).then(() => {
          // Do Nothing
        }).catch((error) => {
          // Do Nothing
        })
      }
    }
  }

  cancelEdit = () => {
    this.setState({
      editId: null,
      editTitle: '',
      editDescription: '',
      editGallery: [],
      isEditClicked: false
    })
  }

  cancelNewUpdate = () => {
    this.setState({
      addnewUpdates: [],
      isClicked: false,
      title: '',
      description: '',
      gallery: [],
    })
  };


  // Function to display errors, takes key as parameters and returns the error, if any
  displayErrors = (key) => {
    const { errors } = this.state
    if (errors.length !== undefined && errors.length !== 0) {
      let thisError = errors.filter(item => item.hasOwnProperty(key))[0];
      if (thisError !== undefined) {
        return (
          <div className="error-message col-sm-12">
            {thisError[key]}
          </div>
        )
      }
    }
  }

  render() {
    const { addUpdates, imageModal, addnewUpdates, isClicked, isEditClicked, modelOpen, currentModelImage, galleryImage } = this.state;
    let indexValue = 0;
    return (
      <div className="row justify-content-md-center">
        <ImageGallery modelOpen={modelOpen} gallery={galleryImage} closeModal={this.closeModal} currentModelImage={currentModelImage} />
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">Updates</h3>
              </div>
            </div>
            {!isClicked && (addUpdates === this.na || addUpdates.length === 0) ? (
              <div className="mr-t-20 mr-b-20">
                <h5 className="txt-center"><i>Nothing here, Please click on Add Update to add updates</i></h5>
              </div>
            ) : null}
            {addUpdates && addUpdates.map((item, index) => {
              return item._id !== this.state.editId ? (
                <div className="card-body" key={item._id}>
                  <div className="update-wrapper">
                    <div className="mr-t-20 mr-b-20">
                      <div className="update-panel">
                        <p className="update-date" >{format(new Date(item?.created_at), 'do MMM yyyy')}</p>
                        <h3 className="update-title">{item.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: item.description }} />
                        <div className="gl-list">
                          <div>
                            <div className="gl-list">
                              {(item.gallery && item.gallery.length > 0 ? item.gallery.map((d, i) => {
                                if (d.media_type === 'gallery_image') {
                                  const temp_val = indexValue;
                                  indexValue++;
                                  return (
                                    <div className="gl-item" key={i}>
                                      <div className="img-wrapper" onClick={(e) => { e.preventDefault(); this.openModal("gallery", temp_val, item.gallery.filter(i => i.media_type === 'gallery_image').map(i => ({ src: i.uri }))); }}>
                                        <img src={d.uri} alt="" />
                                      </div>
                                      <p>{d.label}</p>
                                    </div>
                                  );
                                }
                              }) : null)}
                            </div>
                            <div className="gl-list">
                              {(item.gallery && item.gallery.length > 0 ? item.gallery.map((d, i) => (
                                (d.media_type === 'gallery_video') ? (
                                  <div className="gl-item" key={d.uri}>
                                    <div className="img-wrapper">
                                      <iframe width="176" height="100" src={d.uri} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                      <Icon path={mdiPlayCircleOutline} className="menu-arrow" size={1.5} color="#c4302b" />
                                    </div>
                                    <p>{d.label}</p>
                                  </div>
                                ) : null
                              )) : null)}
                            </div>
                          </div>
                          <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                            <button type="button" className="btn btn-success mr-2" disabled={isClicked} onClick={(e) => { e.preventDefault(); this.onEditClicked(item._id); }}>
                              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />&nbsp;Edit
                            </button>
                            <button type="button" className="btn btn-danger mr-2" disabled={isClicked} onClick={(e) => { e.preventDefault(); this.onRemoveUpdateClicked(item._id); }}>
                              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />&nbsp;Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
                :
                (
                  <div className="card-body" key={item._id}>
                    <div className="update-wrapper">
                      <div className="mr-t-20 mr-b-20">
                        <div className="update-panel">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <label>Title</label>
                                <input type="text" className="form-control" defaultValue={this.state.editTitle} onChange={(e) => { this.handleEditonChange(e) }} />
                                {this.displayErrors('title')}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <label>Description</label>
                                <Editor value={this.state.editDescription} className="editDescription" onChange={(e) => { this.handleEditonChange(e) }} />
                                {this.displayErrors('description')}
                              </div>
                            </div>
                          </div>
                          <div className="gl-list">
                            {this.state.editGallery
                              ? this.state.editGallery.map((d, i) =>
                                d.media_type === "gallery_image" ? (
                                  <div className="gl-item" key={d.uri}>
                                    <div className="img-wrapper">
                                      <img src={d.uri} alt={d.label} />
                                      <div className="img-delete" onClick={() => this.removeMedia(i)}>
                                        <Icon path={mdiTrashCan} size={0.8} color="#ffffff" />
                                      </div>
                                    </div>
                                    {this.displayErrors(`gallery.${i}.uri`)}
                                    <div className="form-group">
                                      <input type="text" className="form-control" placeholder="Description" defaultValue={d.label} onChange={e => this.onDescriptionChange(e, i)} />
                                      {this.displayErrors(`gallery.${i}.label`)}
                                    </div>
                                  </div>
                                ) : null)
                              : null}
                            <div className="gl-item">
                              <div className="img-wrapper">
                                <button type="button" onClick={(e) => { e.preventDefault(); this.openImageModal() }}>
                                  <Icon path={mdiPlus} className="menu-arrow" size={0.6} />{" "}
                                  <span>Add Image</span>
                                </button>
                              </div>
                              {imageModal ? (
                                <ModalPopUp onClose={this.closeImageModal} title="Please select Gallery image" size="lg">
                                  <ImageCropUpload label="Gallery" height={900} width={1600} value="" onChange={(e) => { this.addMediaonEdit(e, "gallery_image"); }} />
                                </ModalPopUp>
                              ) : null}
                            </div>
                          </div>
                          <div className="gl-list">
                            {this.state.editGallery ? this.state.editGallery.map((d, indexVal) => (
                              (d.media_type === 'gallery_video') ? (
                                <div className="gl-item" key={indexVal}>
                                  <div className="img-wrapper">
                                    <img src={`https://i1.ytimg.com/vi/${this.youtubeValidation(d.uri)}/mqdefault.jpg`} alt="" />
                                    <Icon path={mdiPlayCircleOutline} className="menu-arrow play-icon" size={1.5} color="#c4302b" />
                                    <div className="img-delete" onClick={() => this.removeMedia(indexVal)}>
                                      <Icon path={mdiTrashCan} size={.8} color="#ffffff" />
                                    </div>
                                  </div>
                                  {this.displayErrors(`gallery.${indexVal}.uri`)}
                                  <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Description" defaultValue={d.label} onChange={(e) => { e.preventDefault(); this.onDescriptionChange(e, indexVal) }} />
                                    {this.displayErrors(`gallery.${indexVal}.label`)}
                                  </div>
                                </div>
                              ) : null
                            )) : null}
                            <div className="gl-item">
                              <div className="img-wrapper">
                                <button type="button" onClick={(e) => { e.preventDefault(); this.openVideoModal() }}><Icon path={mdiPlus} className="menu-arrow" size={0.60} /> <span>Add Video</span></button>
                              </div>
                              {this.state.videoModal ? (
                                <ModalPopUp onClose={this.closeVideoModal} title="Please add Gallery video as youtube URL">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group zoom-wrapper">
                                        <label>URL</label>
                                        <input className="form-control" type="text" value={this.state.YoutubeURL} onChange={this.YoutubeURLChange} />
                                      </div>
                                    </div>
                                    <div className="col-md-12 uimg-wrapper">
                                      <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.addMediaonEdit(this.state.YoutubeURL, 'gallery_video') }}>
                                        <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                                        &nbsp;Save
                                      </button>
                                    </div>
                                  </div>
                                </ModalPopUp>
                              ) : null}
                            </div>
                          </div>
                          {isEditClicked ?
                            <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                              <button type="submit" className="btn btn-primary mr-2" onClick={this.handelSubmit}> &nbsp;Save</button>
                              <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.cancelEdit(); }}>
                                <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />&nbsp;Cancel
                              </button>
                            </div> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )
            })
            }
            {isClicked ? addnewUpdates &&
              addnewUpdates.map((item, index) => {
                return (
                  <div className="card-body" key={index}>
                    <div className="update-wrapper">
                      <div className="mr-t-20 mr-b-20">
                        <div className="update-panel">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <label>Title</label>
                                <input type="text" className="form-control" defaultValue={item.title} onChange={(e) => this.handleEditonChange(e)} />
                                {this.displayErrors('title')}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <label>Description</label>
                                <Editor value={""} className="editDescription" onChange={(e) => this.handleEditonChange(e)} />
                                {this.displayErrors('description')}
                              </div>
                            </div>
                          </div>
                          <div className="gl-list">
                            {item.gallery
                              ? item.gallery.map((d, i) =>
                                d.media_type === "gallery_image" ? (
                                  <div className="gl-item" key={i}>
                                    <div className="img-wrapper">
                                      <img src={d.uri} alt={d.label} />
                                      <div className="img-delete" onClick={() => this.removeMediaonUpdate(i, item._id)}>
                                        <Icon path={mdiTrashCan} size={0.8} color="#ffffff" />
                                      </div>
                                    </div>
                                    {this.displayErrors(`gallery.${i}.uri`)}
                                    <div className="form-group">
                                      <input type="text" className="form-control" placeholder="Description" defaultValue={d.label} onChange={e => this.onDescriptionChange(e, i)} />
                                      {this.displayErrors(`gallery.${i}.label`)}
                                    </div>
                                  </div>
                                ) : null
                              )
                              : null}
                            <div className="gl-item">
                              <div className="img-wrapper">
                                <button type="button" onClick={(e) => { e.preventDefault(); this.openImageModal() }}>
                                  <Icon path={mdiPlus} className="menu-arrow" size={0.6} />{" "}
                                  <span>Add Image</span>
                                </button>
                              </div>
                              {imageModal ? (
                                <ModalPopUp onClose={this.closeImageModal} title="Please select Gallery image" size="lg" >
                                  <ImageCropUpload label="Gallery" height={900} width={1600} value="" onChange={(e) => { this.addMedia(e, "gallery_image"); }} />
                                </ModalPopUp>
                              ) : null}
                            </div>
                          </div>
                          <div className="gl-list">
                            {item.gallery ? item.gallery.map((d, idx) => (
                              (d.media_type === 'gallery_video') ? (
                                <div className="gl-item" key={idx}>
                                  <div className="img-wrapper">
                                    <img src={`https://i1.ytimg.com/vi/${this.youtubeValidation(d.uri)}/mqdefault.jpg`} alt="" />
                                    <Icon path={mdiPlayCircleOutline} className="menu-arrow play-icon" size={1.5} color="#c4302b" />
                                    <div className="img-delete" onClick={() => this.removeMediaonUpdate(idx, item._id)}>
                                      <Icon path={mdiTrashCan} size={.8} color="#ffffff" />
                                    </div>
                                  </div>
                                  {this.displayErrors(`gallery.${idx}.uri`)}
                                  <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Description" defaultValue={d.label} onChange={e => this.onDescriptionChange(e, idx)} />
                                    {this.displayErrors(`gallery.${idx}.label`)}
                                  </div>
                                </div>
                              ) : null
                            )) : null}
                            <div className="gl-item">
                              <div className="img-wrapper">
                                <button type="button" onClick={(e) => { e.preventDefault(); this.openVideoModal() }}><Icon path={mdiPlus} className="menu-arrow" size={0.60} /> <span>Add Video</span></button>
                              </div>
                              {this.state.videoModal ? (
                                <ModalPopUp onClose={this.closeVideoModal} title="Please add Gallery video as youtube URL">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group zoom-wrapper">
                                        <label>URL</label>
                                        <input className="form-control" type="text" value={this.state.YoutubeURL} onChange={this.YoutubeURLChange} />
                                      </div>
                                    </div>
                                    <div className="col-md-12 uimg-wrapper">
                                      <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.addMedia(this.state.YoutubeURL, 'gallery_video') }}>
                                        <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                                        &nbsp;Save
                                      </button>
                                    </div>
                                  </div>
                                </ModalPopUp>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                            <button type="submit" className="btn btn-primary mr-2" onClick={this.handelSubmit}>&nbsp;Save</button>
                            <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.cancelNewUpdate(); }}>
                              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />&nbsp;Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }) :
              <div className="card-body">
                <div className="update-wrapper">
                  <div className="mr-t-20 mr-b-20">
                    <div className="gl-list">
                      <div className="gl-item">
                        <button className="btn btn-info" type="button" disabled={isEditClicked} onClick={() => { this.handleAddUpdates(); }}>
                          <Icon path={mdiPlus} className="menu-arrow" size={0.75} color="#ffffff" />{" "}
                          <span>Add Update</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
        </div>
      </div>
    );
  }
}
export default UpdateRead;