/**
 * Service function to make API call
 */

import _Error from '../utils/_error';
import API from '../utils/api';
import { endpoints } from '../config';


export default class ReportService {
  static requestAPI(endpoint, request = {}) {
    return API.request(endpoint, request);
  }
  static get(request) {
    return this.requestAPI(endpoints.reports, request);
  }
  static get10BdBe(request) {
    const url = request && request.length ? `${endpoints.reports10bdBe.url}?nonprofit_id=${request}` : endpoints.reports10bdBe.url
    const reports10bdBe = {
      url,
      auth: true,
    }
    return this.requestAPI(reports10bdBe, request);
  }
}
