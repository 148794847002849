import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ProjectsActions from '../../../reducers/AdminApprovals/projectSelfServeItemReducer';
import * as NotificationAction from '../../../reducers/notificationReducer';

import { Redirect } from 'react-router-dom'
import Icon from '@mdi/react';
import { mdiChevronRight, mdiLoading, mdiFloppy, mdiConsoleNetwork } from '@mdi/js';

import Helpers from '../../../utils/helpers';

import Loading from '../../../appComponents/Loading';

import ProjectGeneralRead from '../../../components/ProjectGeneralRead'
import CampaignRead from '../../../components/CampaignRead'
import GalleryRead from '../../../components/GalleryRead';
import FaqRead from '../../../components/FaqRead'
class SelfserveProjectsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      prevProject: {},
      approvals: [],
      rejections: [],
    };
  }

  componentDidMount() {
    const { projects, Projects, match } = this.props;
    const { id } = match.params;
    if (!projects.get_status.project) {
      Projects.getProject(id);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.projects.project !== state.prevProject) {
      setData.prevProject = props.projects.project;
      setData.project = Helpers.createNonprofitWithDiff(props.projects.project);
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  componentWillUnmount() {
    const { Projects } = this.props;
    Projects.reset();
  }

  onProjectSave = () => {
    const { Projects, Notification } = this.props;
    const { project, approvals, rejections } = this.state;
    Projects.setProjectAP(project._id, { approve: approvals, reject: rejections }).then(() => {
      this.setState({ approvals: [], rejections: [] });
    }).catch((e) => {
      console.log("Errors are", "==>>", JSON.stringify(e));
    });
  }

  checkProjectCompliance = () => {
    const { Projects, Notification } = this.props;
    const { project } = this.state;
    const approved_compliances = project.required_compliances.map(i => ({ id: i }));
    Projects.checkProjectCompliance(project._id, { approved_compliances }).then(() => {
      console.log('Done');
    }).catch((e) => {
      console.log("Errors are", "==>>", JSON.stringify(e));
    });
  }

  approveAll = () => {
    const { project } = this.state;
    const { edits } = project;
    const approvals = edits.map((k, i) => i);
    this.setState({ approvals, rejections: [] }, () => { console.log('Approvals=>', this.state.approvals) });
  }
  rejectAll = () => {
    const { project } = this.state;
    const { edits } = project;
    const rejections = edits.map((k, i) => i);
    this.setState({ rejections, approvals: [] }, () => { console.log('Rejections=>', this.state.rejections) });
  }

  buttonListHTML = () => {
    const { projects } = this.props;
    const { project } = this.state;
    const { edits } = project;
    return (
      <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper" key="buttons">
        {/* <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.approveAll(); }} disabled={edits ? edits.length < 1 : true}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          &nbsp;Mark all as Approved
        </button>
        <button type="button" className="btn btn-danger mr-2" onClick={(e) => { e.preventDefault(); this.rejectAll(); }} disabled={edits ? edits.length < 1 : true}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          &nbsp;Mark all as Rejected
        </button> */}
        <button type="button" className="btn btn-primary mr-2" onClick={(e) => { e.preventDefault(); this.onProjectSave(); }} disabled={(this.state.approvals.length + this.state.rejections.length) < 1}>
          {(projects.set_status.project === 'pending') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
            <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
          )}
          &nbsp;Submit changes
        </button>
        {/* <button type="button" className="btn btn-primary mr-2" onClick={(e) => { e.preventDefault(); this.checkProjectCompliance(); }}>
          {(projects.set_status.compliance === 'pending') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
              <Icon path={mdiConsoleNetwork} className="menu-arrow" size={0.75} color="#ffffff" />
            )}
          &nbsp;Check compliance
        </button> */}
      </div>
    );
  }

  approvalRejectionChange = (value, to, type) => {
    const { approvals, rejections } = this.state;
    if (type === 'add') {
      if (to === 'approvals') {
        this.setState({ approvals: [...approvals, ...value] }, () => { console.log('Approvals=>', this.state.approvals) });
      } else if (to === 'rejections') {
        this.setState({ rejections: [...rejections, ...value] }, () => { console.log('Rejections=>', this.state.rejections) });
      }
    } else if (type === 'remove') {
      if (to === 'approvals') {
        this.setState({ approvals: approvals.filter(i => !value.includes(i)) }, () => { console.log('Approvals=>', this.state.approvals) });
      } else if (to === 'rejections') {
        this.setState({ rejections: rejections.filter(i => !value.includes(i)) }, () => { console.log('Rejections=>', this.state.rejections) });
      }
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { project } = this.state;
    const compliance = project['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  errorHTML = () => {
    const { projects } = this.props;
    const { project: errors } = projects.errors;
    if (errors && errors.length > 0) {
      return (
        <div>
          {errors.map(i => (
            Object.values(i).map(j => (
              <div>
                {j}
              </div>
            ))
          ))}
        </div>
      );
    }
    return null;
  }

  whichForm = () => {
    const { project, prevProject, approvals, rejections } = this.state;
    const { compliance } = project;
    let returnDOM = [];
    returnDOM.push(
      <ProjectGeneralRead key="general" tabs={{ Approvals: project, Live: prevProject }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} >
        {/* {this.buttonListHTML('general')} */}
      </ProjectGeneralRead>
    );
    returnDOM.push(
      <CampaignRead key="campaign" tabs={{ Approvals: project, Live: prevProject }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} >
        {/* {this.buttonListHTML('campaign')} */}
      </CampaignRead>
    );
    returnDOM.push(
      <GalleryRead key="gallery" tabs={{ Approvals: project, Live: prevProject }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} >
        {/* {this.buttonListHTML('gallery')} */}
      </GalleryRead>
    );
    returnDOM.push(
      <FaqRead key="faq" tabs={{ Approvals: project, Live: prevProject }} approvals={approvals} rejections={rejections} approvalRejectionChange={this.approvalRejectionChange} >
        {/* {this.buttonListHTML('faq')} */}
      </FaqRead>
    );
    returnDOM.push(this.errorHTML());
    returnDOM.push(this.buttonListHTML());
    return (
      <div className="content-wrapper">
        {returnDOM}
      </div>
    );
  }

  render() {
    const { projects } = this.props;
    if (!projects.get_status.project || projects.get_status.project === 'pending') {
      return (<Loading />);
    }
    if (!projects.get_status.project || projects.get_status.project === 'failed') {
      return (<Redirect to="/404" />);
    }
    return this.whichForm();
  }
}

const mapStateToProps = state => ({
  projects: state.projectSelfServeItem,
});

const mapActionToProps = dispatch => ({
  Projects: bindActionCreators(ProjectsActions, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(SelfserveProjectsItem);
