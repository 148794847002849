/**
 * Service function to make API call
 */
import memoize from "memoize-one";

import _Error from "../utils/_error";
import API from "../utils/api";
import { endpoints } from "../config";
// import Helpers from '../utils/helpers';

export default class DonationService {
  static requestAPI(endpoint = endpoints.donations, request = {}) {
    return API.request(endpoint, request);
  }

  static cacheGet = memoize(this.requestAPI);

  static get(request) {
    return this.cacheGet(undefined, request);
  }

  static updateTagsForDonation(params, request) {
    return this.requestAPI(
      {
        url: `${endpoints.donations.url}${params.order_id}/update-tags`,
      },
      {
        method: "POST",
        data: request,
        auth: true,
      }
    );
  }

  static upload10bdbe(request) {
    const { csvFile, zipFile,nonprofitId } = request
    const payload = new FormData();
    payload.append("csvFile", csvFile);
    payload.append("zipFile", zipFile);
    return this.requestAPI(
      {
        url: `${endpoints.searchNonprofits10bdBe.url}upload/10be-certificates/${nonprofitId}`,
      },
      {
        method: "POST",
        data: payload,
        auth: true,
      }
    );
  }

  static deleteTagFromDonation(params) {
    return this.requestAPI(
      {
        url: `${endpoints.donations.url}${params.order_id}/remove/${params.tag_id}`,
      },
      {
        method: "DELETE",
        auth: true,
      }
    );
  }

  static async sendNotification(params) {
    let response = await this.requestAPI(
      {
        url: `${endpoints.donations.url}${params.orderId}/notification`,
      },
      {
        method: "POST",
        data: params,
        auth: true,
      }
    );
    return response;
  }

  static getNonprofit(id){
    return this.requestAPI(
      {
        url: `${endpoints.nonprofits.url}${id}`,
      },
      {
        method: "GET",
        auth: true,
      }
    );
  }
}
