import React, { useState } from "react";
import "./NonProfit.css";
import { Redirect } from "react-router-dom";
import Nav from "../NGOOnboarding/Nav";
import Select from 'react-select';

import { NONPROFIT_STATUSES } from '../../config'

const NonProfit = props => {

  const [ nonprofit, setNonprofit ] = useState("");
  const [ profile, setProfile ] = useState(null);
  const [ onBoarding, setOnboarding ] = useState(null);
  const { home, setParams, params, login, redirectPath } = props;
  
  const onNonProfitChange = np => {
    setNonprofit(np.value);
    const setData = {};
    const selectedNonProfit = home.nonprofits.filter(item => (item._id === np.value));
    if(selectedNonProfit.length > 0 && !login.user.is_admin){
      setData.params = { ...params, nonprofit: selectedNonProfit[0]._id, compliance: selectedNonProfit[0].required_compliances };
    }
    setParams(setData.params);
  };

  const handleClickContinue = () => {
    setProfile(`/profile/${nonprofit}/nonprofit/basic`);
    setOnboarding(`/welcome-to-onboarding`);
  };

  const is_onboarding = home.nonprofits.filter(item => item._id === nonprofit)[0];
  if (is_onboarding && onBoarding) {  
    // Instead of putting an array on the file, moved the array of statuses to config   
    if(NONPROFIT_STATUSES.indexOf(is_onboarding.status) > -1) {
      return <Redirect to={onBoarding} />;
    } else {
      return <Redirect to={profile} />;
    } 
    // TODO: Implement logic for passing redirect url, if needed   
  }

  const options = home.nonprofits.map((id, idx) => {
    return {value : id._id, label: `${id.name} - ${id.status}`}
  })

  return (
    <>
      <Nav />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel container">
          <div className="content-wrapper">
            <div className="row help-wrapper">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h3 className="mr-b-20 title">Please select a NonProfit </h3>
                    <div className="form-group">
                      <Select
                        onChange={(e) => onNonProfitChange(e)}
                        options={options}
                      />                      
                    </div>
                    <div className="grid-margin stretch-card flx-h-right np-btn-wrapper">
                      <button className="btn btn-primary" onClick={handleClickContinue}>Continue</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NonProfit;
