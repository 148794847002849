import React, { Component } from 'react';

import './ModalPopUp.css';

class ModalPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  closeModal = (e) => {
    const { onClose } = this.props;
    if (onClose && (e.target === this.parent || e.target === this.close  || e.target === this.closeSpan || e.target === this.closeButton)) {
      onClose();
    }
  }

  render() {
    const { title, children, footer, size } = this.props;
    return (
      <div>
        <style>
          {`
          .main-panel {
            overflow: hidden;
        }
        `}
        </style>
        <div className="modal fade show" role="dialog" style={{ display: "block", overflow: "auto" }} ref={(r) => { this.parent = r; }} aria-modal="true" onClick={(e) => { e.preventDefault(); this.closeModal(e) }}>
          <div className={`modal-dialog modal-${size}`} role="document">
            <div className="modal-content">
              <div className="modal-header">
                {title ? (<h5 className="modal-title">{title}</h5>) : null}
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={(r) => { this.close = r; }}>
                  <span aria-hidden="true" ref={(r) => { this.closeSpan = r; }}>×</span>
                </button>
              </div>
              <div className="modal-body">
                {children}
              </div>
              {footer ? (
                <div className="modal-footer">
                  <button type="button" className="btn btn-light" data-dismiss="modal" ref={(r) => { this.closeButton = r; }}>Ok</button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  }
}

export default ModalPopUp;
