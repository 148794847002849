import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import Icon from "@mdi/react";
import { OverlayTrigger, Popover, Row, Col, Dropdown } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import {
  mdiCheckCircleOutline,
  mdiContentSave,
  mdiDelete,
  mdiPencil,
} from "@mdi/js";

import * as Tags from "../../../reducers/Tags/tagsReducer";
import "./AdminTagsList.scss";

import Loading from "../../../appComponents/Loading";
import FilterByInput from "../../../components/FilterByInput";
import AdminTagCreateEdit from "../AdminTagCreateEdit";
import Helpers from "../../../utils/helpers";
import AdminTagImport from "./AdminTagImport";

const AdminTagsList = (props) => {
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [mode, setMode] = useState("create");
  const [allTags, setAllTags] = useState([]);
  const [filters, setFilters] = useState([
    {
      label: "Search",
      type: [
        {
          type: "default",
          label: "Title",
          query: "title",
        },
        {
          type: "default",
          label: "Team",
          query: "team",
        },
      ],
      placeholder: "Search by Tag(s) title or team.",
    },
    {
      label: "Created Before",
      type: "date",
      query: "created_at_before",
    },
    {
      label: "Created After",
      type: "date",
      query: "created_at_after",
    },
  ]);
  const [filter, setFilter] = useState({
    status: "active",
    sort: "-created_at",
    limit: 10,
    skip: 0,
  });
  const [status, setStatus] = useState("active");
  const [selectedTag, setSelectedTag] = useState(false);
  const [exportFileURL, setExportFileUrl] = useState(false);

  const { Tags, tags } = props;

  useEffect(() => {
    const query = Helpers.queryBuilderForFilterInput(filters);
    Tags.get({
      ...filter,
      ...query,
    });
  }, [filter, filters]);

  const updateFilter = (value) => {
    if (value.status) {
      setStatus(value.status);
      delete filter.expiry
      setFilter({
        ...filter,
        status: value.status,
        skip: 0,
      });
    } else {
      if (value.expiry) {
        setStatus("expired");
      }
      delete filter.status 
      setFilter({
        ...filter,
        [Object.keys(value)[0]]: Object.values(value)[0],
        skip: 0,
      });
    }
  };

  useEffect(() => {
    if (tags.get_status.tags === "success" && tags.data) {
      setAllTags([...tags.data]);
    }

    if (tags.get_status.tag === "success") {
      setIsCreateMode(false);
      setSelectedTag(false);
    }
  }, [tags]);

  const createEditTag = (data) => {
    if (!data._id) {
      Tags.create(data).then((response) => {
        if (response && response.success === true) {
          Tags.get({ ...filter });
        }
      });
    } else {
      Tags.edit(data, data._id).then((response) => {
        if (response && response.success === true) {
          Tags.get({ ...filter });
        }
      });
    }
  };

  const deleteTag = (id) => {
    Tags.remove(id).then((response) => {
      if (response.success === true) {
        Tags.get({ ...filter });
      }
    });
  };

  const unDeleteTag = (id) => {
    Tags.unDelete(id).then((response) => {
      if (response.success === true) {
        Tags.get({ ...filter });
      }
    });
  };

  const editTag = (id) => {
    setSelectedTag({
      ...allTags.filter((item) => item._id === id)[0],
    });
    setIsCreateMode(true);
    setMode("edit");
  };

  const deleteConfirmation = (item) => {
    return (
      <Popover id="popover-basic">
        <Popover.Content>
          <Row>
            <Col md={12} xs={12} className="">
              <p>
                Are you sure, you want to archive the tag <strong>{item.title}</strong>
              </p>
            </Col>
            <Col md={12} xs={12} className="export-download">
              <button
                type="button"
                className="btn btn-outline-secondary btn-block"
                onClick={(e) => (status === "active" ? deleteTag(item._id) : unDeleteTag(item._id))}
              >
                {status === "active" ? "Archive" : "Unarchive"}
              </button>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );
  };

  const getExportLink = (id, tagType) => {
    Tags.exportItems(id, tagType).then((response) => {
      if (response.success === true) {
        if (response.data && response.data.url) {
          window?.open(response.data.url, "_blank").focus();
          setExportFileUrl(response.data.url);
        }
      }
    });
  };

  const tagsHTML = () => {
    if (tags.get_status.tags === "pending" || tags.data === 0) {
      return <Loading />;
    }

    if (allTags.length === 0) {
      return <i className="align-center">No items available</i>;
    }

    return allTags.map((item) => (
      <li className="table-list-item" key={item._id}>
        <div className="row">
          <div className="col-md-12 table-col">
            <div className="row">
              <div className="col-md-2 col-12 table-col dl-mob">
                <span>{item.title}</span>
                {/* <br />
                <span className="badge badge-primary">{item?.slug}</span> */}
              </div>
              {item.meta?.description ? (
                <div className="col-md-3 col-12 table-col dl-mob user-info-livedis">
                  <small>{item.meta?.description}</small>
                </div>
              ) : (
                <div className="col-md-3 col-12 table-col dl-mob user-info-livedis">
                  No Description
                </div>
              )}

              <div className="col-md-2 col-12 table-col dl-mob user-info-livedis">
                {item.user ? (
                  <>
                    <img alt="image" className="img-circle width-30" src={item.user.profile_pic} />
                    <div className="name-email">
                      <span>{item.user.name}</span>
                      <small>
                        {item.user.email}
                        {item.user.is_email_verified ? (
                          <Icon path={mdiCheckCircleOutline} size={0.75} color="#777777" />
                        ) : null}
                      </small>
                      <small style={{ display: "block" }}>
                        {format(new Date(item?.created_at), "do MMM yyyy")}
                      </small>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="col-md-1 col-12 table-col dl-mob">
                <div>{item.meta?.team}</div>
              </div>

              <div className="col-md-1 col-12 table-col dl-mob user-info-livedis">
                {item.expiry ? (
                  <small style={{ display: "block" }}>
                    {format(new Date(item.expiry), "do MMM yyyy")}
                  </small>
                ) : (
                  <small style={{ display: "block" }}>Infinite</small>
                )}
              </div>
              <div className="col-md-1 col-12 table-col dl-mob-right">{item.itemCount || 0}</div>
              <div className="col-md-2 col-12 table-col dl-mob-right">
                <div className="row">
                  <div className="col-12 mb-1">
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" id={`export-{item._id}`}>
                        Export Items
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {["Campaigns", "Projects", "Non Profits", "Donations"].map((type) => (
                          <Dropdown.Item
                            key={type}
                            onClick={() =>
                              getExportLink(item._id, type.toLowerCase().replace(" ", ""))
                            }
                          >
                            {type}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mb-1">
                    <AdminTagImport item={item} Tags={Tags}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <OverlayTrigger
                      trigger="click"
                      placement={isMobile ? "bottom-end" : "left-start"}
                      overlay={deleteConfirmation(item)}
                      rootClose
                    >
                      <button type="button" className="btn btn-outline-secondary">
                        <Icon
                          path={status === "active" ? mdiDelete : mdiContentSave}
                          size={0.75}
                          color="#777777"
                        />
                      </button>
                    </OverlayTrigger>
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={(e) => editTag(item._id)}
                    >
                      <Icon path={mdiPencil} size={0.75} color="#777777" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    ));
  };

  const filterOnChange = (e) => {
    setFilters(e);
  };

  return (
    <div className="content-wrapper">
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card tag-card">
            <div className="card-body">
              <h3 className="mr-b-20">All Tags</h3>
              <FilterByInput value={filters} onChange={filterOnChange} />
              <div className="row justify-content-end">
                <div className="col-md-12 col-12 text-right">
                  <button
                    type="button"
                    disabled={isCreateMode}
                    className="btn btn-primary"
                    onClick={() => {
                      setIsCreateMode(true);
                      setMode("create");
                    }}
                  >
                    + Create New
                  </button>
                </div>
              </div>
            </div>

            {exportFileURL ? (
              <div className="card-body">
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                  Your file will be downloading shortly. If it doesn't, click{" "}
                  <a className="alert-link" href={exportFileURL} target="_blank">
                    here
                  </a>{" "}
                  to donwload the file.
                  <button
                    type="button"
                    className="close"
                    onClick={(e) => setExportFileUrl(false)}
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            ) : null}

            {isCreateMode && (
              <AdminTagCreateEdit mode={mode} tag={selectedTag} onSubmit={createEditTag} />
            )}
          </div>

          <div className="row">
            <div className="card-col col-xl-12 col-12">
              <div className="ibox-content tag-segment-navigation">
                <ul className="nav">
                  <li
                    className={`nav-item ${status === "active" ? "active" : ""}`}
                    onClick={() => updateFilter({ 
                      status: "active"
                    })}
                  >
                    <a className="nav-link">Active</a>
                  </li>
                  <li
                    className={`nav-item ${status === "archived" ? "active" : ""}`}
                    onClick={() => updateFilter({ 
                      status: "archived"
                    })}
                  >
                    <a className="nav-link">Archived</a>
                  </li>
                  <li
                    className={`nav-item ${status === "expired" ? "active" : ""}`}
                    onClick={() =>
                      updateFilter({
                        expiry: { value: `${new Date().toISOString().split("T")[0]}`, op: "<" },
                      })
                    }
                  >
                    <a className="nav-link">Expired</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card-col col-xl-12 col-12">
              <div className="ibox-content">
                <div className="table-list live-dis-table">
                  <ul>
                    <li className="table-head">
                      <div className="row">
                        <div className="col-md-12 table-col">
                          <div className="row">
                            <div className="col-md-2 table-col dl-mob">Title of Tag</div>
                            <div className="col-md-3 table-col dl-mob">Description</div>
                            <div className="col-md-2 table-col dl-mob-right">Created By</div>
                            <div className="col-md-1 table-col dl-mob">Team</div>
                            <div className="col-md-1 table-col dl-mob">Expiry</div>
                            <div className="col-md-1 table-col dl-mob">#Items</div>
                            <div className="col-md-2 table-col dl-mob-right">Actions</div>
                          </div>
                        </div>
                      </div>
                    </li>
                    {allTags.length > 0 && tagsHTML()}
                    {allTags.length === 0 ? (
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <br/>
                          <small>Nothing to see here.</small>
                        </div>
                      </div>
                    ): null}
                  </ul>
                  <div className="row">
                    {tags.load_status?.tags ? (
                      <div className="col-md-12 text-center">
                        <button
                          type="button"
                          className="btn btn-fw align-center"
                          onClick={(e) => {
                            e.preventDefault();
                            setFilter({
                              ...filter,
                              skip: filter.skip + filter.limit,
                            });
                          }}
                        >
                          Load more
                        </button>
                      </div>
                    ) : null}
                    {tags.get_status.tags === "pending" && tags.tags !== 0 ? (
                      <div className="col-md-12">
                        <Loading />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tags: state.tags,
});

const mapActionToProps = (dispatch) => ({
  Tags: bindActionCreators(Tags, dispatch),
});

export default connect(mapStateToProps, mapActionToProps)(AdminTagsList);
