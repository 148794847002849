import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import DonationService from '../../services/donation-service';
import ReportService from '../../services/report-service';
import Helpers from '../../utils/helpers';

export const GET_DONATIONS_LIST = 'GET_DONATIONS_LIST';
export const GET_DONATIONS_LIST_SUCCESS = 'GET_DONATIONS_LIST_SUCCESS';
export const GET_DONATIONS_LIST_FAIL = 'GET_DONATIONS_LIST_FAIL';

export const GET_NONPROFIT = 'GET_NONPROFIT';
export const GET_NONPROFIT_SUCCESS = 'GET_NONPROFIT_SUCCESS';
export const GET_NONPROFIT_FAIL = 'GET_NONPROFIT_FAIL';

export const GET_DONATIONS_LIST_10BDBD = 'GET_DONATIONS_LIST_10BDBD';
export const GET_DONATIONS_LIST_SUCCESS_10BDBD = 'GET_DONATIONS_LIST_SUCCESS_10BDBD';
export const GET_DONATIONS_LIST_FAIL_10BDBD = 'GET_DONATIONS_LIST_FAIL_10BDBD';

export const GET_DONATIONS_RESYNC = 'GET_DONATIONS_RESYNC';
export const GET_DONATIONS_RESYNC_SUCCESS = 'GET_DONATIONS_RESYNC_SUCCESS';
export const GET_DONATIONS_RESYNC_FAIL = 'GET_DONATIONS_RESYNC_FAIL';

export const GET_ADMIN_DONATIONS_FOR_DOWNLOAD = 'GET_ADMIN_DONATIONS_FOR_DOWNLOAD';
export const GET_ADMIN_DONATIONS_FOR_DOWNLOAD_SUCCESS = 'GET_ADMIN_DONATIONS_FOR_DOWNLOAD_SUCCESS';
export const GET_ADMIN_DONATIONS_FOR_DOWNLOAD_FAIL = 'GET_ADMIN_DONATIONS_FOR_DOWNLOAD_FAIL';

export const UPLOAD_10BDBD = 'UPLOAD_10BDBD';
export const UPLOAD_10BDBD_SUCCESS = 'UPLOAD_10BDBD_SUCCESS';
export const UPLOAD_10BDBD_FAIL = 'UPLOAD_10BDBD_FAIL';

export const GENERATE_REPORT_LINK = 'GENERATE_REPORT_LINK';
export const GENERATE_REPORT_LINK_SUCCESS = 'GENERATE_REPORT_LINK_SUCCESS';
export const GENERATE_REPORT_LINK_FAIL = 'GENERATE_REPORT_LINK_FAIL';

export const GET_REPORT_LINK = 'GET_REPORT_LINK';
export const GET_REPORT_LINK_SUCCESS = 'GET_REPORT_LINK_SUCCESS';
export const GET_REPORT_LINK_FAIL = 'GET_REPORT_LINK_FAIL';

export const DONATION_LIST_RESET = 'DONATION_LIST_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  donations: [],
  get_status: {},
  load_status: {},
  donation10BdBe: [],
  upload10bdbe:{},
  nonProfitDetails:{},
  isFetching:false,
  totalCount:0
};

export const adminDonationListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_NONPROFIT:
      return {
        ...state,
       isFetching:true
      };
      case GET_DONATIONS_LIST:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation: 'pending',
        },
      };
    case GET_DONATIONS_LIST_SUCCESS:
      return {
        ...state,
        donations: action.donations,
        get_status: {
          ...state.get_status,
          donation: 'success',
        },
        load_status: {
          ...state.load_status,
          donation: action.load_status,
        }
      };
      case GET_NONPROFIT_SUCCESS:
        return {
          ...state,
          nonProfitDetails:action.nonProfitDetails,
          isFetching:false
        };
        case GET_NONPROFIT_FAIL:
          return {
            ...state,
            nonProfitDetails:[],
            isFetching:false
          };
    case GET_DONATIONS_LIST_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation: 'failed',
        },
      };
    case GET_DONATIONS_LIST_10BDBD:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation10BdBe: 'pending',
        },
      };
    case GET_DONATIONS_LIST_SUCCESS_10BDBD:
      return {
        ...state,
        donation10BdBe: action.donations,
        get_status: {
          ...state.get_status,
          donation10BdBe: 'success',
          donation: false
        },
        load_status: {
          ...state.load_status,
          donation10BdBe: action.load_status,
        },
         totalCount:action.totalCount
      };
    case GET_DONATIONS_LIST_FAIL_10BDBD:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation10BdBe: 'failed',
          donation: false
        },
      };
    case GET_DONATIONS_RESYNC:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['resync_' + action.id]: 'pending',
        },
      };
    case GET_DONATIONS_RESYNC_SUCCESS:
      return {
        ...state,
        donations: action.donations,
        get_status: {
          ...state.get_status,
          ['resync_' + action.id]: 'success',
        },
      };
    case GET_DONATIONS_RESYNC_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['resync_' + action.id]: 'failed',
        },
      };
    case DONATION_LIST_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    case GET_ADMIN_DONATIONS_FOR_DOWNLOAD:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'pending',
        },
      };
    case GET_ADMIN_DONATIONS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'success',
        },
      };
    case GET_ADMIN_DONATIONS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'failed',
        },
      };
      case UPLOAD_10BDBD:
        return {
          ...state,
         upload10bdbe:{
          status:'pending'
         }
        };
      case UPLOAD_10BDBD_SUCCESS:
        return {
          ...state,
          upload10bdbe:{
           status:'success'
          }
        };
      case UPLOAD_10BDBD_FAIL:
        return {
          ...state,
          upload10bdbe:{
           status:'failed'
          }
        };
    case GENERATE_REPORT_LINK:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'pending',
        },
      };
    case GENERATE_REPORT_LINK_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'success',
        },
      };
    case GENERATE_REPORT_LINK_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_report: 'failed',
        },
      };
    case GET_REPORT_LINK:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'pending',
        },
      };
    case GET_REPORT_LINK_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'success',
        },
      };
    case GET_REPORT_LINK_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          get_report: 'failed',
        },
      };
    default:
      return state;
  }
};

export function getDonations(filter = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_DONATIONS_LIST,
    });
    const currentDonations = getState().adminDonationList.donations;
    const params = {
      ...filter,
      skip: currentDonations.length,
      limit: 10,
    };

    return DonationService.get({ params })
      .then((response) => {
        if (response.supporters.length === 0) {
          dispatch({
            type: GET_DONATIONS_LIST_SUCCESS,
            donations: currentDonations,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_DONATIONS_LIST_SUCCESS,
            donations: [...currentDonations, ...response.supporters],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DONATIONS_LIST_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}
export function getNonprofitDetails(id) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_NONPROFIT,
    });
    return DonationService.getNonprofit(id)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_NONPROFIT_SUCCESS,
            nonProfitDetails:response
          });
        } 
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_NONPROFIT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getDonations10bdbe(filter = {}, id) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_DONATIONS_LIST_10BDBD,
    });
    const currentDonations = getState().adminDonationList.donation10BdBe;
    const params = {
      ...filter,
      filter_by_10BD: true,
      filter_by_nonprofit: id,
      skip: currentDonations?.length,
      limit: 10,

    };

    return DonationService.get({ params })
      .then((response) => {
        if (response.supporters.length === 0) {
          dispatch({
            type: GET_DONATIONS_LIST_SUCCESS_10BDBD,
            donations: currentDonations,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_DONATIONS_LIST_SUCCESS_10BDBD,
            donations: [...currentDonations, ...response.supporters],
            totalCount:response?.total_count,
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DONATIONS_LIST_FAIL_10BDBD,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getReSyncDonation(orderId) {
  return (dispatch, getState) => {
    const currentDonations = [...getState().adminDonationList.donations];
    dispatch({
      type: GET_DONATIONS_RESYNC,
      id: orderId,
    });
    const request = {
      path: [orderId, 're-confirm'],
      method: 'post'
    };
    return DonationService.get(request)
      .then((response) => {
        const index = Helpers.objSearch(currentDonations, orderId, 'order_id');
        const donation = response.donation;
        const currentDonation = currentDonations[index];
        if (index >= 0) {
          // currency_code, amount, status
          let message = '';
          if (donation.currency_code !== currentDonation.currency_code) {
            message = message + ' Currency:' + currentDonation.currency_code + '->' + donation.currency_code;
            currentDonation.currency_code = donation.currency_code;
          }
          if (donation.amount !== currentDonation.amount) {
            message = message + ' Amount:' + currentDonation.amount + '->' + donation.amount;
            currentDonation.amount = donation.amount;
          }
          if (donation.status !== currentDonation.status) {
            message = message + ' Status:' + currentDonation.status + '->' + donation.status;
            currentDonation.status = donation.status;
          }
          dispatch({
            type: GET_DONATIONS_RESYNC_SUCCESS,
            id: orderId,
            donations: currentDonations,
          });
          dispatch(NotificationAction.addNotification(`Successfully updated donation! ${message}`, 'SUCCESS'));
        } else {
          dispatch(NotificationAction.addNotification("Successfully updated donation!, Please refresh the page", 'Error'));
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DONATIONS_RESYNC_FAIL,
          id: orderId,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getDonationsForDownload(request) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_ADMIN_DONATIONS_FOR_DOWNLOAD,
    });
    const params = {
      ...request,
      skip: 0,
      limit: 1000,
    };
    return DonationService.get({ params })
      .then((response) => {
        dispatch({
          type: GET_ADMIN_DONATIONS_FOR_DOWNLOAD_SUCCESS,
        });
        return response.supporters;
      })
      .catch((error) => {
        dispatch({
          type: GET_ADMIN_DONATIONS_FOR_DOWNLOAD_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}
export function upload10bdbe(request) {
  return (dispatch, getState) => {
    dispatch({
      type: UPLOAD_10BDBD,
    });
   return DonationService.upload10bdbe(request)
      .then((response) => {
        const {status} = response ||'';
        if(status === 200){
          dispatch({
            type: UPLOAD_10BDBD_SUCCESS,
          });
          dispatch(NotificationAction.addNotification(`Documents submitted. It has been sent for Approval`, 'SUCCESS'));
        } else if(status === 500){
          dispatch({
            type: UPLOAD_10BDBD_FAIL,
          });
          dispatch(NotificationAction.addNotification('Please upload valid files'));
        }
       return response
      })
      .catch((error) => {
        dispatch({
          type: UPLOAD_10BDBD_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
   };
}

export function generateReportLink({ ...query }, exportFilters = []) {
  return (dispatch) => {
    dispatch({
      type: GENERATE_REPORT_LINK,
    });
    if (!query.after || !query.before) {  // Required Fields
      dispatch({
        type: GENERATE_REPORT_LINK_FAIL,
      });
      dispatch(NotificationAction.addNotification('Created after and Created before are required fields'));
      return;
    }
    const request = {
      data: {
        selector: exportFilters,
        filter: {
          startDate: query.after,
          endDate: query.before,
          status: query.filter_by_status || [],
          campaigns: query.filter_by_campaign || [],
          projects: query.filter_by_project || [],
          nonprofits: query.filter_by_nonprofit || [],
          email: query.filter_by_user_email || '',
          phone: query.filter_by_user_phone || '',
          tag: query.filter_by_event || '',
          payment_entity: query.filter_by_payment_entity || [],
          gateway: query.filter_by_gateway || [],
          settlementId: query.filter_by_payout_id || [],
        },
      },
      method: 'post'
    };
    return ReportService.get(request)
      .then((response) => {
        dispatch({
          type: GENERATE_REPORT_LINK_SUCCESS,
        });
        dispatch(NotificationAction.addNotification('Your request has been received. Please check the Queued Downloads for the status of your request.', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GENERATE_REPORT_LINK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}
export function generateReportLink10BdBe({ ...query }) {
  return (dispatch) => {
    dispatch({
      type: GENERATE_REPORT_LINK,
    });
    // parked for future
    // if (!query.createdAfter || !query.createdBefore) {  // Required Fields
    //   dispatch({
    //     type: GENERATE_REPORT_LINK_FAIL,
    //   });
    //   dispatch(NotificationAction.addNotification('Created after and Created before are required fields'));
    //   return;
    // }
    const request = {
      data: {
        filter: {
          nonprofitId: query.nonprofitId,
          createdBefore: query.before,
          createdAfter: query.after
        },
      },
      method: 'post'
    };
    return ReportService.get10BdBe(request)
      .then((response) => {
        dispatch({
          type: GENERATE_REPORT_LINK_SUCCESS,
        });
        dispatch(NotificationAction.addNotification('Your request has been received. Please check the Queued Downloads for the status of your request.', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GENERATE_REPORT_LINK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getReportLinks(query) {
  const { is10BdBe, nonprofitId } = query || {}
  return (dispatch) => {
    dispatch({
      type: GET_REPORT_LINK,
    });
    return (is10BdBe ? ReportService.get10BdBe(nonprofitId) : ReportService.get())
      .then((response) => {
        dispatch({
          type: GET_REPORT_LINK_SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_REPORT_LINK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: DONATION_LIST_RESET,
    });
  };
}


