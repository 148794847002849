export const baseUrl = process.env.REACT_APP_BASE_URI_GIVE_DASHBOARD ? process.env.REACT_APP_BASE_URI_GIVE_DASHBOARD : 'https://qa2.give.do';
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID_GIVE_DASHBOARD
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID_GIVE_DASHBOARD
export const SUPPORT_EMAIL_ID = process.env.REACT_APP_SUPPORT_EMAIL_GIVE_DASHBOARD

export const apiUrl = `${baseUrl}/api`;

export const requiredComplianceOptions = [
  { label: 'Give Assured', value: 'giveassured' },
  { label: 'Give Compliant', value: 'givecompliant' }
];

export const configCategoryCodeOptions = [
  { value: "A", label: "A::Arts, Culture & Humanities" },
  { value: "B", label: "B::Education" },
  { value: "C", label: "C::Environmental Quality, Protection & Beautification" },
  { value: "D", label: "D::Animal-Related" },
  { value: "E", label: "E::Health Care" },
  { value: "F", label: "F::Mental Health & Crisis Intervention" },
  { value: "G", label: "G::Diseases, Disorders & Medical Disciplines" },
  { value: "H", label: "H::Medical Research" },
  { value: "I", label: "I::Crime & Legal-Related" },
  { value: "J", label: "J::Employment" },
  { value: "K", label: "K::Food, Agriculture & Nutrition" },
  { value: "L", label: "L::Housing & Shelter" },
  { value: "M", label: "M::Public Safety, Disaster Preparedness & Relief" },
  { value: "N", label: "N::Recreation, Sports" },
  { value: "O", label: "O::Youth Development" },
  { value: "P", label: "P::Human Services" },
  { value: "Q", label: "Q::International, Foreign Affairs & National Security" },
  { value: "R", label: "R::Civil Rights, Social Action & Advocacy" },
  { value: "S", label: "S::Community Improvement & Capacity Building" },
  { value: "T", label: "T::Philanthropy, Voluntarism & Grantmaking Foundations" },
  { value: "U", label: "U::Science & Technology" },
  { value: "V", label: "V::Social Science" },
  { value: "W", label: "W::Public & Societal Benefit" },
  { value: "X", label: "X::Religion-Related" },
  { value: "Y", label: "Y::Mutual & Membership Benefit" },
  { value: "Z", label: "Z::Unknown" }
];

export const priorityOptions = [
  { label: "P0", value: "P0" },
  { label: "P1", value: "P1" },
  { label: "P2", value: "P2" },
  { label: "P3", value: "P3" },
  { label: "P4", value: "P4" },
  { label: "P5", value: "P5" },
  { label: "P6", value: "P6" }
];

export const GOOGLE_MAPS_API = 'AIzaSyChXtwPelOGYKZ8MptGIGYvcwQZRW0rVzo';

export const NONPROFIT_STATUSES = [
  "pending",
  "document_check",
  "content_check",
  "cause_check",
  "final_approval"
]

export const complianceRenewalKeys = [
  '80g_doc',
  'governing_body.number_of_meetings',
  'governing_body.minutes_of_meeting',
  'governing_body.composition_change_as_of_date'
]


export const endpoints = {
  config: {
    url: `${apiUrl}/config/`,
    auth: false,
  },
  event: {
    url: `${apiUrl}/events/`,
    auth: false,
  },
  user: {
    url: `${apiUrl}/login/`,
    auth: true,
  },
  nonprofits: {
    url: `${apiUrl}/nonprofits/`,
    auth: true,
  },
  projects: {
    url: `${apiUrl}/projects/`,
    auth: true,
  },
  file: {
    url: `${apiUrl}/files/`,
    auth: true,
  },
  payment_entities: {
    url: `${apiUrl}/donations/payment-entities`,
    auth: false,
  },
  campaigns: {
    url: `${apiUrl}/campaigns/`,
    auth: true,
  },
  donations: {
    url: `${apiUrl}/donations/`,
    auth: true,
  },
  subscriptions: {
    url: `${apiUrl}/payments/subscriptions/`,
    auth: true,
  },
  disbursement: {
    url: `${apiUrl}/donations/disbursements/`,
    auth: true,
  },
  onboardingFormSchema: {
    url: `${apiUrl}/nonprofits/:nonprofit/forms`,
    auth: true,
  },
  update: {
    url: `${apiUrl}/updates`,
    auth: true,
  },
  renewal: {
    url: `${apiUrl}/nonprofits/:nonprofit/renewals`,
    auth: true,
  },
  reports: {
    url: `${apiUrl}/events/reports`,
    auth: true,
  },
  subscriptionsReports: {
    url: `${apiUrl}/events/reports/subscriptions`,
    auth: true,
  },
  fundraiserReports: {
    url: `${apiUrl}/events/reports/fundraisers`,
    auth: true,
  },
  tags: {
    url: `${apiUrl}/tags/`,
    auth: true,
  },
  bdBe: {
    url: `${apiUrl}/nonprofits/downloads/donation-10bd`,
    auth: true,
  },
  reports10bdBe: {
    url: `${apiUrl}/events/reports/donations-10bd`,
    auth: true,
  },
  searchNonprofits10bdBe: {
    url: `${apiUrl}/nonprofits/`,
    auth: true,
  },
  causes:{
    url: `${apiUrl}/config/causes`,
    auth: false,
  },
  demographics:{
    url: `${apiUrl}/config/demographics`,
    auth: false},
  statusNonprofits10bdBe: {
    url: `${apiUrl}/nonprofits/tax/certificates`,
    auth: true,
  },
  approve10bdbeNonprofits: {
    url: `${apiUrl}/nonprofits/tax/certificates/approve`,
    auth: true,
  },
  reject10bdbeNonprofits: {
    url: `${apiUrl}/nonprofits/tax/certificates/reject`,
    auth: true,
  },
  sendEmail10bdbeNonprofits: {
    url: `${apiUrl}/nonprofits/tax/certificates/sendmail`,
    auth: true,
  }
};
