import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';
import ProjectService from '../../services/project-service';

export const GET_NONPROFIT = 'GET_NONPROFIT';
export const GET_NONPROFIT_SUCCESS = 'GET_NONPROFIT_SUCCESS';
export const GET_NONPROFIT_FAIL = 'GET_NONPROFIT_FAIL';
export const SET_NONPROFIT = 'SET_NONPROFIT';
export const SET_NONPROFIT_SUCCESS = 'SET_NONPROFIT_SUCCESS';
export const SET_NONPROFIT_FAIL = 'SET_NONPROFIT_FAIL';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_PROJECT_SUCCESS = 'SET_PROJECT_SUCCESS';
export const SET_PROJECT_FAIL = 'SET_PROJECT_FAIL';
export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

// Constants created for JSON Schema fetch for form 
export const GET_SCHEMA = 'GET_SCHEMA'
export const GET_SCHEMA_SUCCESS = 'GET_SCHEMA_SUCCESS'
export const GET_SCHEMA_FAIL = 'GET_SCHEMA_FAIL'

const initialState = {
  nonprofit: {},
  project: {},
  get_status: {},
  set_status: {},
  errors: {},  
  formSchema: {}, // Variable to store schema
};

export const complianceOnboardingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_NONPROFIT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case GET_NONPROFIT_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
      };
    case GET_NONPROFIT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
      };
    case SET_NONPROFIT:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case SET_NONPROFIT_SUCCESS:      
      return {
        ...state,
        nonprofit: action.nonprofit,
        set_status: {
          ...state.set_status,
          nonprofit: 'success',
        },
      };
    case SET_NONPROFIT_FAIL:      
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors,
        },
      };
    case GET_PROJECT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'pending',
        },
        errors: {
          ...state.errors,
          project: {},
        },
      };

    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        get_status: {
          ...state.get_status,
          project: 'success',
        },
      };
    case GET_PROJECT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'failed',
        },
      };
    case SET_PROJECT:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'pending',
        },
        errors: {
          ...state.errors,
          project: {},
        },
      };
    case SET_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        set_status: {
          ...state.set_status,
          project: 'success',
        },
      };
    case SET_PROJECT_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'failed',
        },
        errors: {
          ...state.errors,
          project: action.errors,
        },
      };
    case GET_SCHEMA:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'pending',          
        },
        schema: {},        
      }
    case GET_SCHEMA_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'success',          
        },
        schema: action.schema,
        errors: {
          ...state.errors,
          schema: action.errors
        }
      }
    case GET_SCHEMA_FAIL:      
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'failed',
        },
        // schema: schema, //TODO: Update this to an error, should not be using files from the repo. 
        errors: {
          ...state.errors,
          schema: action.errors
        }
      }
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };    
    default:
      return state;
  }
};

export function getNonprofit() {
  return (dispatch) => {
    dispatch({
      type: GET_NONPROFIT,
    });
    return NonProfitService.get()
      .then((response) => {
        const nonprofits = response.filter((item) => {
          // TOOO: Don't do the check. Removed Status Check
          if (item.status === 'pending' || item.status === 'document_check' || item.status === 'content_check' || item.status === 'cause_check' || item.status === 'final_approval' || item.status === 'published') {
            return true;
          }
          return false;
        });        
        if (nonprofits.length === 0) {
          dispatch({
            type: GET_NONPROFIT_SUCCESS,
            nonprofit: {}, // Initiate new nonprofit
          });
        } else {
          dispatch({
            type: GET_NONPROFIT_SUCCESS,
            nonprofit: nonprofits[0],
          });
        }
        return nonprofits;
      })
      .catch((error) => {
        dispatch({
          type: GET_NONPROFIT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function setNonprofit(data) {
  return (dispatch) => {
    dispatch({
      type: SET_NONPROFIT,
    });
    return NonProfitService.set({ data, method: 'put', path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Nonprofit Details saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {        
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_NONPROFIT_FAIL,
            errors: error.value.errors,
          });          
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));          
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_NONPROFIT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));          
        } else {
          dispatch({
            type: SET_NONPROFIT_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error
      });
  };
}

export function createNonprofit(data) {
  return (dispatch) => {
    dispatch({
      type: SET_NONPROFIT,
    });
    return NonProfitService.create({ data, method: 'post' })
      .then((response) => {
        dispatch({
          type: SET_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Nonprofit Details saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: SET_NONPROFIT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getProject(nonprofit_id) {
  return (dispatch) => {
    dispatch({
      type: GET_PROJECT,
    });

    const params = {    
      nonprofit: nonprofit_id
    }
    return ProjectService.get({params} )
      .then((response) => {
        const projects = response.filter((item) => {
          if (item.status === 'pending' || item.status === 'final_approval') {
            return true;
          }
          return false;
        });
        if (projects.length === 0) {
          dispatch({
            type: GET_PROJECT_SUCCESS,
            project: {}, // Initiate new project
          });
        } else {
          dispatch({
            type: GET_PROJECT_SUCCESS,
            project: projects[0],
          });
        }
        return projects;
      })
      .catch((error) => {
        dispatch({
          type: GET_PROJECT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function setProject(data) {
  return (dispatch) => {
    dispatch({
      type: SET_PROJECT,
    });
    return ProjectService.set({ data, method: 'put', path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_PROJECT_SUCCESS,
          project: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_PROJECT_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Project form'));
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_PROJECT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
        } else {
          dispatch({
            type: SET_PROJECT_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function createProject(request) {
  const data = request;
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROJECT,
    });
    
    // if (nonprofit && nonprofit._id) {
    //   data.nonprofit = nonprofit._id;
    // } else {
    //   dispatch(NotificationAction.addNotification('Please save Non-profit before saving Project'));
    //   return Promise.reject(new _Error());
    // }

    return ProjectService.create({ data, method: 'post' })
      .then((response) => {
        dispatch({
          type: SET_PROJECT_SUCCESS,
          project: response,
        });
        dispatch(NotificationAction.addNotification('Project Details saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: SET_PROJECT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

const dummy = {
  "type": "object",
  "title": "A registration form",
  "description": "A simple form example.",
  "element": "form",
  "properties": {
    "nonprofit_basic_details": {
      "element": "step",
      "type": "object",
      "title": "Basic Details",
      "properties": {
        "generalDetails": {
          "element": "section",
          "title": "General Details",
          "type": "object",
          "properties": {
            "nameOfTheOrganization": {
              "type": "string",
              "title": "Name of the Organisation",
              "element": "input",
              "collection": "nonprofits",
              "key": "name",
              "placeholder": "As per documents",
              "validations": {
                "required": true,
                "minLength": 3,
                "compliance": [
                  "giveassured",
                  "givecompliant"
                ],
                "status": [
                  "document_check",
                  "content_check",
                  "cause_check",
                  "final_approval",
                  "published"
                ]
              }
            },
            "sameRegisteredName": {
              "element": "radio",
              "type": "boolean",
              "key": "isRegisteredName",
              "title": "The name is same as mentioned in your registration certificate?",
              "value": "yes",
              "enum": {
                "yes": "Yes",
                "no": "No"
              }
            },
            "registeredName": {
              "type": "string",
              "title": "Registered Name",
              "description": "As per the Registration Certificate/PAN Card",
              "collection": "nonprofits",
              "key": "official_name",
              "element": "input",
              "dependency": {
                "key": "isRegisteredName",
                "on": "no"
              },
              "validations": {
                "required": true,
                "minLength": 3,
                "compliance": [
                  "giveassured",
                  "givecompliant"
                ],
                "status": [
                  "document_check",
                  "content_check",
                  "cause_check",
                  "final_approval",
                  "published"
                ]
              }
            },
            "aboutTheOrganization": {
              "element": "textarea",
              "type": "string",
              "description": "Give a brief description about your organisation and work you'll do",
              "title": "About the Organization",
              "collection": "nonprofits",
              "key": "description",
              "validations": {
                "required": true,
                "minLength": 150,
                "compliance": [
                  "giveassured",
                  "givecompliant"
                ],
                "status": [
                  "document_check",
                  "content_check",
                  "cause_check",
                  "final_approval",
                  "published"
                ]
              }
            },
            "ngoTypeAuditor": {
              "type": "object",
              "properties": {
                "ngoType": {
                  "element": "select",
                  "type": "string",
                  "title": "NGO Type",
                  "enum": {
                    "Trust": "Trust",
                    "Society": "Society",
                    "Section 25": "Section 25",
                    "Section 7": "Section 7"
                  },
                  "collection": "nonprofits",
                  "key": "registration_type",
                  "validations": {
                    "required": true,
                    "compliance": [
                      "giveassured",
                      "givecompliant"
                    ],
                    "status": [
                      "document_check",
                      "content_check",
                      "cause_check",
                      "final_approval",
                      "published"
                    ]
                  }
                },
                "auditorName": {
                  "type": "string",
                  "element": "input",
                  "title": "Auditor's Name",
                  "collection": "nonprofits",
                  "key": "auditor_name"
                }
              },
              "element": "row"
            },
            "websiteYoutube": {
              "type": "object",
              "properties": {
                "website": {
                  "type": "object",
                  "key": "external_profiles",
                  "collection": "nonprofits",
                  "siblings": true,
                  "identifier": {
                    "label": "Website"
                  },
                  "properties": {
                    "website": {
                      "collection": "nonprofits",
                      "key": "uri",
                      "type": "string",
                      "element": "input",
                      "title": "Website of the Organization",
                      "validations": {
                        "format": "url"
                      }
                    },
                    "websiteLabel": {
                      "collection": "nonprofits",
                      "type": "string",
                      "element": "input",
                      "title": "label",
                      "value": "Website",
                      "key": "label",
                      "visibility": false
                    }
                  }
                },
                "youtubeVideoUrl": {
                  "collection": "nonprofits",
                  "key": "video",
                  "type": "string",
                  "element": "input",
                  "title": "YouTube Video URL",
                  "validations": {
                    "format": "url"
                  }
                }
              },
              "element": "row"
            },
            "contactInfo": {
              "type": "object",
              "element": "row",
              "properties": {
                "publicEmail": {
                  "type": "string",
                  "collection": "nonprofits",
                  "key": "email",
                  "element": "input",
                  "title": "Public Email ID",
                  "validations": {
                    "required": true,
                    "message": "Please specify a valid email!",
                    "compliance": [
                      "giveassured",
                      "givecompliant"
                    ],
                    "format": "email",
                    "status": [
                      "document_check",
                      "content_check",
                      "cause_check",
                      "final_approval",
                      "published"
                    ]
                  }
                },
                "publicContactNumber": {
                  "type": "string",
                  "collection": "nonprofits",
                  "key": "phone",
                  "element": "tel",
                  "title": "Public Contact Number",
                  "validations": {
                    "required": true,
                    "message": "Please specify a valid contact number!",
                    "compliance": [
                      "giveassured",
                      "givecompliant"
                    ],
                    "status": [
                      "document_check",
                      "content_check",
                      "cause_check",
                      "final_approval",
                      "published"
                    ]
                  }
                }
              }
            }
          }
        },
        "headOfficeAddress": {
          "type": "object",
          "title": "Head Office Address",
          "properties": {
            "address": {
              "type": "object",
              "element": "row",
              "collection": "nonprofits",
              "key": "address",
              "properties": {
                "addressPart1": {
                  "type": "string",
                  "title": "Address Part 1",
                  "collection": "nonprofits",
                  "key": "address.street",
                  "element": "input",
                  "validations": {
                    "required": true
                  }
                },
                "addressPart2": {
                  "type": "string",
                  "title": "Address Part 2",
                  "collection": "nonprofits",
                  "key": "address.locality",
                  "element": "input",
                  "validations": {
                    "required": true
                  }
                },
                "city": {
                  "type": "string",
                  "title": "City",
                  "collection": "nonprofits",
                  "key": "address.city",
                  "element": "input",
                  "validations": {
                    "required": true
                  }
                },
                "state": {
                  "type": "string",
                  "title": "State",
                  "collection": "nonprofits",
                  "key": "address.region",
                  "element": "select",
                  "enum": "regionOptions"
                },
                "country": {
                  "type": "string",
                  "title": "Country",
                  "collection": "nonprofits",
                  "key": "address.country_code",
                  "element": "input",
                  "value": "India",
                  "disabled": true,
                  "validations": {
                    "required": true
                  },
                },
                "pincode": {
                  "type": "string",
                  "title": "Pincode",
                  "collection": "nonprofits",
                  "key": "address.area_code",
                  "element": "input",
                  "validations": {
                    "required": true,
                    "format": "number"
                  }
                }
              },
              "validations": {
                "compliance": [
                  "giveassured",
                  "givecompliant"
                ],
                "status": [
                  "document_check",
                  "content_check",
                  "cause_check",
                  "final_approval",
                  "published"
                ]
              }
            }
          },
          "element": "section"
        }
      }
    },
    "registration_details": {
      "element": "step",
      "type": "object",
      "title": "Registration Details",
      "properties": {
        "deedNumber": {
          "type": "string",
          "element": "input",
          "title": "Registration/Trust Deed/MOA Number",
          "collection": "nonprofits",
          "key": "registration_number",
          "validations": {
            "required": true,
            "compliance": [
              "giveassured",
              "givecompliant"
            ],
            "status": [
              "document_check",
              "content_check",
              "cause_check",
              "final_approval",
              "published"
            ]
          }
        },
        "12ANumber": {
          "type": "string",
          "title": "12A Number",
          "collection": "nonprofits",
          "key": "12a",
          "element": "input",
          "validations": {
            "required": true,
            "compliance": [
              "giveassured",
              "givecompliant"
            ],
            "status": [
              "document_check",
              "content_check",
              "cause_check",
              "final_approval",
              "published"
            ]
          }
        },
        "12ACerti": {
          "type": "string",
          "element": "upload",
          "title": "12A Document",
          "collection": "nonprofits",
          "key": "12a_doc",
          "validations": {
            "required": true,
            "compliance": [
              "giveassured",
              "givecompliant"
            ],
            "status": [
              "document_check",
              "content_check",
              "cause_check",
              "final_approval",
              "published"
            ]
          }
        },
        "80GNumber": {
          "type": "string",
          "title": "80G Number",
          "collection": "nonprofits",
          "key": "80g",
          "element": "input",
          "validations": {
            "required": true,
            "compliance": [
              "giveassured",
              "givecompliant"
            ]
          }
        },
        "80GCerti": {
          "type": "string",
          "element": "upload",
          "title": "80G Document",
          "collection": "nonprofits",
          "key": "80g_doc",
          "validations": {
            "compliance": [
              "giveassured",
              "givecompliant"
            ],
            "renewals": [
              "giveassured",
              "givecompliant"
            ]
          }
        },
        "panCardNumber": {
          "type": "string",
          "title": "PAN Card Number",
          "collection": "nonprofits",
          "key": "pan_card",
          "element": "input",
          "validations": {
            "required": true,
            "uppercase": true,
            "compliance": [
              "giveassured",
              "givecompliant"
            ],
            "format": "pan",
            "status": [
              "document_check",
              "content_check",
              "cause_check",
              "final_approval",
              "published"
            ]
          }
        },
        "panCard": {
          "type": "string",
          "element": "upload",
          "title": "PAN Card",
          "collection": "nonprofits",
          "key": "pan_card_doc",
          "validations": {
            "required": true,
            "compliance": [
              "giveassured",
              "givecompliant"
            ],
            "status": [
              "document_check",
              "content_check",
              "cause_check",
              "final_approval",
              "published"
            ]
          }
        }
      }
    },
    "bank_account_details": {
      "type": "object",
      "title": "Bank Account Details",
      "key": "bank_accounts",
      "element": "step",
      "properties": {
        "domesticBankAccount": {
          "title": "Domestic Bank Account",
          "type": "object",
          "key": "bank_accounts",
          "collection": "nonprofits",
          "siblings": true,
          "identifier": {
            "is_international": false
          },
          "properties": {
            "is_international": {
              "visibility": false,
              "key": "is_international",
              "value": false,
              "element": "input",
              "validations": {
                "required": true
              }
            },
            "beneficiaryName": {
              "key": "account_name",
              "type": "string",
              "title": "Beneficiary Name",
              "element": "input",
              "validations": {
                "required": true
              }
            },
            "accountType": {
              "key": "account_type",
              "element": "select",
              "type": "string",
              "title": "Account Type",
              "enum": {
                "Savings": "Savings Account",
                "Current": "Current Account"
              },
              "validations": {
                "required": true
              }
            },
            "accountNumber": {
              "type": "string",
              "title": "Account Number",
              "collection": "nonprofits",
              "key": "account_number",
              "element": "input",
              "validations": {
                "required": true
              }
            },
            "ifsc": {
              "type": "string",
              "title": "IFSC",
              "collection": "nonprofits",
              "key": "ifsc_code",
              "element": "input",
              "validations": {
                "format": "ifsc",
                "required": true
              }
            },
            "cancelledCheque": {
              "type": "string",
              "element": "upload",
              "title": "Cancelled Cheque ( Indian Bank Account )",
              "collection": "nonprofits",
              "key": "cancelled_cheque_doc",
              "validations": {
                "required": true
              }
            }
          }
        },
        "fcraCertificateCheck": {
          "element": "radio",
          "type": "boolean",
          "key": "isFCRA",
          "title": "Do you have an FCRA Certificate?",
          "enum": {
            "yes": "Yes",
            "no": "No"
          }
        },
        "fcraDetails": {
          "title": "FCRA Details",
          "type": "object",
          "element": "row",
          "properties": {
            "fcraNumber": {
              "type": "string",
              "title": "FCRA Number",
              "collection": "nonprofits",
              "key": "fcra",
              "element": "input",
              "validations": {
                "required": true
              }
            },
            "fcraCertificate": {
              "type": "string",
              "element": "upload",
              "title": "FCRA Certificate",
              "collection": "nonprofits",
              "key": "fcra_doc",
              "validations": {
                "required": true
              }
            },
            "fcraExpiryDate": {
              "type": "string",
              "element": "input",
              "title": "FCRA Expiry Date",
              "collection": "nonprofits",
              "key": "fcra_validity",
              "validations": {
                "required": true,
                "format": "date"
              }
            }
          },
          "dependency": {
            "key": "isFCRA",
            "on": "yes"
          }
        },
        "fcraBankAccount": {
          "title": "FCRA Bank Account",
          "type": "object",
          "element": "row",
          "key": "bank_accounts",
          "collection": "nonprofits",
          "siblings": true,
          "identifier": {
            "is_international": true
          },
          "properties": {
            "is_international": {
              "visibility": false,
              "collection": "nonprofits",
              "key": "is_international",
              "value": true,
              "element": "input",
              "validations": {
                "required": true
              }
            },
            "beneficiaryName": {
              "type": "string",
              "title": "Beneficiary Name",
              "collection": "nonprofits",
              "key": "account_name",
              "element": "input",
              "validations": {
                "required": true
              }
            },
            "accountType": {
              "element": "select",
              "type": "string",
              "title": "Account Type",
              "collection": "nonprofits",
              "key": "account_type",
              "enum": {
                "Savings": "Savings Account",
                "Current": "Current Account"
              },
              "validations": {
                "required": true
              }
            },
            "accountNumber": {
              "type": "string",
              "collection": "nonprofits",
              "key": "account_number",
              "title": "Account Number",
              "element": "input",
              "validations": {
                "required": true
              }
            },
            "ifsc": {
              "type": "string",
              "title": "IFSC",
              "collection": "nonprofits",
              "key": "ifsc_code",
              "element": "input",
              "validations": {
                "required": true,
                "format": "ifsc"
              }
            },
            "cancelledCheque": {
              "type": "string",
              "element": "upload",
              "title": "Cancelled Cheque ( Indian Bank Account )",
              "collection": "nonprofits",
              "key": "cancelled_cheque_doc",
              "validations": {
                "required": true
              }
            }
          },
          "dependency": {
            "key": "isFCRA",
            "on": "yes"
          }
        }
      },
      "validations": {
        "status": [
          "document_check",
          "content_check",
          "cause_check",
          "final_approval",
          "published"
        ]
      }
    },
    "nonprofit_images": {
      "element": "step",
      "title": "Nonprofit Images",
      "type": "object",
      "collection": 'nonprofit',
      "properties": {
        "nonprofit_logo": {
          "type": "object",
          "title": "Non-profit Logo",
          "element": "section",
          "properties": {
            "nonProfitLogo": {
              "type": "string",
              "element": "media",
              "collection": "nonprofits",
              "key": "image",
              "title": "Please select Non-profit logo",
              "validations": {
                "required": true,
                "compliance": [
                  "giveassured",
                  "givecompliant"
                ],
                "status": [
                  "document_check",
                  "content_check",
                  "cause_check",
                  "final_approval",
                  "published"
                ]
              }
            }
          }
        },
        "cover_image": {
          "type": "string",
          "element": "image",
          "title": "Nonprofit Cover Image",
          "collection": "nonprofits",
          "key": "cover_image",
          "validations": {
            "required": false,
            "compliance": [
              "giveassured",
              "givecompliant"
            ],
            "status": [
              "document_check",
              "content_check",
              "cause_check",
              "final_approval",
              "published"
            ]
          }
        }
      }
    },
    'documents': {
      'element': 'step',
      'type': 'object',
      'description':'1. Please ensure that all uploaded documents are legible and vertically aligned (not rotated).<br> 2. Each upload cannot be more than 10 MB',
      'title': 'Documents',
      'properties': {
          'registrationCertificate': {
              'type': 'string',
              'element': 'upload',
              'title': 'Registration/Trust Deed/MOA Number Document',
              'collection': 'nonprofits',
              'key': 'registration_doc',
              'validations': {
                  'required': true,
                  'compliance': [
                      'giveassured',
                      'givecompliant'
                  ],
                  'status': [
                      'document_check',
                      'content_check',
                      'cause_check',
                      'final_approval',
                      'published'
                  ]
              }
          },                                                                  
          'annualReport': {
              'type': 'string',
              'element': 'upload',
              'title': 'Annual Report',
              'description':'For the latest financial year',
              'collection': 'nonprofits',
              'key': 'annual_report_doc'
          },                                
          'addressProof': {
              'type': 'string',
              'element': 'upload',
              'title': 'Address proof',
              'collection': 'nonprofits',
              'key': 'address_proof_doc',
              'description': 
                  'Telephone/Electricity/Wifi bill; Rent/Lease Agreement allowed. In case document in the name of the organisation is not available, add Telephone/Electricity/Wifi bill; Rent/Lease Agreement in the name of the individual/organisation with attested declaration mentioning that the organisation has been given permission to use the said premises.',
              'validations': {
                  'required': true,
                  'compliance': [                            
                      'giveassured',
                      'givecompliant'
                  ]
              }
          },                
          // TODO: This is a hacky way to do schema for both compliances separately
          'documentsFacebookAssured': {
              'type': 'object',
              'iterable': true,
              'repeaton': ["2020-21"],
              'element': 'section',
              'title': 'Financial Year {{repeatKey}}',
              'index': 'financialYear',
              'key': 'documents',
              'collection': 'nonprofits',
              'dontShowIn': {
                  '0': 'giveassured',
                  '1': 'givecompliant'
              },
              'validations': {
                  'required': true,
                  'compliance': [                            
                      'giveassured',
                      'givecompliant'
                  ]
              },
              'properties': {
                  'document_type': {
                      'type': 'object',
                      'iterable': true,
                      'element': 'section',
                      'index': 'documentName',
                      // 'title': '{{repeatKey}}',
                      'title': {
                          'auditor_report': 'Auditor\'s Report',
                          'income_expenditure': 'Income & Expenditure Report',
                          'balance_sheet': 'Balance Sheet',
                          'schedule': 'Schedules',
                          'annual_report': 'Annual Report',
                          'account_notes': 'Notes on Accounts',
                          'income_tax_return': 'Income Tax Returns',
                          'fcra_return': 'FCRA Returns'
                      },
                      'description':{                                
                          'schedule':
                              'Schedules/Annexures/Statement to Balance sheet allowed. If schedules included within Balance Sheet, upload complete financials here. In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence.',
                          'account_notes':
                              'Notes to accounts/notes at the end of Financial Statement/Significant Accounting policies to be accepted. In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence.',
                          'annual_report':
                              'In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence.',
                          'fcra_return':
                              'In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence.',
                      },
                      'repeaton': [
                          'auditor_report',
                          'income_expenditure',
                          'balance_sheet',
                          'schedule',
                          'annual_report',
                          'account_notes',
                          'income_tax_return',
                          'fcra_return'
                      ],
                      'siblings': true,
                      'identifier': {
                          'fy': '{{financialYear}}',
                          'document_type': '{{documentName}}'
                      },
                      'properties': {
                          'fy': {
                              'type': 'string',
                              'visibility': false,
                              'element': 'input',
                              'key': 'fy',
                              'value': '{{financialYear}}'
                          },
                          'document_type': {
                              'type': 'string',
                              'visibility': false,
                              'element': 'input',
                              'key': 'document_type',
                              'value': '{{documentName}}'
                          },
                          'uri': {
                              'type': 'string',
                              'element': 'upload',
                              'key': 'uri',
                          }
                      }
                  }
              }
          },
          // TODO: This is a hacky way to do schema for both compliances separately
          'documentsGiveAssured': {
              'type': 'object',
              'iterable': true,
              'repeaton': ["2020-21"],
              'element': 'section',
              'title': 'Financial Year {{repeatKey}}p',
              'index': 'financialYear',
              'key': 'documents',
              'collection': 'nonprofits',
              'dontShowIn': {                        
                  '0': 'givecompliant'
              },
              'validations': {
                  'required': true,
                  'compliance': [                            
                      'giveassured'
                  ]
              },
              'properties': {
                  'document_type': {
                      'type': 'object',
                      'iterable': true,
                      'element': 'section',
                      'index': 'documentName',
                      // 'title': '{{repeatKey}}',
                      'title': {
                          'auditor_report': 'Auditor\'s Report',
                          'income_expenditure': 'Income & Expenditure Report',
                          'balance_sheet': 'Balance Sheet',
                          'schedule': 'Schedules',
                          'annual_report': 'Annual Report',
                          'account_notes': 'Notes on Accounts',
                          'income_tax_return': 'Income Tax Returns',
                          'fcra_return': 'FCRA Returns'
                      },
                      'description':{                                
                          'schedule':
                              'Schedules/Annexures/Statement to Balance sheet allowed. If schedules included within Balance Sheet, upload complete financials here. In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence.',
                          'account_notes':
                              'Notes to accounts/notes at the end of Financial Statement/Significant Accounting policies to be accepted. In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence.',
                          'annual_report':
                              'In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence.',
                          'fcra_return':
                              'In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence.',
                      },
                      'repeaton': [
                          'auditor_report',
                          'income_expenditure',
                          'balance_sheet',
                          'schedule',
                          'annual_report',
                          'account_notes',
                          'income_tax_return',
                          'fcra_return'
                      ],
                      'siblings': true,
                      'identifier': {
                          'fy': '{{financialYear}}',
                          'document_type': '{{documentName}}'
                      },
                      'properties': {
                          'fy': {
                              'type': 'string',
                              'visibility': false,
                              'element': 'input',
                              'key': 'fy',
                              'value': '{{financialYear}}'
                          },
                          'document_type': {
                              'type': 'string',
                              'visibility': false,
                              'element': 'input',
                              'key': 'document_type',
                              'value': '{{documentName}}'
                          },
                          'uri': {
                              'type': 'string',
                              'element': 'upload',
                              'key': 'uri',
                          }
                      }
                  }
              }
          },
          // TODO: This is a hacky way to do schema for the compliances separately
          'documentsGiveCompliant': {
              'type': 'object',
              'iterable': true,
              'repeaton': ["2020-21"],
              'element': 'section',
              'title': 'Financial Year {{repeatKey}}',
              'index': 'financialYear',
              'key': 'documents',
              'collection': 'nonprofits',
              'dontShowIn': {                        
                  '0': 'giveassured'
              },
              'validations': {
                  'required': true,
                  'compliance': [                            
                      'giveassured'
                  ]
              },
              'properties': {
                  'document_type': {
                      'type': 'object',
                      'iterable': true,
                      'element': 'section',
                      'index': 'documentName',
                      'title': {                                
                          'income_expenditure': 'Income & Expenditure Report',
                          'balance_sheet': 'Balance Sheet'                                
                      },                            
                      'repeaton': [                                
                          'income_expenditure',
                          'balance_sheet'                                
                      ],
                      'iterable':true,
                      'siblings': true,
                      'identifier': {
                          'fy': '{{financialYear}}',
                          'document_type': '{{documentName}}'
                      },
                      'properties': {
                          'fy': {
                              'type': 'string',
                              'visibility': false,
                              'element': 'input',
                              'key': 'fy',
                              'value': '{{financialYear}}'
                          },
                          'document_type': {
                              'type': 'string',
                              'visibility': false,
                              'element': 'input',
                              'key': 'document_type',
                              'value': '{{documentName}}'
                          },
                          'uri': {
                              'type': 'string',
                              'element': 'upload',
                              'key': 'uri',
                          }
                      }
                  }
              }
          },
      }
  },
    "social_media_accounts": {
      "type": "object",
      "title": "Social Media Accounts",
      "element": "step",
      "properties": {
        "social": {
          "type": "object",
          "element": "row",
          "properties": {
            "facebook": {
              "type": "object",
              "key": "external_profiles",
              "collection": "nonprofits",
              "siblings": true,
              "identifier": {
                "label": "Facebook"
              },
              "properties": {
                "facebookLabel": {
                  "key": "label",
                  "type": "string",
                  "title": "label",
                  "value": "Facebook",
                  "element": "input",
                  "visibility": false
                },
                "facebookUrl": {
                  "type": "string",
                  "description": "Add URL of official page of organisation on Facebook. Only Pages with \"Like\" option can be added",
                  "title": "Facebook Page",
                  "key": "uri",
                  "element": "input",
                  "validations": {
                    "format": "url"
                  }
                }
              }
            },
            "twitter": {
              "type": "object",
              "key": "external_profiles",
              "collection": "nonprofits",
              "siblings": true,
              "identifier": {
                "label": "Twitter"
              },
              "properties": {
                "twitterLabel": {
                  "key": "label",
                  "type": "string",
                  "title": "label",
                  "value": "Twitter",
                  "visibility": false,
                  "element": "input"
                },
                "twitterPageUrl": {
                  "type": "string",
                  "title": "Twitter Page",
                  "description": "Add URL of official page of the organisation on Twitter",
                  "key": "uri",
                  "element": "input",
                  "validations": {
                    "format": "url"
                  }
                }
              }
            },
            "youtube": {
              "type": "object",
              "key": "external_profiles",
              "collection": "nonprofits",
              "siblings": true,
              "identifier": {
                "label": "Youtube"
              },
              "properties": {
                "youtubeLabel": {
                  "key": "label",
                  "type": "string",
                  "title": "label",
                  "value": "Youtube Channel",
                  "visibility": false,
                  "element": "input"
                },
                "youtubeUrl": {
                  "type": "string",
                  "title": "YouTube Channel",
                  "description": "Add URL of official page of the organisation on YouTube",
                  "key": "uri",
                  "element": "input",
                  "validations": {
                    "format": "url"
                  }
                }
              }
            },
            "instagram": {
              "type": "object",
              "key": "external_profiles",
              "collection": "nonprofits",
              "siblings": true,
              "identifier": {
                "label": "Instagram"
              },
              "properties": {
                "instagramLabel": {
                  "key": "label",
                  "type": "string",
                  "title": "label",
                  "value": "Instagram",
                  "visibility": false,
                  "element": "input"
                },
                "instagramUrl": {
                  "type": "string",
                  "title": "Instagram Profile",
                  "description": "Add URL of official page of the organisation on Instagram",
                  "key": "uri",
                  "element": "input",
                  "validations": {
                    "format": "url"
                  }
                }
              }
            },
            "linkedIn": {
              "type": "object",
              "key": "external_profiles",
              "collection": "nonprofits",
              "siblings": true,
              "identifier": {
                "label": "LinkedIn"
              },
              "properties": {
                "linkedinLabel": {
                  "key": "label",
                  "type": "string",
                  "title": "label",
                  "value": "Linkedin",
                  "visibility": false,
                  "element": "input"
                },
                "linkedinUrl": {
                  "type": "string",
                  "title": "Linkedin",
                  "description": "Add URL of official page of the organisation on Linkedin",
                  "key": "uri",
                  "element": "input",
                  "validations": {
                    "format": "url"
                  }
                }
              }
            }
          }
        }
      }
    },
    "project": {
      "element": "step",
      "type": "object",
      "title": "Project Details",
      "properties": {
        "projectDetail": {
          "element": "section",
          "properties": {
            "projectName": {
              "element": "input",
              "title": "Name of the Project",
              "key": "name",
              "collection": "projects",
              "validations": {
                "compliance": [
                  "giveassured",
                  "givecompliant"
                ],
                "status": [
                  "document_check",
                  "content_check",
                  "cause_check",
                  "final_approval"
                ]
              }
            },
            "projectIssue": {
              "element": "textarea",
              "title": "Issue (What's the concern? - Max 200 Words)",
              "key": "issue",
              "collection": "projects"
            },
            "action": {
              "element": "textarea",
              "title": "Action (What are you going to do about it? - Max 200 words)",
              "key": "action",
              "collection": "projects"
            },
            "impact": {
              "element": "textarea",
              "title": "Impact (What is the impact that you wish to create? - Max 200 words)",
              "key": "impact",
              "collection": "projects"
            },
            "primaryCause": {
              "element": "select",
              "title": "Primary Cause",
              "enum": {
                "Education": "Education",
                "Children": "Children",
                "Women Empowerment": "Women Empowerment",
                "Healthcare": "Healthcare",
                "Livelihood": "Livelihood",
                "Differently Abled": "Differently Abled",
                "Disaster Relief": "Disaster Relief",
                "Community & Rural Development": "Community & Rural Development",
                "Animal Welfare": "Animal Welfare",
                "Elderly Care": "Elderly Care",
                "Human Rights": "Human Rights",
                "Environment": "Environment",
                "Hunger & Homelessness": "Hunger & Homelessness",
                "Art & Culture": "Art & Culture",
                "Sports": "Sports"
              },
              "collection": "projects",
              "key": "causes.0"
            },
            "secondaryCause": {
              "element": "select",
              "title": "Secondary Cause",
              "enum": {
                "Education": "Education",
                "Children": "Children",
                "Women Empowerment": "Women Empowerment",
                "Healthcare": "Healthcare",
                "Livelihood": "Livelihood",
                "Differently Abled": "Differently Abled",
                "Disaster Relief": "Disaster Relief",
                "Community & Rural Development": "Community & Rural Development",
                "Animal Welfare": "Animal Welfare",
                "Elderly Care": "Elderly Care",
                "Human Rights": "Human Rights",
                "Environment": "Environment",
                "Hunger & Homelessness": "Hunger & Homelessness",
                "Art & Culture": "Art & Culture",
                "Sports": "Sports"
              },
              "collection": "projects",
              "key": "causes.1"
            },
            "projectImage": {
              "element": "media",
              "title": "Project Images",
              "description": "Please select Cover Image for Project",
              "key": "cover_image",
              "collection": "projects"
            },
            "tileImage": {
              "element": "media",
              "title": "Project Tile Image",
              "description": "Please select Tile Image for Project",
              "key": "image",
              "collection": "projects"
            },
            "donationOptions": {
              "element": "row",
              "type": "object",
              "iterable": true,
              "repeaton": [
                0,
                1,
                2
              ],
              "collection": "projects",
              "key": "donation_options",
              "title": "",
              "siblings": true,
              "validations": {
                "required": true
              },
              "properties": {
                "amount": {
                  "type": "string",
                  "element": "input",
                  "title": "Amount",
                  "key": "amount",
                  "collection": "projects",
                  "validations": {
                    "required": true,
                    "format": "number"
                  }
                },
                "currency_code": {
                  "element": "input",
                  "key": "currency_code",
                  "collection": "projects",
                  "value": "INR",
                  "visibility": false,
                  "validations": {
                    "required": true
                  }
                },
                "title": {
                  "element": "input",
                  "type": "string",
                  "key": "title",
                  "collection": "projects",
                  "title": "Title",
                  "validations": {
                    "required": true
                  }
                }
              }
            },
            "costBreakUp": {
              "element": "row",
              "type": "object",
              "iterable": true,
              "startIndex": 0,
              "collection": "projects",
              "key": "project_costs",
              "title": "Project Cost",
              "validations": {
                "required": true
              },
              "properties": {
                "title": {
                  "element": "input",
                  "type": "string",
                  "key": "title",
                  "collection": "projects",
                  "title": "Particulars",
                  "validations": {
                    "required": true
                  }
                },
                "value": {
                  "type": "string",
                  "element": "input",
                  "title": "Amount",
                  "key": "value",
                  "collection": "projects",
                  "validations": {
                    "required": true
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


// Method to fetch the FORM Schema from the Core Service API
export function getFormSchema(request){
  const data = request
  return (dispatch) => {
    dispatch({
      type: GET_SCHEMA
    })
    return NonProfitService.getSchema(request)
      .then((response) => {
        dispatch({
          type: GET_SCHEMA_SUCCESS,
          // type: GET_SCHEMA_FAIL,
          schema: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_SCHEMA_FAIL,
          errors: error
        })
        dispatch(NotificationAction.addNotification(error.message));
      })              
  }
}
