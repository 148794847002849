import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';

import classnames from 'classnames';

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import NonprofitTile from '../../../components/NonprofitTile';

import * as NGOListActions from '../../../reducers/AdminApprovals/ngoListReducer';

import './NGOList.scss';
import Loading from '../../../appComponents/Loading';
import Helpers from '../../../utils/helpers';

class NGOList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q: '',
    };
  }

  componentDidMount() {
    const { NGOList } = this.props;
    const { q } = this.state;
    NGOList.getNonprofits({ q });
  }

  componentWillUnmount() {
    const { NGOList } = this.props;
    NGOList.reset();
  }

  handelQ = (e) => {
    e.preventDefault();
    this.setState({ q: e.target.value });
  }

  handelSearch = (e) => {
    e.preventDefault();
    const { q } = this.state;
    const { NGOList } = this.props;
    if (q.length >= 3) {
      NGOList.reset();
      NGOList.getNonprofits({ q });
    } else if (q.length === 0) {
      NGOList.reset();
      NGOList.getProjects({ q: '' });
    }
  }

  clearSearch = () => {
    this.setState({ q: '' });
    const { NGOList } = this.props;
    NGOList.reset();
    NGOList.getNonprofits({ q: '' });
  }

  loadMore = () => {
    const { NGOList } = this.props;
    const { q } = this.state;
    NGOList.getNonprofits({ q });
  }

  NGOListHTML = () => {
    const { ngoList } = this.props;
    if (!ngoList.get_status.nonprofit || ngoList.get_status.nonprofit === 'pending' && ngoList.nonprofits.length === 0) {
      return (<Loading />);
    }
    if (ngoList.nonprofits.length === 0) {
      return (
        <i className="align-center">No items available</i>
      );
    }
    return ngoList.nonprofits.map(item => (
      <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col" key={item.slug}>
        <NonprofitTile value={item} link={'/approvals/nonprofit-onboarding/'} />
      </div>
    ));
  }

  downloadDonationList = (e) => {
    e.preventDefault();
    const { NGOList } = this.props;
    const { q } = this.state;
    NGOList.getNonprofitsForDownload({ q }).then((data) => {
      const fileName = `GiveFundraisers-pending-nonprofits-${format(new Date(), 'HH:mm:ss')}`;
      const json = data.map((d) => ({
        "Created date": format(new Date(d.created_at), 'do MMM yyyy'),
        "Created time": format(new Date(d.created_at), 'HH:mm'),
        "Updated date": format(new Date(d.updated_at), 'do MMM yyyy'),
        "Updated time": format(new Date(d.updated_at), 'HH:mm'),
        "Name": d.name,
        "PAN": (d.pan_card ? d.pan_card : ''),
        "Status": d.status,
        "POC Name": (d.members[0] ? d.members[0].name : ''),
        "POC Email": (d.members[0] ? d.members[0].email : ''),
        "POC Mobile Number": (d.members[0] ? d.members[0].phone : ''),
      }));
      Helpers.convertJSONtoCSV(json, fileName);
    });
  }

  render() {
    const { ngoList } = this.props;
    const { q } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Pending NonProfit List</h3>
                <div className="row">
                  <div className="col-md-6 col-12">
                    {/* <div className="form-group">
                        <SelectInput />
                      </div> */}
                  </div>
                  <div className="col-md-6 col-12">
                    <form onSubmit={this.handelSearch}>
                      <div className="form-group d-flex search-field">
                        <div className={classnames('input-group', { active: (q !== '') })}>
                          <input type="text" className="form-control" placeholder="Search Here" value={q} onChange={this.handelQ} />
                          <div className="search-close">
                            <button type="button" onClick={this.clearSearch}><Icon path={mdiClose} className="menu-arrow" size={0.75} color="#979797" /></button>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary ml-3">Search</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row justify-content-end">
                  <div className="col-md-12 col-12 text-right">
                    <button type="button" className="btn btn-secondary" onClick={this.downloadDonationList}>
                      {ngoList.get_status.download === 'pending' ? 'Downloading...' : 'Export to CSV'}
                    </button>
                    <div>
                      <i><small className="text-muted">CSV file contains max of 1000 entries</small></i>
                    </div>
                  </div>
                </div>
                <div className="row ngo-list-wrapper">
                  {this.NGOListHTML()}
                </div>
                <div className="row">
                  {(ngoList.load_status.nonprofit) ? (
                    <div className="col-md-12">
                      <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                    </div>
                  ) : null}
                  {(ngoList.get_status.nonprofit === 'pending' && ngoList.nonprofits.length !== 0) ? (
                    <div className="col-md-12">
                      <Loading />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ngoList: state.ngoList,
});

const mapActionToProps = dispatch => ({
  NGOList: bindActionCreators(NGOListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(NGOList);
