import * as NotificationAction from "../notificationReducer";
import UpdateService from "../../services/update-service";

export const UPDATES_DATA_SET = 'UPDATES_DATA_SET';
export const UPDATES_DATA_APPEND = 'UPDATES_DATA_APPEND';
export const UPDATES_DATA_PREPEND = 'UPDATES_DATA_PREPEND';
export const UPDATES_DATA_REPLACE = 'UPDATES_DATA_REPLACE';
export const UPDATES_DATA_DELETE = 'UPDATES_DATA_DELETE';
export const UPDATES_LOADER_STATE = 'UPDATES_LOADER_STATE';
export const ERROR_STATE = 'ERROR_STATE';

const initialState = {
  loading: false,
  data: [],
  errors: {}
};

export const updates = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATES_DATA_SET:
      return {
        ...state,
        data: action.data,
        loading: false,
      };

    case UPDATES_DATA_PREPEND:
      return {
        ...state,
        data: [].concat(action.data).concat(state.data),
        loading: false,
      };

    case UPDATES_DATA_APPEND:
      return {
        ...state,
        data: state.data.concat(action.data),
        loading: false,
      };

    case UPDATES_DATA_REPLACE:
      return {
        ...state,
        data: state.data.map((update) => {
          if (update._id === action.data._id) {
            return action.data
          } else {
            return update
          }
        }),
        loading: false,
      };

    case UPDATES_DATA_DELETE:
      return {
        ...state,
        data: state.data.filter((update) => {
          return update._id !== action.data._id
        }),
        loading: false,
      };

    case ERROR_STATE:
      return {
        ...state,
        errors: {
          ...state.errors,
          data: action.errors,
        },
      };

    case UPDATES_LOADER_STATE:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}

export function loadResultsFail(error) {
  return {
    type: UPDATES_LOADER_STATE,
    error,
    loading: false
  };
}

export function loadResultsSuccess(data) {
  return {
    type: UPDATES_DATA_SET,
    data: data.results,
  };
}

export function createUpdate(newUpdateTitleValue, newUpdateDescriptionValue, newUpdateGalleryValue, projectId) {
  return (dispatch, getState) => {
    let submitData = {
      title: newUpdateTitleValue,
      description: newUpdateDescriptionValue,
      gallery: newUpdateGalleryValue,
      type: 'project',
    };
    return UpdateService.createUpdate(submitData, projectId)
      .then(data => {
        dispatch({
          type: UPDATES_DATA_PREPEND,
          data,
        })
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: ERROR_STATE,
            errors: error.value.errors
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Project form'))
        }
        else if (error.value && error.value.error) {
          dispatch({
            type: ERROR_STATE,
            errors: {}
          });
          dispatch(NotificationAction.addNotification(error.value.error))
        }
        else {
          dispatch({
            type: ERROR_STATE,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function editUpdate(editUpdateTitleValue, editUpdateDescriptionValue, editUpdateGalleryValue, projectId, updateId) {
  return (dispatch) => {
    let submitData = {
      title: editUpdateTitleValue,
      description: editUpdateDescriptionValue,
      gallery: editUpdateGalleryValue,
      type: 'project',
    };
    return UpdateService
      .editUpdate(submitData, projectId, updateId)
      .then(data => {
        dispatch(
          {
            type: UPDATES_DATA_REPLACE,
            data,
          }
        );
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
      })
      .catch(error => {
        if (error.value && error.value.errors) {
          dispatch({
            type: ERROR_STATE,
            errors: error.value.errors
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Project form'))
        }
        else if (error.value && error.value.error) {
          dispatch({
            type: ERROR_STATE,
            errors: {}
          });
          dispatch(NotificationAction.addNotification(error.value.error))
        }
        else {
          dispatch({
            type: ERROR_STATE,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function deleteUpdate(projectId, updateId) {
  return (dispatch) => {
    return UpdateService
      .deleteUpdate(projectId, updateId)
      .then(data => {
        data._id = updateId
        dispatch(
          {
            type: UPDATES_DATA_DELETE,
            data,
          }
        );
        dispatch(NotificationAction.addNotification('Deleted successfully', 'SUCCESS'));
      })
      .catch(error => {
        if (error.value && error.value.errors) {
          dispatch({
            type: ERROR_STATE,
            errors: error.value.errors
          });
          dispatch(NotificationAction.addNotification('Error occured during deletion!'))
        }
        else if (error.value && error.value.error) {
          dispatch({
            type: ERROR_STATE,
            errors: {}
          });
          dispatch(NotificationAction.addNotification(error.value.error))
        }
        else {
          dispatch({
            type: ERROR_STATE,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function load(projectId) {
  return (dispatch) => {
    dispatch({
      type: UPDATES_LOADER_STATE,
    });

    return UpdateService
      .getUpdates(projectId)
      .then(data => {
        dispatch(loadResultsSuccess(data));
      }).catch((error) => {
        dispatch(loadResultsFail(error));
      })
  };
}