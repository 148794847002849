import {
  createStore,
  combineReducers,
  compose,
  applyMiddleware,
} from 'redux';

import ReduxThunk from 'redux-thunk';
import { loginReducer } from './loginReducer';
import { notificationReducer } from './notificationReducer';
import { homeReducer } from './homeReducer';
import { homeReducer as ngoOnboardingHomeReducer } from './NGOOnboarding/homeReducer';
import { dashboardReducer as adminOnboardingDashboardReducer } from './AdminApprovals/dashboardReducer';
import { pendingApprovalsReducer } from './AdminApprovals/pendingApprovalsReducer';
import { pendingApprovalsItemReducer } from './AdminApprovals/pendingApprovalsItemReducer';
import { projectApprovalsReducer } from './AdminApprovals/projectApprovalsReducer';
import { projectApprovalsItemReducer } from './AdminApprovals/projectApprovalsItemReducer';
import { ngoListReducer } from './AdminApprovals/ngoListReducer';
import { nonprofitSelfServeListReducer } from './AdminApprovals/nonprofitSelfServeListReducer';
import { nonprofitSelfServeItemReducer } from './AdminApprovals/nonprofitSelfServeItemReducer';
import { projectListReducer } from './AdminApprovals/projectListReducer';
import { configReducer } from './configReducer';
import { eventReducer } from './eventReducer';
import { dashboardReducer as crowdfundingDashboardReducer } from './Crowdfunding/dashboardReducer';
import { createBulkCampaignReducer } from './Crowdfunding/createBulkCampaignReducer';
import { liveDonationReducer as crowdfundingLiveDonationReducer } from './Crowdfunding/liveDonationReducer';
import { disbursedDonationReducer as crowdfundingDisbursedDonationReducer } from './Crowdfunding/disbursedDonationReducer';
import { campaignSupportersReducer as crowdfundingCampaignSupportersReducer } from './Crowdfunding/campaignSupportersReducer';
import { syncReducer as disbursementsSyncReducer } from './Disbursements/syncReducer';
import { disbursementReducer as disbursementsDisbursementReducer } from './Disbursements/disbursementReducer';
import { settledReducer as disbursementsSettledReducer } from './Disbursements/settledReducer';
import { nonprofitsReducer as profileNonprofitsReducer } from './Profile/nonprofitsReducer';
import { projectsReducer as profileProjectsReducer } from './Profile/projectsReducer';
import { projectItemReducer as profileProjectItemReducer } from './Profile/projectItemReducer';
import { adminNonprofitListReducer as adminNonprofitListReducer } from './Admin/adminNonprofitListReducer';
import { admin10BdBeListReducer as admin10BdBeListReducer } from './Admin/admin10BdBeListReducer';
import { complianceOnboardingReducer as complianceOnboardingReducer } from './ComplianceOnboarding/complianceOnboardingReducer';
import { complianceApprovalReducer as complianceApprovalReducer } from './AdminApprovals/complianceApprovalReducer';
import { adminNonprofitItemReducer as adminNonprofitItemReducer } from './Admin/adminNonprofitItemReducer';
import { adminProjectListReducer as adminProjectListReducer } from './Admin/adminProjectListReducer';
import { adminProjectItemReducer as adminProjectItemReducer } from './Admin/adminProjectItemReducer';
import { adminDonationListReducer as adminDonationListReducer } from './Admin/adminDonationListReducer';
import { adminSubscriptionListReducer as adminSubscriptionListReducer} from './Admin/adminSubscriptionListReducer';
import { supportDonationListReducer as supportDonationListReducer } from './Support/supportDonationListReducer';
import { adminCampaignListReducer as adminCampaignListReducer } from './Admin/adminCampaignListReducer';
import { projectSelfServeListReducer as projectSelfServeListReducer } from './AdminApprovals/projectSelfServeListReducer'
import { projectSelfServeItemReducer as projectSelfServeItemReducer } from './AdminApprovals/projectSelfServeItemReducer'
import { updates as updates} from "./Admin/adminProjectUpdatesReducer";
import { tagsReducer as tags} from "./Tags/tagsReducer";
import { filterByReducer as filterByReducer } from './Other/filterByReducer';

import {facebookOnboardingReducer} from './FacebookOnboarding/fbOnboardingReducer'
import {allDonationReducer} from './Crowdfunding/allDonationsReducer'


// if you're using redux-thunk or other middlewares, add them here
const createStoreWithMiddleware = compose(applyMiddleware(
  ReduxThunk,
))(createStore);
const rootReducer = combineReducers({
  login: loginReducer,
  notification: notificationReducer,
  home: homeReducer,
  ngoOnboardingHome: ngoOnboardingHomeReducer,
  pendingApprovals: pendingApprovalsReducer,
  pendingApprovalsItem: pendingApprovalsItemReducer,
  projectApprovals: projectApprovalsReducer,
  projectApprovalsItem: projectApprovalsItemReducer,
  config: configReducer,
  event: eventReducer,
  ngoList: ngoListReducer,
  nonprofitSelfServeList: nonprofitSelfServeListReducer,
  nonprofitSelfServeItem: nonprofitSelfServeItemReducer,
  projectsList: projectListReducer,
  crowdfundingDashboard: crowdfundingDashboardReducer,
  crowdfundingLiveDonation: crowdfundingLiveDonationReducer,
  crowdfundingDisbursedDonation: crowdfundingDisbursedDonationReducer,
  crowdfundingCampaignSupporters: crowdfundingCampaignSupportersReducer,
  complianceApproval: complianceApprovalReducer,
  complianceOnboarding: complianceOnboardingReducer,
  disbursementsSync: disbursementsSyncReducer,
  disbursementsDisbursements: disbursementsDisbursementReducer,
  disbursementsSettled: disbursementsSettledReducer,
  adminOnboardingDashboard: adminOnboardingDashboardReducer,
  profileNonprofits: profileNonprofitsReducer,
  profileProjects: profileProjectsReducer,
  profileProjectItem: profileProjectItemReducer,
  adminNonprofitList: adminNonprofitListReducer,
  adminNonprofit10BdBeList: admin10BdBeListReducer,
  adminCampaignList: adminCampaignListReducer,
  adminProjectList: adminProjectListReducer,
  adminSubScriptionList: adminSubscriptionListReducer,
  adminNonprofitItem: adminNonprofitItemReducer,
  adminProjectItem: adminProjectItemReducer,
  adminDonationList: adminDonationListReducer,
  supportDonationList: supportDonationListReducer,
  filterBy: filterByReducer,
  createBulkCampaign: createBulkCampaignReducer,
  projectSelfServeList : projectSelfServeListReducer,
  projectSelfServeItem : projectSelfServeItemReducer,
  facebookOnboarding: facebookOnboardingReducer,
  adminProjectUpdates : updates,
  allNgoDonations: allDonationReducer,
  tags: tags,
});

export default function configureStore(initialState = {}) {
  return createStoreWithMiddleware(rootReducer, initialState);
}
