import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import DisbursementService from '../../services/disbursement-service';
import DonationService from '../../services/donation-service';
import Helpers from '../../utils/helpers';

export const GET_DISBURSEMENTS_DISBURSEMENT_PG = 'GET_DISBURSEMENTS_DISBURSEMENT_PG';
export const GET_DISBURSEMENTS_DISBURSEMENT_PG_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_PG_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_PG_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_PG_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_STATS = 'GET_DISBURSEMENTS_DISBURSEMENT_STATS';
export const GET_DISBURSEMENTS_DISBURSEMENT_STATS_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_STATS_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_STATS_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_STATS_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST = 'GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST';
export const GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE = 'GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE';
export const GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE = 'GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE';
export const GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_GET = 'GET_DISBURSEMENTS_DISBURSEMENT_GET';
export const GET_DISBURSEMENTS_DISBURSEMENT_GET_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_GET_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_GET_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_GET_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT = 'GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT';
export const GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE = 'GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE';
export const GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE_FAIL';

export const GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT = 'GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT';
export const GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT_SUCCESS = 'GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT_SUCCESS';
export const GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT_FAIL = 'GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT_FAIL';

export const DISBURSEMENTS_DISBURSEMENT_RESET_TAB = 'DISBURSEMENTS_DISBURSEMENT_RESET_TAB';
export const DISBURSEMENTS_DISBURSEMENT_RESET = 'DISBURSEMENTS_DISBURSEMENT_RESET';

const initialState = {
  stats: [],
  pg: {},
  ngo_list: [],
  generate_pre_disbursemet: {},
  pre_disbursements: [],
  sync: {},
  disbursements: [],
  get_status: {},
  load_status: {},
};

export const disbursementReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DISBURSEMENTS_DISBURSEMENT_PG:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pg: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_PG_SUCCESS:
      return {
        ...state,
        pg: action.pg,
        get_status: {
          ...state.get_status,
          pg: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_PG_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pg: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_STATS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          stat: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats,
        get_status: {
          ...state.get_status,
          stat: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_STATS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          stat: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ngo_list: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST_SUCCESS:
      return {
        ...state,
        ngo_list: action.ngo_list,
        generate_pre_disbursemet: {},
        get_status: {
          ...state.get_status,
          ngo_list: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ngo_list: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_pre_disbursemet: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE_SUCCESS:
      return {
        ...state,
        generate_pre_disbursemet: action.generate_pre_disbursemet,
        get_status: {
          ...state.get_status,
          generate_pre_disbursemet: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          generate_pre_disbursemet: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pre_disbursement: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE_SUCCESS:
      return {
        ...state,
        pre_disbursements: action.pre_disbursements,
        get_status: {
          ...state.get_status,
          pre_disbursement: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          pre_disbursement: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_GET:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          disbursement: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_GET_SUCCESS:
      return {
        ...state,
        disbursements: action.disbursements,
        get_status: {
          ...state.get_status,
          disbursement: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_GET_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          disbursement: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          disbursement: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT_SUCCESS:
      return {
        ...state,
        disbursements: action.disbursements,
        get_status: {
          ...state.get_status,
          disbursement: 'success',
        },
        load_status: {
          ...state.load_status,
          disbursement: action.load_status
        }
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          disbursement: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          downloadBank: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          downloadBank: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          downloadBank: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          sync: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC_SUCCESS:
      return {
        ...state,
        sync: action.sync,
        get_status: {
          ...state.get_status,
          sync: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          sync: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          delete: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE_SUCCESS:
      return {
        ...state,
        pre_disbursements: action.pre_disbursements,
        get_status: {
          ...state.get_status,
          delete: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          delete: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          approve: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          approve: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          approve: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          settle: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          settle: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          settle: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['settle_' + action.id]: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['settle_' + action.id]: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['settle_' + action.id]: 'failed',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['send_report_' + action.id]: 'pending',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['send_report_' + action.id]: 'success',
        },
      };
    case GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          ['send_report_' + action.id]: 'failed',
        },
      };
    case DISBURSEMENTS_DISBURSEMENT_RESET:
      return {
        ...initialState,
      };
    case DISBURSEMENTS_DISBURSEMENT_RESET_TAB:
      return {
        ...state,
        ngo_list: [],
        generate_pre_disbursemet: {},
        pre_disbursements: [],
        sync: {},
        disbursements: [],
        get_status: {
          pg: state.get_status.pg,
          stat: state.get_status.stat,
        },
      };
    default:
      return state;
  }
};

export function getPG() {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_PG,
    });
    return DonationService.get({ path: ['payment-entities'] })
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_PG_SUCCESS,
          pg: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_PG_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getStats() {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_STATS,
    });
    const request = {
      path: ['stats'],
      method: 'post',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_STATS_SUCCESS,
          stats: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_STATS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getNGOList(data) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST,
    });
    const request = {
      path: ['pending'],
      params: data,
    };
    return DisbursementService.get(request)
      .then((response) => {
        if(response.length === 0) {
          dispatch(NotificationAction.addNotification(`Zero results found for this filter criteria`));
        }
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST_SUCCESS,
          ngo_list: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_NGO_LIST_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function generatePreDisbursement(data) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE,
    });
    const request = { data, path: ['batches'] };
    return DisbursementService.set(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE_SUCCESS,
          generate_pre_disbursemet: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_GENERATE_PRE_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getPreDisbursements() {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE,
    });
    const request = {
      path: ['batches'],
      params: { filter_by_status: 'PENDING' },
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE_SUCCESS,
          pre_disbursements: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_GET_PRE_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getDisbursements() {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_GET,
    });
    const request = {
      path: ['batches'],
      params: { filter_by_status: 'APPROVED' },
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_GET_SUCCESS,
          disbursements: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_GET_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getNonProfitDisbursements(nonprofit, filter={}) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT,
    });
    const currentNonProfitDisbursements = getState().disbursementsDisbursements.disbursements;
    const request = {
      params: { 
        ...filter,
        filter_by_nonprofit : nonprofit,
        skip: currentNonProfitDisbursements.length,
        limit: 10,
      }
    };
    return DisbursementService.get(request)
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT_SUCCESS,
            disbursements: currentNonProfitDisbursements,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT_SUCCESS,
            disbursements: [...currentNonProfitDisbursements, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_NONPROFIT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function downloadDisbursementBatch(data, type) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD,
    });
    let request;
    if(type === 'disburseTab') {
      request = {
        path: ['batches', data, 'download?status=approved'],
        method: 'POST',
      };
    } else {
      request = {
        path: ['batches', data, 'download'],
        method: 'POST',
      };
    }
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function downloadBankDisbursementBatch(data) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK,
    });
    const request = {
      path: ['batches', data, 'download-bank-report'],
      method: 'POST',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK_SUCCESS,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DOWNLOAD_BANK_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function syncDisbursementBatch(data, batchNo, type) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC,
    });
    let request;
    if(type === 'disburseTab') {
       request = {
        path: ['batches', batchNo, 'sync?type=utr'],
        data,
      };
    } else {
      request = {
        path: ['batches', batchNo, 'sync'],
        data,
      };
    }
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC_SUCCESS,
          sync: {
            data: response,
            batchNo,
          },
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_SYNC_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function deleteDisbursementBatch(batchNo) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE,
    });
    const request = {
      path: ['batches', batchNo, 'delete'],
      method: 'POST',
    };
    const current_pre_disbursement = getState().disbursementsDisbursements.pre_disbursements;
    const index = Helpers.objSearch(current_pre_disbursement, batchNo, '_id');
    return DisbursementService.get(request)
      .then((response) => {
        if (index >= 0) {
          current_pre_disbursement.splice(index, 1);
          const pre_disbursements = [...current_pre_disbursement];
          dispatch({
            type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE_SUCCESS,
            pre_disbursements: pre_disbursements,
          });
        } else {
          return Promise.reject(new _Error('Something went wrong!, Please refresh the page and try again!'));
        }

        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_DELETE_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function approveDisbursementBatch(batchNo) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE,
    });
    const request = {
      path: ['batches', batchNo, 'approve'],
      method: 'POST',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE_SUCCESS,
        });
        if(response.message){
          dispatch(NotificationAction.addNotification(response.message, 'SUCCESS'));
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_APPROVE_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function markAsSettleDisbursementBatch(batchNo) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE,
    });
    const request = {
      path: ['batches', batchNo, 'settle'],
      method: 'POST',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE_SUCCESS,
        });
        if(response.message){
          dispatch(NotificationAction.addNotification(response.message, 'SUCCESS'));
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_BATCH_MARK_SETTLE_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function markAsSettleDisbursement(disbursementNumber) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE,
      id: disbursementNumber,
    });
    const request = {
      path: [disbursementNumber, 'settle'],
      method: 'POST',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE_SUCCESS,
          id: disbursementNumber,
        });
        if(response.message){
          dispatch(NotificationAction.addNotification(response.message, 'SUCCESS'));
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_MARK_SETTLE_FAIL,
          id: disbursementNumber,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function resendDisbursementReport(disbursementNumber) {
  return (dispatch) => {
    dispatch({
      type: GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT,
      id: disbursementNumber,
    });
    const request = {
      path: [disbursementNumber, 'send-report'],
      method: 'POST',
    };
    return DisbursementService.get(request)
      .then((response) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT_SUCCESS,
          id: disbursementNumber,
        });
        if(response.message){
          dispatch(NotificationAction.addNotification(response.message, 'SUCCESS'));
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_DISBURSEMENTS_DISBURSEMENT_SEND_REPORT_FAIL,
          id: disbursementNumber,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function resetTab() {
  return (dispatch) => {
    dispatch({
      type: DISBURSEMENTS_DISBURSEMENT_RESET_TAB,
    });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: DISBURSEMENTS_DISBURSEMENT_RESET,
    });
  };
}
