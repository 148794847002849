import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import SingleFileUpload from '../SingleFileUpload/SingleFileUpload';

class NonprofitDocumentsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: {_id: props.value._id},
    };
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    let val  = e;
    if (onChange) {
      if (e !== null && key[1] === 'documents') {
        const [document_type, fy] = key[2].split(':');
        val = {
          fy: fy,
          uri: e,
          document_type: document_type,
        };
      }
      const change = Helpers.setDataToObject(value, key, val);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    if (onSubmit) {
      onSubmit(changes);
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { value } = this.props;
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  financialYearHtmlDocument = (year) => {
    const yearVar = `${year.slice(0,5)}${year.slice(-2)}`;
    return (
      <div key={year}>
        <h4 className="mr-b-20">Financial Year {year}</h4>
        <div className="form-group">
          <label> Auditor's Report</label>
          <div className="col-xs-12">
            <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'documents', `auditor_report:${yearVar}`])} onChange={(e) => { this.handelInputChange(['nonprofit', 'documents', `auditor_report:${yearVar}`], e); }} error={this.getInputErrors(['nonprofit', 'documents', `auditor_report:${yearVar}`])} />
          </div>
        </div>
        <div className="form-group">
          <label> Income &amp; Expenditure Report</label>
          <div className="col-xs-12">
            <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'documents', `income_expenditure:${yearVar}`])} onChange={(e) => { this.handelInputChange(['nonprofit', 'documents', `income_expenditure:${yearVar}`], e); }} error={this.getInputErrors(['nonprofit', 'documents', `income_expenditure:${yearVar}`])} />
          </div>
        </div>
        <div className="form-group">
          <label> Balance Sheet</label>
          <div className="col-xs-12">
            <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'documents', `balance_sheet:${yearVar}`])} onChange={(e) => { this.handelInputChange(['nonprofit', 'documents', `balance_sheet:${yearVar}`], e); }} error={this.getInputErrors(['nonprofit', 'documents', `balance_sheet:${yearVar}`])} />
          </div>
        </div>
        <div className="form-group">
          <label> Schedules</label>
          <div>
          <small>Schedules/Annexures/Statement to Balance sheet allowed. If schedules included within Balance Sheet, upload complete financials here. In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence</small>
          </div>
          <div className="col-xs-12">
            <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'documents', `schedule:${yearVar}`])} onChange={(e) => { this.handelInputChange(['nonprofit', 'documents', `schedule:${yearVar}`], e); }} error={this.getInputErrors(['nonprofit', 'documents', `schedule:${yearVar}`])} />
          </div>
        </div>
        <div className="form-group">
          <label> Notes on Accounts</label>
          <div>
          <small>Notes to accounts/notes at the end of Financial Statement/Significant Accounting policies to be accepted. In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence</small>
          </div>
          <div className="col-xs-12">
            <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'documents', `account_notes:${yearVar}`])} onChange={(e) => { this.handelInputChange(['nonprofit', 'documents', `account_notes:${yearVar}`], e); }} error={this.getInputErrors(['nonprofit', 'documents', `account_notes:${yearVar}`])} />
          </div>
        </div>
        <div className="form-group">
          <label> Annual Report</label>
          <div>
          <small>In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence</small>
          </div>
          <div className="col-xs-12">
            <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'documents', `annual_report:${yearVar}`])} onChange={(e) => { this.handelInputChange(['nonprofit', 'documents', `annual_report:${yearVar}`], e); }} error={this.getInputErrors(['nonprofit', 'documents', `annual_report:${yearVar}`])} />
          </div>
        </div>
        <div className="form-group">
          <label> Income Tax Returns</label>
          <div className="col-xs-12">
            <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'documents', `income_tax_return:${yearVar}`])} onChange={(e) => { this.handelInputChange(['nonprofit', 'documents', `income_tax_return:${yearVar}`], e); }} error={this.getInputErrors(['nonprofit', 'documents', `income_tax_return:${yearVar}`])} />
          </div>
        </div>
        <div className="form-group">
          <label>FCRA Returns</label>
          <div>
          <small>In case of unavailability, upload declaration on letterhead with attestation, mentioning the reason for absence</small>
          </div>
          <div className="col-xs-12">
            <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'documents', `fcra_return:${yearVar}`])} onChange={(e) => { this.handelInputChange(['nonprofit', 'documents', `fcra_return:${yearVar}`], e); }} error={this.getInputErrors(['nonprofit', 'documents', `fcra_return:${yearVar}`])} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { children } = this.props;
    const financialYears = Helpers.getFinancialYears();
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">Media &amp; Documents</h3>
                </div>
                <h4 className="mr-b-20">General documents</h4>
                <div className="form-group">
                  <label> Address proof</label>
                  <div>
                  <small>Telephone/Electricity/Wifi bill; Rent/Lease Agreement allowed. In case document in the name of the organisation is not available, add Telephone/Electricity/Wifi bill; Rent/Lease Agreement in the name of the individual/organisation with attested declaration mentioning that the organisation has been given permission to use the said premises. </small>
                  </div>
                  <div className="col-xs-12">
                    <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'address_proof_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'address_proof_doc'], e); }} error={this.getInputErrors(['nonprofit', 'address_proof_doc'])} />
                  </div>
                </div>
                <div className="form-group">
                  <label> High resolution logo vector</label>
                  <div className="col-xs-12">
                    <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'highresolution_image'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'highresolution_image'], e); }} error={this.getInputErrors(['nonprofit', 'highresolution_image'])} />
                  </div>
                </div>
                {this.shouldShowItemOnCompliance('fbassured') ?
                  financialYears.map((year, idx) => (
                    this.financialYearHtmlDocument(year)
                  )) : 
                  this.financialYearHtmlDocument(financialYears[0])
                }
              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default NonprofitDocumentsEdit;
