import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from '../appComponents/Login';
import PrivateRoute from '../appComponents/Login/PrivateRoute';
import Notification from '../appComponents/Notification';
import HomeRoutes from './HomeRoutes';
import FBOnboarding from '../productComponents/fbOnboarding/FBOnboarding';
import FacebookOnboarding from '../productComponents/FacebookOnboarding/FacebookOnboarding'
import LandingPage from '../productComponents/FacebookOnboarding/FacebookOnboardingLanding/LandingPage';
import ErrorBoundary from '../utils/errorBoundaries';
import FundUtlisationCertificate from '../productComponents/FUC/FundUtilisationCertificate';

// import AdminHome from '../productComponents/Admin/AdminHome';
// import AdminOnboardingHome from '../productComponents/AdminApprovals/AdminOnboardingHome';
// import CrowdfundingHome from '../productComponents/Crowdfunding/CrowdfundingHome';
// import DisbursementHome from '../productComponents/Disbursements/DisbursementHome';
// import NGOOnboardingHome from '../productComponents/NGOOnboarding/NGOOnboardingHome';
// import ProfileHome from '../productComponents/Profile/ProfileHome';


// add url for fb-onboarding in privateroute

const Routes = () => (
  <Router>
    <ErrorBoundary>
    <div>
      <Notification />
      <Switch>
        <Route path='/fb-onboarding' component={FBOnboarding} /> 
        <Route path="/login" component={Login} />
        <Route path="/facebook" component={LandingPage} />
        <Route path="/facebook-onboarding" component={FacebookOnboarding} />
        <Route path="/fund-utilisation-certificate" component={FundUtlisationCertificate} />
        <PrivateRoute path="/" component={HomeRoutes} />
       
        {/* <PrivateRoute path="/admin" component={AdminHome} />
        <PrivateRoute path="/onboarding-approval" component={AdminOnboardingHome} />
        <PrivateRoute path="/crowdfunding" component={CrowdfundingHome} />
        <PrivateRoute path="/disbursement" component={DisbursementHome} />
        <PrivateRoute path="/onboarding" component={NGOOnboardingHome} />
        <PrivateRoute path="/profile" component={ProfileHome} /> */}
      </Switch>
    </div>
  </ErrorBoundary>
  </Router>
);

export default Routes;
