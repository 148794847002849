import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Icon from '@mdi/react';
import {
  mdiChevronRight,
  mdiLoading
} from '@mdi/js';

import * as NonProfitActions from '../../../reducers/FacebookOnboarding/onboardingNonProfitItemReducer';
import * as LoginAction from '../../../reducers/loginReducer';

class FbOnboardPAN extends Component {

  constructor(props) {
    super(props);  
    this.state = {
       nonprofit:{},
       isloading: false,
    }
  }

  checkPan = () => {
    const {state, addError, removeError} = this.props
    const pan = state.dataPan.panCard
    const regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if(!regex.test(pan.toUpperCase())) {
      addError('dataPanError', 'panCard', 'Invalid Pan')
      return false
    } else {
      removeError('dataPanError', 'panCard')
      return true
    }
  }  
  
  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.handleSubmit();
    }
  }
  
  handleSubmit = async () => {
    const { onSubmit } = this.props
    if(this.checkPan()){ 
    this.setState({...this.state, isloading: true });
      onSubmit();
    }
  }

  render() {
    const {state, setData} = this.props
    const {dataPan, error} = state
    const {panCard} = dataPan
    const { isloading } = this.state;
    return (
      <div className="container-scroller">
        <div className="card">

          <div className="card-body">
            <h3 className="mr-b-20">Step 1 - Enter PAN to Initiate Onboarding</h3>
            <div className="form-group">
              <label>PAN Card Number</label>
              <input className="form-control" type="text" onKeyDown={this.onKeyDown} placeholder="PAN Card" name="panCard" 
              value={panCard} onChange={(e) => setData(e, 'dataPan')} onBlur={this.checkPan} />
              <span className="field-valid-error">{error.dataPanError.findIndex(o => o.key === 'panCard')>-1 && error.dataPanError[error.dataPanError.findIndex(o => o.key === 'panCard')].value}</span>
            </div>
          </div>

          <span className="save-click-error">{error.dataPanError.findIndex(o => o.key === 'panCard')>-1 && 'Please correct all the errors'}</span>
          <div className="card-body">
            <div className="col-md-12 d-flex align-items-stretch">
              <div className="row flex-grow">
                <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                  {/* <button type="button" className="btn btn-outline-primary mr-2">
                    <Icon path={mdiHelpCircle} className="menu-arrow" size={0.75} /> Help
                  </button> */}
                  <button type="submit" className="btn btn-primary mr-2" onClick={this.handleSubmit}>
                  <Icon path={isloading ? mdiLoading: mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" {...(isloading? {spin: 'spin'}: {})} /> Next
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  nonprofits: state.profileNonprofits,
  login: state.login,
});

const mapActionToProps = dispatch => ({
  Nonprofits: bindActionCreators(NonProfitActions, dispatch), 
  Login: bindActionCreators(LoginAction, dispatch),  
});


export default connect (
  mapStateToProps,
  mapActionToProps,
)(FbOnboardPAN);