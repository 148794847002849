import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import Icon from '@mdi/react';
import {
  mdiLoading,
  mdiChevronRight,
  mdiChevronLeft,
} from '@mdi/js';

import * as SyncActions from '../../../reducers/Disbursements/syncReducer';

import './SyncDonation.scss';

import Loading from '../../../appComponents/Loading';
import Stats from '../Stats';
import SingleFileInput from '../../../components/SingleFileInput/SingleFileInput';
import Helpers from '../../../utils/helpers';

class SyncDonation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pg: [],
      file: {},
      prevGetStatus: {},
    };
  }

  componentDidMount() {
    const { sync, Sync } = this.props;
    if (!sync.get_status.pg) {
      Sync.getPG();
    }
    if (!sync.get_status.stat) {
      Sync.getStats();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.sync.get_status !== state.prevGetStatus && Object.keys(props.sync.get_status).length) {
      setData.prevGetStatus = props.sync.get_status;
      if (!props.sync.get_status.stat) {
        props.Sync.getStats();
      }
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  componentWillUnmount() {
    const { Sync } = this.props;
    Sync.reset();
  }

  changePGList = (e) => {
    e.preventDefault();
    const value = e.target.value.split(',');
    this.setState({ pg: value });
  }

  getPGList = () => {
    const { sync } = this.props;
    const { pg } = this.state;
    if (Object.keys(sync.pg).length > 0) {
      return (
        <select className="form-control" onChange={this.changePGList} value={pg.join(',')}>
          <option disabled hidden value="">Select</option>
          {Object.keys(sync.pg).map(entity => (
            sync.pg[entity].gateway.map(pg => (
              <option key={`${entity}::${pg}`} value={[entity, pg]}>{`${entity}::${pg}`}</option>
            ))
          ))}
        </select>
      );
    }
    return <Loading />;
  }

  handelFileInput = (e) => {
    this.setState({ file: e });
  }

  handelSyncDonations = (e) => {
    const { Sync } = this.props;
    const { file, pg } = this.state;
    e.preventDefault();
    const data = new FormData();
    data.append('entity', pg[0]);
    data.append('gateway', pg[1]);
    data.append('report', file);
    Sync.syncDonations(data);
  }

  handelBack = (e) => {
    const { Sync } = this.props;
    e.preventDefault();
    Sync.syncReset();
  }

  downloadDonationList = (e) => {
    e.preventDefault();
    const { sync } = this.props;
    const fileName = `GiveDisbursement-Sync-Report-${format(new Date(), 'do MMM yyyy-HH:mm:ss')}`;
    const json = [];
    Object.keys(sync.sync_response).forEach((key) => {
      if (key === 'stats') {
        const data = Object.keys(sync.sync_response[key].by_settlement).forEach(settlement => {
          json.push({
            "Type": 'Stats',
            "Settlement Id": settlement,
            "Rows processed": sync.sync_response[key].by_settlement[settlement].row_count,
            "Number of donations processed": sync.sync_response[key].by_settlement[settlement].donation_count,
            "Total amount": sync.sync_response[key].by_settlement[settlement].total_amount,
            "Total fee": sync.sync_response[key].by_settlement[settlement].total_fee,
          });
        });
        json.push({
          "Type": 'Stats',
          "Settlement Id": "Totals",
          "Rows processed": sync.sync_response[key].row_count,
          "Number of donations processed": sync.sync_response[key].donation_count,
          "Total amount": sync.sync_response[key].total_amount,
          "Total fee": sync.sync_response[key].total_fee,
        });
      }
      if (key === 'updates') {
        json.push({
          "Types": '',
        })
        sync.sync_response[key].forEach(i => {
          json.push({
            "Type": 'Updates',
            "Order Id": i.order_id,
            "Message": i.message,
          });
        });
      }
      if (key === 'errors') {
        json.push({
          "Types": '',
        })
        sync.sync_response[key].forEach(i => {
          json.push({
            "Type": 'Errors',
            "Order Id": i.order_id,
            "Message": i.message,
          });
        });
      }
    });
    Helpers.convertJSONtoCSV(json, fileName);
  }

  instructionsHTML = (pg) => {
    console.log(pg);
    if (pg[1] === 'NEFT') {
      return (
        <div>To sync NEFT transfer <a href="/Direct Bank Transfers_Donations to be tagged .xlsx" target="_blank">Download Excel file</a>
        </div>
      );
    }
    if (pg[1] === 'Stripe') {
      return (
        <div className="stripe-instructions">
          <p>We have shifted to Stripe Payout reports for syncing settlement data into our platform. To that end, we need to update the instructions on the disbursement panel for Stripe US and UK.</p>
            <ul>
              <li>Go to <a href="https://dashboard.stripe.com/reports/reconciliation">https://dashboard.stripe.com/reports/reconciliation</a></li>
              <li>Select date after last sync till today</li>
              <li>Click Download for <b>Payout reconciliation</b> report</li>
              <li>Select the following options in the popup</li>
              <li>Report format - Itemized</li>
              <li>Reporting category - All</li>
              <li>Columns - automatic payout id, balance transaction id, currency, gross, fee, net, reporting category, source id, customer facing amount, customer facing currency, charge id, transactionId</li>
              <li>Click Download to download the report and sync it</li>
              <li>Please note
                <ul>
                  <li>Files should only be synced in chronological order i.e you cannot sync Apr report after May</li>
                  <li>Report once synced should not be synced again</li>
                </ul>
              </li>
            </ul>
        </div>
      );
    }
    if (pg[1] === 'Razorpay') {
      return (
        <div>
          <div>
            <iframe style={{ display: 'flex', margin: 'auto' }} width="300" src="https://www.youtube.com/embed/YUiLjbfNhQI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div style={{ margin: '20px 0' }}>Watch the video to see, how to download settlement report</div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { sync } = this.props;
    const { pg } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <Stats value={sync.stats} loading={sync.get_status.stat === 'pending'} />
          {(Object.keys(sync.sync_response).length > 0) ? (
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4>Sync donations</h4>
                  <div className="row justify-content-end">
                    <div className="col-md-12 col-12 text-right">
                      <button type="button" className="btn btn-secondary" onClick={this.downloadDonationList}>
                        Download sync report
                      </button>
                      <div>
                        <i><small className="text-muted">CSV file contains max of 1000 entries</small></i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="mt-3 text-center">Stats</h5>
                      <table className="table table-bordered table-info">
                        <thead>
                          <tr>
                            <th> Settlement Id </th>
                            <th> Rows processed </th>
                            <th> Number of donations processed </th>
                            <th> Total amount </th>
                            <th> Total fee </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(sync.sync_response.stats?.by_settlement || {}).map((i, index) => (
                            <tr key={index}>
                              <td> {i} </td>
                              <td> {sync.sync_response.stats?.by_settlement[i].row_count?.toLocaleString('en-in')} </td>
                              <td> {sync.sync_response.stats?.by_settlement[i].donation_count?.toLocaleString('en-in')} </td>
                              <td> {sync.sync_response.stats?.by_settlement[i].total_amount?.toLocaleString('en-in')} </td>
                              <td> {sync.sync_response.stats?.by_settlement[i].total_fee?.toLocaleString('en-in')} </td>
                            </tr>
                          ))}
                          <tr>
                            <td> Totals </td>
                            <td> {sync.sync_response.stats.row_count?.toLocaleString('en-in')} </td>
                            <td> {sync.sync_response.stats.donation_count?.toLocaleString('en-in')} </td>
                            <td> {sync.sync_response.stats.total_amount?.toLocaleString('en-in')} </td>
                            <td> {sync.sync_response.stats.total_fee?.toLocaleString('en-in')} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-12">
                      <h5 className="mt-4 text-center">Successfully updated {sync.sync_response.updates.length} donations</h5>
                      {sync.sync_response.updates.length ? (
                        <table className="table table-bordered table-success">
                          <thead>
                            <tr>
                              <th> Order ID </th>
                              <th> Message </th>
                            </tr>
                          </thead>
                          <tbody>
                            {sync.sync_response.updates.map((item, index) => (
                              <tr key={item.order_id + index}>
                                <td> {item.order_id} </td>
                                <td> {item.message} </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ): null}
                    </div>
                    <div className="col-md-12">
                      <h5 className="mt-4 text-center">Problem in {sync.sync_response.errors.length} donations</h5>
                      {sync.sync_response.errors.length ? (
                        <table className="table table-bordered table-danger">
                          <thead>
                            <tr>
                              <th> Order ID </th>
                              <th> Message </th>
                            </tr>
                          </thead>
                          <tbody>
                            {sync.sync_response.errors.map((item, index) => (
                              <tr key={item.order_id + index}>
                                <td> {item.order_id} </td>
                                <td> {item.message} </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ): null}
                    </div>
                  </div>
                  <form className="sync-wrapper mt-4">
                    <a href="#back" onClick={this.handelBack}><Icon path={mdiChevronLeft} className="menu-icon" size={0.75} color="#979797" /> Back</a>
                  </form>
                </div>
              </div>
            </div>
          ) : (
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <h4>Sync donations</h4>
                    <form className="sync-wrapper" onSubmit={this.handelSyncDonations}>
                      {this.getPGList()}
                      {this.instructionsHTML(pg)}
                      <SingleFileInput onChange={this.handelFileInput} accept=".csv,.xlsx" />
                      <button type="submit" className="btn btn-primary btn-fw">
                        {(sync.get_status.sync === 'pending') ? (
                          <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                        ) : (
                            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                          )}
                        &nbsp;Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  sync: state.disbursementsSync,
});

const mapActionToProps = dispatch => ({
  Sync: bindActionCreators(SyncActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(SyncDonation);
