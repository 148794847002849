import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';
import ProjectService from '../../services/project-service';

export const GET_NONPROFIT = 'GET_NONPROFIT';
export const GET_NONPROFIT_SUCCESS = 'GET_NONPROFIT_SUCCESS';
export const GET_NONPROFIT_FAIL = 'GET_NONPROFIT_FAIL';
export const SET_NONPROFIT = 'SET_NONPROFIT';
export const SET_NONPROFIT_SUCCESS = 'SET_NONPROFIT_SUCCESS';
export const SET_NONPROFIT_FAIL = 'SET_NONPROFIT_FAIL';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_PROJECT_SUCCESS = 'SET_PROJECT_SUCCESS';
export const SET_PROJECT_FAIL = 'SET_PROJECT_FAIL';
export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

// Constants created for JSON Schema fetch for form 
export const GET_SCHEMA = 'GET_SCHEMA'
export const GET_SCHEMA_SUCCESS = 'GET_SCHEMA_SUCCESS'
export const GET_SCHEMA_FAIL = 'GET_SCHEMA_FAIL'

const initialState = {
  nonprofit: {},
  project: {},
  get_status: {},
  set_status: {},
  errors: {},  
  formSchema: {}, // Variable to store schema
};

export const homeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_NONPROFIT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case GET_NONPROFIT_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
      };
    case GET_NONPROFIT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
      };
    case SET_NONPROFIT:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'pending',
        },
        errors: {
          ...state.errors,
          nonprofit: {},
        },
      };

    case SET_NONPROFIT_SUCCESS:      
      return {
        ...state,
        nonprofit: action.nonprofit,
        set_status: {
          ...state.set_status,
          nonprofit: 'success',
        },
      };
    case SET_NONPROFIT_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          nonprofit: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors,
        },
      };
    case GET_PROJECT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'pending',
        },
        errors: {
          ...state.errors,
          project: {},
        },
      };

    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        get_status: {
          ...state.get_status,
          project: 'success',
        },
      };
    case GET_PROJECT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'failed',
        },
      };
    case SET_PROJECT:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'pending',
        },
        errors: {
          ...state.errors,
          project: {},
        },
      };
    case SET_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.project,
        set_status: {
          ...state.set_status,
          project: 'success',
        },
      };
    case SET_PROJECT_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'failed',
        },
        errors: {
          ...state.errors,
          project: action.errors,
        },
      };
    case GET_SCHEMA:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'pending',          
        },
        schema: {},        
      }
    case GET_SCHEMA_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'success',          
        },
        schema: action.schema,
        errors: {
          ...state.errors,
          schema: action.errors
        }
      }
    case GET_SCHEMA_FAIL:      
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'failed',
        },
        // schema: schema, //TODO: Update this to an error, should not be using files from the repo. 
        errors: {
          ...state.errors,
          schema: action.errors
        }
      }
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };    
    default:
      return state;
  }
};

export function getNonprofit(id = false) {
  return (dispatch) => {
    dispatch({
      type: GET_NONPROFIT,
    });
    return NonProfitService.get()
      .then((response) => {
        const nonprofits = response.filter((item) => {
          // TOOO: Don't do the check. Removed Status Check
          if (item.status === 'pending' || item.status === 'document_check' || item.status === 'content_check' || item.status === 'cause_check' || item.status === 'final_approval' || item.status === 'published') {
            return true;
          }
          return false;
        });        
        if (nonprofits.length === 0) {
          dispatch({
            type: GET_NONPROFIT_SUCCESS,
            nonprofit: {}, // Initiate new nonprofit
          });
        } else {          
          dispatch({
            type: GET_NONPROFIT_SUCCESS,
            nonprofit: id ? nonprofits.filter(item => item._id === id)[0] : nonprofits[0],
          });
        }
        return nonprofits;
      })
      .catch((error) => {
        dispatch({
          type: GET_NONPROFIT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function setNonprofit(data) {
  return (dispatch) => {
    dispatch({
      type: SET_NONPROFIT,
    });
    return NonProfitService.set({ data, method: 'put', path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_NONPROFIT_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_NONPROFIT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
        } else {
          dispatch({
            type: SET_NONPROFIT_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error
      });
  };
}

export function createNonprofit(data) {
  return (dispatch) => {
    dispatch({
      type: SET_NONPROFIT,
    });
    return NonProfitService.create({ data, method: 'post' })
      .then((response) => {
        dispatch({
          type: SET_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: SET_NONPROFIT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getProject(request = false) {
  return (dispatch) => {
    dispatch({
      type: GET_PROJECT,
    });
    return ProjectService.get(request ? request : undefined)
      .then((response) => {
        const projects = response.filter((item) => {
          if (item.status === 'pending' || item.status === 'final_approval') {
            return true;
          }
          return false;
        });
        if (projects.length === 0) {
          dispatch({
            type: GET_PROJECT_SUCCESS,
            project: {}, // Initiate new project
          });
        } else {
          dispatch({
            type: GET_PROJECT_SUCCESS,
            project: projects[0],
          });
        }
        return projects;
      })
      .catch((error) => {
        dispatch({
          type: GET_PROJECT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function setProject(data, show_notification = true) {
  return (dispatch) => {
    dispatch({
      type: SET_PROJECT,
    });
    return ProjectService.set({ data, method: 'put', path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_PROJECT_SUCCESS,
          project: response,
        });
        if(show_notification) dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_PROJECT_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Project form'));
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_PROJECT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
        } else {
          dispatch({
            type: SET_PROJECT_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function createProject(request) {
  const data = request;
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROJECT,
    });
    const { nonprofit } = getState().ngoOnboardingHome;
    if (nonprofit && nonprofit._id) {
      data.nonprofit = nonprofit._id;
    } else {
      dispatch(NotificationAction.addNotification('Please save Non-profit before saving Project'));
      return Promise.reject(new _Error());
    }
    return ProjectService.create({ data, method: 'post' })
      .then((response) => {
        dispatch({
          type: SET_PROJECT_SUCCESS,
          project: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: SET_PROJECT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

// Method to fetch the FORM Schema from the Core Service API
export function getFormSchema(request){
  const data = request
  return (dispatch) => {
    dispatch({
      type: GET_SCHEMA
    })
    return NonProfitService.getSchema(request)
      .then((response) => {      
        dispatch({
          type: GET_SCHEMA_SUCCESS,          
          schema: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_SCHEMA_FAIL,
          errors: error
        })
        dispatch(NotificationAction.addNotification(error.message));
      })              
  }
}
