import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import ProjectService from '../../services/project-service';

export const GET_PROFILE_PROJECT_ITEM = 'GET_PROFILE_PROJECT_ITEM';
export const GET_PROFILE_PROJECT_ITEM_SUCCESS = 'GET_PROFILE_PROJECT_ITEM_SUCCESS';
export const GET_PROFILE_PROJECT_ITEM_FAIL = 'GET_PROFILE_PROJECT_ITEM_FAIL';

export const SET_PROFILE_PROJECT_ITEM = 'SET_PROFILE_PROJECT_ITEM';
export const SET_PROFILE_PROJECT_ITEM_SUCCESS = 'SET_PROFILE_PROJECT_ITEM_SUCCESS';
export const SET_PROFILE_PROJECT_ITEM_FAIL = 'SET_PROFILE_PROJECT_ITEM_FAIL';

export const PROFILE_PROJECT_ITEM_RESET = 'PROFILE_PROJECT_ITEM_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  project: {},
  get_status: {},
  set_status: {},
  errors: {},
};

export const projectItemReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PROFILE_PROJECT_ITEM:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'pending',
        },
      };
    case GET_PROFILE_PROJECT_ITEM_SUCCESS:
      return {
        ...state,
        project: action.project,
        get_status: {
          ...state.get_status,
          project: 'success',
        },
      };
    case GET_PROFILE_PROJECT_ITEM_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          project: 'failed',
        },
      };
    case SET_PROFILE_PROJECT_ITEM:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'pending',
        },
        errors: {
          ...state.errors,
          project: {},
        },
      };
    case SET_PROFILE_PROJECT_ITEM_SUCCESS:
      return {
        ...state,
        project: action.project,
        set_status: {
          ...state.set_status,
          project: 'success',
        },
      };
    case SET_PROFILE_PROJECT_ITEM_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          project: 'failed',
        },
        errors: {
          ...state.errors,
          project: action.errors,
        },
      };
    case PROFILE_PROJECT_ITEM_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getProject(slug) {
  return (dispatch) => {
    dispatch({
      type: GET_PROFILE_PROJECT_ITEM,
    });
    return ProjectService.get({ path: [slug] })
      .then((response) => {
        dispatch({
          type: GET_PROFILE_PROJECT_ITEM_SUCCESS,
          project: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_PROFILE_PROJECT_ITEM_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
        return error;
      });
  };
}

export function setProject(data) {
  return (dispatch) => {
    dispatch({
      type: SET_PROFILE_PROJECT_ITEM,
    });
    return ProjectService.set({ data, method: 'put', path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_PROFILE_PROJECT_ITEM_SUCCESS,
          project: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_PROFILE_PROJECT_ITEM_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Project form'));
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_PROFILE_PROJECT_ITEM_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
        } else {
          dispatch({
            type: SET_PROFILE_PROJECT_ITEM_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function createProject(data) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PROFILE_PROJECT_ITEM,
    });
    return ProjectService.create({ data, method: 'post' })
      .then((response) => {
        dispatch({
          type: SET_PROFILE_PROJECT_ITEM_SUCCESS,
          project: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch({
          type: SET_PROFILE_PROJECT_ITEM_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: PROFILE_PROJECT_ITEM_RESET,
    });
  };
}
