import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import FormInput from '../Form/FormInput'

import Helpers from '../../utils/helpers';

const RepeatingFields = (props) => {

  const [ element, setElement ] = useState({})
  const [ displayElements, setDisplayElements ] = useState([])
  const [ repeaterValue, setRepeaterValue ] = useState(0)
  
  const [ changedValueKeys, setChangedValueKeys ] = useState([])

  const { isReadMode, formData } = props

  const getValue = (e) => {
    return props.getValue(e);
  }

  const onChange = (e) => {    
    if(!e.isInvisible){
      setChangedValueKeys([...changedValueKeys, e.name])
    }    
    props.formChangeHandler(e)
  }

  useEffect(() => {
    if(props && props.element && !Helpers.isEqual(props.element, element)){
      setElement(props.element)
    }    
  }, [props])

  useEffect(() => {    
    if(props.formData && element && element.repeaton){
      let repeaterValue = Helpers.getObjectfromPath(props.formData, element.repeaton)            
      setRepeaterValue(repeaterValue)
    }
  }, [props.formData, element])

  useEffect(() => {        
    if(element && element.repeaton){
      let elements = []          
      let r = repeaterValue     
      // Checking for repeater value and setting a value if nothing found 
      if(r === undefined){        
        const valueAtKey = Helpers.getObjectfromPath(props.formData, element.key)        
        if(valueAtKey){
          r = valueAtKey.length          
          if(element.startIndex){
            r -= element.startIndex
          }
        }
        else{          
          r = 1
        }
        setRepeaterValue(r)        
        if(isReadMode !== true){
          onChange({
            name: element.repeaton,
            value: r
          })
        }
      }
      else if(typeof r === 'object' && r.length !== 0){
        r = r.length        
      }

 
      for(let repeater = 0; repeater < r; repeater++){  
        
        elements.push((
          <div 
            key={`section.${repeater}`} 
            className='col-lg-12 col-sm-12 col-md-12 col'
          >
            <FormInput
              item={{
                element:'section', 
                title: element.title ? (element.title.replace('{{repeatKey}}', repeater + 1)) : ''
              }}
            />
          </div>
        ))
        element.repeat.map((formElement, i) => {
          const item = Helpers.cloneObject(formElement)  ;        
          item.key = item.key.replace('{{index}}', repeater + (element.startIndex ? element.startIndex : 0))

          elements.push((
            <div 
              key={`array.${item.key}.${i}.${repeater}`} 
              className={item.is_column? 'col-sm-12 col-lg-6 col-md-6 col col-xs-12' : 'col-lg-12 col-sm-12 col-md-12 col'}
            >
              <FormInput 
                item={item}
                onChange={onChange}                          
                value={getValue(item.key)}
                isRequiredError={props.requiredFields.length > 0 && props.requiredFields.indexOf(item.key) >= 0 ? item.key : false}
                isValidationError={Object.keys(props.errors).length > 0 && Object.keys(props.errors).indexOf(item.key) >= 0 ? {[item.key]: props.errors[item.key]} : false}              
                isReadMode={isReadMode}
                formData={formData}
              />
            </div>
          ))        
        })                              
        elements.push((
          <div key={repeater} className='col-lg-12 col-sm-12 col-md-12 col'>
            <FormInput 
              item = {{
                ...element,
                element: 'decrement-button', 
                title: 'Remove', 
                key: element.repeaton
              }}
              value={repeaterValue}
              onChange={onChange}
              index={repeater + 1}
              controlArrayKey={element.key}
              isReadMode={isReadMode}
              formData={formData}              
            />
          </div>
        ))
      }
      
      setDisplayElements(elements)      
    }
  }, [element, repeaterValue, isReadMode, changedValueKeys])
  

  return displayElements

}

export default RepeatingFields