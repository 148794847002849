import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronRight, mdiLoading } from '@mdi/js';

import * as SettledActions from '../../../reducers/Disbursements/settledReducer';
import Loading from '../../../appComponents/Loading';
import Failed from '../../../appComponents/Failed';
import { downloadPdf } from '../../../utils/helpers';
import Stats from '../Stats';

class Settled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      prevGetStatus: {},
      q: '',
    };
  }

  componentDidMount() {
    const { settled, Settled } = this.props;
    if (!settled.get_status.stat) {
      Settled.getStats();
    }
    if (!settled.get_status.disbursement || settled.get_status.disbursement !== 'pending') {
      Settled.getDisbursements();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.settled.get_status !== state.prevGetStatus && Object.keys(props.settled.get_status).length) {
      setData.prevGetStatus = props.settled.get_status;
      if (!props.settled.get_status.stat) {
        props.Settled.getStats();
      }
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  componentWillUnmount() {
    const { Settled } = this.props;
    Settled.reset();
  }

  handelQ = (e) => {
    e.preventDefault();
    this.setState({ q: e.target.value });
  }

  resendDisbursementReport = (disbursementNo) => {
    const { Settled } = this.props;
    Settled.resendDisbursementReport(disbursementNo);
  }

  downloadSettledBatch = (batchNo) => {
    const { Settled } = this.props;
    Settled.downloadSettledBatch(batchNo).then(response => {
      if(response && response.report_uri) {
        downloadPdf(response.report_uri);        
      }
    });
  }

  actionsHTML = (batchNo) => {
    const { settled } = this.props;
    return (
      <div className="col-md-12 table-col tl-btn-group">
        <button type="button" className="btn btn-primary" onClick={(e) => { e.preventDefault(); this.downloadSettledBatch(batchNo); }}>
          {(settled.get_status.download === 'pending') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          )}
          &nbsp;Download
        </button>
      </div>
    );
  }

  ngoHTML = (disbursements) => {
    const { settled } = this.props;
    return (
      <div className="col-md-12 table-col table-list-detail">
        <ul>
          <li className="table-head">
            <div className="row">
              <div className="col-md-3 col-12 table-col dl-mob">
                NGO Name
              </div>
              <div className="col-md-2 col-12 table-col dl-mob">
                Disbursement No
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                Donation count
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                From
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                To
              </div>
              <div className="col-md-1 col-12 table-col dl-mob">
                Settled at
              </div>
              <div className="col-md-1 col-12 table-col dl-mob txt-right">
                Amount
              </div>
              <div className="col-md-2 col-12 table-col dl-mob txt-right">
                Actions
              </div>
            </div>
          </li>
          {disbursements.map(item => (
            <li className="table-list-item" key={item.disbursement_number}>
              <div className="row">
                <div className="col-md-3 col-12 table-col dl-mob">
                  {item.nonprofit.name}
                </div>
                <div className="col-md-2 col-12 table-col dl-mob">
                  {item.disbursement_number}
                </div>
                <div className="col-md-1 col-12 table-col dl-mob">
                  {item.donation_count}
                </div>
                <div className="col-md-1 col-12 table-col dl-mob">
                  {item.from_date}
                </div>
                <div className="col-md-1 col-12 table-col dl-mob">
                  {item.to_date}
                </div>
                <div className="col-md-1 col-12 table-col dl-mob">
                  {item.settled_at}
                </div>
                <div className="col-md-1 col-12 table-col dl-mob txt-right">
                  {`${item.currency_code} ${item.payable_amount}`}
                </div>
                <div className="col-md-2 col-12 table-col dl-mob txt-right">
                  <button className="btn btn-success btn-sm" onClick={(e) => { e.preventDefault(); this.resendDisbursementReport(item.disbursement_number); }}>
                    {(settled.get_status['send_report_' + item.disbursement_number] === 'pending') ? (
                      <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                    ) : (
                      <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                    )}
                    &nbsp;Resend report
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  toggleDetails = (batchNo) => {
    const { details } = this.state;
    if (details === batchNo) {
      this.setState({ details: null });
    } else {
      this.setState({ details: batchNo });
    }
  }

  searchQinDisbursements = (data) => {
    const { q } = this.state;
    if (q === '') {
      return data;
    }
    return data.filter(p => {
      let parentBool = false;
      p.disbursements.forEach(c => {
        const search = q.toLowerCase().split(' ');
        let childBool = true;
        search.forEach(cc => {
          if (`${c.disbursement_number}${c.nonprofit.name}${c.nonprofit._id}${c.currency_code}${c.donated_amount}${c.captured_amount}${c.payable_amount}${c.to_date}${c.from_date}${c.settled_at}`.toLowerCase().indexOf(cc) > -1) {
            childBool = childBool && true;
          } else {
            childBool = childBool && false;
          }
        });
        if(childBool === true)
        parentBool = childBool || parentBool;
      });
      return parentBool;
    }).map(p => {
      let d = { ...p };
      d.disbursements = d.disbursements.slice(0).filter(c => {
        const search = q.toLowerCase().split(' ');
        let returnBool = true;
        search.forEach(cc => {
          if (`${c.disbursement_number}${c.nonprofit.name}${c.nonprofit._id}${c.currency_code}${c.donated_amount}${c.captured_amount}${c.payable_amount}${c.to_date}${c.from_date}${c.settled_at}`.toLowerCase().indexOf(cc) > -1) {
            returnBool = returnBool && true;
          } else {
            returnBool = returnBool && false;
          }
        });
        return returnBool;
      });
      return d;
    });
  }

  render() {
    const { settled } = this.props;
    const { details, q } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <Stats value={settled.stats} loading={settled.get_status.stat === 'pending'} />
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="row justify-content-end">
                  <div className="col-md-6 col-12">
                    <div className="form-group d-flex search-field">
                      <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search Here" value={q} onChange={this.handelQ} />
                      </div>
                      <button type="submit" className="btn btn-primary ml-3">Search</button>
                    </div>
                  </div>
                </div>
                <div className="table-tab-wrapper">
                  <ul className="table-tab">
                    <li className="active">Settled disbursement</li>
                  </ul>
                </div>
                <div className="table-list">
                  <ul>
                    <li className="table-head">
                      <div className="row">
                        <div className="col-md-12 table-col">
                          <div className="row">
                            <div className="col-md-2 table-col dl-mob">Disbursement Batch No</div>
                            <div className="col-md-2 table-col dl-mob">Disbursement range</div>
                            <div className="col-md-2 table-col dl-mob">Disbursement Date</div>
                            <div className="col-md-2 table-col dl-mob">Number of NGOs</div>
                            <div className="col-md-2 table-col dl-mob">Entity</div>
                            <div className="col-md-2 table-col dl-mob-right txt-right">Amount</div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                    {(!settled.get_status.disbursement || settled.get_status.disbursement === 'pending') ? (<Loading />) : null}
                    {(settled.get_status.disbursement === 'failed') ? (<Failed />) : null}
                    </li>
                    {this.searchQinDisbursements(settled.disbursements).map(item => (
                      <li className="table-list-item" key={item._id}>
                        <div className="row">
                          <div className="col-md-12 table-col">
                            <div className="row">
                              <div className="col-md-2 col-12 table-col tfirst-col dl-mob">
                                <strong className="show-mobile">Disbursement Batch No</strong>
                                {details ? (
                                  <Icon path={mdiChevronDown} className="menu-icon" size={0.75} color="#979797" />
                                ) : (
                                  <Icon path={mdiChevronRight} className="menu-icon" size={0.75} color="#979797" />
                                )}
                                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item._id}</span></a>
                              </div>
                              <div className="col-md-2 col-12 table-col dl-mob">
                                <strong className="show-mobile">Disbursement range</strong>
                                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{`${item.from_date} - ${item.to_date}`}</span></a>
                              </div>
                              <div className="col-md-2 col-12 table-col dl-mob">
                                <strong className="show-mobile">Disbursement Date</strong>
                                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item.created_at}</span></a>
                              </div>
                              <div className="col-md-2 col-12 table-col dl-mob">
                                <strong className="show-mobile">NGOs</strong>
                                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item.disbursements.length}</span></a>
                              </div>
                              <div className="col-md-2 col-12 table-col dl-mob">
                                <strong className="show-mobile">Entity</strong>
                                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{item.payment_entity}</span></a>
                              </div>
                              <div className="col-md-2 col-12 table-col dl-mob txt-right">
                                <strong className="show-mobile">Amount</strong>
                                <a href="#list" onClick={(e) => { e.preventDefault(); this.toggleDetails(item._id) }}><span>{`${item.currency_code} ${item.payable_amount}`}</span></a>
                              </div>
                              {(details === item._id) ? this.actionsHTML(item._id) : null}
                              {(details === item._id || q !== '') ? this.ngoHTML(item.disbursements) : null}
                              <div className="show-mobile">
                                {this.actionsHTML(item._id)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  settled: state.disbursementsSettled,
});

const mapActionToProps = dispatch => ({
  Settled: bindActionCreators(SettledActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Settled);
