import React, { Component } from 'react';

import Helpers from '../../utils/helpers';
import PhoneNumberInput from '../PhoneInput';
import SingleFileUpload from '../SingleFileUpload/SingleFileUpload';

class NonprofitPointOfContactEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changes: {_id: props.value._id},
    };
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit } = this.props;
    const { changes } = this.state;
    e.preventDefault();
    if (onSubmit) {
      onSubmit(changes);
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { value } = this.props;
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  render() {
    const { children, config } = this.props;
    const { country_code } = config.defaults || {};
    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                  <div className="heading-bar">
                    <h3 className="mr-b-20">Point of contact</h3>
                  </div>
                  <h6> CEO/Head of Organisation Contact</h6>
                  <div>
                  <small>Complete name as it appears on PAN Card</small>
                  </div>
                  <div className="row mr-t-5 tm-divider">
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        {this.getInputErrors(['nonprofit', 'points_of_contact', 'ceo', 'name'])}
                        <input type="text" className="form-control" placeholder="Name" value={this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'ceo', 'name'], e); }} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group">
                        {this.getInputErrors(['nonprofit', 'points_of_contact', 'ceo', 'email'])}
                        <input type="" className="form-control" placeholder="Email id" value={this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'email'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'ceo', 'email'], e); }} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                      <div className="form-group">
                        {this.getInputErrors(['nonprofit', 'points_of_contact', 'ceo', 'phone'])}
                        <PhoneNumberInput countrycode={country_code} value={this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'phone'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'ceo', 'phone'], e); }} />
                      </div>
                    </div>
                    {( this.shouldShowItemOnCompliance('fbassured') || this.shouldShowItemOnCompliance('giveassured')) ? (
                    <>
                      <div className="col-md-3">
                        <div className="form-group">
                          {this.getInputErrors(['nonprofit', 'points_of_contact', 'ceo', 'title'])}
                          <input type="text" className="form-control" placeholder="Designation" value={this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'title'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'ceo', 'title'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          {this.getInputErrors(['nonprofit', 'points_of_contact', 'ceo', 'date_of_birth'])}
                          <input type="text" className="form-control" placeholder="Date of Birth(DD/MM/yyyy)" value={this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'date_of_birth'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'ceo', 'date_of_birth'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          {this.getInputErrors(['nonprofit', 'points_of_contact', 'ceo', 'id'])}
                          <input type="text" className="form-control" placeholder="PAN number" value={this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'id'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'ceo', 'id'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          {this.getInputErrors(['nonprofit', 'points_of_contact', 'ceo', 'id_doc'])}
                          <SingleFileUpload label="" value={this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'id_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact',  'ceo', 'id_doc'], e); }} error={this.getInputErrors(['nonprofit', 'points_of_contact', 'ceo', 'id_doc'])} />
                        </div>
                      </div>
                    </> ) : null }
                  </div>
                  { this.shouldShowItemOnCompliance('giveassured') ? (
                    <>
                      <h6> First Point of Contact</h6>
                      <small>First point of contact for all matters</small>
                      <div className="row mr-t-5 tm-divider">
                        <div className="col-lg-4 col-md-12">
                          <div className="form-group">
                            {this.getInputErrors(['nonprofit', 'points_of_contact', 'first_poc', 'name'])}
                            <input type="text" className="form-control" placeholder="Name" value={this.getInputData(['nonprofit', 'points_of_contact', 'first_poc', 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'first_poc', 'name'], e); }} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                          <div className="form-group">
                            {this.getInputErrors(['nonprofit', 'points_of_contact', 'first_poc', 'email'])}
                            <input type="" className="form-control" placeholder="Email id" value={this.getInputData(['nonprofit', 'points_of_contact', 'first_poc', 'email'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'first_poc', 'email'], e); }} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                          <div className="form-group">
                            {this.getInputErrors(['nonprofit', 'points_of_contact', 'first_poc', 'phone'])}
                            <PhoneNumberInput countrycode={country_code} value={this.getInputData(['nonprofit', 'points_of_contact', 'first_poc', 'phone'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'first_poc', 'phone'], e); }} />
                          </div>
                        </div>
                      </div>

                      <h6>Reporting Point of Contact</h6>                      
                      <div className="row mr-t-5 tm-divider">
                        <div className="col-lg-4 col-md-12">
                          <div className="form-group">
                            {this.getInputErrors(['nonprofit', 'points_of_contact', 'reporting', 'name'])}
                            <input type="text" className="form-control" placeholder="Name" value={this.getInputData(['nonprofit', 'points_of_contact', 'reporting', 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'reporting', 'name'], e); }} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                          <div className="form-group">
                            {this.getInputErrors(['nonprofit', 'points_of_contact', 'reporting', 'email'])}
                            <input type="" className="form-control" placeholder="Email id" value={this.getInputData(['nonprofit', 'points_of_contact', 'reporting', 'email'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'reporting', 'email'], e); }} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                          <div className="form-group">
                            {this.getInputErrors(['nonprofit', 'points_of_contact', 'reporting', 'phone'])}
                            <PhoneNumberInput countrycode={country_code} value={this.getInputData(['nonprofit', 'points_of_contact', 'reporting', 'phone'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'reporting', 'phone'], e); }} />
                          </div>
                        </div>
                      </div>

                      <h6>Accounts Point of Contact</h6>                      
                      <div className="row mr-t-5 tm-divider">
                        <div className="col-lg-4 col-md-12">
                          <div className="form-group">
                            {this.getInputErrors(['nonprofit', 'points_of_contact', 'accounts', 'name'])}
                            <input type="text" className="form-control" placeholder="Name" value={this.getInputData(['nonprofit', 'points_of_contact', 'accounts', 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'accounts', 'name'], e); }} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                          <div className="form-group">
                            {this.getInputErrors(['nonprofit', 'points_of_contact', 'accounts', 'email'])}
                            <input type="" className="form-control" placeholder="Email id" value={this.getInputData(['nonprofit', 'points_of_contact', 'accounts', 'email'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'accounts', 'email'], e); }} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                          <div className="form-group">
                            {this.getInputErrors(['nonprofit', 'points_of_contact', 'accounts', 'phone'])}
                            <PhoneNumberInput countrycode={country_code} value={this.getInputData(['nonprofit', 'points_of_contact', 'accounts', 'phone'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'points_of_contact', 'accounts', 'phone'], e); }} />
                          </div>
                        </div>
                      </div>
                    </>
                    ): null
                  }                  
              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default NonprofitPointOfContactEdit;
