/**
 * Service function to make API call
 */
import memoize from 'memoize-one';

import API from '../utils/api';
import { endpoints } from '../config';


export default class CausesService {
  static requestAPI(endpoint, request = {}) {
    return API.request(endpoint, request);
  }

  static cacheGet = memoize(this.requestAPI);

  static getCauses(request) {
    return this.cacheGet(endpoints.causes, request);
  }
  static getDemographics(request) {
    return this.cacheGet(endpoints.demographics, request);
  }
}
