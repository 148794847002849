import React from "react";
import Icon from "@mdi/react";
import { mdiTelevision } from "@mdi/js";

import Dashboard from "../../productComponents/Crowdfunding/Dashboard";
import NonprofitDonations from "../../productComponents/Crowdfunding/NonprofitDonationsList/NonprofitDonations";
import LiveDonations from "../../productComponents/Crowdfunding/LiveDonations";
import DisbursedDonations from "../../productComponents/Crowdfunding/DisbursedDonations";
import DisbursementList from "../../productComponents/Crowdfunding/DisbursementList";
import Campaigns from "../../productComponents/Crowdfunding/Campaigns";
import CreateBulkCampaign from "../../productComponents/Crowdfunding/CreateBulkCampaign";

const routes = {
  title: "Crowdfunding",
  path: "/crowdfunding",
  default_path: "/crowdfunding/:nonprofit/dashboard",
  for_user: "user",
  sidebar: [
    {
      title: "Dashboard",
      path: "/crowdfunding/:nonprofit/dashboard",
      icon: <Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />,
      component: Dashboard,
    },
    {
      title: "Donation",
      icon: <Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />,
      child: [
        { title: "All", path: "/crowdfunding/:nonprofit/all", component: NonprofitDonations },
        {
          title: "Live",
          path: "/crowdfunding/:nonprofit/live",
          component: LiveDonations,
        },
        {
          title: "Disbursed",
          path: "/crowdfunding/:nonprofit/disbursed",
          component: DisbursedDonations,
        },
      ],
    },
    {
      title: "Disbursement",
      path: "/crowdfunding/:nonprofit/disbursement",
      icon: <Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />,
      component: DisbursementList,
    },
    {
      title: "Supporters",
      icon: <Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />,
      child: [
        {
          title: "Fundraiser Owner",
          path: "/crowdfunding/:nonprofit/campaigns",
          component: Campaigns,
        },
      ],
    },
    {
      title: "Bulk Task",
      icon: <Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />,
      child: [
        {
          title: "Create Bulk Fundraisers",
          path: "/crowdfunding/:nonprofit/create-bulk-campaigns",
          component: CreateBulkCampaign,
        },
      ],
    },
  ],
};

export default routes;
