import React from 'react';
import { formatDistanceToNow, format } from 'date-fns';

import { Link } from 'react-router-dom';

import './NonprofitTile.scss';
import Nonprofits10bdbeDownloaded from './components/Nonprofits10bdbeDownloaded';
import Nonprofits10bdbePending from './components/Nonprofits10bdbePending';
import Nonprofits10bdbeApproved from './components/Nonprofits10bdbeApproved';

function NonprofitTile(props) {
  const { name, members, address, created_at, updated_at, _id, status: nonprofitStatus } = props.value;
  const { status: nonprofit10bdbeStatus } = props.query || ''
  const { status, link, compliances, is10BdBe ,handleApprove} = props;
  let linkStatus = status;
  if (nonprofitStatus === 'published' || !status) {
    linkStatus = 'preview';
  }
  return (
    is10BdBe ?
      <>
        {nonprofit10bdbeStatus === '' ||nonprofit10bdbeStatus === 'downloaded'||nonprofit10bdbeStatus === 'download'||nonprofit10bdbeStatus === undefined ?
          <Nonprofits10bdbeDownloaded link={link} tileProps={props.value} status={nonprofit10bdbeStatus} />
          :
          <Nonprofits10bdbePending link={link} tileProps={props.value} status={nonprofit10bdbeStatus} handleApprove={handleApprove}/>
        }
      </>
      :
      <div className="ngo-tiles">
        <div className="ngo-tiles-wrapper">
          {!status ? (<div className="ob-status pending">{nonprofitStatus}</div>) : null}
          {(compliances && compliances.length) ? (
            <div className={'ob-status compliance_key'}>
              {compliances.map(item => (
                <div key={item} className={`compliances ${item}`}>{item}</div>
              ))}
            </div>
          ) : null}
          <Link to={`${link}${_id}/${linkStatus}`}><h3><strong>{name ? name : 'No name available'}</strong></h3></Link>
          {members?.filter(item => item.is_primary_contact).map((item, index) => (
            <div className="np-poc" key={item.phone + index}>
              <span>{item.name}</span>
              <span>{item.phone}</span>
              <span>{item.email}</span>
            </div>
          ))}
          {/* <div className="np-causes">
          <span>#Children</span> <span>#Art & Culture</span> <span>#Education</span> <span>#Environment</span> <span>#Animal</span> <span>#Welfare</span>
        </div> */}
          <div className="np-address">
            {(address && address.location) ? address.location : 'Adress not available'}
          </div>
        </div>
        <div className="ngo-tiles-footer">
          <div className="updates">
            <span>{updated_at && formatDistanceToNow(new Date(updated_at))}</span>
            <small>Last updated</small>
          </div>
          <div className="updates right">
            {/* <span>{format(new Date(created_at), 'do MMM yyyy H:mm')}</span> */}
            <span>{created_at && format(new Date(created_at), 'do MMM yyyy')}</span>
            <small>Created</small>
          </div>
        </div>

      </div>
  );
}
export default NonprofitTile;
