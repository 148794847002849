import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

// import FacebookOnboardingService from '../../services/fbonboarding-service';
import NonProfitService from '../../services/nonprofit-service'

export const SET_ONBOARDING_DATA = 'SET_ONBOARDING_DATA';
export const SET_ONBOARDING_DATA_SUCCESS = 'SET_ONBOARDING_DATA_SUCCESS';
export const SET_ONBOARDING_DATA_FAIL = 'SET_ONBOARDING_DATA_FAIL';


const initialState = {
  formData: {},
  get_status: {},
  set_status: {},
  errors: {},
};

export const facebookOnboardingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
   
    case SET_ONBOARDING_DATA:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          formData: 'pending',
        },
        errors: {
          ...state.errors,
          formData: {},
        },
      };

    case SET_ONBOARDING_DATA_SUCCESS:
      return {
        ...state,
        formData: action.formData,
        set_status: {
          ...state.set_status,
          formData: 'success',
        },
      };
    case SET_ONBOARDING_DATA_FAIL:
      return {
        ...state,
        set_status: {
          ...state.set_status,
          formData: 'failed',
        },
        errors: {
          ...state.errors,
          formData: action.errors,
        },
      };

    default:
      return state;
  }
};


export function setNgoDetails(data) {
  return (dispatch) => {
    dispatch({
      type: SET_ONBOARDING_DATA,
    });
    return NonProfitService.set({ data, method: 'post', path:['facebook/onboarding'], auth: false})
      .then((response) => {
        dispatch({
          type: SET_ONBOARDING_DATA_SUCCESS,
          formData: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_ONBOARDING_DATA_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));
          throw error.value.errors;
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_ONBOARDING_DATA_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));
          throw error.value.error;
        } else {
          dispatch({
            type: SET_ONBOARDING_DATA_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));
        throw error;
      });
  };
}

