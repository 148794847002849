import React, {  useState } from 'react';

import './PhoneInput.scss';
import PhoneInput from 'react-phone-input-2'
import en from 'react-phone-number-input/locale/en.json'
import 'react-phone-input-2/lib/style.css'

function PhoneNumberInput(props) {
  const { countrycode, value, onChange, onCountryChange, disabled,onPhoneBlur } = props;
  const [errors, setErrors] = useState()
  const handleCountryChange = (val) => {
    setErrors('')
    onChange(val)
    if (onCountryChange) {
      onCountryChange(en[val])
    }
  }

  return (
    <>
      <PhoneInput
        country={countrycode?.toLowerCase()}
        value={value}
        onChange={(e) => handleCountryChange(e)}
        disabled={disabled}
        placeholder='Enter phone number'
        onBlur={(e, country) => {
          let _length = country.format.split(".").length - 1;
          if (value.length !== _length) {
            let _error = { ...errors };
            _error.isMobileEmpty = true;
            setErrors({ ..._error });
            onPhoneBlur({..._error},_length)
          }
        }}
      />
    </>
  );
}
export default PhoneNumberInput;
