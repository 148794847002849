import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router-dom';

import Icon from '@mdi/react';
import {
  mdiChevronLeft,
  mdiLoading,
  mdiFloppy,
  mdiChevronRight,
  mdiHelpCircleOutline,
} from '@mdi/js';

import './NGOOnboardingHome.css';

import * as LoginAction from '../../../reducers/loginReducer';
import * as NGOOnboardingHomeAction from '../../../reducers/NGOOnboarding/homeReducer';
import * as NotificationAction from '../../../reducers/notificationReducer';
import * as ConfigAction from '../../../reducers/configReducer';

import ScrollToTop from '../../../appComponents/ScrollToTop';
import Nav from '../Nav';

import Profile from '../Profile';
import OnboardingPending from '../OnboardingPending';
import Help from '../Help';
import Loading from '../../../appComponents/Loading';
import Failed from '../../../appComponents/Failed';
import OnboardingHome from './Onboarding';

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: 'profile',
      nonprofit: {},
      project: {},
      profile: {},
      prevNonprofit: {},
      prevProject: {},
      prevProfile: {},
    };
  }

  componentDidMount() {
    const { NGOOnboardingHome, ngoOnboardingHome, login, Config, config, location } = this.props;
    let id = location?.pathname?.replace('/onboarding/', '').split('/')[0];
    const { prevProfile } = this.state;
    if (!ngoOnboardingHome.get_status.nonprofit) {
      if (id) NGOOnboardingHome.getNonprofit(id);
      else NGOOnboardingHome.getNonprofit();
    }

    if (!config.get_status.config) {
      Config.get();
    }
    if (login.user !== prevProfile) {
      this.setState({ profile: login.user, prevProfile: login.user });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.login.user !== state.prevProfile) {
      setData.prevProfile = props.login.user;
      setData.profile = { ...props.login.user };
      if (setData.profile.is_email_verified && setData.profile.is_phone_verified && setData.profile.name !== '') {
        setData.form = 'nonprofit';
      }
    }
    if (props.ngoOnboardingHome.nonprofit !== state.prevNonprofit) {
      setData.prevNonprofit = props.ngoOnboardingHome.nonprofit;
      setData.nonprofit = { ...props.ngoOnboardingHome.nonprofit };
      if (setData.nonprofit && setData.nonprofit.status && state.form === 'nonprofit' && (setData.nonprofit.status === 'document_check' || setData.nonprofit.status === 'content_check' || setData.nonprofit.status === 'cause_check' || setData.nonprofit.status === 'final_approval')) {
        // setData.form = 'project';
        // if (!props.ngoOnboardingHome.get_status.project) {
        //   props.NGOOnboardingHome.getProject();
        // }
      }
    }
    if (props.ngoOnboardingHome.project !== state.prevProject) {
      setData.prevProject = props.ngoOnboardingHome.project;
      setData.project = { ...props.ngoOnboardingHome.project };
      if (setData.project && setData.project.status && state.form === 'project' && (setData.project.status === 'final_approval')) {
        setData.form = null;
      }
    }

    // if (!props.ngoOnboardingHome.get_status.project && props.ngoOnboardingHome.nonprofit?._id) {
    //   props.NGOOnboardingHome.getProject({ params: { nonprofit: props.ngoOnboardingHome.nonprofit._id } });
    // }

    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  onProfileSave = () => {
    const { Login, login, Notification } = this.props;
    const { profile } = this.state;
    if (profile.name !== '') {
      if(profile.phoneInputError){
        Notification.addNotification('Please enter valid phone number');
      } else{
      Login.set({ name: profile.name });
    } }
     else {
      Notification.addNotification('Please enter your name');
    }
  }

  onProfileSubmit = () => {
    const { Login, login, Notification } = this.props;
    const { profile } = this.state;
    if (profile.is_email_verified && profile.is_phone_verified && profile.name !== '') {
      Login.set({ name: profile.name }).then(() => {
        this.changeForm('nonprofit');
      });
    } else {
      if (!profile.is_email_verified) {
        Notification.addNotification('Please verify your email id');
      }
      if (!profile.is_phone_verified) {
        Notification.addNotification('Please verify your phone number');
      }
      if (login.user.name === '') {
        Notification.addNotification('Please enter your name');
      }
    }
  }

  onProfileChange = (profile) => {
    this.setState({ profile });
  }
  onProfileBlur = (p) => {
    let val = { ...this.state.profile, ...p }
    this.setState({ profile: val });
  }

  onNonprofitSave = () => {
    const { NGOOnboardingHome } = this.props;
    const { nonprofit } = this.state;
    if (nonprofit._id) {
      return NGOOnboardingHome.setNonprofit(nonprofit);
    } else {
      return NGOOnboardingHome.createNonprofit(nonprofit);
    }
  }

  onNonprofitSubmit = () => {
    const { NGOOnboardingHome } = this.props;
    const { nonprofit } = this.state;
    if (nonprofit._id) {
      return NGOOnboardingHome.setNonprofit({ ...nonprofit, status: 'document_check' });
    }
  }

  onNonprofitChange = (nonprofit) => {
    this.setState({ nonprofit });
  }

  onProjectSave = () => {
    const { NGOOnboardingHome, Notification } = this.props;
    const { project } = this.state;
    if (project._id) {
      return NGOOnboardingHome.setProject(project);
    } else {
      // return NGOOnboardingHome.createProject(project);
      Notification.addNotification('Something went wrong! Please contact support');
    }
  }

  onProjectSubmit = () => {
    const { NGOOnboardingHome } = this.props;
    const { project } = this.state;
    if (project._id) {
      return NGOOnboardingHome.setProject({ ...project, status: 'final_approval' });
    } else {
      return NGOOnboardingHome.createProject({ ...project, status: 'final_approval' });
    }
  }

  onProjectChange = (project) => {
    this.setState({ project });
  }

  buttonListHTML = () => {
    const { form } = this.state;
    const { ngoOnboardingHome, login } = this.props;
    const { nonprofit, profile } = this.state;
    switch (form) {
      case 'profile':
        return (
          <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
            <button type="submit" className="btn btn-primary mr-2" 
            // disabled={profile && profile?.phoneInputError}
            >
              {(login.loading === true) ? (
                <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
              ) : (
                <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
              )}
              &nbsp;Save
            </button>
            <button type="button" className="btn btn-success mr-2" 
            // disabled={profile && profile?.phoneInputError} 
            onClick={(e) => { e.preventDefault(); this.onProfileSubmit(); }}>
              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
              &nbsp;Save & Next
            </button>
          </div>
        );
      case 'nonprofit':
        return (
          <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
            {/* <button type="button" className="btn btn-outline-primary mr-2" onClick={(e) => { e.preventDefault(); this.changeForm('profile'); }}>
              <Icon path={mdiChevronLeft} className="menu-arrow" size={0.60} />
              &nbsp;Back
            </button> */}
            <button type="button" className="btn btn-outline-primary mr-2" onClick={(e) => { e.preventDefault(); this.changeForm('help'); }}>
              <Icon path={mdiHelpCircleOutline} className="menu-arrow" size={0.60} />
              &nbsp;Help
            </button>
            <button type="submit" className="btn btn-primary mr-2">
              {(ngoOnboardingHome.set_status.nonprofit === 'pending') ? (
                <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
              ) : (
                <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
              )}
              &nbsp;Save
            </button>
            {(nonprofit._id) ? (

              <button type="submit" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.onNonprofitSubmit(); }}>
                <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                &nbsp;Save & Next
              </button>
            ) : null}
          </div>
        );
      case 'help':
        return (
          <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
            <button type="button" className="btn btn-outline-primary mr-2" onClick={(e) => { e.preventDefault(); this.resumeForm(); }}>
              <Icon path={mdiChevronLeft} className="menu-arrow" size={0.60} />
              &nbsp;Back
            </button>
          </div>
        );
      default:
        return null;
    }
  }


  whichForm = () => {
    const {
      Login,
      login,
      Notification,
      ngoOnboardingHome,
      config,
    } = this.props;
    const { form, nonprofit, project, profile } = this.state;
    switch (form) {
      case 'profile':
        return (
          <Profile login={login} Login={Login} Notification={Notification} value={profile}
            onChange={this.onProfileChange}
            onSubmit={this.onProfileSave}
            onMobileBlur={this.onProfileBlur}
            config={config}>
            {this.buttonListHTML()}
          </Profile>
        );
      case 'nonprofit':
        if (!ngoOnboardingHome.get_status.nonprofit || ngoOnboardingHome.get_status.nonprofit === 'pending') {
          return (<Loading />);
        }
        if (ngoOnboardingHome.get_status.nonprofit === 'failed') {
          return (<Failed />);
        }
        if (ngoOnboardingHome.get_status.nonprofit === 'success' &&ngoOnboardingHome.nonprofit&& !Object.keys(ngoOnboardingHome.nonprofit).length) {
          return window.location.href = '/welcome-to-onboarding'
        }

        return (
          <OnboardingHome compliance={'givecompliant'} />
        );
      case 'help':
        return (
          <Help>
            {this.buttonListHTML()}
          </Help>
        );
      default:
        return (
          <Profile login={login} Login={Login} Notification={Notification}>
            {this.buttonListHTML()}
          </Profile>
        );
    }
  }

  changeForm = (form) => {
    this.setState({ form });
  }

  resumeForm = () => {
    const { profile, nonprofit, project } = this.state;
    let form = 'profile';
    if (profile.is_email_verified && profile.is_phone_verified && profile.name !== '') {
      form = 'nonprofit';
    }
    // if (nonprofit && nonprofit.status && (nonprofit.status === 'document_check' || nonprofit.status === 'content_check' || nonprofit.status === 'cause_check' || nonprofit.status === 'final_approval')) {
    //   form = 'project';
    // }
    this.setState({ form });
  }


  render() {
    const { location } = this.props;
    const { form, nonprofit } = this.state;

    return (
      <ScrollToTop location={{ pathname: `${location.pathname} ${form}` }}>
        <div className="container-scroller">
          <Nav form={form} />
          <div className="container-fluid page-body-wrapper">
            <div className="main-panel container">
              <div className="content-wrapper">
                {this.whichForm()}
              </div>
            </div>
          </div>
        </div>
      </ScrollToTop>
    );
  }
}
const mapStateToProps = state => ({
  login: state.login,
  ngoOnboardingHome: state.ngoOnboardingHome,
  config: state.config,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
  NGOOnboardingHome: bindActionCreators(NGOOnboardingHomeAction, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
)(Onboarding));
