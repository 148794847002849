import React from 'react';
import './Help.css';

function Help(props) {
  const { children } = props;
  return (
    <div className="row help-wrapper">
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h3 className="mr-b-20">Guidelines to the Registration Form</h3>
            <h4 className="mr-b-20">General Details</h4>
            <h6>Name of the Organization:</h6>
            <p className="text-muted">
              The ‘registered’ name of your non-profit, as it appears on your Registration Certificate/Trust deed and/or PAN card.
            </p>
            <h6>Alias/Common/Other Familiar Name (If any):</h6>
            <p className="text-muted">
              Any other name or an acronym that your organization is more commonly known as.For Example:
              <ul>
                <li>a) ‘Association of Parents of Mentally Retarded Children’ is also known as ADHAR.</li>
                <li>b) ‘Kokanes Kohinoor Technical Trust’ is more often recognized as ‘Barefoot Edu Foundation’</li>
              </ul>
            </p>
            <h6>About the Organization:</h6>
            <p className="text-muted">
              A brief introduction about your non-profit. This should include basic information like what is it, where is it based, what are the causes that you believe in, how does the organization tackle the issues that it works towards and likewise. Any potential donor or supporter should be able to know sufficient about your non-profit from this section, before he or she decides to support you and your cause.
            </p>
            <h6>NGO Type:</h6>
            <p className="text-muted">Whether your non-profit is registered as a ‘Society’ or a ‘Section 25 company’?</p>
            <h6>Auditor’s Name:</h6>
            <p className="text-muted">The name of the Chartered Accountant or the firm that audits your annual financial reports, including your Balance Sheets and Income-Expenditure statement.</p>
            <h6>Website of the Organization:</h6>
            <p className="text-muted">The hosted website of your non-profit, with a secure link.</p>
            <h6>YouTube Video URL:</h6>
            <p className="text-muted">
              The YouTube link to your non-profit video that describes the work of your organization in brief.<br />
              In case you do not have a video, we recommend you to record a 2-minute video using your
              phone camera, in landscape made and good sound quality, describing the cause that you
              work for and how. You can either upload the video on your YouTube channel and send us
              the link OR mail us the video and we will upload it on our channel.
            </p>
            <h6>Public E-mail ID:</h6>
            <p className="text-muted">
              An e-mail ID where people who come across your profile on LetzChange can directly reach
              you for any kind of query, assistance or feedback related to your organization. Please note
              that public e-mail ID will be displayed on the platform for public reference.
            </p>
            <h6>Public Contact Number:</h6>
            <p className="text-muted">
              A phone number where people who come across your profile on LetzChange can directly get
              in touch with you for any kind of query, assistance or feedback related to your organization.
              Please note that public contact number will be displayed on the platform for public
              reference.
            </p>
            <h6>Head Office Address:</h6>
            <p className="text-muted">
              The complete address of your registered head office. Please note that this address will be
              displayed on the platform for public reference.
            </p>

            <h4 className="mr-b-20 pd-t-20">Registration Details</h4>
            <h6>Registration/Trust Deed/MoA Number</h6>
            <p className="text-muted">
              The registration number of your organization that is mentioned either on your Registration
              Certificate, Trust Deed or Memorandum of Association. Please note that this number should
              be easily traceable on the document, for internal verification.
            </p>
            <h6>12A and 80G Numbers</h6>
            <p className="text-muted">
              The numbers mentioned on the 12A Certificate and 80G certificate of your organization
              respectively. Please note that the numbers should be easily traceable on the documents, for
              internal verification.
            </p>
            <h6>PAN Card Number</h6>
            <p className="text-muted">
              The Permanent Account Number mentioned on the PAN card of your organization. Please
              note that this number should be easily traceable on the document, for internal verification.
            </p>

            <h4 className="mr-b-20 pd-t-20">Bank Account Details</h4>
            <h5 className="mr-b-20">a) Domestic Bank Account</h5>
            <h6>Beneficiary Name:</h6>
            <p className="text-muted">
              The name of the beneficiary of the account. In your case, the registered name of your
              organization should appear in the beneficiary name column, to be validated by a cancelled
              cheque/ passbook furnished by the non-profit.
            </p>
            <h6>Account Type:</h6>
            <p className="text-muted">
              Is it a Savings Account or a Current Account?
            </p>
            <h6>Account Number:</h6>
            <p className="text-muted">
              Please provide the domestic bank account number of your organization. This will be
              validated by a cancelled cheque/ passbook furnished by the non-profit.
            </p>
            <h6>IFSC:</h6>
            <p className="text-muted">
              Provide the IFSC of the branch where your account is. The IFSC is mentioned on the cheque
              leaflet. This will be validated by a cancelled cheque/ passbook furnished by the non-profit.
            </p>
            <h6>Cancelled Cheque:</h6>
            <p className="text-muted">
              Please upload a cancelled cheque of the relevant bank account to facilitate the validation of
              the bank account details furnished by you.
            </p>
            <h5 className="mr-b-20 pd-t-20">b) FCRA Bank Account (If applicable)</h5>
            <p className="text-muted">
              If your organization has a valid FCRA Certificate, you can accept foreign donations for your
              non-profit, in currencies other than INR, on LetzChange.
            </p>
            <h6>Beneficiary Name:</h6>
            <p className="text-muted">
              The name of the beneficiary of the account. In your case, the registered name of your
              organization should appear in the beneficiary name column, to be validated by a cancelled
              cheque/ passbook furnished by the non-profit.
            </p>
            <h6>Account Type:</h6>
            <p className="text-muted">
              Is it a Savings Account or a Current Account?
            </p>
            <h6>Account Number:</h6>
            <p className="text-muted">
              Please provide the FCRA bank account number of your organization. This will be validated by
              a cancelled cheque/ passbook furnished by the non-profit.
            </p>
            <h6>IFSC:</h6>
            <p className="text-muted">
              Provide the IFSC of the branch where your account is. The IFSC is mentioned on the cheque
              leaflet. This will be validated by a cancelled cheque/ passbook furnished by the non-profit.
            </p>
            <h6>Cancelled Cheque:</h6>
            <p className="text-muted">
              Please upload a cancelled cheque of the relevant bank account to facilitate the validation of
              the bank account details furnished by you.
            </p>
            <h6>FCRA Number:</h6>
            <p className="text-muted">
              The 9-digit FCRA registration number mentioned on your FCRA certificate. Please ensure
              that the certificate stands valid at the time of submission and any expiry/ renewal should be
              intimated to LetzChange at the earliest.
            </p>

            <h4 className="mr-b-20 pd-t-20">Non-profit Logo</h4>
            <p className="text-muted">
              Provide the registered logo of your non-profit to be displayed on top of your profile, on
              LetzChange.
            </p>

            <h4 className="mr-b-20 pd-t-20">Documents/Files</h4>
            <p className="text-muted">
              Upload all the below mentioned documents against their respective fields. Please note that
              the name and numbers on the documents should be clear and legible, for our internal
              verification.
              <ul>
                <li>a) Registration Certificate/ Trust Deed</li>
                <li>b) 12A Certificate</li>
                <li>c) 80G Certificate</li>
                <li>d) PAN Card</li>
                <li>e) FCRA Certificate (if applicable)</li>
                <li>f) ITR (of the latest financial year)</li>
              </ul>
            </p>

            <h4 className="mr-b-20 pd-t-20">Social Media Accounts</h4>
            <p className="text-muted">
              The URLs of your organization’s:
              <ul>
                <li>a) Facebook Page</li>
                <li>b) Twitter Handle</li>
                <li>c) YouTube Channel</li>
                <li>d) Instagram Profile</li>
                <li>e) Linkedin Business Page</li>
              </ul>
            </p>

            <h3 className="mr-b-20 pd-t-40">Guidelines to the Project Form</h3>
            <p className="text-muted">
              A ‘Project’ on LetzChange is a program that the non-profit wishes to raise funds for, using
              our fundraiser tool and services. Below are the guidelines that shall simplify the process and
              ease the efforts of filling your project form:
            </p>
            <h6>Name of the Project:</h6>
            <p className="text-muted">
              The name of your project that people identify and relate to. For example- Project ‘Rahat
              Floods’ of Goonj and ‘Swachh Bharat Mission’ of the ‘Government of India’
            </p>
            <h6>Issue:</h6>
            <p className="text-muted">
              What is the problem that you wish to address through your project? This section should
              ideally talk about the cause you are working for and how it is prevalent in the society.
              Include ground data and statistics to establish it as a pervasive problem and rationalize why
              it needs immediate attention and remedial actions.
            </p>
            <h6>Action:</h6>
            <p className="text-muted">
              What are you, as an organization, going to do about the prevalent problem? This section
              should talk about the planned work that you and your organization are going to undertake
              to correct the above-mentioned issue. It should also include the proposed or potential
              course of action that needs to be taken, concerning the same. The donors should be able to
              see your enthusiasm and dedication towards the cause, through this section.
            </p>
            <h6>Impact:</h6>
            <p className="text-muted">
              What is the effect that you aspire to create? This section should talk about the change that
              you wish to bring with your efforts. Illustrate realistic targets that is achievable with the help
              and contribution of people towards the cause, inculcating a sense of social responsibility
              amongst them. Let the potential statistics do the talking. Have a CALL TO ACTION appeal for
              all existing and prospective donors.
            </p>
            <h6>Primary Cause:</h6>
            <p className="text-muted">
              The main cause that you work towards.
                </p>
            <h6>Secondary Cause:</h6>
            <p className="text-muted">
              A sub cause within the main cause.
              <br />
              <span className="text-dark">For Example:</span>
              &nbsp;If you have a project dedicated to Girl Child Education, ‘Education’ can be a sub cause within the primary cause of ‘Women Empowerment’.
            </p>
            <h6>Project Location(s):</h6>
            <p className="text-muted">
              The place(s) where you are planning to implement the project on a ground level. It can be a city or cities, a state or states and/or pan-India.
                </p>
            <h6>Donation Options:</h6>
            <p className="text-muted">
              The donation options are a way of prompting different donors with a suitable amount that they would be happy to contribute.<br />
              Our recommendation is to set the first donation option as the lowest amount you can associate with a tangible impact. To allow maximum people to contribute, we recommend that the amount should be less than ₹1000 such that many people can contribute.<br />
              The second donation option is aimed at donors who can contribute a larger amount. We usually recommend keeping it at 5-10x of the first donation option.<br />
              The third donation option is an editable field and is for donors who would like to contribute an amount of their choice. You can still provide a hint amount, which will be pre-filled, but can be changed by the donor.<br />
              Every donation option should be aptly justified by a crisp description. Tell the donors what their donation would be used for.<br />
              <span className="text-dark">For example:</span>
              &nbsp;₹500- To provide books to 2 children
              <br />
              ₹1500- To buy bricks for the construction of library
            </p>
            <h6>Cost Break-up and Target Amount:</h6>
            <p className="text-muted">
              This section requires you to furnish an approximate amount that you would need for this
              project, with a break-up of the same. Once you start filling in the break-up amounts, it shall
              automatically add up and reflect in the last section of the form as the ‘Target Amount’.
              Please note that this detail is for our internal use and will not be shared on the LetzChange
              platform at any given point of time.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-12 d-flex align-items-stretch">
        <div className="row flex-grow">
          {children}
        </div>
      </div>
    </div>
  );
}
export default Help;
