import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as LoginAction from "../../../reducers/loginReducer";
import * as OnboardingHomeAction from "../../../reducers/NGOOnboarding/homeReducer";
import * as NotificationAction from '../../../reducers/notificationReducer';

import ScrollToTop from "../../../appComponents/ScrollToTop";
import FormNav from "../FormNavigation";
import Helpers from "../../../utils/helpers";
import DynamicFormHelper from "../../../utils/dynamicform";
import ActionStatus from "../../../appComponents/ActionStatus";
import _ from "lodash";
import Form from "../../../components/OnboardingComponents/Form";
import { baseUrl } from "../../../config";

const OnboardingHome = (props) => {
  const { onboardingHome, login, OnboardingHome, Notification, compliance } = props;
  const [ submittedForApproval, setSubmittedForApproval] = useState(false);
  const [ user, setUser] = useState({});
  const [ nonprofit, setNonprofit] = useState(null);
  const [ projectState, setProjectState] = useState(null);
  const [ changes, setChanges ] = useState(null);

  const [errors, setErrors] = useState("");
  const [requiredFields, setRequiredFields] = useState([]);
  const [message, setMessage] = useState("");
  const [steps, setSteps] = useState(null);
  const [prevStep, setPrevStep] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [nextStep, setNextStep] = useState(null);

  const [formSchema, setFormSchema] = useState(null);
  const [formValues, setFormValues] = useState({ nonprofits: {}, projects: {} });
  const [currentStepSchema, setCurrentStepSchema] = useState(null);
  const [validations, setValidations] = useState([]);
  const [formSchemaValidation, setFormSchemaValidation] = useState([]);
  const [stepErrors, setStepErrors] = useState({});

  const [checkuiErrors, setCheckuiErrors] = useState([]);
  const SUBMISSION_MESSAGE = '<p>Thank you for filling in the information. We will get back to you with an update on the same. If you have entered incorrect information, or have any queries, please write to <a href="mailto:membership@give.do">membership@give.do<a></p><p>Your forms will be subjected to an internal verification, post which we will be queuing your profile to go live on the platform. Should there be any discrepancies, we shall get back to you at the earliest. </p>'


  // Setting logged in user to state 
  useEffect(() => {
    if(login.user){
      setUser(login.user)
    }
  }, [login.user]);

  // Setting errors while saving data to State 
  useEffect(() => {
    if(onboardingHome.errors.nonprofit && !Helpers.isEqual(onboardingHome.errors.nonprofit, errors.nonprofit)){
      if(Object.keys(onboardingHome.errors.nonprofit).length !== 0){ 
        setErrors(errors)
        setRequiredFields(onboardingHome.errors.nonprofit)
      }
    }
  }, [onboardingHome.errors]);

  useEffect(() => {
    const { nonprofit } = onboardingHome
    if(onboardingHome.nonprofit && onboardingHome.nonprofit._id) {
      setNonprofit(onboardingHome.nonprofit);
      
      if(onboardingHome.nonprofit.required_compliances.indexOf(compliance) > -1){
        
        OnboardingHome.getFormSchema({
          nonprofit: onboardingHome.nonprofit._id,
          compliance: compliance
        });

        setFormValues({
          ...formValues,
          nonprofits: Helpers.createNonprofitWithDiff(Helpers.cloneObject(onboardingHome.nonprofit)),
        });            
      }else{
        // Throw Error if the compliance is not present
      }      

      if(nonprofit.submitted_compliances && nonprofit.submitted_compliances.indexOf(compliance) !== -1 && nonprofit.status === 'document_check'){
        setSubmittedForApproval(true)
        setMessage(SUBMISSION_MESSAGE)  
      }
    }
  }, [onboardingHome.nonprofit]);

  useEffect(()=>{
    if(message === SUBMISSION_MESSAGE){
      let url = `${baseUrl}/fundraisers/create/nonprofit/${onboardingHome?.nonprofit?.slug}?is_primary=yes`
      setTimeout(() => {
       window.open(url,'_self')
      }, 2000);
    }
  },[message])

  useEffect(() => {
    if(onboardingHome.project && onboardingHome.project._id) {
      setProjectState(JSON.stringify(onboardingHome.project));
      setFormValues({
        ...formValues,
        projects: Helpers.createNonprofitWithDiff(onboardingHome.project),
      })
    }
  }, [onboardingHome.project]);  
  
  useEffect(() => {
    if (onboardingHome.schema && Object.keys(onboardingHome.schema).length) {
      setFormSchema(onboardingHome.schema);
      const steps = Helpers.getFormSteps(onboardingHome.schema, login.user.is_admin);
      if (steps) {
        setSteps(steps);        
        if(!currentStep){
          setCurrentStep(steps[0].key);
          setNextStep(steps[1].key);
          setPrevStep(null);

          let currentStepSchema = DynamicFormHelper.getThisFormSchema(
            onboardingHome.schema,
            steps[0].key
          );
          if (currentStepSchema) {
            setCurrentStepSchema(currentStepSchema);
          }
        }
        setValidations(DynamicFormHelper.getValidationsFromSchema(onboardingHome.schema));        
        if(onboardingHome.nonprofit) getNonprofitChanges(onboardingHome.nonprofit, onboardingHome.schema)
      }
    }
  }, [onboardingHome.schema]);


  const getNonprofitChanges = (nonprofit, schema) => {    
    if(schema){
      const changes = Helpers.getKeysFromPatch(
        Helpers.generatePatch(
          nonprofit, 
          Helpers.createNonprofitWithDiff(
            Helpers.cloneObject(nonprofit)
          )
        )
      )
      if(schema && changes.length){        
        setChanges(DynamicFormHelper.mapErrorToStepKey(changes, onboardingHome.schema))
      }
    }
  }

  const getNextStep = (step) => {
    let index = _.findIndex(steps, (item) => item.key === step);
    if (index + 1 >= steps.length) {
      return null;
    } else {
      return steps[index + 1].key;
    }
  };

  const getPrevStep = (step) => {
    let index = _.findIndex(steps, (item) => item.key === step);
    if (index - 1 < 0) {
      return null;
    } else {
      return steps[index - 1].key;
    }
  };

  const onFormChange = (step, formData) => {
    if(step !== prevStep) {
      let getStepErrors;
      // If form doesn't data just needs to be saved, set the data with the current status             
      let uniqValidationArr = [...new Set(validations?.required)]; 
      const errors = Helpers.validateFormFields(formData, uniqValidationArr)
      setFormSchemaValidation(errors)
      if(errors.length){
      // Since we are clearing the errors step wise before procedding to next page !
        getStepErrors = getStepWiseErrors(errors)
      }
      if(getStepErrors !== undefined && Object.keys(getStepErrors).includes(currentStep) || checkuiErrors.length > 0) {
        Notification.addNotification('Please resolve all errors and save before going to the next page')  
        return
      }
    }
    const stepSchema = DynamicFormHelper.getThisFormSchema(formSchema, step);
    const next_Step = getNextStep(step);
    const prev_Step = getPrevStep(step);
    setCurrentStepSchema(stepSchema);
    setCurrentStep(step);
    setNextStep(next_Step);
    setPrevStep(prev_Step);
  };

  const saveForm = async (data, isSubmittingForApproval = false) => { 
    let compliance = nonprofit.required_compliances[0];

    let getStepErrors;
    // If form doesn't data just needs to be saved, set the data with the current status             
    let nonprofit_id   
    let uniqValidationArr = [...new Set(validations?.required)]; 
    const errors = Helpers.validateFormFields(data, uniqValidationArr)
    setFormSchemaValidation(errors)
    data = DynamicFormHelper.clearFormData(data)
    if(errors.length){
    // Since we are clearing the errors step wise before procedding to next page !
      getStepErrors = getStepWiseErrors(errors)
    }
    else{
      setStepErrors({})
    } 

    if(data.nonprofits){      
      const nonprofitDiffData = DynamicFormHelper.getObjectDiff(data.nonprofits, nonprofit) 
      if (data.nonprofits._id) {
        // removing project creation
        // const project = await createOrSaveProject(data, isSubmittingForApproval)
      
        if(!isSubmittingForApproval){
          if(getStepErrors !== undefined && Object.keys(getStepErrors).includes(currentStep) || checkuiErrors?.length > 0) {
          Notification.addNotification('Please resolve all errors before saving the form')  
        } else {
          try{
            await OnboardingHome.setNonprofit({
              ...nonprofitDiffData,
              status: nonprofit.status
            })
          }
          catch(e){
            console.error(e);
          }
        }
        }
        else{   
          if(errors.length === 0 && checkuiErrors.length === 0){  
              OnboardingHome.setNonprofit({
                ...nonprofitDiffData,
                status: data.nonprofits.status === 'pending' ? 'document_check' : data.nonprofits.status,  
                submitted_compliances: [...(data.nonprofits.submitted_compliances || []) , compliance]
              }).then(res => {
                if(res.status === '200'){
                  setSubmittedForApproval(true);
                  setMessage(SUBMISSION_MESSAGE)  
                }
              }).catch(error => {
                console.error(error);
              })
            }            
          else{            
            Notification.addNotification('Please resolve all errors before submitting the form')
          }
        }
      } else {
        OnboardingHome.createNonprofit(data.nonprofits).then(response => {
          nonprofit_id = response._id
        });
      }
    }    
  }

  const getStepWiseErrors = (errors) => {
    const stepErrors = {}     
    errors.map(item => {
      const stepKey = DynamicFormHelper.getStepDetailsfromKey(item, formSchema)
      if(stepErrors[stepKey]){
        stepErrors[stepKey].push(item)
      }
      else{
        stepErrors[stepKey] = [item]
      }      
    })
    if(currentStep === 'nonprofit_basic_details') {
      let ifNotNonProfitKey = !Object.keys(stepErrors).includes(currentStep)
      if (stepErrors['documents']?.length > 0) {
        let docError = [...stepErrors['documents']]
        if(ifNotNonProfitKey) {
          stepErrors['nonprofit_basic_details'] = [...docError]
        } else { stepErrors['nonprofit_basic_details'] = [...stepErrors['nonprofit_basic_details'], ...docError];}
        delete stepErrors['documents']
      } else if(stepErrors['social_media_accounts']?.length > 0) {
        let socError = [...stepErrors['social_media_accounts']];
        if(ifNotNonProfitKey) {
          stepErrors['nonprofit_basic_details'] = [...socError]
        } else {
        stepErrors['nonprofit_basic_details'] = [...stepErrors['nonprofit_basic_details'], ...socError];}
        delete stepErrors['social_media_accounts']
      }
    }
    setStepErrors(stepErrors) 
    return stepErrors
  }

  return (
    <ScrollToTop location={{ pathname: `/` }}>
      <div className="container-scroller">
        {!submittedForApproval ? <FormNav steps={steps} currentStep={currentStep} /> : <FormNav />}

        <div className="container-fluid page-body-wrapper">
          <div className="main-panel container">
            <div className="content-wrapper">
              {/* Since we are showing step wise errors no need to list all the errors on first page itself */}
              {/* {Object.keys(stepErrors).length ? (
                <>
                  <div className="row justify-content-md-center">
                    <div className="col-md-12 form-alert warning grid-margin">
                      <div className="card">
                        <span>
                          Some required fields don't have values entered in them. Rest of the fields have been saved. <br />
                        </span>
                        {Object.keys(stepErrors).length && (
                          <ul>
                            {Object.keys(stepErrors).map((item, index) => {
                              return (
                                <li key={index}>
                                  <a onClick={(e) => onFormChange(item)} href="#">
                                    {Helpers.formatText(item.replace(/_/g, " "))}
                                  </a>
                                  &nbsp; has {stepErrors[item].length} error(s)
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : null} */}

              {submittedForApproval === true ? (
                <ActionStatus message={message} />
              ) : Object.keys(errors).length !== 0 ? (
                <ActionStatus errors={errors} />
              ) : (
                <Form
                  key={1}
                  onChange={onFormChange}
                  formSchema={currentStepSchema}
                  step={currentStep}
                  values={formValues}
                  requiredFields={formSchemaValidation}
                  errors={DynamicFormHelper.transformAPIRequestErrors(requiredFields)}
                  stepHasChanges={(changes && Object.keys(changes).indexOf(currentStep) >= 0) ? changes[currentStep].length : false}
                  onSubmit={saveForm}
                  isAdmin={user.is_admin}
                  setCheckuiErrors={setCheckuiErrors}
                  actions={{
                    stepchange: {
                      previous: prevStep,
                      next: nextStep,
                    },
                    submit: {
                      label: "Save",
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </ScrollToTop>
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
  onboardingHome: state.ngoOnboardingHome,
});

const mapActionToProps = (dispatch) => ({
  Login: bindActionCreators(LoginAction, dispatch),
  OnboardingHome: bindActionCreators(OnboardingHomeAction, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(mapStateToProps, mapActionToProps)(OnboardingHome);
