import * as NotificationAction from '../notificationReducer';


import BdBeService from '../../services/10BdBe-service';

export const GET_LIST_ADMIN_10BDBE_NONPROFITS = 'GET_LIST_ADMIN_10BDBE_NONPROFITS';
export const GET_LIST_ADMIN_10BDBE_NONPROFITS_SUCCESS = 'GET_LIST_ADMIN_10BDBE_NONPROFITS_SUCCESS';
export const GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL = 'GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL';

export const ADMIN_10BDBE_NONPROFIT_LIST_RESET = 'ADMIN_10BDBE_NONPROFIT_LIST_RESET';
export const CHANGE_ADMIN_10BDBE_NONPROFITS = 'CHANGE_ADMIN_10BDBE_NONPROFITS'
export const CHANGE_ADMIN_10BDBE_NONPROFITS_SUCCESS = 'CHANGE_ADMIN_10BDBE_NONPROFITS_SUCCESS'
export const CHANGE_ADMIN_10BDBE_NONPROFITS_FAIL = 'CHANGE_ADMIN_10BDBE_NONPROFITS_FAIL'
export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  nonprofits10BdBe: [],
  get_status: {},
  load_status: {},
  isChangingStatus: false,
  isChangedStatus: false,
  nonprofit_count: 0
};

export const admin10BdBeListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LIST_ADMIN_10BDBE_NONPROFITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
      };
    case CHANGE_ADMIN_10BDBE_NONPROFITS:
      return {
        ...state,
        isChangingStatus: true
      };
    case CHANGE_ADMIN_10BDBE_NONPROFITS_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        isChangedStatus: true
      };
    case CHANGE_ADMIN_10BDBE_NONPROFITS_FAIL:
      return {
        ...state,
        isChangingStatus: false,
        isChangedStatus: false
      };
    case GET_LIST_ADMIN_10BDBE_NONPROFITS_SUCCESS:
      return {
        ...state,
        nonprofits10BdBe: action.nonprofits,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
        load_status: {
          ...state.load_status,
          nonprofit: action.load_status,
        },
        nonprofit_count: action.count
      };
    case GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
      };
    case ADMIN_10BDBE_NONPROFIT_LIST_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getNonprofits({ ...query }) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_LIST_ADMIN_10BDBE_NONPROFITS,
    });
    const { isSearch, q, status } = query || {}
    const currentNonprofits = getState().adminNonprofit10BdBeList.nonprofits10BdBe
    const isQuerySearch = isSearch || (q && q.length > 0)
    
    const params = {
      status: status&&status!==undefined&&status?status.toUpperCase():'DOWNLOADED',
      limit: 10,
      skip: currentNonprofits.length,
      ...isQuerySearch ? { sort: '-updated_at', q: `${q}&populate_10bd=true`, } : null
    }
    return BdBeService.get({ isQuerySearch }, { params })
      .then((response) => {
        if (status === 'pending' || status === 'approved' || status === 'rejected'||status === 'download-pending') {
          dispatch({
            type: GET_LIST_ADMIN_10BDBE_NONPROFITS_SUCCESS,
            nonprofits: currentNonprofits && currentNonprofits.length ? [...currentNonprofits, ...response.data] : [...response.data],
            load_status: true,
            count: response.total_nonprofits
          });
        } else {
          const resultArray = response && response.map(item => {
            if ('data_10bd' in item && item.data_10bd && item.data_10bd.nonprofit && 'name' in item.data_10bd.nonprofit && item.data_10bd.nonprofit.name !== '') {
              return item.data_10bd;
            } else {
              return { ...item.data_10bd, nonprofit: { name: item.name, _id: item._id }, _id: item._id };
            }
          });
          const fres = isQuerySearch ? resultArray : response;
          if (fres.length === 0) {
            dispatch({
              type: GET_LIST_ADMIN_10BDBE_NONPROFITS_SUCCESS,
              nonprofits: [],
              load_status: false,
            });
          } else {
            dispatch({
              type: GET_LIST_ADMIN_10BDBE_NONPROFITS_SUCCESS,
              nonprofits: currentNonprofits && currentNonprofits.length ? [...currentNonprofits, ...fres] : [...fres],
              load_status: true,
              count: response.nonprofit_count
            });
          }
          return fres;
        }
        return response
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function approveNonprofits(id) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ADMIN_10BDBE_NONPROFITS,
    });
    return BdBeService.set({ id })
      .then((response) => {
        if (response) {
          dispatch({
            type: CHANGE_ADMIN_10BDBE_NONPROFITS_SUCCESS,
          });
          dispatch(NotificationAction.addNotification('Approved successfully', 'SUCCESS'));

        } else {
          dispatch({
            type: GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}
export function sendEmailNonprofits(id) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ADMIN_10BDBE_NONPROFITS,
    });
    return BdBeService.set({ id, isSendEmail: true })
      .then((response) => {
        if (response) {
          dispatch({
            type: CHANGE_ADMIN_10BDBE_NONPROFITS_SUCCESS,
          });
          dispatch(NotificationAction.addNotification('Email sent successfully', 'SUCCESS'));

        } else {
          dispatch({
            type: GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}
export function rejectNonprofits(id,rejectReason) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_ADMIN_10BDBE_NONPROFITS,
    });
    return BdBeService.set({ id, isReject: true,rejectReason })
      .then((response) => {
        if (response) {
          dispatch({
            type: CHANGE_ADMIN_10BDBE_NONPROFITS_SUCCESS,
          });
          dispatch(NotificationAction.addNotification('Rejected successfully', 'SUCCESS'));

        } else {
          dispatch({
            type: GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_ADMIN_10BDBE_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: ADMIN_10BDBE_NONPROFIT_LIST_RESET,
    });
  };
}
