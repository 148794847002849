import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ComplianceApprovalReducer from '../../../reducers/AdminApprovals/complianceApprovalReducer';
import Helpers from '../../../utils/helpers'
import DynamicFormHelper from '../../../utils/dynamicform'
import Form from '../../../components/OnboardingComponents/Form'
import FormActionControls from '../../../components/OnboardingComponents/FormActionControls';
import * as NotificationAction from '../../../reducers/notificationReducer';
import Editor from "../../../components/Editor"

/*
  Compliance Approval Item  
  TODO: Add what this component does.
*/

const ComplianceApprovalItem = (props) => {
  
  const { match, ComplianceApproval, complianceData } = props

  const [ nonprofitID, setNonprofitID ] = useState(null)  
  const [ compliance, setCompliance ] = useState(null)  
  const [ formValues, setFormValues ] = useState({})
  const [ formSchema, setFormSchema ] = useState({})
  const [ stepWiseSchema, setStepWiseSchema ] = useState([])
  const [ validations, setValidations ] = useState(null)
  const [ errors, setErrors ] = useState([])
  const [ stepErrors, setStepErrors ] = useState({})
  const [ changes, setChanges ] = useState({})
  const [ formSchemaValidation, setFormSchemaValidation] = useState({})
  const [ isNGOApproved, setIsNGOApproved] = useState(false)
  const [ showRejectActions, setShowRejectActions] = useState(false)
  const [ rejectionEmailData, setRejectionEmailData ] = useState({})

  useEffect(() => {
    if(match && match.params){
      if(match.params.id){
        setNonprofitID(match.params.id)
      }      
      if(match.params.compliance){
        setCompliance(match.params.compliance)
      }
    }    
  }, [match])


  useEffect(() => {
    if(nonprofitID && nonprofitID !== null && compliance && compliance !== null){
      ComplianceApproval.getNonprofit({ path: [nonprofitID]})
      ComplianceApproval.getFormSchema({ nonprofit: nonprofitID, compliance: compliance })      
    }
  }, [nonprofitID, compliance])


  useEffect(() => {      
    if(complianceData && complianceData.nonprofit && Object.keys(complianceData.nonprofit).length !== 0){
      setFormValues({
        ...formValues,
        nonprofits: Helpers.createNonprofitWithDiff(Helpers.cloneObject(complianceData.nonprofit))
      })
      
      if(complianceData.nonprofit.approved_compliances && complianceData.nonprofit.approved_compliances.length !== 0){
        const complianceApprovalCheck = complianceData.nonprofit.approved_compliances.find(item => item.id === match.params.compliance)
        if(complianceApprovalCheck && Object.keys(complianceApprovalCheck).length !== 0){
          setIsNGOApproved(true)
        }
      }

      const changes = Helpers.getKeysFromPatch(
        Helpers.generatePatch(
          complianceData.nonprofit, 
          Helpers.createNonprofitWithDiff(
            Helpers.cloneObject(complianceData.nonprofit)
          )
        )
      )

      if(changes.length){        
        setChanges(DynamicFormHelper.mapErrorToStepKey(changes, formSchema))
      }
      
    }  
    if(complianceData && complianceData.schema){      
      setFormSchema(complianceData.schema)
      const validations = DynamicFormHelper.getValidationsFromSchema(complianceData.schema);       
      setValidations(validations)
    }    
  }, [complianceData])  

  useEffect(() => {
    if(formSchema){      
      // Passing IsAdmin value as false 
      const steps = Helpers.getFormSteps(formSchema, true);
      if(steps !== undefined){
        const stepSchema = {}
        steps.forEach(item => {          
          stepSchema[item.key] = DynamicFormHelper.getThisFormSchema(formSchema, item.key)
        })        
        setStepWiseSchema(stepSchema)
      }
    }
  }, [formSchema])  

  useEffect(() => {    
    if(complianceData.get_status && complianceData.get_status.nonprofit === 'failed'){      
      setErrors(complianceData.errors.nonprofit)
    }
  },[complianceData.get_status])


  useEffect(() => {    
    const stepErrors = DynamicFormHelper.mapErrorToStepKey(errors, formSchema)
    setStepErrors(stepErrors)
  }, [errors])  

  const saveNonProfitData = (data) => {    
    data = DynamicFormHelper.clearFormData(data)
    if(data.nonprofits){      
      const nonprofitDiffData = DynamicFormHelper.getObjectDiff(data.nonprofits, complianceData.nonprofit)      
      ComplianceApproval.setNonprofit(nonprofitDiffData)
    }
  }

  const approveRejectChanges = async (nonprofit, isRejection = false, edits = false) => {
    if(nonprofit.edits && nonprofit.edits.length >= 0){  
      let approveRejectionIndexes      
      if(!edits){
        approveRejectionIndexes = nonprofit.edits.map((item, index) => index)      
      }
      else{        
        approveRejectionIndexes = edits
      }   
      if (!isRejection) {
        return await ComplianceApproval.setNonprofitWithEdits(nonprofit._id, { approve: approveRejectionIndexes })
      }
      else{
        initiateRejection()
      }
    }
    else{
      return nonprofit
    }
  }

  const approveNonProfitCompliance = async () => {       
    const complianceCheckData = DynamicFormHelper.clearFormData(formValues)
    let nonprofit = complianceCheckData.nonprofits
    
    const errors = Helpers.validateFormFields(complianceCheckData, validations?.required)            

    if(errors.length === 0){
      setFormSchemaValidation({})
      if(nonprofit.edits && nonprofit.edits.length > 0){
        nonprofit = await approveRejectChanges(nonprofit)
      }          
      
      if(nonprofit){
        ComplianceApproval.setNonprofit({
          _id: nonprofit._id,
          approved_compliances: [
            ...( nonprofit.approved_compliances || [] ),
            {
              id: compliance
            }
          ],
          submitted_compliances: nonprofit.submitted_compliances ? nonprofit.submitted_compliances.filter(item => item !== compliance ) : []
        })
      }
    }
    else{      
      setFormSchemaValidation(errors)
      props.Notification.addNotification('Please resolve all errors before submitting the form')
    }
  }

  const initiateRejection = () => {
    setShowRejectActions(true)
  }

  const approveRejectSection = async (indexes, action = 'approve') => {
    await approveRejectChanges(formValues.nonprofits, action === 'approve' ? false : true, indexes)
  }

  const handleRejectionEmailChanges = (e, key = undefined) => {
    if (key) {
      setRejectionEmailData({
        ...rejectionEmailData,
        [e.target?.name || key]: e.target?.value || e
      })
    } else {
      setRejectionEmailData({
        ...rejectionEmailData,
        [e.target?.name]: e.target?.value
      })
    }
  }

 const rejectNonprofit = () => {
    if (rejectionEmailData.subject && rejectionEmailData.body) {
      const { nonprofits } = formValues
      ComplianceApproval.rejectNonprofit({
        _id: nonprofits._id,
        submitted_compliances: nonprofits.submitted_compliances.filter(item => item !== compliance),
        email: rejectionEmailData,
        compliance_key: 'giveassured'
      })
        .then(() => {
          return window.location.href = '/approvals/nonprofit-onboarding';
        })
        .catch(e => {
          console.error('Error rejecting Nonprofit', e)
        })
    }
  }
  

  const cancelRejection = () => {
    setShowRejectActions(false)
    setRejectionEmailData({})
  }
  
  return (
    <div className="content-wrapper">
      <div className="row justify-content-md-center">        
        <div className="col-md-12 grid-margin">
          <div className="card">
            <div className="card-body">
              {formValues?.nonprofits && (
                <>
                <h4>Nonprofit Name: <strong>{formValues?.nonprofits?.name}</strong></h4>
                <p>Click <a href={`/admin/nonprofits/${formValues?.nonprofits?._id}/preview`}> here</a> to check {formValues?.nonprofits?.name}'s basic details</p>
                { isNGOApproved ? 
                  <div className="ob-status published"><strong>{formValues?.nonprofits?.name}</strong> is already <strong>{match.params.compliance}</strong> approved.</div>
                : null }
                </>
              )}
            </div>
          </div>
        </div>
        {!isNGOApproved ? (
          <>
          <div className="col-md-12 grid-margin">                    
              { (stepWiseSchema && Object.keys(stepWiseSchema).length) ? Object.keys(stepWiseSchema).map((item, index) => (              
                <Form
                  key={index}                  
                  index={index}
                  formSchema={stepWiseSchema[item]}
                  step={item}
                  values={formValues}
                  isReadMode={true}
                  requiredFields={formSchemaValidation}                     
                  errors={errors ? DynamicFormHelper.transformAPIRequestErrors(errors): false}
                  onSubmit={e => saveNonProfitData(e)}
                  isAdmin={true}
                  isApprovals={true}
                  stepHasError={stepErrors[item] ? stepErrors[item] : null}
                  stepHasChanges={Object.keys(changes).indexOf(item) >= 0 ? changes[item].length : false}
                  isCollapseable={true}
                  actions={{
                    sectionApprove: {
                      label: 'Approve'
                    },
                    sectionReject: {
                      label: 'Reject'
                    }
                  }}
                  methods={{
                    approveRejectSection: approveRejectSection
                  }}
                />              
              )) : null}
          </div>
          <FormActionControls 
            actions={{            
              approve: {
                label: 'Approve All',
                method: approveNonProfitCompliance,
              },
              reject: {
                label: 'Reject All',
                method: initiateRejection,
                btnClass: 'btn-danger'
                },
              delete: {
                label: 'Delete NGO',
                btnClass: 'btn-danger',
                method: rejectNonprofit
              }
            }}
            />
             { showRejectActions && (
            <div className="col-md-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <p>
                    From: <strong><em>partners@give.do</em></strong><br/>
                    To: <strong><em>{formValues.nonprofits?.email}</em></strong>
                  </p>
                  <div className="form-group">
                    <label>Email Subject</label>
                    <input 
                      className="form-control"										
                      name="subject"
                      value={rejectionEmailData?.subject}										
                      placeholder=" Application Rejected because of Registration Details"										
                      onChange={handleRejectionEmailChanges}
                    />                  
                  </div>				
                  <div className="form-group">
                    <label>Email Body</label>																
                    <Editor value={rejectionEmailData?.body} name="body" 
                      onChange={e => handleRejectionEmailChanges(e, 'body')} 
                    />
                  </div>
                  <button className="btn btn-danger" type="submit" onClick={() => rejectNonprofit()}>Reject &amp; Email </button>
                  &nbsp; <button className="btn btn-primary" type="submit" onClick={cancelRejection}>Cancel </button>
                </div>
              </div>
            </div> 
          )}
          </>
        ): null}
        
        {errors && errors.length ? (
          <div className="col-md-12 grid-margin">
            <div className="card">
              <div className="card-body error-card">
                <h4>Errors [{errors.length}]</h4>
                {errors.map(item => (
                  <>
                  {(item && Object.keys(item).length !== 0) ? (
                    <>
                    <strong>{Helpers.startCase(Object.keys(item)[0])}</strong> is required. <br/>
                    </>
                  ):null}
                  </>
                ))}
                <br/>
                <p>Please resolve these errors to approve the Nonprofit for the selected compliance.</p>
              </div>
            </div>
          </div>
        ): null}
      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  complianceData: state.complianceApproval,
});

const mapActionToProps = dispatch => ({
  ComplianceApproval: bindActionCreators(ComplianceApprovalReducer, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(ComplianceApprovalItem);
