import React, { Component } from 'react';
import Helpers from '../../utils/helpers';
import { GOOGLE_MAPS_API } from '../../config';
import { ENGINE_METHOD_DIGESTS } from 'constants';

class GoogleCityInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    Helpers.loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API}&libraries=places`).then(() => {
      this.initAutocomplete();
    });
  }

  initAutocomplete = () => {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    this.autocomplete = new window.google.maps.places.Autocomplete(this.element, {
      types: ['(cities)'],
      componentRestrictions: { country: 'in' },
    });

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(['address_component']);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autocomplete.addListener('place_changed', this.fillInAddress);
  }

  fillInAddress = () => {
    const { onSelect } = this.props;
    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();
    onSelect({
      city: place.address_components[(place.address_components.length === 4) ? 1 : 0].long_name,
      region: place.address_components[place.address_components.length - 2].long_name,
      country_code: place.address_components[place.address_components.length - 1].short_name,
    });
    this.element.value = '';
  }

  render() {
    return (
      <input type="text" className="form-control" placeholder="Enter city" ref={(e) => { this.element = e; }} />
    );
  }
}

export default GoogleCityInput;
