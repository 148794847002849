import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';

// Get Nonprofits List Flags  
export const GET_LIST_NONPROFITS = 'GET_LIST_NONPROFITS';
export const GET_LIST_NONPROFITS_SUCCESS = 'GET_LIST_NONPROFITS_SUCCESS';
export const GET_LIST_NONPROFITS_FAIL = 'GET_LIST_NONPROFITS_FAIL';

// Get Nonprofit Flags 
export const GET_NONPROFIT = 'GET_NONPROFIT'
export const GET_NONPROFIT_SUCCESS = 'GET_NONPROFIT_SUCCESS'
export const GET_NONPROFIT_FAIL = 'GET_NONPROFIT_FAIL'

// Set Nonprofit Flags 
export const SET_NONPROFIT = 'SET_NONPROFIT'
export const SET_NONPROFIT_SUCCESS = 'SET_NONPROFIT_SUCCESS'
export const SET_NONPROFIT_FAIL = 'SET_NONPROFIT_FAIL'

// Set Nonprofit with Edits 
export const SET_NONPROFIT_EDITS = 'SET_NONPROFIT_EDITS'
export const SET_NONPROFIT_EDITS_FAIL = 'SET_NONPROFIT_EDITS_FAIL'
export const SET_NONPROFIT_EDITS_SUCCESS = 'SET_NONPROFIT_EDITS_SUCCESS'

// Schema Form Flags
export const GET_SCHEMA = 'GET_SCHEMA'
export const GET_SCHEMA_SUCCESS = 'GET_SCHEMA_SUCCESS'
export const GET_SCHEMA_FAIL = 'GET_SCHEMA_FAIL'

// Compliance Check Flags 
export const CHECK_COMPLIANCE = 'CHECK_COMPLIANCE'
export const CHECK_COMPLIANCE_SUCCESS = 'CHECK_COMPLIANCE_SUCCESS'
export const CHECK_COMPLIANCE_FAIL = 'CHECK_COMPLIANCE_FAIL'

// Compliance Renewal Flags 
export const RENEW_COMPLIANCES = 'RENEW_COMPLIANCES'
export const RENEW_COMPLIANCES_SUCCESS = 'RENEW_COMPLIANCES_SUCCESS'
export const RENEW_COMPLIANCES_FAIL = 'RENEW_COMPLIANCES_FAIL'

export const REJECT_NONPROFIT = 'REJECT_NONPROFIT';
export const REJECT_NONPROFIT_SUCCESS = 'REJECT_NONPROFIT_SUCCESS';
export const REJECT_NONPROFIT_FAIL = 'REJECT_NONPROFIT_FAIL'

export const NONPROFIT_LIST_REST = 'NONPROFIT_LIST_REST';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  nonprofits: [],
  get_status: {},
  load_status: {},
  nonprofit: {},
  schema: {},
  errors: {},  
};

export const complianceApprovalReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LIST_NONPROFITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofits: 'pending',
        },
      };
    case GET_NONPROFIT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
      };      
    case SET_NONPROFIT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
      };      
    case SET_NONPROFIT_EDITS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
      };      
    case GET_SCHEMA:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'pending',
        },
      };      
    case CHECK_COMPLIANCE:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          compliance: 'pending',
        },
      };      
    case RENEW_COMPLIANCES:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          compliance: 'pending',
        },
      };      
    case GET_LIST_NONPROFITS_SUCCESS:
      return {
        ...state,
        nonprofits: action.nonprofits,
        get_status: {
          ...state.get_status,
          nonprofits: 'success',
        },
        load_status: {
          ...state.load_status,
          nonprofits: action.load_status,
        }
      };
    case GET_NONPROFIT_SUCCESS:      
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
        load_status: {
          ...state.load_status,
          nonprofit: action.load_status,
        }
      };
    case SET_NONPROFIT_SUCCESS:      
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
        load_status: {
          ...state.load_status,
          nonprofit: action.load_status,
        }
      };
    case SET_NONPROFIT_EDITS_SUCCESS:      
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
        load_status: {
          ...state.load_status,
          nonprofit: action.load_status,
        }
      };
    case GET_SCHEMA_SUCCESS:      
      return {
        ...state,
        schema: action.schema,
        get_status: {
          ...state.get_status,
          schema: 'success',
        },
        load_status: {
          ...state.load_status,
          schema: action.load_status,
        }
      };
    case CHECK_COMPLIANCE_SUCCESS:      
      return {
        ...state,
        nonprofit: action.schema,
        get_status: {
          ...state.get_status,
          compliance: 'success',
        },
        load_status: {
          ...state.load_status,
          compliance: action.load_status,
        }
      };
    case RENEW_COMPLIANCES_SUCCESS:      
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          compliance: 'success',
        },
        load_status: {
          ...state.load_status,
          compliance: action.load_status,
        }
      };
    case GET_LIST_NONPROFITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofits: 'failed',
        }        
      };
    case GET_NONPROFIT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        }       
      };
    case SET_NONPROFIT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors
        }
      };
    case SET_NONPROFIT_EDITS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors
        }
      };
    case GET_SCHEMA_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          schema: 'failed',
        },
        errors: {
          ...state.errors,
          schema: action.errors
        }
      };
    case CHECK_COMPLIANCE_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          compliance: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors
        }
      };
    case RENEW_COMPLIANCES_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          compliance: 'failed',
        },
        errors: {
          ...state.errors,
          nonprofit: action.errors
        }
      };
    case NONPROFIT_LIST_REST:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getNonprofits(query) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_LIST_NONPROFITS,
    });    
    const currentNonprofits = getState().complianceApproval.nonprofits;
    const params = {
      skip: currentNonprofits.length,
      limit: 20,
      sort: '-updated_at',
      ...query,
    };
    
    return NonProfitService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_LIST_NONPROFITS_SUCCESS,
            nonprofits: currentNonprofits,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_LIST_NONPROFITS_SUCCESS,
            nonprofits: [...currentNonprofits, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_NONPROFITS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}


export function getNonprofit(request) {
  return (dispatch) => {
    dispatch({
      type: GET_NONPROFIT,
    });
    return NonProfitService.get(request)
      .then((response) => {        
        dispatch({
          type: GET_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_NONPROFIT_FAIL          
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function setNonprofit(data, method = 'put') {
  return (dispatch) => {
    dispatch({
      type: SET_NONPROFIT,
    });
    return NonProfitService.set({ data, method: method, path: [data._id] })
      .then((response) => {
        dispatch({
          type: SET_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_NONPROFIT_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));          
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_NONPROFIT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));          
        } else {
          dispatch({
            type: SET_NONPROFIT_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));        
      });
  };
}

export function getFormSchema(request){
  const data = request
  return (dispatch) => {
    dispatch({
      type: GET_SCHEMA
    })
    return NonProfitService.getSchema(request)
      .then((response) => {        
        dispatch({
          type: GET_SCHEMA_SUCCESS,
          // type: GET_SCHEMA_FAIL,
          schema: response,
        })
      })
      .catch((error) => {
        dispatch({
          type: GET_SCHEMA_FAIL,
          errors: error
        })
        dispatch(NotificationAction.addNotification(error.message));
      })              
  }
}

export function checkCompliance(id, data) {
  return (dispatch) => {
    dispatch({
      type: CHECK_COMPLIANCE,
    });
    const request = {};
    request.method = 'put';
    request.path = [id];
    request.data = data;
    return NonProfitService.set(request)
      .then((response) => {
        dispatch({
          type: CHECK_COMPLIANCE_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: CHECK_COMPLIANCE_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Compliance check failed. Please check the errors in the form.'));          
        } else if (error.value && error.value.error) {
          dispatch({
            type: CHECK_COMPLIANCE_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));          
        } else {
          dispatch({
            type: CHECK_COMPLIANCE_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));        
      });
  };
}

export function renewCompliances(nonprofit, keys) {
  return (dispatch) => {
    dispatch({
      type: RENEW_COMPLIANCES,
    })

    const request = {}
    request.method = 'put';        
    request.data = { 
      compliance_keys: keys 
    }   
    return NonProfitService.complianceRenewal(nonprofit._id, request)
      .then((response) => {
        dispatch({
          type: RENEW_COMPLIANCES_SUCCESS,
          nonprofit: response.data,
        });
        dispatch(NotificationAction.addNotification('Saved successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: RENEW_COMPLIANCES_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Compliance check failed. Please check the errors in the form.'));                    
        } else if (error.value && error.value.error) {
          dispatch({
            type: RENEW_COMPLIANCES_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));                    
        } else {
          dispatch({
            type: RENEW_COMPLIANCES_FAIL,
            errors: {},
          });
        }        
        dispatch(NotificationAction.addNotification(error.message));        
        throw error
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: NONPROFIT_LIST_REST,
    });
  };
}

export function rejectNonprofit(data) {
  return (dispatch) => {
    dispatch({
      type: REJECT_NONPROFIT,
    });
    return NonProfitService.reject({ path: [data._id, 'reject'], data, method: 'post' })
      .then((response) => {
        dispatch({
          type: REJECT_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        dispatch(NotificationAction.addNotification('Rejected successfully', 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: REJECT_NONPROFIT_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));          
        } else if (error.value && error.value.error) {
          dispatch({
            type: REJECT_NONPROFIT_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));          
        } else {
          dispatch({
            type: REJECT_NONPROFIT_FAIL,
            errors: {},
          });
        }       
      });
  };
}

export function setNonprofitWithEdits(id, data) {
  return (dispatch) => {
    dispatch({
      type: SET_NONPROFIT_EDITS,
    });
    const request = {};
    request.method = 'put';
    request.path = [id, 'apply-edits'];
    request.data = data;
    return NonProfitService.set(request)
      .then((response) => {
        dispatch({
          type: SET_NONPROFIT_EDITS_SUCCESS,
          nonprofit: response.data,
        });        
        return response.data;
      })
      .catch((error) => {
        if (error.value && error.value.errors) {
          dispatch({
            type: SET_NONPROFIT_EDITS_FAIL,
            errors: error.value.errors,
          });
          dispatch(NotificationAction.addNotification('Please fix all errors in Non-profit form'));          
        } else if (error.value && error.value.error) {
          dispatch({
            type: SET_NONPROFIT_EDITS_FAIL,
            errors: {},
          });
          dispatch(NotificationAction.addNotification(error.value.error));          
        } else {
          dispatch({
            type: SET_NONPROFIT_EDITS_FAIL,
            errors: {},
          });
        }
        dispatch(NotificationAction.addNotification(error.message));        
      });
  };
}