import profile from './profile';
import admin from './admin';
import crowdfunding from './crowdfunding';
// import monthly from './monthly';
import adminApprovals from './adminApprovals';
import disbursements from './disbursements';
import support from './support';

const routes = [profile, crowdfunding, admin, adminApprovals, disbursements, support];

export default routes;
