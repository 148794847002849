import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import Spinner from "react-bootstrap/Spinner";
import { baseUrl } from "../../config";

const useStyles = makeStyles((theme) => ({
  fundText: {
    textAlign: "center",
    margin: "30px 0",
    padding: "0 16px",
  },
  fundWrapper: {
    maxWidth: "1500px",
    margin: "0 auto",
    padding: "0 32px",
  },
  fundPara: {
    textAlign: "center",
    width: "60%",
    margin: "0 auto",
    marginBottom: "50px",
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "0px",
    },
  },
  fucTableWrapper: {
    margin: "50px 0 20px 0",
    overflowY: "hidden",
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    margin: "20px 0",
    fontSize: "16px",
    textAlign: "left",
    "& td": {
      padding: "10px",
    },
    "& thead": {
      background: "#f6716c",
    },
    "& thead td": {
      padding: "10px",
    },
    "& thead th": {
      borderTop: "1px solid #f2f2f2",
      borderRight: "1px solid #f2f2f2",
      borderBottom: "0",
      color: "#ffffff",
      fontSize: "16px",
      lineHeight: 1.1,
      whiteSpace: "nowrap",
      padding: "10px",
      fontWeight: 100,
    },
    "& tbody": {
      borderBottom: "1px solid #f2f2f2",
      "& td": {
        borderRight: "1px solid #f2f2f2",
        borderTop: 0,
      },
      "& tr > td:first-child": {
        borderLeft: "1px solid #f2f2f2",
        borderTop: 0,
      },
    },
  },
  tablestriped: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "#ffffff",
    },
  },
  tablestriped: {
    "& tbody tr:nth-of-type(even)": {
      backgroundColor: "#f5f5f5",
    },
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    padding: "8px",
    fontSize: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxShadow: "rgb(230, 235, 241) 0px 1px 3px 0px",
    borderRadius: "5px",
  },
  spinner_fuc: {
    marginRight: "10px",
  },
  btn: {
    margin: "3rem 0",
    textAlign: "center",
  },
  submitbtn: {
    backgroundColor: "#f6716c",
    border: "#f6716c",
    padding: "9px 80px",
    color: "#fff",
    borderRadius: "5px",
    letterSpacing: "0.8px",
  },
  logo: {
    height: "60px",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
    },
  },
  logoWrapper: {
    padding: "20px 32px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
    },
  },
  successScreen: {
    textAlign: 'center',
    fontSize: '20px',
  },
  invalidFeedback: {
    fontSize : '16px', 
    color : "#f44336",
    padding:'32px'
  },
  footer: {
    textAlign: "center",
    marginBottom: "3rem"
  },
  errorMsg: {
    color: 'red',
    fontSize: '14px',
    paddingTop: '5px'
  },
  btndisabled: {
    opacity: 0.7
  }
}));
const FundUtlisationCertificate = () => {
  const classes = useStyles();
  const [fucRecords, setFucRecords] = useState([]);
  const [formActionInProgress, setFormActionInProgress] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [allFieldsEntered, setAllFieldsEntered] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const key = urlParams.get("key");
    const apiUrl = `${baseUrl}/api/nonprofits/fuc/${key}`;

    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const updatedResult = result?.map((record) => ({
          ...record,

          indian_utilized: record.indian_disbursed === 0 && 0,
          sathire_utilized: record.sathire_disbursed === 0 && 0,
          uk_utilized: record.uk_disbursed === 0 && 0,
          us_utilized: record.us_disbursed === 0 && 0,

          indianCarryForward: record.indian_disbursed - (record.indian_utilized || 0),
          USCarryForward: record.us_disbursed - (record.us_utilized || 0),
          UKCarryForward: record.uk_disbursed - (record.uk_utilized || 0),
          SathireCarryForward: record.sathire_disbursed - (record.sathire_utilized || 0),

          indianMinAllowed: Math.round(record.indian_disbursed - record.indian_carry_forward_max),
          USMinAllowed: Math.round(record.us_disbursed - record.us_carry_forward_max),
          UKMinAllowed: Math.round(record.uk_disbursed - record.uk_max_carry_forward),
          SathireMinAllowed: Math.round(record.sathire_disbursed - record.sathire_max_carry_forward),
        }));

        setFucRecords(updatedResult);
      } catch (error) {
        console.log("Error in loading data")
      }
    };

    fetchData();
  }, []);

  function getPreviousFinancialYear() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const month = today.getMonth() + 1;

    let financialYear;
    if (month >= 4) {
        financialYear = `${currentYear - 1}-${currentYear}`;
    } else {
        financialYear = `${currentYear - 2}-${currentYear - 1}`;
    }

    return financialYear;
}

  const fucHeaderData = [
    {
      name: "Give Foundation (India - in INR)",
      columns: ["Received", "Utilisation", "Carry Forward"],
    },
    {
      name: "Give Foundation Inc. (USA - in USD)",
      columns: ["Received", "Utilisation", "Carry Forward"],
    },
    {

      name: " Giving Impetus To Voluntary Effort (UK - in GBP)",
      columns: ["Received", "Utilisation", "Carry Forward"],
    },
    {
      name: "Saathire Social Impact Solutions Pvt Ltd (India - in INR)",
      columns: ["Received", "Utilisation", "Carry Forward"],
    },
  ];
 
  const checkAllFieldsEntered = () => {
    const allFieldsFilled = fucRecords?.every(record => {
      const isIndianValid = (
        record.indian_utilized !== undefined &&
        record.indian_utilized !== '' &&
        record.indian_utilized !== false  &&
        !isNaN(record.indian_utilized) && 
        Math.round(record.indian_utilized) >= record.indianMinAllowed &&
        Math.round(record.indian_utilized) <= Math.round(record.indian_disbursed)
      );
  
      const isUSValid = (
        record.us_utilized !== undefined &&
        record.us_utilized !== '' &&
        record.indian_utilized !== false &&
        !isNaN(record.us_utilized) && 
        Math.round(record.us_utilized) >= record.USMinAllowed &&
        Math.round(record.us_utilized) <= Math.round(record.us_disbursed)
      );
  
      const isUKValid = (
        record.uk_utilized !== undefined &&
        record.uk_utilized !== '' &&
        record.uk_utilized !== false &&
        !isNaN(record.uk_utilized) && 
        Math.round(record.uk_utilized) >= record.UKMinAllowed &&
        Math.round(record.uk_utilized) <= Math.round(record.uk_disbursed)
      );
  
      const isSaathireValid = (
        record.sathire_utilized !== undefined &&
        record.sathire_utilized !== '' &&
        record.sathire_utilized !== false &&
        !isNaN(record.sathire_utilized) && 
        Math.round(record.sathire_utilized) >= record.SathireMinAllowed &&
        Math.round(record.sathire_utilized) <= Math.round(record.sathire_disbursed)
      );
  
      return isIndianValid && isUSValid && isUKValid && isSaathireValid;
    });
  
    setAllFieldsEntered(allFieldsFilled || false); 
  };
  

  useEffect(() => {
    checkAllFieldsEntered();
  }, [fucRecords]);

  const handleUtilisationInput = (event, record, type) => {
    let value = parseInt(event.target.value);

    switch (type) {
      case "indian":
        record.indian_utilized = value;
        record.indianCarryForward = Math.round(record.indian_disbursed - (value || 0)) ;
        break;
      case "us":
        record.us_utilized = value;
        record.USCarryForward = Math.round(record.us_disbursed - (value || 0)) ;
        break;
      case "uk":
        record.uk_utilized = value;
        record.UKCarryForward = Math.round(record.uk_disbursed - (value || 0)) ;
        break;
      case "sathire":
        record.sathire_utilized = value;
        record.SathireCarryForward = Math.round(record.sathire_disbursed - (value || 0));
        break;
    }
    setSubmitError(false)
    setFucRecords([...fucRecords]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setFormActionInProgress(true);

    const urlParams = new URLSearchParams(window?.location?.search);
    const key = urlParams.get("key");

    const dataToSubmit = fucRecords.map((record) => ({
      id: record._id,
      indian_utilized: record.indian_utilized,
      us_utilized: record.us_utilized,
      uk_utilized: record.uk_utilized,
      sathire_utilized: record.sathire_utilized,
    }));

    const submitFormResponse = await fetch(`${baseUrl}/api/nonprofits/fuc/${key}`, {
      method: "POST",
      body: JSON.stringify(dataToSubmit),
      headers: {
        'content-type': 'application/json'
      },
    });
    if (submitFormResponse.status === 200) {
      setFormActionInProgress(false);
      setShowThankYou(true);
      setSubmitError(false)
    } else {
      setSubmitError(true)
      setFormActionInProgress(false);
    }
  };
  const alreadySubmittedMessage =
    "Fund utilisation data already recorded. Please contact your RM if you need to make changes.";
  const thankYouMessage =
    "Fund utilisation data successfully recorded. Please check your email for next steps.";

  return (
    <Box className={classes.fundWrapper}>
      <div className={classes.logoWrapper}>
        <a className={classes.navbarLogo} href="/">
          <img src={"https://cfstatic.give.do/logo.png"} className={classes.logo} alt="" />
        </a>
      </div>
      <Typography variant="h4" className={classes.fundText}>
        Fund Utilisation Certificate
      </Typography>

      { showThankYou || fucRecords[0]?.status === "submitted" ? (
        showThankYou ? (
          <p className={classes.successScreen}>{thankYouMessage}</p>
        ) : (
          <p className={classes.successScreen}>{alreadySubmittedMessage}</p>
        )
      ) : (
        <>
          <Typography className={classes.fundPara}>
            Please submit this form to declare your fund utilisation for the financial year{" "}
            {`${getPreviousFinancialYear()}`}. We request you to carefully fill the data according to
            the money utilised as per your book of accounts. Please note that you will have to
            declare 100% utilisation for all funds under "Carry forward + Dedo + Bounce back"
            category are allowed to carry-forward only the funds raised in January - March FY-
            {`${getPreviousFinancialYear()}`}.{" "}
            <b>The Deadline for FUC submission is before the end of this month </b>
            but we request you to submit this as soon as possible so we can address changes, if
            necessary.
          </Typography>
          <div className="row">
            <div className="col-12">
              <form onSubmit={handleFormSubmit}>
                <div className={classes.fucTableWrapper}>
                  <table className={`${classes.table} ${classes.tablestriped}`}>
                    <thead>
                      <tr>
                        <th rowSpan="2">Program Name</th>
                        {fucHeaderData.map((header, index) => (
                          <th key={index} className="text-center" colSpan="3">
                            {header.name}
                          </th>
                        ))}
                      </tr>
                      <tr>
                        {fucHeaderData.map((header, index) =>
                          header.columns.map((column, subIndex) => (
                            <th key={`${index}-${subIndex}`}>{column}</th>
                          ))
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {fucRecords?.map((record, idx) => (
                        <tr key={idx}>
                          <td className="align-middle">{record.donation_option}</td>
                          <td className="align-middle">{record.indian_disbursed}</td>
                          <td className="align-middle">
                            <div className={classes.formGroup}>
                              <input
                                type="number"
                                className={classes.formControl}
                                placeholder={'Amount'}
                                value={
                                  record.indian_utilized === 0 || record.indian_utilized
                                    ? record.indian_utilized
                                    : ""
                                }
                                disabled={record.indian_disbursed === 0}
                                onChange={(event) =>
                                  handleUtilisationInput(event, record, "indian")
                                }
                              />
                              {
                                record.indian_utilized === false || isNaN(record.indian_utilized)? <span className={classes.errorMsg}>value is required</span> : 
                                (record.indian_utilized < record.indianMinAllowed || record.indian_utilized > record.indian_disbursed) ?
                                <span className={classes.errorMsg}>Value must be greater than or equal to {`${record.indianMinAllowed}`}</span> : null
                              }
                            </div>
                          </td>
                          <td className="align-middle">{Math.round(record.indianCarryForward)}</td>
                          <td className="align-middle">{record.us_disbursed}</td>
                          <td className="align-middle">
                            <div className={classes.formGroup}>
                              <input
                                type="number"
                                className={classes.formControl}
                                placeholder={'Amount'}
                                value={ record.us_utilized === 0 || record.us_utilized
                                  ? record.us_utilized
                                  : ""}
                                disabled={record.us_disbursed === 0}
                                onChange={(event) => handleUtilisationInput(event, record, "us")}
                              />
                            </div>
                            {
                                record.us_utilized === false || isNaN(record.us_utilized) ? <span className={classes.errorMsg}>value is required</span> : 
                                (record.us_utilized < record.USMinAllowed || record.us_utilized > record.us_disbursed) ?
                                <span className={classes.errorMsg}>Value must be greater than or equal to {`${record.USMinAllowed}`}</span> : null
                              }
                          </td>
                          <td className="align-middle">{Math.round(record.USCarryForward)}</td>
                          <td className="align-middle">{record.uk_disbursed}</td>
                          <td className="align-middle">
                            <div className={classes.formGroup}>
                              <input
                                type="number"
                                className={classes.formControl}
                                placeholder={'Amount'}
                                value={ record.uk_utilized === 0 || record.uk_utilized
                                  ? record.uk_utilized
                                  : ""}
                                disabled={record.uk_disbursed === 0}
                                onChange={(event) => handleUtilisationInput(event, record, "uk")}
                              />
                            </div>
                            {
                                record.uk_utilized === false || isNaN(record.uk_utilized) ? <span className={classes.errorMsg}>value is required</span> : 
                                (record.uk_utilized < record.UKMinAllowed || record.uk_utilized > record.uk_disbursed) ?
                                <span className={classes.errorMsg}>Value must be greater than or equal to {`${record.UKMinAllowed}`}</span> : null
                              }
                          </td>
                          <td className="align-middle">{Math.round(record.UKCarryForward)}</td>
                          <td className="align-middle">{record.sathire_disbursed}</td>
                          <td className="align-middle">
                            <div className={classes.formGroup}>
                              <input
                                type="number"
                                className={classes.formControl}
                                placeholder={'Amount'}
                                value={ record.sathire_utilized === 0 || record.sathire_utilized
                                  ? record.sathire_utilized
                                  : ""}
                                disabled={record.sathire_disbursed === 0}
                                onChange={(event) =>
                                  handleUtilisationInput(event, record, "sathire")
                                }
                              />
                                 {
                                record.sathire_utilized === false || isNaN(record.sathire_utilized) ? <span className={classes.errorMsg}>value is required</span> : 
                                (record.sathire_utilized < record.SathireMinAllowed || record.sathire_utilized > record.sathire_disbursed) ?
                                <span className={classes.errorMsg}>Value must be greater than or equal to {`${record.SathireMinAllowed}`}</span> : null
                              }
                            </div>
                          </td>
                          <td className="align-middle">{Math.round(record.SathireCarryForward)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className={classes.btn}>
                  <button className={`${classes.submitbtn} ${!allFieldsEntered ? classes.btndisabled : ''}`}  style={{ cursor: !allFieldsEntered ? 'not-allowed' : 'pointer' }}
                    disabled={!allFieldsEntered}>
                    {formActionInProgress ? (
                      <Spinner animation="border" size="sm" className={classes.spinner_fuc} />
                    ) : (
                      ""
                    )}
                    Submit
                  </button>
                  {submitError && <div className={classes.invalidFeedback}>Submission failed. Please try again or reach out to your RM.</div>}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </Box>
  );
};
export default FundUtlisationCertificate;
