/**
 * Created by nitinkushwaha
 * message: string
 * value.errors: json
 * value.location: URL or path
 */
export default class _Error {
  constructor(message = 'Something went wrong! Please try again later!', value = {}) {
    this.value = value;
    this.message = message;
  }

  toString = () => (this.value + this.message);
}
