import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import * as LoginAction from '../../../reducers/loginReducer';
import * as nonprofitsActions from '../../../reducers/Profile/nonprofitsReducer';

import Navbar from '../../../appComponents/Navbar';
import ViewType from '../../../appComponents/ViewType';
import ScrollToTop from '../../../appComponents/ScrollToTop';
import Footer from '../../../appComponents/Footer';

import Sidebar from '../../../appComponents/Sidebar';
import crowdfundingRoutes from '../../../routes/productRoutes/crowdfunding';
import MigrationNotification from '../../../appComponents/MigrationNotification';
class Home extends Component {
  componentDidMount() {
    const { nonprofits, Nonprofits, match } = this.props;
    const { nonprofit } = match.params;
    if (!nonprofits.get_status.nonprofit) {
      Nonprofits.getNonprofit({ path: [nonprofit] });
    }
  }

  render() {    
    const { Login, login, location, nonprofits, match } = this.props;
    const params = { nonprofit: match.params.nonprofit, compliance: nonprofits.nonprofit.required_compliances }    
    let userType = 'user';
    if (login.user.is_admin) {
      userType = 'admin';
    }
    return (
      <ScrollToTop location={location}>
        <div className="container-scroller">
          {/* Adds notification */}
          {/* <MigrationNotification /> */}
          <Navbar
            Login={Login}
            login={login}
            location={location}
            user={userType}
            routesList={crowdfundingRoutes}
            params={params}
          />
          <div className="container-fluid page-body-wrapper sidebar-fixed">
            <Sidebar
              location={location}
              routesList={crowdfundingRoutes}
              params={params}
            />
            <div className="main-panel">
              <ViewType user={userType} routesList={crowdfundingRoutes} />
              <Footer />
            </div>
          </div>
        </div>
      </ScrollToTop>
    );
  }
}
const mapStateToProps = state => ({
  login: state.login,
  nonprofits: state.profileNonprofits,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
  Nonprofits: bindActionCreators(nonprofitsActions, dispatch),
});

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
)(Home));
