import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as CauseAndDemographicAction from '../../reducers/CauseAndDemographicReducer';

import PhoneInput from '../PhoneInput';

import Helpers from '../../utils/helpers';

import SingleFileUpload from '../SingleFileUpload/SingleFileUpload';
import ImageCropUpload from '../ImageCropUpload';
import Editor from '../Editor';
import SelectInput from '../SelectInput';
import Icon from '@mdi/react';
import {
  mdiDelete
} from '@mdi/js'
import { format } from 'date-fns'

const regionOptions = [
  { label: 'Andaman & Nicobar Islands', value: 'Andaman & Nicobar Islands' },
  { label: 'Andhra Pradesh', value: 'Andhra Pradesh' },
  { label: 'Assam', value: 'Assam' },
  { label: 'Chandigarh', value: 'Chandigarh' },
  { label: 'Dadra & Nagar Haveli', value: 'Dadra & Nagar Haveli' },
  { label: 'Delhi', value: 'Delhi' },
  { label: 'Gujarat', value: 'Gujarat' },
  { label: 'Haryana', value: 'Haryana' },
  { label: 'Himachal Pradesh', value: 'Himachal Pradesh' },
  { label: 'Jammu & Kashmir', value: 'Jammu & Kashmir' },
  { label: 'Kerala', value: 'Kerala' },
  { label: 'Lakshadweep', value: 'Lakshadweep' },
  { label: 'Maharashtra', value: 'Maharashtra' },
  { label: 'Manipur', value: 'Manipur' },
  { label: 'Meghalaya', value: 'Meghalaya' },
  { label: 'Karnataka', value: 'Karnataka' },
  { label: 'Nagaland', value: 'Nagaland' },
  { label: 'Odisha', value: 'Odisha' },
  { label: 'Pondicherry', value: 'Pondicherry' },
  { label: 'Punjab', value: 'Punjab' },
  { label: 'Rajasthan', value: 'Rajasthan' },
  { label: 'Tamil Nadu', value: 'Tamil Nadu' },
  { label: 'Tripura', value: 'Tripura' },
  { label: 'West Bengal', value: 'West Bengal' },
  { label: 'Sikkim', value: 'Sikkim' },
  { label: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
  { label: 'Mizoram', value: 'Mizoram' },
  { label: 'Daman & Diu', value: 'Daman & Diu' },
  { label: 'Goa', value: 'Goa' },
  { label: 'Bihar', value: 'Bihar' },
  { label: 'Madhya Pradesh', value: 'Madhya Pradesh' },
  { label: 'Uttar Pradesh', value: 'Uttar Pradesh' },
  { label: 'Chattisgarh', value: 'Chattisgarh' },
  { label: 'Jharkhand', value: 'Jharkhand' },
  { label: 'Uttarakhand', value: 'Uttarakhand' },
  { label: 'Telangana', value: 'Telangana' },
];


class NonprofitBasicDetailsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFCRA: '',
      isRegisteredName: '',
      changes: { _id: props.value._id },
      haveGST: false,
      isGSTReg: false,
      isNonProfitLogin: props?.isNonProfitLogin || false,
      isNonProfit80G: props?.value?.is80gValid || false,
      is80G: '',
      causeList: [...props?.value?.causes],
      demographicList: [...props?.value?.demographics],
      can_accept_donations_without_fcra: "",
      backupFcra: [],
      causeFullList: [],
      demographicFullList: [],
      genderList: []
    };
  }
  async componentDidMount() {
    const state = {};
    const { CauseAndDemographics } = this.props
    CauseAndDemographics.getCauses().then((res) => {
      if (res) {
        this.setState({ causeFullList: res })
      }
    })
    CauseAndDemographics.getDemographics().then((res) => {
      if (res) {
        const getGenderList = res.map((_d) => _d.gender)
        const selectedDGs = this.props?.value?.demographics
        const list = [...res]
        const modifiedArr= selectedDGs.map((_d, i) => {
          const filterByGender = list.filter((_l)=>_l.gender === _d.gender)
          const filterByAge = filterByGender&&filterByGender.length?filterByGender[0].ages.filter((_a)=>_a.age === _d.age):[]
          const ages = filterByGender && filterByGender.length ?Array.from(new Set(filterByGender[0].ages.map(_a => _a.age))) :[]
          const roles = filterByAge && filterByAge.length ? filterByAge[0].roles:[]
          return{
            ..._d,
            roleList:roles,
            ageList:ages
          }
        })
        this.setState({ demographicFullList: res, genderList: getGenderList,demographicList:modifiedArr })

      }
    })
    if (this.getInputData(['nonprofit', 'fcra']) !== ''
      || this.getInputData(['nonprofit', 'fcra_doc']) !== '') {
      state.isFCRA = 'yes';
    } else {
      state.isFCRA = 'no';
    }
    if (this.getInputData(['nonprofit', 'can_accept_donations_without_fcra']) === true) {
      state.can_accept_donations_without_fcra = 'yes';
    } else {
      state.can_accept_donations_without_fcra = 'no';
    }

    if (this.getInputData(['nonprofit', 'bank_accounts'])) {
      let bankAccounts = this.getInputData(['nonprofit', 'bank_accounts',]);
      state.backupFcra = bankAccounts
    }

    if (this.getInputData(['nonprofit', 'official_name']) !== '') {
      if (this.getInputData(['nonprofit', 'name']) === this.getInputData(['nonprofit', 'official_name'])) {
        state.isRegisteredName = 'yes';
      } else {
        state.isRegisteredName = 'no';
      }
    }
    if (this.getInputData(['nonprofit', 'gst_details', 'gst_number']) !== '' && this.getInputData(['nonprofit', 'gst_details', 'gst_number']) !== undefined) {
      state.haveGST = true
    }

    if (this.getInputData(['nonprofit', 'gst_details', 'gst_address']) === '') {
      if (this.getInputData(['nonprofit', 'gst_details', 'gst_number']) !== '') {
        state.changes = {
          ...this.state.changes,
          gst_details: {
            ...this.getInputData(['nonprofit', 'gst_details']),
            gst_address: this.getInputData(['nonprofit', 'address'])
          }
        }
      }
    }
    else {
      // Checking if GST Address Value is Similar to the existing Address 
      if (!Helpers.isEqual(this.getInputData(['nonprofit', 'address']), this.getInputData(['nonprofit', 'gst_details', 'gst_address']))) state.isGSTReg = true
    }

    if (this.getInputData(['nonprofit', '80g']) !== '' && this.getInputData(['nonprofit', '80g']) !== null) {
      state.is80G = 'yes'
    }
    else {
      state.is80G = 'no'
    }

    this.setState(state);
  }

  handelInputChange = (key, e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    if (onChange) {
      const change = Helpers.setDataToObject(value, key, e);
      this.setState({ changes: { ...changes, ...change.changes } });
      onChange(change.data);
    }
  }

  getInputData = (key) => {
    const { value } = this.props;
    return Helpers.getDataFromObject(value, key);
  }

  getInputErrors = (key) => {
    const { value, errors } = this.props;
    const { state } = this;
    return Helpers.getErrorMessageFromObject(errors, key, value, state);
  }

  handelSubmit = (e) => {
    const { onSubmit, login } = this.props;
    const { changes, isNonProfit80G, can_accept_donations_without_fcra } = this.state;
    const payloadWith80g = { ...changes, is80gValid: !login?.user?.is_admin ? undefined : isNonProfit80G, can_accept_donations_without_fcra: can_accept_donations_without_fcra === "yes" ? true : false }
    e.preventDefault();
    if (onSubmit) {
      onSubmit(payloadWith80g);
    }
  }
  onWithoutFCRAChange = (e) => {
    const { value, onChange } = this.props;
    const { changes, can_accept_donations_without_fcra, backupFcra } = this.state;
    let nonInternationalBankDeets = [];
    let bankAccountsChanges = [];
    let data = value;
    let updatedObject = {};
    this.setState({ ...this.state, can_accept_donations_without_fcra: e.target.value });
    let bankAccounts = this.getInputData(['nonprofit', 'bank_accounts',]);
    nonInternationalBankDeets = bankAccounts.filter((account) => account.is_international === false);

    if (can_accept_donations_without_fcra === "yes") {
      updatedObject = Helpers.replaceBankAccountData(data, backupFcra);
    }
    else {
      updatedObject = Helpers.replaceBankAccountData(data, nonInternationalBankDeets);
    }
    bankAccountsChanges = { ...updatedObject.changes };
    data = { ...data, ...updatedObject.data };
    onChange(data);
    this.setState({ changes: { ...changes, ...bankAccountsChanges } });

  }
  onFCRAChange = (e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    this.setState({ ...this.state, isFCRA: e.target.value });

    let FCRAChanges = {};
    let data = value;
    const fcraValues = [
      ['nonprofit', 'fcra'],
      ['nonprofit', 'fcra_doc'],
      ['nonprofit', 'fcra_validity'],
    ];

    let updatedObject = {};
    if (e.target.value === 'no') {
      fcraValues.forEach(key => {
        if (key.indexOf('fcra_doc') >= 0) {
          updatedObject = Helpers.setDataToObject(data, key, '');
          data = { ...data, ...updatedObject.data }
          FCRAChanges = { ...FCRAChanges, ...updatedObject.changes };
        }
        else if (key.indexOf('fcra') >= 0 || key.indexOf('fcra_validity') >= 0) {
          updatedObject = Helpers.setDataToObject(data, key, { target: { value: '' } });
          FCRAChanges = { ...FCRAChanges, ...updatedObject.changes };
          data = { ...data, ...updatedObject.data };
        }
      });

      // Update Data to state
      onChange(data);
      this.setState({ changes: { ...changes, ...FCRAChanges } });
    }
  }
  on80GChange = (e) => {
    const { value, onChange } = this.props;
    const { changes } = this.state;
    this.setState({ ...this.state, is80G: e.target.value });

    let expiryValuesChanges = {};
    let data = value;
    const expiryValues = [
      ['nonprofit', '80g'],
      ['nonprofit', '80g_doc'],
      ['nonprofit', '80g_expiry_date'],
    ];

    let updatedObject = {};
    if (e.target.value === 'no') {
      expiryValues.forEach(key => {
        if (key.indexOf('80g_doc') >= 0) {
          updatedObject = Helpers.setDataToObject(data, key, '');
          data = { ...data, ...updatedObject.data }
          expiryValuesChanges = { ...expiryValuesChanges, ...updatedObject.changes };
        }
        else if (key.indexOf('80g') >= 0 || key.indexOf('80g_expiry_date') >= 0) {
          updatedObject = Helpers.setDataToObject(data, key, { target: { value: '' } });
          expiryValuesChanges = { ...expiryValuesChanges, ...updatedObject.changes };
          data = { ...data, ...updatedObject.data };
        }
      });

      // Update Data to state
      onChange(data);
      this.setState({ changes: { ...changes, ...expiryValuesChanges } });
    }
  }

  onGSTChange = () => {
    if (!this.state.haveGST) {

      const this_changes = {
        ...this.state.changes,
        gst_details: {
          ...this.getInputData(['nonprofit', 'gst_details']),
          gst_address: this.getInputData(['nonprofit', 'address'])
        }
      }

      this.setState({
        haveGST: !this.state.haveGST,
        changes: this_changes
      })

      const { value, onChange } = this.props
      const data = { ...value, ...this_changes }
      onChange(data);
    }
    else {
      this.setState({
        haveGST: !this.state.haveGST
      })
    }
  }

  onGSTAddressChange = () => {
    // Checking for false condition as toggle will send value as true 
    if (!this.state.isGSTReg) {

      const this_changes = {
        ...this.state.changes,
        gst_details: {
          ...this.getInputData(['nonprofit', 'gst_details']),
          ...this.state.changes.gst_details,
          gst_address: this.getInputData(['nonprofit', 'address'])
        }
      }

      this.setState({
        changes: this_changes
      })

      const { value, onChange } = this.props
      const data = { ...value, ...this_changes }
      onChange(data);
    }
    this.setState({
      isGSTReg: !this.state.isGSTReg
    })

  }

  setRegionSelect = (region) => {
    const e = region.value;
    this.handelInputChange(['nonprofit', 'address', 'region'], e);
  }
  setSubcauses = (scause, i) => {
    // const e = scause[i].value;
    const e = scause && scause.map((_s) => _s.value).join('-')
    this.handelInputChange(['nonprofit', 'causes', 'subcauses', i, 'name'], e);
  }
  getRegionSelect = () => {
    const data = this.getInputData(['nonprofit', 'address', 'region']);
    return { label: data, value: data };
  }
  getDefaultSubCauses = (i) => {
    const data = this.getInputData(['nonprofit', 'causes', i, 'subcauses']);
    return data&&data.length ? data?.map((_c) => ({ label: _c, value: _c })):null
  }
  setGSTAddress = (region) => {
    const e = region.value;
    this.handelInputChange(['nonprofit', 'gst_details', 'gst_address', 'region'], e);
    this.handelInputChange(['nonprofit', 'gst_details', 'gst_address', 'country_code'], 'INDIA');
  }

  getGSTAddress = () => {
    const data = this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'region']);
    return { label: data, value: data };
  }

  on80gValidChange = (e) => {
    this.setState({ isNonProfit80G: e.target.checked })
  }
  handleAddCause = () => {
    const cList = [...this.state.causeList]
    cList.push({ name: '', description: '' })
    this.setState({ causeList: cList })
    this.handelInputChange(['nonprofit', 'causes'], cList);
  }
  handleDeleteCause = (i) => {
    const cList = [...this.state.causeList]
    cList.splice(i, 1)
    this.setState({ causeList: cList })
    this.handelInputChange(['nonprofit', 'causes'], cList);
  }
  handleAddDemographic = () => {
    const cList = [...this.state.demographicList]
    cList.push({ gender: '', age: '', role: '', ageList: [], roleList: [] })
    this.setState({ demographicList: cList })
    this.handelInputChange(['nonprofit', 'demographics'], cList);

  }
  handleDeleteDemographic = (i) => {
    const cList = [...this.state.demographicList]
    cList.splice(i, 1)
    this.setState({ demographicList: cList })
    this.handelInputChange(['nonprofit', 'demographics'], cList);
  }
  getSubCausesList = (causeName) => {
    const { causeFullList } = this.state
    if (causeFullList && causeFullList.length > 0) {
      const subCauseArr = causeFullList.filter((_c) => _c.name === causeName)
      const { subcauses } = subCauseArr[0] || []
      if (subcauses && subcauses.length > 0) {
        const multiOp = subcauses.reduce((prev, current) => {
          prev.push({ value: current.name, label: current.name });
          return prev;
        }, []);
        return multiOp
      }
    } else {
      return []
    }
  }

  render() {
    const { children, config, login, value } = this.props;
    const { country_code } = config.defaults || {};
    const { isRegisteredName, isFCRA, haveGST, isGSTReg, isNonProfit80G, is80G, causeList, demographicList, can_accept_donations_without_fcra, isNonProfitLogin, genderList, demographicFullList } = this.state;

    return (
      <form className="ob-np-form" onSubmit={this.handelSubmit}>
        <div className="row justify-content-md-center">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-20">General Details</h3>
                </div>
                {/* <p className="text-secondary"> Nonprofit general details </p> */}
                <div className="form-group">
                  <label> Name of the Organization</label>
                  {this.getInputErrors(['nonprofit', 'name'])}
                  <input type="text" className="form-control" placeholder="As per documents" value={this.getInputData(['nonprofit', 'name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'name'], e); }} />
                </div>

                <div className="form-group row">
                  <label className="col-md-12 col-form-label">The name is same as mentioned in your registration certificate?</label>
                  {this.getInputErrors(['nonprofit', 'isRegisteredName'])}
                  <div className="col-sm-6">
                    <div className="form-radio">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="isRegisteredName" value="yes" checked={isRegisteredName === 'yes'} onChange={(e) => { this.setState({ isRegisteredName: e.target.value }) }} /> Yes <i className="input-helper"></i></label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-radio">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="isRegisteredName" value="no" checked={isRegisteredName === 'no'} onChange={(e) => { this.setState({ isRegisteredName: e.target.value }) }} /> No <i className="input-helper"></i></label>
                    </div>
                  </div>
                </div>
                {(isRegisteredName === 'no') ? (
                  <div className="form-group">
                    <label> Registered Name (as per the Registration Certificate/PAN Card)</label>
                    {this.getInputErrors(['nonprofit', 'official_name'])}
                    <input type="text" className="form-control" placeholder="To be displayed" value={this.getInputData(['nonprofit', 'official_name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'official_name'], e); }} />
                  </div>
                ) : null}
                <div className="form-group">
                  <label> About the Organization</label>
                  {this.getInputErrors(['nonprofit', 'description'])}
                  <Editor value={this.getInputData(['nonprofit', 'description'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'description'], e); }} />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> NGO Type</label>
                      {this.getInputErrors(['nonprofit', 'registration_type'])}
                      <select className="form-control" value={this.getInputData(['nonprofit', 'registration_type'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'registration_type'], e); }}>
                        <option disabled hidden value="">Select</option>
                        <option value="Trust">Trust</option>
                        <option value="Society">Society</option>
                        <option value="Section 25">Section 25</option>
                        <option value="Section 7">Section 8</option>
                        {login.user?.is_admin && (<option value="Affiliate Partner">Affiliate Partner</option>)}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Auditor's Name</label>
                      {this.getInputErrors(['nonprofit', 'auditor_name'])}
                      <input type="text" className="form-control" placeholder="name" value={this.getInputData(['nonprofit', 'auditor_name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'auditor_name'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>YouTube Video URL</label>
                      {this.getInputErrors(['nonprofit', 'video'])}
                      <input type="text" className="form-control" placeholder="Video link" value={this.getInputData(['nonprofit', 'video'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'video'], e); }} />
                    </div>
                  </div>
                </div>
                {/* Causes Section  */}
                <h4 className="mr-b-20">Causes</h4>
                {causeList && causeList.map((item, i) => (
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="form-group">
                        <label>Cause</label>
                        {this.getInputErrors(['nonprofit', 'causes', i, 'name'])}
                        <select className="form-control" value={this.getInputData(['nonprofit', 'causes', i, 'name'])}
                          onChange={(e) => { this.handelInputChange(['nonprofit', 'causes', i, 'name'], e); }}>
                          <option disabled hidden value="">Select</option>
                          {config?.causes?.length > 1 && config?.causes.map((item) => (
                            <option value={item.name}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Subcauses</label>
                        {this.getInputErrors(['nonprofit', 'causes', i, 'subcauses'])}
                        <SelectInput isMulti={true} options={this.getSubCausesList(item.name)} onChange={(e) => this.setSubcauses(e, i)} styles={this.customStyles} defaultValue={this.getDefaultSubCauses(i)} />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Description</label>
                        {this.getInputErrors(['nonprofit', 'causes', i, 'description'])}
                        <input type="text" className="form-control" placeholder="As per documents"
                          value={this.getInputData(['nonprofit', 'causes', i, 'description'])}
                          onChange={(e) => { this.handelInputChange(['nonprofit', 'causes', i, 'description'], e); }} />
                      </div>
                    </div>
                    {i !== 0 ?
                      <div className="col-md-1">
                        <Icon path={mdiDelete} className="menu-arrow cursor-pointer" size={0.75} color="#000000" onClick={() => { this.handleDeleteCause(i) }} />
                      </div>:
                      <div className="col-md-1">
</div>
                    }
                  </div>
                ))}
                <div className="col-md-12 col-12 text-left grid-margin stretch-card flx-h-right np-btn-wrapper">
                  <button type="button" className="btn btn-info mr-2" onClick={() => { this.handleAddCause() }}>
                    + Add New Cause and Subcauses
                  </button>
                </div>
                {/* Demographics Section */}
                <h4 className="mr-b-20">Demographics</h4>
                {demographicList && demographicList.map((item, i) => (
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="form-group">
                        <label>Gender</label>
                        {this.getInputErrors(['nonprofit', 'demographics', i, 'gender'])}
                        <select className="form-control"
                          value={this.getInputData(['nonprofit', 'demographics', i, 'gender'])}
                          onChange={(e) => {
                            this.handelInputChange(['nonprofit', 'demographics', i, 'gender'], e);
                            const { value } = e.target || {}
                            const list = [...demographicList]
                            list[i].gender = value
                            list[i].age = ''
                            list[i].role = ''
                            const ageList = demographicFullList.filter((_d) => _d.gender === value)
                            if (ageList && ageList.length) {
                              const ages = Array.from(new Set(ageList[0].ages.map(_a => _a.age)));
                              list[i].ageList = ages
                            }
                            this.setState({ demographicList: list })
                          }}>
                          <option disabled value={''}>Select Gender</option>
                          {genderList && genderList.length > 0 && genderList.map((_g) => (
                            <option value={_g} key={_g}>{_g}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Age</label>
                        {this.getInputErrors(['nonprofit', 'demographics', i, 'age'])}
                        <select
                          className="form-control"
                          value={this.getInputData(['nonprofit', 'demographics', i, 'age'])}
                          onChange={(e) => {
                            this.handelInputChange(['nonprofit', 'demographics', i, 'age'], e);
                            const { value } = e.target || {}
                            const list = [...demographicList]
                            list[i].age = value
                            list[i].role = ''
                            const filterByGener = demographicFullList.filter((_d) => _d.gender === list[i].gender)
                            const filterByAge = filterByGener[0].ages.filter((_r) => _r.age === value)
                            const roles = filterByAge[0].roles
                            if (roles && roles.length) list[i].roleList = roles
                            this.setState({ demographicList: list })
                          }
                          } disabled={!item.gender}>
                          <option disabled value={''}>Select Age</option>
                          {item.ageList && item.ageList.length > 0 && item.ageList.map((_g) => (
                            <option value={_g} key={_g}>{_g}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label>Role</label>
                        {this.getInputErrors(['nonprofit', 'demographics', i, 'roles'])}
                        <select
                          className="form-control"
                          value={this.getInputData(['nonprofit', 'demographics', i, 'roles'])}
                          onChange={(e) => {
                            this.handelInputChange(['nonprofit', 'demographics', i, 'roles'], e);
                            const { value } = e.target || {}
                            const list = [...demographicList]
                            list[i].role = value
                            this.setState({ demographicList: list })
                          }
                          } disabled={!item.age}>
                          <option disabled value={''}>Select Role</option>
                          {item.roleList && item.roleList.length > 0 && item.roleList.map((_g) => (
                            <option value={_g} key={_g}>{_g}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {i !== 0 ?
                      <div className="col-md-1">
                        <Icon path={mdiDelete} className="menu-arrow cursor-pointer" size={0.75} color="#000000" onClick={() => { this.handleDeleteDemographic(i) }} />
                      </div>:
                      <div className="col-md-1">
</div>
                    }
                  </div>
                ))}
                <div className="col-md-12 col-12 text-left grid-margin stretch-card flx-h-right np-btn-wrapper">
                  <button type="button" className="btn btn-info mr-2" onClick={() => { this.handleAddDemographic() }}>
                    + Add New Demographics
                  </button>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Public E-mail ID</label>
                      {this.getInputErrors(['nonprofit', 'email'])}
                      <input type="text" className="form-control" placeholder="Email Id" value={this.getInputData(['nonprofit', 'email'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'email'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Public Contact Number</label>
                      {this.getInputErrors(['nonprofit', 'phone'])}
                      <PhoneInput countrycode={country_code} value={this.getInputData(['nonprofit', 'phone'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'phone'], e); }} />
                      {/* <input type="text" className="form-control" placeholder="Contact number" value={this.getInputData(['nonprofit', 'phone'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'phone']); }} /> */}
                    </div>
                  </div>
                </div>
                <h4 className="mr-b-20">Head Office Address</h4>
                {this.getInputErrors(['nonprofit', 'address'])}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Address Part 1</label>
                      {this.getInputErrors(['nonprofit', 'address', 'street'])}
                      <input type="text" className="form-control" placeholder="Address" value={this.getInputData(['nonprofit', 'address', 'street'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'address', 'street'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Address Part 2</label>
                      {this.getInputErrors(['nonprofit', 'address', 'locality'])}
                      <input type="text" className="form-control" placeholder="Address" value={this.getInputData(['nonprofit', 'address', 'locality'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'address', 'locality'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label> City</label>
                      {this.getInputErrors(['nonprofit', 'address', 'city'])}
                      <input type="text" className="form-control" placeholder="City" value={this.getInputData(['nonprofit', 'address', 'city'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'address', 'city'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label> State</label>
                      {this.getInputErrors(['nonprofit', 'address', 'region'])}
                      <SelectInput options={regionOptions} onChange={(e) => { this.setRegionSelect(e); }} defaultValue={this.getRegionSelect()} />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Country</label>
                      <input type="text" className="form-control" placeholder="Country" value="India" disabled />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label> Pincode</label>
                      {this.getInputErrors(['nonprofit', 'address', 'area_code'])}
                      <input type="text" className="form-control" placeholder="Pincode" value={this.getInputData(['nonprofit', 'address', 'area_code'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'address', 'area_code'], e); }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Registration Details</h3>
                {/* <p className="text-secondary"> A simple suggestion engine </p> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Registration/Trust Deed number</label>
                      {this.getInputErrors(['nonprofit', 'registration_number'])}
                      <input className="form-control" type="text" placeholder="Registration number/Trust deed/MOA" value={this.getInputData(['nonprofit', 'registration_number'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'registration_number'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> 12A Number</label>
                      {this.getInputErrors(['nonprofit', '12a'])}
                      <input className="form-control" type="text" placeholder="12A" value={this.getInputData(['nonprofit', '12a'])} onChange={(e) => { this.handelInputChange(['nonprofit', '12a'], e); }} />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label> 12A Expiry Date</label>
                      {this.getInputErrors(['nonprofit', '12a_expiry_date'])}
                      <input className="form-control" type="date" placeholder="12A Expiry Date" value={this.getInputData(['nonprofit', '12a_expiry_date']) ? format(new Date(this.getInputData(['nonprofit', '12a_expiry_date'])), 'yyyy-MM-dd') : ''} onChange={(e) => { this.handelInputChange(['nonprofit', '12a_expiry_date'], e); }} />
                    </div>
                  </div>
                  <div className="form-group row padleft12">
                    <label className="col-md-12 col-form-label">Do you have an 80G Certificate?</label>
                    {this.getInputErrors(['nonprofit', 'is80G'])}
                    <div className="col-sm-6">
                      <div className="form-radio">
                        <label className="form-check-label">
                          <input type="radio" className="form-check-input" name="is80G" value="yes"
                            checked={is80G === 'yes'} onChange={this.on80GChange}
                          /> Yes <i className="input-helper"></i></label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-radio">
                        <label className="form-check-label">
                          <input type="radio" className="form-check-input" name="is80G" value="no"
                            checked={is80G === 'no'} onChange={this.on80GChange}
                          /> No <i className="input-helper"></i></label>
                      </div>
                    </div>
                  </div>
                  {(is80G === 'yes') ? (
                    <div className="row padleft12">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> 80G Number</label>
                          {this.getInputErrors(['nonprofit', '80g'])}
                          <input className="form-control" type="text" placeholder="80G" value={this.getInputData(['nonprofit', '80g'])} onChange={(e) => { this.handelInputChange(['nonprofit', '80g'], e); }} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <SingleFileUpload label="80G Certificate" value={this.getInputData(['nonprofit', '80g_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', '80g_doc'], e); }} error={this.getInputErrors(['nonprofit', '80g_doc'])} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <label> 80G Expiry Date</label>
                          {this.getInputErrors(['nonprofit', '80g_expiry_date'])}
                          <input className="form-control" type="date" placeholder="80G Expiry Date" value={this.getInputData(['nonprofit', '80g_expiry_date']) ? format(new Date(this.getInputData(['nonprofit', '80g_expiry_date'])), 'yyyy-MM-dd') : ''} onChange={(e) => { this.handelInputChange(['nonprofit', '80g_expiry_date'], e); }} />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {login.user?.is_admin && <div className="col-md-12">
                    <div className="form-group" style={{ display: "flex", alignItems: "center" }}>
                      {this.getInputErrors(['nonprofit', '80g'])}
                      <input type="checkbox" name="isNonProfit80G" checked={isNonProfit80G} value={this.getInputData(['nonprofit', '80g'])} onChange={(e) => { this.on80gValidChange(e); }} />
                      <label className="col-md-12 col-form-label">80g Valid</label>
                    </div>
                  </div>}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> PAN Card Number</label>
                      {this.getInputErrors(['nonprofit', 'pan_card'])}
                      <input className="form-control" type="text" placeholder="PAN Card" value={this.getInputData(['nonprofit', 'pan_card'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'pan_card'], e); }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Bank Account Details</h3>
                <h4 className="mr-b-20">Domestic Bank Account</h4>
                {this.getInputErrors(['nonprofit', 'bank_accounts'])}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Beneficiary Name</label>
                      {this.getInputErrors(['nonprofit', 'bank_accounts', false, 'account_name'])}
                      <input className="form-control" type="text" placeholder="Name" value={this.getInputData(['nonprofit', 'bank_accounts', false, 'account_name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', false, 'account_name'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Account Type</label>
                      {this.getInputErrors(['nonprofit', 'bank_accounts', false, 'account_type'])}
                      <select className="form-control" value={this.getInputData(['nonprofit', 'bank_accounts', false, 'account_type'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', false, 'account_type'], e); }}>
                        <option disabled hidden value="">Select</option>
                        <option value="Savings">Savings Account</option>
                        <option value="Current">Current Account</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Account Number</label>
                      {this.getInputErrors(['nonprofit', 'bank_accounts', false, 'account_number'])}
                      <input className="form-control" type="text" placeholder="Account number" value={this.getInputData(['nonprofit', 'bank_accounts', false, 'account_number'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', false, 'account_number'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> IFSC</label>
                      {this.getInputErrors(['nonprofit', 'bank_accounts', false, 'ifsc_code'])}
                      <input className="form-control" type="text" placeholder="IFSC" value={this.getInputData(['nonprofit', 'bank_accounts', false, 'ifsc_code'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', false, 'ifsc_code'], e); }} />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <SingleFileUpload label="Cancelled Cheque ( Indian Bank Account )" value={this.getInputData(['nonprofit', 'bank_accounts', false, 'cancelled_cheque_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', false, 'cancelled_cheque_doc'], e); }} error={this.getInputErrors(['nonprofit', 'bank_accounts', false, 'cancelled_cheque_doc'])} />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-12 col-form-label">Can Accept Donations Without FCRA?</label>
                  {this.getInputErrors(['nonprofit', 'can_accept_donations_without_fcra'])}
                  <div className="col-sm-6">
                    <div className="form-radio">
                      <label className="form-check-label">
                        <input type="radio" disabled={isNonProfitLogin ? true :
                          false} className="form-check-input" name="can_accept_donations_without_fcra" value="yes" checked={can_accept_donations_without_fcra === 'yes'}
                          onChange={this.onWithoutFCRAChange}
                        />
                        Yes <i className="input-helper"></i></label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-radio">
                      <label className="form-check-label">
                        <input type="radio" disabled={isNonProfitLogin ? true :
                          can_accept_donations_without_fcra == "yes" && this.getInputData(['nonprofit', 'bank_accounts', true, 'swift_code']) ? true :
                            false} className="form-check-input" name="can_accept_donations_without_fcra" value="no" checked={can_accept_donations_without_fcra === 'no'}
                          onChange={this.onWithoutFCRAChange}

                        /> No <i className="input-helper"></i></label>
                    </div>
                  </div>
                </div>
                {(can_accept_donations_without_fcra === 'yes') ? (
                  <>
                    <h4 className="mr-b-20">Non FCRA Bank Account</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> Beneficiary Name</label>
                          {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'account_name'])}
                          <input disabled={isNonProfitLogin ? true : false} className="form-control" type="text" placeholder="Name" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'account_name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'account_name'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> Account Type</label>
                          {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'account_type'])}
                          <select disabled={isNonProfitLogin ? true : false} className="form-control" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'account_type'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'account_type'], e); }}>
                            <option disabled hidden value="">Select</option>
                            <option value="Savings">Savings Account</option>
                            <option value="Current">Current Account</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> Account Number</label>
                          {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'account_number'])}
                          <input disabled={isNonProfitLogin ? true : false} className="form-control" type="text" placeholder="Account number" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'account_number'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'account_number'], e); }} />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label> ABA routing number</label>
                          {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'swift_code'])}
                          <input disabled={isNonProfitLogin ? true : false} className="form-control" type="text" placeholder="ABA routing number" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'swift_code'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'swift_code'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> Bank Name</label>
                          {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'bank_name'])}
                          <input disabled={isNonProfitLogin ? true : false} className="form-control" type="text" placeholder="Bank Name" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'bank_name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'bank_name'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> Bank Address</label>
                          {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'bank_address'])}
                          <input disabled={isNonProfitLogin ? true : false} className="form-control" type="text" placeholder="Bank Address" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'bank_address'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'bank_address'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label> Bank Phone</label>
                          {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'bank_phone'])}
                          <input disabled={isNonProfitLogin ? true : false} className="form-control" type="text" placeholder="Bank Phone" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'bank_phone'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'bank_phone'], e); }} />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mr-b-20">FCRA Bank Account</h4>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Beneficiary Name</label>
                        {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'account_name'])}
                        <input className="form-control" type="text" placeholder="Name" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'account_name'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'account_name'], e); }} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Account Type</label>
                        {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'account_type'])}
                        <select className="form-control" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'account_type'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'account_type'], e); }}>
                          <option disabled hidden value="">Select</option>
                          <option value="Savings">Savings Account</option>
                          <option value="Current">Current Account</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Account Number</label>
                        {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'account_number'])}
                        <input className="form-control" type="text" placeholder="Account number" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'account_number'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'account_number'], e); }} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> IFSC</label>
                        {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'ifsc_code'])}
                        <input className="form-control" type="text" placeholder="IFSC" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'ifsc_code'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'ifsc_code'], e); }} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> SWIFT Code</label>
                        {this.getInputErrors(['nonprofit', 'bank_accounts', true, 'swift_code'])}
                        <input className="form-control" type="text" placeholder="SWIFT Code" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'swift_code'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'swift_code'], e); }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <SingleFileUpload label="Cancelled Cheque ( FCRA linked Bank Account )" value={this.getInputData(['nonprofit', 'bank_accounts', true, 'cancelled_cheque_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'bank_accounts', true, 'cancelled_cheque_doc'], e); }} error={this.getInputErrors(['nonprofit', 'bank_accounts', true, 'cancelled_cheque_doc'])} />
                    </div>
                  </div>
                )}
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-md-12 col-form-label">Do you have an FCRA Certificate?</label>
                  {this.getInputErrors(['nonprofit', 'isFCRA'])}
                  <div className="col-sm-6">
                    <div className="form-radio">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="isFCRA" value="yes" checked={isFCRA === 'yes'} onChange={this.onFCRAChange} /> Yes <i className="input-helper"></i></label>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-radio">
                      <label className="form-check-label">
                        <input type="radio" className="form-check-input" name="isFCRA" value="no" checked={isFCRA === 'no'} onChange={this.onFCRAChange} /> No <i className="input-helper"></i></label>
                    </div>
                  </div>
                </div>
                {(isFCRA === 'yes') ? (
                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="mr-b-20">FCRA</h3>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label> FCRA Number</label>
                        {this.getInputErrors(['nonprofit', 'fcra'])}
                        <input className="form-control" type="text" placeholder="FCRA" value={this.getInputData(['nonprofit', 'fcra']) || ''} onChange={(e) => { this.handelInputChange(['nonprofit', 'fcra'], e); }} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <SingleFileUpload label="FCRA Certificate" description="(Please upload the latest document. If you have changed your FCRA bank account, then kindly merge/combine the “Change Detail Certificate” issued by MHA with the FCRA certificate)" value={this.getInputData(['nonprofit', 'fcra_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'fcra_doc'], e); }} error={this.getInputErrors(['nonprofit', 'fcra_doc'])} />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <label> FCRA Expiry Date</label>
                        {this.getInputErrors(['nonprofit', 'fcra_validity'])}
                        <input className="form-control" type="date" placeholder="FCRA Validity" value={this.getInputData(['nonprofit', 'fcra_validity']) ? format(new Date(this.getInputData(['nonprofit', 'fcra_validity'])), 'yyyy-MM-dd') : ''} onChange={(e) => { this.handelInputChange(['nonprofit', 'fcra_validity'], e); }} />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {/** GST fields begins here */}
              <div className="card-body">
                <h3 className="mr-b-20">GST Details</h3>
                <div className="form-group form-check" style={{ display: "flex", alignItems: "center" }}>
                  <input type="checkbox" className="form-check-input" style={{ marginTop: "0px" }} name="haveGST" value="yes" checked={haveGST} onChange={this.onGSTChange} />
                  <label className="col-md-12 col-form-label">Our NGO has a GST number for refund claim</label>
                </div>
                {
                  haveGST ?
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>GST number (if applicable)</label>
                            {this.getInputErrors(['nonprofit', 'gst_details', 'gst_number'])}
                            <input className="form-control" type="text" placeholder="GST number" value={this.getInputData(['nonprofit', 'gst_details', 'gst_number']) || ''} onChange={(e) => { this.handelInputChange(['nonprofit', 'gst_details', 'gst_number'], e); }} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <SingleFileUpload label="GST Certificate" value={this.getInputData(['nonprofit', 'gst_details', 'gst_uri'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'gst_details', 'gst_uri'], e); }} error={this.getInputErrors(['nonprofit', 'gst_details', 'gst_uri'])} />
                          </div>
                        </div>

                      </div>
                      <div className="form-group form-check" style={{ display: "flex", alignItems: "center" }}>
                        <input type="checkbox" className="form-check-input" style={{ marginTop: "0px" }} name="isGSTReg" value="yes" checked={isGSTReg} onChange={this.onGSTAddressChange} />
                        <label className="col-md-12 col-form-label">Our GST address is different from the registered address.</label>
                      </div>
                    </>
                    : null
                }
                {
                  isGSTReg ?
                    <div className="row">
                      <p style={{ marginBottom: "40px" }}>
                        GST Address: <span>
                          {`
                          ${this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'street']) + ' ' +
                            this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'city']) + ' ' +
                            this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'area_code']) + ' ' +
                            this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'region']) + ' ' +
                            this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'country_code'])
                            }
                        `}
                        </span>
                      </p>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label> Address</label>
                          {this.getInputErrors(['nonprofit', 'gst_details', 'gst_address', 'street'])}
                          <input type="text" className="form-control" placeholder="Address" value={this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'street'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'gst_details', 'gst_address', 'street'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label> City</label>
                          {this.getInputErrors(['nonprofit', 'gst_details', 'gst_address', 'city'])}
                          <input type="text" className="form-control" placeholder="City" value={this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'city'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'gst_details', 'gst_address', 'city'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label> State</label>
                          {this.getInputErrors(['nonprofit', 'gst_details', 'gst_address', 'region'])}
                          <SelectInput options={regionOptions} onChange={(e) => { this.setGSTAddress(e); }} defaultValue={this.getGSTAddress()} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label> Pincode</label>
                          {this.getInputErrors(['nonprofit', 'gst_details', 'gst_address', 'area_code'])}
                          <input type="text" className="form-control" placeholder="Pincode" value={this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'area_code'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'gst_details', 'gst_address', 'area_code'], e); }} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Country</label>
                          <input type="text" className="form-control" placeholder="Country" value="India" disabled />
                        </div>
                      </div>
                    </div>
                    : null
                }
              </div>
              <div className="card-body">
                <h3> Non-profit Logo</h3>
                <ImageCropUpload title="Please select Non-profit logo" label="Logo" height={300} width={300} value={this.getInputData(['nonprofit', 'image'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'image'], e); }} error={this.getInputErrors(['nonprofit', 'image'])} />
                <ImageCropUpload title="Please select Non-profit cover image" label="cover_image" height={300} width={1200} value={this.getInputData(['nonprofit', 'cover_image'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'cover_image'], e); }} error={this.getInputErrors(['nonprofit', 'cover_image'])} />
              </div>
              <div className="card-body">
                <h3 className="mr-b-20"> Documents/Files</h3>
                {/* <p className="text-secondary"> A simple suggestion engine </p> */}
                <SingleFileUpload label="Registration Certificate/Trust Deed" value={this.getInputData(['nonprofit', 'registration_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'registration_doc'], e); }} error={this.getInputErrors(['nonprofit', 'registration_doc'])} />
                <SingleFileUpload label="12A Certificate" value={this.getInputData(['nonprofit', '12a_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', '12a_doc'], e); }} error={this.getInputErrors(['nonprofit', '12a_doc'])} />
                <SingleFileUpload label="PAN Card" value={this.getInputData(['nonprofit', 'pan_card_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'pan_card_doc'], e); }} error={this.getInputErrors(['nonprofit', 'pan_card_doc'])} />
                <SingleFileUpload label="Annual Report (for the latest financial year)" value={this.getInputData(['nonprofit', 'annual_report_doc'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'annual_report_doc'], e); }} error={this.getInputErrors(['nonprofit', 'annual_report_doc'])} />
              </div>
              <div className="card-body">
                <h3 className="mr-b-20">Social Media Accounts</h3>
                {/* <p className="text-secondary">National(non-FCRA)</p> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Website of the Organization</label>
                      {this.getInputErrors(['nonprofit', 'external_profiles', 'Website'])}
                      <input type="text" className="form-control" placeholder="Website URL" value={this.getInputData(['nonprofit', 'external_profiles', 'Website'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'external_profiles', 'Website'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Facebook Page URL (If available)</label>
                      <div>
                        <small>Add URL of official page of organisation on Facebook. Only Pages with “Like” option can be added</small>
                      </div>
                      {this.getInputErrors(['nonprofit', 'external_profiles', 'Facebook'])}
                      <input className="form-control" type="text" placeholder="Facebook" value={this.getInputData(['nonprofit', 'external_profiles', 'Facebook'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'external_profiles', 'Facebook'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Twitter Page URL (If available)</label>
                      {this.getInputErrors(['nonprofit', 'external_profiles', 'Twitter'])}
                      <input className="form-control" type="text" placeholder="Twitter" value={this.getInputData(['nonprofit', 'external_profiles', 'Twitter'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'external_profiles', 'Twitter'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>YouTube Channel URL (If available)</label>
                      {this.getInputErrors(['nonprofit', 'external_profiles', 'Youtube Channel'])}
                      <input className="form-control" type="text" placeholder="YouTube channel" value={this.getInputData(['nonprofit', 'external_profiles', 'Youtube Channel'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'external_profiles', 'Youtube Channel'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Instagram Profile URL (If available)</label>
                      {this.getInputErrors(['nonprofit', 'external_profiles', 'Instagram'])}
                      <input className="form-control" type="text" placeholder="Instagram" value={this.getInputData(['nonprofit', 'external_profiles', 'Instagram'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'external_profiles', 'Instagram'], e); }} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Linkedin URL (If available)</label>
                      {this.getInputErrors(['nonprofit', 'external_profiles', 'Linkedin'])}
                      <input className="form-control" type="text" placeholder="Instagram" value={this.getInputData(['nonprofit', 'external_profiles', 'Linkedin'])} onChange={(e) => { this.handelInputChange(['nonprofit', 'external_profiles', 'Linkedin'], e); }} />
                    </div>
                  </div>
                </div>

              </div>
              <div className="card-body">
                <div className="col-md-12 d-flex align-items-stretch">
                  <div className="row flex-grow">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
const mapStateToProps = state => ({
  login: state.login,
});
const mapActionToProps = dispatch => ({
  CauseAndDemographics: bindActionCreators(CauseAndDemographicAction, dispatch),
});

export default withRouter(connect(mapStateToProps, mapActionToProps)(NonprofitBasicDetailsEdit));
