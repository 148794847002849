import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import { format } from 'date-fns';
import AdminEntityTagWidget from '../../productComponents/Admin/AdminEntityTagWidget/AdminEntityTagWidget';

import { requiredComplianceOptions, configCategoryCodeOptions } from '../../config';

class NonprofitAdminSettingsRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canprovide10be: (this.props && this.props.live && this.props.live.can_provide_10be) ? this.props.live.can_provide_10be : false
    };
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { live } = this.props;
    const val = Helpers.getDataFromObject(live, key);
    if(val === '') {
      return this.na;
    }
    return val;
  }

  categoryCodeToDescription = (value) => {
    const index = Helpers.objSearch(configCategoryCodeOptions, value, 'value');
    if (index > -1) {
      return configCategoryCodeOptions[index].label;
    }
    return value;
  }

  render() {
    const { children } = this.props;

    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3 className="mr-b-20">Nonprofit Settings for Admin</h3>
              {/* <h6>Tags</h6>
              <AdminEntityTagWidget entity_id={this.props.live?._id} entity_type="nonprofits" isReadMode={true}/> */}
              <h6>Auto receipt</h6>
              <p className="text-muted">{this.getInputData(['nonprofit', 'auto_receipt']) === true ? 'Yes' : this.getInputData(['nonprofit', 'auto_receipt']) === false ? 'No' : this.getInputData(['nonprofit', 'auto_receipt'])}</p>
              <div className="row">
                <div className="col-md-6">
                  <h6>Nonprofit Compliance Required</h6>
                  {this.getInputData(['nonprofit', 'required_compliances']) !== this.na && this.getInputData(['nonprofit', 'required_compliances']).length > 0 ? this.getInputData(['nonprofit', 'required_compliances']).map(i => (
                    <label key={i} className="badge badge-primary">{requiredComplianceOptions[Helpers.objSearch(requiredComplianceOptions, i, 'value')].label}</label>
                  )) : this.na}
                  <h6>Nonprofit Compliance Approved</h6>
                  <div className="row">
                    {this.getInputData(['nonprofit', 'approved_compliances']) !== this.na && this.getInputData(['nonprofit', 'approved_compliances']).length > 0 ? this.getInputData(['nonprofit', 'approved_compliances']).map(i => (
                      <div key={i.id} className="col-md-6">
                        <label className="badge badge-primary">{requiredComplianceOptions[Helpers.objSearch(requiredComplianceOptions, i.id, 'value')].label}</label>
                        <div className="col-md-12 col-12 table-col dl-mob user-info-livedis">
                          <img alt="image" className="img-circle width-30" src={i.approved_by.profile_pic} />
                          <div className="name-email">
                            <span>{i.approved_by.name}</span>
                            <small>{format(new Date(i.approved_time), 'do MMM yyyy HH:mm')}</small>
                          </div>
                        </div>
                      </div>
                    )) : this.na}
                  </div>
                </div>
                <div className="col-md-6">
                  {this.getInputData(['nonprofit', 'required_compliances']).includes("fbassured") ? (
                    <div>
                      <h6>Category Code</h6>
                      <div className="tags-wrapper">
                        {this.getInputData(['nonprofit', 'nteecc_category_codes']) !== this.na && this.getInputData(['nonprofit', 'nteecc_category_codes']).length > 0 ? this.getInputData(['nonprofit', 'nteecc_category_codes']).map(i => (
                          <label key={i} className="badge badge-primary">{this.categoryCodeToDescription(i)}</label>
                        )) : this.getInputData(['nonprofit', 'nteecc_category_codes'])}
                      </div>
                      <h6>Priority</h6>
                      {this.getInputData(['nonprofit', 'priority']) !== this.na ? (
                        <label className="badge badge-primary">{this.getInputData(['nonprofit', 'priority'])}</label>
                      ) : this.na}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group" style={{ display: "flex" }}>
                  <input type="checkbox" name="isProvideCertificate10BE" checked={this.state.canprovide10be} readOnly />
                  <label className="col-md-12 col-form-label">Can give 10BE certificate</label>
                </div>
              </div>
              <h6>NGO-require pan card for donation amount more then</h6>
              <p className="text-muted"><i>Feature to be implemented</i></p>
              <h6>Members</h6>
              <table className="table table-bordered text-muted">
                <thead>
                  <tr>
                    <th> Name </th>
                    <th> Designation </th>
                    <th> Phone </th>
                    <th> Email </th>
                    <th> Primary Contact </th>
                  </tr>
                </thead>
                <tbody>
                  {this.getInputData(['nonprofit', 'members']) !== this.na && this.getInputData(['nonprofit', 'members']).length > 0 ? this.getInputData(['nonprofit', 'members']).map(i => (
                    <tr key={i.name + i.email}>
                      <td> {i.name} </td>
                      <td> {i.designation} </td>
                      <td> {i.phone} </td>
                      <td> {i.email} </td>
                      <td> {i.is_primary_contact ? 'Yes' : 'No'} </td>
                    </tr>
                  )) : this.getInputData(['nonprofit', 'members'])}
                </tbody>
              </table>
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NonprofitAdminSettingsRead;
