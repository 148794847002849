import React, { Component } from 'react';

import Helpers from '../../utils/helpers';

import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';

class NonprofitPointOfContactRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
    };
  }

  switchView = (view) => {
    this.setState({ view: view });
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if(val === '' && key[1] !== 'video') {
      return this.na;
    }
    return val;
  }

  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if(to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if(to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if(to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if(to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }

  shouldShowItemOnCompliance = (compliances) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const compliance = value['required_compliances'];
    const condition = compliances.split('|');
    if (condition.length > 0 && compliance) {
      return condition.filter(i => compliance.includes(i)).length > 0;
    }
    return true;
  }

  render() {
    const { children, tabs } = this.props;
    const { view } = this.state;
    return (
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">Point of Contact</h3>
                <div className="heading-bar-btn">
                  {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab => {
                    if (tab === view) {
                      return (
                        <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                      );
                    }
                    return (
                      <button type="button" className="btn btn-outline-secondary btn-left" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                    );
                  })}
                </div>
              </div>
              <ApprovalsRejectionsButtons path={['nonprofit', 'points_of_contact']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div className="row mr-t-5 tm-divider pd-b-20">
                  <div className="col-lg-4 col-md-12"><strong>Name</strong></div>
                  <div className="col-lg-4 col-md-12"><strong>Email id</strong></div>
                  <div className="col-lg-4 col-md-12"><strong>Mobile No.</strong></div>
                </div>
                <div>
                  <h6>CEO/Head of Organisation Contact</h6>
                  <small>Complete name as it appears on PAN Card</small>
                  <div className="row mr-t-5 tm-divider pd-b-20">
                    <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'name'])}</div>
                    <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'email'])}</div>
                    <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'phone'])}</div>
                    {this.shouldShowItemOnCompliance('fbassured') ? (
                    <>
                      <div className="col-md-3 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'title'])}</div>
                      <div className="col-md-3 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'date_of_birth'])}</div>
                      <div className="col-md-3 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'id'])}</div>
                      <div className="col-md-3 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'id_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'points_of_contact', 'ceo', 'id_doc'])}>(Preview)</a>) : this.na}</div>
                    </> ) : null }
                  </div>
                  { this.shouldShowItemOnCompliance('giveassured') ? (
                    <>
                      <h6>First Point of Contact</h6>
                      <small>First point of contact for all matters</small>
                      <div className="row mr-t-5 tm-divider pd-b-20">
                        <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'first_poc', 'name'])}</div>
                        <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'first_poc', 'email'])}</div>
                        <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'first_poc', 'phone'])}</div>
                      </div>
                      <h6>Reporting Point of Contact</h6>
                      <small>Contact who will handle reporting requirement</small>
                      <div className="row mr-t-5 tm-divider pd-b-20">
                        <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'reporting', 'name'])}</div>
                        <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'reporting', 'email'])}</div>
                        <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'reporting', 'phone'])}</div>
                      </div>
                      <h6>Accounting Point of Contact</h6>
                      <small>Contact who will handle fundraising requirement</small>
                      <div className="row mr-t-5 tm-divider pd-b-20">
                        <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'accounts', 'name'])}</div>
                        <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'accounts', 'email'])}</div>
                        <div className="col-lg-4 col-md-12 text-muted">{this.getInputData(['nonprofit', 'points_of_contact', 'accounts', 'phone'])}</div>
                      </div>   
                    </> 
                  ) : null }
                                   
                </div>
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NonprofitPointOfContactRead;
