import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OverlayTrigger, Form, Popover, Button, Row, Col, Tooltip } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { Link } from 'react-router-dom';
import { format } from "date-fns";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline, mdiFilter, mdiArrowLeft } from "@mdi/js";

import * as adminDonationListAction from "../../../reducers/Admin/adminDonationListReducer";

import * as eventAction from "../../../reducers/eventReducer";
import "../AdminDonationsList/AdminDonationsList.scss";

import Loading from "../../../appComponents/Loading";
import Helpers, { asyncDownloadFile } from "../../../utils/helpers";
import Admin10BdBeUploadModal from "../Admin10BdBeDonations/Admin10BdBeUploadModal";

const startOfFinancialYear2023 = new Date('2023-04-01T00:00:00.000Z');
const currentDate = new Date();
currentDate.setUTCHours(23, 59, 59, 999);
class Admin10BdBeDonations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventList: [],
      query: {
        after: startOfFinancialYear2023.toISOString(),
        before: currentDate.toISOString(),
      },
      currentTab: 'export',
      filters: [
        {
          label: 'Search',
          type: [{
            type: 'default',
            label: 'Name',
            query: 'q',
          }, {
            type: 'email',
            label: 'Email id',
            query: 'filter_by_user_email',
          }, {
            type: 'phone',
            label: 'Mobile number',
            query: 'filter_by_user_phone',
          }, {
            type: 'order',
            label: 'Order Id',
            query: 'filter_by_order_id',
          }, {
            type: 'disbursement',
            label: 'Disbursement Id',
            query: 'filter_by_disbursement_number',
          }],
          placeholder: 'Search for Name, Email, Phone, Order id, Disbursement id',
        }, {
          label: 'Created after',
          type: 'date',
          query: 'createdAfter',
        }, {
          label: 'Created before',
          type: 'date',
          query: 'createdBefore',
        }],
      exportFilters: [],
      reportLinks: [],
      loader: false,
      isUploadModal: false,
      isUploading: false,
      downloadLoader: false,
      latestUploadedFile: {},
      latestUploadedFileStatus: ''
    };
  }

  componentDidMount() {
    const { donation, Donation, event, Event, match } = this.props;
    const { state } = this.props.location
    const { nonprofit, id } = match.params;
    const { query } = this.state
    const nonprofitId = (state?.nonprofit ? state.nonprofit : nonprofit) || state || id
    Donation.getNonprofitDetails(nonprofitId).then((res) => {
      const { files_10be, latest_10be_status } = res || {}
      this.setState({
        latestUploadedFileStatus: latest_10be_status
      })
      if (files_10be && files_10be.length > 0) {
        const latestFile = files_10be[files_10be.length - 1]
        this.setState({ latestUploadedFile: latestFile })
      }
    })
    if (!donation.get_status.donation10BdBe) {
      Donation.getDonations10bdbe(query, nonprofitId);
    }
    if (!event.get_status.event) {
      Event.get().then((events) => {
        events &&
          events.length > 0 &&
          this.setState({
            eventList: events.map((event) => {
              return [
                {
                  label: event.name,
                  value: event.event_id,
                },
              ];
            }),
          });
      });
    }
  }

  componentDidUpdate() {
    let updatedFilters = this.state.filters;
    if (this.state.eventList.length > 0) {
      for (let e = 0; e < updatedFilters.length; e++) {
        if (updatedFilters[e].label === "Event") {
          updatedFilters[e].list = this.state.eventList.map((list) => {
            return list[0];
          });
        }
      }

    }
  }
  componentWillUnmount() {
    const { Donation } = this.props;
    Donation.reset();
  }

  loadMore = () => {
    const { Donation, match } = this.props;
    const { query } = this.state;
    const { state } = this.props.location
    const { nonprofit } = match.params;
    const nonprofitId = (state?.nonprofit ? state.nonprofit : nonprofit) || state
    Donation.getDonations10bdbe(query, nonprofitId);
  };
  refreshDonationsList = () => {
    const { Donation, match } = this.props;
    const { query } = this.state;
    const { state } = this.props.location
    const { nonprofit } = match.params;
    const nonprofitId = (state?.nonprofit ? state.nonprofit : nonprofit) || state
    Donation.reset();
    Donation.getDonations10bdbe(query, nonprofitId);
  };


  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) }, () => {
      this.refreshDonationsList()
    });
  };



  addToFilter = (type, value) => {
    const { filters } = this.state;
    const newOption = { ...filters.find((i) => i.label === type) };
    newOption.data = value;
    const newOptions = filters.map((item) => (item.label === type ? newOption : item));
    this.setState(
      { filters: newOptions, query: Helpers.queryBuilderForFilterInput(newOptions) }
    );
  };



  generateReportLink = (e) => {
    e.preventDefault();
    this.setState({ loader: true })
    const { Donation, match } = this.props;
    const { state } = this.props.location
    const { id, nonprofit } = match.params;
    const nonprofitId = (state?.nonprofit ? state.nonprofit : id) || state || nonprofit
    const params = {
      after: startOfFinancialYear2023.toISOString(),
      before: new Date('2024-03-31').toISOString(),
      nonprofitId
    }
    Donation.generateReportLink10BdBe({ ...params })?.then((data) => {
      if (data !== undefined) {
        const { match } = this.props
        const { state } = this.props.location
        const { nonprofit } = match.params;

        const nonprofitId = state?.nonprofit ? state.nonprofit : nonprofit
        const isAdmin = state?.nonprofit?.name && state?.nonprofit?.name.length > 0;
        const query = {
          is10BdBe: true,
          nonprofitId: isAdmin ? nonprofitId : undefined
        }
        Donation.getReportLinks(query).then((reportsData) => {
          this.setState({ reportLinks: reportsData, currentTab: 'downloads', loader: false });
        });
      }
      else {
        this.setState({ currentTab: 'export', loader: false });
      }
    });
  };

  getReportLinks = (e, isExport) => {
    e.preventDefault();
    const { Donation } = this.props;
    this.setState({
      loader: true
    })
    if (isExport) {
      this.setState({
        currentTab: isExport
      })
    }
    const { match } = this.props
    const { state } = this.props.location
    const { nonprofit } = match.params;

    const nonprofitId = state?.nonprofit ? state.nonprofit : nonprofit
    const isAdmin = state?.nonprofit?.name && state?.nonprofit?.name.length > 0;
    const query = {
      is10BdBe: true,
      nonprofitId: isAdmin ? nonprofitId : undefined
    }
    Donation.getReportLinks(query).then((data) => {
      setTimeout(() => {
        this.setState({
          loader: false
        })
      }, 2000);
      this.setState({ reportLinks: data });
    });
  };

  addToFilterHTML = (type, value) => (
    <span className="filter-wrapper">
      <button
        type="button"
        className="btn btn-rounded btn-inverse-outline-primary btn-filters"
        onClick={(e) => {
          e.preventDefault();
          this.addToFilter(type, value);
        }}
      >
        <Icon path={mdiFilter} size={0.5} color="#2196f3" />
        <span>Apply as filter</span>
      </button>
    </span>
  );

  onTabChange = (e, tab) => {
    this.setState({
      currentTab: tab
    })
    if (tab === 'downloads') {
      this.getReportLinks(e, 'downloads')
    }
  };
  handleUpload = (files) => {
    const { Donation } = this.props || {};
    this.setState({ isUploading: true })
    const csvFile = files?.find(file => file?.uploadedFile === "10beSheet")?.file;
    const zipFile = files?.find(file => file?.uploadedFile === "10beCertificateFolder")?.file;
    const { match } = this.props
    const { state } = this.props.location
    const { nonprofit } = match.params;
    const nonprofitId = (state?.nonprofit ? state.nonprofit : nonprofit) || state || match?.params?.id
    const query = {
      csvFile,
      zipFile,
      nonprofitId
    }
    Donation.upload10bdbe(query)
      .then(() => {
        this.setState({ isUploading: false, isUploadModal: false, showUploadedFiles: true })
        Donation.getNonprofitDetails(nonprofitId).then((res) => {
          const { files_10be, latest_10be_status } = res || {}
          this.setState({ latestUploadedFileStatus: latest_10be_status })
          if (files_10be && files_10be.length > 0) {
            const latestFile = files_10be[files_10be.length - 1]
            this.setState({ latestUploadedFile: latestFile })
          }
        })
      })
      .catch(() => this.setState({ isUploading: false }))

  }
  handleDownload = async (url) => {
    this.setState({ downloadLoader: true })
    try {
      await asyncDownloadFile(url);
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ downloadLoader: false })
    }
  };

  popover = () => {
    const { donation } = this.props;
    const { reportLinks } = this.state;
    const fieldNames = {
      basic:
        "(createdAfter,createdBefore)",
    };

    return (
      <Popover id="popover-basic-1">
        <Popover.Content>
          <Row>
            <Col md={12} xs={12} className="export-download">
              <ul className="nav nav-tabs tab-basic" role="tablist">
                <li className="nav-item">
                  <a className={`${`nav-link ${this.state.currentTab === 'export' ? `active` : ''} show`}`} id="export-tab" data-toggle="tab" href="#export" onClick={(e) => this.onTabChange(e, 'export')} >Export</a>
                </li>
                <li className="nav-item">
                  <a className={`${`nav-link ${this.state.currentTab === 'downloads' ? `active` : ''} show`}`} id="download-tab" data-toggle="tab" href="#download" onClick={(e) => this.onTabChange(e, 'downloads')} >Downloads</a>
                </li>
              </ul>
              <div className="tab-content tab-content-basic">
                {
                  this.state.currentTab === 'export' ?
                    <div className="tab-pane fade show active">
                      <Col md={12} xs={12} className="export-filters">
                        <Popover.Title as="h3">Select Fields</Popover.Title>
                        <Form.Check className="popover-checkbox" type="checkbox" id="basic" label="Basic Details" disabled checked />
                        <div className="popover-checkbox-div" htmlFor="basic">{fieldNames.basic}</div>

                        <div className="text-center">
                          <Button className="btn btn-sm" onClick={this.generateReportLink}>{donation.get_status.generate_report === '' ? 'Export to CSV' : donation.get_status.generate_report === 'pending' ? 'Generating...' : 'Export to CSV'}</Button>
                        </div>
                      </Col>
                    </div> :
                    <div className="tab-pane fade show active" >
                      <Col md={12} xs={12}>
                        <Popover.Title as="h3">
                          <span>Queued for download</span>
                          <button type="button" className="btn btn-inverse-link" onClick={this.getReportLinks}>
                            <div className="export">
                              <div className={`${this.state.loader ? `export-loader-spin` : `export-loader`}`}></div>
                            </div>
                          </button>
                        </Popover.Title>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Requested</th>
                              <th>Tags</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(!reportLinks || reportLinks?.length === 0) &&
                              <i className="align-center">No items available</i>}
                            {reportLinks && reportLinks?.length && reportLinks.map((link, index) => {
                              let filterAppl = link.queryParameter.filter;

                              return (
                                <tr key={index}>
                                  <td style={{ maxWidth: "100%", whiteSpace: "nowrap" }}>
                                    <div className="download-tag-wrapper">
                                      <span className="download-tag">{format(new Date(link.created_at), "MMM do, yyyy HH:mm aa")}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="download-tag-wrapper">
                                      {['a'].map(item => (
                                        <>
                                          {
                                            filterAppl &&
                                            <>
                                              {filterAppl.createdBefore ? <span className="download-tag">Created before: {format(new Date(filterAppl.createdBefore), "MMM do, yyyy")}</span> : ''}
                                              {filterAppl.createdAfter ? <span className="download-tag">Created after: {format(new Date(filterAppl.createdAfter), "MMM do, yyyy")}</span> : ''}
                                            </>
                                          }
                                        </>
                                      ))}
                                    </div>
                                  </td>
                                  <td><a className={`btn btn-xs ${link.status === 'completed' ? 'btn-success' : 'disabled btn-secondary'}`} href={link.dataLink} download={`GiveFundraisers-live-${format(new Date(), 'HH:mm:ss')}`}>{link.status === 'completed' ? 'Download' : link.status}</a></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </Col>
                    </div>}
              </div>
            </Col>
          </Row>
          <Row></Row>
        </Popover.Content>
      </Popover>
    );
  };

  donationsHTML = () => {
    const { donation } = this.props;
    const { donation10BdBe } = donation || {}

    if (
      !donation.get_status.donation10BdBe ||
      (donation.get_status.donation10BdBe === "pending" && donation10BdBe.length === 0)
    ) {
      return <Loading />;
    }
    if (donation10BdBe?.length === 0) {
      return <i className="align-center">No items available</i>;
    }
    return (
      <>
        {donation10BdBe.map((item, index) => (
          <li className="table-list-item" key={item.order_id}>
            <div className="row">
              <div className="col-md-12 table-col">
                <div className="row">
                  <div className="col-md col-12 table-col dl-mob">
                    {index + 1}
                  </div>
                  <div className="col-md col-12 table-col dl-mob">
                    -
                  </div>

                  <div className="col-md-2 col-12 table-col dl-mob">
                    <span>{item.user?.pan_card}</span>
                  </div>

                  <div className="col-md-2 col-12 table-col dl-mob">
                    <span>{
                      item?.nonprofit?.registration_date ? format(new Date(item?.nonprofit?.registration_date), 'do MMM yyyy') : null
                    }</span>
                  </div>

                  {item.user ? (
                    <div className="col-md-2 col-12 table-col dl-mob user-info-livedis">
                      <img alt="user" className="img-circle width-30" src={item.user.profile_pic} />
                      <div className="name-email">
                        <span>{item.user.name}</span>
                        <small>
                          {item.user.email}
                          {item.user.is_email_verified ? (
                            <Icon path={mdiCheckCircleOutline} size={0.75} color="#777777" />
                          ) : null}
                        </small>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-2 col-12 table-col dl-mob user-info-livedis">
                      User Not Available
                    </div>
                  )}
                  <div className="col-md-2 col-12 table-col dl-mob">
                    <span>{item.user?.address?.formatted}</span>

                  </div>
                  <div className="col-md-1 col-12 table-col dl-mob-right">
                    INR {item.totalAmount}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </>
    )

  };

  render() {
    const { donation } = this.props;
    const { get_status } = donation || {}
    const { donation10BdBe: loadStatus } = get_status || ''
    const { state } = this.props.location
    const { isUploadModal, isUploading, downloadLoader, latestUploadedFile, latestUploadedFileStatus } = this.state;
    const { csvUri, zipUri } = latestUploadedFile
    const isUploadButtonDisabled = latestUploadedFileStatus ? latestUploadedFileStatus === 'APPROVED' : true;
    const tooltip = (
      <Tooltip id="tooltip">Latest files upload status - {latestUploadedFileStatus}</Tooltip>
    );
    const { totalCount, donation10BdBe } = donation
    const isLoadMoreVisible = totalCount > donation10BdBe?.length;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">
                  {state?.nonprofit?.name &&
                    <Link to={`..`} ><Icon path={mdiArrowLeft} size={1} className="arrow-left" color="#333333" />
                    </Link>}<strong>
                    {`${state?.nonprofit?.name ? `${state?.nonprofit?.name} - ` : '10BD/BE '}`}
                  </strong>
                  Donations</h3>
                {/* Parked for future use */}
                {/* <FilterByInput value={filters} onChange={this.filterOnChange} is10BdBe /> */}
                <div className="row justify-content-end">
                  <div className="col-md-6 col-6 text-left ">

                    <OverlayTrigger
                      placement={'auto'}
                      overlay={tooltip}
                      rootClose
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => this.setState({ isUploadModal: true })}
                        disabled={isUploadButtonDisabled}
                      >
                        Upload 10BE
                      </button>
                    </OverlayTrigger>
                    { }
                    {csvUri && csvUri !== undefined && zipUri &&
                      <div className="row mt-3">
                        {/* <div className="col-8">
                          Latest File Upload Status - {latestUploadedFileStatus}
</div> */}
                        <div className="col-8">
                          <p className={`${downloadLoader && 'disabled-button'} download-btn`} onClick={() => this.handleDownload(csvUri)}>{`Download CSV - ${csvUri && csvUri?.split('/').pop()}`}</p>
                        {
                          typeof zipUri === 'string' ? 
                          <p className={`${downloadLoader && 'disabled-button'} download-btn`} onClick={() => this.handleDownload(zipUri)}>{`Download ZIP - ${zipUri && zipUri?.split('/').pop()}`}</p>
                          : 
                          <p className={`${downloadLoader && 'disabled-button'} download-btn`} onClick={() => this.handleDownload(zipUri)}>{`Download ZIP - ${zipUri && zipUri?.map((item) => item?.split('/').pop())}`}</p>

                        }
                        </div>
                        <div className={`${!downloadLoader && 'd-none'} export col-4`}>
                          <div className={`${downloadLoader ? `export-loader-spin` : `export-loader`}`}></div>
                        </div>
                      </div>}
                  </div>
                  <div className="col-md-6 col-6 text-right">
                    <OverlayTrigger
                      trigger="click"
                      placement={isMobile ? "bottom-end" : "left-start"}
                      overlay={this.popover()}
                      rootClose
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={(e) => this.getReportLinks(e, "export")}
                      >
                        Proceed to Export
                      </button>
                    </OverlayTrigger>
                    <div>
                      <i>
                        <small className="text-muted">CSV file contains max of 1000 entries</small>
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card-col col-xl-6 col-12 level-title-12 pr0" >
                <div className="ibox-content prBorder">
                  <div >
                    <strong>ID Code</strong>    :  {donation.donation10BdBe && donation.donation10BdBe[0]?.nonprofit?.pan_card ? '' : ''}
                  </div>
                  <div >
                    <strong>Section Code</strong>    : {donation.donation10BdBe && donation.donation10BdBe.length ? donation.donations[0]?.nonprofit['80g'] : ''}
                  </div>

                  <div >
                    <strong>URN(Reg No of NGO)</strong>    :  {donation.donation10BdBe && donation.donation10BdBe[0]?.nonprofit?.registration_number ? '' : ''}
                  </div>
                </div>
              </div>
              <div className="card-col col-xl-6 col-12 level-title-12 pl0" >
                <div className="ibox-content">
                  <div >
                    <strong>Donation Type</strong>    : Specific grant
                  </div>
                  <div >
                    <strong>Mode of receipt</strong>    : Electronic modes including account payee cheque/draft
                  </div>
                  <div className="hidden">
                    <strong>-</strong>
                  </div>
                </div>
              </div>

            </div>
            <div className="row">

              <div className="card-col col-xl-12 col-12">
                <div className="ibox-content">

                  <div className="table-list live-dis-table">
                    <ul>
                      <li className="table-head">
                        <div className="row">
                          <div className="col-md-12 table-col">
                            <div className="row">
                              <div className="col-md table-col dl-mob">S.No</div>
                              <div className="col-md table-col dl-mob">Pre Ack No</div>
                              <div className="col-md-2 table-col dl-mob">UIN(PAN of Donor)</div>
                              <div className="col-md-2 table-col dl-mob">Date issue of URN</div>
                              <div className="col-md-2 table-col dl-mob">Donor</div>
                              <div className="col-md-2 table-col dl-mob">
                                Address
                              </div>
                              <div className="col-md-1 table-col dl-mob-right">Amount</div>
                            </div>
                          </div>
                        </div>
                      </li>

                      {this.donationsHTML()}
                    </ul>
                    <div className="row">
                      {isLoadMoreVisible ? (
                        <div className="col-md-12 text-center">
                          <button type="button" disabled={loadStatus === "pending"} className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>
                            {loadStatus === "pending" ?
                              <>
                                <span className='ml-2'>
                                  Loading...
                                </span>
                              </>
                              : 'Load more'}

                          </button>
                        </div>
                      ) : null}

                      {donation.get_status.donation10BdBe === "pending" &&
                        donation.donation10BdBe.length !== 0 ? (
                        <div className="col-md-12">
                          <Loading />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Admin10BdBeUploadModal showModal={isUploadModal} setShowModal={() => this.setState({ isUploadModal: false })} handleUpload={this.handleUpload} isUploading={isUploading} latestUploadedFile={latestUploadedFile} latestUploadedFileStatus={latestUploadedFileStatus}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  donation: state.adminDonationList,
  event: state.event,

});

const mapActionToProps = (dispatch) => ({
  Donation: bindActionCreators(adminDonationListAction, dispatch),
  Event: bindActionCreators(eventAction, dispatch),

});

export default connect(mapStateToProps, mapActionToProps)(Admin10BdBeDonations);
