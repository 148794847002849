import React, { Component } from 'react';

import DateInput from '../../../components/DateInput';
import Loading from '../../../appComponents/Loading';
import FilterByInput from '../../../components/FilterByInput';

import Icon from '@mdi/react';
import {
  mdiLoading,
  mdiChevronRight,
} from '@mdi/js';

import Helpers from '../../../utils/helpers';

class TabGenerateDisbursement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pg: [],
      date: [],
      selected: [],
      request: {},
      query: {},
      filters:[
        {
          label: 'Search',
          type: null,
          placeholder: 'Search',
        },
        {
          label: 'Created after',
          type: 'date',
          query: 'start_date',
        },
        {
          label: 'Created before',
          type: 'date',
          query: 'end_date',
        },
        {
          label: 'Fundraiser',
          type: 'service',
          query: 'campaign_id',
          placeholder: 'Type fundraiser title',
          service: 'campaigns',
          filterType: 'multiple'
        },
        {
          label:'Event',
          type: 'list',
          query: 'event_id',
          list: [{label: 'indiafightscorona', value: 'indiafightscorona'}, {label: 'irao2020', value: 'irao2020'}],
        },
        {
          label: 'Entity',
          type: 'list',
          query: 'entity',
          list: Object.keys(props.disbursement.pg).map(pg => {return pg = {label: pg, value: pg}})
        }
      ],
    };
  }

  componentWillUnmount () {
    const { Disbursement } = this.props;
    Disbursement.resetTab();
  }

  changePGList = (e) => {
    e.preventDefault();
    const value = e.target.value.split(',');
    this.setState({ pg: value });
  }

  changeEventList = (e) => {
    e.preventDefault();
    const value = e.target.value;
    this.setState({ event_id: value });
  }

  getPGList = () => {
    const { disbursement } = this.props;
    const { pg } = this.state;
    if (Object.keys(disbursement.pg).length > 0) {
      return (
        <select className="form-control" onChange={this.changePGList} value={pg.join(',')}>
          <option disabled hidden value="">Select</option>
          {Object.keys(disbursement.pg).map(entity => (
            <option key={`${entity}`} value={[entity]}>{`${entity}`}</option>
          ))}
        </select>
      );
    }
    return <Loading />;
  }

  getEventList = () => {
    const { event_id } = this.state;
    const events = ['indiafightscorona','irao2020'];
    if (events.length > 0) {
      return (
        <select className="form-control" onChange={this.changeEventList} value={event_id}>
          <option disabled hidden value="">Select Event</option>
          {events.map(e => (
            <option key={`${e}`} value={[e]}>{`${e}`}</option>
          ))}
        </select>
      );
    }
    return <Loading />;
  }

  dateChange = (e) => {
    this.setState({ date: e });;
  }

  handleCheckBoxChange = (e) => {
    const { selected } = this.state;
    const { disbursement } = this.props;
    const { ngo_list } = disbursement;
    let newSelected = [...selected];
    const value = e.target.checked;
    const name = e.target.name;
    if (name === 'select_all') {
      if (value === true) {
        newSelected = ngo_list.map(item => item.nonprofit._id);
      } else {
        newSelected = [];
      }
    } else if (selected.includes(name)) {
      newSelected.splice(selected.indexOf(name), 1);
    } else {
      newSelected.push(name);
    }
    this.setState({ selected: newSelected });
  }

  getPreDisbursementNgoList = (e) => {
    const { Notification, Disbursement } = this.props;
    e.preventDefault();
    const { query } = this.state;
    if (!query.entity) {
      Notification.addNotification('Please select entity from dropdown');
    } else if (!query.start_date && !query.end_date) {
      Notification.addNotification('Please either select from or to date');
    } else {
      const request = {...query};
      request.start_date = request.start_date;
      request.end_date = request.end_date;
      this.setState({ request });
      let dupRequest = JSON.parse(JSON.stringify(request));
      Disbursement.getNGOList(dupRequest);
    }
  }

  generatePreDisbursement = (e) => {
    e.preventDefault();
    const { selected, request } = this.state;
    const { Disbursement } = this.props;
    const data = { ...request, nonprofit_ids: selected };
    Disbursement.generatePreDisbursement(data);
  }

  getNGOListHTML = () => {
    const { selected } = this.state;
    const { disbursement } = this.props;
    const { ngo_list } = disbursement;
    return (
      <div className="table-list">
        <ul>
          <li className="table-head">
            <div className="row">
              <div className="col-md-12 table-col">
                <div className="row">
                  <div className="col-md-2 table-col dl-mob"><label><input type="checkbox" name="select_all" onChange={this.handleCheckBoxChange} value={false} />&nbsp;&nbsp;Select all</label></div>
                  <div className="col-md-6 table-col dl-mob">NGO Name</div>
                  <div className="col-md-2 table-col dl-mob">No. of donations</div>
                  <div className="col-md-2 table-col dl-mob txt-right">Amount</div>
                </div>
              </div>
            </div>
          </li>
          {ngo_list.map(item => (
            <li className="table-list-item" key={item._id}>
              <div className="row">
                <div className="col-md-12 table-col">
                  <label style={{ display: 'unset', margin: 0 }}>
                    <div className="row">
                      <div className="col-md-2 col-12 table-col dl-mob">
                        <input type="checkbox" name={item._id} onChange={this.handleCheckBoxChange} checked={selected.includes(item._id)} />
                      </div>
                      <div className="col-md-6 col-12 table-col dl-mob">
                        <strong className="show-mobile">NGO Name</strong>
                        <span>{item.nonprofit.name}</span>
                      </div>
                      <div className="col-md-2 col-12 table-col dl-mob">
                        <strong className="show-mobile">No. of donations</strong>
                        <span>{item.donation_count}</span>
                      </div>
                      <div className="col-md-2 col-12 table-col dl-mob txt-right">
                        <strong className="show-mobile">Amount</strong>
                        <span>{`${item.currency_code} ${item.raised_amount}`}</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <button type="button" className="btn btn-primary btn-fw flt-right" onClick={this.generatePreDisbursement}>
          {(disbursement.get_status.generate_pre_disbursemet === 'pending') ? (
            <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
          ) : (
              <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            )}
          &nbsp;Generate pre-disbursement
        </button>
      </div>
    );
  }

  getNGONameFromNGOList = (nonprofit_id) => {
    const { disbursement } = this.props;
    const { ngo_list } = disbursement;
    const exist = Helpers.objSearch(ngo_list, nonprofit_id, '_id');
    if (exist >= 0) {
      return ngo_list[exist].nonprofit.name;
    }
    return 'Unknown';
  }

  filterOnChange = (filters) => {
    this.setState({ filters, query: Helpers.queryBuilderForFilterInput(filters) });
  }

  render() {
    const { children, disbursement } = this.props;
    const { filters } = this.state;
    const { ngo_list, generate_pre_disbursemet } = disbursement;
    return (
      <div className="col-12 grid-margin">
        <div className="card">
          <div className="card-body">
            {children}
            <FilterByInput value={filters} onChange={this.filterOnChange} />
            <div className="col-md-12 text-right">
              <button type="button" onClick={this.getPreDisbursementNgoList} className="btn btn-sm btn-primary btn-fw">
                {(disbursement.get_status.ngo_list === 'pending') ? (
                  <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
                ) : (
                    <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                )}
                &nbsp;GO
              </button>
            </div>
            {Object.keys(generate_pre_disbursemet).length ? (
              <div>
                <h5 className="mt-4 text-center">Successfully disbursed {generate_pre_disbursemet.updates.length} NGOs</h5>
                <h5 className="mt-4 text-center text-danger">Problem in {generate_pre_disbursemet.errors.length} NGOs</h5>
                {generate_pre_disbursemet.errors.length ? (
                  <table className="table table-bordered table-danger">
                    <thead>
                      <tr>
                        <th> NGO id </th>
                        <th> Name </th>
                        <th> Message </th>
                      </tr>
                    </thead>
                    <tbody>
                      {generate_pre_disbursemet.errors.map(item => (
                        <tr>
                          <td> {item.nonprofit_id} </td>
                          <td> {this.getNGONameFromNGOList(item.nonprofit_id)} </td>
                          <td> {item.message} </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ): null}
              </div>
            ) : (
                <div>
                  {ngo_list.length ? this.getNGOListHTML() : null}
                  {(disbursement.get_status.ngo_status === 'pending') ? (
                    <Loading />
                  ) : !ngo_list.length ? (
                    <div className="text-center mt-5">
                      <p>Please select date range and payment gateway and click on GO</p>
                    </div>
                  ) : null}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default TabGenerateDisbursement;
