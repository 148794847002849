import React from 'react';
import Icon from '@mdi/react';
import { mdiOfficeBuilding, mdiFormatPageBreak, mdiTelevision } from '@mdi/js';

import BasicDetails from '../../productComponents/Profile/BasicDetails';
import Gallery from '../../productComponents/Profile/Gallery';
import Documents from '../../productComponents/Profile/Documents';
import PointOfContact from '../../productComponents/Profile/PointOfContact';
import GoverningBody from '../../productComponents/Profile/GoverningBody';
import LegalInformation from '../../productComponents/Profile/LegalInformation';
import OtherDetails from '../../productComponents/Profile/OtherDetails';
import Awards from '../../productComponents/Profile/Awards';
import Project from '../../productComponents/Profile/Project';
import ProjectItem from '../../productComponents/Profile/ProjectItem';
import AgreementOverview from '../../productComponents/Profile/AgreementOverview/agreementOverview'
import Admin10BdBeDonations from '../../productComponents/Admin/Admin10BdBeDonations';

const routes = {
  title: 'Profile',
  path: '/profile',
  default_path: '/profile/:nonprofit/nonprofit/basic',
  for_user: 'user',
  sidebar: [
    {
      title: 'Overview',
      path: '/profile/:nonprofit/overview',
      icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
      component: AgreementOverview,
    },
    {
    title: 'NonProfit',
    icon: (<Icon path={mdiOfficeBuilding} className="menu-icon" size={0.75} color="#979797" />),
    child: [{
      title: 'Basic details',
      path: '/profile/:nonprofit/nonprofit/basic',
      component: BasicDetails,
    }, {
      title: 'Gallery',
      path: '/profile/:nonprofit/nonprofit/gallery',
      component: Gallery,
    },
  
    // {
    //   title: 'Campaign Templates',
    //   path: '/profile/:nonprofit/nonprofit/templates',
    //   component: MouDetails,
    // }, 
    // {
    //   title: 'Media & Documents',
    //   path: '/profile/:nonprofit/nonprofit/media',
    //   component: Documents,
    //   show: ['params.compliance', 'fbassured|giveassured'],
    // },
    // {
    //   title: 'Point Of Contact',
    //   path: '/profile/:nonprofit/nonprofit/contact',
    //   component: PointOfContact,
    //   show: ['params.compliance', 'fbassured|giveassured'],
    // },
    // {
    //   title: 'Governing Body',
    //   path: '/profile/:nonprofit/nonprofit/governing',
    //   component: GoverningBody,
    //   show: ['params.compliance', 'fbassured|giveassured'],
    // },
    // {
    //   title: 'Legal Information',
    //   path: '/profile/:nonprofit/nonprofit/legal-information',
    //   component: LegalInformation,
    //   show: ['params.compliance', 'fbassured|giveassured'],
    // },
    // {
    //   title: 'Other Details',
    //   path: '/profile/:nonprofit/nonprofit/others',
    //   component: OtherDetails,
    //   show: ['params.compliance', 'fbassured|giveassured'],
    // },
    // {
    //   title: 'Awards and Recognitions',
    //   path: '/profile/:nonprofit/nonprofit/awards',
    //   component: Awards,
    // },
  
  ],
  },
  // {
  //   title: '10BD/BE',
  //   path: '/profile/:nonprofit/10bdbe/donations',
  //   icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
  //   component: Admin10BdBeDonations,
  // },
  //  {
  //   title: 'Projects',
  //   path: '/profile/:nonprofit/projects',
  //   icon: (<Icon path={mdiFormatPageBreak} className="menu-icon" size={0.75} color="#979797" />),
  //   component: Project,
  //   maskedChild: [{
  //     path: '/profile/:nonprofit/projects/:project',
  //     component: ProjectItem,
  //   }],
  // }
  ],
};

export default routes;
