/**
 * Service function to make API call
 */
import _Error from '../utils/_error';
import API from '../utils/api';
import { endpoints } from '../config';
import Storage from '../utils/storage';
// import Helpers from '../utils/helpers';


export default class UserService {
  static requestAPI(endpoint = endpoints.user, request = {}) {
    return API.request(endpoint, request);
  }

  static verify(data) {
    return this.requestAPI(undefined, { path: ['authorize'], data });
  }

  static authorise(data) {
    return this.requestAPI(undefined, { path: ['verify'], data }).then((response) => {
      if (response.data && response.data.token) {
        Storage.setItem('token', response.data.token);
      }
      return response.data;
    });
  }

  static get() {
    const token = Storage.getItem('token');
    if (token) {
      return this.requestAPI(undefined, { path: ['me'] }, true)
        .then(response => {
          if(response.data.token){
            Storage.setItem('token', response.data.token);
          }
          return response.data
        })
        .catch((error) => {
          if (error.value.status === 401) {
            Storage.removeItem('token');
          }
          throw error;
        });
    }
    return Promise.reject(new _Error('', { status: 401 }));
  }

  static set(data) {
    return this.requestAPI(undefined, { path: ['me'], data, method: 'post' }, true).then(response => response.data);
  }

  static deauthorise() {
    return new Promise((resolve, reject) => {
      if (Storage.removeItem('token')) {
        resolve(true);
      }
      reject();
    });
  }
}
