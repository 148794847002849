import React, { Component } from 'react'

import Icon from '@mdi/react';
import {
  mdiChevronLeft,
  mdiChevronRight
} from '@mdi/js';

export default class FbOnboardingRequiredDocuments extends Component {

  componentDidMount(){
		window.scrollTo(0, 0);
  }
  
  handleSubmit = async () => {    
    const {state, addError, removeError, onSubmit} = this.props
    const {dataDocuments} = state;
    for (var key of Object.keys(dataDocuments)) {
      if(key !== 'provideDocumentsClarification') {
        if(dataDocuments[key] === '') {
          await addError('dataDocumentsError', key, 'Add data')
        } else {
          await removeError('dataDocumentsError', key)
        }
      }      
    }
    if(this.props.state.error.dataDocumentsError.length == 0) {      
      onSubmit()
    } 
  }

  render() {
    const {state, setData, goBack} = this.props
    const {dataDocuments, error} = state
    const {provideDocumentsClarification} = dataDocuments

    return (
      <>
      <div className="row justify-content-md-center">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3 className="mr-b-20">Step 2 - Documents Required</h3>
              <p>Nonprofit Name: <strong>{this.props.state.nonprofit.name}</strong> </p>
              <p className="mr-b-20">Please go through the list of documents required for the onboarding process for Facebook Charity Giving. There is a separate portal where these will have to be submitted. We recommend you discuss internally on the availability of these documents and information. We have attached a copy of the same document in case you are unable to view it here.</p>
              <p className="text-center">Document required by Non Profits for Facebook Onboarding</p>
              <h4 className="mr-b-20 text-center border-bottom pd-b-20">General Details</h4>
              <div className="row mr-b-20">
                <div className="col-sm-6">
                  <ul>
                    <li>Name of the organization</li>
                    <li>Registered name</li>
                    <li>About the organization</li>
                    <li>NGO Type</li>
                    <li>Auditor's name</li>
                    <li>Public email ID & Public contact number</li>
                    <li>Head office address (Communication address)</li>
                    <li>Registration number and document (trust deed/certificate)</li>
                    <li>12A number and document</li>
                    <li>80G number and document</li>
                    <li>PAN number and document</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul>
                    <li>Bank details (For FCRA and Non-FCRA): Beneficiary (NGO) name, Account number, account type (saving/current), IFSC code, Cheaque number and cancelled cheque document</li>
                    <li>FCRA certificate number and document (latest one, should be valid)</li>
                    <li>Non-profit logo upload</li>
                    <li>Non-profit cover image upload</li>
                    <li>Social media accounts (URLs): Website of the organization, Facebook, Twitter, Instagram, YouTube, LinkedIn</li>
                  </ul>
                </div>
              </div>
              <h4 className="mr-b-20 text-center border-bottom pd-b-20">Project Section</h4>
              <div className="row mr-b-20">
                <div className="col-sm-6">
                  <ul>
                    <li>Name of the  Project</li>
                    <li>Issue  (What  is the concern?)</li>
                    <li>Action  (What  are you going to do  about  it?)</li>
                    <li>Impact  (What  is the impact  you wish to create?)</li>
                    <li>Primary  cause</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul>
                    <li>Secondary  cause</li>
                    <li>Project  location</li>
                    <li>Project  images</li>
                    <li>Donation options  (need to give 3)</li>
                    <li>Cost break-up  and  target amount</li>
                  </ul>
                </div>
              </div>
              <h4 className="mr-b-20 text-center border-bottom pd-b-20">Gallery</h4>
              <div className="row mr-b-20">
                <div className="col-sm-12">
                  <p>Upload as many images and videos as the NGO can</p>
                </div>
              </div>
              <h4 className="mr-b-20 text-center border-bottom pd-b-20">Media and Documents</h4>
              <div className="row mr-b-20">
                <div className="col-sm-12">
                  <p>General document Uploads</p>
                  <ul>
                    <li>Address  proof  (Should  match  with the address  written  in the Basic details field)</li>
                    <li>High resolution  Logo</li>
                  </ul>
                </div>
                <div className="col-sm-12">
                  <p>Financials –Upload the following documents for 3 financial years-2018-19; 2017-18;2016-17</p>
                </div>
                <div className="col-sm-6">
                  <ul>
                    <li>Auditor’s  report</li>
                    <li>Income  and expenditure</li>
                    <li>Schedules</li>
                    <li>Balance sheet</li>
                    <li>Notes on accounts</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul>
                    <li>Annual  report</li>
                    <li>ITR (the assessment  year should  be the  following year)</li>
                    <li>FCRA returns</li>
                  </ul>
                </div>
              </div>
              <h4 className="mr-b-20 text-center border-bottom pd-b-20">Point of Contact</h4>
              <div className="row mr-b-20">
                <div className="col-sm-12">
                  <p>Provide  the Name; email ID and  contact  numbers  of:</p>
                </div>
                <div className="col-sm-6">
                  <ul>
                    <li>CEO/ Head of the organization(PAN copy  also reqd)</li>
                    <li>Fundraising  contact</li>
                    <li>Donor  reporting  contact</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul>
                    <li>First  point  of contact</li>
                    <li>Accounts/  finance  contact</li>
                  </ul>
                </div>
              </div>
              <h4 className="mr-b-20 text-center border-bottom pd-b-20">Governing Body Details</h4>
              <div className="row mr-b-20">
                <div className="col-sm-6">
                  <ul>
                    <li>Do you have a Statutory  Governing  Body?</li>
                    <li>What  is the current  strength  of the body?</li>
                    <li>Board  Composition</li>
                    <li>How many meetings of Board  were held in FY 18-19</li>
                    <li>Minutes  of meeting (Upload  Minutes  of Meetings of Board of Directors  held  in FY 18-19, preferably  AGM)</li>
                    <li>Was there  a change in the Board  composition  as on date?</li>
                    <li>Proof  of change in BoD</li>
                    <li>Certified  note  for approving  signatory  (Any document  or extract  that contains  the names  of all the authorizedsignatories  of the organizationon letterhead,  attested  by one of them)</li>
                    <li>Maximum possible  strength  of the  body as per  the Constitution  of the organization</li>
                    <li>Minimum  possible  strength  of the body  as per the Constitution  of the organization</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <p>All members of the Statutory  Governing  Body: Provide  the following  details for  all the board members:</p>
                  <ul>
                    <li>Name</li>
                    <li>Date of birth  (dd/mm/yyyy  format)</li>
                    <li>Gender</li>
                    <li>Position  on board</li>
                    <li>Remuneration -yes/no</li>
                    <li>PAN/Passport  number  and the softcopies  of all directors  (upto 10 count)</li>
                    <li>Address  proof  (both  sides)  of one  director/  board member</li>
                    <li>CEO’s PAN number and document,  designation  and Date of birth  (dd/mm/yyyy  format)</li>
                    <li>Memorandum  of Association+Articles  of Association</li>
                    <li>Quorum  of Board  Meetings, as stipulated  in your Articles  of Association/  Trust  Deed</li>
                  </ul>
                </div>
              </div>
              <h4 className="mr-b-20 text-center border-bottom pd-b-20">Legal Information</h4>
              <div className="row mr-b-20">
                <div className="col-sm-6">
                  <ul>
                    <li>Has your 12A ever been revoked?</li>
                    <li>Please provide  details  of why it was revoked  and  when it was restored?</li>
                    <li>Is there  a pending  litigation,  by any party  against the organization?</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul>
                    <li>If Yes, then  please upload  a declaration  form.</li>
                    <li>Is there  a pending  litigation,  by the organization against any party?</li>
                    <li>IfYes, then  please upload  a declaration  form</li>
                  </ul>
                </div>
              </div>
              <h4 className="mr-b-20 text-center border-bottom pd-b-20">Norms for 2020-21</h4>
              <div className="row mr-b-20">
                <div className="col-sm-12">
                  <ul>
                    <li>Has your non-profit  status  been questioned  under  any income tax assessment?</li>
                    <li>Do you have a valid 80 G?</li>
                    <li>Has your FCRA  registration  been revoked  or suspended?</li>
                  </ul>
                </div>
              </div>
              <h4 className="mr-b-20 text-center border-bottom pd-b-20">Other details</h4>
              <div className="row mr-b-20">
                <div className="col-sm-6">
                  <p>Details of the Facebook  admin</p>
                  <ul>
                    <li>Name</li>
                    <li>Designation</li>
                    <li>Date of birth</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <p>FB Terms and  Conditions  form to be filled:<br /><b>https://www.giveindia.org/fb-onboarding</b></p>
                </div>
              </div>
              {/* <div className="form-group">
                <label>Please mention if you need clarification on the above segment</label>
                <Editor value={provideDocumentsClarification} onChange={(e) => setEditorData(e, 'dataDocuments', 'provideDocumentsClarification')}/>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h4 className="mr-b-20 text-center border-bottom pd-b-20">Available Documents</h4>
          <div className="form-group row">
          <label className="col-md-12 col-form-label">Having read the list of documents carefully, please select any one of the following options:</label>
          <div className="col-sm-12">
            <div className="form-radio">
              <label className="form-check-label">
                <input type="radio" className="form-check-input" value="all" name="provideDocuments" 
                  onChange={(e) => setData(e, 'dataDocuments')} 
                  checked={state.dataDocuments.provideDocuments === 'all'}
                /> Yes, we will be able to provide all the documents &nbsp;<i className="input-helper" style={{fontSize: '12px'}}>(Required for Facebook)</i>
              </label>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-radio">
              <label className="form-check-label">
                <input type="radio" className="form-check-input" value="none" name="provideDocuments" 
                  onChange={(e) => setData(e, 'dataDocuments')} 
                  checked={state.dataDocuments.provideDocuments === 'none'}
                /> No, we have one or more documents missing <i className="input-helper"></i>
              </label>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-radio">
              <label className="form-check-label">
                <input type="radio" className="form-check-input" value="some" name="provideDocuments" 
                  onChange={(e) => setData(e, 'dataDocuments')} 
                  checked={state.dataDocuments.provideDocuments === 'some'}
                /> We will not be able to share some of the documents <i className="input-helper"></i>
              </label>
            </div>
          </div>
          <span className="field-valid-error-row">{error.dataDocumentsError.findIndex(o => o.key === 'provideDocuments')>-1 && error.dataDocumentsError[error.dataDocumentsError.findIndex(o => o.key === 'provideDocuments')].value}</span>
          </div>
          <span className="save-click-error" style={{display: 'block', textAlign: 'right'}}>{(error.dataDocumentsError.findIndex(o => o.key === 'provideDocuments')>-1 || error.dataDocumentsError.length>0) && 'Please correct all the errors'}</span>
          <div className="card-body">
            <div className="col-md-12 d-flex align-items-stretch">
              <div className="row flex-grow">
                <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                  <button type="button" className="btn btn-outline-primary mr-2" onClick={goBack}>
                    <Icon path={mdiChevronLeft} className="menu-arrow" size={0.75} /> Back
                  </button>
                  <button type="submit" className="btn btn-primary mr-2" onClick={this.handleSubmit}>
                    <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" /> Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
}
