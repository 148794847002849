/*
  This component is used for FormActions
*/

// TODO: Improvements to be made 
// • Optimise compontent render, by checking state and props checking and memoising 

import React from 'react'

import Icon from '@mdi/react';
import {
  mdiChevronLeft,
  mdiLoading,
  mdiFloppy,
  mdiChevronRight,
  mdiConsoleNetworkOutline,
  mdiClose
} from '@mdi/js'

import './Form.scss'


// Component for Action Controllers 
const FormActionControls = props => {
  // Currently when data is getting saved, the icons don't change to loading, set that up 
  const { formActionHandler, hasSaveError, actions, isReadMode, view } = props;
  
  return (
    <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">      
      {/* Previous Button , rendered if actions has a stepchange property */}
      { actions && actions.stepchange && actions.stepchange.previous !== null ? (
        <button type="button" className="btn btn-primary mr-2" onClick={() => actions.stepchange.method(actions.stepchange.previous)}>
          <Icon path={mdiChevronLeft} className="menu-arrow" size={0.75} color="#FFF" />
          &nbsp; Previous
        </button>
      ): null }
  
      { actions && actions.reject && actions.reject.method !== null && view !== "preview" ? (
        <button type="button" className={`btn ${actions.reject.btnClass || `btn-danger`} mr-2`} {...hasSaveError ? {disabled: true}: ''} name="submit" onClick={actions.reject.method}>
          <Icon path={mdiChevronLeft} className="menu-arrow" size={0.75} color="#ffffff" />
          &nbsp; {actions.reject.label || 'Reject All'}
        </button>
      ): null}

      { actions && actions.submit ? (
        <button type="button" className="btn btn-primary mr-2" name="save" onClick={actions.submit.method}>
          {/* {(login.loading === true) ? ( */}
            {/* <Icon path={mdiLoading} size={0.75} color="#ffffff" spin /> */}
          {/* ) : ( */}
            <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
          {/* )} */}
          &nbsp;{actions.submit.label || 'Save'}
        </button>
      ): null }      

      {/* Next Button , rendered if actions has a stepchange property */}
      { actions && actions.stepchange && actions.stepchange.next !== null ? (
        <button type="button" className="btn btn-primary mr-2" onClick={() => actions.stepchange.method(actions.stepchange.next)}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#FFF" />
          &nbsp; Save & Next
        </button>
      ): null}

      { actions && actions.stepchange && actions.stepchange.next === null && actions.submit ? (
        <button type="button" className="btn btn-success mr-2" {...hasSaveError ? {disabled: true}: ''} name="submit" onClick={formActionHandler}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          &nbsp; Submit for Approval
        </button>
      ): null}      

      { actions && actions.editorread ? (
        <button type="button" className="btn btn-success mr-2" {...hasSaveError ? {disabled: true}: ''} name="submit" onClick={actions.editorread?.method}>
          <Icon path={isReadMode ? mdiChevronRight : mdiClose} className="menu-arrow" size={0.75} color="#ffffff" />
          {isReadMode ? ( 
            <React.Fragment>
              &nbsp;{ actions.editorread?.label || 'Edit'}
            </React.Fragment>
          ): (
            <React.Fragment>
              &nbsp; Cancel
            </React.Fragment>
          )}
        </button>
      ): null}

      { actions && actions.sectionApprove ? (
        <button type="button" className="btn btn-success mr-2" {...hasSaveError ? {disabled: true}: ''} name="submit" onClick={actions.sectionApprove.method}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          &nbsp;{ actions.sectionApprove.label || 'Approve'}
        </button>
      ): null}

      { actions && actions.sectionReject ? (
        <button type="button" className="btn btn-danger mr-2" {...hasSaveError ? {disabled: true}: ''} name="submit" onClick={actions.sectionReject.method}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          &nbsp;{ actions.sectionReject.label || 'Reject'}
        </button>
      ): null}

      { actions && actions.approve && view !== "preview" ? (
        <button type="button" className="btn btn-success mr-2" {...hasSaveError ? {disabled: true}: ''} name="submit" onClick={actions.approve.method}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          &nbsp;{ actions.approve.label || 'Approve All'}
        </button>
      ): null}

      { actions && actions.delete && view === "preview" ? (
        <button type="button" className={`btn ${actions.delete.btnClass || `btn-danger`} mr-2`} {...hasSaveError ? {disabled: true}: ''} name="submit" onClick={actions.delete.method}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          &nbsp;{ actions.delete.label || 'Delete'}
        </button>
      ): null}

    </div>
  );      
}


export default FormActionControls