import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as DashboardAction from '../../../reducers/AdminApprovals/dashboardReducer';

import './Dashboard.scss';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { dashboard, Dashboard } = this.props;
    if (!dashboard.get_status.stat) {
      Dashboard.getStats();
    }
  }

  render() {
    const { dashboard } = this.props;
    return (
      <div className="content-wrapper admin-ob-dashboard">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="row">
              <div className="card-col col-xl-3 col-lg-3 col-md-6 col-12">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                      <div className="wrapper text-center text-sm-left">
                        <p className="card-text mb-0">Pending for approvals</p>
                        <div className="fluid-container">
                          <h3 className="mb-0 font-weight-medium">{dashboard.stats.pending_for_approval}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-col col-xl-3 col-lg-3 col-md-6 col-12">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                      <div className="wrapper text-center text-sm-left">
                        <p className="card-text mb-0">Recent archieved in 30 days</p>
                        <div className="fluid-container">
                          <h3 className="mb-0 font-weight-medium">{dashboard.stats.recent_archived}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-col col-xl-3 col-lg-3 col-md-6 col-12">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                      <div className="wrapper text-center text-sm-left">
                        <p className="card-text mb-0">Recent pending forms</p>
                        <div className="fluid-container">
                          <h3 className="mb-0 font-weight-medium">{dashboard.stats.pending_forms}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-col col-xl-3 col-lg-3 col-md-6 col-12">
                <div className="card card-statistics">
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
                      <div className="wrapper text-center text-sm-left">
                        <p className="card-text mb-0">Recent onboarded</p>
                        <div className="fluid-container">
                          <h3 className="mb-0 font-weight-medium">{dashboard.stats.recent_onboarded}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  dashboard: state.adminOnboardingDashboard,
});

const mapActionToProps = dispatch => ({
  Dashboard: bindActionCreators(DashboardAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Dashboard);
