import React from 'react'
import Icon from '@mdi/react';
import './ComplianceOnboardingNotification.scss'
import { mdiInformation } from '@mdi/js';

const ComplianceOnboardingNotification = React.memo((props) => {

  const { giveAssuredCompliance, nonprofit_id} = props

  const getComlianceFormatterName = (key) => {
    switch(key){
      case 'giveassured':
        return 'Give Assured'
      default:
        return key
    }
  }

  return (    
    <div className="card">      
      {giveAssuredCompliance.map(item => {        
          return (
            <div className="card-body">
              <Icon path={mdiInformation} className="menu-icon" size={1} color="#ef8075" /> &nbsp;
              Please click <a href={`/new-onboarding/${nonprofit_id}/${item}`}> Here</a> for Give Assured renewal process. Please make sure you complete the process latest by July 15th 2022, to enjoy the privilege of Give Assured. Only fields that are blank have to be filled in. Do not change the fields that have entry in it.
            </div>          
          )
      })}      
    </div>                              
  )
})

export default ComplianceOnboardingNotification