/**
 * Service function to make API call
 */

 import _Error from '../utils/_error';
 import API from '../utils/api';
 import { endpoints } from '../config';
 // import Helpers from '../utils/helpers';
 
 
 export default class FundraiserReportService {
   static requestAPI(endpoint = endpoints.fundraiserReports, request = {}) {
     return API.request(endpoint, request);
   }
   static get(request) {
     return this.requestAPI(undefined, request);
   }
 }
 