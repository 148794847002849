const URL_FORMAT = new RegExp(`((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)`)
const EMAIL_FORMAT = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
// const EMAIL_FORMAT = new RegExp(`^[^\s@]+@[^\s@]+\.[^\s@]+`)
const PAN_FORMAT = new RegExp(`[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}`)
const IFSC_FORMAT = new RegExp(`^[A-Z]{4}0[A-Z0-9]{6}$`)
const PHONENUMBER_FORMAT = new RegExp(`[^+0-9\s-]+`)

const generateError = (type) => {

  let error_key
  
  switch(type){
    case 'email': 
      error_key = 'Email'
      break;
    case 'url':
      error_key = 'URL'
      break;
    case 'pan':
      error_key = 'PAN Card Number'
      break
    case 'ifsc':
      error_key = 'IFSC Code'
      break
    case 'number':
      error_key = 'Number'
      break
    case 'tel':
      error_key = 'Number'
      break
    default: 
      error_key = 'data'
      break
  }

  const error = {
    status: false,
    message: `Please enter a valid ${error_key}`
  }  

  return error
}

const FormatValidator = {
  url: (input) => {
    if(!URL_FORMAT.test(input)){
      return generateError('url')
    }
    else return true    
  },

  email: (input) => {
    if(!EMAIL_FORMAT.test(input)){
      return generateError('email')
    }
    else return true
  },

  pan: (input) => {
    if(!PAN_FORMAT.test(input)){
      return generateError('pan')
    }
    else return true
  },

  tel: (input) => {
    if(!PHONENUMBER_FORMAT.test(input)){
      return generateError('tel')
    }
    else return true
  },

  number: (input) => {
    if(Number.isNaN(input)){
      return generateError('number')
    }
    else return true
  },

  ifsc: (input) => {
    if(!IFSC_FORMAT.test(input)){
      return generateError('ifsc')
    }
    else return true
  },

  date: (input) => {
    return true
  }
}

export default FormatValidator