import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SelectInput from '../../../components/SelectInput';
import { format } from 'date-fns';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline, mdiChevronRight, mdiLoading, mdiFilter, mdiDelete, mdiPencil, mdiPen } from '@mdi/js';
import { OverlayTrigger, Form, Popover, ListGroup, Button, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Helpers from '../../../utils/helpers';


import * as Tags from '../../../reducers/Tags/tagsReducer'
import './EntityTagWidget.css'


const AdminEntityTagWidget = (props) => {
  
  const [ isAddMode, setIsAddMode ] = useState(false)
  const [ request, setRequest ] = useState({})
  const [ status, setStatus ] = useState('active')
  const [ allTags, setAllTags ] = useState([])
  const [ tagsOptions, setTagsOptions ] = useState([])
  const [ thisEntityTags, setThisEntityTags ] = useState([])
  const { entity_id, entity_type, tags, entityTags, isReadMode, Tags } = props

  useEffect(() => {
    if(entity_id && entity_type){
      setRequest({
        ...request,
        entity_id: entity_id,
        type: entity_type,        
      })
    }    
  }, [ entity_type, entity_id, status])

  useEffect(() => {    
    Tags.get({
      status: 'active'
    })
  },[])

  useEffect(() => {        
    if(Object.keys(request).length){
      Tags.getTagsForEntity(request, { status: status })
    }
  },[request, status])
  
  useEffect(() => {
    if(tags.entityTags && Object.keys(tags.entityTags).length){
      setThisEntityTags([
        ...tags.entityTags.data
      ])
    }

    if(tags.data){      
      setAllTags([
        ...tags.data
      ])
    }
  }, [tags])


  useEffect(() => {
    if(allTags.length){
      const options = []
      allTags.forEach(item => {
        const checkForExisting = thisEntityTags.find(tag => tag._id === item._id);
        if(!checkForExisting && !options.map(o => o.value).includes(item._id)){
          options.push({
            value: item._id,
            label: item.title
          })
        }
      })
      setTagsOptions([ ...options ])
    }
  },[allTags])


  const addTagsForEntity = (e) => {
    const tag = allTags.find(tag => tag._id === e.value)
    setThisEntityTags([
      ...thisEntityTags,
      tag
    ])

    const options = tagsOptions.filter(item => item.value !== e.value)
    setTagsOptions([...options])
  }


  const saveTagsForEntity = () => {

    const tags = thisEntityTags.map(item => {
      return item._id
    })    
    
    Tags.updateTagsForEntity(
      {
        entity_id: entity_id, 
        type: entity_type
      },
      {
        tags: tags
      }).then(() => {
        Tags.getTagsForEntity(request, { status: status })
      }
    )

    thisEntityTags.forEach(async item => {      
      await Tags.edit(item, item._id, false)
      Tags.getTagsForEntity(request, { status: status })
    })    

    setIsAddMode(false)
  }

  const removeTag = (id) => {
    Tags.removeTagsForEntity({
      entity_id: entity_id,
      type: entity_type,
      id: id
    }).then(() => {
      Tags.getTagsForEntity(request, { status: status })
    })
    setThisEntityTags(thisEntityTags.filter(item => item._id !== id))    
  }


  const updateRank = (id, entity, e, currentIndex) => {
    const tags = Helpers.cloneObject(thisEntityTags)
    const tagIndex = Helpers.getArrayIndex(tags, '_id', id)
    const tag = tags[tagIndex]
          
    const entityItemIndex = Helpers.getArrayIndex(tag.items, 'entity', entity)
    const entityItem = tag.items[entityItemIndex]

    entityItem.ids = Helpers.arrayIndexSwitch(entityItem.ids, e.target.value - 1, currentIndex);
    tags[tagIndex].items[entityItemIndex] = entityItem
    setThisEntityTags(tags)
  }



  const deleteConfirmation = (item) => {
    return(
      <Popover id="popover-basic">        
        <Popover.Content>
          <Row>
            <Col md={12} xs={12} className="">
              <p>Are you sure, you want to remove the tag <strong>{item.title}</strong> for this entity?</p>
            </Col>
            <Col md={6} xs={6} className="export-download">
              <button type="button" className="btn btn-outline-secondary btn-block" onClick={e => removeTag(item._id)}>
                Remove
              </button>
            </Col>
            <Col md={6} xs={6} className="export-download">
              <button type="button" className="btn btn-outline-secondary btn-block">
                Cancel
              </button>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    )
  }

  return (
    <div className="form-group mr-b-20">
      <div className="form-group">
        <div className="row">
          <div className="card-col col-xl-12 col-12">
            <div className="ibox-content tag-segment-navigation">
              <ul className="nav">
                <li className={`nav-item ${(status === 'active') ? 'active': ''}`} onClick={() => setStatus('active')}>
                  <a className="nav-link">Active</a>
                </li>
                <li className={`nav-item ${(status === 'archived') ? 'active': ''}`} onClick={() => setStatus('archived')}>
                  <a className="nav-link">Archived</a>
                </li>
                <li className={`nav-item ${(status === 'expired') ? 'active': ''}`} onClick={() => setStatus('expired')}>
                  <a className="nav-link">Expired</a>
                </li>                                  
              </ul>
            </div>
          </div>
        </div>
        <div className="ibox-content">   
          <div className="table-list live-dis-table">
            <ul>
              <li className="table-head">
                <div className="row">
                  <div className="col-md-12 table-col">
                    <div className="row">
                      <div className="col-md-2 table-col dl-mob">Title of Tag</div>
                      <div className="col-md-3 table-col dl-mob">Description</div>
                      <div className={!isReadMode ? `col-md-3 table-col dl-mob`: `col-md-3 table-col dl-mob`}>Created By</div>
                      <div className={!isReadMode ? `col-md-1 table-col dl-mob`: `col-md-2 table-col dl-mob`}>Team</div>
                      <div className="col-md-1 table-col dl-mob">Expiry</div>
                      <div className="col-md-1 table-col dl-mob">Rank</div>
                      {!isReadMode && (
                        <div className="col-md-1 table-col dl-mob-right">Actions</div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
              {thisEntityTags.length ? thisEntityTags.map(item => (
                <li className="table-list-item" key={item._id}>
                <div className="row">
                  <div className="col-md-12 table-col">
                    <div className="row">
                      <div className="col-md-2 table-col dl-mob">
                        <span>{item.title}</span>
                        <br/>
                        <span className="badge badge-primary">{item?.slug}</span>
                      </div>
                      <div className="col-md-3 table-col dl-mob">
                        <small>{item?.meta?.description || 'Not Availabe'}</small>
                      </div>
                      <div className={!isReadMode ? `col-md-3 table-col dl-mob user-info-livedis`: `col-md-3 table-col dl-mob user-info-livedis`}>
                        {(item.user) ? (
                          <>
                          <img alt="image" className="img-circle width-30" src={item.user.profile_pic} />
                          <div className="name-email">
                            <span>{item.user.name}</span>
                            <small>{item.user.email}{item.user.is_email_verified ? (<Icon path={mdiCheckCircleOutline} size={0.75} color="#777777" />) : null}</small>
                            <small style={{ display: 'block' }}>{format(new Date(item?.created_at), 'do MMM yyyy')}</small>                
                          </div>
                          </>
                        ): null }
                      </div>
                      <div className={!isReadMode ? `col-md-1 table-col dl-mob`: `col-md-2 table-col dl-mob`}>
                        {item?.meta?.team || 'Not Availabe'}
                      </div>
                      <div className="col-md-1 table-col dl-mob">
                        <small style={{ display: 'block' }}>{format(new Date(item.expiry), 'do MMM yyyy')}</small>
                      </div>
                      <div className="col-md-1 table-col dl-mob">
                        {isReadMode ? (
                          <>
                            #{item.items.filter(item => item.entity === entity_type).length ? (item.items.filter(item => item.entity === entity_type)[0].ids.indexOf(entity_id) + 1) : 'NA'} 
                            / 
                            {item.items.filter(item => item.entity === entity_type).length ? (item.items.filter(item => item.entity === entity_type)[0].ids.length) : 'NA'}
                          </>
                        ): (
                          <>
                          # <input 
                              className="rank_input" 
                              value={item.items.filter(item => item.entity === entity_type).length ? (item.items.filter(item => item.entity === entity_type)[0].ids.indexOf(entity_id) + 1) : 'NA'} 
                              onChange={(e) => {
                                if(e.target.value.length && parseInt(e.target.value) <= item.items.filter(item => item.entity === entity_type)[0].ids.length) {
                                  updateRank(item._id, entity_type, e, item.items.filter(item => item.entity === entity_type)[0].ids.indexOf(entity_id))}
                                }
                              }
                              type="number"
                              max={item.items.filter(item => item.entity === entity_type).length ? (item.items.filter(item => item.entity === entity_type)[0].ids.length) : 10}
                              min={1}
                              step={1}
                            />
                          / 
                          {item.items.filter(item => item.entity === entity_type).length ? (item.items.filter(item => item.entity === entity_type)[0].ids.length) : 'NA'}
                          </>
                        )}
                        
                      </div>
                      {!isReadMode && (
                        <div className="col-md-1 table-col dl-mob-right">
                          <div className="col-12">                  
                            <OverlayTrigger
                              trigger="click"
                              placement={isMobile ? "bottom-end" : "left-start"}
                              overlay={deleteConfirmation(item)}
                              rootClose
                            >
                              <button type="button" className="btn btn-outline-secondary">
                                <Icon path={mdiDelete} size={0.75} color="#777777"/>
                              </button>
                            </OverlayTrigger>                          
                          </div>
                        </div>
                      )}                    
                    </div>
                  </div>
                </div>
                </li>
              )): (
                <div className="row">
                  <div className="col-12">
                    <p className="center">
                      No tags added to this entity.
                    </p>
                  </div>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="row justify-content-start">

        {isAddMode? (
          <div className="col-md-12 col-12 text-left">
          <p>Add New tags</p>
          <SelectInput options={tagsOptions} onChange={addTagsForEntity}/>
          <br/>          
        </div>
        ): null}

        {!isReadMode ? (        
            <div className="col-md-12 col-12 text-left grid-margin stretch-card flx-h-right np-btn-wrapper">
              <button type="button" disabled={isAddMode} className="btn btn-info mr-2" onClick={() => setIsAddMode(true)}>
                + Add Tag(s)
              </button>
              <button type="button" className="btn btn-info" onClick={() => saveTagsForEntity()}>
                Save
              </button>                    
            </div>        
        ): null}        
      </div>      

    </div>
  )
}

const mapStateToProps = state => ({
  tags: state.tags
});

const mapActionToProps = dispatch => ({
  Tags: bindActionCreators(Tags, dispatch),  
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(AdminEntityTagWidget);