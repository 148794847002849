import * as NotificationAction from './notificationReducer';

import _Error from '../utils/_error';

import EventService from '../services/event-service';

export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAIL = 'GET_EVENT_FAIL';

export const EVENT_RESET = 'EVENT_RESET';

const initialState = {
  get_status: {},
  data: [],
};

export const eventReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EVENT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          event: 'pending',
        },
      };
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        data: action.event,
        get_status: {
          ...state.get_status,
          event: 'success',
        },
      };
    case GET_EVENT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          event: 'failed',
        },
      };
    default:
      return state;
  }
};

export function get() {
  return (dispatch) => {
    dispatch({
      type: GET_EVENT,
    });
    return EventService.get()
      .then((response) => {
        dispatch({
          type: GET_EVENT_SUCCESS,
          event: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_EVENT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: EVENT_RESET,
    });
  };
}
