import React, { useState,useEffect } from 'react';
import Select from 'react-select';
import './SelectInput.scss';

const customStyles = {
  control: style => ({
    ...style,
    minHeight: '32px',
    height: 'auto',
    border: '1px solid #f2f2f2',
    borderRadius: '0',
    fontSize: '0.75rem',
    alignItems: 'flex-start',
    ':hover': {
      borderColor: '#f2f2f2',
    },
  }),
  container: styles => ({
    ...styles,
    ':focus': {
      borderColor: '#80bdff',
    },
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: '#495057',
    padding: '0 8px',
  }),
  clearIndicator: styles => ({
    ...styles,
    color: '#495057',
    padding: '0 8px',
  }),
  menu: styles => ({
    ...styles,
    borderRadius: '0',
  }),
  placeholder: styles => ({
    ...styles,
    color: '#9b9b9b',
  }),
};


function SelectInput(props) {
  const { options, onChange, isMulti, defaultValue, selectedOption } = props;
  const [isSelect, setIsSelect] = useState(true)

  // setting this reset as select should clear the value
  useEffect(() => {
    if (selectedOption === null) {
      setIsSelect(false);
      setTimeout(() => {
        setIsSelect(true);
      }, 0);
    }
  }, [selectedOption])
  return (
    isSelect && <Select isMulti={isMulti} className="" options={options} styles={customStyles} onChange={onChange} defaultValue={defaultValue} />
  );
}
export default SelectInput;
