import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import { format } from 'date-fns';

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import * as PendingApprovalsActions from '../../../reducers/AdminApprovals/pendingApprovalsReducer';

import NonprofitTile from '../../../components/NonprofitTile';

import './PendingApprovals.scss';
import Loading from '../../../appComponents/Loading';
import Helpers from '../../../utils/helpers';

class PendingApprovals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q: '',
    };
  }

  componentDidMount() {
    const { pendingApprovals, PendingApprovals } = this.props;
    const { q } = this.state;
    if (!pendingApprovals.get_status.document_nonprofit) {
      PendingApprovals.getDocumentNonprofits(q);
    }
    if (!pendingApprovals.get_status.content_nonprofits) {
      PendingApprovals.getcontentNonprofits(q);
    }
    if (!pendingApprovals.get_status.cause_nonprofits) {
      PendingApprovals.getCauseNonprofits(q);
    }
    if (!pendingApprovals.get_status.final_nonprofits) {
      PendingApprovals.getFinalNonprofits(q);
    }
  }
   
  componentWillUnmount() {
    const { PendingApprovals } = this.props;
    PendingApprovals.reset();
  }

  handelQ = (e) => {
    e.preventDefault();
    const { q } = this.state;
    if(q !== '' && e.target.value === '') {
      this.clearSearch();
    } else {
      this.setState({q: e.target.value});
    }
  }


  downloadPendingApprovalList = (e) => {
    e.preventDefault();
    const { PendingApprovals } = this.props;
    const { q } = this.state;
    PendingApprovals.getAllPendingNonprofits({ q }).then((data) => {
      const fileName = `GiveFundraisers-pending-nonprofits-${format(new Date(), 'HH:mm:ss')}`;
      const json = data.map((d) => ({
        "Created date": format(new Date(d.created_at), 'do MMM yyyy'),
        "Created time": format(new Date(d.created_at), 'HH:mm'),
        "Updated date": format(new Date(d.updated_at), 'do MMM yyyy'),
        "Updated time": format(new Date(d.updated_at), 'HH:mm'),
        "Name": d.name,
        "PAN": (d.pan_card ? d.pan_card : ''),
        "Status": d.status,
        "POC Name": (d.members[0] ? d.members[0].name : ''),
        "POC Email": (d.members[0] ? d.members[0].email : ''),
        "POC Mobile Number": (d.members[0] ? d.members[0].phone : ''),
      }));
      Helpers.convertJSONtoCSV(json, fileName);
    });
  }

  handelSearch = (e) => {
    e.preventDefault();
    const { q } = this.state;
    if (q.length >= 3) {
      const { PendingApprovals } = this.props;
      PendingApprovals.reset();
      PendingApprovals.getDocumentNonprofits(q);
      PendingApprovals.getcontentNonprofits(q);
      PendingApprovals.getCauseNonprofits(q);
      PendingApprovals.getFinalNonprofits(q);
    }
  }

  clearSearch = () => {
    this.setState({q: ''});
    const { PendingApprovals } = this.props;
    PendingApprovals.reset();
    PendingApprovals.getDocumentNonprofits('');
    PendingApprovals.getcontentNonprofits('');
    PendingApprovals.getCauseNonprofits('');
    PendingApprovals.getFinalNonprofits('');
  }

  loadMore = (type) => {
    const { PendingApprovals } = this.props;
    const { q } = this.state;
    if (type === 'document') {
      PendingApprovals.getDocumentNonprofits(q);
    } else
    if (type === 'content') {
      PendingApprovals.getcontentNonprofits(q);
    } else
    if (type === 'cause') {
      PendingApprovals.getCauseNonprofits(q);
    } else
    if (type === 'final') {
      PendingApprovals.getFinalNonprofits(q);
    }
  }

  render() {
    const { pendingApprovals } = this.props;
    const { q } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row grid-margin">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Pending Nonprofit Onboarding Approvals</h3>
                <div className="row justify-content-end">
                    <div className="col-md-4">
                      <form onSubmit={this.handelSearch}>
                        <div className="form-group d-flex search-field">
                          <div className={classnames('input-group', {active: (q !== '' )})}>
                            <input type="text" className="form-control" placeholder="Search Here" value={q} onChange={this.handelQ} />
                            <div className="search-close">
                              <button type="button" onClick={this.clearSearch}><Icon path={mdiClose} className="menu-arrow" size={0.75} color="#979797" /></button>
                            </div>
                          </div>
                          <button type="submit" className="btn btn-primary ml-3">Search</button>
                        </div>
                      </form>
                      <div className="row justify-content-end">
                          <div className="col-md-12 col-12 text-right">
                            <button type="button" className="btn btn-secondary" onClick={this.downloadPendingApprovalList}>
                              {pendingApprovals.get_status.all_nonprofit === 'pending' ? 'Downloading...' : 'Export to CSV'}
                            </button>
                            <div>
                              <i><small className="text-muted">CSV file contains max of 1000 entries</small></i>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                <div className="row ngo-list-wrapper">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col">
                    <div className="column-list">
                      <h2>Document check</h2>
                      {pendingApprovals.get_status.document_nonprofit === 'pending' ? (
                        <Loading />
                      ) : null}
                      {pendingApprovals.document_nonprofits.map(item => (
                        <NonprofitTile value={item} key={item._id} status="document_check" link={'/approvals/nonprofit-onboarding/'} compliances={item.required_compliances} />
                      ))}
                      {(pendingApprovals.document_nonprofits.length === 0) ? (
                        <i>No items available</i>
                      ): null}
                      {(pendingApprovals.load_status.document_nonprofit) ? (
                        <button type="button" className="btn btn-fw" onClick={(e) => { e.preventDefault(); this.loadMore('document'); }}>Load more</button>
                      ): null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col">
                    <div className="column-list">
                      <h2>Content check</h2>
                      {pendingApprovals.get_status.content_nonprofit === 'pending' ? (
                        <Loading />
                      ) : null}
                      {pendingApprovals.content_nonprofits.map(item => (
                        <NonprofitTile value={item} key={item._id} status="content_check" link={'/approvals/nonprofit-onboarding/'} compliances={item.required_compliances} />
                      ))}
                      {(pendingApprovals.content_nonprofits.length === 0) ? (
                        <i>No items available</i>
                      ): null}
                      {(pendingApprovals.load_status.content_nonprofit) ? (
                        <button type="button" className="btn btn-fw" onClick={(e) => { e.preventDefault(); this.loadMore('content'); }}>Load more</button>
                      ): null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col">
                    <div className="column-list">
                      <h2>4limb/non4limb</h2>
                      {pendingApprovals.get_status.cause_nonprofit === 'pending' ? (
                        <Loading />
                      ) : null}
                      {pendingApprovals.cause_nonprofits.map(item => (
                        <NonprofitTile value={item} key={item._id} status="cause_check" link={'/approvals/nonprofit-onboarding/'} compliances={item.required_compliances} />
                      ))}
                      {(pendingApprovals.cause_nonprofits.length === 0) ? (
                        <i>No items available</i>
                      ): null}
                      {(pendingApprovals.load_status.cause_nonprofit) ? (
                        <button type="button" className="btn btn-fw" onClick={(e) => { e.preventDefault(); this.loadMore('cause'); }}>Load more</button>
                      ): null}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col">
                    <div className="column-list">
                      <h2>Approve & merge</h2>
                      {pendingApprovals.get_status.final_nonprofit === 'pending' ? (
                        <Loading />
                      ) : null}
                      {pendingApprovals.final_nonprofits.map(item => (
                        <NonprofitTile value={item} key={item._id} status="final_approval" link={'/approvals/nonprofit-onboarding/'} compliances={item.required_compliances} />
                      ))}
                      {(pendingApprovals.final_nonprofits.length === 0) ? (
                        <i>No items available</i>
                      ): null}
                      {(pendingApprovals.load_status.final_nonprofit) ? (
                        <button type="button" className="btn btn-fw" onClick={(e) => { e.preventDefault(); this.loadMore('final'); }}>Load more</button>
                      ): null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pendingApprovals: state.pendingApprovals,
});

const mapActionToProps = dispatch => ({
  PendingApprovals: bindActionCreators(PendingApprovalsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(PendingApprovals);
