import React from 'react';
import Icon from '@mdi/react';
import {
  mdiTelevision,
  mdiHomeCityOutline,
  mdiAccountClockOutline,
  mdiAccountCheckOutline
} from '@mdi/js';

import Dashboard from '../../productComponents/AdminApprovals/Dashboard';
import PendingApprovals from '../../productComponents/AdminApprovals/PendingApprovals';
import NGOList from '../../productComponents/AdminApprovals/NGOList';
import ProjectList from '../../productComponents/AdminApprovals/ProjectList';
import PendingApprovalsItem from '../../productComponents/AdminApprovals/PendingApprovalsItem';
import ProjectApprovals from '../../productComponents/AdminApprovals/ProjectApprovals';
import ProjectApprovalsItem from '../../productComponents/AdminApprovals/ProjectApprovalsItem';
import SelfserveNonprofitsList from '../../productComponents/AdminApprovals/SelfserveNonprofitsList';
import SelfserveNonprofitsItem from '../../productComponents/AdminApprovals/SelfserveNonprofitsItem';
import SelfserveProjectsList from '../../productComponents/AdminApprovals/SelfserveProjectsList'
import SelfserveProjectsItem from '../../productComponents/AdminApprovals/SelfserveProjectsItem'
import ComplianceApprovals from '../../productComponents/AdminApprovals/ComplianceApproval'
import ComplianceApprovalItem from '../../productComponents/AdminApprovals/ComplianceApprovalItem'

const routes = {
  title: 'Approvals',
  path: '/approvals',
  default_path: '/approvals/dashboard',
  for_user: 'admin',
  sidebar: [{
    title: 'Dashboard',
    path: '/approvals/dashboard',
    icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
    component: Dashboard,
  }, {
    title: 'Pending',
    icon: (<Icon path={mdiHomeCityOutline} className="menu-icon" size={0.75} color="#979797" />),
    child: [{
      title: 'Nonprofits',
      path: '/approvals/nonprofit-pending',
      component: NGOList,
    }, 
    // {
    //   title: 'Projects',
    //   path: '/approvals/project-pending',
    //   component: ProjectList,
    // }
  ]
  }, {
    title: 'Give Compliant',
    icon: (<Icon path={mdiAccountClockOutline} className="menu-icon" size={0.75} color="#979797" />),
    child: [{
      title: 'Nonprofit',
      path: '/approvals/nonprofit-onboarding',
      component: PendingApprovals,
      maskedChild: [{
        path: '/approvals/nonprofit-onboarding/:id/:status',
        component: PendingApprovalsItem,
      }],
    },
    //  {
    //   title: 'Project',
    //   path: '/approvals/project-onboarding',
    //   component: ProjectApprovals,
    //   maskedChild: [{
    //     path: '/approvals/project-onboarding/:id',
    //     component: ProjectApprovalsItem,
    //   }],
    // }
  ]
  },
  {
    title: 'Give Assured',
    icon: (<Icon path={mdiAccountCheckOutline} className="menu-icon" size={0.75} color="#979797" />),
    child: [{
      title: 'Nonprofits',
      path: '/approvals/nonprofit-onboarding/giveassured',
      component: ComplianceApprovals,
      maskedChild: [{
        path: '/approvals/nonprofit-onboarding/:compliance/:id/:status',
        component: ComplianceApprovalItem,
      }],
    }]
  },
  {
    title: 'Self Serve',
    icon: (<Icon path={mdiTelevision} className="menu-icon" size={0.75} color="#979797" />),
    child: [{
      title: 'Nonprofit',
      path: '/approvals/nonprofit-selfserve',
      component: SelfserveNonprofitsList,
      maskedChild: [{
        path: '/approvals/nonprofit-selfserve/:id/preview',
        component: SelfserveNonprofitsItem,
      }],
    }
    , 
    // {
    //   title: 'Project',
    //   path: '/approvals/project-selfserve',
    //   component: SelfserveProjectsList,
    //   maskedChild: [{
    //     path: '/approvals/project-selfserve/:id',
    //     component: SelfserveProjectsItem,
    //   }],
    // }     
    ]
  },
  ],
};

export default routes;
