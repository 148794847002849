import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classnames from 'classnames';

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import NonprofitTile from '../../../components/NonprofitTile';

import * as NonprofitSelfServeListActions from '../../../reducers/AdminApprovals/nonprofitSelfServeListReducer';

import Loading from '../../../appComponents/Loading';

class SelfserveNonprofitsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      q: '',
    };
  }

  componentDidMount() {
    const { Nonprofits } = this.props;
    const { q } = this.state;
    Nonprofits.getNonprofits({q: ''});
  }

  componentWillUnmount() {
    const { Nonprofits } = this.props;
    Nonprofits.reset();
  }

  handelQ = (e) => {
    e.preventDefault();
    this.setState({ q: e.target.value });
  }

  handelSearch = (e) => {
    e.preventDefault();
    const { q } = this.state;
    const { Nonprofits } = this.props;
    if (q.length >= 3) {
      Nonprofits.reset();
      Nonprofits.getNonprofits({ q });
    } else if (q.length === 0) {
      Nonprofits.reset();
      Nonprofits.getNonprofits({ q: '' });
    }
  }

  clearSearch = () => {
    this.setState({q: ''});
    const { Nonprofits } = this.props;
    Nonprofits.reset();
    Nonprofits.getNonprofits({ q: '' });
  }

  loadMore = () => {
    const { Nonprofits } = this.props;
    const { q } = this.state;
    Nonprofits.getNonprofits({ q });
  }

  NGOListHTML = () => {
    const { nonprofits } = this.props;
    if (!nonprofits.get_status.nonprofit || nonprofits.get_status.nonprofit === 'pending' && nonprofits.nonprofits.length === 0) {
      return (<Loading />);
    }
    if (nonprofits.nonprofits.length === 0) {
      return(
        <i className="align-center">No items available</i>
      );
    }
    return nonprofits.nonprofits.map(item => (
      <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col" key={item.slug}>
        <NonprofitTile value={item} link={'/approvals/nonprofit-selfserve/'} status="aa" />
      </div>
    ));
  }

  render() {
    const { nonprofits } = this.props;
    const { q } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">Pending Nonprofit Self-Serve Approvals</h3>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      {/* <div className="form-group">
                        <SelectInput />
                      </div> */}
                    </div>
                    <div className="col-md-6 col-12">
                      <form onSubmit={this.handelSearch}>
                        <div className="form-group d-flex search-field">
                          <div className={classnames('input-group', { active: (q !== '') })}>
                            <input type="text" className="form-control" placeholder="Search Here" value={q} onChange={this.handelQ} />
                            <div className="search-close">
                              <button type="button" onClick={this.clearSearch}><Icon path={mdiClose} className="menu-arrow" size={0.75} color="#979797" /></button>
                            </div>
                          </div>
                          <button type="submit" className="btn btn-primary ml-3">Search</button>
                        </div>
                      </form>
                    </div>
                  </div>
                <div className="row ngo-list-wrapper">
                  {this.NGOListHTML()}
                </div>
                <div className="row">
                  {(nonprofits.load_status.nonprofit) ? (
                    <div className="col-md-12">
                      <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                    </div>
                  ) : null}
                  {(nonprofits.get_status.nonprofit === 'pending' && nonprofits.nonprofits.length !== 0) ? (
                    <div className="col-md-12">
                      <Loading />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  nonprofits: state.nonprofitSelfServeList,
});

const mapActionToProps = dispatch => ({
  Nonprofits: bindActionCreators(NonprofitSelfServeListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(SelfserveNonprofitsList);
