import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import DonationService from '../../services/donation-service';
import Helpers from '../../utils/helpers';

export const GET_SUPPORT_DONATIONS_LIST = 'GET_SUPPORT_DONATIONS_LIST';
export const GET_SUPPORT_DONATIONS_LIST_SUCCESS = 'GET_SUPPORT_DONATIONS_LIST_SUCCESS';
export const GET_SUPPORT_DONATIONS_LIST_FAIL = 'GET_SUPPORT_DONATIONS_LIST_FAIL';

export const SUPPORT_DONATION_LIST_RESET = 'SUPPORT_DONATION_LIST_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  donations: [],
  get_status: {},
  load_status: {},
  load_more_count: 0,
};

export const supportDonationListReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SUPPORT_DONATIONS_LIST:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation: 'pending',
        },
      };
    case GET_SUPPORT_DONATIONS_LIST_SUCCESS:
      return {
        ...state,
        donations: action.donations.sort((a, b) => (new Date(b.created_at) - new Date(a.created_at))),
        load_more_count: state.load_more_count + 1,
        get_status: {
          ...state.get_status,
          donation: 'success',
        },
        load_status: {
          ...state.load_status,
          donation: action.load_status,
        }
      };
    case GET_SUPPORT_DONATIONS_LIST_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation: 'failed',
        },
      };
    case SUPPORT_DONATION_LIST_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getDonations(filter = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_SUPPORT_DONATIONS_LIST,
    });
    const currentDonations = getState().supportDonationList.donations;
    const params = {
      ...filter,
      limit: 10,
      skip: getState().supportDonationList.load_more_count * 10,
    };    
    return DonationService.get({ params, path: ['whereiswaldo'] })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_SUPPORT_DONATIONS_LIST_SUCCESS,
            donations: currentDonations,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_SUPPORT_DONATIONS_LIST_SUCCESS,
            donations: [...currentDonations, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_SUPPORT_DONATIONS_LIST_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: SUPPORT_DONATION_LIST_RESET,
    });
  };
}
