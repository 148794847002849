import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './Notification.css';

import NotificationsWidget from '../NotificationWidget';

import * as NotificationAction from '../../reducers/notificationReducer';

class NotificationComponent extends Component {
  componentDidMount() {

  }

  showErrors() {
    const { notification, Notification } = this.props;
    return notification.messages.map(item => (<NotificationsWidget {...item} Notification={Notification} key={item.id} />));
  }

  render() {
    return (
      <div className="notification-wrapper">
        {this.showErrors()}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  notification: state.notification,
});

const mapActionToProps = dispatch => ({
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(NotificationComponent);
