import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helpers from '../../../utils/helpers';

import * as nonprofitsActions from '../../../reducers/Profile/nonprofitsReducer';
import * as ConfigAction from '../../../reducers/configReducer';
import * as NotificationAction from '../../../reducers/notificationReducer';

import Icon from '@mdi/react';
import { mdiChevronRight, mdiLoading, mdiFloppy } from '@mdi/js';

import Loading from '../../../appComponents/Loading';
import NonprofitDocumentsRead from '../../../components/NonprofitDocumentsRead';
import NonprofitDocumentsEdit from '../../../components/NonprofitDocumentsEdit';
import { Redirect } from 'react-router-dom';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readEdit: null,
      nonprofit: {},
      prevNonprofit: {},
    };
  }

  componentDidMount() {
    const { nonprofits, Nonprofits, match, config, Config } = this.props;
    const { nonprofit } = match.params;
    if (!nonprofits.get_status.nonprofit) {
      Nonprofits.getNonprofit({ path: [nonprofit] });
    }
    if (!config.get_status.config) {
      Config.get();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.nonprofits.nonprofit !== state.prevNonprofit) {
      setData.prevNonprofit = props.nonprofits.nonprofit;
      setData.nonprofit = Helpers.createNonprofitWithDiff(props.nonprofits.nonprofit);
    }
    if (Object.keys(setData).length > 0) {
      return setData;
    }
    return null;
  }

  onNonprofitSave = (nonprofit) => {
    const { Nonprofits } = this.props;
    return Nonprofits.setNonprofit(nonprofit).then(() => {
      this.changeReadOrEditForm();
    }).catch((e)=>{
      console.log("Errors are", "==>>", JSON.stringify(e));
    });
  }

  onNonprofitChange = (nonprofit) => {
    this.setState({ nonprofit });
  }

  changeReadOrEditForm = (to) => {
    const { readEdit } = this.state;
    const { Notification, nonprofits } = this.props;

    if (to === undefined) {
      this.setState({ readEdit: null });
    } else if (to === null) {
      this.setState({ readEdit: null, nonprofit: Helpers.createNonprofitWithDiff(nonprofits.nonprofit) });
    } else if (to !== null) {
      if(readEdit !== null) {
        Notification.addNotification("Please Save/Cancel before editing another section");
      } else {
        this.setState({ readEdit: to });
      }
    }
  }

  buttonListHTML = (component) => {
    const { nonprofits } = this.props;
    const { readEdit } = this.state;
    if(readEdit === component) {
      return (
        <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
          <button type="submit" className="btn btn-primary mr-2">
            {(nonprofits.set_status.nonprofit === 'pending') ? (
              <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />
            ) : (
                <Icon path={mdiFloppy} className="menu-arrow" size={0.75} color="#ffffff" />
              )}
            &nbsp;Save
          </button>
          <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.changeReadOrEditForm(null); }}>
            <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
            &nbsp;Cancel
          </button>
        </div>
      );
    }
    return (
      <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
        <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.changeReadOrEditForm(component); }}>
          <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
          &nbsp;Edit
        </button>
      </div>
    );
  }

  whichForm = () => {
    const { readEdit, nonprofit, prevNonprofit } = this.state;
    const { config, nonprofits } = this.props;
    let returnDOM;
    if(readEdit === 'general') {
      returnDOM = (
        <NonprofitDocumentsEdit value={nonprofit} errors={nonprofits.errors.nonprofit} onSubmit={this.onNonprofitSave} onChange={this.onNonprofitChange} config={config}>
          {this.buttonListHTML('general')}
        </NonprofitDocumentsEdit>
      );
    } else {
      returnDOM = (
        <NonprofitDocumentsRead tabs={{ Draft: nonprofit, Live: prevNonprofit }}>
          {this.buttonListHTML('general')}
        </NonprofitDocumentsRead>
      );
    }

    return (
      <div className="content-wrapper">
        {returnDOM}
      </div>
    );
  }

  render() {
    const { nonprofits } = this.props;
    if (!nonprofits.get_status.nonprofit || nonprofits.get_status.nonprofit === 'pending') {
      return (<Loading />);
    }
    if (!nonprofits.get_status.nonprofit || nonprofits.get_status.nonprofit === 'failed') {
      return (<Redirect to="/404" />);
    }
    return this.whichForm();
  }
}

const mapStateToProps = state => ({
  nonprofits: state.profileNonprofits,
  config: state.config,
});

const mapActionToProps = dispatch => ({
  Nonprofits: bindActionCreators(nonprofitsActions, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(Documents);
