import React, { Component } from 'react'
import OnboardingForm from './onboardingForm'
import * as facebookOnboardingActions from '../../reducers/FacebookOnboarding/fbOnboardingReducer'
import { connect } from 'react-redux';
import './fbOnboarding.scss';
import { bindActionCreators } from 'redux';
import Icon from '@mdi/react';
import {
  mdiLoading,
} from '@mdi/js';

class FBOnboarding extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ngoname: null,
			url: '',
			signName: null,
			signemail: '',
			isFacebookTermsAndConditionAccepted: false,
			isRazorpayTermsAndConditionAccepted: false,
			formSubmitionComplete: false,
			termsAndConditions: '',
			pan: '',
			formSubmitting: null,
		}
	}


	handleInputChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value,
			formSubmitting: null
		});
	};

	handleClick = (e) => {
		this.setState({
			[e.target.id]: e.target.checked,
		})
	};


	submitOnboardingForm = async (e) => {
		e.preventDefault();
		this.setState({formSubmitting: true});
		const { FbOnboarding } = this.props
		const { ngoname, url, signName, signemail, isFacebookTermsAndConditionAccepted, isRazorpayTermsAndConditionAccepted, pan } = this.state;

		const req_payload = {
			name: ngoname,
			signatory_name: signName,
			url: url,
			signatory_email: signemail,
			accept_facebook_terms: isFacebookTermsAndConditionAccepted,
			accept_razorpay_terms: isRazorpayTermsAndConditionAccepted,
			pan: pan
		};
		await FbOnboarding.setNgoDetails(req_payload).then((res) => {
			this.setState({ formSubmitionComplete: res.success });
		})
		this.setState({formSubmitting: null});
	};


	render() {
		const formErrors = this.props.fbOnboarding.errors;
		return (

			<div>
				<div className="navbar-brand-wrapper align-items-top" style={{ padding: '10px 10px 30px' }}>
					<a className="navbar-brand brand-logo" href="/">
						<img src="/static/media/giveindia.f7bf8247.svg" alt="logo" width='125px' /></a>
				</div>

				<div>
					<title>Facebook onboarding form</title>
					<meta name="description" content="Give.do is India’s largest and most trustworthy online donation platform. Donate to India’s most credible NGOs and causes including health, education, elderly care and more." />
				</div>


				{this.state.formSubmitionComplete ? (
					<div>
						<h3 className="_form-header">Thank you for on-boarding.</h3>
						<div className="container" style={{ paddingBottom: 20 }}>
							<div style={{ fontSize: '20px' }}>
								Thanks, your charitable organization is now eligible to submit application materials through Give.do, we look forward to your charity raise funds on the Facebook platform. The application process is estimated to take a week.
									</div>
						</div>
					</div>
				) : (
						<div>
							<OnboardingForm
								handleInputChange={this.handleInputChange}
								ngoName={this.state.ngoName}
								url={this.state.url}
								signatoryName={this.state.signatoryName}
								signatoryEmail={this.state.signatoryEmail}
								pan={this.state.pan}
								formErrors={formErrors.formData}
							/>

							<div className="onboarding-terms">
								<h3 className="_form-header">Facebook Terms of Use</h3>
								<div className="container" style={{ padding: '20px 10px' }}>
									<div style={{ fontSize: '20px', display: 'flex' }} id='isFacebookTermsAndConditionAccepted'>
										<input type="checkbox" onClick={(e) => this.handleClick(e)} id='isFacebookTermsAndConditionAccepted' required />
										<label htmlFor="accept">On behalf of my organization, I accept Facebook's <a href={'https://www.facebook.com/legal/donation_button_terms'} target="_blank" rel="noopener noreferrer" style={{ color: '#59699B', textDecoration: 'underline', cursor: 'pointer', marginRight: '20px' }}>Charitable Donation Terms</a></label>
									</div>
									{this.state.termsAndConditions.length > 0 ? (<p className="error-message">{this.state.termsAndConditions}</p>) : null}
								</div>
							</div>

							<div className="onboarding-terms">
								<h3 className="_form-header">Razorpay Terms of Use</h3>
								<div className="container" style={{ padding: '20px 10px' }}>
									<div style={{ fontSize: '20px', display: 'flex' }} id="isRazorpayTermsAndConditionAccepted">
										<input type="checkbox" onClick={(e) => this.handleClick(e)} id="isRazorpayTermsAndConditionAccepted" required />
										<label htmlFor="accept">On behalf of my organization, I accept Razorpay's <a href={'https://razorpay.com/terms/'} target="_blank" style={{ color: '#59699B', textDecoration: 'underline', cursor: 'pointer', marginRight: '20px' }}>Terms of Use</a></label>
									</div>
									{this.state.termsAndConditions.length > 0 ? (<p className="error-message">{this.state.termsAndConditions}</p>) : null}

								</div>

							</div>
						</div>)}

				{!this.state.formSubmitionComplete ? (<div className="form-action-buttons">
					<button className={`${(!this.state.isFacebookTermsAndConditionAccepted && !this.state.isRazorpayTermsAndConditionAccepted) || this.state.formSubmitting ? 'disable-submit-btn' : 'btn-next'} `} onClick={this.submitOnboardingForm} disabled={(!this.state.isFacebookTermsAndConditionAccepted && !this.state.isRazorpayTermsAndConditionAccepted) || this.state.formSubmitting ? true : false}>
					{this.state.formSubmitting && <Icon path={mdiLoading} size={0.75} color="#ffffff" spin />}
					 Finish 
					</button>
				</div>) : null}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	fbOnboarding: state.facebookOnboarding
})

const mapActionToProps = (dispatch) => ({
	FbOnboarding: bindActionCreators(facebookOnboardingActions, dispatch)
})

export default connect(mapStateToProps, mapActionToProps)(FBOnboarding);