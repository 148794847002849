import React from 'react';
import ReactDOM from 'react-dom';

import Loadable from 'react-loadable';
import { Provider as ReduxProvider } from 'react-redux';

import './index.css';
import './resources/scss/style2.scss';
import './resources/scss/style-main.scss';
import './resources/scss/common.scss';
import Routes from './routes/routes';
import configureStore from './reducers/configureStore';
import * as serviceWorker from './serviceWorker';

const store = configureStore();
const AppBundle = React.createElement(ReduxProvider, {
  store,
}, React.createElement(Routes, null));

window.onload = () => {
  Loadable.preloadReady().then(() => {
    ReactDOM.hydrate(
      AppBundle,
      document.getElementById('root'),
    );
  });
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
