import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';
import DonationService from '../../services/donation-service';
import CampaignService from '../../services/campaign-service';

export const GET_CROWDFUNDING_DASHBOARD_NONPROFIT = 'GET_CROWDFUNDING_DASHBOARD_NONPROFIT';
export const GET_CROWDFUNDING_DASHBOARD_NONPROFIT_SUCCESS = 'GET_CROWDFUNDING_DASHBOARD_NONPROFIT_SUCCESS';
export const GET_CROWDFUNDING_DASHBOARD_NONPROFIT_FAIL = 'GET_CROWDFUNDING_DASHBOARD_NONPROFIT_FAIL';

export const GET_CROWDFUNDING_DASHBOARD_DONATIONS = 'GET_CROWDFUNDING_DASHBOARD_DONATIONS';
export const GET_CROWDFUNDING_DASHBOARD_DONATIONS_SUCCESS = 'GET_CROWDFUNDING_DASHBOARD_DONATIONS_SUCCESS';
export const GET_CROWDFUNDING_DASHBOARD_DONATIONS_FAIL = 'GET_CROWDFUNDING_DASHBOARD_DONATIONS_FAIL';

export const GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS = 'GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS';
export const GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS_SUCCESS = 'GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS_SUCCESS';
export const GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS_FAIL = 'GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS_FAIL';

export const CROWDFUNDING_DASHBOARD_RESET = 'CROWDFUNDING_DASHBOARD_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  nonprofit: {},
  donations: [],
  campaigns: [],
  get_status: {},
};

export const dashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CROWDFUNDING_DASHBOARD_NONPROFIT:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'pending',
        },
      };
    case GET_CROWDFUNDING_DASHBOARD_NONPROFIT_SUCCESS:
      return {
        ...state,
        nonprofit: action.nonprofit,
        get_status: {
          ...state.get_status,
          nonprofit: 'success',
        },
      };
    case GET_CROWDFUNDING_DASHBOARD_NONPROFIT_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          nonprofit: 'failed',
        },
      };
    case GET_CROWDFUNDING_DASHBOARD_DONATIONS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation: 'pending',
        },
      };
    case GET_CROWDFUNDING_DASHBOARD_DONATIONS_SUCCESS:
      return {
        ...state,
        donations: action.donations,
        get_status: {
          ...state.get_status,
          donation: 'success',
        },
      };
    case GET_CROWDFUNDING_DASHBOARD_DONATIONS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          donation: 'failed',
        },
      };
    case GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          campaign: 'pending',
        },
      };
    case GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.campaigns,
        get_status: {
          ...state.get_status,
          campaign: 'success',
        },
      };
    case GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          campaign: 'failed',
        },
      };
    case CROWDFUNDING_DASHBOARD_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getNonprofit(request) {
  return (dispatch) => {
    dispatch({
      type: GET_CROWDFUNDING_DASHBOARD_NONPROFIT,
    });
    return NonProfitService.get(request)
      .then((response) => {
        dispatch({
          type: GET_CROWDFUNDING_DASHBOARD_NONPROFIT_SUCCESS,
          nonprofit: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CROWDFUNDING_DASHBOARD_NONPROFIT_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getDonations(request) {
  return (dispatch) => {
    dispatch({
      type: GET_CROWDFUNDING_DASHBOARD_DONATIONS,
    });
    const params = {
      filter_by_nonprofit: request._id,
      filter_by_status: 'LIVE',
      skip: 0,
      limit: 20
    };
    return DonationService.get({ params })
      .then((response) => {
        dispatch({
          type: GET_CROWDFUNDING_DASHBOARD_DONATIONS_SUCCESS,
          donations: response.supporters,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CROWDFUNDING_DASHBOARD_DONATIONS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getCampaigns(request) {
  return (dispatch) => {
    dispatch({
      type: GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS,
    });
    const params = {
      'nonprofit._id': request._id,
      scope: 'global',
      skip: 0,
      limit: 10,
    };
    return CampaignService.get({ params })
      .then((response) => {
        dispatch({
          type: GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS_SUCCESS,
          campaigns: response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CROWDFUNDING_DASHBOARD_CAMPAIGNS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: CROWDFUNDING_DASHBOARD_RESET,
    });
  };
}
