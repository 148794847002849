import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import Icon from '@mdi/react';
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';

export default class FbOnboardWelcome extends Component {

  componentDidMount(){
		window.scrollTo(0, 0);
  }

  render() {
    const {heading, description, buttonText, redirectUrl, customButton, mailTo} = this.props.state.finalData;
    return (
      <div className="container-scroller">

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h3 className="mr-b-20">{heading}</h3>
              <div className="text-muted" dangerouslySetInnerHTML={{__html: description}} />
            </div>
          
            <div className="col-md-12 d-flex align-items-stretch">
              <div className="row flex-grow">
                <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">
                  <button type="button" className="btn btn-outline-primary mr-2" onClick={this.props.goBack}>
                    <Icon path={mdiChevronLeft} className="menu-arrow" size={0.75} /> Back
                  </button>
                  {buttonText &&
                  <Link className="btn btn-success mr-2" to={redirectUrl}>
                    <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                    &nbsp;{buttonText}
                  </Link>}
                  {customButton && 
                  <a className="btn btn-success mr-2" href={mailTo}>
                  <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                  &nbsp;{customButton}
                  </a>}
                </div>
              </div>
            </div>
          </div>
        </div>
            
    </div>
    )
  }
}
