import React from 'react';

import Icon from '@mdi/react';
import {
  mdiHeart
} from '@mdi/js';

import './Footer.css';

function Footer(){  
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
          Copyright ©
            {new Date().getFullYear()}
          . All rights reserved.
          </span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          Hand-crafted with&nbsp;
          <Icon path={mdiHeart} className="heart" size={0.75} color="#f44336" />
        </span>
      </div>
    </footer>
  );
}
export default Footer;
