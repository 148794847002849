import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiAlertCircle } from '@mdi/js';
import Helpers from '../../utils/helpers';

import { format } from 'date-fns'

import ApprovalsRejectionsButtons from '../ApprovalsRejectionsButtons';
import { Link } from 'react-router-dom';

class NonprofitBasicDetailsRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: Object.keys(props.tabs)[0],
      showGSTAlert: false,
    };
  }

  componentDidMount() {
    this.checkGSTStatus()
  }

  checkGSTStatus = () => {
    const value = this.props.tabs[this.state.view]
    const value_with_edits = Helpers.createNonprofitWithDiff(value)
    if (!(value_with_edits.gst_details && value_with_edits.gst_details.gst_number)) {
      this.setState({
        showGSTAlert: true
      })
    }
  }


  switchView = (view) => {
    this.setState({ view: view });
  }

  na = (<i>Not available</i>);

  getInputData = (key) => {
    const { tabs } = this.props;
    const { view } = this.state;
    const value = tabs[view];
    const val = Helpers.getDataFromObject(value, key);
    if (val === '' && key[1] == "12a_expiry_date") {
      return val
    }
    if (val === '' && key[1] !== 'video') {
      return this.na;
    }
    return val;
  }



  getApprovalRejectionState = (key) => {
    const { approvals, rejections, approvalRejectionChange, tabs } = this.props;
    if (approvals && rejections && approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (selected.length === 0) {
        return null;
      }
      if (approvals.length > 0 && selected.filter(s => approvals.includes(s)).length === selected.length) {
        return 'approving';
      }
      if (rejections.length > 0 && selected.filter(s => rejections.includes(s)).length === selected.length) {
        return 'rejecting'
      }
      return 'default';
    }
    return undefined;
  }

  setApprovalRejectionState = (key, to) => {
    const { approvalRejectionChange, tabs } = this.props;
    if (approvalRejectionChange) {
      const edits = tabs.Approvals.edits;
      const selected = Helpers.getSelectedFromEdits(key, edits);
      if (to === 'defaultToApproving') {
        approvalRejectionChange(selected, 'approvals', 'add');
      }
      if (to === 'defaultToRejecting') {
        approvalRejectionChange(selected, 'rejections', 'add');
      }
      if (to === 'rejectingToDefault') {
        approvalRejectionChange(selected, 'rejections', 'remove');
      }
      if (to === 'approvingToDefault') {
        approvalRejectionChange(selected, 'approvals', 'remove');
      }
    }
  }
  render() {
    const { children, tabs, approvals, rejections, approvalRejectionChange, status } = this.props;
    const { view } = this.state;
    const liveCauses = this.getInputData(['nonprofit', 'causes']);
    const causes = this.getInputData(['nonprofit', 'edits'])
    let updateCauses =causes?.length >0 && causes?.filter((data)=>{
      if(data.path.includes("cause")) 
        return data
    });
    return (
      <div className="row justify-content-md-center">
        {this.state.showGSTAlert ?
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="heading-bar">
                  <h3 className="mr-b-10"><Icon path={mdiAlertCircle} className="menu-arrow" size={1} color="#ff5050" /> Update GST Details for your organisation</h3>
                </div>
                <p>Click <a href="#" onClick={(e) => { this.props.changeReadOrEditForm('general') }}>here</a> to fill in your GST details, if any. Read GST related FAQs to know more</p>
              </div>
            </div>
          </div>
          : null}

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="heading-bar">
                <h3 className="mr-b-20">General Details</h3>
                <p className={`ob-status ${this.getInputData(['nonprofit', 'status'])}`}>{this.getInputData(['nonprofit', 'status'])}</p>
                <div className="heading-bar-btn">
                  {Object.keys(tabs).length > 1 && Object.keys(tabs).map(tab => {
                    if (tab === view) {
                      return (
                        <button type="button" className="btn btn-primary btn-left" key={tab}>{tab}</button>
                      );
                    }
                    return (
                      <button type="button" className="btn btn-outline-secondary btn-right" onClick={(e) => { e.preventDefault(); this.switchView(tab) }} key={tab}>{tab}</button>
                    );
                  })}
                </div>
              </div>
              <ApprovalsRejectionsButtons path={['nonprofit', 'name']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div>
                  <h6> Name of the Organization</h6>
                  <p className="text-muted">{this.getInputData(['nonprofit', 'name'])}</p>
                </div>
              </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['nonprofit', 'official_name']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div>
                  <h6> Registered Name (as per the Registration Certificate/PAN Card)</h6>
                  <p className="text-muted">{this.getInputData(['nonprofit', 'official_name'])}</p>
                </div>
              </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['nonprofit', 'description']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div>
                  <h6> About the Organization</h6>
                  <div className="text-muted image-container" dangerouslySetInnerHTML={{ __html: this.getInputData(['nonprofit', 'description']) }}></div>
                </div>
              </ApprovalsRejectionsButtons>
              <div className="row">
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'registration_type']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> NGO Type</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'registration_type'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'auditor_name']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Auditor's Name</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'auditor_name'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'video']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6>YouTube Video URL</h6>
                      <div className="text-muted">
                        {(Helpers.getYoutubeId(this.getInputData(['nonprofit', 'video'])) !== null) ? (
                          <iframe width="100%" height="300" src={`https://www.youtube.com/embed/${Helpers.getYoutubeId(this.getInputData(['nonprofit', 'video']))}`} frameBorder="0" allowFullScreen></iframe>
                        ) : this.getInputData(['nonprofit', 'video'])}
                      </div>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>



              <div className="row">
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'email']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Public E-mail ID</h6>
                      <p>
                        <a className="text-muted" href={"mailto:" + this.getInputData(['nonprofit', 'email'])}>{this.getInputData(['nonprofit', 'email'])}</a>
                      </p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'phone']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Public Contact Number</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'phone'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
              <ApprovalsRejectionsButtons path={['nonprofit', 'registration_type']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div>
                  <h4 className="mr-b-20">Causes</h4>
                  {
                    view == "Draft" ? <>
                      {tabs?.Draft?.causes && tabs?.Draft?.causes.map((item) => 
                  {
                    const subCausesString = item?.subcauses?.length > 0 ? item.subcauses.join(', ') : '';
                   return(
                      <div className="row">
                      <div className="col-md-4">
                        <div>
                          <h6>Cause</h6>
                          <p className="text-muted">{item.name}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <h6>Subcauses</h6>
                            <p className="text-muted">{subCausesString}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <h6>Desription</h6>
                          <p className="text-muted">{item.description}</p>
                        </div>
                      </div>
                    </div>
                    )})}
                    </> :<>
                    {liveCauses && liveCauses?.map((item) => 
                  {
                    const subCausesString = item?.subcauses?.length > 0 ? item.subcauses.join(', ') : '';
                   return(
                      <div className="row">
                      <div className="col-md-4">
                        <div>
                          <h6>Cause</h6>
                          <p className="text-muted">{item.name}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <h6>Subcauses</h6>
                            <p className="text-muted">{subCausesString}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <h6>Desription</h6>
                          <p className="text-muted">{item.description}</p>
                        </div>
                      </div>
                    </div>
                    )})}
                    
                    </>
                  }
                </div>
              </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['nonprofit', 'registration_type']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div>
                  <h4 className="mr-b-20">Demographics</h4>
                  {tabs?.Draft?.demographics && tabs?.Draft?.demographics.map((item) => (
                    <div className="row">
                      <div className="col-md-4">
                        <div>
                          <h6>Gender</h6>
                          <p className="text-muted">{item.gender}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <h6>Age</h6>
                            <p className="text-muted">{item.age}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <h6>Role</h6>
                          <p className="text-muted">{item.roles && item.roles.length ? item.roles[0] :''}</p>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['nonprofit', 'address']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div>
                  <h4 className="mr-b-20">Head Office Address</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <h6> Address Part 1</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'address', 'street'])}</p>
                    </div>
                    <div className="col-md-6">
                      <h6> Address Part 2</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'address', 'locality'])}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <h6> City</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'address', 'city'])}</p>
                    </div>
                    <div className="col-md-3">
                      <h6> State</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'address', 'region'])}</p>
                    </div>
                    <div className="col-md-3">
                      <h6> Country</h6>
                      <p className="text-muted">India</p>
                    </div>
                    <div className="col-md-3">
                      <h6> Pincode</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'address', 'area_code'])}</p>
                    </div>
                  </div>
                </div>
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Registration Details</h3>
              <div className="row">
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'registration_number']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Registration/Trust Deed/MOA Number</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'registration_number'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'registration_doc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> Registration/Trust Deed/MOA Number Document</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'registration_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'registration_doc'])}>(Preview)</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', '12a']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> 12A Number</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', '12a'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', '12a_doc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> 12A Document</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', '12a_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', '12a_doc'])}>(Preview)</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', '12a_expiry_date']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> 12A Expiry Date</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', '12a_expiry_date']) !== "" ? format(new Date(this.getInputData(['nonprofit', '12a_expiry_date'])), 'yyyy-MM-dd') : "-"}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', '80g']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> 80G Number</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', '80g'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', '80g_doc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> 80G Document</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', '80g_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', '80g_doc'])}>(Preview)</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', '80g_expiry_date']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> 80G Expiry Date</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', '80g_expiry_date']) !== null && this.getInputData(['nonprofit', '80g_expiry_date']) !== this.na ? format(new Date(this.getInputData(['nonprofit', '80g_expiry_date'])), 'yyyy-MM-dd') : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'pan_card']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> PAN Card Number</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'pan_card'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'pan_card_doc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> PAN Card Document</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'pan_card_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'pan_card_doc'])}>(Preview)</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
            </div>
            <div className="card-body">
              <ApprovalsRejectionsButtons path={['nonprofit', 'bank_accounts']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <h3 className="mr-b-20">Bank Account Details</h3>
                <h4 className="mr-b-20">Domestic Bank Account</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <h6> Beneficiary Name</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', false, 'account_name'])}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <h6> Account Type</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', false, 'account_type'])}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <h6> Account Number</h6 >
                      <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', false, 'account_number'])}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <h6> IFSC</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', false, 'ifsc_code'])}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <h6> Bank Name</h6 >
                      <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', false, 'bank_name'])}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <h6> Bank Address</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', false, 'bank_address'])}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <h6> Bank Phone</h6 >
                      <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', false, 'bank_phone'])}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <h6> Cancelled Cheque</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', false, 'cancelled_cheque_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'bank_accounts', false, 'cancelled_cheque_doc'])}>(Preview)</a>) : this.na}</p>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-md-6 col-form-label">Can Accept Donations Without FCRA?</label>
                  <label className="col-md-6 col-form-label">{this.getInputData(['nonprofit', 'can_accept_donations_without_fcra']) === true ? "Yes" : "No"}</label>
                </div>
                {
                  this.getInputData(['nonprofit', 'can_accept_donations_without_fcra']) === true ? <><h4 className="mr-b-20">Non FCRA Bank Account</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <h6> Beneficiary Name</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'account_name'])}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <h6> Account Type</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'account_type'])}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <h6> Account Number</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'account_number'])}</p>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div>
                          <h6> ABA routing number</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'swift_code'])}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <h6> Bank Name</h6 >
                          <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'bank_name'])}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <h6> Bank Address</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'bank_address'])}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <h6> Bank Phone</h6 >
                          <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'bank_phone'])}</p>
                        </div>
                      </div>
                    </div> </> :
                    <>
                      <h4 className="mr-b-20">FCRA Bank Account</h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div>
                            <h6> Beneficiary Name</h6>
                            <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'account_name'])}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <h6> Account Type</h6>
                            <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'account_type'])}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <h6> Account Number</h6>
                            <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'account_number'])}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <h6> IFSC</h6>
                            <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'ifsc_code'])}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <h6> SWIFT Code</h6>
                            <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'swift_code'])}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <h6> Bank Name</h6 >
                            <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'bank_name'])}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <h6> Bank Address</h6>
                            <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'bank_address'])}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <h6> Bank Phone</h6 >
                            <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'bank_phone'])}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <h6> Cancelled Cheque</h6>
                            <p className="text-muted">{this.getInputData(['nonprofit', 'bank_accounts', true, 'cancelled_cheque_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'bank_accounts', true, 'cancelled_cheque_doc'])}>(Preview)</a>) : this.na}</p>
                          </div>
                        </div>
                      </div>
                    </>
                }



              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20"> FCRA</h3>
              <div className="row">
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'fcra']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> FCRA Number</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'fcra'])}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'fcra_doc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> FCRA Certificate</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'fcra_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'fcra_doc'])}>Preview</a>) : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
                <div className="col-md-6">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'fcra_validity']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div>
                      <h6> FCRA Expiry</h6>
                      <p className="text-muted">{this.getInputData(['nonprofit', 'fcra_validity']) !== this.na ? format(new Date(this.getInputData(['nonprofit', 'fcra_validity'])), 'yyyy-MM-dd') : this.na}</p>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
            </div>
            {/* Add the GST Read fields Here */}
            <div className="card-body">
              <h3 className="mr-b-20">GST Details</h3>
              <div className="row">
                <div className="col-md-12">
                  <ApprovalsRejectionsButtons path={['nonprofit', 'gst_details']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                    <div className="row">
                      <div className='col-md-6'>
                        <h6> GST Number</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'gst_details', 'gst_number'])}</p>
                      </div>
                      <div className='col-md-6'>
                        <h6> GST Certificate</h6>
                        <p className="text-muted">{this.getInputData(['nonprofit', 'gst_details', 'gst_uri']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'gst_details', 'gst_uri'])}>Preview</a>) : this.na}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div>
                          <h6> Address</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'street'])}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <h6> City</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'city'])}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <h6> State</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'region'])}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <h6> Pincode</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'area_code'])}</p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <h6> Country</h6>
                          <p className="text-muted">{this.getInputData(['nonprofit', 'gst_details', 'gst_address', 'country_code'])}</p>
                        </div>
                      </div>
                    </div>
                  </ApprovalsRejectionsButtons>
                </div>
              </div>
            </div>
            {/* GST Read fields END */}
            <div className="card-body">
              <h3 className="mr-b-20"> Non-profit Images</h3>
              <ApprovalsRejectionsButtons path={['nonprofit', 'image']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div>
                  <h6> Nonprofit Logo</h6>
                  <img className="maxwidth100" style={{ margin: "0 auto", display: 'block' }} src={this.getInputData(['nonprofit', 'image'])} alt="" />
                </div>
              </ApprovalsRejectionsButtons>
              <ApprovalsRejectionsButtons path={['nonprofit', 'cover_image']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div>
                  <h6> Nonprofit cover image</h6>
                  {this.getInputData(['nonprofit', 'cover_image']) !== this.na ? (
                    <img className="maxwidth100" style={{ margin: '0 auto', display: 'block' }} src={this.getInputData(['nonprofit', 'cover_image'])} alt="" />
                  ) : this.getInputData(['nonprofit', 'cover_image'])}
                </div>
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Causes</h3>
              {
             updateCauses?.length >0 && updateCauses?.map((item,index)=>{
                let pathName= item?.path.split("/")
                let pathIndex = pathName[pathName.length - 1]
                const subCausesString = item?.value?.subcauses?.length > 0 ? item?.value?.subcauses.join(', ') : ''; 
                return(
                  <ApprovalsRejectionsButtons  path={['nonprofit', 'causes', pathIndex]} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                   <div className="row">
                   <div className="col-md-4">
                     <div>
                       <h6>Cause</h6>
                       <p className="text-muted">{item?.value?.name}</p>
                     </div>
                   </div>
                   <div className="col-md-4">
                     <div>
                       <h6>Subcauses</h6>
                         <p className="text-muted">{subCausesString}</p>
                     </div>
                   </div>
                   <div className="col-md-4">
                     <div>
                       <h6>Desription</h6>
                       <p className="text-muted">{item?.value?.description}</p>
                     </div>
                   </div>
                 </div>
                </ApprovalsRejectionsButtons>
                 )
              })
             }
            </div>            
            <div className="card-body">
              <h3 className="mr-b-20">  Documents/Files</h3>
              <ApprovalsRejectionsButtons path={['nonprofit', 'annual_report_doc']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div>
                  <h6>Annual Report(for the latest financial year)</h6>
                  <p className="text-muted">{this.getInputData(['nonprofit', 'annual_report_doc']) !== this.na ? (<a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'annual_report_doc'])}>Preview</a>) : this.na}</p>
                </div>
              </ApprovalsRejectionsButtons>
            </div>
            <div className="card-body">
              <h3 className="mr-b-20">Social Media Accounts</h3>
              <ApprovalsRejectionsButtons path={['nonprofit', 'external_profiles']} getState={this.getApprovalRejectionState} changeState={this.setApprovalRejectionState}>
                <div className="row">
                  <div className="col-md-6">
                    <h6>Website of the Organization</h6>
                    <p className="text-muted">
                      {this.getInputData(['nonprofit', 'external_profiles', 'Website']) !== this.na ? <a className="text-muted" href={this.getInputData(['nonprofit', 'external_profiles', 'Website'])} target="_blank">{this.getInputData(['nonprofit', 'external_profiles', 'Website'])}</a> : this.na}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h6>Facebook Page URL</h6>
                    <small>Add URL of official page of organisation on Facebook. Only Pages with “Like” option can be added</small>
                    <p className="text-muted">
                      {this.getInputData(['nonprofit', 'external_profiles', 'Facebook']) !== this.na ? <a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'external_profiles', 'Facebook'])}>{this.getInputData(['nonprofit', 'external_profiles', 'Facebook'])}</a> : this.na}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h6>Twitter Page URL</h6>
                    <p className="text-muted">
                      {this.getInputData(['nonprofit', 'external_profiles', 'Twitter']) !== this.na ? <a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'external_profiles', 'Twitter'])}>{this.getInputData(['nonprofit', 'external_profiles', 'Twitter'])}</a> : this.na}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h6>YouTube Channel URL</h6>
                    <p className="text-muted">
                      {this.getInputData(['nonprofit', 'external_profiles', 'Youtube Channel']) !== this.na ? <a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'external_profiles', 'Youtube Channel'])}>{this.getInputData(['nonprofit', 'external_profiles', 'Youtube Channel'])}</a> : this.na}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h6>Instagram Profile URL</h6>
                    <p className="text-muted">
                      {this.getInputData(['nonprofit', 'external_profiles', 'Instagram']) !== this.na ? <a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'external_profiles', 'Instagram'])}>{this.getInputData(['nonprofit', 'external_profiles', 'Instagram'])}</a> : this.na}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <h6>Linkedin URL</h6>
                    <p className="text-muted">
                      {this.getInputData(['nonprofit', 'external_profiles', 'Linkedin']) !== this.na ? <a className="text-muted" target="_blank" href={this.getInputData(['nonprofit', 'external_profiles', 'Linkedin'])}>{this.getInputData(['nonprofit', 'external_profiles', 'Linkedin'])}</a> : this.na}
                    </p>
                  </div>
                </div>
              </ApprovalsRejectionsButtons>

            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex align-items-stretch">
                <div className="row flex-grow">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NonprofitBasicDetailsRead;
