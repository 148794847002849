import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loading from '../Loading';
import Profile from '../../productComponents/NGOOnboarding/Profile'

import Icon from '@mdi/react';

import {
  mdiChevronLeft,
  mdiLoading,
  mdiFloppy,
  mdiChevronRight,
  mdiHelpCircleOutline,
} from '@mdi/js';

import * as LoginAction from '../../reducers/loginReducer';
import * as ConfigAction from '../../reducers/configReducer';
import * as NotificationAction from '../../reducers/notificationReducer';

import Nav from '../../productComponents/NGOOnboarding/Nav';

class UserUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {}
    };
  }

  componentDidMount = () => {
    const { login, Login, privateRoute, PrivateRoute } = this.props;
    if (login.user_is_valid === null) {
      Login.get();
    }
  }

  componentDidUpdate = () => {
    const { login, Login, privateRoute, PrivateRoute } = this.props;
    if (login.user_is_valid === null && login.loading === false) {
      Login.get();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const setData = {};
    if (props.login.user !== state.prevProfile) {
      setData.prevProfile = props.login.user;
      setData.profile = { ...props.login.user };
    }

    if (Object.keys(setData).length > 0) {
      return setData;
    }
  }

  onProfileChange = (profile) => {
    this.setState({ profile });
  }

  onProfileSubmit = () => {
    const { Login, login, Notification } = this.props;
    const { profile } = this.state;
    if (profile.is_email_verified && profile.is_phone_verified && profile.name !== '') {
      Login.set({ name: profile.name }).then(() => {
        this.changeForm('nonprofit');
      });
    } else {
      if (!profile.is_email_verified) {
        Notification.addNotification('Please verify your email id');
      }
      if (!profile.is_phone_verified) {
        Notification.addNotification('Please verify your phone number');
      }
      if (login.user.name === '') {
        Notification.addNotification('Please enter your name');
      }
    }
  }

  render() {
    const {
      component: Private,
      login,
      Login,
      Notification,
      location,
      privateRoute,
    } = this.props;
    
    const { profile } = this.state

    return (
      <div className="container-scroller">
        <Nav form="profile" />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel container">
            <div className="content-wrapper">
            <Profile 
              value={profile} 
              login={login}
              Login={Login}
              Notification={Notification}
              config={this.props.config}
              onChange={this.onProfileChange}
            >
                <div className="col-md-12 grid-margin stretch-card flx-h-right np-btn-wrapper">                
                <button type="button" className="btn btn-success mr-2" onClick={(e) => { e.preventDefault(); this.onProfileSubmit(); }}>
                  <Icon path={mdiChevronRight} className="menu-arrow" size={0.75} color="#ffffff" />
                  &nbsp;Save & Next
                </button>
              </div>
            </Profile>
            </div>
          </div>
        </div>
      </div>          
    );    
  }
}

const mapStateToProps = state => ({
  login: state.login,
  config: state.config,
});

const mapActionToProps = dispatch => ({
  Login: bindActionCreators(LoginAction, dispatch),
  Config: bindActionCreators(ConfigAction, dispatch),
  Notification: bindActionCreators(NotificationAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(UserUpdate);
