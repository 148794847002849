import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classnames from 'classnames';

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import NonprofitTile from '../../../components/NonprofitTile';
import * as ComplianceApprovalActions from '../../../reducers/AdminApprovals/complianceApprovalReducer';

import Loading from '../../../appComponents/Loading';
class ComplianceApprovals extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      q: '',    
      filterQuery: {
        submitted_compliances: '',
        status: '',              
      },
      complianceKeyName: {
        giveassured: 'Give Assured'
      },
      compliance: ''
    };
  }

  componentDidMount() {    
    const { ComplianceApproval, match} = this.props;
    const { path } = match    
    const pathParams = path.split('/')    
    if(pathParams && pathParams.length){
      const complianceKey = pathParams[pathParams.length - 1]
      this.setState(
        {
          filterQuery: {
            submitted_compliances: complianceKey,
            required_compliances: complianceKey,
            status: 'published'        
          },
          compliance: complianceKey
        }
      )
      ComplianceApproval.getNonprofits({
        submitted_compliances: complianceKey,
        required_compliances: complianceKey,
        status: 'published'
      })
    }
  }

  componentWillUnmount() {
    const { ComplianceApproval } = this.props;
    ComplianceApproval.reset();
  }

  handelQ = (e) => {
    e.preventDefault();
    this.setState({ q: e.target.value });
  }

  handelSearch = (e) => {
    e.preventDefault();
    const { q, filterQuery } = this.state;
    const { ComplianceApproval } = this.props;
    if (q.length >= 3) {
      ComplianceApproval.reset();
      ComplianceApproval.getNonprofits({ ...filterQuery, q: q });
    } else if (q.length === 0) {
      ComplianceApproval.reset();
      ComplianceApproval.getNonprofits({ ...filterQuery });
    }
  }

  clearSearch = () => {
    const { filterQuery } = this.state;
    this.setState({q: ''});
    const { ComplianceApproval } = this.props;
    ComplianceApproval.reset();
    ComplianceApproval.getNonprofits({ ...filterQuery});
  }

  loadMore = () => {
    const { ComplianceApproval } = this.props;
    const { q } = this.state;
    ComplianceApproval.getNonprofits({ q });
  }

  NGOListHTML = () => {
    const { complianceApproval } = this.props;    
    if (!complianceApproval.get_status.nonprofits || complianceApproval.get_status.nonprofits === 'pending' && complianceApproval.nonprofits.length === 0) {
      return (<Loading />);
    }
    if (complianceApproval.nonprofits.length === 0) {
      return(
        <i className="align-center">No items available</i>
      );
    }
    return complianceApproval.nonprofits.map(item => (
      <div className="col-xl-3 col-lg-3 col-md-6 col-12 ngo-list-col" key={item.slug}>
        <NonprofitTile value={item} link={`/approvals/nonprofit-onboarding/${this.state.compliance}/`} status={item.status}/>
      </div>
    ));
  }



  render() {
    const { complianceApproval } = this.props;
    const { q, complianceKeyName, filterQuery } = this.state;
    return (
      <div className="content-wrapper">
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h3 className="mr-b-20">
                  {complianceKeyName[filterQuery.submitted_compliances]} Approvals
                </h3>
                <div className="row">
                  <div className="col-md-6 col-12">
                    {/* <div className="form-group">
                      <SelectInput />
                    </div> */}
                  </div>
                  <div className="col-md-6 col-12">
                    <form onSubmit={this.handelSearch}>
                      <div className="form-group d-flex search-field">
                        <div className={classnames('input-group', { active: (q !== '') })}>
                          <input type="text" className="form-control" placeholder="Search Here" value={q} onChange={this.handelQ} />
                          <div className="search-close">
                            <button type="button" onClick={this.clearSearch}><Icon path={mdiClose} className="menu-arrow" size={0.75} color="#979797" /></button>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary ml-3">Search</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row ngo-list-wrapper">
                  {this.NGOListHTML()}
                </div>
                <div className="row">
                  {(complianceApproval.load_status.nonprofits) ? (
                    <div className="col-md-12">
                      <button type="button" className="btn btn-fw align-center" onClick={(e) => { e.preventDefault(); this.loadMore(); }}>Load more</button>
                    </div>
                  ) : null}
                  {(complianceApproval.get_status.nonprofit === 'pending' && complianceApproval.nonprofits.length !== 0) ? (
                    <div className="col-md-12">
                      <Loading />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  complianceApproval: state.complianceApproval,
});

const mapActionToProps = dispatch => ({
  ComplianceApproval: bindActionCreators(ComplianceApprovalActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(ComplianceApprovals);
