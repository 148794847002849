import * as NotificationAction from '../notificationReducer';

import _Error from '../../utils/_error';

import NonProfitService from '../../services/nonprofit-service';
import CampaignService from '../../services/campaign-service';

export const GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS = 'GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS';
export const GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_SUCCESS = 'GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_SUCCESS';
export const GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FAIL = 'GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FAIL';

export const GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD = 'GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD';
export const GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD_SUCCESS = 'GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD_SUCCESS';
export const GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD_FAIL = 'GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD_FAIL';


export const CROWDFUNDING_DASHBOARD_RESET = 'CROWDFUNDING_DASHBOARD_RESET';

export const LOGIN_DEAUTHORISE = 'LOGIN_DEAUTHORISE';

const initialState = {
  nonprofit: {},
  campaigns: [],
  get_status: {},
  load_status: {},
};

export const campaignSupportersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          campaign: 'pending',
        },
      };
    case GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.campaigns,
        get_status: {
          ...state.get_status,
          campaign: 'success',
        },
        load_status: {
          ...state.load_status,
          campaign: action.load_status,
        }
      };
    case GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          campaign: 'failed',
        },
      };
    case GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'pending',
        },
      };
    case GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'success',
        }
      };
    case GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD_FAIL:
      return {
        ...state,
        get_status: {
          ...state.get_status,
          download: 'failed',
        },
      };
    case CROWDFUNDING_DASHBOARD_RESET:
      return {
        ...initialState,
      };
    case LOGIN_DEAUTHORISE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export function getCampaigns(query) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS,
    });
    const currentCampaigns = getState().crowdfundingCampaignSupporters.campaigns;
    const params = {
      ...query,
      scope: 'global',
      skip: currentCampaigns.length,
      limit: 10,
    };
    return CampaignService.get({ params })
      .then((response) => {
        if (response.length === 0) {
          dispatch({
            type: GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_SUCCESS,
            campaigns: currentCampaigns,
            load_status: false,
          });
        } else {
          dispatch({
            type: GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_SUCCESS,
            campaigns: [...currentCampaigns, ...response],
            load_status: true,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function getCampaignsForDownload(query) {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD,
    });
    const params = {
      ...query,
      scope: 'global',
      skip: 0,
      limit: 1000,
    };
    return CampaignService.get({ params })
      .then((response) => {
          dispatch({
            type: GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD_SUCCESS,
          });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GET_CROWDFUNDING_SUPPORTERS_CAMPAIGNS_FOR_DOWNLOAD_FAIL,
        });
        dispatch(NotificationAction.addNotification(error.message));
      });
  };
}

export function reset() {
  return (dispatch) => {
    dispatch({
      type: CROWDFUNDING_DASHBOARD_RESET,
    });
  };
}
